import React, { useState } from "react";
import ToggleInputAndText from "../../../../toggleInputAndText";
import { Dropdown, DropdownButton } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import "./matrix.css";

export default function MatrixTypeQuestion(props) {
  const {
    onDraw,
    isPreview,
    isEdit,
    addColumnToMatrix,
    deleteMatrixItem,
    addRowToMatrix,
    data,
    handleChangeMatrixData,
    saveMatrixOption,
    isMobile = false,
    isTablet = false,
    handleEditView,
    rightBarView,
  } = props;
  const { id, matrix, style } = onDraw;
  if (!style.matrix)
    style.matrix = {
      rowColor: "#000",
      columnColor: "#000",
      rowFontSize: "28",
      mobileRowFontSize: "28",
      tabletRowFontSize: "28",
      columnFontSize: "28",
      mobileColumnFontSize: "28",
      tabletColumnFontSize: "28",
      fontFamily: "",
      borderSize: "0",
      borderColor: "#000",
    };

  let rowFontSize = style.matrix.rowFontSize;
  if (isMobile && style.matrix.mobileRowFontSize)
    rowFontSize = style.matrix.mobileRowFontSize;
  else if (isTablet && style.matrix.tabletRowFontSize)
    rowFontSize = style.matrix.tabletRowFontSize;
  else if (style.matrix.rowFontSize) rowFontSize = style.matrix.rowFontSize;

  const rowStyle = {
    fontSize: `${rowFontSize}px`,
    color: style.matrix.rowColor,
    border: `${style.matrix.borderSize}px solid ${style.matrix.borderColor}`,
  };

  let columnFontSize = style.matrix.columnFontSize;
  if (isMobile && style.matrix.mobileColumnFontSize)
    columnFontSize = style.matrix.mobileColumnFontSize;
  else if (isTablet && style.matrix.tabletColumnFontSize)
    columnFontSize = style.matrix.tabletColumnFontSize;
  else if (style.matrix.rowFontSize)
    columnFontSize = style.matrix.columnFontSize;

  const columnStyle = {
    fontSize: `${columnFontSize}px`,
    color: style.matrix.columnColor,
    border: `${style.matrix.borderSize}px solid ${style.matrix.borderColor}`,
  };

  const matrixStyle = {
    fontFamily: style.matrix.fontFamily.replace(/\+/g, " "),
  };

  const { columns = [], rows = [], allowMultipleSelection = false } = matrix;
  const [selectedCollapse, setSelectedCollapse] = useState(null);
  if (!isMobile) {
    return (
      <div
        style={matrixStyle}
        onClick={(e) => handleEditView(e, "matrixStyle")}
        className={`matrix ${isEdit ? "matrix-edit" : ""} ${
          isEdit && rightBarView.context.replace("Style", "") === "matrix"
            ? "active-element"
            : ""
        }`}
      >
        <div className="matrix-table">
          <div className="matrix-content">
            <div className="matrix-header">
              <div
                className="matrix-scale-points-gap"
                style={columnStyle}
              ></div>
              <div
                aria-label="Set of answer choices"
                className="matrix-scale-points-items"
                role="group"
                style={{
                  gridTemplateColumns: `repeat(${columns.length}, 1fr)`,
                }}
              >
                {columns.map((column, index) => (
                  <div
                    key={column.id}
                    style={columnStyle}
                    className="matrix-scale-points-item"
                  >
                    <span
                      className="display-with-image"
                      id="matrix-scale-point-QID1-1"
                    >
                      <span className="display-with-image-display rich-text">
                        <ToggleInputAndText
                          isRichText={true}
                          value={column.value || `col`}
                          handleChange={(e) =>
                            handleChangeMatrixData(e, id, index, "columns")
                          }
                          id={column.id}
                          name="value"
                          data={data}
                          tag="span"
                          isEdit={isEdit}
                          placeholder="Write title here"
                          isResult={false}
                        />
                        {isEdit ? (
                          <a
                            className="matrix-delete-button"
                            type="button"
                            onClick={() =>
                              deleteMatrixItem(id, index, "columns")
                            }
                            title=""
                          ></a>
                        ) : (
                          <></>
                        )}
                      </span>
                    </span>
                    <p style={{ width: "0px", height: "0px" }}></p>
                  </div>
                ))}
              </div>
            </div>
            {rows.map((row, index) => (
              <div
                key={row.id}
                role="radiogroup"
                aria-labelledby="choice-display-QID1-1"
                style={rowStyle}
              >
                <div className="matrix-statement">
                  <div className="matrix-statement-header" id="QID1-s-1">
                    <div className="choice">
                      <div className="choice-error-container">
                        <div className="choice-error">
                          <span className="choice-content">
                            <span
                              className="display-with-image"
                              id="choice-display-QID1-1"
                            >
                              <span className="display-with-image-display rich-text testing-quill-matrix">
                                <ToggleInputAndText
                                  isRichText={false}
                                  value={row.value || `row`}
                                  handleChange={(e) =>
                                    handleChangeMatrixData(e, id, index, "rows")
                                  }
                                  id={row.id}
                                  name="value"
                                  data={data}
                                  tag="span"
                                  isEdit={isEdit}
                                  placeholder="Write title here"
                                  isResult={false}
                                />
                                {isEdit ? (
                                  <a
                                    className="matrix-delete-button"
                                    type="button"
                                    onClick={() =>
                                      deleteMatrixItem(id, index, "rows")
                                    }
                                    title=""
                                  ></a>
                                ) : (
                                  <></>
                                )}
                              </span>
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="matrix-statement-items"
                    style={{
                      gridTemplateColumns: `repeat(${columns.length}, 1fr)`,
                    }}
                  >
                    {row.columns.map((column, index) => (
                      <div
                        key={index}
                        className="matrix-statement-item"
                        style={rowStyle}
                      >
                        <div className="matrix-input">
                          <input
                            aria-invalid="false"
                            aria-labelledby="matrix-scale-point-QID1-25"
                            onChange={(e) =>
                              isPreview
                                ? saveMatrixOption(e, row, column)
                                : null
                            }
                            type={allowMultipleSelection ? "checkbox" : "radio"}
                            name={row.id}
                            value={column.id}
                          />

                          <span className="matrix-input-control">
                            <span className="matrix-input-shadow"></span>
                            <span className="radio-button radio"></span>
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {isEdit ? (
          <>
            <a
              type="button"
              onClick={() => addRowToMatrix(id)}
              title=""
              className="addRow"
              style={{ direction: "ltr" }}
            >
              Add row
            </a>
            <a
              type="button"
              onClick={() => addColumnToMatrix(id)}
              title=""
              className="addCol"
              style={{ direction: "ltr" }}
            >
              Add col
            </a>
            {data.type === "scored" ? (
              <a
                type="button"
                title=""
                className="edit"
                onClick={(e) => handleEditView(e, "matrixExtraData")}
                style={{ direction: "ltr" }}
              ></a>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    );
  }
  return (
    <div className="matrix grid2 matrix-mobile">
      {rows.map((row, index) => {
        return (
          <>
            <div
              className="matrix-collapse-mobile"
              onClick={() => setSelectedCollapse(index)}
            >
              <span>{row.value.replace(/(<([^>]+)>)/gi, "")}</span>
            </div>
            <Collapse in={index === selectedCollapse}>
              <div>
                {columns.map((column) => {
                  return (
                    <div className="matrix-mobile-item">
                      <label htmlFor={`${row.id}_${column.id}`}>
                        {column.value.replace(/(<([^>]+)>)/gi, "")}
                      </label>
                      <input
                        type={allowMultipleSelection ? "checkbox" : "radio"}
                        name={row.id}
                        value={row.id}
                        id={`${row.id}_${column.id}`}
                        onChange={(e) => {
                          if (isPreview) {
                            saveMatrixOption(e, row, column);
                            if (rows[index + 1]) setSelectedCollapse(index + 1);
                          }
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </Collapse>
          </>
        );
      })}
    </div>
  );
}
