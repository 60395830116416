export class Text {

    constructor(title, counter) {
        this.id = `T_${Math.round(Math.random() * 999999)}`;
        this.type = "text";
        this.counter = counter;
        this.stepIndex = 2;
        this.title = title;
        this.canDeleted = true;
        this.description = "Enter text.";
        this.buttonText = "Start Quiz";
        this.redirectTo = "";
        this.showMedia = false;
        this.image = "https://www.freeiconspng.com/uploads/no-image-icon-11.PNG";
        this.buttonPosition = "center";
        this.mediaImageSrc = "";
        this.startWithEnterKey = false;
        this.style = {
            title: {
                color: "#000",
                fontWeight: "",
                fontSize: "28",
                fontType: "",
                fontFamily: "",
                fontStyle: "",
                textAlign: "center",
                padding: { top: "0", right: "0", bottom: "0", left: "0" },
            },
            subtitle: {
                color: "#000",
                fontWeight: "",
                fontSize: "16",
                fontType: "",
                fontFamily: "",
                fontStyle: "",
                textAlign: "center",
                padding: { top: "0", right: "0", bottom: "0", left: "0" },
            },
            media: {
                size: "25",
                borderSize: "0",
                borderRadius: "0",
                borderColor: "",
                padding: { top: "0", right: "0", bottom: "0", left: "0" },
            },
            button: {
                color: "#000",
                background: "",
                borderSize: "0",
                borderColor: "",
                borderRadius: "0",
                hoverColor: "",
                hoverBackground: "",
                hoverBorderSize: "0",
                hoverBorderColor: "",
                hoverBorderRadius: "0",
                fontWeight: "",
                fontSize: "",
                fontType: "",
                fontFamily: "",
                fontStyle: "",
                padding: { top: "0", right: "0", bottom: "0", left: "0" },
                textAlign: "center",
            },
        };
    }
}
