import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Header from "../../../ThemeEdit/header";
// import { FiEdit3 } from "react-icons/fi";
// import Avatar from "@material-ui/core/Avatar";
import { addStyleSettings } from "../../../../../utils/patchFunctions";

function HeaderStyle(props) {
  const [hookSettings, setHookSettings] = useState({});
  const [hookStyle, setHookStyle] = useState({});
  const handleColorsToChange = (e, name) => {
    const { r, g, b, a } = e.rgb;
    const value = `rgba(${r}, ${g}, ${b}, ${a})`;
    props.handleQuestionStyle({ target: { name, value } }, props.id, "header");
  };
  const {
    settings,
    style,
    handleQuestionStyle,
    fonts,
    id,
    useHeaderCustomStyle,
    handleChangeQuestion,
    t,
    changeNavs,
    mainSettings,
  } = props;

  useEffect(() => {
    if (!settings.header) {
      const newSettings = addStyleSettings(settings, "header");
      setHookSettings(newSettings);
    } else {
      setHookSettings(settings);
    }

    if (!style.header) {
      const newstyle = addStyleSettings(style, "header");
      setHookStyle(newstyle);
    } else {
      setHookStyle(style);
    }
  }, [props.settings, props.style]);

  return (
    <>
      <div className="addSwitch override">
        <label className="switch">
          <input
            type="checkbox"
            name="withSubtitle"
            checked={useHeaderCustomStyle ? true : false}
            onChange={(e) =>
              handleChangeQuestion(
                {
                  target: {
                    name: "useHeaderCustomStyle",
                    type: "checkbox",
                    checked: useHeaderCustomStyle ? false : true,
                  },
                },
                id
              )
            }
          />
          <span className="slider round"></span>
        </label>
        {t("rightBar-customize-override")}
      </div>
      {useHeaderCustomStyle ? (
        <>
          <Header
            fromTheme={false}
            selectedTheme={"override-edit"}
            handleChange={handleQuestionStyle}
            context="header"
            // fonts={fonts}
            props={{
              ...props,
              data: {
                header: props.style.header || props.settings.header,
                fonts,
              },
              changeNavs,
              mainSettings,
            }}
            handleColorsToChange={handleColorsToChange}
            // changeNavs={changeNavs}

            // handleChange={this.handleChange}
            // onSwatchHover={this.onSwatchHover}
            // changeBackgroundImage={this.changeBackgroundImage}
            // changeHeaderImage={this.changeHeaderImage}
            // changeLogo={this.changeLogo}
          />
        </>
      ) : (
        <>
          <div className="addSwitch-disabled"></div>
          <Header
            fromTheme={false}
            selectedTheme={"override-edit"}
            handleChange={{}}
            context="header"
            // fonts={fonts}
            props={{
              ...props,
              data: {
                header: props.style.header || props.settings.header,
                fonts,
              },
              changeNavs,
              mainSettings,
            }}
            handleColorsToChange={{}}
          />
        </>
      )}
    </>
  );
}

export default withTranslation()(HeaderStyle);
