import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getThemes } from "../../../redux/actions";
import ThemesItem from "./ThemeItem";
import "./themes.css";
import { withTranslation } from "react-i18next";
import { GrFormClose } from "react-icons/gr";
import Modal from "react-bootstrap/esm/Modal";

function ThemesList(props) {
  const {
    changeTheme,
    selectedTheme,
    handleCurrentEdit,
    themes,
    newThemeTemplate,
    changeRightBarView, context,
    t,
    setThemeData
  } = props;
  const [showMyThemes, setShowMyThemes] = useState(true);

  const [themesForDom, setThemesForDom] = useState([]);
  const [importTheme, setImportTheme] = useState(false);

  useEffect(() => {
    props.actions.getThemes();
  }, []);

  useEffect(() => {
    // console.log(selectedTheme);
    const selectedIndex = themes.findIndex(
      (theme) => theme.theme_id === selectedTheme.theme_id
    );
    let tempThemes = [...themes];
    const currentTheme = tempThemes.splice(selectedIndex, 1);
    tempThemes.push(...currentTheme);
    setThemesForDom(tempThemes);
  }, [themes]);

  return (
    <div className="tabContent tabActive">
      <div className="d-flex w-100 flex-row-reverse">
        <button className="btn btn-link" style={{marginTop:"-15px"}}
          onClick={() => changeRightBarView(context, "", "myStyle")}> <GrFormClose size="20" /></button>
      </div>
      <div className="themesView" style={{marginTop:"-10px"}}>
        <a
          className={`tabView ${showMyThemes ? "active" : ""}`}
          onClick={() => setShowMyThemes(true)}
          type="button"
        >
          {t("rightBar-theme-my-themes")}
        </a>
        <a
          onClick={() => setShowMyThemes(false)}
          className={`tabView ${!showMyThemes ? "active" : ""}`}
          type="button"
        >
          {t("rightBar-theme-gallery")}
        </a>
      </div>
      {/* {showMyThemes ? (
        <a
          type="button"
          title=""
          onClick={() => handleCurrentEdit(newThemeTemplate)}
          className="add"
        >
          <span>+ {t("rightBar-theme-add-theme")}</span>
        </a>
      ) : (
        <></>
      )} */}

      {themesForDom
        .filter(
          (theme) =>
            (showMyThemes && !theme.is_public) ||
            (!showMyThemes && theme.is_public)
        )
        .reverse()
        .map((theme, index) => {
          return (
            <ThemesItem
              key={index}
              changeTheme={changeTheme}
              theme={theme}
              selectedTheme={selectedTheme}
              handleCurrentEdit={handleCurrentEdit}
              setImportTheme={setImportTheme}
            />
          );
        })}

      <Modal
        className="package-modal"
        size="sm"
        show={importTheme}
        onHide={() => {
          setImportTheme(null);
        }}
        centered
      >
        <h3>Are you sure you want to import this theme?</h3>
        <p>you can't undo this action</p>
        <div className="d-flex justify-content-around">
          <button className="btn btn-link" style={{ color: "#000" }} onClick={() => setImportTheme(null)}>Cancel</button>
          <button className="publish" onClick={() => {
            setThemeData(Object.assign({}, importTheme));
            setImportTheme(null)
          }}>Yes</button>
        </div>
      </Modal>

    </div>
  );
}

const mapStateToProps = ({ themes }) => {
  return { themes };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      getThemes: () => dispatch(getThemes()),
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ThemesList)
);
