import React, { useState, useEffect } from "react";
import Select from "./Select";
import TextInput from "react-autocomplete-input";
import SelectFields from "./SelectFields";
import InputFields from "./InputFields";
import AcTest from "../../../../AcTest/Index";

export default function Parameters({
  moduleParameters = [],
  moduleName,
  selectedIntegrationAppId,
  selectedConnection,
  setParams,
  params,
  getModulesConnectionsOptions,
  moduleConnectionsOptions,
  selectedTrigger,
  setSelectedTrigger,
  dataWithVariables,
  moduleFieldsOptions,
  triggerRpc,
  moduleAdditionalptions,
}) {

  // const [ selectedConnection, setSelectedConnection ] = useState({});
  const [moduleParams, setModuleParams] = useState([]);
  // useEffect(() => { }, [selectedConnection]);

  useEffect(() => {
    if (typeof moduleParameters == "string")
      moduleParameters = setModuleParams(JSON.parse(moduleParameters));
  }, [moduleParameters]);

  const onChange = ({ value }, parameter) => {
    setParams({ ...params, [parameter.name]: value });
    if (selectedTrigger) {
      setSelectedTrigger({
        ...selectedTrigger,
        connection_config: {
          [selectedTrigger.module_id]: {
            ...selectedTrigger.connection_config[selectedTrigger.module_id],

            [parameter.name]: value,
          },
        },
      });
    }
    if (parameter.trigger && parameter.trigger.length) {
      triggerRpc(
        {
          connectionId: selectedConnection.id,
          integrationId: selectedConnection.integration_app_connection_id,
          isHeirarchyConnection: true,
        },
        parameter.trigger,
        moduleParams,
        {
          [parameter.name]: value,
        }
      );
    }
  };

  const drawParameterByType = (parameter) => {
    const {
      type,
      label,
      isMulti,
      name: pName,
      required,
      description,
    } = parameter;
    switch (type) {
      case "select":
        return (
          <div className="d-flex align-items-center mt-2">
            <div className="col-6">
              <label>
                {label} {required ? <span className="red">*</span> : ""}{" "}
              </label>
              <Select
                setSelectedTrigger={setSelectedTrigger}
                selectedTrigger={selectedTrigger}
                parameter={parameter}
                setParams={setParams}
                params={params}
                moduleName={moduleName}
                selectedIntegrationAppId={selectedIntegrationAppId}
                selectedConnection={selectedConnection}
                getModulesConnectionsOptions={getModulesConnectionsOptions}
                moduleConnectionsOptions={moduleConnectionsOptions}
                onChange={onChange}
                isMulti={isMulti}
                moduleAdditionalptions={moduleAdditionalptions}
              />
            </div>
            <span className="mt-3">
              {description || "Description not available"}
            </span>
          </div>
        );
      case "textarea":
        return (
          <div className="d-flex align-items-center mt-2">
            <div className="col-6">
              {selectedTrigger ? (
                <>
                  <label htmlFor="">
                    {label} {required ? <span className="red">*</span> : ""}
                  </label>
                  <AcTest
                    changeOnSelect={(trigger, slug) => "{{" + slug + "}}"}
                    trigger={["{{"]}
                    spacer=""
                    options={{ "{{": [...dataWithVariables] }}
                    className="w-100"
                    cols="30"
                    value={
                      selectedTrigger.connection_config[
                      selectedTrigger.module_id
                      ][pName]
                    }
                    rows="10"
                    maxOptions={50}
                    onChange={(e) => onChange({ value: e }, parameter)}
                  />
                </>
              ) : (
                <>
                  <label htmlFor="">
                    {label} {required ? <span className="red">*</span> : ""}
                  </label>
                  <AcTest
                    changeOnSelect={(trigger, slug) => "{{" + slug + "}}"}
                    trigger={["{{"]}
                    spacer=""
                    options={{ "{{": [...dataWithVariables] }}
                    className="w-100"
                    cols="30"
                    rows="10"
                    maxOptions={50}
                    onChange={(e) => setParams({ ...params, [pName]: e })}
                  />
                </>
              )}
            </div>
            <span className="mt-3">
              {description || "Description not available"}
            </span>
          </div>
        );
      case "text":
        return (
          <div className="d-flex align-items-center mt-2">
            <div className="col-6">
              {selectedTrigger ? (
                <>
                  <label htmlFor="">
                    {label} {required ? <span className="red">*</span> : ""}
                  </label>
                  <AcTest
                    changeOnSelect={(trigger, slug) => "{{" + slug + "}}"}
                    trigger={["{{"]}
                    spacer=""
                    options={{ "{{": [...dataWithVariables] }}
                    className="w-100 just-input"
                    style={{ height: "44px" }}
                    value={
                      selectedTrigger.connection_config[
                      selectedTrigger.module_id
                      ][pName]
                    }
                    rows="1"
                    maxOptions={50}
                    onChange={(e) => onChange({ value: e }, parameter)}
                  />
                </>
              ) : (
                <>
                  <label htmlFor="">
                    {label} {required ? <span className="red">*</span> : ""}
                  </label>
                  <AcTest
                    changeOnSelect={(trigger, slug) => "{{" + slug + "}}"}
                    spacer=""
                    trigger={["{{"]}
                    options={{ "{{": [...dataWithVariables] }}
                    className="w-100 just-input"
                    rows="1"
                    maxOptions={50}
                    onChange={(e) => setParams({ ...params, [pName]: e })}
                  />
                </>
              )}
            </div>
            <span className="mt-3">
              {description || "Description not available"}
            </span>
          </div>
        );
      case "selectFields":
        return (
          <div className="d-flex align-items-center mt-2">
            <div className="col-6">
              <label>
                {label} {required ? <span className="red">*</span> : ""}
              </label>
              <SelectFields
                setSelectedTrigger={setSelectedTrigger}
                selectedTrigger={selectedTrigger}
                parameter={parameter}
                setParams={setParams}
                params={params}
                moduleName={moduleName}
                selectedIntegrationAppId={selectedIntegrationAppId}
                selectedConnection={selectedConnection}
                getModulesConnectionsOptions={getModulesConnectionsOptions}
                moduleConnectionsOptions={moduleConnectionsOptions}
                onChange={onChange}
                dataWithVariables={dataWithVariables}
                moduleFieldsOptions={moduleFieldsOptions}
                moduleParams={moduleParams}
              />
            </div>
            <span className="mt-3">
              {description || "Description not available"}
            </span>
          </div>
        );
      case "inputFields":
        return (
          <div className="d-flex align-items-center mt-2">
            <div className="col-6">
              <label>
                {label} {required ? <span className="red">*</span> : ""}
              </label>
              <InputFields
                setSelectedTrigger={setSelectedTrigger}
                selectedTrigger={selectedTrigger}
                parameter={parameter}
                setParams={setParams}
                params={params}
                moduleName={moduleName}
                selectedIntegrationAppId={selectedIntegrationAppId}
                selectedConnection={selectedConnection}
                getModulesConnectionsOptions={getModulesConnectionsOptions}
                moduleConnectionsOptions={moduleConnectionsOptions}
                onChange={onChange}
                dataWithVariables={dataWithVariables}
                moduleFieldsOptions={moduleFieldsOptions}
                moduleParams={moduleParams}
              />
            </div>
            <span className="mt-3">
              {description || "Description not available"}
            </span>
          </div>
        );
      default:
        break;
    }
    return <></>;
  };

  return (
    <div>
      {moduleParams.map((parameter) => {
        return drawParameterByType(parameter);
      })}
    </div>
  );
}
