import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { changeEmbeddObject, setGtmStatusAction } from "../../redux/actions";
import { Redirect } from "react-router-dom";
import FullPage from "./EmbedViews/FullPage";
import Banner from "./EmbedViews/Banner";
import PopUp from "./EmbedViews/PopUp";
import Side from "./EmbedViews/Side";
import CustomEmbed from "./EmbedViews/CustomEmbed";
import Navbar from "../Navbar";
import "./embedd.css";
import { withTranslation } from "react-i18next";
import handleHighlightService from "../../utils/recoredSessionService";

function Embedd(props) {
  const { slug, guid, path, quizId, t } = props;
  const [redirectToDashboard, setRedirectToDashboard] = useState(false);
  const [redirectToQuiz, setRedirectToQuiz] = useState(false);

  const [selectedTab, setSelectedTab] = useState("fullpage");
  useEffect(() => {
    const { changeEmbeddObject } = props.actions;
    if (!slug || !guid || !path || !quizId) {
      // setRedirectToDashboard(true);
    }
    if (
      props.userId &&
      props.gtmStatus.page &&
      props.gtmStatus.page !== "embedd"
    ) {
      props.actions.setGtmStatus({
        page: "embedd",
        userId: props.userId,
        events: null,
      });
      handleHighlightService(props.userId, props.userName);
    }
    return () =>
      changeEmbeddObject({ slug: null, guid: null, path: null, quizId: null });
  }, []);

  const rediercts = () => {
    if (redirectToDashboard) {
      return <Redirect to="/dashboard" />;
    } else if (redirectToQuiz) {
      return <Redirect to={`/edit/${quizId}`} />;
    } else {
      return <></>;
    }
  };

  const tabContent = () => {
    switch (selectedTab) {
      case "fullpage":
        return <FullPage slug={slug} guid={guid} path={path} />;
        break;
      case "banner":
        return <Banner slug={slug} guid={guid} path={path} />;
        break;
      case "popup":
        return <PopUp slug={slug} guid={guid} path={path} />;
        break;
      case "side":
        return <Side slug={slug} guid={guid} path={path} />;
        break;
      case "custom":
        return <CustomEmbed slug={slug} guid={guid} path={path} />;
        break;
      default:
        return <></>;
    }
  };

  const renderTabBtn = (tabName, viewName) => {
    if (tabName === selectedTab) {
      return (
        <a title="" className="active">
          {viewName}
        </a>
      );
    } else {
      return (
        <a
          onClick={() => setSelectedTab(tabName)}
          title=""
          className=" pointer"
        >
          {viewName}
        </a>
      );
    }
  };

  return (
    <>
      {rediercts()} <Navbar helpLabel={t("help-center")} />
      <section className="embed">
        <a
          title=""
          className="back Pointer"
          onClick={() => setRedirectToQuiz(true)}
        >
          Back to the Quiz
        </a>
        <div className="embedContent">
          <h2>Embeding preferences:</h2>
          <div className="embedTabs">
            {renderTabBtn("fullpage", "Full Page")}
            {renderTabBtn("popup", "Popup")}

            {renderTabBtn("banner", "Banner")}
            {renderTabBtn("side", "Side")}

            {renderTabBtn("custom", "Custom Embed")}
            {/*<a*/}
            {/*  title=""*/}
            {/*  onClick={(e) => alert("option not available")}*/}
            {/*  value="amp"*/}
            {/*>*/}
            {/*  Instant Article/AMP{" "}*/}
            {/*</a>*/}
          </div>
          <div className="embedTabs-content">{tabContent()}</div>
        </div>
      </section>
    </>
  );
}

const mapToProps = (state) => {
  const { embedd, gtmStatus, loginDetails } = state;
  return {
    ...embedd,
    gtmStatus,
    userId: loginDetails.user_id,
    userName: loginDetails.name,
  };
};
const mapDispatch = (dispatch) => {
  return {
    actions: {
      changeEmbeddObject: (obj) => dispatch(changeEmbeddObject(obj)),
      setGtmStatus: (status) => dispatch(setGtmStatusAction(status)),
    },
  };
};

export default withTranslation()(connect(mapToProps, mapDispatch)(Embedd));
