import React, { useEffect, useState } from "react";

function CreateApiKeyModal(props) {
  const {
    setKeyNameForm,
    setKeyTypeForm,
    setKeySecurityForm,
    validateNewKey,
    hide,
  } = props;
  return (
    <div className="api" id="key-form">
      <div className=" ">
        <label className="fields"> Key Name: </label>
        <input
          type="text"
          className=""
          onChange={(e) => setKeyNameForm(e.target.value)}
        />
      </div>
      <div className=" fields">
        <label className=""> key Type: </label>
        <select
          name=""
          id=""
          className=""
          onChange={(e) => setKeyTypeForm(e.target.value)}
        >
          <option value="">Choose</option>
          <option value="dev">Development</option>
          <option value="prod">Production</option>
        </select>
      </div>
      <div className="fields">
        <label className=""> key Security: </label>
        <select
          name=""
          id=""
          className=""
          onChange={(e) => setKeySecurityForm(e.target.value)}
        >
          <option value="">Choose</option>
          <option value="none">None</option>
        </select>
        <button className="btn-popup" onClick={() => validateNewKey()}>
          {" "}
          Create
        </button>
        <span className="close close_multi float-initial" onClick={hide}>
          Cancel
        </span>
      </div>
    </div>
  );
}

export default CreateApiKeyModal;
