import React, { useState, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AddContactInputField from "../AddContactInputField";
import AddContactSelectField from "../AddContactSelectField";
import AddContactDateField from "../AddContactDateField";
import AddContactCheckboxField from "../AddContactCheckboxField";
import AddContactSliderField from "../AddContactSliderField";
import AddContactSignatureField from "../AddContactSignatureField";
import { stripTags, isJsonString } from "../../../../../utils";
import AddContactFileField from "../AddContactFileField";
import { RxSlider } from "react-icons/rx";

import {
  MdOutlineInput,
  MdOutlineArrowDropDownCircle,
  MdOutlineDateRange,
  MdOutlineCheckBox,
  MdOutlineLocationOn,
  MdUploadFile,
} from "react-icons/md";
import AddContactLocationField from "../AddContactLocationField";

import { FaSignature } from "react-icons/fa";

function ContactFormLayout(props) {
  let {
    inputs,
    id,
    deleteInputFromContact,
    changeInputIndexes,
    handleContactInputs,
    handleChangeContactDetails,
    addInputToContact,
    arrayOfData,
    variables,

    addLocationsContainerToContact,
    deleteLocationContainerFromContact,
    handleContactLocationInputs,
  } = props;
  const { locationsInputs = [] } = props.data.onEdit;

  const [dataWithVariables, setDataWithVariables] = useState([]);
  const [jsonVarsArray, setJsonVarsArray] = useState([]);
  const [isAddNew, setIsAddNew] = useState(false);

  useEffect(() => {
    const questionsSteps = arrayOfData
      .filter((itr) => itr.type === "question")
      .map((itr) => stripTags(itr.title));
    let contactSteps = arrayOfData.filter((itr) => itr.type === "contact");
    const allInputs = contactSteps.reduce((allInputs, step) => {
      const { inputs = [] } = step;
      inputs.forEach((input) => {
        if (input.type === "location") {
          input.inputs.forEach((locationInput) => {
            if (locationInput.show) {
              allInputs.push(`${input.label}_${locationInput.label}`);
            }
          });
        } else allInputs.push(input.label);
      });
      return allInputs;
    }, []);

    const variablesArray = variables.map((variable) => variable.name);

    setDataWithVariables([
      ...new Set(
        [...questionsSteps, ...allInputs, ...variablesArray, "score"].filter(
          (item) => item
        )
      ),
    ]);
    setJsonVarsArray([
      ...new Set([
        ...variables.filter(
          (item) => item.type === "json" && isJsonString(item.value)
        ),
      ]),
    ]);
  }, [arrayOfData, variables]);

  const allInputsTypes = []
    .concat(
      ...arrayOfData
        .filter((step) => step.type === "contact")
        .map((step) => step.inputs)
    )
    .map((input) => input.validationType);

  const drawInputsFields = () => {
    return inputs.map((step, index) => {
      switch (step.type) {
        case "text":
        case "number":
        case "tel":
        case "url":
        case "phoneNumber":
        case "email": {
          return (
            <Draggable key={step.id} draggableId={step.id} index={index}>
              {(provided) => {
                return (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    key={step.id}
                  >
                    <AddContactInputField
                      step={step}
                      key={index}
                      index={index}
                      id={id}
                      deleteInput={deleteInputFromContact}
                      toggleError={toggleErrorMessageInput}
                      handleInputName={handleInputName}
                      handleInputVariableName={handleInputVariableName}
                      handleContactInputs={handleContactInputs}
                      allInputsTypes={allInputsTypes}
                      dataWithVariables={dataWithVariables}
                    />
                  </div>
                );
              }}
            </Draggable>
          );
        }

        case "select": {
          return (
            <Draggable key={step.id} draggableId={step.id} index={index}>
              {(provided) => {
                return (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    key={step.id}
                  >
                    <AddContactSelectField
                      step={step}
                      key={index}
                      index={index}
                      id={id}
                      deleteInput={deleteInputFromContact}
                      toggleError={toggleErrorMessageInput}
                      handleInputName={handleInputName}
                      handleInputVariableName={handleInputVariableName}
                      handleContactInputs={handleContactInputs}
                      dataWithVariables={dataWithVariables}
                      jsonVarsArray={jsonVarsArray}
                    />
                  </div>
                );
              }}
            </Draggable>
          );
        }
        case "date": {
          return (
            <Draggable key={step.id} draggableId={step.id} index={index}>
              {(provided) => {
                return (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    key={step.id}
                  >
                    <AddContactDateField
                      step={step}
                      key={index}
                      index={index}
                      id={id}
                      deleteInput={deleteInputFromContact}
                      toggleError={toggleErrorMessageInput}
                      handleInputName={handleInputName}
                      handleInputVariableName={handleInputVariableName}
                      handleContactInputs={handleContactInputs}
                      dataWithVariables={dataWithVariables}
                    />
                  </div>
                );
              }}
            </Draggable>
          );
        }
        case "checkbox": {
          return (
            <Draggable key={step.id} draggableId={step.id} index={index}>
              {(provided) => {
                return (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    key={step.id}
                  >
                    <AddContactCheckboxField
                      step={step}
                      key={index}
                      index={index}
                      id={id}
                      deleteInput={deleteInputFromContact}
                      toggleError={toggleErrorMessageInput}
                      handleInputName={handleInputName}
                      handleInputVariableName={handleInputVariableName}
                      handleContactInputs={handleContactInputs}
                      dataWithVariables={dataWithVariables}
                    />
                  </div>
                );
              }}
            </Draggable>
          );
        }
        case "signature": {
          return (
            <Draggable key={step.id} draggableId={step.id} index={index}>
              {(provided) => {
                return (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    key={step.id}
                  >
                    <AddContactSignatureField
                      step={step}
                      key={index}
                      index={index}
                      id={id}
                      deleteInput={deleteInputFromContact}
                      toggleError={toggleErrorMessageInput}
                      handleInputName={handleInputName}
                      handleInputVariableName={handleInputVariableName}
                      handleContactInputs={handleContactInputs}
                      dataWithVariables={dataWithVariables}
                    />
                  </div>
                );
              }}
            </Draggable>
          );
        }

        case "location": {
          return (
            <Draggable key={step.id} draggableId={step.id} index={index}>
              {(provided) => {
                return (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    key={step.id}
                  >
                    <AddContactLocationField
                      step={step}
                      key={index}
                      index={index}
                      id={id}
                      deleteLocationContainerFromContact={
                        deleteLocationContainerFromContact
                      }
                      toggleError={toggleErrorMessageInput}
                      handleInputName={handleInputName}
                      handleInputVariableName={handleInputVariableName}
                      handleContactInputs={handleContactInputs}
                      handleContactLocationInputs={handleContactLocationInputs}
                      allInputsTypes={allInputsTypes}
                      dataWithVariables={dataWithVariables}
                    />
                  </div>
                );
              }}
            </Draggable>
          );
        }

        case "file": {
          return (
            <Draggable key={step.id} draggableId={step.id} index={index}>
              {(provided) => {
                return (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    key={step.id}
                  >
                    <AddContactFileField
                      step={step}
                      key={index}
                      index={index}
                      id={id}
                      deleteInput={deleteInputFromContact}
                      toggleError={toggleErrorMessageInput}
                      handleInputName={handleInputName}
                      handleInputVariableName={handleInputVariableName}
                      handleContactInputs={handleContactInputs}
                      dataWithVariables={dataWithVariables}
                    />
                  </div>
                );
              }}
            </Draggable>
          );
        }

        case "slider": {
          return (
            <Draggable key={step.id} draggableId={step.id} index={index}>
              {(provided) => {
                return (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    key={step.id}
                  >
                    <AddContactSliderField
                      step={step}
                      key={index}
                      index={index}
                      id={id}
                      deleteInput={deleteInputFromContact}
                      toggleError={toggleErrorMessageInput}
                      handleInputName={handleInputName}
                      handleInputVariableName={handleInputVariableName}
                      handleContactInputs={handleContactInputs}
                      dataWithVariables={dataWithVariables}
                    />
                  </div>
                );
              }}
            </Draggable>
          );


        }
      }
    });
  };

  const toggleErrorMessageInput = (input) => {
    const {
      required,
      withValidation,
      errorMessage,
      type,
      fileType = null,
    } = input;
    if (
      required ||
      withValidation ||
      type === "phoneNumber" ||
      (type === "file" && (fileType == "predefined" || fileType == "custom"))
    ) {
      return (
        <>
          <label>Error Message:</label>
          <input
            type="text"
            name="errorMessage"
            value={errorMessage}
            onChange={(e) => handleContactInputs(e, input.id, id)}
            className="form-control"
          />
        </>
      );
    } else return <></>;
  };

  const addLocationInputs = () => {
    const { id: contactId } = props.data.onEdit;
    const id = `L_${Math.round(Math.random() * 999999)}`;
    const label = `Location`;
    const inputsLabels = [
      "Street Address",
      "Street Address 2",
      "Country",
      "State/Region",
      "City",
      "Postal/Zip Code",
    ];

    const inputs = inputsLabels.map((label) => {
      let validationType = "text";
      if (label === "Country") validationType = "country";
      if (label === "Postal/Zip Code") validationType = "postal";
      return {
        id: `L_I_${Math.round(Math.random() * 999999)}`,
        label,
        name: `L_I_N_${Math.round(Math.random() * 999999)}`,
        validationType,
        placeholder: "",
        type: label === "Country" ? label : "text",
        locationType: label,
        required: false,
        show: true,
        errorMessage:
          label === "Postal/Zip Code"
            ? "Postal/Zip Code is not valid."
            : "Field is required.",
      };
    });

    const newLocationContainer = { id, label, inputs, type: "location" };
    addInputToContact(newLocationContainer, contactId);
    setIsAddNew(false);
  };

  const addInput = (type) => {
    const { id: contactId } = props.data.onEdit;
    inputs = Object.values(inputs) ? Object.values(inputs) : [];
    // let label = "";
    const label =
      type === "signature"
        ? `Signature${inputs.length}`
        : `Item${inputs.length}`;
    const id = `I_${Math.round(Math.random() * 999999)}`;
    const name = `I_N_${Math.round(Math.random() * 999999)}`;
    addInputToContact(
      {
        id,
        name,
        validationType: "other",
        label,
        placeholder: "",
        type,
        required: false,
        isSignature: type === "signature" ? true : false,
        errorMessage: type === "file" ? "This file type is not allowed" : null,
        isFile: type === "file" ? true : false,
      },
      contactId
    );
    setIsAddNew(false);
  };

  const handleInputName = (input, index) => {
    let { inputs } = props.data.onEdit;

    inputs = Object.values(inputs) ? Object.values(inputs) : [];
    if (!input.label || !input.label.trim())
      return `Item-${inputs[index].type}-${index + 1}`;
    return input.label;
  };

  const handleInputVariableName = (e, inputId, stepId) => {
    const { arrayOfData } = props.data;
    const { name, value } = e.target;
    const contactIndex = arrayOfData.findIndex((step) => step.id === stepId);
    const isVariableNameExists = arrayOfData.find((itr, index) => {
      if (itr.type !== "contact") return null;
      if (index == contactIndex) {
        return Object.values(itr.inputs).find((input, i) => {
          if (input.id == inputId) return null;
          return input.name == value;
        });
      }
      return Object.values(itr.inputs).find((input) => input.name === value);
    });
    if (isVariableNameExists) {
      handleContactInputs(
        { target: { name, value: `${value}_1` } },
        inputId,
        stepId
      );
    }
  };

  const onDragEnd = ({ source, destination }) => {
    if (!source || !destination) return;
    changeInputIndexes(source.index, destination.index, id);
  };

  return (
    <>
      <div className="fieldList line">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="formContact">
            {(provided, snapshot) => {
              return (
                <div
                  className="content-list"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {drawInputsFields()}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>

        <a
          type="button"
          className="add pointer"
          onClick={() => setIsAddNew(true)}
        >
          <span>+</span> Add New
        </a>
        <div
          className="addNewList"
          style={{ display: isAddNew ? "block" : "none" }}
        >
          <a
            type="button"
            className="add pointer"
            onClick={() => addInput("text")}
          >
            <MdOutlineInput size={20} color="black" /> Input
          </a>

          <a
            type="button"
            className="add pointer"
            onClick={() => addInput("select")}
          >
            <MdOutlineArrowDropDownCircle size={20} color="black" /> DropDown
          </a>

          <a
            type="button"
            className="add pointer"
            onClick={() => addInput("date")}
          >
            <MdOutlineDateRange size={20} color="black" /> Date
          </a>

          <a
            type="button"
            className="add pointer"
            onClick={() => addInput("checkbox")}
          >
            <MdOutlineCheckBox size={20} color="black" /> Checkbox
          </a>

          <a
            type="button"
            className="add pointer"
            onClick={() => addLocationInputs()}
          >
            <MdOutlineLocationOn size={20} color="black" /> Location{" "}
          </a>

          <a
            type="button"
            className="add pointer"
            onClick={() => addInput("signature")}
          >
            <FaSignature size={20} color="black" /> signature{" "}
          </a>

          <a
            type="button"
            className="add pointer"
            onClick={() => addInput("file")}
          >
            <MdUploadFile size={20} color="black" /> File Upload{" "}
          </a>

          <a
            type="button"
            className="add Slider"
            onClick={() => addInput("slider")}
          >
            <RxSlider size={20} color="black" /> Slider{" "}
          </a>
        </div>
      </div>
    </>
  );
}

export default ContactFormLayout;
