import React from "react";
import { withTranslation } from "react-i18next";

function PaddingInput(props) {
  const {
    paddingValue,
    paddingDirection,
    selectedTheme,
    context,
    id,
    handleChange,
    changeMainStyle,
    translationContent,
    t,
    borderValue,
    defaultValueClass = "",
  } = props;
  return (
    <label>
      <input
        type="number"
        className={defaultValueClass}
        name="padding"
        defaultValue={paddingValue}
        value={paddingValue}
        onChange={(e) => {
          selectedTheme == "override-edit"
            ? (function (e) {
                handleChange(
                  { target: { name: "padding", value: e.target.value } },
                  id,
                  context,
                  paddingDirection
                );
              })(e)
            : (function (e) {
                changeMainStyle(e, context, paddingDirection);
                handleChange(e, context, paddingDirection);
              })(e);
        }}
        border={borderValue}
      />
      {t(translationContent)}
    </label>
  );
}

export default withTranslation()(PaddingInput);
