import React, { useState, useEffect, useRef } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import { MdFileOpen, MdClose, MdFolder } from "react-icons/md";
import { truncate } from "../../../utils";
import { apiUrl } from "../../../config";
import { mainAxios } from "../../../Axios/mainAxios";
import { connect } from "react-redux";

function FileInput(props) {
  const {
    color = "#000000",
    minimalStyle,
    defaultValue,
    isPreview,
    placeholder,
    isRtl,
    name,
    inputId,
    inputStyle,
    errorMessage,
    inputValid,
    isValid,
    required,
    handlevalidationInputChanges,
    width,
    isMobile,
    isMobilePreview,
    maxNumberOfFiles,
    maxFileErrorMessage,
    maxFileSize,
    minFileSize,
    customFileSize,
    customFileTypes,
    fileType,
    predifinedFileType,
    dataWithVariables,
    label,
    quizId,
    isPreviewDesignMode,
  } = props;

  const dropzoneRef = useRef({});
  const [defaultVal, setDefaultVal] = useState(defaultValue);
  const [filesArray, setFilesArray] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isMaxFileError, setIsMaxFileError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (defaultValue && dataWithVariables) {
      setFilesArray(defaultValue);
      const fileError = handleError(defaultValue);
      handlevalidationInputChanges(defaultValue, inputId, fileError);
    }
  }, [defaultValue, dataWithVariables]);

  const handleAddFiles = (acceptedFiles) => {
    if (isPreviewDesignMode) return;
    // setFilesArray([...filesArray, ...acceptedFiles])
    const fileError = handleError([...filesArray, ...acceptedFiles]);
    if (!fileError && isPreview) uploadFiles([...filesArray, ...acceptedFiles]);
    handlevalidationInputChanges(
      [...filesArray, ...acceptedFiles],
      inputId,
      fileError
    );
  };

  const openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  const uploadFiles = async (array) => {
    const formData = new FormData();
    const visitGuid =
      sessionStorage.getItem("visitKey") || props.visitKey || "";
    const headers = { visitGuid, qlabel: label, quizId };
    array.forEach(async (file) => {
      if (file) {
        formData.append("uploaded_file", file, file.name);
      }
    });
    setIsLoading(true);
    const { data } = await mainAxios.post(
      `${apiUrl}/images/uploadFormFiles`,
      formData,
      { headers }
    );

    if (!data.success) return;
    array.forEach((file, i) => {
      file.savedPath = data.pathes[i];
    });
    setFilesArray(array);
    setIsLoading(false);
  };

  const handleError = (filesArray) => {
    if (!filesArray.length && required) {
      setIsError(true);
      return true;
    } else {
      setIsError(false);
    }

    if (maxNumberOfFiles && filesArray.length > maxNumberOfFiles) {
      setIsError(true);
      return true;
    } else {
      setIsError(false);
    }
    if (customFileSize) {
      if (maxFileSize) {
        for (let i = 0; i < filesArray.length; i++) {
          if (filesArray[i].size / 1000 > maxFileSize) {
            setIsMaxFileError(true);
            return true;
          } else {
            setIsMaxFileError(false);
          }
        }
      }
      if (minFileSize) {
        for (let i = 0; i < filesArray.length; i++) {
          if (filesArray[i].size / 1000 < minFileSize) {
            setIsMaxFileError(true);
            return true;
          } else {
            setIsMaxFileError(false);
          }
        }
      }
    }
    if (fileType == "custom") {
      if (customFileTypes) {
        const typesArray = customFileTypes.split(",");
        if (typesArray.length) {
          for (let i = 0; i < filesArray.length; i++) {
            const fileExtension = filesArray[i].name.split(".").pop();
            if (!typesArray.includes(`${fileExtension}`)) {
              setIsError(true);
              return true;
            } else {
              setIsError(false);
            }
          }
        }
      }
      return false;
    }

    if (fileType == "predefined") {
      if (predifinedFileType) {
        const constantFileTypes = {
          images: [".png", ".jpg", ".jpeg", ".gif", ".svg"],
          docs: [
            ".xlsx",
            ".xls",
            ".doc",
            ".docx",
            ".ppt",
            ".pptx",
            ".pdf",
            ".txt",
          ],
          compressed: [".zip", ".rar", ".7z"],
        };
        for (let i = 0; i < filesArray.length; i++) {
          const fileExtension = filesArray[i].name.split(".").pop();
          if (
            !constantFileTypes[predifinedFileType].includes(`.${fileExtension}`)
          ) {
            return setIsError(true);
          } else {
            setIsError(false);
          }
        }
      }
    }
  };

  return (
    <>
      {isPreview ? (
        <>
          <Dropzone
            ref={dropzoneRef}
            onDrop={(acceptedFiles) => handleAddFiles(acceptedFiles)}
            noClick={true}
          >
            {/* maxFiles={1}
            // maxSize={100000}>
            > */}
            {({ getRootProps, getInputProps, open }) => (
              <section>
                <div
                  style={{
                    minHeight: "100px",
                    border: "solid 1px black",
                    backgroundColor: "white",
                  }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div className="d-flex align-items-baseline justify-content-center m-auto">
                    <p>
                      Drag & drop files or{" "}
                      <span
                        className="text-decoration-underline"
                        onClick={openDialog}
                      >
                        Browse
                      </span>
                    </p>
                    <button
                      className="btn btn-light"
                      type="button"
                      onClick={openDialog}
                    >
                      <MdFolder size={30} color={"#A2AEBD"} />
                    </button>
                  </div>
                  {!filesArray || !filesArray.length ? (
                    <></>
                  ) : (
                    <div className="d-flex">
                      {filesArray.map((f, i) => {
                        return (
                          <div
                            style={{
                              width: "fit-content",
                              marginRight: "10px",
                            }}
                            className="d-flex flex-column "
                          >
                            <div style={{ width: "fit-content" }} className="">
                              <MdFileOpen size={30} color={"#1B45AE"} />
                              <MdClose
                                size={20}
                                color={"#1B45AE"}
                                onClick={(e) => {
                                  const temp = Object.assign([], filesArray);
                                  temp.splice(i, 1);
                                  // setFilesArray(temp)
                                  const fileError = handleError(temp);
                                  if (!fileError && isPreview)
                                    uploadFiles(temp);
                                  handlevalidationInputChanges(
                                    temp,
                                    inputId,
                                    fileError
                                  );
                                }}
                              />
                            </div>
                            <span
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {truncate(f.name, 15)}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {isLoading ? (
                    <>
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="spinner-border" role="status"></div>{" "}
                        <span className=""> &nbsp; Loading...</span>
                      </div>{" "}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </section>
            )}
          </Dropzone>
        </>
      ) : (
        <>
          <Dropzone
            ref={dropzoneRef}
            onDrop={(acceptedFiles) => handleAddFiles(acceptedFiles)}
            noClick={true}
          >
            {/* maxFiles={1}
            // maxSize={100000}>
            > */}
            {({ getRootProps, getInputProps, open }) => (
              <section>
                <div
                  style={{
                    minHeight: "100px",
                    border: "solid 1px black",
                    backgroundColor: "white",
                  }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div className="d-flex align-items-baseline justify-content-center m-auto">
                    <p>
                      Drag & drop files or{" "}
                      <span
                        className="text-decoration-underline"
                        onClick={openDialog}
                      >
                        Browse
                      </span>
                    </p>
                    <button
                      className="btn btn-light"
                      type="button"
                      onClick={openDialog}
                    >
                      <MdFolder size={30} color={"#A2AEBD"} />
                    </button>
                  </div>
                  {!filesArray || !filesArray.length ? (
                    <></>
                  ) : (
                    <div className="d-flex">
                      {filesArray.map((f, i) => {
                        return (
                          <div
                            style={{ width: "fit-content", width: "20%" }}
                            className="d-flex flex-column "
                          >
                            <div style={{ width: "fit-content" }} className="">
                              <MdFileOpen size={30} color={"#1B45AE"} />
                              <MdClose
                                size={20}
                                color={"#1B45AE"}
                                onClick={(e) => {
                                  const temp = Object.assign([], filesArray);
                                  temp.splice(i, 1);
                                  handleError(temp);
                                  setFilesArray(temp);
                                }}
                              />
                            </div>
                            <span
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {truncate(f.name, 15)}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </section>
            )}
          </Dropzone>
        </>
      )}

      {isError ? (
        <span style={{ color: "red" }} className="req-error">
          {errorMessage}
        </span>
      ) : (
        <></>
      )}
      {isMaxFileError ? (
        <span style={{ color: "red" }} className="req-error">
          {maxFileErrorMessage || "File size is too large or too small"}
        </span>
      ) : (
        <></>
      )}

      {/* {inputValid || isValid ? (
            <></>
        ) : (
            <span style={{ color: "red" }} className="req-error">
                {errorMessage}
            </span>
        )} */}
    </>
  );
}
const mapStateToiProps = (state) => {
  const { visitKey } = state;
  return visitKey;
};

export default connect(mapStateToiProps, null)(FileInput);
