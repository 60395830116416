import React from "react";
import { withTranslation } from "react-i18next";

class ProductsEdit extends React.Component {
  render() {
    const { id, handleChangeQuestion } = this.props;
    const {
      type,
      resultType,
      maxProductsOnPage,
      defaultProductLink,
      linkButtonText,
      showProductPrice = true,
      showProductDescription = true,
      showProductCTA = true,
      showProductCategory = false,
    } = this.props.data.onEdit;
    return (
      <div className="">
        {type == "result" && resultType == "ecom" ? (
          <>
            <div className="addSwitch">
              <label className="switch">
                <input
                  type="checkbox"
                  name="showProductPrice"
                  checked={showProductPrice}
                  onChange={(e) => handleChangeQuestion(e, id)}
                />
                <span className="slider round"></span>
              </label>
              Show Price
            </div>
            <div className="addSwitch">
              <label className="switch">
                <input
                  type="checkbox"
                  name="showProductDescription"
                  checked={showProductDescription}
                  onChange={(e) => handleChangeQuestion(e, id)}
                />
                <span className="slider round"></span>
              </label>
              Show Description
            </div>
            <div className="addSwitch">
              <label className="switch">
                <input
                  type="checkbox"
                  name="showProductCategory"
                  checked={showProductCategory}
                  onChange={(e) => handleChangeQuestion(e, id)}
                />
                <span className="slider round"></span>
              </label>
              Show Category
            </div>
            <div className="addSwitch">
              <label className="switch">
                <input
                  type="checkbox"
                  name="showProductCTA"
                  checked={showProductCTA}
                  onChange={(e) => handleChangeQuestion(e, id)}
                />
                <span className="slider round"></span>
              </label>
              Show product link button
            </div>
            <div className="addSwitch">
              <label>Max products on page</label>
              <select
                name="maxProductsOnPage"
                className="form-control"
                onChange={(e) => {
                  handleChangeQuestion(e, id);
                }}
                value={maxProductsOnPage}
              >
                {Array(15)
                  .fill()
                  .map((itr, i) => (
                    <option value={i + 1}>{i + 1}</option>
                  ))}
              </select>
              {/* <Slider
                  min={1}
                  max={15}
                  value={maxProductsOnPage}
                  onChange={(value) => {
                    handleChangeQuestion(
                      { target: { value, name: "maxProductsOnPage" } },
                      id
                    );
                  }}
                />
                <span>{maxProductsOnPage}</span> */}
            </div>
            <div className="addSwitch">
              <label>Default product link</label>
              <input
                type="text"
                value={defaultProductLink}
                name="defaultProductLink"
                onChange={(e) => handleChangeQuestion(e, id)}
                className="form-control"
              />
            </div>

            <div className="addSwitch">
              <label>Link button text</label>
              <input
                type="text"
                value={linkButtonText}
                name="linkButtonText"
                placeholder="Go to product"
                onChange={(e) => handleChangeQuestion(e, id)}
                className="form-control"
              />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default withTranslation()(ProductsEdit);
