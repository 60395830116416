import React, { useState } from "react";
import { Modal, Button, Dropdown } from "react-bootstrap";
import { websiteUrl } from "../../../config";
import Conditions from "./Conditions";
import "./logic.css";
import { withTranslation } from "react-i18next";
import { mainAxios } from "../../../Axios/mainAxios";
import ConditionLine from "./ConditionLine";
import FeatureUnavailable from "../../Subscriptions/FeatureUnavailable";
class LogicModal extends React.Component {
  state = {
    show: this.props.isShow,
    logic: {},
    logics: { before: [], after: [] },
    stepId: null,
    isPermited: true,
  };

  componentDidMount() {
    this.props.step.logics = this.props.logics;
    // this.props.get
    let {
      title,
      id,
      options,
      type,
      inputs: contacts,
      logics = { before: [], after: [] },
    } = this.props.step;
    logics = JSON.parse(JSON.stringify(logics));

    const { logic } = this.state;
    const { arrayOfData, permitions } = this.props;
    options = options ? options : [];
    contacts = contacts ? contacts : [];

    const allContacts = arrayOfData.reduce((obj, step) => {
      if (step.type !== "contact") return obj;
      step.inputs.forEach((input) => {
        obj[input.name] = input;
      });
      return obj;
    }, {});

    if (type === "question") {
      this.setState({
        permitions,
        logic: {
          ...logic,
          [id]: {
            id,
            title,
            type,
            options,
            match: "",
            redirectTo: options[0].id,
          },
        },
        stepId: id,
        logics,
        allContacts,
      });
    }
    if (type === "contact") {
      this.setState({
        logic: {
          ...logic,
          [id]: { id, type, title, contacts, match: "", redirectTo: "" },
        },
        stepId: id,
        logics,
        allContacts,
      });
    }
    if (type === "start" || type === "text") {
      this.setState({
        logic: {
          ...logic,
          [id]: { id, type, title, match: "", redirectTo: "" },
        },
        stepId: id,
        logics,
        allContacts,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.logics !== this.props.logics) {
      this.setState({ logics: this.props.logics });
    }
  }

  // handleShow = (e) => {
  //   e.preventDefault();
  //   this.setState({ show: true });
  // };

  handleClose = () => {
    let { logics = {} } = this.props.step;
    logics = JSON.parse(JSON.stringify(logics));
    this.props.resetLogics();
    this.setState({ logics, show: false });
    // this.setState({ show: false });
  };

  handleSave = () => {
    const { stepId, logics } = this.state;
    if (JSON.stringify(logics) !== JSON.stringify(this.props.step.logics)) {
      this.props.saveStepLogics(stepId, logics);
    }

    this.handleClose();
  };

  handleReset = () => {
    let { logics } = this.props.step;
    if (JSON.stringify(logics) !== JSON.stringify(this.props.step.logics)) {
      logics = JSON.parse(JSON.stringify(logics));
      this.setState({ logics });
    }
    this.handleClose();
  };

  handleChange = (e) => {
    const { value, name } = e.target;
    const { logic } = this.state;
    let { id } = this.props.step;
    logic[id] = { [name]: value };
    this.setState({ logic });
  };

  addRule = (logicIndex, position) => {
    const { logics, stepId } = this.state;

    const conditionObj = {
      id: `L${Math.random() * 9999999}`,
      conditionCase: "",
      sectionId: "UTM_Source",
      operation: "==",
      value: "",
      operator: "&&",
      validationType: "UTM",
      then: "jump",
      to: "",
      redirectUrl: websiteUrl,
    };
    logics[position][logicIndex].conditions.push(conditionObj);
    this.setState({ logics });
  };

  addNewLogic = (position) => {
    const { logics, stepId } = this.state;
    const { arrayOfData } = this.props;
    const findStep = arrayOfData.findIndex((step) => step.id === stepId);
    const selectedStepId = arrayOfData[findStep + 1].id;
    if (!logics[position]) {
      logics[position] = [];
    }
    logics[position].push({
      conditions: [
        {
          id: `L${Math.random() * 9999999}`,
          conditionCase: "",
          sectionId: "UTM_Source",
          operation: "==",
          value: "",
          validationType: "UTM",
          then: "jump",
          to: "",
          redirectUrl: websiteUrl,
        },
      ],
      then: "jump",
      to: selectedStepId,
      redirectUrl: websiteUrl,
    });
    this.setState({ logics });
  };

  handleContactList = () => {
    const { arrayOfData } = this.props;
    return Object.values(
      arrayOfData.reduce((obj, step) => {
        if (step.type !== "contact") return obj;
        step.inputs.forEach((input) => {
          input.slideName = step.slideName;
          if (input.type === "location") {
            input.inputs.forEach((itr) => {
              itr.slideName = step.slideName;
              if (itr.show) obj[itr.name] = itr;
            });
          } else obj[input.name] = input;
        });
        return obj;
      }, {})
    );
  };

  handleStepLogic = (
    e,
    selectedConditionPosition,
    conditionIndex,
    logicIndex
  ) => {
    const { logics } = this.state;
    const { name, value } = e.target;
    logics[selectedConditionPosition][logicIndex].conditions[conditionIndex][
      name
    ] = value;
    const validationType =
      e.target.tagName === "SELECT"
        ? e.target.options[e.target.selectedIndex].parentNode.label
        : null;
    if (validationType) {
      logics[selectedConditionPosition][logicIndex].conditions[
        conditionIndex
      ].validationType = validationType;
      logics[selectedConditionPosition][logicIndex].conditions[
        conditionIndex
      ].operation = "==";
      logics[selectedConditionPosition][logicIndex].conditions[
        conditionIndex
      ].value = "";
    }
    this.setState({ logics });
  };

  removeConditionFromStep = (
    logicIndex,
    conditionIndex,
    selectedConditionPosition
  ) => {
    const { logics } = this.state;
    logics[selectedConditionPosition][logicIndex].conditions.splice(
      conditionIndex,
      1
    );
    this.setState({ logics });
  };

  handleLogicDetails = (e, logicIndex, position) => {
    const { name, value } = e.target;
    const { arrayOfData, variables = [], customScripts = [] } = this.props;
    const { logics } = this.state;
    logics[position][logicIndex][name] = value;
    if (
      name === "then" &&
      (value === "numeric" || value === "variable" || value === "script")
    ) {
      if (value === "numeric") {
        const selectedVar = variables.find(
          (variable) => variable.type === "number"
        );
        if (selectedVar) logics[position][logicIndex].to = selectedVar.name; // Added if statement that check if we found the relevant variable.
      } else if (value === "variable") {
        const selectedVar = variables.find(
          (variable) => variable.type !== "number"
        );
        if (selectedVar) logics[position][logicIndex].to = selectedVar; // Added if statement that check if we found the relevant variable.
      } else if (value === "script" && customScripts.length) {
        logics[position][logicIndex].to = customScripts[0].id;
      }
    }
    this.setState({ logics });
  };

  removeLogic = (logicIndex, selectedConditionPosition) => {
    const { logics } = this.state;
    logics[selectedConditionPosition].splice(logicIndex, 1);
    this.setState({ logics });
  };

  showConditions = (step, logic, selectedConditionPosition, logicIndex) => {
    const { arrayOfData, variables = [], customScripts = [] } = this.props;
    const { questionType, inputs: contacts } = this.props.step;

    const matrixOptions = arrayOfData.reduce((array, itr) => {
      if (itr.type != "question") {
        return array;
      }
      const { slideName } = itr;
      if (itr.questionType == "matrix") {
        itr.matrix.rows.forEach((row) => {
          array.push({
            title: `${slideName} -> ${row.value.replace(/(<([^>]+)>)/gi, "")}`,
            id: row.id,
          });
        });
      }
      return array;
    }, []);
    return logic.conditions.map((condition, conditionIndex) => {
      const { t } = this.props;
      const { allContacts = {} } = this.state;
      const {
        id,
        type,
        sectionId,
        operation,
        conditionCase,
        validationType,
        value,
        operator,
      } = condition;

      let currentStep = arrayOfData.find((itr) => itr.id == sectionId);
      if (validationType == "Questions" && !currentStep) return <></>;
      if (validationType == "Matrix") {
        currentStep = arrayOfData.find(
          (itr) =>
            itr.questionType == "matrix" &&
            itr.matrix.rows.find((row) => row.id == sectionId)
        );
      }

      return (
        <div key={id} className="d-flex justify-content-around">
          <div key={id} className=" d-flex justify-content-around col-2">
            {conditionIndex ? (
              <div>
                <select
                  name="operator"
                  value={operator}
                  className="form-control"
                  onChange={(e) =>
                    this.handleStepLogic(
                      e,
                      selectedConditionPosition,
                      conditionIndex,
                      logicIndex
                    )
                  }
                >
                  <option value={"&&"}>
                    {t("leftBar-welcome-logicModal-and")}
                  </option>
                  <option value={"||"}>
                    {t("leftBar-welcome-logicModal-or")}
                  </option>
                </select>
              </div>
            ) : (
              // <></>
              <label className="">
                {t("leftBar-welcome-logicModal-response-to")}
              </label>
            )}
          </div>
          <div
            className="d-flex col-9 row"
            style={{ maxHeight: "219px", minHeight: "60px" }}
          >
            <div className="col-4">
              <select
                name="sectionId"
                className="form-control product-logic-input"
                value={sectionId}
                onChange={(e) =>
                  this.handleStepLogic(
                    e,
                    selectedConditionPosition,
                    conditionIndex,
                    logicIndex
                  )
                }
              >
                <option value="" disabled>
                  {t("leftBar-welcome-logicModal-select")}
                </option>
                <optgroup label="Questions">
                  {arrayOfData.map((itr, index) => {
                    if (itr.type != "question" || itr.questionType == "matrix")
                      return <></>;

                    return (
                      <option key={itr.id} value={itr.id}>
                        {itr.slideName}
                      </option>
                    );
                  })}
                </optgroup>
                <optgroup label="Matrix">
                  {matrixOptions.map((itr, index) => {
                    return (
                      <option key={itr.id} value={itr.id}>
                        {itr.title}
                      </option>
                    );
                  })}
                </optgroup>
                <optgroup label="Variables">
                  {variables.map((variable, index) => {
                    const { name, type } = variable;
                    if (type == "UTM") return <></>;
                    return (
                      <option key={index} value={name}>
                        {name}
                      </option>
                    );
                  })}
                </optgroup>
                <optgroup label="UTM">
                  {variables.map((variable, index) => {
                    const { name, type } = variable;
                    if (type != "UTM") return <></>;
                    return (
                      <option key={index} value={name}>
                        {name}
                      </option>
                    );
                  })}
                </optgroup>
                <optgroup label="Form Answers">
                  {this.handleContactList().map((variable) => {
                    const { label, name, slideName } = variable;
                    return (
                      <option key={name} value={label}>
                        {`${slideName} ->`} {label}
                      </option>
                    );
                  })}
                </optgroup>
                <optgroup label="System">
                  <option key={"score"} value={"score"}>
                    {"score"}
                  </option>
                </optgroup>
              </select>
            </div>
            <ConditionLine
              handleStepLogic={this.handleStepLogic}
              selectedConditionPosition={selectedConditionPosition}
              conditionIndex={conditionIndex}
              logicIndex={logicIndex}
              operation={operation}
              currentStep={currentStep}
              t={t}
              value={value}
              conditionType={
                currentStep && validationType == "Questions"
                  ? currentStep.questionType
                  : validationType
              }
              sectionId={sectionId}
              contacts={allContacts}
              score={this.props.score}
            />
          </div>

          {logic.conditions.length !== 1 && (
            <div className="col-1 d-flex justify-content-between">
              {/* <a title="" className="down"></a> */}
              <a
                title=""
                className="trash"
                onClick={() =>
                  this.removeConditionFromStep(
                    logicIndex,
                    conditionIndex,
                    selectedConditionPosition
                  )
                }
              ></a>
            </div>
          )}
        </div>
      );
    });
  };

  changeLogicsDefaultRedirect = (moveToStep, position) => {
    const { logics } = this.state;
    const { otherCasesRedirect = { after: "", before: "" } } = logics;
    otherCasesRedirect[position] = moveToStep;
    logics.otherCasesRedirect = otherCasesRedirect;
    this.setState({ logics });
  };

  render() {
    let { id, options } = this.props.step;
    const { logics } = this.state;

    // const logics = this.props;
    options = options ? options : [];
    if (!this.state.logic[id]) return <></>;
    let { t, customScripts = [] } = this.props;

    if (!this.state.isPermited) {
      return (
        <>
          <FeatureUnavailable
            isShow="true"
            hide={() => this.setState({ isPermited: true })}
            feature="logic"
          />
        </>
      );
    }
    return (
      <>
        <Dropdown.Item
          // active
          // disabled={!this.props.permitions.logics}
          // color="danger"

          onClick={() => {
            if (!this.props.permitions.logics)
              return this.setState({ isPermited: false });
            this.props.getStepLogic(this.props.quizId, id);
            this.setState({ show: true });
          }}
        >
          <i className="bi bi-shuffle left-bar-dropdown-item-icon"></i>
          {t("leftBar-dropdown-icons-logic")}
          {!this.props.permitions.logics ? (
            <i class="bi bi-lock-fill m-2"></i>
          ) : (
            <></>
          )}
        </Dropdown.Item>
        <Modal show={this.state.show} size="xl" onHide={this.handleClose}>
          <Modal.Header>
            {/* <Modal.Title className="logic"> */}
            <h2>{t("leftBar-welcome-logicModal-title")}</h2>{" "}
          </Modal.Header>
          <Modal.Body className="modal-logic">
            <Conditions
              {...this.props}
              changeLogicsDefaultRedirect={this.changeLogicsDefaultRedirect}
              removeLogic={this.removeLogic}
              handleChange={this.handleChange}
              handleLogicDetails={this.handleLogicDetails}
              removeConditionFromStep={this.removeConditionFromStep}
              handleStepLogic={this.handleStepLogic}
              handleContactList={this.handleContactList}
              addNewLogic={this.addNewLogic}
              addRule={this.addRule}
              showConditions={this.showConditions}
              logics={logics}
              customScripts={customScripts}
            />
          </Modal.Body>
          <Modal.Footer className="w-100 p-0">
            <div className="popupFooter justify-content-between">
              <div className="d-flex">
                <input
                  type="button"
                  value={t("general-cancel")}
                  onClick={this.handleClose}
                  className="btn-popup-cancel"
                />
                <span
                  className="close close_multi"
                  disabled={
                    JSON.stringify(logics) ===
                    JSON.stringify(this.props.step.logics)
                  }
                  onClick={this.handleReset}
                >
                  {t("leftBar-welcome-logicModal-reset")}
                </span>
              </div>

              <input
                type="button"
                value={t("leftBar-welcome-logicModal-save")}
                className={
                  JSON.stringify(logics) ===
                  JSON.stringify(this.props.step.logics)
                    ? "btn-popup-cancel"
                    : "btn-popup"
                }
                disabled={
                  JSON.stringify(logics) ===
                  JSON.stringify(this.props.step.logics)
                }
                onClick={this.handleSave}
              />
            </div>
            {/* </div> */}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default withTranslation()(LogicModal);
