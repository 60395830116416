import React, { useState, useEffect, useRef } from "react";
import Navbar from "../Navbar";
import { withTranslation } from "react-i18next";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import PersonalDetails from "./personalDetails";
import {
  changeLanguage,
  getUserInfoActions,
  updateUserInfoActions,
  updateUserPasswordActions,
  setGtmStatusAction,
} from "../../redux/actions";
import i18n from "../../translate/i18n";
import { connect } from "react-redux";
import "./account.css";
import handleHighlightService from "../../utils/recoredSessionService";

const AccountPage = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [languagePreference, setLanguagePreference] = useState(
    localStorage.getItem("language")
  );
  const [toggleTab, setToggleTab] = useState("Personal");

  const {
    t,
    getUserInfo,
    profile,
    updateUserPassword,
    updateUserInfo,
    passwordUpdate,
    userId,
    gtmStatus,
    setGtmStatus,
    userName,
  } = props;
  const changePasswordHeader = t("account-change-password");

  const selectDefault = languagePreference === "he" ? `שפה` : `Language`;
  useEffect(() => {
    getUserInfo();
    if (userId && gtmStatus.page && gtmStatus.page !== "profile") {
      setGtmStatus({ page: "profile", userId, events: null });
      handleHighlightService(userId, userName);
    }
  }, []);

  useEffect(() => {
    if (!profile.first_name) return;
    setFirstName(profile.first_name);
    setLastName(profile.last_name);
  }, [profile]);

  const handleUpdateUserInfo = () => {
    handleLanguage(languagePreference);
    localStorage.setItem("language", languagePreference);
    updateUserInfo(firstName, lastName, languagePreference);
  };
  /* 
  const handlePasswordUpdate = () => {
    const oldPass = oldPassword.current.value;
    const newPass = newPassword.current.value;
    const newPassCheck = newPasswordCheck.current.value;
    oldPassword.current.value = "";
    newPassword.current.value = "";
    newPasswordCheck.current.value = "";
    if (oldPass === newPass || oldPass === "") {
      error1.classList.remove("hide");
      setInputCollapse(!inputCollapse);
      return;
    } else if (newPass !== newPassCheck || newPass === "") {
      error2.classList.remove("hide");
      setInputCollapse(!inputCollapse);
      return;
    } else {
      success.classList.remove("hide");
      return updateUserPassword(oldPass, newPass);
    }
  } */

  const handleLanguage = (value) => {
    changeLanguage(value);
    i18n.changeLanguage(value);
  };

  return (
    <div className="profile-page">
      <Navbar active="Profile" helpLabel={t("help-center")} />
      <div
        /*   className="profile-content profile-wrapper " */
        className="profile-content profile-wrapper"
      >
        <div className="tabs-wrapper">
          <Tabs
            activeKey={toggleTab}
            className="tab mb-3"
            id="controlled-tab-example"
            onSelect={(e) => setToggleTab(e)}
            justify
          >
            <Tab eventKey="Personal">
              <PersonalDetails
                details={profile}
                updateUserInfo={updateUserInfo}
                updateUserPassword={updateUserPassword}
                passwordUpdate={passwordUpdate}
                getUserinfo={getUserInfo}
              />
            </Tab>
            {/* <Tab eventKey="Subscription" title="Subscription">
              <h1>Subscription</h1>
            </Tab>
            <Tab eventKey="Invoice" title="Invoice">
              <h1>Invoice</h1>
            </Tab> */}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

const mapToProps = (state) => {
  const profile = state.profile;
  return {
    profile,
    passwordUpdate: state.updatedPassword,
    userId: state.loginDetails.user_id,
    gtmStatus: state.gtmStatus,
    userName: state.loginDetails.name,
  };
};
const mapDispatch = (dispatch) => {
  return {
    getUserInfo: () => {
      dispatch(getUserInfoActions());
    },
    updateUserInfo: (firstName, lastName, language) => {
      dispatch(updateUserInfoActions(firstName, lastName, language));
    },
    updateUserPassword: (oldPass, newPass) => {
      dispatch(updateUserPasswordActions(oldPass, newPass));
    },
    changeLanguage: (language) => dispatch(changeLanguage(language)),
    setGtmStatus: (status) => dispatch(setGtmStatusAction(status)),
  };
};

export default withTranslation()(connect(mapToProps, mapDispatch)(AccountPage));
