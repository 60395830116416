import React from "react";
import AddQuestion from "../Add/Question/AddQuestion";
import AddContact from "../Add/Contact/AddContact";
import AddResult from "../Add/Result/AddResult";
import StyleSettings from "../ThemeEdit/Settings";
import "./style.css";
import Result from "../Add/Result";
import Themes from "../Themes";
import ThemesTab from "../Add/Question/Themes";
import { withTranslation } from "react-i18next";
import ToolBar from "../ToolBar";
import { connect } from "react-redux";
import { changeRightBarView, saveAnswer } from "../../../redux/actions";
import { capitalizeFirstLetter } from "../../../utils";
import MyStyle from "./MyStyle/Index";

class RightBar extends React.Component {
  state = {
    tabToShow: "customize",
    themeDataImport: null,
  };
  componentDidUpdate() {
    const { onEdit } = this.props.data;

    // if (this.props.context !== "pageLayout" && this.state.tabToShow !== "design") {
    // this.setState({ tabToShow: "design" })
    // }
    //
    // document.getElementById("mainContent").scrollTop =
    //   document.getElementById(onEdit.id).offsetTop - 50;
  }

  setThemeData = (themeData) => {
    const { theme_data } = themeData;
    const TempSettings = Object.assign({}, this.props.data.settings);
    Object.keys(theme_data).forEach((key) => {
      if (!key) theme_data[key] = TempSettings[key];
    });
    this.props.changeTheme(themeData);
    // this.props.data.settings = theme_data;
  };

  handleCustomizeRender = () => {
    const type = this.props.data.rightBarActive;
    const {
      changeQuestionsOptionsDetails,
      handleChange,
      addOptionToQuestion,
      deleteFromArray,
      handleChangeContactInput,
      addInputToContact,
      deleteInputFromContact,
      data,
      handleContactInputs,
      updateMainSettings,
      changeLogoSettings,
      editBackgroundColors,
      addLinkToNav,
      changeNavs,
      removeLinkFromNav,
      deleteOptionFromQuestion,
      changeInputIndexes,
      handleQuestionFeedback,
      updateMainFeedbacks,
      handleChangeResultShare,
      handleShareDetails,
      handleQuestionStyle,
      handleGradientType,
      changeBackgroudnColorType,
      changeMainStyle,
      handleMatrixConfig,
      changeTheme,
      selectedTheme,
      addLocationsContainerToContact,
      deleteLocationContainerFromContact,
      handleContactLocationInputs,
      handleChangeMatrixData,
      handleChangeSliderData,
      handleSlidersTransitions
    } = this.props;
    switch (type) {
      case "start":
      case "text":
      case "result":
      case "question": {
        return (
          <AddQuestion
            data={data}
            changeQuestionsOptionsDetails={changeQuestionsOptionsDetails}
            handleChangeQuestion={handleChange}
            addOptionToQuestion={addOptionToQuestion}
            deleteFromArray={deleteFromArray}
            deleteOptionFromQuestion={deleteOptionFromQuestion}
            changeInputIndexes={changeInputIndexes}
            handleQuestionFeedback={handleQuestionFeedback}
            handleQuestionStyle={handleQuestionStyle}
            handleMatrixConfig={handleMatrixConfig}
            changeTheme={changeTheme}
            changeNavs={changeNavs}
            selectedTheme={selectedTheme}
            handleChangeMatrixData={handleChangeMatrixData}
            handleChangeSliderData={handleChangeSliderData}
          />
        );
      }
      case "result": {
        return (
          <Result
            data={data}
            handleChangeResult={handleChange}
            handleChangeResultShare={handleChangeResultShare}
            handleShareDetails={handleShareDetails}
          />
        );
      }
      case "contact": {
        return (
          <AddContact
            data={data}
            handleChangeContactDetails={handleChange}
            handleQuestionStyle={handleQuestionStyle}
            handleChangeContactInput={handleChangeContactInput}
            addInputToContact={addInputToContact}
            deleteInputFromContact={deleteInputFromContact}
            deleteFromArray={deleteFromArray}
            handleContactInputs={handleContactInputs}
            changeInputIndexes={changeInputIndexes}
            addLocationsContainerToContact={addLocationsContainerToContact}
            deleteLocationContainerFromContact={
              deleteLocationContainerFromContact
            }
            handleContactLocationInputs={handleContactLocationInputs}
          />
        );
      }
      case "styleSettings": {
        return (
          <Themes
            data={data}
            handleChange={handleChange}
            updateMainSettings={updateMainSettings}
            changeLogoSettings={changeLogoSettings}
            editBackgroundColors={editBackgroundColors}
            addLinkToNav={addLinkToNav}
            changeNavs={changeNavs}
            removeLinkFromNav={removeLinkFromNav}
            updateMainFeedbacks={updateMainFeedbacks}
            handleGradientType={handleGradientType}
            changeBackgroudnColorType={changeBackgroudnColorType}
            changeMainStyle={changeMainStyle}
            changeTheme={changeTheme}
          />
        );
      }
      default: {
        return (
          // <Start
          //   data={data}
          //   handleChange={handleChange}
          //   updateMainSettings={updateMainSettings}
          // />
          <></>
        );
      }
    }
  };

  render() {
    const { tabToShow } = this.state;
    const {
      changeQuestionsOptionsDetails,
      handleChange,
      addOptionToQuestion,
      deleteFromArray,
      handleChangeContactInput,
      addInputToContact,
      deleteInputFromContact,
      data,
      handleContactInputs,
      updateMainSettings,
      changeLogoSettings,
      editBackgroundColors,
      addLinkToNav,
      changeNavs,
      removeLinkFromNav,
      deleteOptionFromQuestion,
      changeInputIndexes,
      handleQuestionFeedback,
      updateMainFeedbacks,
      handleChangeResultShare,
      handleShareDetails,
      handleQuestionStyle,
      handleGradientType,
      changeBackgroudnColorType,
      changeMainStyle,
      handleMatrixConfig,
      changeTheme,
      selectedTheme,
      addLocationsContainerToContact,
      deleteLocationContainerFromContact,
      handleContactLocationInputs,
      handleChangeMatrixData,
      handleChangeSliderData,
    } = this.props;
    const { actions } = this.props;
    const { t } = this.props;
    return (
      <div className="rightbar">
        <div className="tabBar noselect">
          <a
            type="button"
            // onClick={() => this.setState({ tabToShow: "customize" })}
            onClick={() =>
              actions.changeRightBarView(this.props.context, "", "customize")
            }
            title="customize"
            id="rightbar-customize"
            className={
              this.props.rightBarViewTab == "customize" ||
              !this.props.rightBarViewTab
                ? "active"
                : ""
            }
          >
            {t("rightBar-top-customize")}
          </a>

          <a
            type="button"
            onClick={() =>
              actions.changeRightBarView(this.props.context, "", "design")
            }
            title="Design"
            id="rightbar-customize"
            className={this.props.rightBarViewTab == "design" ? "active" : ""}
            // style={this.props.context && this.props.context != "pageLayout" ? { } : {}}
          >
            {/* {this.props.context && this.props.context != "pageLayout" ? capitalizeFirstLetter(this.props.context.toLowerCase().replace("style", "")) : "design"} */}
            {/* {this.props.context && this.props.context != "pageLayout" ? `${this.props.context}-customize-tab` : "design"} */}

            {this.props.context && this.props.context != "pageLayout"
              ? t(`${this.props.context}-customize-tab`)
              : "Design"}
          </a>

          {/* <a
            type="button"
            // onClick={() => this.setState({ tabToShow: "themes" })}
            onClick={() =>
              actions.changeRightBarView(this.props.context, "", "themes")
            }
            title="themes"
            className={this.props.rightBarViewTab == "themes" ? "active" : ""}
          >
            {t("rightBar-top-theme")}
          </a> */}

          <a
            type="button"
            // onClick={() => this.setState({ tabToShow: "themes" })}
            onClick={() =>
              actions.changeRightBarView(this.props.context, "", "myStyle")
            }
            title="themes"
            className={
              this.props.rightBarViewTab === "myStyle" ? "myStyle active" : ""
            }
          >
            Style
          </a>
        </div>

        {this.props.rightBarViewTab === "customize" ||
        !this.props.rightBarViewTab ? (
          this.handleCustomizeRender()
        ) : (
          <></>
        )}
        {this.props.rightBarViewTab === "design" ? (
          <div className="overflow-auto h-100">
            <ToolBar
              data={data}
              changeQuestionsOptionsDetails={changeQuestionsOptionsDetails} //
              handleChange={handleChange}
              addOptionToQuestion={addOptionToQuestion}
              deleteFromArray={deleteFromArray}
              handleContactInputs={handleContactInputs}
              addInputToContact={addInputToContact}
              deleteInputFromContact={deleteInputFromContact}
              updateMainSettings={updateMainSettings}
              changeLogoSettings={changeLogoSettings}
              editBackgroundColors={editBackgroundColors}
              addLinkToNav={addLinkToNav}
              removeLinkFromNav={removeLinkFromNav}
              changeNavs={changeNavs}
              deleteOptionFromQuestion={deleteOptionFromQuestion}
              changeInputIndexes={changeInputIndexes}
              handleQuestionFeedback={handleQuestionFeedback}
              updateMainFeedbacks={updateMainFeedbacks}
              handleChangeResultShare={handleChangeResultShare}
              handleShareDetails={handleShareDetails}
              handleQuestionStyle={handleQuestionStyle}
              handleGradientType={handleGradientType}
              changeBackgroudnColorType={changeBackgroudnColorType}
              changeMainStyle={changeMainStyle}
              handleMatrixConfig={handleMatrixConfig}
              changeTheme={changeTheme}
              selectedTheme={this.props.selectedTheme}
              addLocationsContainerToContact={addLocationsContainerToContact}
              deleteLocationContainerFromContact={
                deleteLocationContainerFromContact
              }
              handleContactLocationInputs={handleContactLocationInputs}
              handleChangeMatrixData={handleChangeMatrixData}
              handleChangeSliderData={handleChangeSliderData}
            />
          </div>
        ) : (
          <></>
        )}
        {this.props.rightBarViewTab === "themes" ? (
          <ThemesTab
            changeTheme={this.props.changeTheme}
            selectedTheme={this.props.selectedTheme}
            settings={this.props.data.settings}
            changeMainStyle={this.props.changeMainStyle}
            updateMainSettings={this.props.updateMainSettings}
            data={this.props.data}
            changeNavs={this.props.changeNavs}
            addLinkToNav={this.props.addLinkToNav}
            removeLinkFromNav={this.props.removeLinkFromNav}
            changeRightBarView={actions.changeRightBarView}
            context={this.props.context}
            setThemeData={this.setThemeData}
          />
        ) : (
          <></>
        )}
        {this.props.rightBarViewTab === "myStyle" ? (
          <MyStyle
            changeTheme={this.props.changeTheme}
            selectedTheme={this.props.selectedTheme}
            settings={this.props.data.settings}
            changeMainStyle={this.props.changeMainStyle}
            updateMainSettings={this.props.updateMainSettings}
            data={this.props.data}
            changeNavs={this.props.changeNavs}
            addLinkToNav={this.props.addLinkToNav}
            removeLinkFromNav={this.props.removeLinkFromNav}
            changeRightBarView={actions.changeRightBarView}
            context={this.props.context}
            handleSlidersTransitions={this.props.handleSlidersTransitions}
          />
        ) : (
          <></>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { rightBarView, rightBarViewActive } = state;
  const { context = "" } = rightBarView;
  if (rightBarViewActive) {
    const { rightBarViewTab = "" } = rightBarViewActive;
    return { context, rightBarViewTab };
  }
  // console.log(state)

  return { context };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      changeRightBarView: (context, subContextId, rightBarViewTab) =>
        dispatch(changeRightBarView(context, subContextId, rightBarViewTab)),
    },
  };
};

// export default withTranslation()(RightBar);
// export default connect(mapStateToProps, null)(RightBar);
// export default compose(withTranslation, connect(mapStateToProps))(RightBar);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(RightBar));

// export default connect(mapStateToProps, null)(AddContact);
