import React from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import "../Analyze/analyze.css";
class Graph extends React.Component {
  render() {
    let calculate = {};
    this.props.data.map((visit) => {
      const itr = visit[this.props.toCalculate];

      calculate[itr] = isNaN(calculate[itr]) ? 1 : calculate[itr] + 1;
    });

    // tocalculate =  mobile/desktop \ browser

    let data = {
      labels: Object.keys(calculate),
      datasets: [
        {
          data: Object.values(calculate),
          backgroundColor:  ["#0046B5", "#03A6CA", "#BE1EB9","#e8eef1"],
          hoverBackgroundColor:  ["#0046B5", "#03A6CA", "#BE1EB9","#e8eef1"],
        },
      ],
    };

    const pieOptions = {
      autoPadding: false,
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: 0
        }
      },
      plugins: {
        legend: {
          // display: false,
          position: "right",
          // borderRadius: "10px",
          labels: {
            boxWidth: 10,
            borderRadius: 10,
            items: {
              borderRadius: 10,
            },
          },
        },
      },
    };
    // pieOptions.legend.item.borderRadius = 10;
    return (
      <div className="analyze-question-chart-wrapper">
        <div style={{ width: "auto", height: "250px" }}>
          <h4 style={{ textAlign: "center" }}>{this.props.label}</h4>
          <Doughnut data={data} options={pieOptions} />
        </div>
      </div>
    );
  }
}

export default Graph;
