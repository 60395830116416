import React, { useEffect, useState } from "react";
import { Modal, Button, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function FeatureUnavailable(props) {

    const [isShow, setIsShow] = useState(false);

    useEffect(() => {
        if (props.isShow) {
            setIsShow(true);
        }
        return () => props.hide()
    }, [props.isShow]);

    return (
        <div className="">
            <Modal show={isShow} size="md" onHide={() => props.hide()}>
                <Modal.Header>
                    <Modal.Title>This Feature Is Unavailable</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-logic">
                    To Upgrade your plan please check out our <Link to="/subscriptions"> Subcription page </Link>
                </Modal.Body>
                <Modal.Footer className="w-100 p-0">

                </Modal.Footer>
            </Modal>
        </div>
    );
}