import React from "react";
import { Redirect } from "react-router-dom";
import QuizItem from "./QuizItem";
import { withTranslation } from "react-i18next";
import { ReactComponent as PlusSign } from "./../../../img/plus.svg";
import { ThemeConsumer } from "react-bootstrap/esm/ThemeProvider";
class QuizesListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isNew: false };
  }
  render() {
    const { t, folder, wsId } = this.props;

    if (this.state.isNew)
      return (
        <Redirect
          to={{
            pathname: "/templates",
            state: { folder },
          }}
        />
      );
    return (
      <div className="dashboard-list">
        <button
          className="new"
          // onClick={() => {
          //   this.setState({ isNew: true });
          // }}
          onClick={() =>
            this.props.handleCreateNewBtnClick()
          }
        >
          {/*<PlusSign style={{ marginBottom: "20px" }} />*/}
          {t("dashboard-create-new")}
        </button>

        <div className="dashboard-list-table">
          <table cellPadding="0" cellsSacing="0" border="0">
            <thead>
              <tr>
                <th></th>
                <th> {t("dashboard-name")}</th>
                <th>{t("dashboard-date-created")}</th>
                <th>{t("dashboard-last-modified")}</th>
                {/*<th>{t("dashboard-results")} </th>*/}
                <th>{t("dashboard-status")}</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.props.quizes.map((quiz) => {
                return (
                  <QuizItem
                    key={quiz.id}
                    render={this.render}
                    data={quiz}
                    handleToggelModal={this.props.handleToggelModal}
                    wsId={wsId}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default withTranslation()(QuizesListContainer);
