import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
function AnalyzaNotPublish(props) {
  const { t } = props;

  // const [isActive, setIsActive] = useState(false);

  useEffect(() => {}, []);

  return (
    <div className="container">
      <div className="row">
        <section className="col-12 analyze-content h-100">
          <div className="filter row">
            <div className="col-9">
              {/* <SimpleReactCalendar
                          mode='range'
                          blockClassName='date_picker'
                          onSelect={({ start, end }) => {
                              console.log('>>>>>', { end, start })
                          }}
                      /> */}
            </div>
          </div>
          <div className="margin-analyse d-flex justify-content-center">
            <div className="tab-content" id="analyzeTabContent">
              <div className="d-flex justify-content-between">
                <h3>
                  {/*<strong> {t("analyze-tab-title")} </strong>*/}
                </h3>
                <div className="visit-detailes d-flex w-50 justify-content-between">
                  <span className="d-flex flex-column align-items-center">
                    <span>{t("analyze-total-visits")} </span>
                    <h4 style={{color: "#03A6CA"}}>0</h4>
                  </span>
                  <span className="d-flex flex-column align-items-center">
                    <span>{t("analyze-total-starts")} </span>
                    <h4 style={{color: "#03A6CA"}}>0</h4>
                  </span>
                  <span className="d-flex flex-column align-items-center">
                    <span>{t("analyze-total-finish")} </span>
                    <h4 style={{color: "#03A6CA"}}>0</h4>
                  </span>
                  <span className="d-flex flex-column align-items-center">
                    <span>{t("analyze-total-leads")} </span>
                    <h4 style={{color: "#03A6CA"}}>0</h4>
                  </span>
                  <span className="d-flex flex-column align-items-center">
                    <span>{t("analyze-total-score")}</span>
                    <h4 style={{color: "#03A6CA"}}>0</h4>
                  </span>
                </div>
              </div>
              <div
                className="tab-pane fade show active "
                id="graph"
                role="tabpanel"
                aria-labelledby="graph-tab"
              >
                <img src="/img/analyzepublish.png"></img>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
export default withTranslation()(AnalyzaNotPublish);
