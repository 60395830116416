import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DateTimePicker from "react-datetime-picker";
import DatePicker from "react-date-picker";
import moment from "moment";

function DateInput(props) {
  const {
    minimalStyle,
    change,
    currInput,
    isPreview,
    input,
    inputStyle,
    defaultValue,
    errorMessage,
    inputValid,
    isValid,
    color = "#000000",
  } = props;

  const { isTime, placeholder, isRange } = input;
  const [defaultVlueObj, setDefaultValueObj] = useState();
  const [defaultPlaceholder, setDefaultPlaceholder] = useState();
  const [value, setValue] = useState(null);
  const [daysDisabled, setDaysDisabled] = useState([]);
  const [ColorComp, setColorComp] = useState(styled.div``);

  useEffect(() => {
    if (defaultValue) {
      setDefaultValueObj(new Date(defaultValue));
    }
    if (placeholder) {
      setDefaultPlaceholder(new Date(placeholder));
    } else {
      setDefaultPlaceholder(null);
    }
  }, [defaultValue, placeholder]);

  useEffect(() => {
    if (input.daysDisabled) {
      setDaysDisabled(JSON.parse(input.daysDisabled));
    }
  }, [input.daysDisabled]);

  useEffect(() => {
    const StyledDateInput = minimalStyle
      ? styled.div`
          > div {
            color: ${color.slice(0, color.lastIndexOf(",")).replace(/,/g, "") +
            ") !important"};
            box-shadow: ${color
              .slice(0, color.lastIndexOf(","))
              .replace(/,/g, "") + " /30%) 0px 1px !important"};
          }
          > div div div input {
            color: ${color.slice(0, color.lastIndexOf(",")).replace(/,/g, "") +
            ") !important"};
            opacity: 1 !important;
          }
          > div div div input::placeholder {
            color: ${color.slice(0, color.lastIndexOf(",")).replace(/,/g, "") +
            ") !important"};
            opacity: 0.4;
          }
        `
      : styled.div``;

    setColorComp(StyledDateInput);
  }, [color, minimalStyle]);

  return (
    <ColorComp className={minimalStyle ? "minimaldate" : ""}>
      {isTime ? (
        <>
          <DateTimePicker
            onChange={(e) => {
              console.log(e);
              change(moment(e).format("YYYY-MM-DD hh:mm:ss"), currInput);
              if (e) {
                setValue(new Date(e));
              } else {
                setValue(null);
              }
            }}
            value={value || defaultVlueObj || defaultPlaceholder || null}
            disableClock={true}
            required={input.required}
            yearPlaceholder={"YYYY"}
            monthPlaceholder={"MM"}
            dayPlaceholder={"DD"}
            format={input.format || "yyyy-MM-dd"}
            disableCalendar={input.hideCalender}
            maxDate={
              input.maxDate ? new Date(input.maxDate) : new Date(9999, 12, 31)
            }
            minDate={input.minDate ? new Date(input.minDate) : null}
            calendarType={input.calenderType}
            tileDisabled={({ activeStartDate, date, view }) => {
              if (
                !daysDisabled ||
                !Array.isArray(daysDisabled) ||
                daysDisabled.length == 0
              ) {
                return null;
              } else {
                let cond;
                daysDisabled.forEach((day) => {
                  if (!cond) {
                    cond = `${day} == date.getDay()`;
                  } else {
                    cond += ` || ${day} == date.getDay()`;
                  }
                });
                return eval(cond);
              }
            }}
          />
        </>
      ) : (
        <>
          <DatePicker
            className={inputStyle}
            onChange={(e) => {
              change(moment(e).format("YYYY-MM-DD hh:mm:ss"), currInput);
              if (e) {
                setValue(new Date(e));
              } else {
                setValue(null);
              }
            }}
            value={value || defaultVlueObj || defaultPlaceholder || null}
            required={input.required}
            yearPlaceholder={"YYYY"}
            monthPlaceholder={"MM"}
            dayPlaceholder={"DD"}
            disableCalendar={input.hideCalender}
            format={input.format || "yyyy-MM-dd"}
            maxDate={
              input.maxDate ? new Date(input.maxDate) : new Date(9999, 12, 31)
            }
            minDate={input.minDate ? new Date(input.minDate) : null}
            calendarType={input.calenderType}
            tileDisabled={({ activeStartDate, date, view }) => {
              if (
                !daysDisabled ||
                !Array.isArray(daysDisabled) ||
                daysDisabled.length == 0
              ) {
                return null;
              } else {
                let cond;
                daysDisabled.forEach((day) => {
                  if (!cond) {
                    cond = `${day} == date.getDay()`;
                  } else {
                    cond += ` || ${day} == date.getDay()`;
                  }
                });
                return eval(cond);
              }
            }}
          />
        </>
      )}

      {inputValid || isValid ? (
        <></>
      ) : (
        <span style={{ color: "red" }} className="req-error">
          {errorMessage}
        </span>
      )}
    </ColorComp>
  );
}

export default DateInput;
