import React, { useEffect, useState } from "react";
// import withOnBlur from "react-onblur";

import { withTranslation } from "react-i18next";
import i18n from "../../../translate/i18n";

import Modal from "react-bootstrap/esm/Modal";
import {
  BiFileBlank,
  BiSelectMultiple,
  BiCheckSquare,
  BiTable,
  BiMapPin,
  BiUpload,
} from "react-icons/bi";
import { RxSlider } from "react-icons/rx";
import { TbFileDescription } from "react-icons/tb";
import { FiStar, FiSmartphone } from "react-icons/fi";
import { BsTextLeft } from "react-icons/bs";
import { MdShortText } from "react-icons/md";
import { CiViewTable } from "react-icons/ci";
function AddContentModal(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { setBlurListener, unsetBlurListener, handleCreate } = props;

  useEffect(() => {
    // if (isOpen) setBlurListener(onBlur);
    // // else we will remove listeners
    // else unsetBlurListener();
  }, [isOpen]);

  const onBlur = () => {
    setIsOpen(false);
  };

  const createContent = (type, qt = null) => {
    handleCreate(type, qt);
    onBlur();
  };
  const { t } = props;
  return (
    <div className="addContent-wapper">
      <a type="button" title="" onClick={() => setIsOpen(true)} className="add">
        <span>+</span> {t("leftBar-add-content")}
      </a>
      <Modal
        className="addContent-modal"
        size="sm"
        show={isOpen}
        onHide={onBlur}
      >
        <div
          className={
            i18n.language == "he" ? "addContent addContent-rtl" : "addContent"
          }
          tabIndex="1"
        >
          <div className="w-50 d-flex flex-column">
            <h4 className="d-flex">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
                <defs>
                  <style></style>
                </defs>
                <g id="a" />
                <g id="b">
                  <g id="c">
                    <g>
                      <circle className="d" cx="12.5" cy="18" r="1" />
                      <path
                        className="d"
                        d="M12.5,0C5.61,0,0,5.61,0,12.5s5.61,12.5,12.5,12.5,12.5-5.61,12.5-12.5S19.39,0,12.5,0Zm0,24c-6.34,0-11.5-5.16-11.5-11.5S6.16,1,12.5,1s11.5,5.16,11.5,11.5-5.16,11.5-11.5,11.5Z"
                      />
                      <path
                        className="d"
                        d="M13.13,6.05c-1.04-.18-2.09,.1-2.88,.76-.79,.67-1.25,1.65-1.25,2.68,0,.28,.22,.5,.5,.5s.5-.22,.5-.5c0-.74,.33-1.44,.89-1.91,.58-.48,1.31-.68,2.06-.54,1,.18,1.83,1,2,2,.19,1.08-.3,2.12-1.26,2.65-1.07,.59-1.71,1.61-1.71,2.81v1c0,.28,.22,.5,.5,.5s.5-.22,.5-.5v-1.09c0-.75,.45-1.44,1.19-1.85,1.31-.72,2.02-2.21,1.76-3.7-.25-1.41-1.4-2.57-2.81-2.81Z"
                      />
                    </g>
                  </g>
                </g>
              </svg>{" "}
              Question
            </h4>
            <div className="d-flex flex-column" style={{ marginLeft: "30px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <FiStar size={20} />{" "}
                <a
                  style={{ padding: "0 0 0 5px" }}
                  type="button"
                  onClick={() => createContent("question", "rating")}
                  title={t("question-rightBar-rating")}
                >
                  {t("question-rightBar-rating")}
                </a>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <BiSelectMultiple size={20} />
                <a
                  style={{ padding: "0 0 0 5px" }}
                  type="button"
                  onClick={() => createContent("question", "multiSelect")}
                  title={t("question-rightBar-multiple-Select")}
                >
                  {t("question-rightBar-multiple-Select")}
                </a>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <BiCheckSquare size={20} />
                <a
                  style={{ padding: "0 0 0 5px" }}
                  type="button"
                  onClick={() => createContent("question", "singleSelect")}
                  title={t("question-rightBar-single-Select")}
                >
                  {t("question-rightBar-single-Select")}
                </a>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <CiViewTable size={20} />
                <a
                  style={{ padding: "0 0 0 5px" }}
                  type="button"
                  onClick={() => createContent("question", "matrix")}
                  title={t("question-rightBar-matrix")}
                >
                  {t("question-rightBar-matrix")}
                </a>
              </div>
              {/* <div style={{ display: "flex", alignItems: "center" }}>
                <RxSlider size={20} />
                <a
                  style={{ padding: "0 0 0 5px" }}
                  type="button"
                  onClick={() => createContent("question", "slider")}
                  title={t("question-rightBar-slider")}
                >
                  {t("question-rightBar-slider")}
                </a>
              </div> */}
              {/*<a className="disable">Matrix</a>*/}
            </div>
          </div>
          {/*<div className="w-50 d-flex flex-column">*/}
          {/*  <h4 className="d-flex">*/}
          {/*    Form*/}
          {/*  </h4>*/}
          {/*  <div className="d-flex flex-column">*/}
          {/*    <div style={{display:"flex",alignItems:"center"}}><MdShortText size={20} /> <a style={{padding:"0 0 0 5px"}} type="button" onClick={() => createContent("question", "rating")} title={t("question-rightBar-short-text")}>{t("question-rightBar-short-text")}</a></div>*/}
          {/*    <div style={{display:"flex",alignItems:"center"}}><BsTextLeft size={20} /><a style={{padding:"0 0 0 5px"}} type="button" onClick={() => createContent("question", "multiSelect")} title={t("question-rightBar-long-text")}>{t("question-rightBar-long-text")}</a></div>*/}
          {/*    <div style={{display:"flex",alignItems:"center"}}><FiSmartphone size={20} /><a style={{padding:"0 0 0 5px"}} type="button" onClick={() => createContent("question", "singleSelect")} title={t("question-rightBar-phone-number")}>{t("question-rightBar-phone-number")}</a></div>*/}
          {/*    <div style={{display:"flex",alignItems:"center"}}><BiMapPin size={20} /><a style={{padding:"0 0 0 5px"}} type="button" onClick={() => createContent("question", "singleSelect")} title={t("question-rightBar-address")}>{t("question-rightBar-address")}</a></div>*/}
          {/*    <div style={{display:"flex",alignItems:"center"}}><BiUpload size={20} /><a style={{padding:"0 0 0 5px"}} type="button" onClick={() => createContent("question", "singleSelect")} title={t("question-rightBar-signature")}>{t("question-rightBar-signature")}</a></div>*/}
          {/*    <div style={{display:"flex",alignItems:"center"}}><TbFileDescription size={20} /><a style={{padding:"0 0 0 5px"}} type="button" onClick={() => createContent("question", "singleSelect")} title={t("question-rightBar-file-upload")}>{t("question-rightBar-file-upload")}</a></div>*/}
          {/*    /!*<a className="disable">Matrix</a>*!/*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="w-50 d-flex flex-column">
            <h4 href="" className="d-flex">
              <svg
                viewBox="0 0 14 14"
                width="25"
                height="25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="d"
                  d="M6.4375 5.4375C6.5625 5.5625 6.6875 5.625 6.875 5.625C7.0625 5.625 7.1875 5.5625 7.3125 5.4375L9.5 3.25C9.75 3 9.75 2.625 9.5 2.375L7.3125 0.1875C7.0625 -0.0625 6.6875 -0.0625 6.4375 0.1875L4.25 2.375C4 2.625 4 3 4.25 3.25L6.4375 5.4375ZM6.875 1.5L8.1875 2.8125L6.875 4.125L5.5625 2.8125L6.875 1.5ZM13.5625 6.75L11.375 4.5625C11.125 4.3125 10.75 4.3125 10.5 4.5625L8.3125 6.75C8.0625 7 8.0625 7.375 8.3125 7.625L10.5 9.8125C10.625 9.9375 10.8125 10 10.9375 10C11.0625 10 11.25 9.9375 11.375 9.8125L13.5625 7.625C13.8125 7.375 13.8125 7 13.5625 6.75ZM10.9375 8.5L9.625 7.1875L10.9375 5.875L12.25 7.1875L10.9375 8.5ZM7.3125 8.3125C7.0625 8.0625 6.6875 8.0625 6.4375 8.3125L4.25 10.5C4 10.75 4 11.125 4.25 11.375L6.4375 13.5625C6.5625 13.6875 6.75 13.75 6.875 13.75C7 13.75 7.1875 13.6875 7.3125 13.5625L9.5 11.375C9.75 11.125 9.75 10.75 9.5 10.5L7.3125 8.3125ZM6.875 12.25L5.5625 10.9375L6.875 9.625L8.1875 10.9375L6.875 12.25ZM5.4375 6.4375L3.25 4.25C3 4 2.625 4 2.375 4.25L0.1875 6.4375C-0.0625 6.6875 -0.0625 7.0625 0.1875 7.3125L2.375 9.5C2.5 9.625 2.6875 9.6875 2.8125 9.6875C2.9375 9.6875 3.125 9.625 3.25 9.5L5.4375 7.3125C5.6875 7.0625 5.6875 6.6875 5.4375 6.4375ZM2.8125 8.1875L1.5 6.875L2.8125 5.5625L4.125 6.875L2.8125 8.1875Z"
                  fill="#001046"
                />
              </svg>
              Other
            </h4>
            <div className="d-flex flex-column" style={{ marginLeft: "30px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <BiFileBlank size={20} />
                <a
                  style={{ padding: "0 0 0 5px" }}
                  type="button"
                  onClick={() => createContent("contact")}
                  title={t("question-rightBar-form")}
                >
                  {t("question-rightBar-form")}
                </a>
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <TbFileDescription size={20} />
                <a
                  style={{ padding: "0 0 0 5px" }}
                  type="button"
                  onClick={() => createContent("text")}
                  title={t("question-rightBar-text")}
                >
                  {t("question-rightBar-text")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default withTranslation()(AddContentModal);
