import React from "react";
import { connect } from "react-redux";
import PageLayout from "../Question/Customize/pageLayout";
import SubtitleStyle from "../Question/Customize/subtitleStyle";
import ButtonStyleCustom from "../Question/Customize/buttonStyle";
import { withTranslation } from "react-i18next";
import TitleStyle from "../Question/Customize/titleStyle";
import ContactFormLayout from "./Customize/ContactForm";
import RightBarTabsContainer from "../../RightBar/RightBarTabContainer";

// import Avatar from "@material-ui/core/Avatar";
import { FiEdit3 } from "react-icons/fi";
import ContactFormDesign from "../../ThemeEdit/contactForm";
// import SubmitButton from "./Customize/SubmitButton";

import HeaderStyle from "../Question/Customize/headerStyle";
import FooterStyleCustomize from "../Question/Customize/footerStyle";

class AddContact extends React.Component {
  render() {
    let {
      id,
      inputs,
      layout,
      showMedia,
      useTitleCustomStyle,
      useSubTitleCustomStyle,
      useButtonCustomStyle,
      useFormCustomStyle,
      useHeaderCustomStyle,
      useFooterCustomStyle,
      style,
    } = this.props.data.onEdit;

    const { context, t, selectedTheme } = this.props;

    const { settings, fonts } = this.props.data;
    const handleColorsToChange = (e, name) => {
      const { r, g, b, a } = e.rgb;
      const value = `rgba(${r}, ${g}, ${b}, ${a})`;
      this.props.handleQuestionStyle(
        { target: { name, value } },
        this.props.data.onEdit.id,
        "forms"
      );
    };

    const formContent = (
      <ContactFormLayout
        handleChangeContactDetails={this.props.handleChangeContactDetails}
        id={id}
        inputs={inputs}
        data={this.props.data}
        deleteInputFromContact={this.props.deleteInputFromContact}
        handleContactInputs={this.props.handleContactInputs}
        addInputToContact={this.props.addInputToContact}
        layout={layout}
        showMedia={showMedia}
        changeInputIndexes={this.props.changeInputIndexes}
        variables={this.props.data.variables}
        arrayOfData={this.props.data.arrayOfData}
        addLocationsContainerToContact={
          this.props.addLocationsContainerToContact
        }
        deleteLocationContainerFromContact={
          this.props.deleteLocationContainerFromContact
        }
        handleContactLocationInputs={this.props.handleContactLocationInputs}
      />
    );

    const formDesign = (
      <>
        {this.props.toolbar ? (
          <div className="addSwitch override">
            <label className="switch">
              <input
                type="checkbox"
                name="withSubtitle"
                checked={useFormCustomStyle ? true : false}
                onChange={(e) =>
                  this.props.handleChangeContactDetails(
                    {
                      target: {
                        name: "useFormCustomStyle",
                        type: "checkbox",
                        checked: useFormCustomStyle ? false : true,
                      },
                    },
                    id
                  )
                }
              />
              <span className="slider round"></span>
            </label>
            {t("rightBar-customize-override")}
          </div>
        ) : (
          <> </>
        )}
        {useFormCustomStyle && this.props.toolbar ? (
          <>
            <ContactFormDesign
              fromTheme={false}
              selectedTheme={"override-edit"}
              handleChange={this.props.handleQuestionStyle}
              context="forms"
              props={{ ...this.props, id, style }}
              handleColorsToChange={handleColorsToChange}
              style={style}
            />
          </>
        ) : (
          <>
            <div className="addSwitch-disabled"></div>
            <ContactFormDesign
              fromTheme={false}
              selectedTheme={"override-edit"}
              handleChange={{}}
              context="forms"
              props={{ ...this.props, id, style }}
              handleColorsToChange={{}}
              style={style}
            />
          </>
        )}
      </>
    );

    const formTabsObj = { Fields: formContent, Design: formDesign };
    return (
      <div
        id="customize"
        className="tabContent tabActive"
        style={{ display: "block", height: "100%", overflow: "auto" }}
      >
        {/*{context == "pageLayout" && !this.props.toolbar? (*/}
        {!this.props.toolbar ? (
          <PageLayout
            data={this.props.data}
            id={id}
            handleChangeQuestion={this.props.handleChangeContactDetails}
          />
        ) : (
          <></>
        )}
        {context == "titleStyle" && this.props.toolbar ? (
          <TitleStyle
            style={style}
            settings={settings}
            handleQuestionStyle={this.props.handleQuestionStyle}
            handleChangeQuestion={this.props.handleChangeContactDetails}
            fonts={fonts}
            useTitleCustomStyle={useTitleCustomStyle}
            id={id}
          />
        ) : (
          <></>
        )}

        {context == "subtitleStyle" && this.props.toolbar ? (
          <SubtitleStyle
            style={style}
            settings={settings}
            handleQuestionStyle={this.props.handleQuestionStyle}
            handleChangeQuestion={this.props.handleChangeContactDetails}
            useSubTitleCustomStyle={useSubTitleCustomStyle}
            fonts={fonts}
            id={id}
          />
        ) : (
          <></>
        )}

        {context == "buttonStyle" && this.props.toolbar ? (
          <ButtonStyleCustom
            style={style}
            settings={settings}
            handleQuestionStyle={this.props.handleQuestionStyle}
            handleChangeQuestion={this.props.handleChangeContactDetails}
            useButtonCustomStyle={useButtonCustomStyle}
            fonts={fonts}
            id={id}
          />
        ) : (
          <></>
        )}

        {context == "contactForm" && this.props.toolbar ? (
          <>
            <RightBarTabsContainer formTabsObj={formTabsObj} />
          </>
        ) : (
          <></>
        )}

        {context == "headerStyle" && this.props.toolbar ? (
          <HeaderStyle
            mainSettings={this.props.data.header}
            style={style}
            settings={settings}
            handleQuestionStyle={this.props.handleQuestionStyle}
            handleChangeQuestion={this.props.handleChangeContactDetails}
            fonts={fonts}
            useHeaderCustomStyle={useHeaderCustomStyle}
            changeNavs={this.props.changeNavs}
            id={id}
          />
        ) : (
          <></>
        )}

        {context == "footerStyle" && this.props.toolbar ? (
          <FooterStyleCustomize
            mainSettings={this.props.data.footer}
            style={style}
            settings={settings}
            handleQuestionStyle={this.props.handleQuestionStyle}
            handleChangeQuestion={this.props.handleChangeContactDetails}
            fonts={fonts}
            useFooterCustomStyle={useFooterCustomStyle}
            id={id}
          />
        ) : (
          <></>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { rightBarView } = state;
  const { context = "" } = rightBarView;
  return { context };
};

export default connect(mapStateToProps, null)(withTranslation()(AddContact));
