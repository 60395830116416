import React, { useState } from "react";
// import Collapse from "react-bootstrap/Collapse";
// import Fonts from "../../../../../../utils/Fonts/Fonts";
import { withTranslation } from "react-i18next";
// import SelectSize from "../../../../SelectSize";
import ColorPicker from "../../ColorPicker";
import Collapse from "react-bootstrap/Collapse";
import { IoTrashOutline } from "react-icons/io5";
import AcTest from "../../../AcTest/Index";

function AddContactSliderField(props) {
    const { data, id, t, handleChangeSliderData, step, index, toggleError,
        handleInputName, deleteInput,
        handleInputVariableName,

        dataWithVariables, } = props;
    const { slider = {} } = props.step;
    const {
        min,
        max,
        stepSize,
        defaultValue,
        isPercentage,
        displayCurrentValue,
        baseColor = "#0046B5",
        selectedColor = "rgb(190, 30, 185)",
        displayMarks = false
    } = step;


    const [inputCollapse, setInputCollapse] = useState(false);
    // const { type } = data;

    return (
        <>
            <div className="feildHeader">
                <span
                    className="pointer"
                    onClick={() => setInputCollapse(!inputCollapse)}
                >
                    {handleInputName(step, index)}
                </span>

                <IoTrashOutline
                    type="button"
                    title="rightBar-customize-override"
                    onClick={() => deleteInput(id, step.id)}
                    color={"rgba(0, 68, 179, 0.42)"}
                    size={22}
                />
                {/*<a*/}
                {/*  title=""*/}
                {/*  className="bin"*/}
                {/*  onClick={() => deleteInput(id, step.id)}*/}
                {/*></a>*/}
            </div>
            <Collapse in={inputCollapse}>
                <div className="fieldOptions" id={` item${index}`}>




                    <div className="slider-extra-data">


                        <AcTest
                            changeOnSelect={(trigger, slug) => "{{" + slug + "}}"}
                            trigger={["{{"]}
                            options={{ "{{": [...dataWithVariables] }}
                            type="text"
                            name="label"
                            value={step.label}
                            placeholder="Name:"
                            className="autocomplete-form-input"
                            rows={1}
                            maxOptions={50}
                            onChange={(e) => props.handleContactInputs({ target: { name: "label", value: e } }, step.id, id)}
                            onBlur={(e) => handleInputVariableName({ target: { name: "label", value: e } }, step.id, id)}

                        />
                        <div className="form-group">
                            <label>Min: </label>
                            <input
                                type="number"
                                value={step.min}
                                className="form-control"
                                onChange={(e) =>

                                    props.handleContactInputs({ target: { name: "min", value: parseFloat(e.target.value || "0") } }, step.id, id)
                                    // handleChangeSliderData(
                                    //     {
                                    //         target: {
                                    //             value: parseFloat(e.target.value || "0"),
                                    //             name: "min",
                                    //         },
                                    //     },
                                    //     id
                                    // )
                                }
                                name="min"
                            />
                        </div>
                        <div className="form-group">
                            <label>Max: </label>
                            <input
                                type="number"
                                value={step.max}
                                className="form-control"
                                onChange={(e) =>
                                    props.handleContactInputs({ target: { name: "max", value: parseFloat(e.target.value || "0") } }, step.id, id)
                                    // handleChangeSliderData(
                                    //     {
                                    //         target: {
                                    //             value: parseFloat(e.target.value || "0"),
                                    //             name: "max",
                                    //         },
                                    //     },
                                    //     id
                                    // )
                                }
                                name="max"
                            />
                        </div>
                        <div className="form-group">
                            <label>Step: </label>
                            <input
                                type="number"
                                value={step.stepSize}
                                onChange={(e) =>
                                    props.handleContactInputs({ target: { name: "stepSize", value: parseFloat(e.target.value || "0") } }, step.id, id)
                                    // handleChangeSliderData(
                                    //     {
                                    //         target: {
                                    //             value: parseFloat(e.target.value || "0"),
                                    //             name: "stepSize",
                                    //         },
                                    //     },
                                    //     id
                                    // )
                                }
                                name="stepSize"
                            />
                        </div>
                        <div className="form-group">
                            <label>Default Value: </label>
                            <input
                                type="number"
                                value={step.defaultValue}
                                onChange={(e) =>
                                    props.handleContactInputs({ target: { name: "defaultValue", value: parseFloat(e.target.value || "0") } }, step.id, id)
                                    // handleChangeSliderData(
                                    //     {
                                    //         target: {
                                    //             value: parseFloat(e.target.value || "0"),
                                    //             name: "defaultValue",
                                    //         },
                                    //     },
                                    //     id
                                    // )
                                }
                                name="defaultValue"
                            />
                        </div>
                        <div className="fieldList">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={step.isPercentage}
                                    onChange={(e) => {
                                        props.handleContactInputs({ target: { name: "isPercentage", value: e.target.checked } }, step.id, id)
                                        // handleChangeSliderData(
                                        //     { target: { value: e.target.checked, name: "isPercentage" } },
                                        //     id
                                        // )
                                    }}
                                    name="isPercentage"
                                />
                                Percent
                            </label>
                        </div>
                        <div className="fieldList">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={displayCurrentValue}
                                    onChange={(e) =>
                                        props.handleContactInputs({ target: { name: "displayCurrentValue", value: e.target.checked } }, step.id, id)
                                        // handleChangeSliderData(
                                        //     {
                                        //         target: {
                                        //             value: e.target.checked,
                                        //             name: "displayCurrentValue",
                                        //         },
                                        //     },
                                        //     id
                                        // )
                                    }
                                    name="displayCurrentValue"
                                />{" "}
                                Show Value
                            </label>
                        </div>


                        <div className="fieldList">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={displayMarks}
                                    onChange={(e) =>
                                        props.handleContactInputs({ target: { name: "displayMarks", value: e.target.checked } }, step.id, id)
                                        // handleChangeSliderData(
                                        //     {
                                        //         target: {
                                        //             value: e.target.checked,
                                        //             name: "displayCurrentValue",
                                        //         },
                                        //     },
                                        //     id
                                        // )
                                    }
                                    name="displayCurrentValue"
                                />{" "}
                                Show Marks
                            </label>
                        </div>


                        <div>
                            <label>Base Color:</label>
                            <ColorPicker
                                color={baseColor}
                                setRight="inherit"
                                handleChange={(e) => {
                                    const { r, g, b, a } = e.rgb;
                                    const value = `rgba(${r}, ${g}, ${b}, ${a})`;
                                    props.handleContactInputs({ target: { name: "baseColor", value } }, step.id, id)
                                    // handleChangeSliderData(
                                    //     { target: { value, name: "baseColor" } },
                                    //     id
                                    // );
                                }}
                            />
                        </div>
                        <div>
                            <label>Selected Color:</label>
                            <ColorPicker
                                color={selectedColor}
                                setRight="inherit"
                                handleChange={(e) => {
                                    const { r, g, b, a } = e.rgb;
                                    const value = `rgba(${r}, ${g}, ${b}, ${a})`;
                                    props.handleContactInputs({ target: { name: "selectedColor", value } }, step.id, id)
                                    // handleChangeSliderData(
                                    //     { target: { value, name: "selectedColor" } },
                                    //     id
                                    // );
                                }}
                            />
                        </div>
                    </div>

                    {toggleError(step)}
                </div>
            </Collapse>
        </>
    );
}

export default withTranslation()(AddContactSliderField);
