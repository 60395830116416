import React, { useState } from "react";
import Settings from "../../../ThemeEdit/Settings";
import ThemeList from "../../../Themes/themeList";
import { withTranslation } from "react-i18next";
function ThemesTab(props) {
  const { selectedTheme, changeTheme, t, setThemeData } = props;
  const newThemeTemplate = {
    theme_id: null,
    theme_data: {
      // title: {
      //   color: "rgba(0, 0, 0, 1)",
      //   padding: {
      //     top: "0",
      //     left: "0",
      //     right: "0",
      //     bottom: "0",
      //   },
      //   fontSize: "24",
      //   fontType: "",
      //   fontStyle: "",
      //   textAlign: "center",
      //   fontFamily: "Open Sans",
      //   fontWeight: "",
      // },
      answers: {
        padding: {
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          buttonsBorderSize: "0",
          buttonsBorderRadius: "0",
          buttonsBorderColor: "",
        },
        fontSize: "20",
        fontStyle: "",
        fontFamily: "Open Sans",
        fontWeigth: "",
        textAlign: "center",
        buttonsTextColor: "rgba(0, 0, 0, 1)",
        buttonsBorderSize: "0",
        buttonsBorderColor: "",
        buttonsBorderRadius: "0",
        buttonsHoverTextColor: "",
        buttonsBackgroundColor: "",
        buttonsHoverBorderSize: "0",
        buttonsHoverBorderColor: "",
        buttonsHoverBorderRadius: "0",
        buttonsHoverBackgroundColor: "",
        picSize: "200",
        textContainerSize: "150",
      },
      subtitle: {
        color: "rgba(0, 0, 0, 1)",
        padding: {
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
        },
        fontSize: "16",
        fontType: "",
        fontStyle: "",
        fontFamily: "Open Sans",
        fontWeight: "",
        textAlign: "center",
      },
      button: {
        color: "#000",
        background: "",
        borderSize: "0",
        borderColor: "",
        borderRadius: "0",
        hoverColor: "",
        hoverBackground: "",
        hoverBorderSize: "0",
        hoverBorderColor: "",
        hoverBorderRadius: "0",
        fontWeight: "",
        fontSize: "17",
        fontType: "",
        fontFamily: "Open Sans",
        fontStyle: "",
        padding: { top: "0", right: "0", bottom: "0", left: "0" },
        textAlign: "center",
      },
      title: {
        color: "rgba(0, 0, 0, 1)",
        fontWeight: "",
        fontSize: "14",
        fontType: "",
        fontFamily: "Open Sans",
        fontStyle: "",
        padding: { top: "0", right: "0", bottom: "0", left: "0" },
      },
      forms: {
        color: "rgba(0, 0, 0, 1)",
        fontWeight: "",
        fontSize: "14",
        fontType: "",
        fontFamily: "Open Sans",
        fontStyle: "",
        padding: { top: "0", right: "0", bottom: "0", left: "0" },
        formTextAlign: "center",
        formWidth: "auto",
      },
      alert: {
        fontSize: "14",
        fontFamily: "Open Sans",
        textAlign: "center",
        width: "auto",
        correct: {
          text: "Correct!",

          background: "rgba(0,0,0,0.5)",
          color: "#fff",
          borderColor: "black",
          borderSize: "0",
        },
        incorrect: {
          text: "Incorrect",
          background: "rgba(0,0,0,0.5)",
          color: "#fff",
          borderColor: "black",
          borderSize: "0",
        },
      },
      bar: {
        barType: "",
        fontSize: "14",
        color: "rgba(0, 0, 0, 1)",
        textAlign: "center",
      },
      pagination: false,
      gradientDeg: "45deg",
      progressBar: false,
      gradientType: "linear-gradient",
      backgroundSize: "auto",
      backgroundPosition: "center center",
      gradientColors: {
        color1: "",
        color2: "",
      },
      backgroundColor: "rgba(255, 255, 255, 1)",
      backgroundImage: "",
      backgroundMediaType: "image",
      backgroundVideoSrc: "",
      backgroundStyle: "color",
      backgroundColorType: "color",
    },
    theme_title: `${t("rightBar-theme-new-theme")}`,
  };
  const [currentOnEdit, setCurrentOnEdit] = useState(newThemeTemplate);
  const [isEditMode, setIsEditMode] = useState(false);

  const handleCurrentEdit = (selectedTheme) => {
    setCurrentOnEdit(selectedTheme);
    setIsEditMode(true);
  };

  return (
    <div id="Themes">
      {!isEditMode ? (
        <ThemeList
          changeTheme={changeTheme}
          selectedTheme={selectedTheme}
          handleCurrentEdit={handleCurrentEdit}
          newThemeTemplate={newThemeTemplate}
          context={props.context}
          changeRightBarView={props.changeRightBarView}
          setThemeData={setThemeData}

        />
      ) : (<></>
        // <Settings
        //   currentOnEdit={currentOnEdit}
        //   handleCurrentTheme={handleCurrentEdit}
        //   setIsEditMode={setIsEditMode}
        //   {...props}
        // />
      )}
    </div>
  );
}

export default withTranslation()(ThemesTab);
