import React, { useState } from "react";
import { connect } from "react-redux";
import { createFolder, createFolderAndMove, getUserQuizes } from "../../../redux/actions";
import Modal from "react-bootstrap/Modal";
import { withTranslation } from "react-i18next";
function FoldersModal(props) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [textField, setTextField] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { value } = e.target;
    setTextField(value);
  };

  const handleClick = () => {
    if (!textField) return setErrorMessage("Folder name is required.");
    props.actions.createFolder(textField);
    setIsOpen(false);
  };

  const createAndMove = () => {
    if (!textField) return setErrorMessage("Folder name is required.");
    props.actions.createFolderAndMove(textField, quizId.id);
    setIsOpen(false);
    props.hide();
  }

  const { t, withoutModal, quizId } = props;
  return (
    <>
      {!withoutModal ? (
        <>
          <button onClick={() => setIsOpen(true)} className="addFolder myBtn_multi">
            <span>+</span>
            {t("dashboard-rightBar-add-folder")}
          </button>

          <Modal show={modalIsOpen} onHide={() => setIsOpen(false)} centered>
            <h2> {t("dashboard-rightBar-create-new-folder")} </h2>
            {chooseDirData(textField, handleChange, handleClick, createAndMove, t, setIsOpen)}
          </Modal>
        </>
      ) :

        (
          <>  {chooseDirData(textField, handleChange, handleClick, createAndMove, t, setIsOpen, withoutModal)}</>
        )}
    </>
  );
}

const chooseDirData = (textField, handleChange, handleClick, createAndMove, t, setIsOpen, withoutModal) => {
  return (
    <>
      <input
        type="text"
        name="textField"
        autoFocus
        value={textField}
        onChange={handleChange}
        placeholder={t("dashboard-rightBar-folder-name")}
      />
      <input
        style={{ marginBottom: "50px" }}
        type="button"
        value={!withoutModal ? t("publish-popup-btn-create") : t("publish-popup-btn-create-and-move")}
        onClick={!withoutModal ? handleClick : createAndMove}
        className={withoutModal && textField ? "btn-popup" : textField ? "btn-popup" : "btn-popup disabled"}
      />

      {/*<span className="close close_multi" onClick={() => setIsOpen(false)}>*/}
      {/*        {t("general-cancel")}*/}
      {/*      </span>*/}
    </>
  )

}

const mapStateToProps = (state) => {
  return { state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      getUserQuizes: () => dispatch(getUserQuizes()),
      createFolder: (folderName) => dispatch(createFolder(folderName)),
      createFolderAndMove: (folderName, quizId) => dispatch(createFolderAndMove(folderName, quizId)),
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(FoldersModal)
);
