import React, { useEffect, useState } from "react";

export default function JsonAtributePicker(props) {
    const { jsonVars = [], itirate, setShowJsonModal, addIttirateJsonVars } = props
    const [selectedVar, setSelectedVar] = useState("")
    const [visualiserMatrix, setVisualiserMatrix] = useState([])
    const [selectedPath, setSelectedPath] = useState("")


    useEffect(() => {

        if (selectedVar != "") {
            const parsedVar = JSON.parse(jsonVars[selectedVar].value)
            const matrix = [[]]
            for (let k in parsedVar) {
                matrix[0].push({ name: k, value: parsedVar[k], selected: false })
            }
            setVisualiserMatrix(matrix)
            setSelectedPath("")
        }
    }, [selectedVar])


    const handleVarTree = (i, j) => {
        // const { name, body } = selectedVar

        let tempMatrix = Object.assign([], visualiserMatrix)
        tempMatrix[i].forEach(el => el.selected = false)
        tempMatrix.length = i + 1
        tempMatrix[i][j].selected = true
        if (typeof tempMatrix[i][j].value === "object") {
            const body = tempMatrix[i][j].value
            tempMatrix[i + 1] = []
            for (let k in body) {
                tempMatrix[i + 1].push({ name: k, value: body[k], selected: false })
            }
        }
        setVisualiserMatrix(tempMatrix)
        if (itirate && Array.isArray(tempMatrix[i][j].value)) {
            setSelectedPath(stringifyPath(tempMatrix))
        } else {
            setSelectedPath("")
        }
    }


    const stringifyPath = (matrix) => {
        let path = ""
        matrix.forEach((row, i) => {
            row.forEach((el, j) => {
                if (el.selected) {
                    path += `.${el.name}`
                }
            })
        })
        return path
    }


    return (<div>

        <select onChange={(e) => setSelectedVar(e.target.value)}>
            <option value="" > select...</option>
            {jsonVars.map((el, i) => <option value={i}>{el.name}</option>)}

        </select>
        <div>
            {selectedVar && <div className="d-flex ">
                {visualiserMatrix.map((row, i) => (
                    <div className="d-flex flex-column">
                        {row.map((el, j) => (

                            <><button className={el.selected ? "btn btn-primary" : "btn btn-secondary"} onClick={() => handleVarTree(i, j)}>{el.name}</button></>

                        ))}

                    </div>

                ))}



            </div>}

            <div>
                <button
                    className="btn btn-primary"
                    style={{ marginRight: "30px" }}
                    disabled={!selectedPath || !selectedVar}
                    // onClick={saveBulkAnswers}
                    onClick={() => addIttirateJsonVars(selectedPath, jsonVars[selectedVar].name)}
                >
                    Save
                </button>
                <button
                    className="btn btn-secondary"
                    onClick={() => setShowJsonModal(false)}
                >
                    Cancel
                </button>
            </div>

        </div>
    </div >)
}