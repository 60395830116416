import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { editQuiz } from "../../../redux/actions";
import { withTranslation } from "react-i18next";
import i18n from "../../../translate/i18n";
function ConfigActionButtons(props) {
  const {
    selectedFunc,
    handleAccessibilityChanges,
    selectedArgs,
    selectedDetails,
    actions,
    toast,
    t,
  } = props;
  const { editQuiz } = actions;

  const saveCustomConfig = () => {
    // handleAccessibilityChanges(e);
    selectedFunc();
    activateToast("Saved!");
  };

  const activateToast = (msg) => {
    toast.success(msg, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  };

  return (
    <>
      {" "}
      <div className={i18n.language == "he" ? "footerBtns footerBtns-rtl": "footerBtns"} >
        <div className="footerBtnsDiv">
        <a
          title=""
          className="saveBtn pointer"
          onClick={(e) => saveCustomConfig(e)}
        >
          {t("configuration-quiz-save")}
        </a>
      </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  const { saveQuizMessage, selectedDetails, loginDetails } = state;

  const { user_id: userId } = loginDetails;
  return { selectedDetails };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      editQuiz: (quizDetails) => dispatch(editQuiz(quizDetails)),

      // getQuizDetailsById: (quizId) => dispatch(getQuizDetailsById(quizId)),
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConfigActionButtons)
);
