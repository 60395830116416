import React, { useEffect, useState } from "react";
import { CgToggleSquare, CgToggleSquareOff } from "react-icons/cg";
import { connect } from "react-redux";
// import { updateUnpublishConditionsAction, getUnpublishConditionsAction } from "../../../redux/actions";
import { withTranslation } from "react-i18next";
import KnowyLogo from "../../images/knowy-logo-animation.svg";

function Unpublished(props) {
    const { selectedDetails, allresdyAnswered } = props;
    const [showCustomMsg, setShowCustomMsg] = useState(false);
    const [customTitle, setCustomTitle] = useState("");
    const [customDescription, setCustomDescription] = useState("");



    useEffect(() => {
        if (selectedDetails) {
            if (!allresdyAnswered) {
                if (selectedDetails.isMsg) {
                    setShowCustomMsg(true);
                    setCustomTitle(selectedDetails.redirectText);
                    setCustomDescription(selectedDetails.redirectDescription);
                }
            } else if (selectedDetails.quizDetails && selectedDetails.quizDetails.duplications) {
                setShowCustomMsg(true);
                setCustomTitle(selectedDetails.quizDetails.duplications.duplicationsTitle || "This Knowy can only be answered once.")
                setCustomDescription(selectedDetails.quizDetails.duplications.duplicationsBody);
            }
        };

    }, [selectedDetails]);

    return (<div
        className="h-100 d-flex align-items-center justify-content-center"
        style={{ backgroundColor: "#F2F1FF" }}
    >
        <div style={{ display: "block", maxWidth: "600px", minWidth: "350px" }}>
            {/*<KnowyLogo />*/}
            <object type="image/svg+xml" data={KnowyLogo}>
                svg-animation
            </object>

            <div style={{ display: "block", textAlign: "center" }}>
                <h1>
                    {showCustomMsg ? customTitle : "Some answers don't last forever."}
                </h1>
            </div>
            <div style={{ display: "block" }}>
                <br /><br />
                <h3 className="text-center">{showCustomMsg ? customDescription : <>Ooops, thos Knowy has already closed :&#40; <br /> <br />
                    * What's a Knowy? <a href="https://knowyyy.com" style={{ color: 'inherit', textDecoration: "underline" }} target="_blank"> Check out here</a>
                </>}</h3>
            </div>
            <div
                style={{
                    display: "block",
                    color: "#264FA2",
                    paddingTop: "20px",
                    textAlign: "center",
                }}
            >
                {/* Loading... */}
            </div>
        </div>
    </div>
    )
}


const mapStateToProps = (state) => {
    const { selectedDetails } = state;
    return { selectedDetails };
};
// const mapDispatchToProps = (dispatch) => {
//     return {
//         actions: {
//             updateUnpublishConditions: (condition) => dispatch(updateUnpublishConditionsAction(condition)),

//             getUnpublishConditions: (quizId) => dispatch(getUnpublishConditionsAction(quizId)),
//         },
//     };
// };

export default withTranslation()(
    connect(mapStateToProps, null)(Unpublished)
);
