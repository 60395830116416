import React, { useEffect, useState } from "react";
import { websiteUrl, configHostUrl } from "../../../config";
import { copyToClip } from "../../../utils";

function CustomEmbed(props) {
  const { slug, guid, path } = props;

  const [embedCode, setEmbedCode] = useState("");
  const [mobWidth, setMobWidth] = useState(250);
  const [deskWidth, setDeskWidth] = useState(250);
  const [mobHeight, setMobHeight] = useState(250);
  const [deskHeight, setDeskHeight] = useState(250);

  useEffect(() => {
    const embed = `<div><div id=${guid} data-url="${path}" data-width='100%'></div><script src="${websiteUrl}/assets/js/hippoloader.js"></script><script>initEmbed("${guid}", "custom", ["${websiteUrl}",${mobWidth}, ${deskWidth}, ${mobHeight}, ${deskHeight}]);</script></div>`;
    setEmbedCode(embed);
  }, [mobWidth, deskWidth, mobHeight, deskHeight]);
  return (
    <div id="instant" className="active innerTabs">
      <div className="banner">
        <h3>Custom Embed</h3>
        <p>
          The Custom Embed feature in Knowy gives you complete control over how your forms are displayed on your website. With a few simple settings, you can adjust the width and height of your form to perfectly fit any page, delivering a seamless user experience. Customize your form's appearance to match your website's design, and choose exactly where it should be displayed.
        </p>
      </div>
      <div className="screens custom">
        <div className="mobail">
          <span></span>
        </div>
        <div className="desktop">
          <span></span>
        </div>
      </div>
      <div className="style">
        <div>
          <h3>Style:</h3>
          <div className="field">
            <label>Desktop height:</label>
            <input
              type="number"
              placeholder="250px"
              value={deskHeight}
              onChange={(e) => {
                setDeskHeight(e.target.value);
              }}
            />
          </div>
          <div className="field">
            <label>Mobile height:</label>
            <input
              type="number"
              placeholder="250px"
              value={mobHeight}
              onChange={(e) => {
                setMobHeight(e.target.value);
              }}
            />
          </div>
          <div className="field">
            <label>Desktop width:</label>
            <input
              type="number"
              placeholder="450px"
              value={deskWidth}
              onChange={(e) => {
                setDeskWidth(e.target.value);
              }}
            />
          </div>
          <div className="field">
            <label>Mobile width:</label>
            <input
              type="number"
              placeholder="450px"
              value={mobWidth}
              onChange={(e) => {
                setMobWidth(e.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <h3>Code:</h3>
          <textarea disabled value={embedCode}></textarea>
        </div>
      </div>
      <a
        title=""
        className="copy pointer"
        onClick={() => copyToClip(embedCode)}
      >
        Copy
      </a>
    </div>
  );
}

export default CustomEmbed;
