import React from "react";
import { Line } from "react-chartjs-2";

function LineChart({
  quizAnalyzeLineChart = [],
  nameOfXKey,
  nameOfYKey,
  label,
}) {
  const backgroundColor = ["#0046B5", "#03A6CA", "#BE1EB9"];
  const hoverBackgroundColor = ["#0046B5", "#03A6CA", "#BE1EB9"];

  const data = {
    labels: quizAnalyzeLineChart.map((itr) => itr[nameOfXKey]),
    datasets: nameOfYKey.map((key, index) => {
      return {
        label: key.replace("_", " "),
        data: quizAnalyzeLineChart.map((itr) => itr[key]),
        backgroundColor: backgroundColor[index],
        borderColor: hoverBackgroundColor[index],
        pointBackgroundColor: hoverBackgroundColor[index],
        pointHitRadius: 30,
        tension: 0.4,
      };
    }),
  };

  const options = {
    scales: {
      x: {
        ticks: {
          maxTicksLimit: 7,
          min: 0,
          stepSize: 1,
        },
      },
      y: {
        ticks: {
          maxTicksLimit: 5,
          min: 0,
          stepSize: 1,
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    plugins: {
      title: {
        display: true,
        text: label,
        font: {
          size: 30, // Set the desired font size for the title
        }, // Set the desired font size
      },
    },
  };
  if (nameOfYKey.length <= 1) {
    options.plugins.legend = { display: false };
  }
  return (
    <div className="line-chart-analyze">
      <Line data={data} options={options} />
    </div>
  );
}

export default LineChart;
