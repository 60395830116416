import React from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { changeOrgAction, logout } from "../../../redux/actions";
import i18n from "../../../translate/i18n";
// import "./navbar.css"
import { withTranslation } from "react-i18next";
import { MdGroups } from "react-icons/md";
import Collapse from "react-bootstrap/Collapse";
class NavBarModalToggle extends React.Component {
  state = {
    showMenu: false,
    openCompanies: false,
    redirectTo: null,
    redirectToDifferentOrganization: null,
  };

  toggleMenu = () => {
    const { showMenu, isRedirect = false } = this.state;
    this.setState({ showMenu: !showMenu });
  };

  handleClickOnLink = (redirectTo, currentPage) => {
    if (this.props.active === currentPage) return this.toggleMenu();
    if (this.props.openExitWithoutSavingModal) {
      return this.props.openExitWithoutSavingModal(() => (
        <Redirect to={redirectTo} />
      ));
    } else this.setState({ redirectTo });
  };

  handleChangeOrganization = (orgId) => {
    if (
      this.props.openExitWithoutSavingModal &&
      this.props.callbackWhenSwitchOrganization
    ) {
      this.props.openExitWithoutSavingModal(() => {
        this.props.actions
          .changeOrg(orgId)
          .then(this.props.callbackWhenSwitchOrganization);
      });
    } else {
      this.setState({
        redirectToDifferentOrganization: () => {
          this.props.actions.changeOrg(orgId).then(() => {
            this.setState({ isLogout: true });
          });
        },
      });
    }
  };

  render() {
    const { name = "", active, t, orgArray, orgId, permitions } = this.props;

    if (this.state.isLogout) {
      return <Redirect to="/login" />;
    }
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }
    if (this.state.redirectToDifferentOrganization) {
      this.state.redirectToDifferentOrganization();
    }
    const orgObj = orgArray ? orgArray.find((el) => el.id === orgId) : null;
    const orgKeys = orgObj && orgObj.keys ? orgObj.keys.split(",") : [];

    return (
      <>
        <span onClick={this.toggleMenu} className="user">
          {name[0]}
        </span>
        {this.state.showMenu ? (
          <div
            className={
              i18n.language == "he" ? "profile profile-reverse" : "profile"
            }
          >
            <div className="profilePic">
              {name[0]}
              {/* <span></span> */}
            </div>
            <h3>{name}</h3>
            {orgArray && orgArray.length ? (
              <div className="d-flex justify-content-center">
                <h5>{orgArray.find((o) => o.id == orgId).name}</h5>
                {orgArray.length > 1 ? (
                  <>
                    <MdGroups
                      onClick={() =>
                        this.setState({
                          openCompanies: !this.state.openCompanies,
                        })
                      }
                      size={28}
                      color={"#FFFFFF"}
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}

            {this.state.openCompanies ? (
              <>
                <Collapse in={this.state.openCompanies}>
                  <div className="fieldOptions" id={` item${0}`}>
                    {orgArray.map((org, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => this.handleChangeOrganization(org.id)}
                        >
                          <h5>{org.name}</h5>
                        </div>
                      );
                    })}
                  </div>
                </Collapse>
              </>
            ) : (
              <></>
            )}
            <nav className="profileMenu">
              <a
                className={active == "Dashboard" ? "active" : ""}
                type="button"
                onClick={() =>
                  this.handleClickOnLink(
                    {
                      pathname: "/dashboard",
                    },
                    "Dashboard"
                  )
                }
              >
                {t("profile-dashboard")}
              </a>

              {permitions &&
              permitions.add_users &&
              orgKeys.includes("add_users") ? (
                <>
                  <a
                    className={active == "Organization" ? "active" : ""}
                    type="button"
                    onClick={() =>
                      this.handleClickOnLink(
                        {
                          pathname: "/organization",
                        },
                        "Organization"
                      )
                    }
                  >
                    Organization
                  </a>
                </>
              ) : (
                <></>
              )}
              {permitions &&
              permitions.add_users &&
              orgKeys.includes("add_users") ? (
                <>
                  <a
                    className={active == "Integrations" ? "active" : ""}
                    type="button"
                    onClick={() =>
                      this.handleClickOnLink(
                        {
                          pathname: "/integrations",
                        },
                        "Integrations"
                      )
                    }
                  >
                    {t("profile-integrations")}
                  </a>
                </>
              ) : (
                <></>
              )}
              <a
                className={active == "Subscription" ? "active" : ""}
                type="button"
                onClick={() =>
                  this.handleClickOnLink(
                    {
                      pathname: "/subscriptions",
                    },
                    "Subscription"
                  )
                }
              >
                {t("profile-subscription")}
              </a>
              <a
                type="button"
                className={active == "Profile" ? "active" : ""}
                onClick={() =>
                  this.handleClickOnLink(
                    {
                      pathname: "/profile",
                    },
                    "Profile"
                  )
                }
              >
                {t("profile-profile")}
              </a>

              <a
                type="button"
                onClick={() => {
                  this.props.actions.logout();
                  this.setState({ isLogout: true });
                  this.render();
                }}
              >
                {t("profile-logout")}
              </a>
            </nav>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let { name, orgArray, orgId } = state.loginDetails;
  return { name, orgArray, orgId, permitions: state.permitions };
};
const mapDispatchToprops = (dispatch) => {
  return {
    actions: {
      logout: () => dispatch(logout()),
      changeOrg: (orgId) => dispatch(changeOrgAction(orgId)),
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToprops)(NavBarModalToggle)
);
