import React from "react";
import Configure from "./Configure";
import Seo from "./Seo";
import CustomStyle from "./CustomStyle";
import CustomScripts from "./CustomScripts";
import Variables from "./variables";
import { handleAccessibilityConfigurationAction } from "../../../redux/actions";
import "./config.css";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { FaCommentsDollar } from "react-icons/fa";
import AccessibilityConfiguration from "./AccessibilityConfiguration";
import i18n from "../../../translate/i18n";
import Unpublished from "./Unpublished";

class Configuration extends React.Component {
  state = {
    toShow: "configure",
    accessibilityLocalState: {
      hideAccessibility: false,
    },
  };

  componentDidMount() {
    const { accessibilityConfiguration } = this.props;
    this.setState({
      ...this.state,
      accessibilityLocalState: accessibilityConfiguration,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const previousReduxState = JSON.stringify(
      prevProps.accessibilityConfiguration
    );
    const currentReduxState = JSON.stringify(
      this.props.accessibilityConfiguration
    );

    if (previousReduxState === currentReduxState) {
      return;
    }
    this.setState({
      ...this.state,
      accessibilityLocalState: this.props.accessibilityConfiguration,
    });
  }

  handleAccessibilityChanges = (key, value) => {
    this.setState({
      ...this.state,
      accessibilityLocalState: {
        ...this.state.accessibilityLocalState,
        [key]: value,
      },
    });
  };

  handleToShow = () => {
    const {
      data,
      publishQuiz,
      changeConfigureDetails,
      changeSeoDetails,
      handleCustomStyle,
      handleCustomScripts,
      createScript,
      updateScript,
      handleSaveVariables,
      toast,
      saveEditedQuiz,
      deleteScript,
    } = this.props;
    switch (this.state.toShow) {
      case "configure":
        return (
          <Configure
            data={data}
            changeConfigureDetails={changeConfigureDetails}
            toast={toast}
            saveEditedQuiz={saveEditedQuiz}
          />
        );
      case "seo":
        return (
          <Seo
            data={data}
            changeConfigureDetails={changeConfigureDetails}
            changeSeoDetails={changeSeoDetails}
            toast={toast}
            saveEditedQuiz={saveEditedQuiz}
          />
        );
      case "style":
        return (
          <CustomStyle
            data={data}
            handleChangeStyle={handleCustomStyle}
            toast={toast}
          />
        );
      case "script":
        return (
          <CustomScripts
            data={data}
            handleChangeSCripts={handleCustomScripts}
            createScript={createScript}
            updateScript={updateScript}
            toast={toast}
            saveEditedQuiz={saveEditedQuiz}
            deleteScript={deleteScript}
          />
        );
      case "variables":
        return (
          <Variables
            data={data}
            handleChangeSCripts={handleCustomScripts}
            createScript={createScript}
            updateScript={updateScript}
            handleSaveVariables={handleSaveVariables}
            toast={toast}
          />
        );
      case "accessibility":
        return (
          <AccessibilityConfiguration
            quizId={data.id}
            accessibilityLocalState={this.state.accessibilityLocalState}
            handleAccessibilityChanges={this.handleAccessibilityChanges}
            toast={toast}
          />
        );
      case "unpublished":
        return (
          <Unpublished
            data={data}
            changeConfigureDetails={changeConfigureDetails}
            toast={toast}
            saveEditedQuiz={saveEditedQuiz}
            publishQuiz={publishQuiz}
            changeDuplicationsDetiles={this.props.changeDuplicationsDetiles}
          />
        );
      default:
        return <></>;
    }
  };

  isActive = (val) => {
    return val === this.state.toShow ? "active" : "";
  };

  render() {
    const { t } = this.props;
    return (
      <section className="dashboardContent config">
        <div
          className={
            i18n.language == "he"
              ? "config-tabs config-tabs-rtl"
              : "config-tabs"
          }
        >
          <h2>{t("configuration-configure")}</h2>
          <a
            className={this.isActive("configure")}
            onClick={() => this.setState({ toShow: "configure" })}
          >
            {t("configuration-configure-general")}
          </a>
          <a
            className={this.isActive("seo")}
            onClick={() => this.setState({ toShow: "seo" })}
          >
            {t("configuration-seo")}
          </a>
          <a
            className={this.isActive("style")}
            onClick={() => this.setState({ toShow: "style" })}
          >
            {t("configuration-custom-style")}
          </a>
          <a
            className={this.isActive("script")}
            onClick={() => this.setState({ toShow: "script" })}
          >
            {t("configuration-custom-scripts")}
          </a>
          <a
            className={this.isActive("variables")}
            onClick={() => this.setState({ toShow: "variables" })}
          >
            {t("configuration-custom-variables")}
          </a>
          <a
            className={this.isActive("accessibility")}
            onClick={() => this.setState({ toShow: "accessibility" })}
          >
            {t("configuration-custom-accessibility")}
          </a>

          <a
            className={this.isActive("unpublished")}
            onClick={() => this.setState({ toShow: "unpublished" })}
          >
            Limit and Scheduling
          </a>
        </div>
        <div className="tab-content">
          <div className="tab-content-inner">{this.handleToShow()}</div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};
// const mapDispatchToProps = (dispatch) => {
//   return {
//     actions: {
//       handleAccessibilityConfiguration: (settings) =>
//         dispatch(handleAccessibilityConfigurationAction(settings)),
//     },
//   };
// };
export default withTranslation()(connect(mapStateToProps, null)(Configuration));
