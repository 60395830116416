import React, { useState } from "react";
import { connect } from "react-redux";
import { purgeSingleEntry } from "../../../redux/actions";
import Modal from "react-bootstrap/esm/Modal";

const AnalyzeRightTable = ({
  tableId,
  selectedQuiz,
  arrayOfData,
  columns,
  actions,
  moveToSummery,
  updateTable
}) => {
  const { guid, quiz_id } = selectedQuiz;
  const [isConfirmPurgeModalOpen, setIsConfirmPurgeModalOpen] = useState(false);


  const handlePurge = () => {
    actions.purgeSingleEntry(quiz_id, guid);
    setIsConfirmPurgeModalOpen(false);
    updateTable(guid)
  };
  return (
    <>
      <Modal
        className="gallery-modal"
        size="md"
        show={isConfirmPurgeModalOpen}
        onHide={() => {
          setIsConfirmPurgeModalOpen(false);
        }}
        centered
      >
        <h3>
          Are your sure you want to purge this entry? this cannot be undo.
        </h3>
        <button className="btn btn-danger" onClick={handlePurge}>
          Confirm Purge
        </button>
        <button
          className="btn btn-light"
          onClick={() => setIsConfirmPurgeModalOpen(false)}
        >
          Cancel
        </button>
      </Modal>
      <div className="analyze-right-table-wrapper">
        <h3 className="analyze-right-table-title">{selectedQuiz.create_at}</h3>
        <div className="analyze-right-table-wrapper-inner">
          <table className={`table ${tableId}`} id={tableId}>
            <thead>
              <tr>
                <th className="rightTable-headings">No`</th>

                <th className="rightTable-headings">Question</th>

                <th className="rightTable-headings">Answer</th>
              </tr>
            </thead>
            <tbody>
              {columns.map((key, index) => {
                return (
                  <tr key={index}>
                    <td className="rightTable-cell question-number-cell">
                      {index + 1}
                    </td>
                    <td className="rightTable-cell question-cell">
                      {" "}
                      {key.header}
                    </td>
                    <td className="rightTable-cell answer-cell">
                      {selectedQuiz.meta[key.header] &&
                        selectedQuiz.meta[key.header].endsWith(".png") &&
                        selectedQuiz.meta[key.header].includes(
                          "https://smart-hippo.s3.eu-central-1.amazonaws.com/knowys/signatures/"
                        ) ? (
                        <>
                          {" "}
                          <img
                            style={{ maxWidth: "200px" }}
                            src={selectedQuiz.meta[key.header]}
                            alt="signature"
                          />
                        </>
                      ) : (
                        selectedQuiz.meta[key.header]
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <button
          className="btn btn-danger"
          onClick={() => setIsConfirmPurgeModalOpen(true)}
        >
          Delete
        </button>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      purgeSingleEntry: (quizId, guid) =>
        dispatch(purgeSingleEntry(quizId, guid)),

    },
  };
};

export default connect(null, mapDispatchToProps)(AnalyzeRightTable);
