import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import FeatureUnavailable from "../../Subscriptions/FeatureUnavailable";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import QRCode from "./qrCode";
import CopyToClipboard from "react-copy-to-clipboard";

class LinkSettings extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    valError: "",
    isPermited: true,
    copied: false,
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  handleChangeFavicon = (image) => {
    this.props.changeConfigureDetails({
      target: { name: "favicon", value: image },
    });
  };

  handleConfigElements(e) {
    const { title, slug, favicon, direction } = this.props.data;
    this.props.changeConfigureDetails(e);
    if (!e.target.value || !title || !slug) {
      this.setState({
        valError: "*One of the fields is empty, this can cause errors",
      });
    } else {
      this.setState({ valError: "" });
      this.props.changeConfigureDetails(e);
    }
  }

  render() {
    const { knowyLink = "http://localhost:3000" } = this.props;

    const { t } = this.props;

    if (!this.state.isPermited) {
      return (
        <>
          <FeatureUnavailable
            isShow="true"
            hide={() => this.setState({ isPermited: true })}
            feature="remove_branding"
          />
        </>
      );
    }
    return (
      <div>
        <h2>{t("shareSettings-link")}</h2>
        <div className="fields">
          <label>Knowy link:</label>
          <input
            type="text"
            name="KnowyLink"
            value={knowyLink}
            className="form-control"
          />
          <CopyToClipboard
            text={knowyLink}
            onCopy={() => this.setState({ copied: true })}
          >
            <a title="copy-path" className="copy-path">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5 0H5.5C4.83696 0 4.20108 0.263398 3.73224 0.732239C3.2634 1.20108 3 1.83696 3 2.5V3H2.5C1.83696 3 1.20108 3.2634 0.732239 3.73224C0.263398 4.20108 0 4.83696 0 5.5V13.5C0 14.163 0.263398 14.7989 0.732239 15.2678C1.20108 15.7366 1.83696 16 2.5 16H10.5C11.163 16 11.7989 15.7366 12.2678 15.2678C12.7366 14.7989 13 14.163 13 13.5V13H13.5C14.163 13 14.7989 12.7366 15.2678 12.2678C15.7366 11.7989 16 11.163 16 10.5V2.5C16 1.83696 15.7366 1.20108 15.2678 0.732239C14.7989 0.263398 14.163 0 13.5 0ZM12 13.5C12 13.8978 11.842 14.2794 11.5607 14.5607C11.2794 14.842 10.8978 15 10.5 15H2.5C2.10218 15 1.72064 14.842 1.43933 14.5607C1.15803 14.2794 1 13.8978 1 13.5V5.5C1 5.10218 1.15803 4.72064 1.43933 4.43933C1.72064 4.15803 2.10218 4 2.5 4H3V10.5C3 11.163 3.2634 11.7989 3.73224 12.2678C4.20108 12.7366 4.83696 13 5.5 13H12V13.5ZM15 10.5C15 10.8978 14.842 11.2794 14.5607 11.5607C14.2794 11.842 13.8978 12 13.5 12H5.5C5.10218 12 4.72064 11.842 4.43933 11.5607C4.15803 11.2794 4 10.8978 4 10.5V2.5C4 2.10218 4.15803 1.72064 4.43933 1.43933C4.72064 1.15803 5.10218 1 5.5 1H13.5C13.8978 1 14.2794 1.15803 14.5607 1.43933C14.842 1.72064 15 2.10218 15 2.5V10.5Z"
                  fill="#78889F"
                />
              </svg>
            </a>
          </CopyToClipboard>
          {this.state.copied ? (
            <span style={{ color: "#BE1EB9" }}>Copied.</span>
          ) : null}
        </div>
        <div className="fields">
          <label>Social share:</label>
          <br />
          <div className="row">
            <div className="col-4">
              <FacebookShareButton url={knowyLink} quote={""}>
                <FacebookIcon size={64} />
              </FacebookShareButton>
            </div>
            <div className="col-4">
              <LinkedinShareButton url={knowyLink} quote={""}>
                <LinkedinIcon size={64} />
              </LinkedinShareButton>
            </div>
            <div className="col-4">
              <TwitterShareButton url={knowyLink} quote={""}>
                <TwitterIcon size={64} />
              </TwitterShareButton>
            </div>
          </div>
        </div>
        <div className="fields">
          <label>QR code:</label>
          <QRCode url={knowyLink} title={this.props.data.title} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

export default withTranslation()(connect(mapStateToProps, null)(LinkSettings));
