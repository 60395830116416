import React from "react";
import Form from "react-bootstrap/Form";
import PlatformSelect from "../PlatformSelect";

class SelectSize extends React.Component {
  state = {
    mode: {
      value: "desktop",
    },
  };
  handleChange = (e) => {
    const name = this.handleInputName(e.target.name);
    const { id, toChange } = this.props.changedDetails;
    if (!id) return this.props.handleChange(e.target.value, name);
    return this.props.handleChange(e.target.value, id, name);
  };

  handleInputName = (name) => {
    //Font size
    if (name == "fontSize" && this.state.mode.value == "desktop")
      name = "fontSize";
    if (name == "fontSize" && this.state.mode.value == "mobile")
      name = "mobileFontSize";
    if (name == "fontSize" && this.state.mode.value == "tablet")
      name = "tabletFontSize";

    //Width
    if (name == "width" && this.state.mode.value == "desktop") name = "width";
    if (name == "width" && this.state.mode.value == "mobile")
      name = "mobileWidth";
    if (name == "width" && this.state.mode.value == "tablet")
      name = "tabletWidth";

    //Height
    if (name == "height" && this.state.mode.value == "desktop") name = "height";
    if (name == "height" && this.state.mode.value == "mobile")
      name = "mobileHeight";
    if (name == "height" && this.state.mode.value == "tablet")
      name = "tabletHeight";

    //AlertLength
    if (name == "alertLength" && this.state.mode.value == "desktop")
      name = "alertLength";
    if (name == "alertLength" && this.state.mode.value == "mobile")
      name = "mobileAlertLength";
    if (name == "alertLength" && this.state.mode.value == "tablet")
      name = "tabletAlertLength";

    //Size
    if (name == "size" && this.state.mode.value == "desktop") name = "size";
    if (name == "size" && this.state.mode.value == "mobile")
      name = "mobileSize";
    if (name == "size" && this.state.mode.value == "tablet")
      name = "tabletSize";

    // textContainerSize
    if (name == "textContainerSize" && this.state.mode.value == "desktop")
      name = "textContainerSize";
    if (name == "textContainerSize" && this.state.mode.value == "mobile")
      name = "mobileTextContainerSize";
    if (name == "textContainerSize" && this.state.mode.value == "tablet")
      name = "tabletTextContainerSize";

    //picSize
    if (name == "picSize" && this.state.mode.value == "desktop")
      name = "picSize";
    if (name == "picSize" && this.state.mode.value == "mobile")
      name = "mobilePicSize";
    if (name == "picSize" && this.state.mode.value == "tablet")
      name = "tabletPicSize";

    // rowFontSize
    if (name == "rowFontSize" && this.state.mode.value == "desktop")
      name = "rowFontSize";
    if (name == "rowFontSize" && this.state.mode.value == "mobile")
      name = "mobileRowFontSize";
    if (name == "rowFontSize" && this.state.mode.value == "tablet")
      name = "tabletRowFontSize";

    // columnFontSize
    if (name == "columnFontSize" && this.state.mode.value == "desktop")
      name = "columnFontSize";
    if (name == "columnFontSize" && this.state.mode.value == "mobile")
      name = "mobileColumnFontSize";
    if (name == "columnFontSize" && this.state.mode.value == "tablet")
      name = "tabletColumnFontSize";

    // productTitleFontSize
    if (name == "titleFontSize" && this.state.mode.value == "desktop")
      name = "titleFontSize";
    if (name == "titleFontSize" && this.state.mode.value == "mobile")
      name = "titleMobileFontSize";
    if (name == "titleFontSize" && this.state.mode.value == "tablet")
      name = "titleTabletFontSize";

    // productDescriptionFontSize
    if (name == "descriptionFontSize" && this.state.mode.value == "desktop")
      name = "descriptionFontSize";
    if (name == "descriptionFontSize" && this.state.mode.value == "mobile")
      name = "descriptionMobileFontSize";
    if (name == "descriptionFontSize" && this.state.mode.value == "tablet")
      name = "descriptionTabletFontSize";

    // productPriceFontSize
    if (name == "priceFontSize" && this.state.mode.value == "desktop")
      name = "priceFontSize";
    if (name == "priceFontSize" && this.state.mode.value == "mobile")
      name = "priceMobileFontSize";
    if (name == "priceFontSize" && this.state.mode.value == "tablet")
      name = "priceTabletFontSize";

    // productPriceFontSize
    if (name == "linkButtonFontSize" && this.state.mode.value == "desktop")
      name = "linkButtonFontSize";
    if (name == "linkButtonFontSize" && this.state.mode.value == "mobile")
      name = "linkButtonMobileFontSize";
    if (name == "linkButtonFontSize" && this.state.mode.value == "tablet")
      name = "linkButtonTabletFontSize";

    return name;
  };

  setMode = (mode) => {
    this.setState({ mode });
  };

  render() {
    let {
      value,
      placeholder,
      label,
      name,
      fromTheme,
      showPlatformSelect = true,
    } = this.props;
    //if (name == "height") console.log(value);
    let inputClassName = "";
    const { mode } = this.state;
    if (typeof value == "object") {
      if (
        (mode.value == "mobile" && value.mobile == value.desktop) ||
        (mode.value == "tablet" && value.tablet == value.desktop)
      ) {
        inputClassName = "input-responsive-default-value";
      }
      value = value[mode.value];
    }
    return (
      <>
        {fromTheme ? (
          <div
            className={
              showPlatformSelect ? "row platform-selection-container" : ""
            }
          >
            {" "}
            <h3 className={showPlatformSelect ? "col-6" : ""}>{label}</h3>
            {showPlatformSelect ? (
              <PlatformSelect
                showPlatformSelect={showPlatformSelect}
                setMode={this.setMode}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          ""
        )}
        <div className="d-flex" style={{ marginTop: "5px" }}>
          {!fromTheme ? (
            <></>
          ) : (
            <input
              type="number"
              name={name}
              value={this.props.isTimesFour ? value / 4 : value}
              className={`${inputClassName} fontSize w-25 `}
              onChange={(e) => {
                let value = e.target.value;
                if (this.props.timesFourOnlyInput) value = value / 4;
                this.handleChange({ target: { value } });
              }}
            />
          )}
          <Form.Range
            name={name}
            max={this.props.isMilisecons ? 10 : 100}
            step={this.props.isMilisecons ? 0.1 : 1}
            value={
              this.props.isTimesFour || this.props.timesFourOnlyInput
                ? value / 4
                : value
            }
            className={fromTheme ? "fontSizerange" : "fontSize-range"}
            onChange={this.handleChange}
          />
        </div>
      </>
    );
  }
}
export default SelectSize;
