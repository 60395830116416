import React, { useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import Fonts from "../../../../../../utils/Fonts/Fonts";
import { withTranslation } from "react-i18next";
import SelectSize from "../../../../SelectSize";
import ColorPicker from "../../../../ColorPicker";

function MatrixExtraData(props) {
  const {
    data,
    matrixData = {},
    id,
    handleChangeMatrixData,
    stepData,
    t,
  } = props;
  if (!stepData.style.matrix)
    stepData.style.matrix = {
      rowColor: "#000",
      columnColor: "#000",
      rowFontSize: "28",
      mobileRowFontSize: "28",
      tabletRowFontSize: "28",
      columnFontSize: "28",
      mobileColumnFontSize: "28",
      tabletColumnFontSize: "28",
      fontFamily: "",
      borderSize: "0",
      borderColor: "#000",
    };
  const { type } = data;
  const { columns = [] } = matrixData;
  const drawExtraDataByQuizType = () => {
    switch (type) {
      case "scored":
        return columns.map((column, index) => {
          const { value, score } = column;
          return (
            <>
              <h2>{value.replace(/(<([^>]+)>)/gi, "")}</h2>
              <div className="form-group">
                <label>Score:</label>
                <input
                  type="number"
                  name="score"
                  className="form-control"
                  onChange={(e) =>
                    handleChangeMatrixData(
                      {
                        target: {
                          name: "score",
                          value: parseInt(e.target.value),
                        },
                      },
                      id,
                      index,
                      "columns"
                    )
                  }
                  value={score}
                />
              </div>
            </>
          );
        });
      case "personality":
        return <></>;
      case "assessment":
        return <></>;
    }
  };

  return (
    <div className="matrix-extra-data">
      <div>{drawExtraDataByQuizType()}</div>
    </div>
  );
}

export default withTranslation()(MatrixExtraData);
