import React, { useEffect, useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import CountrySelect from "./CountrySelect";
import { IoTrashOutline } from "react-icons/io5";
import InputValidationConstans from "../../../../utils/json/inputValidationConstans.json";
import TextInput from "react-autocomplete-input";
import AcTest from "../../../AcTest/Index";
import SelectSize from "../../SelectSize";

function AddContactInputField(props) {
  const {
    step,
    deleteInput,
    id,
    toggleError,
    handleInputName,
    index,
    handleInputVariableName,
    handleContactInputs,
    allInputsTypes,
    type,
    dataWithVariables,
  } = props;
  const [inputCollapse, setInputCollapse] = useState(false);
  const [isInputOther, setIsInputOther] = useState(false);

  useEffect(() => {}, [dataWithVariables]);

  useEffect(() => {
    if (step.validationType === "other") {
      setIsInputOther(true);
    } else {
      setIsInputOther(false);
    }
  }, [step.validationType]);

  const checkValidationsConditions = (e) => {
    let { name, value } = e.target;
    value = parseInt(value || "0");
    let isValidationError = false;

    switch (name) {
      case "minimum":
        isValidationError = value > parseInt(step.maximum || "0");
        break;
      case "maximum":
        isValidationError = value < parseInt(step.minimum || "0");
        break;
      case "minimumCharacters": {
        let maximumCharacters = parseInt(step.maximumCharacters || "0");
        if (!maximumCharacters) {
          maximumCharacters =
            InputValidationConstans.maximumCharacters[type] || 0;
        }
        isValidationError = value > maximumCharacters;
        break;
      }
      case "maximumCharacters": {
        let maximumCharacters = parseInt(value || "0");
        if (!maximumCharacters) {
          maximumCharacters =
            InputValidationConstans.maximumCharacters[step.type] || 0;
        }
        isValidationError =
          maximumCharacters < parseInt(step.minimumCharacters || "0");
        break;
      }
    }
    handleContactInputs(e, step.id, id);
    handleContactInputs(
      { target: { checked: isValidationError, name: "isValidationError" } },
      step.id,
      id
    );
  };

  return (
    <>
      <div className="feildHeader">
        <span
          className="pointer"
          onClick={() => setInputCollapse(!inputCollapse)}
        >
          {handleInputName(step, index)}
        </span>

        <IoTrashOutline
          type="button"
          title="rightBar-customize-override"
          onClick={() => deleteInput(id, step.id)}
          color={"rgba(0, 68, 179, 0.42)"}
          size={22}
        />
      </div>
      <Collapse in={inputCollapse}>
        <div className="fieldOptions" id={` item${index}`}>
          <h4>Type:</h4>
          <select
            value={step.validationType}
            onChange={(e) => {
              if (
                allInputsTypes.find(
                  (type) => type !== "other" && type == e.target.value
                )
              )
                return;
              handleContactInputs(e, step.id, id);
            }}
            name="type"
          >
            <option
              style={
                allInputsTypes.find((type) => type == "firstName")
                  ? { backgroundColor: "lightgrey" }
                  : {}
              }
              disabled={allInputsTypes.find((type) => type == "firstName")}
              value="firstName"
            >
              First Name
            </option>
            <option
              style={
                allInputsTypes.find((type) => type == "lastName")
                  ? { backgroundColor: "lightgrey" }
                  : {}
              }
              disabled={allInputsTypes.find((type) => type == "lastName")}
              value="lastName"
            >
              Last Name
            </option>
            <option
              style={
                allInputsTypes.find((type) => type == "email")
                  ? { backgroundColor: "lightgrey" }
                  : {}
              }
              disabled={allInputsTypes.find((type) => type == "email")}
              value="email"
            >
              Email
            </option>
            <option
              style={
                allInputsTypes.find((type) => type == "phoneNumber")
                  ? { backgroundColor: "lightgrey" }
                  : {}
              }
              disabled={allInputsTypes.find((type) => type == "phoneNumber")}
              value="phoneNumber"
            >
              Phone Number
            </option>
            <option value="other">Other</option>
          </select>
          {step.validationType === "other" ? (
            <div className="col-sm">
              <select
                className="form-control"
                value={step.type}
                onChange={(e) => handleContactInputs(e, step.id, id)}
                name="validationType"
              >
                <option value="text">Text</option>
                <option value="number">Number</option>
                <option value="url">Url</option>
                <option value="email">Email</option>
              </select>
            </div>
          ) : (
            <></>
          )}
          <h4>Label:</h4>
          {/* <input
            type="text"
            name="label"
            value={step.label}
            placeholder="Name:"
            onBlur={(e) => handleInputVariableName(e, step.id, id)}
            onChange={(e) => props.handleContactInputs(e, step.id, id)}
          /> */}

          <AcTest
            changeOnSelect={(trigger, slug) => "{{" + slug + "}}"}
            trigger={["{{"]}
            options={{ "{{": [...dataWithVariables] }}
            type="text"
            name="label"
            value={step.label}
            placeholder="Name:"
            className="autocomplete-form-input"
            rows={1}
            maxOptions={50}
            onBlur={(e) =>
              handleInputVariableName(
                { target: { name: "label", value: e } },
                step.id,
                id
              )
            }
            onChange={(e) =>
              props.handleContactInputs(
                { target: { name: "label", value: e } },
                step.id,
                id
              )
            }
          />

          <h4>Placeholder:</h4>
          {/* <input
            type="text"
            placeholder="Name:"
            name="placeholder"
            value={step.placeholder}
            onChange={(e) => handleContactInputs(e, step.id, id)}
          /> */}

          <AcTest
            changeOnSelect={(trigger, slug) => "{{" + slug + "}}"}
            trigger={["{{"]}
            options={{ "{{": [...dataWithVariables] }}
            type="text"
            name="placeholder"
            value={step.placeholder}
            placeholder="Placeholder:"
            className="autocomplete-form-input"
            rows={1}
            maxOptions={50}
            onChange={(e) =>
              props.handleContactInputs(
                { target: { name: "placeholder", value: e } },
                step.id,
                id
              )
            }
          />

          <label>
            <input
              type="checkbox"
              name="required"
              checked={step.required}
              onChange={(e) => handleContactInputs(e, step.id, id)}
            />{" "}
            Required
          </label>

          {isInputOther ? (
            <label>
              <input
                type="checkbox"
                checked={step.withValidation}
                name="withValidation"
                onChange={(e) => handleContactInputs(e, step.id, id)}
              />{" "}
              Validation
            </label>
          ) : (
            <></>
          )}
          <Collapse in={step.withValidation}>
            {["text", "email", "url"].includes(step.type) ? (
              <div>
                <label>Mimimum Characters</label>
                <input
                  type="number"
                  name="minimumCharacters"
                  value={step.minimumCharacters}
                  onChange={checkValidationsConditions}
                />{" "}
                <label>Maximum Characters</label>
                <input
                  type="number"
                  name="maximumCharacters"
                  placeholder={
                    InputValidationConstans.maximumCharacters[step.type]
                  }
                  value={step.maximumCharacters}
                  onChange={checkValidationsConditions}
                />{" "}
                <span style={{ color: "red" }}>
                  {step.isValidationError ? "Validation is Impossible" : ""}
                </span>
              </div>
            ) : step.type == "number" ? (
              <div>
                <label>Minimum</label>
                <input
                  type="number"
                  name="minimum"
                  value={step.minimum}
                  onChange={checkValidationsConditions}
                />{" "}
                <label>Maximum</label>
                <input
                  type="number"
                  name="maximum"
                  value={step.maximum}
                  onChange={checkValidationsConditions}
                />{" "}
                <label>Maximum Decimals</label>
                <input
                  type="number"
                  name="maximumDecimals"
                  value={step.maximumDecimals}
                  onChange={checkValidationsConditions}
                />{" "}
                <span style={{ color: "red" }}>
                  {step.isValidationError ? "Validation is Impossible" : ""}
                </span>
              </div>
            ) : (
              <></>
            )}
          </Collapse>
          <label>
            <input
              type="checkbox"
              name="hideLabel"
              checked={step.hideLabel}
              onChange={(e) => handleContactInputs(e, step.id, id)}
            />{" "}
            Hide Label
          </label>
          {isInputOther ? (
            <label>
              <input
                type="checkbox"
                name="isTextarea"
                checked={step.isTextarea}
                onChange={(e) => handleContactInputs(e, step.id, id)}
              />{" "}
              Make Input Text-Area
            </label>
          ) : (
            <></>
          )}
          {step.isTextarea ? (
            <div className="fontStyle">
              <SelectSize
                fromTheme={true}
                name="textareaNumberOfRows"
                showPlatformSelect={false}
                label={"Textarea number of rows"}
                value={step.textareaNumberOfRows || 2}
                handleChange={(size, name = "textareaNumberOfRows") => {
                  handleContactInputs(
                    { target: { name, value: size } },
                    step.id,
                    id
                  );
                }}
                changedDetails={{ toChange: "" }}
              />
            </div>
          ) : (
            <></>
          )}
          {step.type === "phoneNumber" ? (
            <>
              <label>
                <input
                  type="checkbox"
                  name="hideCountryList"
                  checked={step.hideCountryList}
                  onChange={(e) => handleContactInputs(e, step.id, id)}
                />{" "}
                Hide country list
              </label>

              <div>
                <h4>Default country:</h4>
                <CountrySelect
                  onChange={(e) => props.handleContactInputs(e, step.id, id)}
                  defaultCountry={step.defaultCountry}
                  context="phone"
                />
              </div>
            </>
          ) : (
            <></>
          )}
          {toggleError(step)}
          <div>
            <label>
              <input
                type="checkbox"
                name="useDefaultValue"
                checked={step.useDefaultValue}
                onChange={(e) => handleContactInputs(e, step.id, id)}
              />{" "}
              Default Value
            </label>
            <Collapse in={step.useDefaultValue}>
              <div>
                {/* <input
                  type="text"
                  className="form-control"
                  value={step.defaultValue}
                  name="defaultValue"
                  onChange={(e) => handleContactInputs(e, step.id, id)}
                /> */}
                <AcTest
                  changeOnSelect={(trigger, slug) => "{{" + slug + "}}"}
                  trigger={["{{"]}
                  options={{ "{{": [...dataWithVariables] }}
                  type="text"
                  value={step.defaultValue}
                  name="defaultValue"
                  placeholder="Default value:"
                  className="autocomplete-form-input"
                  rows={1}
                  maxOptions={50}
                  onBlur={(e) =>
                    handleInputVariableName(
                      { target: { name: "defaultValue", value: e } },
                      step.id,
                      id
                    )
                  }
                  onChange={(e) =>
                    props.handleContactInputs(
                      { target: { name: "defaultValue", value: e } },
                      step.id,
                      id
                    )
                  }
                />
              </div>
            </Collapse>
          </div>
        </div>
      </Collapse>
    </>
  );
}

export default AddContactInputField;
