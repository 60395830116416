import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createWorkSpaceAction, createFolderAndMove, getUserQuizes, getWorkSpacesAction } from "../../../redux/actions";
import Modal from "react-bootstrap/Modal";
import { withTranslation } from "react-i18next";
function WorkspacesModal(props) {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [textField, setTextField] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const { workspaces } = props;

    useEffect(() => {
        props.actions.getWorkSpaces();
    }, [workspaces]);


    const handleChange = (e) => {
        const { value } = e.target;
        setTextField(value);
    };

    const handleClick = () => {
        if (!textField) return setErrorMessage("Workspace name is required.");
        props.actions.createWorkSpace(textField);
        setIsOpen(false);
    };

    const createAndMove = () => {
        // if (!textField) return setErrorMessage("Folder name is required.");
        // alert(("move"))
        // // props.actions.createFolderAndMove(textField, quizId.id);
        // setIsOpen(false);
        // props.hide();
    }

    const { t, withoutModal, quizId, orgArray, orgId, permitions } = props;
    const orgObj = orgArray ? orgArray.find((el) => el.id === orgId) : null;
    const orgKeys = orgObj && orgObj.keys ? orgObj.keys.split(",") : [];
    return (
        <>
            {!withoutModal ? (
                <>
                    {permitions &&
                        permitions.add_users &&
                        orgKeys.includes("create_workspace") ? (

                        <button onClick={() => setIsOpen(true)} className="addFolder myBtn_multi">
                            <span>+</span>
                            Create
                        </button>) : (<></>)}

                    <Modal show={modalIsOpen} onHide={() => setIsOpen(false)} centered>
                        <h2> New Workspace </h2>
                        {chooseDirData(textField, handleChange, handleClick, createAndMove, t, setIsOpen)}
                    </Modal>
                </>
            ) :

                (
                    <>  {chooseDirData(textField, handleChange, handleClick, createAndMove, t, setIsOpen, withoutModal)}</>
                )}
        </>
    );
}

const chooseDirData = (textField, handleChange, handleClick, createAndMove, t, setIsOpen, withoutModal) => {
    return (
        <>
            <input
                type="text"
                name="textField"
                autoFocus
                value={textField}
                onChange={handleChange}
                placeholder={"workspace name"}
            />
            <input
                style={{ marginBottom: "50px" }}
                type="button"
                value={!withoutModal ? t("publish-popup-btn-create") : t("publish-popup-btn-create-and-move")}
                onClick={handleClick}
                className={withoutModal && textField ? "btn-popup" : textField ? "btn-popup" : "btn-popup disabled"}
            />

            {/*<span className="close close_multi" onClick={() => setIsOpen(false)}>*/}
            {/*        {t("general-cancel")}*/}
            {/*      </span>*/}
        </>
    )

}

const mapStateToProps = (state) => {
    let { name, orgArray, orgId, workspaces } = state.loginDetails;
    return { name, orgArray, orgId, permitions: state.permitions, workspaces };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            getUserQuizes: () => dispatch(getUserQuizes()),
            createWorkSpace: (name) => dispatch(createWorkSpaceAction(name)),
            getWorkSpaces: () => dispatch(getWorkSpacesAction()),

        },
    };
};

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(WorkspacesModal)
);
