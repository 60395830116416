
import { mainAxios } from '../../Axios/mainAxios'
import { apiUrl } from '../../config'


export async function sendResetPasswordEmail(email) {
    const { data } = await mainAxios.post(`${apiUrl}/auth/sendResetPassword`, { email })
    return data
}

export async function validateMailToken(resetPasswordToken) {
    const { data } = await mainAxios.post(`${apiUrl}/auth/verifyResetPasswordPage`, { resetPasswordToken })
    return data
}

export async function resetPassword(newPassword, resetPasswordToken) {
    const { data } = await mainAxios.post(`${apiUrl}/auth/resetPassword`, { newPassword, resetPasswordToken })
    return data
}

