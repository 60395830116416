export class Contact {
  constructor(title, counter) {
    this.id = `C_${Math.round(Math.random() * 999999)}`;
    this.type = "contact";
    this.counter = counter;
    this.stepIndex = 1;
    this.title = title;
    this.required = false;
    this.buttonText = "Send";
    this.inputs = [];
    this.conditions = [];
    this.style = {
      title: {
        color: "#000",
        fontWeight: "",
        fontSize: "",
        fontType: "",
        fontFamily: "",
        fontStyle: "",
        padding: { top: "0", right: "0", bottom: "0", left: "0" },
        textAlign: "center",
      },
      subtitle: {
        color: "#000",
        fontWeight: "",
        fontSize: "",
        fontType: "",
        fontFamily: "",
        fontStyle: "",
        padding: { top: "0", right: "0", bottom: "0", left: "0" },
        textAlign: "center",
      },
      media: {
        size: "25",
        borderSize: "0",
        borderRadius: "0",
        borderColor: "",
        padding: { top: "0", right: "0", bottom: "0", left: "0" },
      },
      button: {
        color: "#000",
        background: "",
        borderSize: "0",
        borderColor: "",
        borderRadius: "0",
        hoverColor: "",
        hoverBackground: "",
        hoverBorderSize: "0",
        hoverBorderColor: "",
        hoverBorderRadius: "0",
        fontWeight: "",
        fontSize: "",
        fontType: "",
        fontFamily: "",
        fontStyle: "",
        padding: { top: "0", right: "0", bottom: "0", left: "0" },
        textAlign: "center",
      },
      forms: {
        color: "rgba(0, 0, 0, 1)",
        fontWeight: "",
        fontSize: "",
        fontType: "",
        fontFamily: "Open Sans",
        fontStyle: "",
        padding: { top: "0", right: "0", bottom: "0", left: "0" },
        textAlign: "center",
        formTextAlign: "center",
        formWidth: "auto",
      },
    };
    this.required = true;
    this.useFormCustomStyle = false;
  }
}

export class Input {
  constructor(id) {
    this.id = id;
    this.type = "text";
    this.validationType = "other";
    this.errorMessage = "Field is required.";
    this.required = false;
    this.name = `I_M${Math.round(Math.random() * 999999)}`;
    this.placeholder = "Type...";
    this.label = "Input";
  }
}
