import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import React from "react";

import ReactSelect from "react-select";
import en from "react-phone-number-input/locale/en.json";
import { useEffect } from "react";
import { getDefaultCountriesOptions } from "../../../../utils/CountriesOptions";

const CountrySelectTemplate = ({ value, onChange, labels, ...rest }) => (
  <select
    {...rest}
    value={value}
    onChange={(event) => onChange(event.target.value || undefined)}
  >
    <option value="">{labels["ZZ"]}</option>
    {getCountries().map((country) => (
      <option key={country} value={country}>
        {labels[country]} +{getCountryCallingCode(country)}
      </option>
    ))}
  </select>
);

export default function CountrySelect(props) {
  let {
    onChange,
    defaultCountry = "",
    context = "phone",
    valueType = "countryName",
    countriesList,
    placeholder,
    isMulti = true,
    style = {},
    controlShouldRenderValue = false,
    hideSelectedOptions = false,
    className = "",
  } = props;

  valueType = valueType == "countryName" ? 1 : 0;
  if (context === "phone") {
    return (
      <CountrySelectTemplate
        labels={en}
        value={defaultCountry}
        onChange={(value) =>
          onChange({ target: { value, name: "defaultCountry" } })
        }
      />
    );
  } else if (context === "location") {
    const countriesOptions = getDefaultCountriesOptions(valueType);

    return (
      <ReactSelect
        options={countriesOptions}
        classNamePrefix={className}
        isMulti={isMulti}
        isSearchable={true}
        placeholder={placeholder || "Choose countries..."}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            margin: "auto",
            maxWidth: "100%",

            ...style,
          }),
          option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            color: "#000 !important",
          }),

          multiValueLabel: (styles, { data }) => ({
            ...styles,
            backgroundColor: "inherit",
            color: "#000 !important",
            opacity: "0.8 !important",
          }),
          multiValueRemove: (styles, { data }) => ({
            ...styles,
            backgroundColor: "inherit",
            color: "#000 !important",
            opacity: "0.8 !important",
          }),
          multiValue: (styles, { data }) => ({
            ...styles,
            backgroundColor: "inherit",
            color: "#000 !important",
            opacity: "0.8 !important",
          }),
        }}
        onChange={(value) =>
          onChange({ target: { value, name: "countriesList" } })
        }
        value={countriesList || countriesOptions}
        controlShouldRenderValue={controlShouldRenderValue}
        hideSelectedOptions={hideSelectedOptions}
      />
    );
  }
}
