import React, { useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import filtersConfig from "./filtersConfig";
import { CgToggleSquare, CgToggleSquareOff } from "react-icons/cg";
import { BsTrashFill } from "react-icons/bs";
// import SimpleReactCalendar from "simple-react-calendar";

const AnalyseSingleFilters = (props) => {
  const {
    variables,
    arrayOfData,
    t,
    setFiltersObject,
    filtersObject,
    resetFlag,
    index,
    filterArray,
    AddRemoveFilters,
  } = props;

  const [questionsStepsArr, setQuestionsStepsArr] = useState([]);
  const [matrixArr, setMatrixArr] = useState([]);
  const [contactsArr, setContactsArr] = useState([]);
  const [orToggle, setOrToggle] = useState(false);

  const [operatorsArr, setOperatorsArr] = useState(
    filtersConfig.operators.default
  );

  const [valueFiledType, setValueFiledType] = useState(
    <input
      type="text"
      className="form-control value-text-filed mw-100 text-justify"
    />
  );

  const initialOption = useRef(null);

  useEffect(() => {
    const questionsSteps = arrayOfData.filter((itr) => itr.type === "question");
    const matrixOptions = arrayOfData.reduce((array, itr) => {
      if (itr.type != "question") {
        return array;
      }
      const { slideName } = itr;
      if (itr.questionType == "matrix") {
        itr.matrix.rows.forEach((row) => {
          array.push({
            slideName: `${slideName}_${row.value.replace(/(<([^>]+)>)/gi, "")}`,
            id: row.id,
            columns: itr.matrix.columns,
          });
        });
      }
      return array;
    }, []);
    let contactSteps = arrayOfData.filter((itr) => itr.type === "contact");
    const allInputs = contactSteps.reduce((allInputs, step) => {
      const { inputs = [] } = step;
      inputs.forEach((input) => {
        if (input.type === "location") {
          input.inputs.forEach((locationInput) => {
            if (locationInput.show) {
              const newLocationInput = JSON.parse(
                JSON.stringify(locationInput)
              );
              newLocationInput.label = `${input.label}_${newLocationInput.label}`;
              allInputs.push(newLocationInput);
            }
          });
        } else allInputs.push(input);
      });
      return allInputs;
    }, []);
    setMatrixArr(matrixOptions);
    setQuestionsStepsArr(questionsSteps);
    setContactsArr(allInputs);
    // changeOperator({
    //   target: {
    //     value: JSON.stringify({
    //       value: variables[0].name,
    //       name: variables[0].name,
    //       type: "variable",
    //     }),
    //   },
    // });
  }, []);

  const handleChangeFilterObject = (type, value) => {
    const tempFilterObject = Object.assign(filterArray);
    filterArray[index][type] = value;
    setFiltersObject(tempFilterObject);
  };

  const changeOperator = (e) => {
    if (!initialOption.current.disabled) {
      initialOption.current.disabled = true;
    }
    const varObj = JSON.parse(e.target.value);
    if (varObj.type === "variable") {
      setOperatorsArr(filtersConfig.operators.text);
      setSelectOptions("text");
      handleChangeFilterObject("configType", "text");
      handleChangeFilterObject("filterDbColumnType", "metadata");
      handleChangeFilterObject("filterField", varObj.value);
    } else if (varObj.type === "contact") {
      const findInput = contactsArr.find((itr) => itr.label === varObj.value);
      if (findInput.type === "select") {
        setOperatorsArr(filtersConfig.operators.select);
        setSelectOptions("select", findInput.selectOptions, "name");
        handleChangeFilterObject("configType", "select");
      } else if (findInput.type === "date") {
        setOperatorsArr(filtersConfig.operators.date);
        setSelectOptions("date");
        handleChangeFilterObject("filterField", findInput.id);
        handleChangeFilterObject("configType", "date");
      } else if (findInput.type === "checkbox") {
        setOperatorsArr(filtersConfig.operators.checkbox);
        setSelectOptions("checkbox");
        handleChangeFilterObject("filterType", "checked");
        // setSelectOptions("select", findInput.selectOptions, "name");
        handleChangeFilterObject("configType", "checkbox");
      } else if (findInput.type === "signature") {
        setOperatorsArr(filtersConfig.operators.signature);
        setSelectOptions("signature");
        handleChangeFilterObject("configType", "signature");
        handleChangeFilterObject("filterType", "signed");
        // } else if (findInput.type !== "phoneNumber") {
      } else if (findInput.type === "slider") {
        setOperatorsArr(filtersConfig.operators.slider);
        setSelectOptions("slider");
        handleChangeFilterObject("configType", "slider");
      } else {
        setOperatorsArr(filtersConfig.operators.text);
        setSelectOptions("text");
        handleChangeFilterObject("configType", "text");
      }

      handleChangeFilterObject("filterDbColumnType", "metadata");
      handleChangeFilterObject(
        "filterField",
        varObj.value.replace(/<[^>]+>/g, "")
      );
    } else if (varObj.type === "system") {
      if (varObj.value === "date") {
        setOperatorsArr(filtersConfig.operators.date);
        setSelectOptions("date");
        handleChangeFilterObject("filterField", "quiz_visits.create_at");
        handleChangeFilterObject("configType", "date");
      } else if (varObj.value === "finish") {
        setOperatorsArr(filtersConfig.operators.finish);
        setSelectOptions("finish");
        handleChangeFilterObject("filterField", "quiz_results.reached_result");
        handleChangeFilterObject("configType", "finish");
        handleChangeFilterObject("filterType", "yes");
      } else if (varObj.value === "score") {
        setOperatorsArr(filtersConfig.operators.score);
        setSelectOptions("score");
        handleChangeFilterObject("filterField", "quiz_results.score");
        handleChangeFilterObject("configType", "score");
        handleChangeFilterObject("filterType", "yes");
      } else {
        setOperatorsArr(filtersConfig.operators.system);
        setSelectOptions(varObj.value);
        const filterField =
          varObj.value === "mobile/desktop"
            ? `quiz_visits.'${varObj.value}'`
            : `quiz_visits.${varObj.value}`;
        handleChangeFilterObject("filterField", filterField);
        handleChangeFilterObject("configType", "system");
      }
    } else if (varObj.type === "matrixAnswer") {
      setOperatorsArr(filtersConfig.operators.default);
      setSelectOptions("matrixAnswer", varObj.columns, "value");
      handleChangeFilterObject("configType", "matrixAnswer");
      handleChangeFilterObject("filterField", varObj.slideName);
    } else {
      const findQusetion = questionsStepsArr.find((q) => q.id == varObj.value);
      if (findQusetion.questionType === "rating") {
        setOperatorsArr(filtersConfig.operators.ratings);
        setSelectOptions("rating");
        handleChangeFilterObject("configType", "ratings");
      } else if (findQusetion.questionType === "slider") {
        setOperatorsArr(filtersConfig.operators.slider);
        setSelectOptions("slider");
        handleChangeFilterObject("configType", "slider");
      } else {
        setSelectOptions("select", findQusetion.options, "title");
        setOperatorsArr(filtersConfig.operators.select);
        handleChangeFilterObject("configType", "select");
      }
      handleChangeFilterObject("filterDbColumnType", "answers");
      handleChangeFilterObject(
        "filterField",
        findQusetion.title.replace(/<[^>]+>/g, "")
      );
    }
  };

  const setSelectOptions = (type, options, label) => {
    if (type === "text") {
      setValueFiledType(
        <input
          onChange={(e) =>
            handleChangeFilterObject("filterValue", e.target.value)
          }
          type="text"
          className="form-control value-text-filed mw-100 text-justify"
        />
      );
      handleChangeFilterObject("filterValue", "");
      handleChangeFilterObject("filterType", "is_exect");
    } else if (type === "select") {
      setValueFiledType(
        <select
          name=""
          onChange={(e) =>
            handleChangeFilterObject("filterValue", e.target.value)
          }
          className=" mw-100 text-justify"
        >
          {options ? (
            options.map((itr) => (
              <option key={itr.id} value={itr[label].replace(/<[^>]+>/g, "")}>
                {itr[label].replace(/<[^>]+>/g, "")}
              </option>
            ))
          ) : (
            <></>
          )}
        </select>
      );
      handleChangeFilterObject(
        "filterValue",
        options && options[0] ? options[0][label].replace(/<[^>]+>/g, "") : ""
      );
      handleChangeFilterObject("filterType", "is");
    } else if (type === "matrixAnswer") {
      setValueFiledType(
        <select
          name=""
          onChange={(e) =>
            handleChangeFilterObject("filterValue", e.target.value)
          }
          className=" mw-100 text-justify"
        >
          {options ? (
            options.map((itr) => (
              <option key={itr.id} value={itr[label].replace(/<[^>]+>/g, "")}>
                {itr[label].replace(/<[^>]+>/g, "")}
              </option>
            ))
          ) : (
            <></>
          )}
        </select>
      );
      handleChangeFilterObject(
        "filterValue",
        options && options[0] ? options[0][label].replace(/<[^>]+>/g, "") : ""
      );
      handleChangeFilterObject("filterType", "is");
    } else if (type === "rating" || type === "score" || type === "slider") {
      setValueFiledType(
        <input
          type="number"
          onChange={(e) =>
            handleChangeFilterObject(
              "filterValue",
              parseInt(e.target.value || "0")
            )
          }
          step={0.01}
          className="form-control value-text-filed mw-100 text-justify"
        />
      );
      handleChangeFilterObject("filterValue", 0);
      handleChangeFilterObject("filterType", "greater_than");
    } else if (type === "date") {
      setValueFiledType(
        <input
          type="date"
          max="9999-12-31"
          onChange={(e) =>
            handleChangeFilterObject("filterValue", e.target.value)
          }
          className="form-control value-text-filed mw-100 text-justify"
        />
      );
    } else if (type === "slider") {
      setValueFiledType(
        <input
          type="number"
          onChange={(e) =>
            handleChangeFilterObject("filterValue", e.target.value)
          }
          className="form-control value-text-filed mw-100 text-justify"
        />
      );
    } else if (type === "browser") {
      setValueFiledType(
        <select
          name=""
          onChange={(e) =>
            handleChangeFilterObject("filterValue", e.target.value)
          }
          className=" mw-100 text-justify"
        >
          <option value="Safari">Safari</option>
          <option value="Firefox">Firefox</option>
          <option value="Edge">Edge</option>
          <option value="Facebook">Facebook</option>
          <option value="Opera">Opera</option>
          <option value="Chrome">Chrome</option>
          <option value="Mobile Safari">Mobile Safari</option>
          <option value="MIUI Browser">SafMIUI Browserari</option>
          <option value="Samsung Browser">Samsung Browser</option>
        </select>
      );
    } else if (type === "mobile/desktop") {
      setValueFiledType(
        <select
          name=""
          onChange={(e) =>
            handleChangeFilterObject("filterValue", e.target.value)
          }
          className=" mw-100 text-justify"
        >
          <option value="Desktop">Desktop</option>
          <option value="Mobile">Mobile</option>
        </select>
      );
    }
  };

  const setChangeAndOr = (value) => {
    const tempFilterObject = Object.assign(filterArray);
    filterArray[index - 1].filterOrOperator = value;
    setFiltersObject(tempFilterObject);
    setOrToggle(value);
  };

  const renderAndOrToggle = () => {
    if (orToggle) {
      return (
        <>
          {" "}
          <span className="text-success fw-bold">OR</span>
          <button className=" btn">
            <CgToggleSquare
              size={20}
              onClick={() => setChangeAndOr(!orToggle)}
            />
          </button>{" "}
          <span className="text-muted fw-light">AND</span>
        </>
      );
    } else {
      return (
        <>
          {" "}
          <span className="text-muted fw-light">OR</span>
          <button className=" btn">
            <CgToggleSquareOff
              size={20}
              onClick={() => setChangeAndOr(!orToggle)}
            />
          </button>{" "}
          <span className="text-success fw-bold">AND</span>
        </>
      );
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center">
        {index > 0 && (
          <div className="d-flex align-items-center">{renderAndOrToggle()}</div>
        )}
      </div>
      <div className="d-flex ">
        <div className="flex-fill m-1">
          <h5>Type </h5>
          <select
            name=""
            className="filter-select mw-100"
            // value={questionType}
            onChange={changeOperator}
          >
            <option value="" ref={initialOption}>
              {t("leftBar-welcome-logicModal-select")}
            </option>
            <optgroup label="System">
              <option
                value={JSON.stringify({
                  value: "mobile/desktop",
                  name: "mobile/desktop",
                  type: "system",
                })}
              >
                Mobile/Desktop
              </option>

              <option
                value={JSON.stringify({
                  value: "browser",
                  name: "browser",
                  type: "system",
                })}
              >
                Browser
              </option>

              <option
                value={JSON.stringify({
                  value: "date",
                  name: "date",
                  type: "system",
                })}
              >
                Date
              </option>

              <option
                value={JSON.stringify({
                  value: "finish",
                  name: "finish",
                  type: "system",
                })}
              >
                Finish
              </option>

              <option
                value={JSON.stringify({
                  value: "score",
                  name: "score",
                  type: "system",
                })}
              >
                Score
              </option>
            </optgroup>
            <optgroup label="Variables">
              {variables.map((variable, index) => {
                const { name } = variable;
                return (
                  <option
                    key={index}
                    value={JSON.stringify({
                      value: name,
                      name,
                      type: "variable",
                    })}
                  >
                    {name}
                  </option>
                );
              })}
            </optgroup>
            <optgroup label="Answers">
              {questionsStepsArr
                .filter((question) => question.questionType != "matrix")
                .map((question, index) => {
                  const { title, id } = question;
                  return (
                    <option
                      key={index}
                      value={JSON.stringify({
                        value: id,
                        name: id,
                        type: "answer",
                      })}
                    >
                      {title.replace(/<[^>]+>/g, "")}
                    </option>
                  );
                })}
            </optgroup>
            <optgroup label="Matrix Answers">
              {matrixArr.map((question, index) => {
                const { slideName, id, columns } = question;
                return (
                  <option
                    key={index}
                    value={JSON.stringify({
                      value: id,
                      name: id,
                      type: "matrixAnswer",
                      columns,
                      slideName,
                    })}
                  >
                    {slideName}
                  </option>
                );
              })}
            </optgroup>
            <optgroup label="Form Answers">
              {contactsArr.map((input, index) => {
                const { label, name } = input;
                return (
                  <option
                    key={index}
                    value={JSON.stringify({
                      value: label,
                      label: label,
                      name: label.toLowerCase().replaceAll(" ", ""),
                      type: "contact",
                    })}
                  >
                    {label}
                  </option>
                );
              })}
            </optgroup>
          </select>
        </div>
        <div className="flex-fill m-1">
          <h5>Operator </h5>
          <select
            name=""
            className="filter-select mw-100 text-left"
            // value={questionType}
            onChange={(e) =>
              handleChangeFilterObject("filterType", e.target.value)
            }
          >
            {operatorsArr.map((operator, index) => {
              return (
                <option
                  key={`op-${operator.replaceAll(" ", "_")}`}
                  value={operator.replaceAll(" ", "_")}
                >
                  {operator}
                </option>
              );
            })}
          </select>
        </div>

        {props.filtersObject.configType !== "checkbox" &&
        props.filtersObject.configType !== "signature" &&
        props.filtersObject.configType !== "finish" ? (
          <div className="flex-fill m-1">
            <h5>value</h5>
            {valueFiledType}
          </div>
        ) : (
          <></>
        )}
        {index > 0 && (
          <button
            className=" btn "
            onClick={() => AddRemoveFilters("remove", index)}
          >
            <BsTrashFill size={20} />
          </button>
        )}
      </div>
    </>
  );
};

export default withTranslation()(AnalyseSingleFilters);
