import React, { useEffect, useState, useRef } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import SummaryTableColumnHeader from "./SummeryTableColumnHeader";
function SummaryTable({
  columns,
  data = [],
  fetchData,
  quizAnalyzeTablePageCount,
}) {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: quizAnalyzeTablePageCount, // Update pageCount based on the data length
      autoResetPage: false,
    },
    useSortBy,
    usePagination
  );
  useEffect(() => {
    fetchData({ pageIndex });
  }, [pageIndex]);

  useEffect(() => {
    setCurrentPageIndex(pageIndex);
  }, [data]);
  return (
    <>
      <table className="table summary-table table-striped" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              className="summary-table-header-row"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, index) => (
                <SummaryTableColumnHeader
                  key={`col-${column.Header.replaceAll(" ", "-")}`}
                  column={column}
                />
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                className="summary-table-row"
                key={`row-${row.original.id}`}
                {...row.getRowProps()}
              >
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      key={`cell-${
                        row.original.id
                      }-${cell.column.Header.replaceAll(" ", "-")}`}
                      {...cell.getCellProps()}
                      className="summary-table-td"
                    >
                      {data[i][`${cell.column.Header}`] &&
                      data[i][`${cell.column.Header}`].endsWith(".png") &&
                      data[i][`${cell.column.Header}`].includes(
                        "https://smart-hippo.s3.eu-central-1.amazonaws.com/knowys/signatures/"
                      ) ? (
                        <>
                          {" "}
                          <img
                            style={{ maxWidth: "200px" }}
                            src={data[i][`${cell.column.Header}`]}
                            alt="signature"
                          />
                        </>
                      ) : !index && cell.column.Header === "Id" ? (
                        i + currentPageIndex * 10 + 1
                      ) : (
                        data[i][`${cell.column.Header}`] || cell.render("Cell")
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="summary-table-pagination">
        <div className="summary-table-pagination-page-number">
          Page
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </div>
        <div className="summary-table-pagination-buttons">
          <button
            className="summary-table-pagination-btn"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {"<"}
          </button>
          <button
            className="summary-table-pagination-btn"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {">"}
          </button>
        </div>
      </div>
    </>
  );
}

const DetailedTable = ({
  columns,
  data,
  filterObj,
  getQuizAnalyzeTable,
  quizId,
  additionalFilters,
  quizAnalyzeTablePageCount,
}) => {
  useEffect(() => {
    fetchData({ pageIndex: 0, init: true });
  }, []);
  const [currentDataOnSelectedPage, setCurrentDataOnSelectedPage] = useState(
    []
  );

  useEffect(() => {
    fetchData({ pageIndex: 0 });
  }, [data]);
  let dynamicHeaders = [];

  const fetchData = ({ pageIndex, init }) => {
    if (init) {
      getQuizAnalyzeTable({
        id: quizId,
        fromDate: null,
        toDate: null,
        filters: filterObj,
        offset: pageIndex * 10,
        additionalFilters,
      });
    }
    setCurrentDataOnSelectedPage(
      data.slice(pageIndex * 10, pageIndex * 10 + 10)
    );
  };

  dynamicHeaders.push(
    { Header: "Id", accessor: "index", id: "index" },
    {
      Header: "Date",
      id: "update_at",
      accessor: "update_at",
    }
  );

  columns.forEach((el, index) => {
    dynamicHeaders.push({
      Header: el.header,
      id: `id-table-${index}`,
      accessor: el.header,
    });
  });

  dynamicHeaders.push({ Header: "Result", accessor: "result", id: "result" });

  const mapedMeta = currentDataOnSelectedPage.map((el, index) => {
    index++;

    return {
      ...el,
      ...el.meta,
      // ...el.metaData,
      index,
      ...el.result,
    };
  });
  return (
    <>
      <h1 className="summary-table-title">Summary</h1>
      <div style={{ overflowX: "scroll" }}>
        <SummaryTable
          columns={dynamicHeaders}
          data={mapedMeta}
          fetchData={fetchData}
          quizAnalyzeTablePageCount={quizAnalyzeTablePageCount}
        />
      </div>
    </>
  );
};

export default DetailedTable;
