import React from "react";
import { connect } from "react-redux";
import { saveCustomScripts, deleteCustomScripts } from "../../../redux/actions";
import ConfigActionButtons from "./ConfigActionButtons";
import NewScriptModal from "./NewScriptModal";
import { withTranslation } from "react-i18next";
import FeatureUnavailable from "../../Subscriptions/FeatureUnavailable";
class CustomScripts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customScripts: this.props.customScripts,
      selectedScriptId: "",
      selectedScriptObj: null,
      modalOpen: false,
      scriptType: "",
      isPermited: true,
    };
  }

  handleNewScript = () => {
    const { t } = this.props;
    const id = `S_${Math.random() * 999999}`;
    const title = `${t("configuration-custom-script-name")}`;
    this.props.createScript({
      id,
      title,
      script: "",
      active: false,
      runtime: 1,
    });
    this.setState({
      selectedScriptId: id,
      selectedScriptObj: { id, title, script: "", active: false, runtime: 1 },
      scriptType: `${t("configuration-custom-scripts-add-script")}`,
    });
    this.modalTogle(true);
  };

  handleDuplicateScript = (oldScriptName, oldScriptBody) => {
    const id = `S_${Math.random() * 999999}`;
    const title = `${oldScriptName}_copy`;
    this.props.createScript({
      id,
      title,
      script: oldScriptBody,
      active: false,
    });
    this.handleSaveScripts(
      {
        id,
        title,
        script: oldScriptBody,
        active: false,
      },
      id
    );
  };

  handleScriptEdit = (script) => {
    this.setState({
      selectedScriptId: script.id,
      selectedScriptObj: script,
      scriptType: "Edit script:",
    });
    this.modalTogle(true);
  };

  modalTogle = (val) => {
    this.setState({ modalOpen: val });
  };

  handleSaveScripts = (script, scriptId) => {
    let { id } = this.props.data;
    let { customScripts } = this.state;
    this.props.actions.saveCustomScripts({ ...script, scriptId: scriptId }, id);
  };

  handleDeleteScript = (scriptId) => {
    let { id } = this.props.data;
    let { customScripts } = this.state;
    const newCustomScripts = Object.values(
      JSON.parse(JSON.stringify(customScripts))
    )
      .filter((el) => el.id != scriptId)
      .reduce((obj, el) => {
        return { ...obj, [el.id]: el };
      }, {});

    this.setState({ customScripts: newCustomScripts });
    // this.props.deleteScript(newCustomScripts);
    // this.props.actions.saveCustomScripts(newCustomScripts, id);
    this.props.actions.deleteCustomScripts(scriptId, id);
  };

  render() {
    let { id } = this.props.data;

    let { selectedScriptId, selectedScriptBody, customScripts } = this.state;
    // customScripts = customScripts ? customScripts : {};
    const { t } = this.props;
    if (!this.state.isPermited) {
      return (
        <>
          <FeatureUnavailable
            isShow="true"
            hide={() => this.setState({ isPermited: true })}
            feature="custom_scripts"
          />
        </>
      );
    }
    return (
      <div className="container">
        <h2>{t("configuration-custom-scripts")} </h2>
        <div>
          {!this.props.permitions.custom_scripts ? (
            <></>
          ) : (
            <ul className="scriptList">
              {Object.values(customScripts || {}).map((script) => {
                if (!script) return;
                const scriptChecked = script.active;
                return (
                  <li key={script.id}>
                    <label className="checkboxContainer">
                      {script.active ? (
                        <span style={{ color: "green" }}>Active</span>
                      ) : (
                        <span style={{ color: "darkred" }}> Non Active</span>
                      )}
                    </label>
                    <h3>{script.title}</h3>
                    <a
                      title=""
                      className="copy pointer"
                      onClick={() =>
                        this.handleDuplicateScript(script.title, script.script)
                      }
                    ></a>
                    <a
                      title=""
                      className="edit pointer"
                      onClick={() => this.handleScriptEdit(script)}
                    ></a>
                    <a
                      title=""
                      className="delete pointer"
                      onClick={() => this.handleDeleteScript(script.id)}
                    >
                      Delete{" "}
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
          <a
            className="addScripts pointer"
            onClick={() => {
              if (this.props.permitions.custom_scripts) {
                return this.handleNewScript();
              }
              this.setState({ isPermited: false });
            }}
          >
            {t("configuration-custom-scripts-add-script")}{" "}
            {!this.props.permitions.custom_scripts ? (
              <i class="bi bi-lock-fill m-2"></i>
            ) : (
              <></>
            )}
          </a>
        </div>

        {/* <ConfigActionButtons
          selectedFunc={this.props.saveEditedQuiz}
          toast={this.props.toast}
        /> */}
        <NewScriptModal
          selectedScriptId={this.state.selectedScriptId}
          selectedScriptObj={this.state.selectedScriptObj}
          modalOpen={this.state.modalOpen}
          scriptType={this.state.scriptType}
          modalTogle={this.modalTogle}
          updateScript={this.props.updateScript}
          handleSaveScripts={this.handleSaveScripts}
          allSteps={this.props.data.arrayOfData}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customScripts: state.selectedDetails.quizDetails.customScripts,
    permitions: state.permitions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      saveCustomScripts: (customScripts, quizId) =>
        dispatch(saveCustomScripts(customScripts, quizId)),
      deleteCustomScripts: (scriptId, quizId) =>
        dispatch(deleteCustomScripts(scriptId, quizId)),
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CustomScripts)
);
