import React from "react";
import AltImagesTextArea from "../../../../AltImagesTextArea";

function ButtonExtraData({
  data,
  optionId,
  options,
  id,
  changeQuestionsOptionsDetails,
}) {
  const optionIndex = options.findIndex((option) => option.id == optionId);
  if (optionIndex === -1) return <></>;
  const { score, correct, mediaAltText } = options[optionIndex];
  const { type } = data;

  const drawExtraDataByQuizType = () => {
    switch (type) {
      case "scored":
        return (
          <div className="form-group ">
            <label>Score:</label>
            <input
              type="number"
              name="score"
              className="form-control"
              onChange={(e) =>
                changeQuestionsOptionsDetails(
                  {
                    target: { name: "score", value: parseInt(e.target.value) },
                  },
                  optionIndex,
                  id
                )
              }
              value={score}
            />
          </div>
        );
      case "personality":
        return <></>;
      case "assessment":
        return (
          <div>
            <label>Correct</label>
            <input
              type="checkbox"
              onChange={(e) =>
                changeQuestionsOptionsDetails(
                  {
                    target: {
                      name: "correct",
                      value: e.target.checked,
                      type: e.target.type,
                    },
                  },
                  optionIndex,
                  id
                )
              }
              name="correct"
              checked={correct}
              className=""
            />
          </div>
        );
    }
  };

  const changeOptionMediaAltText = (e) => {
    changeQuestionsOptionsDetails(e, optionIndex, id);
  };

  return (
    <>
      {drawExtraDataByQuizType()}
      <AltImagesTextArea
        changeImagesTextArea={changeOptionMediaAltText}
        mediaAltText={mediaAltText}
      />
    </>
  );
}

export default ButtonExtraData;
