import React from "react";
import { SketchPicker } from "react-color";

class ColorPicker extends React.Component {
  state = {
    displayColorPicker: false,
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
    this.setState({ color: color.rgb });
  };

  render() {
    const { color = "grey" } = this.props;

    const styles = {
      color: {
        background: color,
        borderRadius: "50%",
        display: "block",
        width: "30px",
        height: "30px",
      },
      swatch: {
        cursor: "pointer",
        border: this.props.borderStyle
          ? this.props.borderStyle
          : "2px solid black",
        borderRadius: "50%",
        width: "fit-content",
      },
      popover: {
        position: "absolute",
        zIndex: 99,
        right: this.props.setRight || "inherit",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    };

    return (
      <div style={{ borderRadius: "50px" }}>
        <div style={styles.swatch} onClick={this.handleClick}>
          <div style={styles.color} />
        </div>
        {this.state.displayColorPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={this.handleClose} />
            <SketchPicker
              color={styles.color.background}
              onChange={(e) => {
                this.props.handleChange(e);
              }}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ColorPicker;
