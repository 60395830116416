import React, { useEffect, useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import CountrySelect from "./CountrySelect";
import { IoTrashOutline } from "react-icons/io5";
import TextInput from "react-autocomplete-input";
import AcTest from "../../../AcTest/Index";

function AddContactCheckboxField(props) {
  const {
    step,
    deleteInput,
    id,
    toggleError,
    handleInputName,
    index,
    handleInputVariableName,
    handleContactInputs,
    type,
    dataWithVariables,
  } = props;
  const [inputCollapse, setInputCollapse] = useState(false);
  const [isInputOther, setIsInputOther] = useState(false);

  useEffect(() => {
    if (step.validationType === "other") {
      setIsInputOther(true);
    } else {
      setIsInputOther(false);
    }
  }, [step.validationType]);
  return (
    <>
      <div className="feildHeader">
        <span
          className="pointer"
          onClick={() => setInputCollapse(!inputCollapse)}
        >
          {handleInputName(step, index)}
        </span>

        <IoTrashOutline
          type="button"
          title="rightBar-customize-override"
          onClick={() => deleteInput(id, step.id)}
          color={"rgba(0, 68, 179, 0.42)"}
          size={22}
        />
        {/*<a*/}
        {/*  title=""*/}
        {/*  className="bin"*/}
        {/*  onClick={() => deleteInput(id, step.id)}*/}
        {/*></a>*/}
      </div>
      <Collapse in={inputCollapse}>
        <div className="fieldOptions" id={` item${index}`}>
          <h4>Label:</h4>
          {/* <input
            type="text"
            name="label"
            value={step.label}
            placeholder="Name:"
            onBlur={(e) => handleInputVariableName(e, step.id, id)}
            onChange={(e) => props.handleContactInputs(e, step.id, id)}
          /> */}

          <AcTest
            changeOnSelect={(trigger, slug) => "{{" + slug + "}}"}
            trigger={["{{"]}
            options={{ "{{": [...dataWithVariables] }}
            type="text"
            name="label"
            value={step.label}
            placeholder="Name:"
            className="autocomplete-form-input"
            rows={1}
            maxOptions={50}
            onChange={(e) => props.handleContactInputs({ target: { name: "label", value: e } }, step.id, id)}
            onBlur={(e) => handleInputVariableName({ target: { name: "label", value: e } }, step.id, id)}

          />


          <h4>Placeholder (will be displayed when label is hidden):</h4>
          {/* <textarea
            type="text"
            placeholder="Name:"
            name="placeholder"
            className="form-control"
            value={step.placeholder}
            onChange={(e) => handleContactInputs(e, step.id, id)}
          /> */}


          <AcTest
            changeOnSelect={(trigger, slug) => "{{" + slug + "}}"}
            trigger={["{{"]}
            options={{ "{{": [...dataWithVariables] }}
            type="text"
            name="placeholder"
            value={step.placeholder}
            placeholder="Placeholder:"
            className="autocomplete-form-input-area"
            rows={2}
            maxOptions={50}
            onChange={(e) => props.handleContactInputs({ target: { name: "placeholder", value: e } }, step.id, id)}


          />
          <label>
            <input
              type="checkbox"
              name="defaultChecked"
              checked={step.defaultChecked}
              onChange={(e) => handleContactInputs(e, step.id, id)}
            />{" "}
            Default checked
          </label>

          <label>
            <input
              type="checkbox"
              name="required"
              checked={step.required}
              onChange={(e) => handleContactInputs(e, step.id, id)}
            />{" "}
            Required
          </label>

          <label>
            <input
              type="checkbox"
              name="hideLabel"
              checked={step.hideLabel}
              onChange={(e) => handleContactInputs(e, step.id, id)}
            />{" "}
            Hide Label
          </label>

          {toggleError(step)}
        </div>
      </Collapse>
    </>
  );
}

export default AddContactCheckboxField;
