import React from "react";
import "./logic.css";
import LogicTab from "./LogicTab";
import { withTranslation } from "react-i18next";
class Conditions extends React.Component {
  state = {
    toShow: "after",
  };

  activeClass(type) {
    if (this.state.toShow === type) {
      return "nav-link active";
    }

    return "nav-link";
  }
  render() {
    const { step, variables = [], customScripts = [] } = this.props;
    let { id, options } = this.props.step;
    const { arrayOfData, t } = this.props;
    const { logics } = this.props;
    if (!logics) return <></>;
    const {
      before = [],
      after = [],
      otherCasesRedirect = { before: "", after: "" },
    } = logics;
    options = options ? options : [];

    return (
      <div className="logic">
        <div className="logic-fields float-left">
          <label>
            Q{this.props.step.counter}.{step.slideName}
            {/* {step.slideName} */}
            {/*conditions:*/}
          </label>
        </div>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className={this.activeClass("before")}
              id="home-tab"
              data-toggle="tab"
              data-target="#before"
              onClick={() => this.setState({ toShow: "before" })}
              type="button"
              role="tab"
              aria-controls="before"
              aria-selected="false"
            >
              {t("leftBar-welcome-logicModal-Before")}
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={this.activeClass("after")}
              id="profile-tab"
              data-toggle="tab"
              data-target="#after"
              onClick={() => this.setState({ toShow: "after" })}
              type="button"
              role="tab"
              aria-controls="after"
              aria-selected="true"
            >
              {t("leftBar-welcome-logicModal-After")}
            </button>
          </li>
        </ul>
        <div
          className="tab-content-logic"
          id="myTabContent"
          style={{ textAlign: "left" }}
        >
          {this.state.toShow === "before" ? (
            <div
              className="tab-pane fade show  "
              id="before"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <LogicTab
                stepName={step.slideName}
                logicStepData={before}
                logicType="before"
                addNewLogic={this.props.addNewLogic}
                changeLogicsDefaultRedirect={
                  this.props.changeLogicsDefaultRedirect
                }
                otherCasesRedirect={otherCasesRedirect}
                arrayOfData={arrayOfData}
                step={step}
                addRule={this.props.addRule}
                removeLogic={this.props.removeLogic}
                showConditions={this.props.showConditions}
                handleLogicDetails={this.props.handleLogicDetails}
                variables={variables}
                customScripts={customScripts}
              />
            </div>
          ) : (
            <></>
          )}
          {this.state.toShow === "after" ? (
            <div
              className="tab-pane fade show active"
              id="after"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <LogicTab
                stepName={step.slideName}
                logicStepData={after}
                logicType="after"
                addNewLogic={this.props.addNewLogic}
                changeLogicsDefaultRedirect={
                  this.props.changeLogicsDefaultRedirect
                }
                otherCasesRedirect={otherCasesRedirect}
                arrayOfData={arrayOfData}
                step={step}
                addRule={this.props.addRule}
                removeLogic={this.props.removeLogic}
                showConditions={this.props.showConditions}
                handleLogicDetails={this.props.handleLogicDetails}
                variables={variables}
                customScripts={customScripts}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div></div>
        <br />
      </div>
    );
  }
}
export default withTranslation()(Conditions);
