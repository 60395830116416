import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { BsFillTrashFill } from "react-icons/bs";
import TextInput from "react-autocomplete-input";
import AcTest from "../../../../AcTest/Index";

export default function SelectFields({
  parameter,
  moduleName,
  selectedIntegrationAppId,
  selectedConnection,
  setParams,
  params,
  getModulesConnectionsOptions,
  selectedTrigger,
  setSelectedTrigger,
  onChange,
  dataWithVariables,
  moduleFieldsOptions,
  moduleParams,
}) {
  const [filedsArray, setFiledsArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    if (Array.isArray(parameter.options)) {
      setOptions(parameter.options);
    }
  }, []);

  useEffect(() => {
    if (
      moduleFieldsOptions &&
      moduleFieldsOptions.length &&
      !Array.isArray(parameter.options) &&
      parameter.options.startsWith("rpc://")
    ) {
      setIsLoading(false);
      if (Array.isArray(moduleFieldsOptions)) {
        return handleFieldsChange(moduleFieldsOptions, "init");
      }
      setOptions([]);
    }
  }, [moduleFieldsOptions]);

  const maybeLoadOptions = () => {
    if (selectedConnection && selectedConnection.id) {
      setIsLoading(true);
      if (
        !Array.isArray(parameter.options) &&
        parameter.options.startsWith("rpc://")
      ) {
        const relevantModuleName =
          !Array.isArray(parameter.options) &&
            parameter.options.startsWith("rpc://")
            ? parameter.options.replace("rpc://", "")
            : moduleName;

        getModulesConnectionsOptions(
          relevantModuleName,
          selectedIntegrationAppId,
          selectedConnection.id,
          {},
          true
        );
      }
    }
  };

  useEffect(() => {
    maybeLoadOptions();
  }, [selectedConnection]);

  useEffect(() => {
    if (selectedTrigger && selectedTrigger.id) {
      setFiledsArray(
        selectedTrigger.connection_config[selectedTrigger.module_id][
        parameter.name
        ] || []
      );
    }

    return () => {
      // setFiledsArray([]);
      // setSelectedTrigger(null);
    }
  }, [selectedTrigger]);

  const handleFieldsChange = (arr, type) => {
    if (type === "init") {
      const moduleparamsNames = moduleParams.map((item) =>
        item.name.toLowerCase()
      );
      const newFiledsArray = arr.filter((item) => {
        return !moduleparamsNames.includes(item.value.toLowerCase());
      });

      setOptions(newFiledsArray);
    }
  };

  const deleteField = (i) => {
    const newFields = [...filedsArray];
    newFields.splice(i, 1);
    onChange({ value: newFields }, parameter);
    setFiledsArray(newFields || []);
  };

  const constChangeArrParam = (value, i, type) => {
    const newFields = [...filedsArray];
    newFields[i][type] = value;
    onChange({ value: filedsArray }, parameter);
    setFiledsArray(newFields || []);
  };
  return (
    <div className="w-100">
      <button
        className="btn btn-primary"
        onClick={() => {
          const tempArr = [...filedsArray];
          tempArr.push({ name: "", type: "" });
          setFiledsArray(tempArr || []);
        }}
      >
        {" "}
        add +
      </button>
      <div className="w-100">
        {filedsArray.map((item, index) => {
          return (
            <div className="d-flex just-input">
              {/* <input type="text" className="w-50" placeholder="field name" /> */}

              <ReactSelect
                name={parameter.name}
                className="w-50"
                required={parameter.required}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? "blue" : "#0046B5",
                    height: "44px",
                  }),
                }}
                // validateValue={userModuleData[name]}
                value={options.filter(
                  (option) => option.value === filedsArray[index].name
                  // selectedTrigger.connection_config[selectedTrigger.module_id][name]
                )}
                isSearchable={true}
                onChange={(e) => constChangeArrParam(e.value, index, "name")}
                options={options}
              />

              <AcTest
                changeOnSelect={(trigger, slug) => "{{" + slug + "}}"}
                trigger={["{{"]}
                options={{ "{{": [...dataWithVariables] }}
                className="w-50 just-input"
                rows="1"
                maxOptions={50}
                value={
                  filedsArray[index].value
                  // selectedTrigger.connection_config[selectedTrigger.module_id][name]
                }
                onChange={(e) => constChangeArrParam(e, index, "value")}
              />
              <button
                className="btn btn-danger"
                onClick={(e) => deleteField(index)}
              >
                <BsFillTrashFill color={"white"} size={18} />{" "}
              </button>
            </div>
          );
        })}
      </div>

      {/* <ReactSelect /> */}
    </div>
  );
}
