import React, { useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import Fonts from "../../../../../../utils/Fonts/Fonts";
import { withTranslation } from "react-i18next";
import SelectSize from "../../../../SelectSize";
import ColorPicker from "../../../../ColorPicker";

function SliderExtraData(props) {
  const { data, id, t, handleChangeSliderData } = props;
  const { slider = {} } = props.stepData;
  const {
    min,
    max,
    stepSize,
    defaultValue,
    isPercentage,
    displayCurrentValue,
    baseColor = "#0046B5",
    selectedColor = "rgb(190, 30, 185)",
  } = slider;
  const { type } = data;

  return (
    <div className="slider-extra-data">
      <div className="form-group">
        <label>Min: </label>
        <input
          type="number"
          value={min}
          className="form-control"
          onChange={(e) =>
            handleChangeSliderData(
              {
                target: {
                  value: parseFloat(e.target.value || "0"),
                  name: "min",
                },
              },
              id
            )
          }
          name="min"
        />
      </div>
      <div className="form-group">
        <label>Max: </label>
        <input
          type="number"
          value={max}
          className="form-control"
          onChange={(e) =>
            handleChangeSliderData(
              {
                target: {
                  value: parseFloat(e.target.value || "0"),
                  name: "max",
                },
              },
              id
            )
          }
          name="max"
        />
      </div>
      <div className="form-group">
        <label>Step: </label>
        <input
          type="number"
          value={stepSize}
          onChange={(e) =>
            handleChangeSliderData(
              {
                target: {
                  value: parseFloat(e.target.value || "0"),
                  name: "stepSize",
                },
              },
              id
            )
          }
          name="stepSize"
        />
      </div>
      <div className="form-group">
        <label>Default Value: </label>
        <input
          type="number"
          value={defaultValue}
          onChange={(e) =>
            handleChangeSliderData(
              {
                target: {
                  value: parseFloat(e.target.value || "0"),
                  name: "defaultValue",
                },
              },
              id
            )
          }
          name="defaultValue"
        />
      </div>
      <div className="fieldList">
        <label>
          <input
            type="checkbox"
            checked={isPercentage}
            onChange={(e) =>
              handleChangeSliderData(
                { target: { value: e.target.checked, name: "isPercentage" } },
                id
              )
            }
            name="isPercentage"
          />
          Percent
        </label>
      </div>
      <div className="fieldList">
        <label>
          <input
            type="checkbox"
            checked={displayCurrentValue}
            onChange={(e) =>
              handleChangeSliderData(
                {
                  target: {
                    value: e.target.checked,
                    name: "displayCurrentValue",
                  },
                },
                id
              )
            }
            name="displayCurrentValue"
          />{" "}
          Show Value
        </label>
      </div>
      <div>
        <label>Base Color:</label>
        <ColorPicker
          color={baseColor}
          setRight="inherit"
          handleChange={(e) => {
            const { r, g, b, a } = e.rgb;
            const value = `rgba(${r}, ${g}, ${b}, ${a})`;
            handleChangeSliderData(
              { target: { value, name: "baseColor" } },
              id
            );
          }}
        />
      </div>
      <div>
        <label>Selected Color:</label>
        <ColorPicker
          color={selectedColor}
          setRight="inherit"
          handleChange={(e) => {
            const { r, g, b, a } = e.rgb;
            const value = `rgba(${r}, ${g}, ${b}, ${a})`;
            handleChangeSliderData(
              { target: { value, name: "selectedColor" } },
              id
            );
          }}
        />
      </div>
    </div>
  );
}

export default withTranslation()(SliderExtraData);
