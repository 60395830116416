import React, { useState } from "react";
import ColorPicker from "../../../../ColorPicker";
import { IconPicker } from "../../../../../../utils/icons-select";

import SelectSize from "../../../../SelectSize";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import Modal from "react-bootstrap/Modal";

function RatingAnswerStyle(props) {
  const [modalName, setModalName] = useState("");

  const drawRatingCountSection = () => {
    return (
      <>
        <h3 style={{ paddingRight: "3px" }}>Amount:</h3>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingLeft: "5px",
            marginBottom: "5px",
          }}
        >
          <input
            className="w-25"
            type="number"
            name="ratingIconCount"
            min={2}
            max={10}
            value={ratingIconCount || 5}
            onChange={(e) => {
              let { name, value } = e.target;
              value = parseInt(value);
              handleChangeQuestion({ target: { value, name } }, qId);
            }}
          />{" "}
          <div className="range-slider-container">
            <input
              type="range"
              name="ratingIconCount"
              className="rangeSlider w-100"
              min={2}
              max={10}
              value={ratingIconCount}
              onChange={(e) => {
                let { name, value } = e.target;
                value = parseInt(value);
                handleChangeQuestion({ target: { value, name } }, qId);
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const drawRatingSizeSection = () => {
    return (
      <>
        <h3 style={{ paddingRight: "3px" }}>Size:</h3>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingLeft: "5px",
            marginBottom: "5px",
          }}
        >
          <input
            className="w-25"
            type="number"
            name="ratingIconSize"
            min={20}
            max={50}
            value={ratingIconSize}
            onChange={(e) => {
              let { name, value } = e.target;
              value = parseInt(value);
              handleChangeQuestion({ target: { value, name } }, qId);
            }}
          />

          <div className="range-slider-container">
            <input
              type="range"
              className="rangeSlider w-100"
              name="ratingIconSize"
              min={20}
              max={50}
              value={ratingIconSize}
              onChange={(e) => {
                let { name, value } = e.target;
                value = parseInt(value);
                handleChangeQuestion({ target: { value, name } }, qId);
              }}
            />
          </div>
        </div>
        {/*</Modal>*/}
      </>
    );
  };

  const {
    ratingIcon,
    ratingIconColor,
    ratingIconEmptyColor,
    ratingIconCount,
    ratingIconSize,
    handleChangeQuestion,
    handleQuestionStyle,
    withNextButton = true,
    qId,
  } = props;
  return (
    <div className="toolbar">
      <div className="fontStyle iconContainer">
        <IconPicker
          buttonStyles={{ border: "0px" }}
          value={ratingIcon}
          buttonIconStyles={{ color: ratingIconColor, fontSize: "28px" }}
          pickerIconStyles={{ color: ratingIconColor }}
          onChange={(value) =>
            handleChangeQuestion({ target: { name: "ratingIcon", value } }, qId)
          }
        />
      </div>

      <div
        className="fontStyle d-flex justify-content-start"
        style={{ margin: "5px", alignSelf: "center" }}
      >
        <h3 className="rating-color-headline">Color:</h3>
        <ColorPicker
          isButtonStyle={false}
          setRight="inherit"
          className="color"
          color={ratingIconColor}
          handleChange={(e) => {
            const { r, g, b, a } = e.rgb;
            const value = `rgba(${r}, ${g}, ${b}, ${a})`;
            handleChangeQuestion(
              { target: { value, name: "ratingIconColor" } },
              qId
            );
          }}
        />
      </div>
      <div
        className="fontStyle d-flex justify-content-start"
        style={{ margin: "5px", alignSelf: "center" }}
      >
        <h3 className="rating-color-headline">Empty Color:</h3>
        <ColorPicker
          isButtonStyle={false}
          setRight="30px"
          className="color"
          color={ratingIconEmptyColor}
          handleChange={(e) => {
            const { r, g, b, a } = e.rgb;
            const value = `rgba(${r}, ${g}, ${b}, ${a})`;
            handleChangeQuestion(
              { target: { value, name: "ratingIconEmptyColor" } },
              qId
            );
          }}
        />
      </div>
      {drawRatingSizeSection()}
      {drawRatingCountSection()}
      <br />
      <div className="addSwitch">
        <label className="switch">
          <input
            type="checkbox"
            name="withNextButton"
            checked={withNextButton}
            onChange={(e) => handleChangeQuestion(e, qId)}
          />
          <span className="slider round"></span>
        </label>
        Add next button
      </div>
    </div>
  );
}

export default RatingAnswerStyle;
