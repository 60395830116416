import React, { useEffect, useState } from "react";

export default function UpgradeModal({
  payDetails,
  setPaymentDetails: setPaymentDetailsServer,
  getPaymentDetails,
  getPaymentCheckout,
  changeSubscriptionPlan,
  planId,
}) {
  const [paymentDetails, setPaymentDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getPaymentDetails();
    return () => {
      setIsLoading(false);
      setPaymentDetailsServer({});
    };
  }, []);

  useEffect(() => {
    if (payDetails.user_id) {
      setPaymentDetails(payDetails);
    }
  }, [payDetails]);

  return (
    <div className="w-100">
      <div className="d-flex  w-100">
        <div className=" flex-fill">
          <button
            className={
              isLoading ? "btn btn-success disabled" : "btn btn-success"
            }
            onClick={() => {
              if (window.confirm("Are you sure you want to Change Plan?")) {
                setIsLoading(true);
                changeSubscriptionPlan(planId);
              }
            }}
          >
            Change Package
          </button>
        </div>
        <div className=" flex-fill">
          {" "}
          <h2>{isLoading ? "Loading..." : ""}</h2>
        </div>
        <div className=" flex-fill">
          {paymentDetails && paymentDetails.user_id && (
            <div>
              <div>Card {paymentDetails.credit_card_type}</div>
              <div>
                last four digits {paymentDetails.credit_card_last_four_digits}
              </div>
              <button
                className="btn btn-primary"
                onClick={() => getPaymentCheckout(window.location.href)}
              >
                Change Payment Details
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
