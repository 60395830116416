import Actions from "./actions.config";
import {
  registerService,
  loginService,
  getThemesService,
  duplicateQuizService,
  saveAnswerService,
  deleteFolderService,
  saveCustomScriptsService,
  deleteCustomScriptsService,
  getQuizAnalyzeStatsService,
  getQuizAnalyzeTableService,
  changeFolderService,
  saveCustomStyleService,
  getQuizDetailsByUserIdAndSlugService,
  createFolderService,
  getQuizAnalyzeGraphService,
  initService,
  deleteQuizService,
  getUserQuizesService,
  getQuizDetailsByIdService,
  getTemplatesService,
  getTemplateDetailsByIdService,
  editQuizService,
  searchForImageService,
  createNewApiKeyService,
  getApiKeysService,
  showApiKeyService,
  toggleKeyActivationService,
  deleteKeyService,
  createSubdomainService,
  saveThemeService,
  getUserInfoService,
  updateUserInfoService,
  updatePasswordService,
  saveLogicsService,
  saveProductsLogicsService,
  getStepLogicService,
  calculateLogicService,
  getQuizAnalyzeQuestionsService,
  getAppsService,
  getModulesService,
  getModulesConnectionsService,
  getModulesConnectionsOptionsService,
  createConnectionService,
  setTriggerService,
  getAllTriggeraService,
  checkTriggersService,
  updateTriggerActivityService,
  handleAccessibilityConfigurationService,
  getIsUserIntegratedService,
  activateIntegratetionService,
  triggerRpcService,
  getPackagesService,
  getPackageCheckoutService,
  getPaymentDetailsService,
  getPaymentCheckoutService,
  changeSubscriptionPlanService,
  getAllTriggersForQuizService,
  getTriggerFullDetailsService,
  updateTriggerNameService,
  deleteTriggerService,
  sendAnalitixService,
  createQuizFromTemplateService,
  createQuizFromScratchService,
  createNewThemeService,
  updateUnpublishConditionsService,
  getUnpublishConditionsService,
  purgeAllDataService,
  purgeSingleEntryService,
  downloadExcellService,
  changeOrgService,
  sendInviteService,
  getOrgsUsersService,
  getOrgsFeedsService,
  parseInviteService,
  getQuizAnalyzeLineChartService,
  createWorkSpaceService,
  getWorkSpacesService,
  getWsQuizesService,
  sendWSInviteService,
  moveQuizToWsService,
  createNewFeedService,
  updateFeedService,
  uploadSCVProductsFileService,
  deleteFeedErrorService,
  importProductsService,
  getProductsLogicService,
  deleteFeedService,
  deleteImageService,
  uploadImageService,
} from "./services";

import handleGtmCall from "../utils/gtmService";
import { authServer, websiteUrl } from "../config";

export const setMediaQuery = (isMobile, isTablet) => {
  return async (dispatch) => {
    dispatch({
      type: Actions.SET_MEDIA_QUERY,
      payload: { isMobile, isTablet },
    });
  };
};

export const setIsMobilePreview = (isMobilePreview) => {
  return async (dispatch) => {
    dispatch({
      type: Actions.SET_IS_MOBILE_PREVIEW,
      payload: isMobilePreview,
    });
  };
};

export const register = (userDetails) => {
  return async (dispatch) => {
    const result = await registerService({
      ...userDetails,
      authPath: authServer,
    });
    dispatch({
      type: Actions.LOGIN,
      payload: result,
    });
  };
};

export const login = (userDetails) => {
  return async (dispatch) => {
    const result = await loginService({ ...userDetails, authPath: authServer });

    dispatch({
      type: Actions.LOGIN,
      payload: result,
    });
  };
};

export const logout = () => {
  return {
    type: Actions.LOGOUT,
  };
};

export const init = () => {
  return async (dispatch) => {
    const result = await initService(authServer);
    const { name, user_name, id, orgId, orgArray } = result;
    dispatch({
      type: Actions.INIT,
      payload: { name, user_name, id, orgId, orgArray },
    });
  };
};

export const searchForImage = (searchDetails) => {
  return async (dispatch) => {
    const { images, hasImages } = await searchForImageService(searchDetails);

    dispatch({
      type: Actions.SEARCH_FOR_IMAGE,
      payload: { images, hasImages },
    });
  };
};
export const deleteImage = (fileName, searchDetails) => {
  return async (dispatch) => {
    const { images, hasImages } = await deleteImageService(
      fileName,
      searchDetails
    );

    dispatch({
      type: Actions.SEARCH_FOR_IMAGE,
      payload: { images, hasImages, replace: true },
    });
  };
};
export const uploadImage = (formData) => {
  return async (dispatch) => {
    const { images, hasImages } = await uploadImageService(formData);
    dispatch({
      type: Actions.SEARCH_FOR_IMAGE,
      payload: { images, hasImages, replace: true },
    });
  };
};

export const resetImages = () => {
  return async (dispatch) => {
    dispatch({
      type: Actions.RESET_IMAGES,
      payload: null,
    });
  };
};

export const editQuiz = (quizDetails) => {
  return async (dispatch) => {
    const data = await editQuizService(quizDetails);
    dispatch({
      type: Actions.EDIT_QUIZ,
      payload: data,
    });
  };
};

export const changeLanguage = (language) => {
  return async (dispatch) => {
    dispatch({
      type: Actions.CHANGE_LANGUAGE,
      payload: language,
    });
  };
};

export const getTemplates = () => {
  return async (dispatch) => {
    const templates = await getTemplatesService();
    dispatch({
      type: Actions.GET_TEMPLATES,
      payload: templates,
    });
  };
};
export const getThemes = () => {
  return async (dispatch) => {
    const themes = await getThemesService();
    dispatch({
      type: Actions.GET_THEMES,
      payload: themes,
    });
  };
};

export const getTemplateDetailsById = (id) => {
  return async (dispatch) => {
    const templateDetails = await getTemplateDetailsByIdService(id);
    dispatch({
      type: Actions.GET_TEMPLATE_BY_ID,
      payload: templateDetails,
    });
  };
};

export const getUserQuizes = () => {
  return async (dispatch) => {
    const { userQuizes, permitions = {} } = await getUserQuizesService();
    dispatch({
      type: Actions.GET_USER_QUIZES,
      payload: userQuizes,
    });
    dispatch({
      type: Actions.CHANGE_PERMITIONS,
      payload: permitions,
    });
  };
};

export const getQuizDetailsById = (quizId, wsId) => {
  return async (dispatch) => {
    const data = await getQuizDetailsByIdService(quizId, wsId);
    dispatch({
      type: Actions.GET_QUIZ_DETAILS_BY_QUIZ_ID,
      payload: data,
    });
  };
};

export const resetQuizDetailsAction = () => {
  return async (dispatch) => {
    dispatch({
      type: Actions.GET_QUIZ_DETAILS_BY_QUIZ_ID,
      payload: {},
    });
  };
};

export const deleteQuiz = (quizId) => {
  return async (dispatch) => {
    const data = await deleteQuizService(quizId);
    dispatch({
      type: Actions.GET_USER_QUIZES,
      payload: data,
    });
  };
};
export const duplicateQuiz = (quizId) => {
  return async (dispatch) => {
    const data = await duplicateQuizService(quizId);
    dispatch({
      type: Actions.GET_USER_QUIZES,
      payload: data,
    });
  };
};

export const getQuizDetailsByUserIdAndSlug = (
  userId,
  slug,
  handler,
  isScreenShot,
  fullUrl
) => {
  return async (dispatch) => {
    const data = await getQuizDetailsByUserIdAndSlugService(
      userId,
      slug,
      handler,
      isScreenShot,
      fullUrl
    );
    if (data.redirectText) {
      data.quizDetails.redirectText = data.redirectText;
      data.quizDetails.redirectDescription = data.redirectDescription;
      data.quizDetails.isMsg = data.isMsg;
      data.quizDetails.isLink = data.isLink;
      data.quizDetails.redirectLink = data.redirectLink;
    }

    dispatch({
      type: Actions.GET_QUIZ_DETAILS_BY_USER_ID_AND_SLUG,
      payload: data,
    });
  };
};

export const saveAnswer = (answerDetails) => {
  return async (dispatch) => {
    const data = await saveAnswerService(answerDetails);
    dispatch({
      type: Actions.SAVE_ANSWER,
      payload: data,
    });
  };
};

export const getQuizAnalyzeGraph = (analyzeSearchDetails) => {
  return async (dispatch) => {
    const data = await getQuizAnalyzeGraphService(analyzeSearchDetails);
    dispatch({
      type: Actions.GET_QUIZ_ANALYZE_GRAPH,
      payload: data,
    });
  };
};
export const getQuizAnalyzeStats = (quizId, filterObj, additionalFilters) => {
  return async (dispatch) => {
    const quizAnalyzeStats = await getQuizAnalyzeStatsService(
      quizId,
      filterObj,
      additionalFilters
    );
    dispatch({
      type: Actions.GET_QUIZ_ANALYZE_STATS,
      payload: quizAnalyzeStats,
    });
  };
};
export const getQuizAnalyzeLineChart = (
  quizId,
  filterObj,
  additionalFilters
) => {
  return async (dispatch) => {
    const quizAnalyzeStats = await getQuizAnalyzeLineChartService(
      quizId,
      filterObj,
      additionalFilters
    );
    dispatch({
      type: Actions.GET_QUIZ_ANALYZE_LINE_CHART,
      payload: quizAnalyzeStats,
    });
  };
};

export const getQuizAnalyzeTable = (analyzeSearchDetails) => {
  return async (dispatch) => {
    const data = await getQuizAnalyzeTableService(analyzeSearchDetails);
    if (data.resultsWithFullData) {
      dispatch(
        getQuizAnalyzeTableSuccess(
          data.resultsWithFullData,
          data.quizAnalyzeTablePageCount
        )
      );
      // dispatch({
      //   type: Actions.GET_QUIZ_ANALYZE_TABLE,
      //   payload: {
      //     analyzeData: data.resultsWithFullData,
      //     pageCount: data.quizAnalyzeTablePageCount,
      //   },
      // });
    }
    if (data.columnHeaders) {
      dispatch({
        type: Actions.GET_QUIZ_ANALYZE_HEADERS,
        payload: data.columnHeaders,
      });
    }
  };
};

export const getQuizAnalyzeTableSuccess = (data, pageCount) => {
  return async (dispatch) => {
    dispatch({
      type: Actions.GET_QUIZ_ANALYZE_TABLE,
      payload: {
        analyzeData: data,
        pageCount: pageCount,
      },
    });
  };
};

export const getQuizAnalyzeQuestions = (analyzeSearchDetails) => {
  return async (dispatch) => {
    const data = await getQuizAnalyzeQuestionsService(analyzeSearchDetails);
    dispatch({
      type: Actions.GET_QUIZ_ANALYZE_QUESTIONS,
      payload: data,
    });
  };
};

export const createFolder = (folderName) => {
  return async (dispatch) => {
    const userQuizes = await createFolderService(folderName);
    dispatch({
      type: Actions.GET_USER_QUIZES,
      payload: userQuizes,
    });
  };
};

export const createFolderAndMove = (folderName, quizId) => {
  return async (dispatch) => {
    const userQuizes = await createFolderService(folderName).then(
      (userQuizes) => {
        return changeFolderService(quizId, userQuizes.folders.at(-1).id);
      }
    );

    dispatch({
      type: Actions.GET_USER_QUIZES,
      payload: userQuizes,
    });
  };
};

export const changeFolder = (quizId, folderId) => {
  return async (dispatch) => {
    const userQuizes = await changeFolderService(quizId, folderId);
    dispatch({
      type: Actions.GET_USER_QUIZES,
      payload: userQuizes,
    });
  };
};
export const deleteFolder = (folderId) => {
  return async (dispatch) => {
    const userQuizes = await deleteFolderService(folderId);
    dispatch({
      type: Actions.GET_USER_QUIZES,
      payload: userQuizes,
    });
  };
};

export const saveCustomStyle = (customStyle, quizId) => {
  return async (dispatch) => {
    const result = await saveCustomStyleService(customStyle, quizId);
    if (result && result.status) {
      dispatch(saveCustomStyleSuccess(result.scriptsAndStyles));
    }
  };
};

export const saveCustomScripts = (customScripts, quizId) => {
  return async (dispatch) => {
    const result = await saveCustomScriptsService(customScripts, quizId);
    if (result && result.status) {
      dispatch(saveCustomStyleSuccess(result.scriptsAndStyles));
    }
  };
};

export const deleteCustomScripts = (scriptId, quizId) => {
  return async (dispatch) => {
    const result = await deleteCustomScriptsService(scriptId, quizId);
    if (result && result.status) {
      dispatch(saveCustomStyleSuccess(result.scriptsAndStyles));
    }
  };
};

export const saveCustomStyleSuccess = (scriptsAndStyles) => {
  return async (dispatch) => {
    dispatch({
      type: Actions.SAVE_SCRIPTS_STYLES_SUCCESS,
      payload: { scriptsAndStyles },
    });
  };
};

export const setEditorToolbarRef = (toolbarRef) => {
  return async (dispatch) => {
    dispatch({
      type: Actions.SET_EDITOR_TOOLBAR_REF,
      payload: toolbarRef,
    });
  };
};

export const getApiKeysAction = () => {
  return async (dispatch) => {
    const result = await getApiKeysService();
    if (result.userKeys && result.userKeys.length) {
      dispatch(getApiKeySuccessAction(result.userKeys));
    } else {
      dispatch(getApiKeySuccessAction([]));
    }
  };
};

export const showApiKeyAction = (id) => {
  return async (dispatch) => {
    const result = await showApiKeyService(id);
    if (result.userKeys && result.userKeys.length) {
      dispatch(getApiKeySuccessAction(result.userKeys));
    } else {
      dispatch(getApiKeySuccessAction([]));
    }
  };
};

export const createNewApiKeyAction = (keyObj) => {
  return async (dispatch) => {
    const result = await createNewApiKeyService(keyObj);

    if (result.userKeys && result.userKeys.length) {
      dispatch(getApiKeySuccessAction(result.userKeys));
    } else {
      dispatch(getApiKeySuccessAction([]));
    }
  };
};

export const toggleKeyActivationAction = (isActive, key_id) => {
  return async (dispatch) => {
    const result = await toggleKeyActivationService(isActive, key_id);

    if (result.userKeys && result.userKeys.length) {
      dispatch(getApiKeySuccessAction(result.userKeys));
    } else {
      dispatch(getApiKeySuccessAction([]));
    }
  };
};

export const deleteKeyAction = (key_user, key_id) => {
  return async (dispatch) => {
    const result = await deleteKeyService(key_user, key_id);

    if (result.userKeys && result.userKeys.length) {
      dispatch(getApiKeySuccessAction(result.userKeys));
    } else {
      dispatch(getApiKeySuccessAction([]));
    }
  };
};

const getApiKeySuccessAction = (keys) => {
  return async (dispatch) => {
    dispatch({
      type: Actions.GET_API_KEYS_SUCCESS,
      payload: keys,
    });
  };
};

export const createSubdomainAction = (subdomainName, quizId) => {
  return async (dispatch) => {
    const data = await createSubdomainService(subdomainName, quizId);
    if (!data || !data.quizDetails || data.quizDetails.status === false) {
      dispatch({
        type: Actions.SET_ERROR,
        payload: { type: "subdomain", value: "This Subdomain is Unavailable" },
      });
    } else {
      dispatch({
        type: Actions.GET_QUIZ_DETAILS_BY_QUIZ_ID,
        payload: { ...data },
      });
      dispatch({
        type: Actions.SET_ERROR,
        payload: { type: "subdomain", value: "" },
      });
    }
  };
};

export const changeRightBarView = (context, subContextId, rightBarViewTab) => {
  return async (dispatch) => {
    dispatch({
      type: Actions.CHANGE_RIGHT_BAR_VIEW,
      payload: { context, subContextId, rightBarViewTab },
    });
  };
};

export const changeEmbeddObject = (embeddObj) => {
  return async (dispatch) => {
    dispatch({
      type: Actions.SAVE_EMBEDD_OBJECT,
      payload: { embeddObj },
    });
  };
};
export const saveTheme = (themeStyle, themeName, themeId) => {
  return async (dispatch) => {
    const themes = await saveThemeService(themeStyle, themeName, themeId);
    dispatch({
      type: Actions.GET_THEMES,
      payload: themes,
    });
  };
};

/* sagi */
export const getUserInfoActions = () => {
  return async (dispatch) => {
    const user = await getUserInfoService();
    dispatch({
      type: Actions.GET_USER_INFO,
      payload: { user },
    });
  };
};

export const updateUserInfoActions = (first, last, lang) => {
  return async (dispatch) => {
    const user = await updateUserInfoService(first, last, lang);
    dispatch({
      type: Actions.UPDATE_USER_INFO,
      payload: { user },
    });
  };
};

export const updateUserPasswordActions = (oldPassword, newPassword) => {
  return async (dispatch) => {
    const user = await updatePasswordService(oldPassword, newPassword);

    dispatch({
      type: Actions.PASSWORD_UPDATE,
      payload: { user },
    });
  };
};

export const saveLogicsAction = (quizId, logicObj) => {
  return async (dispatch) => {
    const logicData = await saveLogicsService(quizId, logicObj);

    dispatch({
      type: Actions.LOGIC_STEP_UPDATE,
      payload: {},
    });
  };
};
export const saveProductsLogicsAction = (quizId, logicObj) => {
  return async (dispatch) => {
    const response = await saveProductsLogicsService(quizId, logicObj);
    if (response.success) {
      dispatch({
        type: Actions.PRODUCTS_LOGIC_UPDATE,
        payload: logicObj,
      });
    }
  };
};

export const getStepLogicAction = (quizId, stepId) => {
  return async (dispatch) => {
    const logicData = await getStepLogicService(quizId, stepId);

    dispatch({
      type: Actions.LOGIC_STEP_UPDATE,
      payload: logicData.data,
    });
  };
};
export const getProductsLogicAction = (quizId) => {
  return async (dispatch) => {
    const logicData = await getProductsLogicService(quizId);
    dispatch({
      type: Actions.PRODUCTS_LOGIC_UPDATE,
      payload: logicData,
    });
  };
};

export const calculateLogicAction = (
  quizId,
  stepId,
  conditionsPosition,
  quizData,
  visitKey
) => {
  return async (dispatch) => {
    const logicData = await calculateLogicService(
      quizId,
      stepId,
      conditionsPosition,
      quizData,
      visitKey
    );

    dispatch({
      type: Actions.LOGIC_RESULT_UPDATE,
      payload: logicData.data,
    });
  };
};

export const resetLogicsAction = () => {
  return async (dispatch) => {
    dispatch({
      type: Actions.LOGIC_STEP_UPDATE,
      payload: {},
    });
  };
};

export const resetLogicsResultAction = () => {
  return async (dispatch) => {
    dispatch({
      type: Actions.LOGIC_RESULT_UPDATE,
      payload: null,
    });
  };
};

/* ACCESSIBILITY */
export const changeAccessibilitySettings = (toChange) => {
  return async (dispatch) => {
    dispatch({
      type: Actions.ACCESSIBILITY,
      payload: toChange,
    });
  };
};

export const handleAccessibilityConfigurationAction = (settings) => {
  return async (dispatch) => {
    const accessibility = await handleAccessibilityConfigurationService(
      settings
    );
    const accessibilityData = {
      accessibilityIcon: accessibility.accessibility_icon,
      hideAccessibility: accessibility.hide_accessibility,
      iconBackgroundColor: accessibility.icon_background_color,
      iconColor: accessibility.icon_color,
      position: accessibility.position,
      statement: accessibility.statement,
    };
    if (
      accessibilityData.accessibilityIcon &&
      accessibilityData.statement &&
      accessibilityData.hideAccessibility &&
      accessibilityData.iconBackgroundColor &&
      accessibilityData.iconColor &&
      accessibilityData.position
    ) {
      dispatch({
        type: Actions.ACCESSIBILITY_CONFIGURATION,
        payload: accessibilityData,
      });
    }
  };
};

// integration server Actions

export const getAppsAction = () => {
  return async (dispatch) => {
    const data = await getAppsService();
    if (Array.isArray(data)) {
      dispatch({
        type: Actions.GET_INTEGRATION_APPS,
        payload: data,
      });
    }
  };
};

export const getModulesAction = (appId) => {
  return async (dispatch) => {
    const data = await getModulesService(appId);

    dispatch({
      type: Actions.GET_INTEGRATION_MODULES,
      payload: data,
    });
  };
};

export const getModulesConnectionsAction = (moudleId) => {
  return async (dispatch) => {
    const data = await getModulesConnectionsService(moudleId);

    dispatch({
      type: Actions.GET_INTEGRATION_CONNECTION_MODULES,
      payload: data,
    });
  };
};

export const getModulesConnectionsOptionsAction = (connectionObj) => {
  return async (dispatch) => {
    const data = await getModulesConnectionsOptionsService(connectionObj);

    if (!connectionObj.isFields) {
      dispatch({
        type: Actions.GET_MDULES_CONNECTIONS_OPTIONS,
        payload: data,
      });
    } else {
      dispatch({
        type: Actions.GET_MDULES_FIELDS_OPTIONS,
        payload: data,
      });
    }
  };
};

export const createConnectionAction = (connectionObj, appID) => {
  return async (dispatch) => {
    const data = await createConnectionService(connectionObj, appID);

    dispatch({
      type: Actions.GET_INTEGRATION_CONNECTION_MODULES,
      payload: data,
    });
  };
};

export const setTriggerAction = (
  paramsObj,
  connectionObj,
  appID,
  quizId,
  selectedTrigger,
  appId
) => {
  return async (dispatch) => {
    const data = await setTriggerService(
      paramsObj,
      connectionObj,
      appID,
      quizId,
      selectedTrigger,
      appId
    );

    dispatch({
      type: Actions.GET_INTEGRATION_TRIGGERS,
      payload: data,
    });
  };
};

export const getAllTriggersAction = (connectionId, moduleId, quizId) => {
  return async (dispatch) => {
    const data = await getAllTriggeraService(connectionId, moduleId, quizId);

    dispatch({
      type: Actions.GET_INTEGRATION_TRIGGERS,
      payload: data,
    });
  };
};

export const getAllTriggersForQuizAction = (quizId) => {
  return async (dispatch) => {
    const data = await getAllTriggersForQuizService(quizId);

    dispatch({
      type: Actions.GET_INTEGRATION_TRIGGERS,
      payload: data,
    });
  };
};

export const checkTriggersAction = (quizId, visitKey) => {
  return async (dispatch) => {
    const data = await checkTriggersService(quizId, visitKey);

    // dispatch({
    //   type: Actions.GET_INTEGRATION_TRIGGERS,
    //   payload: data,
    // });
  };
};

export const updateTriggerActivityAction = (triggerId, isActive) => {
  return async (dispatch) => {
    const data = await updateTriggerActivityService(triggerId, isActive);

    if (data.success) {
      dispatch({
        type: Actions.GET_INTEGRATION_TRIGGERS_ACTIVE,
        payload: { value: isActive ? 1 : 0, triggerId },
      });
    }
  };
};

export const getIsUserIntegratedAction = (userId) => {
  return async (dispatch) => {
    setTimeout(async () => {
      const data = await getIsUserIntegratedService(userId);

      dispatch({
        type: Actions.GET_IS_USER_INTEGRATED,
        payload: data.isUserIntegrated,
      });
    }, 2500);
  };
};

export const activateIntegratetionAction = () => {
  return async (dispatch) => {
    const data = await activateIntegratetionService();

    dispatch({
      type: Actions.GET_IS_USER_INTEGRATED,
      payload: data.isUserIntegrated,
    });
  };
};

export const triggerRpcAction = (
  connectionObj,
  triggerArr,
  moduleParams,
  options
) => {
  return async (dispatch) => {
    const data = await triggerRpcService(
      connectionObj,
      triggerArr,
      moduleParams,
      options
    );

    if (connectionObj.isFields) {
      dispatch({
        type: Actions.GET_MDULES_FIELDS_OPTIONS,
        payload: data,
      });
    } else if (connectionObj.isHeirarchyConnection) {
      const moreData = {};
      triggerArr.forEach((trigger, index) => {
        moreData[trigger] = data[index];
      });
      dispatch({
        type: Actions.GET_MDULES_CONNECTIONS_MORE_OPTIONS,
        payload: moreData,
      });
    } else {
      dispatch({
        type: Actions.GET_MDULES_CONNECTIONS_OPTIONS,
        payload: data,
      });
    }
  };
};

//subscriptions

export const getPackagesAction = () => {
  return async (dispatch) => {
    const data = await getPackagesService();

    dispatch({
      type: Actions.GET_PACKGES,
      payload: data,
    });
  };
};

export const getPackageCheckoutAction = (packgeId, domain) => {
  return async (dispatch) => {
    const data = await getPackageCheckoutService(packgeId, domain);
    dispatch({
      type: Actions.SET_SESSION_URL,
      payload: data.session.url,
    });
  };
};

export const getPaymentDetailsAction = () => {
  return async (dispatch) => {
    const data = await getPaymentDetailsService();
    dispatch(setPaymentDetailsAction(data));
  };
};

export const setPaymentDetailsAction = (data) => {
  return async (dispatch) => {
    dispatch({
      type: Actions.SET_PAYMENT_DETAILS,
      payload: data,
    });
  };
};

export const getPaymentCheckoutAction = (domain) => {
  return async (dispatch) => {
    const data = await getPaymentCheckoutService(domain);

    dispatch({
      type: Actions.SET_SESSION_URL,
      payload: data.session.url,
    });
  };
};

export const changeSubscriptionPlanAction = (planId) => {
  return async (dispatch) => {
    const data = await changeSubscriptionPlanService(planId);
    setTimeout(() => {
      dispatch(getPackagesAction());
    }, 5000);
    // dispatch({
    //   type: Actions.GET_PACKGES,
    //   payload: data,
    // });
  };
};

export const getTriggerFullDetailsAction = (triggerId) => {
  return async (dispatch) => {
    const data = await getTriggerFullDetailsService(triggerId);
    dispatch({
      type: Actions.SET_CONNECT_EDIT_OBJECT,
      payload: data,
    });
  };
};

export const resetEditObjectAction = () => {
  return async (dispatch) => {
    dispatch({
      type: Actions.SET_CONNECT_EDIT_OBJECT,
      payload: {},
    });
  };
};

export const updateTriggerNameAction = (quizId, triggerId, triggerName) => {
  return async (dispatch) => {
    const data = await updateTriggerNameService(triggerId, triggerName, quizId);
    dispatch({
      type: Actions.GET_INTEGRATION_TRIGGERS,
      payload: data,
    });
  };
};

export const deleteTriggerAction = (quizId, triggerId) => {
  return async (dispatch) => {
    const data = await deleteTriggerService(quizId, triggerId);
    dispatch({
      type: Actions.GET_INTEGRATION_TRIGGERS,
      payload: data,
    });
  };
};

export const sendAnalitixAction = (data) => {
  return async (dispatch) => {
    const res = await sendAnalitixService(data);
  };
};

export const createQuizFromTemplateAction = (templateId, folder, history) => {
  return async (dispatch) => {
    const data = await createQuizFromTemplateService(templateId, folder);
    const { id: quizId } = data;
    if (quizId) {
      history.push(`/edit/${quizId}`);
    }
  };
};

export const createQuizFromScratchAction = (
  projectTypeId,
  projectName,
  folderId,
  history,
  selectedFeed
) => {
  return async (dispatch) => {
    const data = await createQuizFromScratchService(
      projectTypeId,
      projectName,
      folderId,
      selectedFeed
    );
    const { id: quizId } = data;
    if (quizId) {
      history.push(`/edit/${quizId}`);
    }
  };
};

export const createNewThemeAction = (theme) => {
  return async (dispatch) => {
    const data = await createNewThemeService(theme);
    dispatch(getThemes());
  };
};

export const updateUnpublishConditionsAction = (conditions) => {
  return async (dispatch) => {
    const data = await updateUnpublishConditionsService(conditions);
    dispatch({
      type: Actions.GET_UNPUBLISH_CONDITION,
      payload: data,
    });
  };
};

export const getUnpublishConditionsAction = (quizId) => {
  return async (dispatch) => {
    const data = await getUnpublishConditionsService(quizId);
    dispatch({
      type: Actions.GET_UNPUBLISH_CONDITION,
      payload: data,
    });
  };
};

export const purgeAllDataAction = (quizId) => {
  return async (dispatch) => {
    const data = await purgeAllDataService(quizId);
    dispatch({
      type: Actions.GET_QUIZ_ANALYZE_STATS,
      payload: data,
    });
  };
};
export const purgeSingleEntry = (quizId, guid) => {
  return async (dispatch) => {
    const data = await purgeSingleEntryService(quizId, guid);
    dispatch({
      type: Actions.GET_QUIZ_ANALYZE_STATS,
      payload: data,
    });
  };
};

export const downloadExcellAction = (analyzeSearchDetails) => {
  return async (dispatch) => {
    const data = await downloadExcellService(analyzeSearchDetails);
    // dispatch({
    //   type: Actions.GET_QUIZ_ANALYZE_GRAPH,
    //   payload: data,
    // });
  };
};

export const setGtmStatusAction = (status) => {
  handleGtmCall(status);
  return async (dispatch) => {
    dispatch({
      type: Actions.SET_GTM_STATUS,
      payload: status,
    });
  };
};

export const saveVisitKey = (visitKey) => {
  return async (dispatch) => {
    dispatch({
      type: Actions.SAVE_VISIT_KEY,
      payload: visitKey,
    });
  };
};

export const changeOrgAction = (orgId) => {
  return async (dispatch) => {
    const data = await changeOrgService(orgId, authServer);
    dispatch({
      type: Actions.LOGIN,
      payload: data,
    });
  };
};

export const sendInviteAction = (emailArray) => {
  return async (dispatch) => {
    const data = await sendInviteService(emailArray, authServer, websiteUrl);
    // dispatch({
    //   type: Actions.SEND_INVITE,
    //   payload: data,
    // });
  };
};

export const getOrgsUsers = () => {
  return async (dispatch) => {
    const data = await getOrgsUsersService(authServer);
    dispatch(getOrgsUsersSuccess(data));
  };
};
export const getOrgFeeds = () => {
  return async (dispatch) => {
    const feeds = await getOrgsFeedsService(authServer);
    dispatch({
      type: Actions.GET_ORGS_FEEDS,
      payload: feeds,
    });
  };
};
export const createNewFeed = (feedName) => {
  return async (dispatch) => {
    const feeds = await createNewFeedService(feedName);
    dispatch({
      type: Actions.GET_ORGS_FEEDS,
      payload: feeds,
    });
  };
};
export const updateFeedData = (feedData) => {
  return async (dispatch) => {
    const feeds = await updateFeedService(feedData);
    dispatch({
      type: Actions.GET_ORGS_FEEDS,
      payload: feeds,
    });
  };
};
export const uploadCSVProductsFile = (formData, feedId) => {
  return async (dispatch) => {
    const feeds = await uploadSCVProductsFileService(formData, feedId);
    dispatch({
      type: Actions.GET_ORGS_FEEDS,
      payload: feeds,
    });
  };
};
export const deleteFeedError = (feedErrorId) => {
  return async (dispatch) => {
    const feeds = await deleteFeedErrorService(feedErrorId);
    dispatch({
      type: Actions.GET_ORGS_FEEDS,
      payload: feeds,
    });
  };
};
export const deleteFeedAction = (feedId) => {
  return async (dispatch) => {
    const feeds = await deleteFeedService(feedId);
    dispatch({
      type: Actions.GET_ORGS_FEEDS,
      payload: feeds,
    });
  };
};

export const getOrgsUsersSuccess = (users) => {
  return async (dispatch) => {
    dispatch({
      type: Actions.GET_ORGS_USERS,
      payload: users,
    });
  };
};

export const parseInviteAction = (invite) => {
  return async (dispatch) => {
    const data = await parseInviteService(invite, authServer);
    // dispatch({
    //   type: Actions.PARSE_INVITE,
    //   payload: data,
    // });
  };
};

export const createWorkSpaceAction = (workSpaceName) => {
  return async (dispatch) => {
    const data = await createWorkSpaceService(workSpaceName, authServer);

    if (data && data.status && data.workspaces) {
      dispatch({
        type: Actions.GET_WORKSPACES,
        payload: data.workspaces,
      });
    }
  };
};

export const getWorkSpacesAction = () => {
  return async (dispatch) => {
    const data = await getWorkSpacesService(authServer);
    dispatch({
      type: Actions.GET_WORKSPACES,
      payload: data,
    });
  };
};

export const getWsQuizesAction = (workSpaceId) => {
  return async (dispatch) => {
    const data = await getWsQuizesService(authServer, workSpaceId);
    dispatch(wsQuizesSuccessAction(data));
  };
};

export const wsQuizesSuccessAction = (data) => {
  return async (dispatch) => {
    dispatch({
      type: Actions.GET_WORKSPACE_QUIZES,
      payload: data,
    });
  };
};

export const sendWSInviteAction = (emailArray, wsId) => {
  return async (dispatch) => {
    const data = await sendWSInviteService(
      emailArray,
      authServer,
      websiteUrl,
      wsId
    );
    // dispatch({
    //   type: Actions.SEND_INVITE,
    //   payload: data,
    // });
  };
};

export const moveQuizToWsAction = (quizId, wsId) => {
  return async (dispatch) => {
    const data = await moveQuizToWsService(quizId, wsId);
    if (data && data.length) {
      dispatch({
        type: Actions.GET_USER_QUIZES,
        payload: data,
      });
    }
  };
};
export const togglePreviewDesignMode = (isActive) => {
  return async (dispatch) => {
    dispatch({
      type: Actions.TOGGLE_PREVIEW_DESIGN_MODE,
      payload: isActive,
    });
  };
};

export const importProductsAction = (feedId) => {
  return async (dispatch) => {
    const data = await importProductsService(feedId);
    // dispatch({
    //   type: Actions.GET_ORGS_FEEDS,
    //   payload: data,
    // });
  };
};

export const chnageQuillTollbarElement = (element) => {
  return async (dispatch) => {
    dispatch({
      type: Actions.CHANGE_QUILL_TOOLBAR,
      payload: element,
    });
  };
};
