import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import FullPage from "../../Embedd/EmbedViews/FullPage";
import Banner from "../../Embedd/EmbedViews/Banner";
import PopUp from "../../Embedd/EmbedViews/PopUp";
import Side from "../../Embedd/EmbedViews/Side";
import CustomEmbed from "../../Embedd/EmbedViews/CustomEmbed";

function EmbeddFromShare(props) {
  const { slug, guid, path, t } = props;
  const [selectedTab, setSelectedTab] = useState("fullpage");

  const renderTabBtn = (tabName, viewName) => {
    if (tabName === selectedTab) {
      return (
        <button title="" className="embed-tab active col-2">
          {viewName}
        </button>
      );
    } else {
      return (
        <button
          onClick={() => setSelectedTab(tabName)}
          title=""
          className="embed-tab col-2 pointer"
        >
          {viewName}
        </button>
      );
    }
  };

  const tabContent = () => {
    switch (selectedTab) {
      case "fullpage":
        return <FullPage slug={slug} guid={guid} path={path} />;
      case "banner":
        return <Banner slug={slug} guid={guid} path={path} />;
      case "popup":
        return <PopUp slug={slug} guid={guid} path={path} />;
      case "side":
        return <Side slug={slug} guid={guid} path={path} />;
      case "custom":
        return <CustomEmbed slug={slug} guid={guid} path={path} />;
      default:
        return <></>;
    }
  };

  return (
    <div>
      <h2>Website embed:</h2>
      <div className="row">
        {renderTabBtn("fullpage", "Full Page")}
        {renderTabBtn("popup", "Popup")}

        {renderTabBtn("banner", "Banner")}
        {renderTabBtn("side", "Side")}

        {renderTabBtn("custom", "Custom Embed")}
      </div>
      <br />
      <br />
      <div className="">
        <div className="embedTabs-content">{tabContent()}</div>
      </div>
    </div>
  );
}

export default withTranslation()(EmbeddFromShare);
