import React, { useState, useEffect } from "react";
import Fonts from "../../../../../../utils/Fonts/Fonts";
import ColorPicker from "../../../../ColorPicker";
import SelectSize from "../../../../SelectSize";
import { withTranslation } from "react-i18next";
import Justify from "../../../../../justify/justify";
import Form from "react-bootstrap/Form";
import Collapse from "react-bootstrap/Collapse";

import Answers from "../../../../ThemeEdit/answers";

// import Avatar from "@material-ui/core/Avatar";
// import { FiEdit3 } from "react-icons/fi";
import RightBarTabsContainer from "../../../../RightBar/RightBarTabContainer";

function ButtonAnswerStyle(props) {
  const {
    settings,
    style,
    handleQuestionStyle,
    showFeedback,
    fonts,
    id,
    questionType,
    useAnswersCustomStyle,
    handleChangeQuestion,
    answerWithImages = true,
    verticalAnswers,
    feedback,
    isRandom,
    isMultiSelectRequired,
    correctMessage,
    incorrectMessage,
    errorMsg,
    t,
    toolbar,
    quizType,
    isOtherOption,
    withOtherOption,
    customOtherTextRequired,
    otherOptionFreeTextLabel,
    otherOptionFreeTextErrorMessage,
    customOtherBtnText
  } = props;

  const [isEroorMsgRequired, setIsEroorMsgRequired] = useState(false);

  useEffect(() => {
    if (isMultiSelectRequired) {
      setIsEroorMsgRequired(true);
    } else {
      setIsEroorMsgRequired(false);
    }
  }, [isMultiSelectRequired]);

  const [addImagesToggle, setAddImagesToggle] = useState(answerWithImages);
  const toggleMultiSelect = (e) => {
    const { name, checked } = e.target;
    const value = checked ? "multiSelect" : "singleSelect";
    handleChangeQuestion({ target: { name, value } }, id);
  };

  const handleColorsToChange = (e, name) => {
    const { r, g, b, a } = e.rgb;
    const value = `rgba(${r}, ${g}, ${b}, ${a})`;
    props.handleQuestionStyle({ target: { name, value } }, props.id, "answers");
  };

  const questionDesign = (
    <>
      {" "}
      {toolbar ? (
        <div className="addSwitch override">
          <label className="switch">
            <input
              type="checkbox"
              name="withSubtitle"
              checked={useAnswersCustomStyle ? true : false}
              onChange={(e) =>
                handleChangeQuestion(
                  {
                    target: {
                      name: "useAnswersCustomStyle",
                      type: "checkbox",
                      checked: useAnswersCustomStyle ? false : true,
                    },
                  },
                  id
                )
              }
            />
            <span className="slider round"></span>
          </label>
          {t("rightBar-customize-override")}
        </div>
      ) : (
        <></>
      )}
      {useAnswersCustomStyle && toolbar ? (
        <>
          <Answers
            selectedTheme={"override-edit"}
            handleChange={handleQuestionStyle}
            context="answers"
            fonts={fonts}
            props={props}
            handleColorsToChange={handleColorsToChange}
          />
        </>
      ) : (
        <>
          <div className="addSwitch-disabled"></div>
          <Answers
            selectedTheme={"override-edit"}
            handleChange={{}}
            context="answers"
            fonts={fonts}
            props={props}
            handleColorsToChange={{}}
          />
        </>
      )}
    </>
  );

  const questionConnfig = (
    <>
      {showFeedback ? (
        <div className="addSwitch">
          <label className="switch">
            <input
              type="checkbox"
              checked={feedback}
              name="feedback"
              onChange={(e) => handleChangeQuestion(e, id)}
            />
            <span className="slider round"></span>
          </label>
          {t("rightBar-customize-add-feedback")}
        </div>
      ) : (
        <></>
      )}
      {feedback && showFeedback ? (
        <>
          <div>
            <label htmlFor="">{t("rightBar-customize-correct-message")}</label>
            <input
              type="text"
              value={correctMessage}
              placeholder="Correct!"
              name="correctMessage"
              className="form-control"
              onChange={(e) => this.props.updateMainFeedbacks(e)}
            />
          </div>
          <br />
          <div>
            <label htmlFor="">
              {t("rightBar-customize-incorrect-message")}
            </label>
            <input
              type="text"
              value={incorrectMessage}
              placeholder="Incorrect!"
              name="incorrectMessage"
              className="form-control"
              onChange={(e) => this.props.updateMainFeedbacks(e)}
            />
          </div>
        </>
      ) : (
        <></>
      )}
      {/* {quizType !== "scored" ? (
      <div className="addSwitch answers-theme">
        <label className="switch">
          <input
            name="questionType"
            checked={questionType === "multiSelect"}
            onChange={toggleMultiSelect}
            type="checkbox"
          />
          <span className="slider round"></span>
        </label>
        {t("rightBar-customize-allow-multiple")}
      </div>
    ) : (
      <></>
    )} */}
      <div className="addSwitch answers-theme">
        <label className="switch">
          <input
            name="verticalAnswers"
            checked={verticalAnswers}
            onChange={(e) =>
              handleChangeQuestion(
                {
                  target: { value: e.target.checked, name: "verticalAnswers" },
                },
                id
              )
            }
            type="checkbox"
          />
          <span className="slider round"></span>
        </label>
        {t("rightBar-customize-vertical-answers")}
      </div>
      <div className="addSwitch answers-theme">
        <label className="switch">
          <input
            type="checkbox"
            name="answerWithImages"
            checked={answerWithImages}
            onChange={(e) => {
              setAddImagesToggle(!addImagesToggle);
              handleChangeQuestion(
                {
                  target: { value: e.target.checked, name: "answerWithImages" },
                },
                id
              );
            }}
          />
          <span className="slider round"></span>
        </label>
        {t("rightBar-customize-add-images")}
      </div>

      <div className="addSwitch answers-theme">
        <label className="switch">
          <input
            name="isRandom"
            checked={isRandom}
            onChange={(e) =>
              handleChangeQuestion(
                {
                  target: { value: e.target.checked, name: "isRandom" },
                },
                id
              )
            }
            type="checkbox"
          />
          <span className="slider round"></span>
        </label>
        Random answers order
      </div>

      {isRandom ? (
        <div
          className={`addSwitch answers-theme ${withOtherOption ? "add-opacity" : ""
            }`}
        >
          <label className="switch">
            <input
              name="isOtherOption"
              disabled={withOtherOption}
              checked={isOtherOption}
              onChange={(e) =>
                handleChangeQuestion(
                  {
                    target: {
                      value: e.target.checked,
                      name: "isOtherOption",
                    },
                  },
                  id
                )
              }
              type="checkbox"
            />
            <span className="slider round"></span>
          </label>
          Last option Fixed
        </div>
      ) : (
        <></>
      )}

      <div className="addSwitch answers-theme">
        <label className="switch">
          <input
            name="withOtherOption"
            checked={withOtherOption}
            onChange={(e) =>
              handleChangeQuestion(
                {
                  target: {
                    value: e.target.checked,
                    name: "withOtherOption",
                  },
                },
                id
              )
            }
            type="checkbox"
          />
          <span className="slider round"></span>
        </label>
        With other option
      </div>

      {withOtherOption ? (
        <>
          <div className="addSwitch answers-theme">
            <label className="switch">
              <input
                name="customOtherTextRequired"
                checked={customOtherTextRequired}
                onChange={(e) =>
                  handleChangeQuestion(
                    {
                      target: {
                        value: e.target.checked,
                        name: "customOtherTextRequired",
                      },
                    },
                    id
                  )
                }
                type="checkbox"
              />
              <span className="slider round"></span>
            </label>
            Other text required
          </div>
          <div>
            <label>Label</label>
            <input
              name="otherOptionFreeTextLabel"
              value={otherOptionFreeTextLabel}
              onChange={(e) => handleChangeQuestion(e, id)}
              type="text"
            />
          </div>

          <div>
            <label>Button Text</label>
            <input
              name="customOtherBtnText"
              value={customOtherBtnText}
              onChange={(e) => handleChangeQuestion(e, id)}
              type="text"
            />
          </div>
        </>
      ) : (
        <></>
      )}
      {customOtherTextRequired ? (
        <>
          <div>
            <label>Error Message</label>
            <input
              name="otherOptionFreeTextErrorMessage"
              value={otherOptionFreeTextErrorMessage}
              onChange={(e) => handleChangeQuestion(e, id)}
              type="text"
            />
          </div>
        </>
      ) : (
        <></>
      )}

      {questionType === "multiSelect" ? (
        <div className="addSwitch answers-theme">
          <label className="switch">
            <input
              name="isMultiSelectRequired"
              checked={isMultiSelectRequired}
              onChange={(e) =>
                handleChangeQuestion(
                  {
                    target: {
                      value: e.target.checked,
                      name: "isMultiSelectRequired",
                    },
                  },
                  id
                )
              }
              type="checkbox"
            />
            <span className="slider round"></span>
          </label>
          Answer Required
        </div>
      ) : (
        <></>
      )}

      <Collapse in={isEroorMsgRequired}>
        <div>
          <label>Error Message:</label>
          <input
            type="text"
            name="errorMsg"
            value={errorMsg}
            onChange={(e) =>
              handleChangeQuestion(
                {
                  target: { value: e.target.value, name: "errorMsg" },
                },
                id
              )
            }
            className="form-control"
          />
        </div>
      </Collapse>
    </>
  );

  const formTabsObj = { Answers: questionConnfig, Design: questionDesign };

  return (
    <>{toolbar ? <RightBarTabsContainer formTabsObj={formTabsObj} /> : <></>}</>
  );
}

export default withTranslation()(ButtonAnswerStyle);
