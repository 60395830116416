import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ReactSelect from "react-select";
import { handleLiquid } from "../../../utils";
import AsyncSelect from "react-select/async";
import axios from "axios";
import { apiUrl } from "../../../config";
import { getCountryCodeByValueType } from "../../../utils/CountriesOptions";
import { getStatesByCountryCode } from "../../../redux/services";

function AsyncSelectInput(props) {
  let {
    color = "#000000",
    minimalStyle,
    defaultValue,
    isPreview,
    placeholder,
    name,
    isAutoComplete,
    selectedOptionsMapped,
    isRtl,
    fontSize,
    height,
    inputId,
    isMultiSelect,
    inputStyle,
    errorMessage,
    inputValid,
    isValid,
    minimalDateClass,
    data,
    required,
    handlevalidationInputChanges,
    dataWithVariables,
    countryCode,
    value,
    changeFieldRequired,
  } = props;
  const [ColorComp, setColorComp] = useState(styled.div``);
  const [isSelected, setIsSelected] = useState("");
  const [states, setStates] = useState([]);
  const [defaultVal, setDefaultVal] = useState("");
  const [isFieldRequiredOnDefault, setFieldRequiredOnDefault] = useState(false);

  useEffect(() => {
    setFieldRequiredOnDefault(required);
    if (data.contacts && data.contacts.other && data.contacts.other[inputId]) {
      const savedValue = data.contacts.other[inputId].value;
      if (savedValue) {
        setDefaultVal({ label: savedValue, value: savedValue });
      }
    }
  }, []);

  useEffect(() => {
    const StyledDateInput = minimalStyle
      ? styled.div`
          > div {
            color: ${color.slice(0, color.lastIndexOf(",")).replace(/,/g, "") +
            ") "};
            box-shadow: ${color
              .slice(0, color.lastIndexOf(","))
              .replace(/,/g, "") + " /30%) 0px 1px !important"};
          }
          > div div div {
            color: ${color.slice(0, color.lastIndexOf(",")).replace(/,/g, "") +
            ") "};
          }
          > div .css-11l0ves-control div {
            color: ${color.slice(0, color.lastIndexOf(",")).replace(/,/g, "") +
            ") "};
            opacity: 0.5;
          }
          > div .css-13cymwt-control div {
            color: ${color.slice(0, color.lastIndexOf(",")).replace(/,/g, "") +
            ") "};
            opacity: 0.5;
          }

          > div .css-17nyuw-control div {
            color: ${color.slice(0, color.lastIndexOf(",")).replace(/,/g, "") +
            ") "};
            opacity: 0.5;
          }
        `
      : styled.div``;
    setColorComp(StyledDateInput);
  }, [color, minimalStyle]);

  useEffect(() => {
    if (countryCode) {
      getStatesByCountryCode(getCountryCodeByValueType(countryCode)).then(
        (data) => {
          setStates(data);
          if (!data.length) {
            changeFieldRequired(inputId, false);
          } else {
            if (isFieldRequiredOnDefault) {
              changeFieldRequired(inputId, true);
            }
          }
        }
      );
    }
  }, [countryCode, inputId]);
  return (
    <ColorComp>
      {isPreview ? (
        <>
          <ReactSelect
            isMulti={isMultiSelect}
            placeholder={handleLiquid(placeholder, dataWithVariables)}
            name={name}
            isSearchable={isAutoComplete}
            isRtl={isRtl}
            options={states}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                margin: "auto",
                maxWidth: "100%",
                fontSize: fontSize,
                height: height,
                border: !minimalStyle ? "1px solid #78889f" : "none",
                borderRadius: !minimalStyle ? "7px" : "none",
              }),
              option: (styles, { isFocused, isSelected }) => ({
                ...styles,

                color: "#000 !important",
              }),

              multiValueLabel: (styles, { data }) => ({
                ...styles,
                backgroundColor: minimalStyle ? "#fff !important" : "inherit",
                color: "#000 !important",
                opacity: "0.8 !important",
              }),
              multiValueRemove: (styles, { data }) => ({
                ...styles,
                backgroundColor: minimalStyle ? "#fff !important" : "inherit",
                color: "#000 !important",
                opacity: "0.8 !important",
              }),
              multiValue: (styles, { data }) => ({
                ...styles,
                backgroundColor: minimalStyle ? "#fff !important" : "inherit",
                color: "#000 !important",
                opacity: "0.8 !important",
              }),
            }}
            className={
              !isRtl
                ? `react-select-form ${minimalDateClass + " " + isSelected}`
                : `${minimalDateClass} ${isSelected}`
            }
            defaultValue={defaultVal}
            value={value}
            onInvalid={(e) => e.target.setCustomValidity(errorMessage)}
            onChange={(value) => {
              if (value && value.value) setIsSelected("minimalselected");
              setDefaultVal(value);
              handlevalidationInputChanges(
                isMultiSelect
                  ? {
                      label: handleLiquid(value, dataWithVariables),
                      value: handleLiquid(value, dataWithVariables),
                    }
                  : handleLiquid(value.value, dataWithVariables),
                inputId
              );
            }}
          />
          {inputValid || isValid ? (
            <></>
          ) : (
            <span style={{ color: "red" }} className="req-error">
              {errorMessage}
            </span>
          )}
        </>
      ) : (
        <>
          <ReactSelect
            isMulti={isMultiSelect}
            name={name}
            placeholder={placeholder}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                margin: "auto",
                maxWidth: "100%",
                fontSize: fontSize,
                height: height,
                border: !minimalStyle ? "1px solid #78889f" : "none",
                borderRadius: !minimalStyle ? "7px" : "none",
              }),
              option: (styles, { isFocused, isSelected }) => ({
                ...styles,
                color: isSelected ? "#000 !important" : "#000 !important",
              }),

              multiValueLabel: (styles, { data }) => ({
                ...styles,
                backgroundColor: minimalStyle ? "#fff !important" : "inherit",
                color: "#000 !important",
                opacity: "0.8 !important",
              }),
              multiValueRemove: (styles, { data }) => ({
                ...styles,
                backgroundColor: minimalStyle ? "#fff !important" : "inherit",
                color: "#000 !important",
                opacity: "0.8 !important",
              }),
              multiValue: (styles, { data }) => ({
                ...styles,
                backgroundColor: minimalStyle ? "#fff !important" : "inherit",
                color: "#000 !important",
                opacity: "0.8 !important",
              }),
            }}
            className={
              !isRtl
                ? `react-select-form ${minimalDateClass + " " + isSelected}`
                : `${minimalDateClass} ${isSelected}`
            }
            isSearchable={isAutoComplete}
            isRtl={isRtl}
            id={inputId}
            onChange={(value) => {
              if (value && value.value) setIsSelected("minimalselected");
            }}
            options={states}
            required={required}
          />
        </>
      )}
    </ColorComp>
  );
}

export default AsyncSelectInput;
