export class Result {
  constructor(title, counter) {
    this.id = `R_${Math.round(Math.random() * 999999)}`;
    this.type = "result";
    this.counter = counter;
    this.stepIndex = 2;
    this.title = title;
    this.required = false;
    this.canDeleted = true;
    this.description = "Enter result text.";
    this.redirectTo = "";
    this.minScore = 0;
    this.maxScore = 0;
    this.maxProductsOnPage = 10;
    this.share = {
      facebook: true,
      whatsapp: true,
      linkedin: false,
      telegram: false,
      twitter: false,
      vk: false,
      email: false,
    };
    this.style = {
      title: {
        color: "#000",
        fontWeight: "",
        fontSize: "28",
        fontType: "",
        fontFamily: "",
        fontStyle: "",
        textAlign: "center",
        padding: { top: "0", right: "0", bottom: "0", left: "0" },
      },
      subtitle: {
        color: "#000",
        fontWeight: "",
        fontSize: "16",
        fontType: "",
        fontFamily: "",
        fontStyle: "",
        textAlign: "center",
        padding: { top: "0", right: "0", bottom: "0", left: "0" },
      },
      media: {
        size: "25",
        borderSize: "0",
        borderRadius: "0",
        borderColor: "",
        padding: { top: "0", right: "0", bottom: "0", left: "0" },
      },
      button: {
        color: "#000",
        background: "",
        borderSize: "0",
        borderColor: "",
        borderRadius: "0",
        hoverColor: "",
        hoverBackground: "",
        hoverBorderSize: "0",
        hoverBorderColor: "",
        hoverBorderRadius: "0",
        fontWeight: "",
        fontSize: "",
        fontType: "",
        fontFamily: "",
        fontStyle: "",
        padding: { top: "0", right: "0", bottom: "0", left: "0" },
        textAlign: "center",
      },
      forms: {
        color: "rgba(0, 0, 0, 1)",
        fontWeight: "",
        fontSize: "",
        fontType: "",
        fontFamily: "Open Sans",
        fontStyle: "",
        padding: { top: "0", right: "0", bottom: "0", left: "0" },
        textAlign: "center",
        formTextAlign: "center",
        formWidth: "auto",
      },
    };
    this.withHeader = false;
    this.withFooter = false;
    this.withPagination = false;
    this.withProgressBar = false;
    this.useTitleCustomStyle = false;
    this.useSubTitleCustomStyle = false;
    this.useButtonCustomStyle = false;
  }
}
