import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { searchForImage, resetImages } from "../../redux/actions";
import InfiniteScroll from "../InfiniteScroll";
import { apiUrl } from "../../config";
import { mainAxios } from "../../Axios/mainAxios";
import "./popups.css";
import { withTranslation } from "react-i18next";

class ChooseImage extends React.Component {
  state = {
    searchValue: "",
    searchTarget: "giphy",
    offset: 0,
    toResetImages: false,
    selectedFile: null,
  };
  componentDidMount() {
    const { searchValue, searchTarget, offset, toResetImages } = this.state;
    this.props.actions.resetImages();
    this.setState({ offset: 0 });
    if (searchTarget === "upload") {
      const target = "myphotos";
      this.props.actions.resetImages();
      this.props.actions.searchForImage({
        searchValue,
        searchTarget: target,
        offset,
        toResetImages,
      });
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, toResetImages: true, offset: 0 });
  };

  search = (e) => {
    if (!e || e.target.id === "scrollMore" || e.charCode === 13) {
      const { toResetImages, offset } = this.state;
      // if (toResetImages) {
      this.props.actions.resetImages();
      // }
      this.props.actions.searchForImage(this.state);
      this.setState({ offset: offset + 10, toResetImages: false });
    }
  };

  saveImage = (image) => {
    if (this.props.changeImage) {
      return this.props.changeImage(image);
    }
    if (this.props.changeOptionImage) {
      const { id, index } = this.props;
      this.props.changeOptionImage(id, index, image);
    }
    if (this.props.changeBackgroundImage) {
      this.props.changeBackgroundImage(image);
    }
    if (this.props.changeLogo) {
      this.props.changeLogo(image, this.props.toChange);
    }
    if (this.props.handleChangeFavicon) {
      this.props.handleChangeFavicon(image);
    }
    if (this.props.handleChangeMediaImage) {
      this.props.handleChangeMediaImage(image);
    }
    if (this.props.handleShareImage) {
      this.props.handleShareImage(image);
    }
  };

  chooseFile = async (e) => {
    const file = e.target.files[0] || null;
    if (!file) return;
    const formData = new FormData();
    formData.append("image", file, file.name);
    const { data } = await mainAxios.post(`${apiUrl}/images/upload`, formData);
    if (!data.success) return;
    this.saveImage(data.image);
  };

  isActiveClass = (e) => {
    return this.state.searchTarget === e ? "active" : "";
  };

  changeSearchTarget = (target) => {
    const { searchValue, searchTarget, offset, toResetImages } = this.state;
    this.props.actions.resetImages();
    this.setState({ searchTarget: target });
    const myPhotos = "myphotos";
    if (target === "upload") {
      this.props.actions.searchForImage({
        searchValue,
        searchTarget: myPhotos,
        offset,
        toResetImages,
      });
    }
  };

  render() {
    const { searchImages, hasImages, index, t } = this.props;
    const { searchTarget } = this.state;
    return (
      <div
        className="modal fade "
        id={`modal${index}`}
        size="xl"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog gallery-modal-old"
          size="xl"
          role="document"
        >
          <div className="modal-content chooseImage" id="infinite_scroll_modal">
            <div className="chooseImage-header">
              <h2>{t("answers-modal-choose-image")} </h2>
              {/*<div className="sort">*/}
              {/*  <label>{t("answers-modal-sort")} </label>*/}
              {/*  <select>*/}
              {/*    <option>{t("answers-modal-choose-image")} </option>*/}
              {/*  </select>*/}
              {/*</div>*/}
              <div className="search">
                <input
                  type="search"
                  onChange={(e) =>
                    this.setState({ searchValue: e.target.value })
                  }
                  onKeyPress={this.search}
                  placeholder={t("answers-modal-search-by-name")}
                />
              </div>
            </div>

            <div className="gallery-tabs">
              <a
                title=""
                value="unsplash"
                className={this.isActiveClass("unsplash")}
                onClick={(e) => this.changeSearchTarget("unsplash")}
              >
                Unsplash
              </a>
              <a
                title=""
                value="pexels"
                className={this.isActiveClass("pexels")}
                onClick={(e) => this.changeSearchTarget("pexels")}
              >
                Pexels
              </a>
              <a
                title=""
                value="pexels-video"
                className={this.isActiveClass("pexels-video")}
                onClick={(e) => this.changeSearchTarget("pexels-video")}
              >
                Video
              </a>
              <a
                title=""
                value="giphy"
                className={this.isActiveClass("giphy")}
                onClick={(e) => this.changeSearchTarget("giphy")}
              >
                Giphy
              </a>
              <a
                title=""
                className={this.isActiveClass("upload")}
                value="upload"
                onClick={(e) => this.changeSearchTarget("upload")}
              >
                {t("answers-modal-photos")}
              </a>
            </div>

            {this.state.searchTarget == "upload" ? (
              <div className="gallery-tabContnet">
                {/* hidden input activated by the next input for design reasons */}
                <input
                  type="file"
                  style={{ display: "none", width: "0px" }}
                  name="selectedFile"
                  onChange={this.chooseFile}
                  id="selectedFile"
                />

                <input
                  type="button"
                  style={{ width: "200px", marginLeft: "20px" }}
                  className="addImage"
                  onClick={() =>
                    document.getElementById("selectedFile").click()
                  }
                />

                <></>
              </div>
            ) : (
              <></>
            )}
            <InfiniteScroll
              data={this.props.searchImages}
              hasImages={this.props.hasImages}
              handleNext={this.search}
              saveImage={this.saveImage}
              id="scrollMore"
            />
            {/* </div> */}
            <div className="popupFooter">
              <span
                data-dismiss="modal"
                aria-label="Close"
                className="close close_multi"
                // onClick={() => setIsModalOpen(false)}
              >
                {t("general-cancel")}
              </span>
              <input
                data-dismiss="modal"
                aria-label="Close"
                type="button"
                value={t("answers-modal-btn-choose")}
                className="btn-popup"
                // onClick={() => setIsModalOpen(false)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { searchImages, hasImages } = state;
  return { searchImages, hasImages };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      searchForImage: (searchDetails) =>
        dispatch(searchForImage(searchDetails)),
      resetImages: () => dispatch(resetImages()),
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ChooseImage)
);
