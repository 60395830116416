export function addStyleSettings(settings, toChange) {
  // a baddly designed patch casued
  // by baddly design app architecture and a style change
  if (toChange === "button") {
    settings[toChange] = {
      color: "#000",
      background: "#000",
      borderSize: "0",
      borderColor: "",
      borderRadius: "0",
      hoverColor: "",
      hoverBackground: "",
      hoverBorderSize: "0",
      hoverBorderColor: "",
      hoverBorderRadius: "0",
      fontWeight: "",
      fontSize: "14",
      fontType: "",
      fontFamily: "Open Sans",
      fontStyle: "",
      padding: { top: "0", right: "0", bottom: "0", left: "0" },
      textAlign: "center",
    };
  } else if (toChange === "forms") {
    settings[toChange] = {
      color: "rgba(0, 0, 0, 1)",
      fontWeight: "",
      fontSize: "",
      fontType: "",
      fontFamily: "Open Sans",
      fontStyle: "",
      padding: { top: "0", right: "0", bottom: "0", left: "0" },
      textAlign: "center",
    };
  } else if (toChange === "alert" || toChange.includes(".alert")) {
    settings.alert = {
      color: "#fff",
      fontSize: "14",
      fontFamily: "Open Sans",
      textAlign: "center",
      width: "auto",
      correct: {
        text: "Correct!",
        color: "#fff",
        background: "rgba(0,0,0,0.5)",

        borderColor: "black",
        borderSize: "0",
      },
      incorrect: {
        text: "Incorrect",
        background: "rgba(0,0,0,0.5)",
        color: "#fff",
        borderColor: "black",
        borderSize: "0",
      },
    };
  } else if (toChange === "header") {
    settings[toChange] = {
      show: true,
      hideLinks: false,
      background: "",
      links: {},
      logoImageSrc: "",
      size: "30",
      left: 0,
      logoPosition: "left",
      fontFamily: "Open Sans",
      fontWeight: "",
      fontStyle: "",
      fontSize: "",
      textAlign: "center",
      height: "150",
    };
  } else if (toChange === "footer") {
    settings[toChange] = {
      show: true,
      hideLinks: false,
      background: "",
      links: {},
      logoImageSrc: "",
      size: "30",
      left: 0,
      logoPosition: "left",
      fontFamily: "Open Sans",
      fontWeight: "",
      fontStyle: "",
      fontSize: "15px",
      textAlign: "center",
    };
  } else if (toChange === "bar") {
    settings[toChange] = {
      barType: "",
      fontSize: "14",
      color: "rgba(0, 0, 0, 1)",
      textAlign: "center",
      fontFamily: "Open Sans",
      showPercent: true,
    };
  } else if (toChange === "backButton") {
    settings[toChange] = {
      color: "#000",
      background: "rgba(0, 0, 0, 0)",
      borderSize: "0",
      borderColor: "rgba(0, 0, 0, 0)",
      borderRadius: "0",
      hoverColor: "",
      hoverBackground: "",
      hoverBorderSize: "0",
      hoverBorderColor: "",
      hoverBorderRadius: "0",
      fontWeight: "",
      fontSize: "14",
      fontType: "",
      fontFamily: "Open Sans",
      fontStyle: "",
      padding: { top: "0", right: "0", bottom: "0", left: "0" },
      textAlign: "center",
      buttonText: "<<",
    };
  } else if (toChange === "products") {
    settings[toChange] = {
      textColor: "black",
      backgroundColor: "",
      buttonsTextColor: "black",
      buttonsBackgroundColor: "",
      buttonsBorderSize: "",
      buttonsBorderRadius: "",
      buttonsBorderColor: "",
      buttonsHoverTextColor: "",
      buttonsHoverBackgroundColor: "",
      buttonsHoverBorderSize: "",
      buttonsHoverBorderRadius: "",
      buttonsHoverBorderColor: "",
      titleFontSize: "20",
      titleMobileFontSize: "20",
      titleTabletFontSize: "20",
      descriptionFontSize: "20",
      descriptionMobileFontSize: "20",
      descriptionTabletFontSize: "20",
      priceFontSize: "20",
      priceMobileFontSize: "20",
      priceTabletFontSize: "20",
      fontFamily: "",
      fontStyle: "",
      fontWeigth: "",
      padding: {
        top: "0",
        right: "0",
        bottom: "0",
        left: "0",
        mobileTop: "0",
        mobileRight: "0",
        mobileBottom: "0",
        mobileLeft: "0",
        tabletTop: "0",
        tabletRight: "0",
        tabletBottom: "0",
        tabletLeft: "0",
      },
    };
  }

  return settings;
}
