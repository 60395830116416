import React, { useState, useEffect } from "react";
import { Accordion, Button, ButtonGroup } from "react-bootstrap";
import DeleteFeedModal from "./DeleteFeedModal";

function FeedAccordion(props) {
  const {
    feed,
    updateFeedData,
    deleteFeedError,
    importProductsManualy,
    deleteFeedAction,
  } = props;
  const {
    feed_id,
    feed_name,
    number_of_products,
    errors_counter,
    errorsArray,
    lastUpdate,
    errorWithDeleted,
  } = feed;
  const [periodicity, setPeriodicity] = useState(feed.periodicity);
  const [syncHour, setSyncHour] = useState(feed.sync_hour || "0");
  const [syncDay, setSyncDay] = useState(feed.sync_day || "1");
  const [syncDataType, setSyncDataType] = useState(
    feed.sync_data_type || "xml"
  );
  const [url, setUrl] = useState(feed.update_url);
  const [isUrlError, setIsUrlError] = useState(false);
  const hoursArray = Array.from(Array(24).keys());

  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadButtonDisabled, setUploadButtonDisabled] = useState(false);
  const [importButtonDisabled, setImportButtonDisabled] = useState(false);

  const handleFileChange = (event) => {
    if (syncDataType === "plugin") return;
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleUpload = async () => {
    if (selectedFile) {
      setUploadButtonDisabled(true);
      // You can perform further processing or send the file to a server
      const formData = new FormData();
      formData.append("file", selectedFile);
      await props.uploadCSVProductsFile(formData, feed_id);
    }
  };

  const checkIfFormValid = () => {
    if (periodicity == "never" && periodicity != feed.periodicity) return true;
    if (
      !periodicity ||
      !syncHour ||
      (!syncDay && periodicity == "weekly") ||
      !syncDataType ||
      !url
    )
      return false;
    if (checkIfFormChanged()) return false;
    if (url && isUrlError) return false;
    return true;
  };

  const updateFeed = () => {
    if (!checkIfFormValid()) return;
    updateFeedData({
      periodicity,
      syncDay,
      syncHour,
      syncDataType,
      url,
      feedId: feed_id,
    });
  };

  const checkIfFormChanged = () => {
    return (
      periodicity == feed.periodicity &&
      syncHour == feed.sync_hour &&
      syncDay == feed.sync_day &&
      syncDataType == feed.sync_data_type &&
      url == feed.update_url
    );
  };

  return (
    <Accordion.Item eventKey={feed_id}>
      <Accordion.Header>
        <div className="row" style={{ width: "100%" }}>
          <div className="col-6">{feed_name}</div>
          <div className="col-4">Last update: {lastUpdate}</div>
          <div className="col-2">Products: {number_of_products}</div>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <div>
          <div>
            {errorsArray.map((itr) => {
              const { errorId, description, createdAt } = itr;
              return (
                <div
                  key={errorId}
                  class="container alert alert-danger"
                  role="alert"
                >
                  <div class="row mt-3">
                    <div class="col-md-8">
                      <p>{description}</p>
                    </div>
                    <div class="col-md-3">
                      <p>{createdAt}</p>
                    </div>
                    <div
                      class="col-md-1"
                      style={{
                        position: "relative",
                        bottom: "7px",
                      }}
                    >
                      <button
                        className="btn"
                        onClick={(e) => deleteFeedError(errorId)}
                        style={{ color: "#842029" }}
                      >
                        X
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <br />
          {number_of_products < 1 && (
            <p style={{ color: "red", textAlign: "center" }}>
              You must upload the products from the plugin or in the
              facebook/google format.
            </p>
          )}
          <ButtonGroup aria-label="Basic example">
            <Button
              variant="secondary"
              active={periodicity === "weekly"}
              onClick={() => setPeriodicity("weekly")}
            >
              Weekly
            </Button>
            <Button
              variant="secondary"
              active={periodicity === "daily"}
              onClick={() => setPeriodicity("daily")}
            >
              Daily
            </Button>
            <Button
              variant="secondary"
              active={periodicity === "never"}
              onClick={() => setPeriodicity("never")}
            >
              Never
            </Button>
          </ButtonGroup>
        </div>
        <br />
        <div>
          <label htmlFor="">URL</label>
          <input
            type="text"
            value={url}
            disabled={periodicity == "never" || syncDataType === "plugin"}
            className="form-control"
            onChange={(e) => {
              setUrl(e.target.value);
              setIsUrlError(
                !/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(e.target.value)
              );
            }}
          />
          {isUrlError && (
            <div>
              <span style={{ color: "red" }}>Url is not valid</span>
            </div>
          )}
        </div>
        <br />
        <div>
          <div className="row">
            {periodicity === "weekly" && (
              <div className="col-4">
                <label>Day</label>
                <select
                  value={syncDay}
                  disabled={periodicity == "never"}
                  className="form-control"
                  onChange={(e) => setSyncDay(e.target.value)}
                >
                  <option value="1">Sunday</option>
                  <option value="2">Monday</option>
                  <option value="3">Tuesday</option>
                  <option value="4">Wednesday</option>
                  <option value="5">Thursday</option>
                  <option value="6">Friday</option>
                  <option value="7">Saturday</option>
                </select>
              </div>
            )}
            <div className="col-4">
              <label>Hour</label>
              <select
                value={syncHour}
                className="form-control"
                disabled={periodicity == "never"}
                onChange={(e) => setSyncHour(e.target.value)}
              >
                {hoursArray.map((hour) => (
                  <option key={hour} value={hour}>
                    {hour}:00
                  </option>
                ))}
              </select>
            </div>
            <div className="col-4">
              <label>Data Type</label>
              <select
                value={syncDataType}
                className="form-control"
                disabled={periodicity == "never" || syncDataType === "plugin"}
                onChange={(e) => {
                  if (syncDataType !== "plugin")
                    setSyncDataType(e.target.value);
                }}
              >
                <option value="xml">XML</option>
                <option value="json">JSON</option>
                {syncDataType === "plugin" && (
                  <option value="plugin">Plugin</option>
                )}
              </select>
            </div>
          </div>
          <br />
          <button
            className="btn btn-primary"
            onClick={updateFeed}
            disabled={!checkIfFormValid()}
          >
            {errors_counter > 4 ? "Save and re-activate" : "Save"}
          </button>
          <button
            className="btn btn-success"
            onClick={() => {
              setImportButtonDisabled(true);
              importProductsManualy(feed_id);
            }}
            disabled={!feed.update_url || importButtonDisabled}
          >
            Sync manualy
          </button>
          {syncDataType !== "plugin" ? (
            <DeleteFeedModal
              feedId={feed_id}
              deleteFeedAction={deleteFeedAction}
            />
          ) : (
            <></>
          )}
          <br />
          {errorWithDeleted ? (
            <span style={{ color: "red" }}>Cant delete an attached feed.</span>
          ) : (
            <></>
          )}
          <br />
          <br />
          <div>
            <h2>OR</h2>
            <div className="container mt-4">
              <h2>Manually upload CSV file</h2>
              <div className="input-group mb-3">
                <input
                  type="file"
                  accept=".csv"
                  disabled={syncDataType === "plugin"}
                  className="form-control"
                  onChange={handleFileChange}
                />
              </div>
              {selectedFile && (
                <p className="mb-3">Selected File: {selectedFile.name}</p>
              )}
            </div>
            <button
              className="btn btn-primary"
              disabled={!selectedFile || uploadButtonDisabled}
              onClick={handleUpload}
            >
              Upload
            </button>
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default FeedAccordion;
