import React, { useEffect, useState } from "react";
import Fonts from "../../../utils/Fonts/Fonts";
import ColorPicker from "../ColorPicker";
import SelectSize from "../SelectSize";

import { withTranslation } from "react-i18next";
import Justify from "../../justify/justify";

function ProgressBarStyle({
  selectedTheme,
  context,
  handleChange,
  props,
  handleColorsToChange,
  fromTheme,
}) {
  const { settings, fonts, t, id } = props;

  if (!selectedTheme) return <></>;
  let styleObject = selectedTheme[context];

  if (!styleObject && props.style && props.style[context]) {
    styleObject = props.style[context];
  } else if (!styleObject) {
    styleObject = {
      batType: "",
      fontSize: "14",
      color: "rgba(0, 0, 0, 1)",
      textAlign: "center",
      fontFamily: "Open Sans",
      showPercent: true,
    };
    settings[context] = styleObject;
  }
  styleObject.fontFamily = styleObject.fontFamily || "Open Sans";
  return (
    <>
      {/*<h3>{t("rightBar-theme-pb-type")}</h3>*/}
      {/*<select*/}
      {/*  name="barType"*/}
      {/*  className="form-control"*/}
      {/*  value={styleObject.barType}*/}
      {/*  onChange={(e) => {*/}
      {/*    props.changeMainStyle(e, context);*/}
      {/*    handleChange(e, id);*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <option value="line">Line</option>*/}
      {/*  <option value="dash">Dash</option>*/}
      {/*  <option value="percent">Percent</option>*/}
      {/*  <option value="">None</option>*/}
      {/*</select>*/}
      <div className="fontStyle mt-3">
        <h3>{t("theme-accordion-font-style")} </h3>
        <div>
          <Fonts
            templatesFonts={fonts}
            selected={
              styleObject.fontFamily.replace(/\+/g, " ")
                ? styleObject.fontFamily.replace(/\+/g, " ")
                : settings.subtitle.fontFamily.replace(/\+/g, " ") ||
                  "open sans"
            }
            saveFont={(font) => {
              props.changeMainStyle(
                { target: { name: "fontFamily", value: font } },
                context
              );
              handleChange(
                { target: { name: "fontFamily", value: font } },
                context
              );
            }}
          />
        </div>
      </div>
      <SelectSize
        fromTheme={fromTheme}
        label={t("general-font-size")}
        name="fontSize"
        value={{
          desktop: styleObject.fontSize,
          mobile: styleObject.mobileFontSize || styleObject.fontSize,
          tablet: styleObject.tabletFontSize || styleObject.fontSize,
        }}
        handleChange={(size, name = "fontSize") => {
          props.changeMainStyle({ target: { name, value: size } }, context);
          handleChange({ target: { name, value: size } }, context);
        }}
        changedDetails={{ toChange: context }}
      />

      <div
        style={{
          display: "flex",
          columnGap: "16px",
          margin: "10px 0 10px 0",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Justify
          JustifyText={t("theme-accordion-text-alignment")}
          handleStyleFunctionName={
            selectedTheme == "override-edit"
              ? "handleChange"
              : "changeMainStyle"
          }
          targetName={"textAlign"}
          value={context}
          id={selectedTheme == "override-edit" ? id : null}
          actionFunction={
            selectedTheme == "override-edit"
              ? handleChange
              : props.changeMainStyle
          }
          selectedJustify={
            selectedTheme == "override-edit"
              ? styleObject.textAlign
              : settings[context].textAlign
          }
        />

        <div
          style={{
            display: "flex",
            width: "20%",
            columnGap: "16px",
            margin: "0px 0 10px 0",
            justifyContent: "space-between",
          }}
        >
          <div className="fontStyle">
            <h3>{t("theme-accordion-text-color")} </h3>
            <ColorPicker
              setRight="0px"
              isButtonStyle={true}
              color={styleObject.color}
              handleChange={(e) => handleColorsToChange(e, "color", context)}
            />
          </div>
        </div>
      </div>
      <div className="addSwitch" style={{ paddingTop: "15px" }}>
        <label className="switch">
          <input
            type="checkbox"
            name="showPercent"
            checked={styleObject.showPercent}
            onChange={(e) => {
              props.changeMainStyle(
                { target: { name: "showPercent", value: e.target.checked } },
                context
              );
              handleChange(
                { target: { name: "showPercent", value: e.target.checked } },
                context
              );
            }}
          />{" "}
          <span className="slider round"></span>
        </label>
        {t("rightBar-theme-pb-percentage")}
      </div>
    </>
  );
}

export default withTranslation()(ProgressBarStyle);
