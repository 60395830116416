import { IconContext } from "react-icons";
import React, { useState, useEffect } from "react";
import { FaUniversalAccess, FaAccessibleIcon } from "react-icons/fa";
import { MdOutlineAccessible, MdOutlineAccessibilityNew } from "react-icons/md";
import { handleAccessibilityConfigurationAction } from "../../../redux/actions";

import {
  ImArrowUpLeft2,
  ImArrowUpRight2,
  ImArrowDownLeft2,
  ImArrowDownRight2,
} from "react-icons/im";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
// import Select from "react-select";
import noAccessibilityImage from "../../../images/noAccessibility.png";
import accessibilityImage from "../../../images/accessibility-img.png";
import ConfigActionButtons from "./ConfigActionButtons";

const AccessibilityConfiguration = (props) => {
  const [accessibilityStatement, setAccessibilityStatement] = useState(
    props.accessibilityLocalState.statement
  );
  const [hideAccessibility, setHideAccessibility] = useState(
    props.accessibilityLocalState.hideAccessibility
  );
  const [iconColor, setIconColor] = useState(
    props.accessibilityLocalState.iconColor
  );
  const [iconBackgroundColor, setIconBackgroundColor] = useState(
    props.accessibilityLocalState.iconBackgroundColor
  );
  const [showIconSelection, setShowIconSelection] = useState(false);
  const [positionIconActive, setPositionIconActive] = useState(
    "ImArrowDownLeft2"
  );
  const [previewIcon, setPreviewIcon] = useState(
    props.accessibilityLocalState.accessibilityIcon
  );

  // useEffect(() => {
  //   console.log(props.accessibilityLocalState, "props.accessibilityLocalState");
  // }, [props.accessibilityLocalState]);

  useEffect(() => {
    setIconBackgroundColor(props.accessibilityLocalState.iconBackgroundColor);
  }, [props.accessibilityLocalState.iconBackgroundColor]);
  useEffect(() => {
    setIconColor(props.accessibilityLocalState.iconColor);
  }, [props.accessibilityLocalState.iconColor]);
  useEffect(() => {
    setAccessibilityStatement(props.accessibilityLocalState.statement);
  }, [props.accessibilityLocalState.statement]);
  useEffect(() => {
    setPreviewIcon(props.accessibilityLocalState.accessibilityIcon);
  }, [props.accessibilityLocalState.accessibilityIcon]);

  const checkUserIcon = (iconString) => {
    switch (iconString) {
      case "MdOutlineAccessibilityNew":
        return <MdOutlineAccessibilityNew size={40} />;

      case "FaUniversalAccess":
        return <FaUniversalAccess size={40} />;

      case "FaAccessibleIcon":
        return <FaAccessibleIcon size={40} />;

      case "MdOutlineAccessible":
        return <MdOutlineAccessible size={40} />;

      default:
        return;
        break;
    }
  };

  const saveAccessibility = () => {
    const { accessibilityLocalState, quizId } = props;
    const accessibilityObject = {
      hide_accessibility: 0,
      accessibility_icon: accessibilityLocalState.accessibilityIcon,
      icon_color: accessibilityLocalState.iconColor,
      icon_background_color: accessibilityLocalState.iconBackgroundColor,
      position: accessibilityLocalState.position,
      statement: accessibilityLocalState.statement
        ? accessibilityLocalState.statement
        : "Accessibility Statement",
      quiz_id: quizId,
    };

    props.actions.handleAccessibilityConfiguration(accessibilityObject);
  };

  const hideAccessibilityAndSave = () => {
    const { accessibilityLocalState, quizId } = props;
    const accessibilityObject = {
      hide_accessibility: 1,
      accessibility_icon: accessibilityLocalState.accessibilityIcon,
      icon_color: accessibilityLocalState.iconColor,
      icon_background_color: accessibilityLocalState.iconBackgroundColor,
      position: accessibilityLocalState.position,
      statement: accessibilityLocalState.statement
          ? accessibilityLocalState.statement
          : "Accessibility Statement",
      quiz_id: quizId,
    };
    props.actions.handleAccessibilityConfiguration(accessibilityObject);
  }

  const handleIconSelection = (e) => {
    const { handleAccessibilityChanges } = props;
    let identifier;

    e.target.classList.length
      ? (identifier = e.target.classList[0])
      : (identifier = e.target.parentElement.classList[0]);

    // cheking if the user clicked on accesibility main icon or accesibility position icon
    // if the icon is position then cheking the direcion of the icon

    if (identifier.charAt(0) == "I") {
      if (identifier.charAt(7) == "U") {
        identifier === "ImArrowUpLeft2"
          ? (identifier = "ImArrowUpLeft2")
          : (identifier = "ImArrowUpRight2");
      } else {
        identifier === "ImArrowDownLeft2"
          ? (identifier = "ImArrowDownLeft2")
          : (identifier = "ImArrowDownRight2");
      }

      setPositionIconActive(identifier);
      handleAccessibilityChanges("position", identifier);
      return;
    }
    handleAccessibilityChanges("accessibilityIcon", identifier);
  };
  const { t, handleAccessibilityChanges } = props;
  const noAccessibilityString = `Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit,
 nobis modi obcaecati explicabo accusantium temporibus esse?
 Minima, placeat blanditiis ullam perspiciatis obcaecati quas
 nesciunt ad debitis corrupti, sit accusamus nemo provident
 distinctio laborum nihil necessitatibus dolorum quidem
 consequuntur? Cum odit quis recusandae dignissimos eligendi hic,
 rendis fuga eum ea dignissimos odit distinctio iusto`;
  return (
    <>
      <h2>{t("configuration-custom-accessibility")}</h2>
      <div className="accessibility-settings-wrapper">


        <input
          type="checkbox"
          name="accessibilityCheckbox"
          id="accessibilityCheckbox"
          className="hideAccessibility"
          defaultChecked={!props.accessibilityLocalState.hideAccessibility}
          onChange={() => {setHideAccessibility(!hideAccessibility) ; hideAccessibilityAndSave()}}
        />
        <label htmlFor="accessibilityCheckbox">
          {t("accessibility:accessibility-configuration-hide")}
        </label>
      </div>

      {!hideAccessibility ? (
        <>
          <div className="accessibility-options-wrapper">
            <div
              className="option-box icon "
              onClick={() => setShowIconSelection(!showIconSelection)}
            >
              <p>Icon</p>
              <img src={accessibilityImage} alt="" />
              {showIconSelection && (
                <>
                  <div id="iconSelection">
                    <IconContext.Provider
                      className="MdOutlineAccessibilityNew"
                      value={{ color: "#78889F" }}
                    >
                      <MdOutlineAccessibilityNew
                        size={40}
                        className="MdOutlineAccessibilityNew"
                        onClick={(e) => handleIconSelection(e)}
                      />
                    </IconContext.Provider>

                    <IconContext.Provider value={{ color: "#78889F" }}>
                      <FaUniversalAccess
                        size={40}
                        className="FaUniversalAccess"
                        onClick={(e) => handleIconSelection(e)}
                      />
                    </IconContext.Provider>

                    <IconContext.Provider value={{ color: "#78889F" }}>
                      <FaAccessibleIcon
                        size={40}
                        className="FaAccessibleIcon"
                        onClick={(e) => handleIconSelection(e)}
                      />
                    </IconContext.Provider>

                    <IconContext.Provider value={{ color: "#78889F" }}>
                      <MdOutlineAccessible
                        size={40}
                        className="MdOutlineAccessible"
                        onClick={(e) => handleIconSelection(e)}
                      />
                    </IconContext.Provider>
                  </div>
                </>
              )}
            </div>
            <div className="option-box background">
              <p>Background Color</p>
              <input
                className="accessibility-icon-background-color"
                name="accessibility-icon-background-color"
                id="accessibility-icon-background-color"
                style={{backgroundColor:props.accessibilityLocalState.iconBackgroundColor, padding: "0"}}
                type="color"
                defaultValue={props.accessibilityLocalState.iconBackgroundColor}
                onChange={(e) =>
                  handleAccessibilityChanges(
                    "iconBackgroundColor",
                    e.target.value
                  )
                }
              />
            </div>
            <div className="option-box icon-color">
              <p>Icon Color</p>
              <input
                className="accessibility-icon-color"
                name="accessibility-icon-color"
                id="accessibility-icon-color"
                type="color"
                style={{backgroundColor:props.accessibilityLocalState.iconColor, padding: "0"}}
                onChange={(e) =>
                  handleAccessibilityChanges("iconColor", e.target.value)
                }
                defaultValue={props.accessibilityLocalState.iconColor}
              />
            </div>

            <div className="option-box position">
              <p>Position:</p>

              <div className="position-icons">
                <div className="top-icons">
                  <IconContext.Provider
                    value={{
                      style: {
                        color:
                          positionIconActive === "ImArrowUpLeft2"
                            ? "#e8eef1"
                            : "#78889F",
                        background:
                          positionIconActive === "ImArrowUpLeft2"
                            ? "#78889F"
                            : "#e8eef1",
                      },
                    }}
                  >
                    <ImArrowUpLeft2
                      size={18}
                      onClick={(e) => handleIconSelection(e)}
                      className="ImArrowUpLeft2 "
                    />
                  </IconContext.Provider>
                  <IconContext.Provider
                    value={{
                      style: {
                        color:
                          positionIconActive === "ImArrowUpRight2"
                            ? "#e8eef1"
                            : "#78889F",
                        background:
                          positionIconActive === "ImArrowUpRight2"
                            ? "#78889F"
                            : "#e8eef1",
                      },
                    }}
                  >
                    <ImArrowUpRight2
                      size={18}
                      onClick={(e) => handleIconSelection(e)}
                      className="ImArrowUpRight2"
                    />
                  </IconContext.Provider>
                </div>

                <div className="bottom-icons">
                  <IconContext.Provider
                    value={{
                      style: {
                        color:
                          positionIconActive === "ImArrowDownLeft2"
                            ? "#e8eef1"
                            : "#78889F",
                        background:
                          positionIconActive === "ImArrowDownLeft2"
                            ? "#78889F"
                            : "#e8eef1",
                      },
                    }}
                  >
                    <ImArrowDownLeft2
                      size={18}
                      onClick={(e) => handleIconSelection(e)}
                      className="ImArrowDownLeft2"
                    />
                  </IconContext.Provider>
                  <IconContext.Provider
                    value={{
                      style: {
                        color:
                          positionIconActive === "ImArrowDownRight2"
                            ? "#e8eef1"
                            : "#78889F",
                        background:
                          positionIconActive === "ImArrowDownRight2"
                            ? "#78889F"
                            : "#e8eef1",
                      },
                    }}
                  >
                    <ImArrowDownRight2
                      size={18}
                      onClick={(e) => handleIconSelection(e)}
                      className="ImArrowDownRight2"
                    />
                  </IconContext.Provider>
                </div>
              </div>
            </div>

            <div className="option-box preview">
              <p>Preview</p>
              <div
                className="preview"
                style={{
                  backgroundColor: `${iconBackgroundColor}`,
                  padding: "20px 20px",
                  borderRadius: "50px",
                }}
              >
                <IconContext.Provider
                  value={{
                    color: `${iconColor}`,
                  }}
                >
                  {checkUserIcon(previewIcon)}
                </IconContext.Provider>
              </div>
            </div>
          </div>
          <div className="accessibility-statement">
            <h3>Accessibility Statement:</h3>
            <textarea
              rows="4"
              cols="50"
              className="statement"
              defaultValue={accessibilityStatement || ""}
              onChange={(e) =>
                handleAccessibilityChanges("statement", e.target.value)
              }
            ></textarea>
          </div>

          <div className="accessibility-save">
            <ConfigActionButtons
              toast={props.toast}
              selectedFunc={saveAccessibility}
            />
          </div>
        </>
      ) : (
        <>
          <div className="noAccessibility">
            <p>{noAccessibilityString}</p>

            <img src={noAccessibilityImage} alt="" />
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return state;
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      handleAccessibilityConfiguration: (settings) =>
        dispatch(handleAccessibilityConfigurationAction(settings)),
    },
  };
};
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(AccessibilityConfiguration)
);
