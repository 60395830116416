import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./integrations.css";
import {
  createNewApiKeyAction,
  getApiKeysAction,
  showApiKeyAction,
  toggleKeyActivationAction,
  deleteKeyAction,
} from "../../redux/actions";
import KeyCard from "./KeyCard";
import Navbar from "../Navbar";
import Collapse from "react-bootstrap/Collapse";

import Modal from "react-bootstrap/esm/Modal";
import CreateApiKeyModal from "./CreateApiKeyModal";
import { withTranslation } from "react-i18next";
function Integrations(props) {
  const {
    apikeys,
    createNewKey,
    getApiKeys,
    showKey,
    toggleKey,
    deleteKey,
    t,
  } = props;

  const [keyFrom, setKeyForm] = useState(false);
  const [keyNameForm, setKeyNameForm] = useState("");
  const [keyTypeForm, setKeyTypeForm] = useState("dev");
  const [keySecurityForm, setKeySecurityForm] = useState("none");

  const [keyFormError, setKeyFormError] = useState("");

  useEffect(() => {
    getApiKeys();
  }, []);

  const validateNewKey = () => {
    if (!keyNameForm || !keyTypeForm || !keySecurityForm) {
      setKeyFormError(" please fill all fileds");
      return;
    }
    setKeyFormError("");
    createNewKey({
      name: keyNameForm,
      type: keyTypeForm,
      security: keySecurityForm,
    });
    setKeyForm(false);
  };

  const keysList = apikeys.map((el) => (
    <KeyCard
      key={el.id}
      keyObj={el}
      showKey={showKey}
      toggleKey={toggleKey}
      deleteKey={deleteKey}
    />
  ));

  return (
    <div className="integration-page">
      <Navbar active="Integrations" helpLabel={t("help-center")}/>
      <section className="question-content p-1">
        <div className="integrationInner">
          <h2>{t("profile-integrations-inner-title")}</h2>
          <h5> {t("profile-integrations-inner-apiKeys")}</h5>
          <button
            className="addScripts border borderless"
            onClick={() => setKeyForm(!keyFrom)}
          >
            {t("profile-integrations-inner-create-new-key")}
          </button>
          <span className="text-danger">{keyFormError}</span>

          <Modal
            className="modal-mail"
            show={keyFrom}
            onHide={() => setKeyForm(false)}
          >
            <Modal.Header
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Modal.Title>Create New Api Key</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CreateApiKeyModal
                setKeyNameForm={setKeyNameForm}
                setKeyTypeForm={setKeyTypeForm}
                setKeySecurityForm={setKeySecurityForm}
                validateNewKey={validateNewKey}
                hide={() => setKeyForm(false)}
              />
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>

          <ul className="scriptList">{keysList}</ul>
        </div>
      </section>
    </div>
  );
}

const mapToProps = (state) => {
  return state;
};
const mapDispatch = (dispatch) => {
  return {
    getApiKeys: () => {
      dispatch(getApiKeysAction());
    },
    createNewKey: (keyObj) => {
      dispatch(createNewApiKeyAction(keyObj));
    },
    showKey: (id) => {
      dispatch(showApiKeyAction(id));
    },

    toggleKey: (isActive, key_id) => {
      dispatch(toggleKeyActivationAction(isActive, key_id));
    },

    deleteKey: (user_id, key_id) => {
      dispatch(deleteKeyAction(user_id, key_id));
    },
  };
};

export default withTranslation()(
  connect(mapToProps, mapDispatch)(Integrations)
);
