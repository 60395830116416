import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { withTranslation } from "react-i18next";
function ExitWithoutSaveModal(props) {
  const { saveQuiz, isOpen, closeModal, afterFunction } = props;
  const [invokeTheAfterFunction, setInvokeTheAfterFunction] = useState(false);
  const handleAfterFunction = (toSave = false) => {
    if (toSave) saveQuiz();
    if (typeof afterFunction === "function") setInvokeTheAfterFunction(true);
  };

  if (invokeTheAfterFunction) return afterFunction();
  return (
    <>
      <Modal show={isOpen} centered>
        <div className="rename-slide-modal">
          <h2>Do you want to save your Knowy before closing it?</h2>
          <button
            style={{ marginRight: "20px" }}
            className="btn-popup"
            onClick={() => handleAfterFunction(true)}
          >
            Save changes
          </button>
          <a
            type="button"
            style={{ marginRight: "20px" }}
            onClick={() => handleAfterFunction()}
          >
            Don’t save changes
          </a>
          <a type="button" onClick={() => closeModal()}>
            Cancel
          </a>
        </div>
      </Modal>
    </>
  );
}

export default withTranslation()(ExitWithoutSaveModal);
