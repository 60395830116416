import React, { useState } from "react";
import { connect } from "react-redux"
import { deleteQuiz } from "../../../redux/actions"
import Modal from 'react-bootstrap/Modal';
function ConfirmDelete(props) {

    const [modalIsOpen, setIsOpen] = useState(false);
    const [textField, setTextField] = useState('');
    const [isError, setIsError] = useState(true);
    const { t } = props;

    const handleChange = (e) => {
        const { value } = e.target
        if (value === "DELETE") setIsError(false);
        setTextField(value);
    }

    const handleClick = () => {
        if (textField !== "DELETE") return;
        props.actions.deleteQuiz(props.data.id)
    }

    return (
        <>
            <a onClick={() => setIsOpen(true)} type="button" title="Delete" className="bi bi-trash3 bin"></a>

            {/*<Modal*/}
            {/*    show={modalIsOpen}*/}
            {/*    onHide={() => setIsOpen(false)}*/}
            {/*    centered*/}
            {/*    style={{paddingTop: "40px", paddingBottom:"40px"}}*/}
            {/*>*/}
            {/*    <h2>Delete Project:</h2>*/}
            {/*    <p>Please type “DELETE” to delete a project</p>*/}
            {/*    <input type="text" name="textField" value={textField} onChange={handleChange} />*/}
            {/*    <button type="button" value="Delete" disabled={isError} onClick={handleClick} className="btn-popup-delete" >Delete</button>*/}
            {/*    <span className="close close_multi" onClick={() => setIsOpen(false)} >Wait, I want to keep it</span>*/}
            {/*</Modal>*/}


            <Modal
                show={modalIsOpen}
                onHide={() => setIsOpen(false)}
                centered

            >
                <div style={{paddingTop: "40px", paddingBottom:"40px"}}>
                <h2>{t("dashboard-quiz-delete-modal-h2")}</h2>
                <p>{t("dashboard-quiz-delete-modal-p")} </p>
                <input
                    type="text"
                    name="textField"
                    onChange={handleChange}
                />
                <button
                    type="button"
                    value={t("general-delete")}
                    disabled={isError} onClick={handleClick}
                    className={textField !== "DELETE" ? "btn-popup-delete btn-popup-delete disabled" : "btn-popup-delete"}
                >
                    {t("general-delete")}
                </button>
                <span
                    className="close close_multi"
                    onClick={() => setIsOpen(false)}
                >
                {t("dashboard-quiz-delete-modal-button")}
              </span>
                </div>
            </Modal>
        </>
    )

}

const mapStateToProps = (state) => {
    return { state }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            deleteQuiz: (id) => dispatch(deleteQuiz(id))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDelete)