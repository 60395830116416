import React, { useEffect, useState } from "react";
import { websiteUrl, configHostUrl } from "../../../config";
import ColorPicker from "../../CreateQuiz/ColorPicker";
import { copyToClip } from "../../../utils";

function Side(props) {
  const { slug, guid, path } = props;
  const [embedCode, setEmbedCode] = useState("");
  const [btnSize, setbtnSize] = useState(25);
  const [btnText, setBtnText] = useState("Hello World");
  const [btnColor, setBtnColor] = useState("black");
  const [btnBgColor, setBtnBgColor] = useState("#FBBB00");
  const [btnPosition, setBtnPosition] = useState("right");

  useEffect(() => {
    const embed = `<div><div id="${guid}" data-knowy-url="${path}" data-knowy-type="side" data-knowy-textSize="${btnSize}" data-knowy-textContent="${btnText}" data-knowy-btnColor="${btnColor}" data-knowy-btnBgColor="${btnBgColor}" data-knowy-btnPosition="${btnPosition}"></div><script src="//app.knowyyy.com/embed.js"></script></div>`;
    setEmbedCode(embed);
  }, [btnSize, btnColor, btnText, btnBgColor, btnPosition]);

  const editBackgroundColors = (e, funcName) => {
    const value = e.hex;
    funcName(value);
  };

  const layoutBtn = (layout, imgSrc) => {
    let classes = layout + " pointer";
    if (layout === btnPosition) {
      classes += " active";
    }
    return (
      <a title="" className={classes} onClick={() => setBtnPosition(layout)}>
        <img src={imgSrc} />
      </a>
    );
  };

  return (
    <div id="instant" className="active innerTabs">
      <div className="banner">
        <h3>Side</h3>
        <p>
          The Sidenote Embed feature in Knowy provides an elegant way to collect information from your users. With a single line of code, you can display a compact form on the side of your website, allowing visitors to open and interact with it with a simple click. The form opens smoothly, without disrupting the user's experience, and can be customized to match your website's look and feel.
        </p>
      </div>
      <div className="screens side right">
        <div className="mobail">
          <span style={{ "background-color": "#FBBB00" }}></span>
        </div>
        <div className="desktop">
          <span style={{ "background-color": "#FBBB00" }}></span>
        </div>
      </div>
      <div className="style">
        <div className="sideStayle">
          <h3>Style:</h3>
          <div className="field">
            <label>Text:</label>
            <input
              type="text"
              value={btnText}
              placeholder="Hello world"
              onChange={(e) => setBtnText(e.target.value)}
            />
          </div>
          <div className="field">
            <label>Size:</label>
            <input
              type="number"
              placeholder={btnSize || 0 + "%"}
              value={btnSize}
              min={1}
              max={100}
              onChange={(e) => setbtnSize(e.target.value)}
            />
          </div>
          <div className="d-flex">
            <div className="field">
              <label>Background color:</label>
              <ColorPicker
                setRight="inherit"
                color={btnBgColor}
                className="colorPiker"
                handleChange={(e) => editBackgroundColors(e, setBtnBgColor)}
              />
            </div>
            <div className="field p-2">
              <label>Text color:</label>
              <ColorPicker
                setRight="inherit"
                color={btnColor}
                className="colorPiker"
                handleChange={(e) => editBackgroundColors(e, setBtnColor)}
              />
            </div>
          </div>
        </div>
        <div className="layout">
          <h3>Layout:</h3>
          {layoutBtn("right", "/img/right-side.png")}

          {layoutBtn("left", "/img/left-side.png")}

          {layoutBtn("bottom", "/img/bottom.png")}
        </div>
        <div>
          <h3>Code:</h3>
          <textarea disabled value={embedCode}></textarea>
        </div>
      </div>
      <a onClick={() => copyToClip(embedCode)} title="" className="copy">
        Copy
      </a>
    </div>
  );
}

export default Side;
