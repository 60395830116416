import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import FooterStyle from "../../../ThemeEdit/FooterStyle";
import { FiEdit3 } from "react-icons/fi";
// import Avatar from "@material-ui/core/Avatar";
import { addStyleSettings } from "../../../../../utils/patchFunctions";


function FooterStyleCustomize(props) {
    const handleColorsToChange = (e, name) => {
        const { r, g, b, a } = e.rgb;
        const value = `rgba(${r}, ${g}, ${b}, ${a})`;
        props.handleQuestionStyle({ target: { name, value } }, props.id, "footer");
    };
    let {
        settings,
        style,
        handleQuestionStyle,
        fonts,
        id,
        useTitleCustomStyle,
        handleChangeQuestion,
        useFooterCustomStyle,
        t,

    } = props;
    const [hookSettings, setHookSettings] = useState({});
    const [hookStyle, setHookStyle] = useState({});


    if (!style.footer) {
        style = addStyleSettings(style, "footer");
    }

    useEffect(() => {
        if (!settings.footer) {
            const newSettings = addStyleSettings(settings, "footer");
            setHookSettings(newSettings);
        } else {
            setHookSettings(settings);
        }

        if (!style.footer) {
            const newstyle = addStyleSettings(style, "footer");

            setHookStyle(newstyle);
        } else {
            setHookStyle(style);
        }
    }, [style, settings]);

    return (
        <>
            <div className="addSwitch override">

                <label className="switch">
                    <input
                        type="checkbox"
                        name="withSubtitle"
                        checked={useFooterCustomStyle ? true : false}
                        onChange={(e) =>
                            handleChangeQuestion(
                                {
                                    target: {
                                        name: "useFooterCustomStyle",
                                        type: "checkbox",
                                        checked: useFooterCustomStyle ? false : true,
                                    },
                                },
                                id
                            )
                        }
                    />
                    <span className="slider round"></span>
                </label>
                {t("rightBar-customize-override")}


            </div>
            {useFooterCustomStyle ? (
                <>
                    <FooterStyle
                        fromTheme={false}
                        id={id}
                        selectedTheme={"override-edit"}
                        handleChange={handleQuestionStyle}
                        context="footer"
                        fonts={fonts}
                        footerObj={style.footer}
                        // props={props}
                        props={{ ...props, fonts }}
                        handleColorsToChange={handleColorsToChange}
                    />
                </>
            ) : (
                <>
                    <div className="addSwitch-disabled"></div>
                    <FooterStyle
                        mainSettings={props.mainSettings}
                        fromTheme={false}
                        id={id}
                        selectedTheme={"override-edit"}
                        handleChange={{}}
                        context="footer"
                        fonts={fonts}
                        footerObj={style.footer}
                        // props={props}
                        props={{ ...props, fonts }}
                        handleColorsToChange={{}}
                    />
                </>
            )}
        </>
    );
}

export default withTranslation()(FooterStyleCustomize);
