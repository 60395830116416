import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import config from './config'

import translationEN from "./en/translation.json";
import translationHE from "./he/translation.json";
import accessibilityEN from "./accessibility/en/englishTranslation.json";
import accessibilityHE from "./accessibility/he/hebrewTranslation.json";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: translationEN,
    accessibility: accessibilityEN,
    // translation: accessibilityEN,
  },
  he: {
    translation: translationHE,
    accessibility: accessibilityHE,

    // translation: accessibilityHE,
  },
};
//
// Object.keys(config).reduce((acc, key) => {
//         acc.he.translation[key] = config[key].he;
//         acc.en.translation[key] = config[key].en;
//         return acc;
//     },

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    defaultNS: "translation",

    // lng: 'en',

    // keySeparator: false, // we do not use keys in form messages.welcome
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    // react: {
    //   transSupportBasicHtmlNodes: true,
    //   transKeepBasicHtmlNodesFor: ['b']
    // }
  });

export default i18n;
