import React from "react";
import ChooseImage from "../ChooseImageModal";
import ColorPicker from "../ColorPicker";
import "../createQuiz.css";
import Fonts from "../../../utils/Fonts/Fonts";
import "../Add/Add.css";
import SelectSize from "../SelectSize";
import { connect } from "react-redux";
import { saveTheme, createNewThemeAction } from "../../../redux/actions";
import { Accordion, Card, Button } from "react-bootstrap";
import TitleAndSubTitle from "./titleAndSubtitle";
import Answers from "./answers";
import Background from "./background";
import Header from "./header";
import { withTranslation } from "react-i18next";
import ButtonStyle from "./buttonStyle";
import ContactFormDesign from "./contactForm";
import AlertStyle from "./AlertStyle";
import FooterStyle from "./FooterStyle";
import ProgressBarStyle from "./ProgressBarStyle";
import Modal from "react-bootstrap/esm/Modal";

import { addStyleSettings } from "../../../utils/patchFunctions";
import Products from "./products";
import Transitions from "./Transitions";
class Settings extends React.Component {
  state = {
    color1: false,
    color2: false,
    selectedTheme: null,
    themeName: "",
    themeId: null,
    showTitleSection: false,
    showSubtitleSection: false,
    showBackgroundSection: false,
    showHeaderSection: false,
    showFooterSection: false,
    showAnswersSection: false,
    newThemeName: "",
  };

  componentDidMount() {
    this.setState({
      selectedTheme: this.props.currentOnEdit.theme_data,
      themeName: this.props.currentOnEdit.theme_title,
      themeId: this.props.currentOnEdit.theme_id,
    });
  }

  changeBackgroundVideo = (video, thumbnail) => {
    this.props.updateMainSettings({
      target: { name: "backgroundVideoSrc", value: video },
    });

    this.props.updateMainSettings({
      target: { name: "backgroundMediaType", value: "video" },
    });

    this.props.updateMainSettings({
      target: { name: "backgroundImage", value: thumbnail },
    });
  };

  changeBackgroundImage = (image) => {
    this.props.updateMainSettings({
      target: { name: "backgroundImage", value: image },
    });

    this.props.updateMainSettings({
      target: { name: "backgroundMediaType", value: "image" },
    });

    this.props.updateMainSettings({
      target: { name: "backgroundVideoSrc", value: "" },
    });
  };

  changeHeaderImage = (image) => {
    this.props.changeNavs(
      { target: { name: "backgroundImage", value: image } },
      "header"
    );
  };

  hexToRgb = (e) => {
    let value = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
      e.target.value
    );
    value = value
      ? `rgb(${parseInt(value[1], 16)}, ${parseInt(value[2], 16)}, ${parseInt(
          value[3],
          16
        )})`
      : "rgb(255,255,255)";
    this.props.updateMainSettings({ target: { name: e.target.name, value } });
  };

  onSwatchHover = (color, toChange) => {
    const { r, g, b, a } = color.rgb;
    switch (toChange) {
      case "background":
        this.props.updateMainSettings({
          target: {
            name: "backgroundColor",
            value: `rgba(${r}, ${g}, ${b}, ${a})`,
          },
        });
        this.handleChange({
          target: {
            name: "backgroundColor",
            value: `rgba(${r}, ${g}, ${b}, ${a})`,
          },
        });
        return;

      case "backgroundOverlayColor":
        this.props.updateMainSettings({
          target: {
            name: "backgroundOverlayColor",
            value: `rgba(${r}, ${g}, ${b}, ${a})`,
          },
        });
        this.handleChange({
          target: {
            name: "backgroundOverlayColor",
            value: `rgba(${r}, ${g}, ${b}, ${a})`,
          },
        });
        return;
      case "header":
        return this.props.changeNavs(
          {
            target: {
              name: "background",
              value: `rgba(${r}, ${g}, ${b}, ${a})`,
            },
          },
          "header"
        );

      case "headerLink":
        return this.props.changeNavs(
          {
            target: {
              name: "linkColor",
              value: `rgba(${r}, ${g}, ${b}, ${a})`,
            },
          },
          "header"
        );
      case "footer":
        return this.props.changeNavs(
          {
            target: {
              name: "background",
              value: `rgba(${r}, ${g}, ${b}, ${a})`,
            },
          },
          "footer"
        );
    }
  };

  handleColorsToChange = (e, name, toChange) => {
    const { r, g, b, a } = e.rgb;
    const value = `rgba(${r}, ${g}, ${b}, ${a})`;
    this.props.changeMainStyle({ target: { name, value } }, toChange);
    this.handleChange({ target: { name, value } }, toChange);
  };

  changeLogo = (img, toChange) => {
    this.props.changeNavs(
      { target: { name: "logoImageSrc", value: img } },
      toChange
    );
  };

  toggleColorPicker = (e) => {
    this.setState({ [e.target.name]: !this.state[e.target.name] });
  };

  handleChange = (e, toChange, paddingPosition) => {
    let { selectedTheme } = this.state;
    const { name, value } = e.target;

    if (!toChange) {
      selectedTheme[name] = value;
      return this.setState({ selectedTheme });
    }

    if (paddingPosition) {
      selectedTheme[toChange][name][paddingPosition] = value;
      return this.setState({ selectedTheme });
    }

    if (!selectedTheme[toChange]) {
      selectedTheme = addStyleSettings(selectedTheme, toChange);
    }

    if (toChange.includes("alert.")) {
      const splitChangObj = toChange.split(".");

      selectedTheme[splitChangObj[0]][splitChangObj[1]][name] = value;

      return this.setState({ selectedTheme });
    }

    selectedTheme[toChange] = { ...selectedTheme[toChange], [name]: value };
    return this.setState({ selectedTheme });
  };

  saveTheme = (selectedTheme, themeName, themeId) => {
    this.props.actions.saveTheme(selectedTheme, themeName, themeId);
    this.props.setToThemeModal(false);
  };

  createNewTheme = () => {
    if (!this.state.newThemeName) return alert("Your theme must have a name");
    this.props.actions.createNewTheme({
      theme_data: this.props.currentOnEdit.theme_data,
      themeName: this.state.newThemeName,
      themeId: null,
    });
    this.props.setToThemeModal(false);
  };

  handleShowSections = (e) => {
    const { name, checked } = e.target;
    this.setState({ [name]: checked });
  };

  cancelTheme = () => {
    this.props.setIsEditMode(false);
  };

  render() {
    const { header, footer, type } = this.props.data;
    const { selectedTheme, themeName } = this.state;
    if (!selectedTheme) return <></>;
    const { t, setToThemeModal, toThemeModal } = this.props;
    return (
      <>
        <div id="themeEdit" className="tabContent tabActive">
          {themeName === "KnowyyyDefaultStyle" ? (
            <div>
              <p>
                This is current style of your Knowy. Any changes will be
                implemented in all slides
              </p>
            </div>
          ) : (
            <div>
              <h3>{t("rightBar-theme-new-theme-name")}</h3>
              <input
                type="text"
                value={themeName}
                onChange={(e) => this.setState({ themeName: e.target.value })}
                placeholder="Clean theme"
              />
            </div>
          )}
          <Accordion style={{ paddingTop: "10px" }} defaultActiveKey={null}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {t("rightBar-theme-new-theme-title")}
              </Accordion.Header>
              <Accordion.Body>
                <TitleAndSubTitle
                  selectedTheme={selectedTheme}
                  handleChange={this.handleChange}
                  context="title"
                  props={this.props}
                  fromTheme={true}
                  handleColorsToChange={this.handleColorsToChange}
                />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>
                {t("rightBar-theme-new-theme-subtitle")}
              </Accordion.Header>

              <Accordion.Body>
                <TitleAndSubTitle
                  selectedTheme={selectedTheme}
                  handleChange={this.handleChange}
                  context="subtitle"
                  props={this.props}
                  fromTheme={true}
                  handleColorsToChange={this.handleColorsToChange}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                {t("rightBar-theme-new-theme-button")}
              </Accordion.Header>
              <Accordion.Body>
                <ButtonStyle
                  selectedTheme={selectedTheme}
                  handleCurrentTheme={this.props.handleCurrentTheme}
                  handleChange={this.handleChange}
                  context="button"
                  props={this.props}
                  fromTheme={true}
                  handleColorsToChange={this.handleColorsToChange}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                {t("rightBar-theme-new-theme-answers")}
              </Accordion.Header>

              <Accordion.Body>
                <Answers
                  fromTheme={true}
                  selectedTheme={selectedTheme}
                  props={this.props}
                  handleChange={this.handleChange}
                  handleColorsToChange={this.handleColorsToChange}
                />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header>
                {t("rightBar-theme-new-theme-background")}
              </Accordion.Header>

              <Accordion.Body>
                <Background
                  selectedTheme={selectedTheme}
                  fromTheme={true}
                  props={this.props}
                  onSwatchHover={this.onSwatchHover}
                  changeBackgroundImage={this.changeBackgroundImage}
                  changeBackgroundVideo={this.changeBackgroundVideo}
                  handleChange={this.handleChange}
                />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5">
              <Accordion.Header>
                {t("rightBar-theme-new-theme-forms")}
              </Accordion.Header>

              <Accordion.Body>
                <ContactFormDesign
                  fromTheme={true}
                  selectedTheme={selectedTheme}
                  handleChange={this.handleChange}
                  context="forms"
                  props={this.props}
                  handleColorsToChange={this.handleColorsToChange}
                />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="6">
              <Accordion.Header>
                {t("rightBar-theme-new-theme-alert")}
              </Accordion.Header>

              <Accordion.Body>
                <AlertStyle
                  fromTheme={true}
                  selectedTheme={selectedTheme}
                  handleChange={this.handleChange}
                  context="alert"
                  props={this.props}
                  handleColorsToChange={this.handleColorsToChange}
                />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="7">
              <Accordion.Header>
                {t("rightBar-theme-new-theme-pb")}
              </Accordion.Header>

              <Accordion.Body>
                <ProgressBarStyle
                  fromTheme={true}
                  selectedTheme={selectedTheme}
                  handleChange={this.handleChange}
                  context="bar"
                  props={this.props}
                  handleColorsToChange={this.handleColorsToChange}
                />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="8">
              <Accordion.Header>
                {t("rightBar-theme-new-theme-header")}
              </Accordion.Header>

              <Accordion.Body>
                <Header
                  fromTheme={true}
                  selectedTheme={selectedTheme}
                  props={this.props}
                  handleChange={this.handleChange}
                  onSwatchHover={this.onSwatchHover}
                  changeBackgroundImage={this.changeBackgroundImage}
                  changeHeaderImage={this.changeHeaderImage}
                  changeLogo={this.changeLogo}
                />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="9">
              <Accordion.Header>
                {t("rightBar-theme-new-theme-footer")}
              </Accordion.Header>
              <Accordion.Body>
                <FooterStyle
                  selectedTheme={selectedTheme}
                  footerObj={this.props.data.footer}
                  handleChange={this.handleChange}
                  context={"footer"}
                  fonts={this.props.data.fonts}
                  addLinkToNav={this.props.addLinkToNav}
                  removeLinkFromNav={this.props.removeLinkFromNav}
                  onSwatchHover={this.onSwatchHover}
                  changeNavs={this.props.changeNavs}
                />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="10">
              <Accordion.Header>
                {t("rightBar-theme-new-theme-back-button")}
              </Accordion.Header>
              <Accordion.Body>
                <ButtonStyle
                  selectedTheme={selectedTheme}
                  handleCurrentTheme={this.props.handleCurrentTheme}
                  handleChange={this.handleChange}
                  context="backButton"
                  props={this.props}
                  fromTheme={true}
                  handleColorsToChange={this.handleColorsToChange}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="11">
              <Accordion.Header>
                {t("rightBar-theme-new-theme-transitions")}
              </Accordion.Header>
              <Accordion.Body>
                <Transitions
                  {...this.props.data.transitions}
                  handleSlidersTransitions={this.props.handleSlidersTransitions}
                />
              </Accordion.Body>
            </Accordion.Item>
            {type === "ecom" && (
              <Accordion.Item eventKey="12">
                <Accordion.Header>
                  {t("rightBar-theme-new-theme-products")}
                </Accordion.Header>

                <Accordion.Body>
                  <Products
                    fromTheme={true}
                    selectedTheme={selectedTheme}
                    props={this.props}
                    handleChange={this.handleChange}
                    handleColorsToChange={this.handleColorsToChange}
                  />
                </Accordion.Body>
              </Accordion.Item>
            )}
          </Accordion>
        </div>
        <div>
          {themeName === "KnowyyyDefaultStyle" ? (
            <div
              className="container"
              style={{
                position: "relative",
                bottom: "110px",
                padding: "0px 15px",
              }}
            >
              <div className="row">
                <div className="col">
                  <span
                    type="button"
                    onClick={() =>
                      this.props.changeRightBarView(
                        this.props.context,
                        "",
                        "themes"
                      )
                    }
                    title=""
                    className="galleryBtn"
                  >
                    To Gallery
                  </span>
                </div>
                <div className="col-md-auto">
                  <span
                    title="Add to my themes"
                    className="themesBtn"
                    onClick={() => setToThemeModal(true)}
                  >
                    Add to my themes
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <>
              <span
                type="button"
                onClick={this.saveTheme}
                title=""
                className="saveBtn"
              >
                {t("general-save")}
              </span>
              <span
                title="cancel"
                className="cancel"
                onClick={this.cancelTheme}
              >
                {t("general-cancel")}
              </span>
            </>
          )}
          <Modal
            className="package-modal"
            size="sm"
            show={toThemeModal}
            onHide={() => {
              setToThemeModal(false);
            }}
            centered
          >
            <div>
              <h3>Save To Theme:</h3>
              <div>
                <h3>{t("rightBar-theme-new-theme-name")}</h3>
                <input
                  type="text"
                  value={this.state.newThemeName}
                  onChange={(e) =>
                    this.setState({ newThemeName: e.target.value })
                  }
                  placeholder="Clean theme"
                />
              </div>
              <span
                type="button"
                onClick={() => {
                  this.createNewTheme();
                }}
                title=""
                className="saveBtn"
              >
                {t("general-save")}
              </span>
              <h3>Or</h3>
              <h3>choose a theme to override</h3>
              <div
                className="d-flex flex-wrap"
                style={{ maxHeight: "50vh", overflowY: "scroll" }}
              >
                {this.props.themes.map((theme) => {
                  return (
                    <div
                      key={theme.theme_id}
                      className="d-flex col-6"
                      onClick={() =>
                        this.saveTheme(
                          this.props.currentOnEdit.theme_data,
                          theme.theme_title,
                          theme.theme_id
                        )
                      }
                      style={{ minHeight: "60px" }}
                    >
                      <button className="btn btn-link">
                        {" "}
                        <img
                          style={{ maxWidth: "60px" }}
                          src={theme.theme_data.backgroundImage}
                          alt=""
                        />
                        {theme.theme_title}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </Modal>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ themes }) => {
  return { themes };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      saveTheme: (themeStyle, themeName, themeId) =>
        dispatch(saveTheme(themeStyle, themeName, themeId)),
      createNewTheme: (theme) => dispatch(createNewThemeAction(theme)),
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Settings)
);
