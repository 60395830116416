import React from "react";
import LinkSettings from "./LinkSettings";
import EmbeddFromShare from "./EmbeddFromShare";
import "../Configuration/config.css";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import i18n from "../../../translate/i18n";
import { addStr } from "../../../utils";
import { configHostUrl, websiteUrl } from "../../../config";

class ShareSettings extends React.Component {
  state = {
    toShow: "link",
  };

  componentDidMount() {
    const { accessibilityConfiguration } = this.props;

    this.setState({
      ...this.state,
      accessibilityLocalState: accessibilityConfiguration,
      toShow: this.props.data.redirectToEmbed ? "websiteEmbed" : "link",
    });
  }

  handleToShow = () => {
    const {
      data,
      changeConfigureDetails,
      handleCustomStyle,
      toast,
      saveEditedQuiz,
    } = this.props;
    const {
      id,
      slug,
      guid,
      domain_protocol,
      subdomain_name,
      orgId,
      is_redirected_domain,
      published,
    } = data;
    if (published != "1") {
      return (
        <div>
          <p>Publish quiz to see share settings.</p>
        </div>
      );
    }
    let subdomainPath;
    if (subdomain_name && !is_redirected_domain) {
      if (
        configHostUrl === "knowyyy.com" ||
        configHostUrl === "smarthippo.io"
      ) {
        subdomainPath = `https://${subdomain_name}.${configHostUrl}`;
      } else {
        subdomainPath = websiteUrl.includes("https://")
          ? addStr(websiteUrl, 8, `${subdomain_name}.`)
          : addStr(websiteUrl, 7, `${subdomain_name}.`);
      }
    } else if (subdomain_name) {
      subdomainPath = domain_protocol + "://" + subdomain_name;
    }
    const quizPath = subdomain_name
      ? `${subdomainPath}/page/${slug}`
      : `${websiteUrl}/quiz/${orgId}/${slug}`;
    switch (this.state.toShow) {
      case "link":
        return (
          <LinkSettings
            data={data}
            changeConfigureDetails={changeConfigureDetails}
            toast={toast}
            saveEditedQuiz={saveEditedQuiz}
            knowyLink={quizPath}
          />
        );
      case "websiteEmbed":
        return (
          <EmbeddFromShare
            quizId={id}
            slug={slug}
            guid={guid}
            path={quizPath}
          />
        );
      case "emailEmbed":
        return <></>;

      default:
        return <></>;
    }
  };

  isActive = (val) => {
    return val === this.state.toShow ? "active" : "";
  };

  render() {
    const { t } = this.props;
    return (
      <section className="dashboardContent config">
        <div
          className={
            i18n.language == "he"
              ? "config-tabs config-tabs-rtl"
              : "config-tabs"
          }
        >
          <h2>{t("shareSettings-title")}</h2>
          <a
            className={this.isActive("link")}
            onClick={() => this.setState({ toShow: "link" })}
          >
            {t("shareSettings-link")}
          </a>
          <a
            className={this.isActive("websiteEmbed")}
            onClick={() => this.setState({ toShow: "websiteEmbed" })}
          >
            {t("shareSettings-websiteEmbed")}
          </a>
          {/* <a
            className={this.isActive("emailEmbed")}
            onClick={() => this.setState({ toShow: "emailEmbed" })}
          >
            {t("shareSettings-emailEmbed")}
          </a> */}
        </div>
        <div className="tab-content">
          <div className="tab-content-inner">{this.handleToShow()}</div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};
// const mapDispatchToProps = (dispatch) => {
//   return {
//     actions: {
//       handleAccessibilityConfiguration: (settings) =>
//         dispatch(handleAccessibilityConfigurationAction(settings)),
//     },
//   };
// };
export default withTranslation()(connect(mapStateToProps, null)(ShareSettings));
