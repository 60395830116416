import React, { useState, useEffect } from "react";
import AppsList from "./AppsList";
import { withTranslation } from "react-i18next";

const ConnectApps = (props) => {
  const { data, isUserIntegrations, activateIntegratetion } = props;
  const {
    arrayOfData,
    variables,

    userId,
  } = data;

  return (
    <div className="dashboardContent config h-100">
      <div className="w-100 h-100">
        <section className="h-100">
          <div className="p-2 w-100 h-100" style={{ overflow: "scroll" }}>
            {isUserIntegrations ? (
              <>
                <AppsList arrayOfData={arrayOfData} variables={variables} />
              </>
            ) : (
              <div
                className=" d-flex flex-column justify-content-between align-items-center"
                style={{ minHeight: "50vh" }}
              >
                <h1>Integrations</h1>
                <h2>
                  In order to use this service you need to activate
                  Knowy-Integration Acount
                </h2>
                <h2>Would you like to activate your acount</h2>
                <button
                  className="btn btn-success btn-lg w-25"
                  onClick={() => activateIntegratetion(userId)}
                >
                  Activate
                </button>
              </div>
            )}
          </div>
        </section>{" "}
      </div>
    </div>
  );
};

export default withTranslation()(ConnectApps);
