import "react-phone-number-input/style.css";
import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-number-input";
import Input from "react-phone-number-input/input";

function PhoneNumberInput({
  defaultCountry,
  change,
  currInput,
  isPreview,
  defaultValue,
  minimalStyle,
  inputStyle,
  placeholder,
  hideCountryList = false,
  dataWithVariables,
}) {
  const [value, setValue] = useState();

  useEffect(() => {
    if (value || value === "") change(value, currInput);
  }, [value]);

  useEffect(() => {
    if (defaultValue && dataWithVariables) setValue(defaultValue);
  }, [defaultValue, dataWithVariables]);

  const selectWidth = isPreview ? "300px" : "200px";
  if (!hideCountryList)
    return (
      <PhoneInput
        {...inputStyle}
        style={{ width: selectWidth }}
        placeholder={placeholder}
        value={value || defaultValue}
        defaultCountry={defaultCountry}
        onChange={setValue}
        className="always-ltr"
      />
    );
  else
    return (
      <Input
        {...inputStyle}
        style={{ width: "100%" }}
        placeholder={placeholder}
        value={value || defaultValue}
        country={defaultCountry || "US"}
        onChange={setValue}
        international
        withCountryCallingCode
        className="always-ltr"
      />
    );
}

export default PhoneNumberInput;
