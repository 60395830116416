import React from "react";
import "./sidebar.css";
import { DragDropContext } from "react-beautiful-dnd";
import { Droppable, Draggable } from "react-beautiful-dnd";
import LogicModal from "../Logic/LogicModal";
import ProductsLogicModal from "./ProductsLogic/ProductsLogicModal";
import AddContentModal from "./addContentModal";
import {
  changeRightBarView,
  getStepLogicAction,
  getProductsLogicAction,
} from "../../../redux/actions";
import { connect } from "react-redux";
import ConfirmDelete from "./ConfirmDelete";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import RenameStep from "./RenameStep";
import AddResultModal from "./addResultModal";
class LeftBar extends React.Component {
  state = {
    showModal: false,
    selectedLogicStep: { index: null, step: null },
    showRenameModal: false,
  };

  componentDidMount() {
    const { id, type } = this.props.data;
    if (type === "ecom") {
      this.props.actions.getProductsLogic(id);
    }
  }

  handleOptionsView = (options) => {
    let titleToReturn = "";
    return options.map((option) => {
      const { title } = option;
      titleToReturn += `${title} </br>`;
      return titleToReturn;
    });
  };

  onDragEnd = ({ source, destination }) => {
    if (!source || !destination) return;
    this.props.changeIndexes(source.index, destination.index);
  };

  goToStepScreen = (e, id) => {
    // document.getElementById("mainContent").scrollTop =
    //   document.getElementById(id).offsetTop - 50;

    this.props.changeTheOnEditObject(id);
    this.props.actions.changeRightBarView("pageLayout", "", "customize");
  };

  handleShowModal = (index, step) => {
    this.setState({ showModal: true, selectedLogicStep: { index, step } });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      selectedLogicStep: { index: null, step: null },
    });
  };

  goToSettingsScreen = (e) => {
    this.props.changeTheOnEditObject("settingsScreen");
  };

  handleCreate = (value, qt = null) => {
    switch (value) {
      case "question": {
        return this.props.addQuestionToView(qt);
      }
      case "contact": {
        return this.props.addContactToView();
      }
      case "result": {
        return this.props.addResultToView(qt);
      }
      case "text": {
        return this.props.addTextToView();
      }
    }
  };

  isModal = () => {
    return this.state.selectedLogicStep.index !== null &&
      this.state.selectedLogicStep.step ? (
      <LogicModal
        permitions={this.props.permitions}
        getStepLogic={this.props.actions.getStepLogic}
        quizId={this.props.selectedDetails.quizDetails.id}
        logics={this.props.logicStepObj}
        index={this.state.selectedLogicStep.index}
        saveStepLogics={this.props.saveStepLogics}
        resetLogics={this.props.resetLogics}
        arrayOfData={this.props.data.arrayOfData}
        step={this.state.selectedLogicStep.step}
        variables={this.props.data.variables}
        isShow={this.state.showModal}
        closeModal={this.handleCloseModal}
        customScripts={this.handleLogicCustomcripts()}
        score={this.props.data.score}
      />
    ) : (
      <></>
    );
  };

  handleLogicCustomcripts = () => {
    const { customScripts = {} } = this.props.data;
    return Object.values(customScripts)
      .filter((script) => script.runtime == 6)
      .map((script) => {
        const { id, title } = script;
        return { id, title };
      });
  };

  render() {
    let {
      arrayOfData,
      variables,
      type: quizType,
      feedProducts = [],
    } = this.props.data;
    const notResultStep = arrayOfData.filter(el => el.type !== "result")
    const customScripts = this.handleLogicCustomcripts();
    const { id: startId } = arrayOfData[0];
    const { id: currentId } = this.props.data.onEdit;
    const { t, productsLogic = {} } = this.props;
    return (
      <div className="leftbar">
        <AddContentModal handleCreate={this.handleCreate} />
        <RenameStep
          id={this.props.data.onEdit.id}
          slideName={this.props.data.onEdit.slideName}
          changeSlideName={this.props.handleChange}
          isOpen={this.props.data.onEdit.created || this.state.showRenameModal}
          closeModal={() => this.setState({ showRenameModal: false })}
        />
        <DragDropContext
          onBeforeCapture={this.onBeforeCapture}
          onBeforeDragStart={this.onBeforeDragStart}
          onDragStart={this.onDragStart}
          onDragUpdate={this.onDragUpdate}
          onDragEnd={this.onDragEnd}
        >
          <Droppable
            droppableId="questionsAndContact"
            type="questionsAndContact"
          >
            {(provided, snapshot) => {
              return (
                <ul
                  className="content-list"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {arrayOfData[0].id === "Start" ? (
                    <li
                      className={
                        this.props.data.onEdit.id === "Start" ? "active" : ""
                      }
                      onClick={(e) => this.goToStepScreen(e, startId)}
                    >
                      <span>
                        {/* {t("leftBar-Welcome")} */}1{" "}
                        {arrayOfData[0].slideName}
                      </span>

                      <DropdownButton
                        id="dropdown-button-three-dots"
                        variant=""
                        title=""
                        className="dropDownBtn"
                      >
                        <LogicModal
                          permitions={this.props.permitions}
                          getStepLogic={this.props.actions.getStepLogic}
                          quizId={this.props.selectedDetails.quizDetails.id}
                          logics={this.props.logicStepObj}
                          resetLogics={this.props.resetLogics}
                          index={0}
                          saveStepLogics={this.props.saveStepLogics}
                          arrayOfData={arrayOfData}
                          step={arrayOfData[0]}
                          closeModal={this.handleCloseModal}
                          variables={variables}
                          customScripts={customScripts}
                          score={this.props.data.score}
                        />
                        <ProductsLogicModal
                          permitions={this.props.permitions}
                          getStepLogic={this.props.actions.getStepLogic}
                          quizId={this.props.selectedDetails.quizDetails.id}
                          logics={this.props.logicStepObj}
                          resetLogics={this.props.resetLogics}
                          index={0}
                          saveStepLogics={this.props.saveStepLogics}
                          arrayOfData={arrayOfData}
                          step={arrayOfData[0]}
                          closeModal={this.handleCloseModal}
                          variables={variables}
                          customScripts={customScripts}
                          score={this.props.data.score}
                          quizType={quizType}
                          feedProducts={feedProducts}
                          productsLogic={productsLogic}
                          saveProductsLogics={this.props.saveProductsLogics}
                        />
                        <Dropdown.Item
                          onClick={() =>
                            this.setState({ showRenameModal: true })
                          }
                        >
                          <i
                            className="bi bi-pencil left-bar-dropdown-item-icon"
                            style={{ display: "inline-block" }}
                          ></i>
                          {t("leftBar-dropdown-icons-rename")}
                        </Dropdown.Item>
                        {notResultStep.length > 1 && (<>
                          <ConfirmDelete
                            id={this.props.data.onEdit.id}
                            deleteFromArray={this.props.deleteFromArray}
                            t={t}
                          /> </>)}
                      </DropdownButton>
                    </li>
                  ) : (
                    <></>
                  )}
                  {arrayOfData.map((step, index) => {
                    const { id, title, type, counter, slideName = "" } = step;
                    const notResultStep = arrayOfData.filter(el => el.type !== "result")

                    if (
                      type === "text" ||
                      type === "question" ||
                      type === "contact"
                    ) {
                      return (
                        <Draggable key={id} draggableId={id} index={index}>
                          {(provided) => {
                            return (
                              <li
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                onClick={(e) => this.goToStepScreen(e, id)}
                                key={id}
                                id={`q-screen-li-${id}`}
                                className={
                                  this.props.data.onEdit.id === id
                                    ? "active"
                                    : ""
                                }
                              >
                                <span id={`q-screen-name-${id}`}>
                                  {/* {type === "question"
                                    ? "Q" + counter + ". "
                                    : ""} */}
                                  {`${index + 1}.`} {slideName}
                                </span>
                                <DropdownButton
                                  id="dropdown-button-three-dots"
                                  variant=""
                                  title=""
                                  className="dropDownBtn"
                                >
                                  <LogicModal
                                    permitions={this.props.permitions}
                                    quizId={
                                      this.props.selectedDetails.quizDetails.id
                                    }
                                    getStepLogic={
                                      this.props.actions.getStepLogic
                                    }
                                    logics={this.props.logicStepObj}
                                    resetLogics={this.props.resetLogics}
                                    index={index}
                                    saveStepLogics={this.props.saveStepLogics}
                                    arrayOfData={arrayOfData}
                                    step={step}
                                    variables={variables}
                                    closeModal={this.handleCloseModal}
                                    customScripts={customScripts}
                                    score={this.props.data.score}
                                  />
                                  <ProductsLogicModal
                                    permitions={this.props.permitions}
                                    quizId={
                                      this.props.selectedDetails.quizDetails.id
                                    }
                                    getStepLogic={
                                      this.props.actions.getStepLogic
                                    }
                                    logics={this.props.logicStepObj}
                                    resetLogics={this.props.resetLogics}
                                    index={index}
                                    saveStepLogics={this.props.saveStepLogics}
                                    arrayOfData={arrayOfData}
                                    step={step}
                                    variables={variables}
                                    closeModal={this.handleCloseModal}
                                    customScripts={customScripts}
                                    score={this.props.data.score}
                                    quizType={quizType}
                                    feedProducts={feedProducts}
                                    productsLogic={productsLogic}
                                    saveProductsLogics={
                                      this.props.saveProductsLogics
                                    }
                                  />
                                  <Dropdown.Item
                                    onClick={() =>
                                      this.setState({ showRenameModal: true })
                                    }
                                  >
                                    <i
                                      className="bi bi-pencil left-bar-dropdown-item-icon"
                                      style={{ display: "inline-block" }}
                                    ></i>
                                    {t("leftBar-dropdown-icons-rename")}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => this.props.duplicateStep(id)}
                                  >
                                    <i className="bi bi-files left-bar-dropdown-item-icon"></i>
                                    {t("leftBar-dropdown-icons-Duplicate")}
                                  </Dropdown.Item>
                                  {notResultStep.length > 1 && (<>
                                    <ConfirmDelete
                                      id={id}
                                      deleteFromArray={this.props.deleteFromArray}
                                      t={t}
                                    /></>)}
                                </DropdownButton>
                              </li>
                            );
                          }}
                        </Draggable>
                      );
                    }
                  })}
                  {provided.placeholder}
                </ul>
              );
            }}
          </Droppable>

          <Droppable droppableId="results" type="results">
            {(provided, snapshot) => {
              return (
                <div
                  className="answer"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <AddResultModal
                    handleCreate={this.handleCreate}
                    quizType={quizType}
                  />
                  <ul className="content-list-results">
                    {arrayOfData.map((step, index) => {
                      const {
                        id,
                        title,
                        type,
                        canDeleted,
                        slideName = "",
                      } = step;
                      if (type === "result") {
                        return (
                          <Draggable key={id} draggableId={id} index={index}>
                            {(provided) => {
                              return (
                                <li
                                  className={
                                    this.props.data.onEdit.id === id
                                      ? "active"
                                      : ""
                                  }
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  onClick={(e) => this.goToStepScreen(e, id)}
                                  key={id}
                                  id={`a-screen-li-${id}`}
                                >
                                  <span id={`a-screen-name-${id}`}>
                                    {slideName}
                                  </span>
                                  <DropdownButton
                                    id="dropdown-button-three-dots"
                                    variant=""
                                    title=""
                                    className="dropDownBtn"
                                  >
                                    <LogicModal
                                      permitions={this.props.permitions}
                                      quizId={
                                        this.props.selectedDetails.quizDetails
                                          .id
                                      }
                                      getStepLogic={
                                        this.props.actions.getStepLogic
                                      }
                                      logics={this.props.logicStepObj}
                                      resetLogics={this.props.resetLogics}
                                      index={index}
                                      saveStepLogics={this.props.saveStepLogics}
                                      arrayOfData={arrayOfData}
                                      step={step}
                                      variables={variables}
                                      closeModal={this.handleCloseModal}
                                      customScripts={customScripts}
                                      score={this.props.data.score}
                                    />
                                    <Dropdown.Item
                                      onClick={() =>
                                        this.setState({ showRenameModal: true })
                                      }
                                    >
                                      <i
                                        className="bi bi-pencil left-bar-dropdown-item-icon"
                                        style={{ display: "inline-block" }}
                                      ></i>
                                      {t("leftBar-dropdown-icons-rename")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        this.props.duplicateStep(id)
                                      }
                                    >
                                      <i className="bi bi-files left-bar-dropdown-item-icon"></i>{" "}
                                      {t("leftBar-dropdown-icons-Duplicate")}
                                    </Dropdown.Item>
                                    {canDeleted ? (
                                      <ConfirmDelete
                                        id={id}
                                        deleteFromArray={
                                          this.props.deleteFromArray
                                        }
                                        t={t}
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </DropdownButton>
                                </li>
                              );
                            }}
                          </Draggable>
                        );
                      }
                    })}
                    {provided.placeholder}
                  </ul>
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
        {this.isModal()}
      </div>
    );

    // IMPLAMENTING THE LOGIC MODEL OUTSIDE THE LI
  }
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      changeRightBarView: (context, subContextId, rightBarViewTab) =>
        dispatch(changeRightBarView(context, subContextId, rightBarViewTab)),
      getStepLogic: (quizId, stepId) =>
        dispatch(getStepLogicAction(quizId, stepId)),
      getProductsLogic: (quizId) => dispatch(getProductsLogicAction(quizId)),
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(LeftBar)
);
