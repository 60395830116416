import React, { useState, useEffect, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
// import "react-quill/dist/quill.bubble.css";
import "../../App.css";
import { stripTags } from "../../utils";

const BubbleTheme = Quill.import("themes/bubble");

class ExtendBubbleTheme extends BubbleTheme {
  constructor(quill, options) {
    super(quill, options);

  }
}

Quill.register("themes/bubble", ExtendBubbleTheme);

function EditorQuill(props) {
  const {
    text,
    id,
    name,
    data,
    tag,
    placeholder,
    className,
    classNameForTag,
    padding,
    lastQuillElementId,
    chnageQuillTollbar
  } = props;
  const { variables: variablesArray, arrayOfData = [] } = data;
  const questionsSteps = arrayOfData.filter((itr) => itr.type === "question");
  let contactSteps = arrayOfData.filter((itr) => itr.type === "contact");
  const allInputs = contactSteps.reduce((allInputs, step) => {
    const { inputs = [] } = step;
    inputs.forEach((input) => {
      if (input.type === "location") {
        input.inputs.forEach((locationInput) => {
          if (locationInput.show) {
            const newLocationInput = JSON.parse(JSON.stringify(locationInput));
            newLocationInput.label = `${input.label}_${newLocationInput.label}`;
            allInputs.push(newLocationInput);
          }
        });
      } else allInputs.push(input);
    });
    return allInputs;
  }, []);

  const ref = useRef(null);

  useEffect(() => {
    // make paste from word or styleing ediotrs strip the styles effect
    if (ref) {
      const quill = ref.current;
      const editor = quill.getEditor();

      editor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        delta.ops = delta.ops.map((op) => {
          return {
            insert: op.insert,
          };
        });
        return delta;
      });
    }
  }, [ref]);

  useEffect(() => {
    const currentId = `toolbar_${id.replace(".", "")}_${name}`
    const quill = ref.current;
    const editor = quill.getEditor();
    if (currentId === lastQuillElementId) {
      editor.theme.tooltip.show()
    } else {
      editor.theme.tooltip.hide()
    }

  }, [lastQuillElementId]);

  const changeInput = (value) => {

    props.handleChange({ target: { name, value: value } });
  };


  return (
    <div className="text-editor">
      <div id={`toolbar_${id.replace(".", "")}`} style={{ width: 300 }}>
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />

        <select className="ql-color"> </select>

        <button className="ql-direction" value="rtl"></button>
        {props.placeholder == "Answer" ||
          props.placeholder == "Write title here" ||
          props.placeholder == "Write text here" ? (
          <></>
        ) : (
          <button className="ql-link" />
        )}

        <select id="variables-editor" className="ql-variables">
          <option value="">Variables</option>
          {variablesArray.map((variable, index) => {
            const { name } = variable;
            return (
              <option key={index} value={JSON.stringify({ value: name, name })}>
                {name}
              </option>
            );
          })}
          {questionsSteps.map((question, index) => {
            const { title, id } = question;
            return (
              <option
                key={index}
                value={JSON.stringify({
                  value: id,
                  name: id,
                })}
              >
                {title.replace(/<[^>]+>/g, "")}
              </option>
            );
          })}
          {allInputs.map((input, index) => {
            const { label, name, type } = input;
            return (
              <option
                key={index}
                value={JSON.stringify({
                  value: label
                    ? label.toLowerCase().replaceAll(" ", "")
                    : type.toLowerCase().replaceAll(" ", ""),
                  name: label
                    ? label.toLowerCase().replaceAll(" ", "")
                    : name.toLowerCase().replaceAll(" ", ""),
                })}
              >
                {label}
              </option>
            );
          })}
        </select>
      </div>
      {/* <span dangerouslySetInnerHTML={{ __html: text }} /> */}
      <ReactQuill
        ref={ref}
        // defaultValue={text}
        //changes this for undo redo if problem change
        value={text}
        id={id}
        name={name}
        onChange={changeInput.bind(this)}
        style={{ padding }}
        modules={{
          toolbar: {
            container: `#toolbar_${id.replace(".", "")}`,
            handlers: { variables: variables },
          },
          clipboard: {
            // matchVisual: false,
          },
        }}
        placeholder={placeholder}
        // formats={EditorQuill.formats}
        theme="bubble"
        className={className}
        syntax={false}


        onFocus={(range, source, quillFunc) => {
          const currentId = `toolbar_${id.replace(".", "")}_${name}`
          chnageQuillTollbar(currentId);
        }
        }

      />
    </div>
  );
}

function variables(variable, e) {
  if (!variable) return;
  let { name, value } = JSON.parse(variable);
  // value = `{{${variable}}}`;

  var range = this.quill.getSelection();
  let position = range ? range.index : 0;
  this.quill.insertText(position, `{{${value}}}`);
}

EditorQuill.formats = [
  "break",
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "image",
  "color",
  "direction",
  "hashtag",
  "contenteditable",
  "link",
];

export default EditorQuill;
