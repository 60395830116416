import React, { useState } from "react";
import Fonts from "../../../utils/Fonts/Fonts";
import ColorPicker from "../ColorPicker";
import SelectSize from "../SelectSize";
import { withTranslation } from "react-i18next";
import Justify from "../../justify/justify";
import Form from "react-bootstrap/Form";
import PaddingEdit from "./PaddingEdit";
import { useMediaQuery } from "react-responsive";
import { ImFont, ImFontSize, ImParagraphJustify } from "react-icons/im";
import { BiFontColor } from "react-icons/bi";
import {
  RiTextSpacing,
  RiArrowUpSLine,
  RiArrowDownSLine,
} from "react-icons/ri";

import Modal from "react-bootstrap/esm/Modal";
import PlatformSelect from "../../PlatformSelect";

function Answers({
  selectedTheme,
  handleChange,
  props,
  handleColorsToChange,
  fromTheme,
}) {
  const { settings, fonts, t, id } = props;

  const [modalName, setModalName] = useState("");
  const [mode, setMode] = useState({});
  const context = "answers";

  const styleObject =
    selectedTheme == "override-edit"
      ? props.style.answers
      : selectedTheme.answers;

  // const styleObject = selectedTheme.answers;

  //@@ function that return diffrent block of padding depanding if theme or customize (modal)
  //being called from inside the component returns HTML
  const drawPaddingEditSection = () => {
    // if (selectedTheme !== "override-edit") {
    return (
      <div className="fontStyle space line">
        <div className="row platform-selection-container">
          <h3 className="col-6"> {t("theme-accordion-space")}</h3>
          <PlatformSelect showPlatformSelect={true} setMode={setMode} />
        </div>{" "}
        <div>
          <PaddingEdit
            paddingObject={styleObject.padding}
            selectedTheme={selectedTheme}
            context={context}
            id={id}
            mode={mode}
            handleChange={handleChange}
            changeMainStyle={props.changeMainStyle}
            t={t}
          />
        </div>
      </div>
    );
  };

  //@@ function that return diffrent block of font size depanding if theme or customize (modal)
  //being called from inside the component returns HTML
  const drawFontSizeEditSection = () => {
    if (selectedTheme !== "override-edit") {
      return (
        <div className="fontStyle">
          <SelectSize
            fromTheme={fromTheme}
            name="fontSize"
            label={t("general-font-size")}
            value={{
              desktop: styleObject.fontSize,
              mobile: styleObject.mobileFontSize || styleObject.fontSize,
              tablet: styleObject.tabletFontSize || styleObject.fontSize,
            }}
            handleChange={(size, name = "fontSize") => {
              selectedTheme == "override-edit"
                ? (function () {
                    handleChange(
                      { target: { name, value: size } },
                      id,
                      context
                    );
                  })()
                : (function () {
                    props.changeMainStyle(
                      { target: { name, value: size } },
                      context
                    );
                    handleChange({ target: { name, value: size } }, context);
                  })();
            }}
            changedDetails={{ toChange: context }}
          />
        </div>
      );
    } else {
      return (
        <>
          <div className="fontSizeDiv">
            <SelectSize
              fromTheme={true}
              name="fontSize"
              label={t("general-font-size")}
              value={{
                desktop: styleObject.fontSize,
                mobile: styleObject.mobileFontSize || styleObject.fontSize,
                tablet: styleObject.tabletFontSize || styleObject.fontSize,
              }}
              handleChange={(size, name = "fontSize") => {
                selectedTheme == "override-edit"
                  ? (function () {
                      handleChange(
                        { target: { name, value: size } },
                        id,
                        context
                      );
                    })()
                  : (function () {
                      props.changeMainStyle(
                        { target: { name, value: size } },
                        context
                      );
                      handleChange({ target: { name, value: size } }, context);
                    })();
              }}
              changedDetails={{ toChange: context }}
            />
          </div>
          {/*</Modal>*/}
        </>
      );
    }
  };

  //@@ function that return diffrent block of Border Attribures depanding if theme or customize (modal)
  //being called from inside the component returns HTML
  const drawBorderEditSection = () => {
    if (selectedTheme !== "override-edit") {
      return (
        <>
          {" "}
          <div className="space line" style={{ width: "25%" }}>
            <h3>{t("theme-accordion-border-size")} </h3>
            <label style={{ width: "100%" }}>
              <input
                type="number"
                name="buttonsBorderSize"
                value={
                  styleObject.buttonsBorderSize
                    ? styleObject.buttonsBorderSize
                    : "0"
                }
                onChange={(e) => {
                  selectedTheme == "override-edit"
                    ? handleChange(e, id, context)
                    : (function (e) {
                        props.changeMainStyle(e, context);
                        handleChange(e, context);
                      })(e);
                }}
                border="1"
              />
              px
            </label>
          </div>
          <div className="space line" style={{ width: "25%" }}>
            <h3>{t("theme-accordion-border-radius")} </h3>

            <label style={{ width: "100%" }}>
              <input
                type="number"
                name="buttonsBorderRadius"
                value={
                  styleObject.buttonsBorderRadius
                    ? styleObject.buttonsBorderRadius
                    : "0"
                }
                onChange={(e) => {
                  selectedTheme == "override-edit"
                    ? handleChange(e, id, context)
                    : (function (e) {
                        props.changeMainStyle(e, context);
                        handleChange(e, context);
                      })(e);
                }}
                border="1"
              />
              px
            </label>
          </div>
        </>
      );
    } else {
      return (
        <>
          {/*<div className="padding-modal-toggle">*/}
          {/*  <button*/}
          {/*    onClick={() =>*/}
          {/*      setModalName(modalName === "border" ? "" : "border")*/}
          {/*    }*/}
          {/*  >*/}
          {/*    {t("theme-accordion-border")}*/}
          {/*    {modalName === "border" ? (*/}
          {/*      <RiArrowDownSLine size={"2em"} />*/}
          {/*    ) : (*/}
          {/*      <RiArrowUpSLine size={"2em"} />*/}
          {/*    )}*/}
          {/*  </button>*/}
          {/*</div>*/}
          {/*<Modal*/}
          {/*  className="borderModal"*/}
          {/*  size="xs"*/}
          {/*  show={modalName === "border"}*/}
          {/*  onHide={() => setModalName("")}*/}
          {/*  // centered*/}
          {/*>*/}
          <div className="fontStyle line d-flex">
            <div
              className="space line flex-column"
              style={{ marginRight: "15px" }}
            >
              <h3>{t("theme-accordion-border-size")} </h3>
              <label style={{ width: "100%" }}>
                <input
                  type="number"
                  name="buttonsBorderSize"
                  value={
                    styleObject.buttonsBorderSize
                      ? styleObject.buttonsBorderSize
                      : "0"
                  }
                  onChange={(e) => {
                    selectedTheme == "override-edit"
                      ? handleChange(e, id, context)
                      : (function (e) {
                          props.changeMainStyle(e, context);
                          handleChange(e, context);
                        })(e);
                  }}
                  border="1"
                />
                px
              </label>
            </div>
            <div className="space line flex-column">
              <h3>{t("theme-accordion-border-radius")} </h3>

              <label style={{ width: "100%" }}>
                <input
                  type="number"
                  name="buttonsBorderRadius"
                  value={
                    styleObject.buttonsBorderRadius
                      ? styleObject.buttonsBorderRadius
                      : "0"
                  }
                  onChange={(e) => {
                    selectedTheme == "override-edit"
                      ? handleChange(e, id, context)
                      : (function (e) {
                          props.changeMainStyle(e, context);
                          handleChange(e, context);
                        })(e);
                  }}
                  border="1"
                />
                px
              </label>
            </div>
          </div>
          {/*</Modal>*/}
        </>
      );
    }
  };

  //@@ function that return diffrent block of colors Attribures depanding if theme or customize (modal)
  //being called from inside the component returns HTML
  const drawColorsEditSection = () => {
    if (selectedTheme !== "override-edit") {
      return (
        <>
          {" "}
          <div className="fontStyle">
            <h3>{t("theme-accordion-color-section")} </h3>
          </div>
          <div
            style={{
              display: "flex",
              columnGap: "16px",
              margin: "0px 0 10px 0",
              justifyContent: "space-between",
            }}
          >
            <div className="fontStyle" style={{ textAlign: "center" }}>
              <div className="buttonColor">
                <ColorPicker
                  setRight="inherit"
                  isButtonStyle={true}
                  color={
                    styleObject.buttonsTextColor
                      ? styleObject.buttonsTextColor
                      : "black"
                  }
                  handleChange={(e) => {
                    handleColorsToChange(e, "buttonsTextColor", context);
                  }}
                />
              </div>
              <div className="noPadding">
                {" "}
                <h3>{t("theme-accordion-text-color")} </h3>
              </div>
            </div>

            <div className="fontStyle" style={{ textAlign: "center" }}>
              <div className="buttonColor">
                <ColorPicker
                  setRight="30px"
                  isButtonStyle={true}
                  color={styleObject.buttonsBackgroundColor}
                  handleChange={(e) =>
                    handleColorsToChange(e, "buttonsBackgroundColor", context)
                  }
                />
              </div>

              <div className="noPadding">
                <h3>{t("theme-accordion-background-color")} </h3>
              </div>
            </div>

            <div className="fontStyle" style={{ textAlign: "center" }}>
              <div className="buttonColor">
                <ColorPicker
                  isButtonStyle={true}
                  setRight="0px"
                  color={styleObject.buttonsBorderColor}
                  name="buttonsBorderColor"
                  handleChange={(e) =>
                    handleColorsToChange(e, "buttonsBorderColor", context)
                  }
                />
              </div>
              <div className="noPadding">
                {" "}
                <h3>{t("theme-accordion-border-color")} </h3>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          {/*<div className="padding-modal-toggle">*/}
          {/*  <button*/}
          {/*    onClick={() =>*/}
          {/*      setModalName(modalName === "colors" ? "" : "colors")*/}
          {/*    }*/}
          {/*  >*/}
          {/*    {t("theme-accordion-border-color")}*/}
          {/*    {modalName === "colors" ? (*/}
          {/*      <RiArrowDownSLine size={"2em"} />*/}
          {/*    ) : (*/}
          {/*      <RiArrowUpSLine size={"2em"} />*/}
          {/*    )}*/}
          {/*  </button>*/}
          {/*</div>*/}
          {/*<Modal*/}
          {/*  className="colorsModal"*/}
          {/*  size="xs"*/}
          {/*  show={modalName === "colors"}*/}
          {/*  onHide={() => setModalName("")}*/}
          {/*  // centered*/}
          {/*>*/}
          <div className="fontStyle space space-modal line d-flex w-100 ">
            <div
              style={{
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div className="buttonColor" style={{ textAlign: "center" }}>
                <ColorPicker
                  setRight="inherit"
                  isButtonStyle={true}
                  color={
                    styleObject.buttonsTextColor
                      ? styleObject.buttonsTextColor
                      : "black"
                  }
                  handleChange={(e) => {
                    handleColorsToChange(e, "buttonsTextColor", context);
                  }}
                />
                <h3>{t("theme-accordion-text-color")} </h3>
              </div>

              <div className="fontStyle" style={{ textAlign: "center" }}>
                <div
                  className="buttonColor"
                  // style={{ textAlign: "center" }}
                >
                  <ColorPicker
                    isButtonStyle={true}
                    setRight="30px"
                    color={styleObject.buttonsBackgroundColor}
                    handleChange={(e) =>
                      handleColorsToChange(e, "buttonsBackgroundColor", context)
                    }
                  />
                </div>

                <h3>{t("theme-accordion-background-color")} </h3>
              </div>

              <div className="fontStyle">
                <div className="borderColor" style={{ textAlign: "center" }}>
                  <ColorPicker
                    isButtonStyle={true}
                    setRight="0px"
                    color={styleObject.buttonsBorderColor}
                    name="buttonsBorderColor"
                    handleChange={(e) =>
                      handleColorsToChange(e, "buttonsBorderColor", context)
                    }
                  />
                </div>
                <h3>{t("theme-accordion-border-color")} </h3>
              </div>
            </div>
          </div>
          {/*</Modal>*/}
        </>
      );
    }
  };

  //@@ function that return diffrent block of box-size Attribures depanding if theme or customize (modal)
  //being called from inside the component returns HTML
  const drawBoxSizeEditSection = () => {
    let desktopPicSize = styleObject.picSize
      ? styleObject.picSize
      : settings.answers.picSize;
    let mobilePicSize = styleObject.mobilePicSize
      ? styleObject.mobilePicSize
      : settings.answers.mobilePicSize;
    let tabletPicSize = styleObject.tabletPicSize
      ? styleObject.tabletPicSize
      : settings.answers.tabletPicSize;

    let desktopTextContainerSize = styleObject.textContainerSize
      ? styleObject.textContainerSize
      : settings.answers.textContainerSize;
    let mobileTextContainerSize = styleObject.textContainerSize
      ? styleObject.mobileTextContainerSize
      : settings.answers.textContainerSize;
    let tabletTextContainerSize = styleObject.tabletTextContainerSize
      ? styleObject.tabletTextContainerSize
      : settings.answers.tabletTextContainerSize;

    if (selectedTheme !== "override-edit") {
      return (
        <>
          <div className="fontSizeDiv" style={{ marginTop: "25px" }}>
            <SelectSize
              fromTheme={true}
              timesFourOnlyInput={true}
              name="picSize"
              label={t("theme-accordion-image-size")}
              value={{
                desktop: desktopPicSize,
                mobile: mobilePicSize || desktopPicSize,
                tablet: tabletPicSize || desktopPicSize,
              }}
              handleChange={(size, name = "picSize") => {
                selectedTheme == "override-edit"
                  ? (function () {
                      handleChange(
                        { target: { name, value: size * 4 } },
                        id,
                        context
                      );
                    })()
                  : (function () {
                      props.changeMainStyle(
                        { target: { name, value: size * 4 } },
                        context
                      );
                      handleChange(
                        { target: { name, value: size * 4 } },
                        context
                      );
                    })();
              }}
              changedDetails={{ toChange: context }}
            />

            <div style={{ marginTop: "15px" }}>
              <SelectSize
                fromTheme={true}
                timesFourOnlyInput={true}
                name="textContainerSize"
                label={t("theme-accordion-answer-text-size")}
                value={{
                  desktop: desktopTextContainerSize,
                  mobile: mobileTextContainerSize || desktopTextContainerSize,
                  tablet: tabletTextContainerSize || desktopTextContainerSize,
                }}
                handleChange={(size, name = "textContainerSize") => {
                  selectedTheme == "override-edit"
                    ? (function () {
                        handleChange(
                          { target: { name, value: size * 4 } },
                          id,
                          context
                        );
                      })()
                    : (function () {
                        props.changeMainStyle(
                          { target: { name, value: size * 4 } },
                          context
                        );
                        handleChange(
                          { target: { name, value: size * 4 } },
                          context
                        );
                      })();
                }}
                changedDetails={{ toChange: context }}
              />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="fontSizeDiv" style={{ marginTop: "25px" }}>
            <SelectSize
              fromTheme={true}
              timesFourOnlyInput={true}
              name="picSize"
              label={t("theme-accordion-image-size")}
              value={{
                desktop: desktopPicSize,
                mobile: mobilePicSize || desktopPicSize,
                tablet: tabletPicSize || desktopPicSize,
              }}
              handleChange={(size, name = "picSize") => {
                selectedTheme == "override-edit"
                  ? (function () {
                      handleChange(
                        { target: { name, value: size * 4 } },
                        id,
                        context
                      );
                    })()
                  : (function () {
                      props.changeMainStyle(
                        { target: { name, value: size * 4 } },
                        context
                      );
                      handleChange(
                        { target: { name, value: size * 4 } },
                        context
                      );
                    })();
              }}
              changedDetails={{ toChange: context }}
            />

            <div style={{ marginTop: "15px" }}>
              <SelectSize
                fromTheme={true}
                timesFourOnlyInput={true}
                name="textContainerSize"
                label={t("theme-accordion-answer-text-size")}
                value={{
                  desktop: desktopTextContainerSize,
                  mobile: mobileTextContainerSize || desktopTextContainerSize,
                  tablet: tabletTextContainerSize || desktopTextContainerSize,
                }}
                handleChange={(size, name = "textContainerSize") => {
                  selectedTheme == "override-edit"
                    ? (function () {
                        handleChange(
                          { target: { name, value: size * 4 } },
                          id,
                          context
                        );
                      })()
                    : (function () {
                        props.changeMainStyle(
                          { target: { name, value: size * 4 } },
                          context
                        );
                        handleChange(
                          { target: { name, value: size * 4 } },
                          context
                        );
                      })();
                }}
                changedDetails={{ toChange: context }}
              />
            </div>
          </div>
        </>
      );
    }
  };
  if (!selectedTheme) return <></>;

  if (
    selectedTheme == "override-edit" &&
    !styleObject.isOverride &&
    typeof handleChange === "function"
  ) {
    // props.style[context] = Object.assign({}, settings[context]);
    Object.entries(settings[context]).forEach(([key, value]) => {
      handleChange({ target: { name: key, value } }, id, context);
    });
    handleChange({ target: { name: "isOverride", value: true } }, id, context);
  }

  return (
    <>
      <div className="toolbar">
        <div className="fontStyle">
          {fromTheme ? <h3>{t("theme-accordion-font-style")} </h3> : ""}
          <Fonts
            templatesFonts={fonts}
            // selected={settings.answers.fontFamily.replace(/\+/g, " ")}
            selected={
              styleObject.fontFamily.replace(/\+/g, " ")
                ? styleObject.fontFamily.replace(/\+/g, " ")
                : settings.answers.fontFamily.replace(/\+/g, " ")
            }
            saveFont={(font) => {
              selectedTheme == "override-edit"
                ? (function () {
                    handleChange(
                      { target: { name: "fontFamily", value: font } },
                      id,
                      "answers"
                    );
                  })()
                : (function () {
                    props.changeMainStyle(
                      { target: { name: "fontFamily", value: font } },
                      "answers"
                    );
                    handleChange(
                      { target: { name: "fontFamily", value: font } },
                      "answers"
                    );
                  })();
            }}
          />
        </div>
        {drawFontSizeEditSection()}

        <div
          style={{
            display: "flex",
            columnGap: "16px",
            margin: "10px 0 10px 0",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Justify
            JustifyText={fromTheme ? t("theme-accordion-text-alignment") : ""}
            handleStyleFunctionName={
              selectedTheme == "override-edit"
                ? "handleQuestionStyle"
                : "changeMainStyle"
            }
            targetName={"alignItems"}
            value="answers"
            id={selectedTheme == "override-edit" ? id : null}
            actionFunction={
              selectedTheme == "override-edit"
                ? handleChange
                : props.changeMainStyle
            }
            selectedJustify={
              selectedTheme == "override-edit"
                ? styleObject.alignItems
                : settings.answers.alignItems
            }
          />
          {drawBorderEditSection()}
        </div>

        {drawColorsEditSection()}

        {drawPaddingEditSection()}
        {drawBoxSizeEditSection()}
      </div>
    </>
  );
}
export default withTranslation()(Answers);
