import React from "react";
import AddResult from "./AddResult";
import ShareSettings from "./ShareSettings";

class Result extends React.Component {
  state = {
    componentToRender: "details",
  };
  componentToRender = () => {
    const {
      data,
      handleChangeResult,
      handleChangeResultShare,
      handleShareDetails,
    } = this.props;
    switch (this.state.componentToRender) {
      case "details":
        return (
          <AddResult
            data={data}
            handleChangeResult={handleChangeResult}
            handleChangeResultShare={handleChangeResultShare}
          />
        );

      case "settings":
        return (
          <ShareSettings data={data} handleShareDetails={handleShareDetails} />
        );
      default:
        break;
    }
  };

  render() {
    return this.componentToRender();
  }
}

export default Result;
