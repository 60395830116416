import React, { useState, useRef } from "react";
import { useEffect } from "react";

export default function AppItem({ selectedApp, appDetails, setSelectedApp }) {
  let buttonRefs = null; // To store refs to each button
  const { id, name, label, image, base_json } = appDetails;

  useEffect(() => {
    if (buttonRefs) {
      buttonRefs.scrollIntoView({
        behavior: "smooth", // You can change this to 'auto' for immediate scrolling
        block: "start", // Scroll to the top of the button
      });
    }
  }, [selectedApp]);

  const handleClick = () => {
    setSelectedApp(appDetails);
  };

  return (
    <div
      ref={(ref) => (buttonRefs = ref)}
      key={`${label.replaceAll(" ", "")}-${id}`}
      id={`${label.replaceAll(" ", "")}-${id}`}
      className={
        selectedApp && selectedApp.id == id
          ? "connectAppDivSelected column-item"
          : "connectAppDiv column-item"
      }
      onClick={handleClick}
    >
      <img width={24} src={image} />
      <span className={"appListTxt"}> {label}</span>
    </div>
  );
}
