import React, { useEffect, useState } from "react";
import Collapse from "react-bootstrap/Collapse";

import { IoTrashOutline } from "react-icons/io5";
import DateTimePicker from 'react-datetime-picker';
import DatePicker from 'react-date-picker';
import TextInput from "react-autocomplete-input";
import AcTest from "../../../AcTest/Index";


function AddContactDateField(props) {
    const {
        step,
        deleteInput,
        id,
        toggleError,
        handleInputName,
        index,
        handleInputVariableName,
        handleContactInputs,
        type,
        dataWithVariables,
    } = props;
    const [inputCollapse, setInputCollapse] = useState(false);
    const [isDisableDaysOpen, setIsDisableDaysOpen] = useState(false);
    const [isInputOther, setIsInputOther] = useState(false);
    const [daysDisabled, setDaysDisabled] = useState([]);

    useEffect(() => {
        if (step.daysDisabled) {
            setDaysDisabled(JSON.parse(step.daysDisabled));
        }
    }, [step.daysDisabled]);

    const handleDisableDays = (value) => {
        let tempDaysDisabled = [...daysDisabled];
        if (!tempDaysDisabled || !Array.isArray(tempDaysDisabled) || tempDaysDisabled.length === 0) {
            tempDaysDisabled = [value];
        } else if (tempDaysDisabled.includes(value)) {
            tempDaysDisabled = tempDaysDisabled.filter(item => item !== value);
        } else {
            tempDaysDisabled.push(value);
        }
        handleContactInputs({ target: { name: "daysDisabled", value: JSON.stringify(tempDaysDisabled) } }, step.id, id);
        setDaysDisabled(tempDaysDisabled);

    }



    return (
        <>
            <div className="feildHeader">
                <span
                    className="pointer"
                    onClick={() => setInputCollapse(!inputCollapse)}
                >
                    {handleInputName(step, index)}
                </span>
                <IoTrashOutline
                    type="button"
                    title="rightBar-customize-override"
                    onClick={() => deleteInput(id, step.id)}
                    color={"rgba(0, 68, 179, 0.42)"}
                    size={22}
                />
                {/*<a*/}
                {/*  title=""*/}
                {/*  className="bin"*/}
                {/*  onClick={() => deleteInput(id, step.id)}*/}
                {/*></a>*/}
            </div>
            <Collapse in={inputCollapse}>
                <div className="fieldOptions" id={` item${index}`}>
                    <h4>Label:</h4>
                    {/* <input
                        type="text"
                        name="label"
                        value={step.label}
                        placeholder="Name:"
                        onBlur={(e) => handleInputVariableName(e, step.id, id)}
                        onChange={(e) => props.handleContactInputs(e, step.id, id)}
                    /> */}

                    <AcTest
                        changeOnSelect={(trigger, slug) => "{{" + slug + "}}"}
                        trigger={["{{"]}
                        options={{ "{{": [...dataWithVariables] }}
                        type="text"
                        name="label"
                        value={step.label}
                        placeholder="Name:"
                        className="autocomplete-form-input"
                        rows={1}
                        maxOptions={50}
                        onBlur={(e) => handleInputVariableName({ target: { name: "label", value: e } }, step.id, id)}
                        onChange={(e) => props.handleContactInputs({ target: { name: "label", value: e } }, step.id, id)}
                    />
                    <h4>Default Value:</h4>
                    {/* <input
                        type="text"
                        placeholder=""
                        name="placeholder"
                        value={step.placeholder}
                        onChange={(e) => handleContactInputs(e, step.id, id)}
                    /> */}
                    <DateTimePicker
                        onChange={(e) => {

                            handleContactInputs({ target: { name: "placeholder", value: e } }, step.id, id)

                        }}
                        maxDate={step.maxDate ? new Date(step.maxDate) : null}
                        minDate={step.minDate ? new Date(step.minDate) : null}
                        value={step.placeholder} disableClock={true} />


                    <label>
                        <input
                            type="checkbox"
                            name="required"
                            checked={step.required}
                            onChange={(e) => handleContactInputs(e, step.id, id)}
                        />{" "}
                        Required
                    </label>

                    <label>
                        <input
                            type="checkbox"
                            name="isTime"
                            checked={step.isTime}
                            onChange={(e) => handleContactInputs(e, step.id, id)}
                        />{" "}
                        Date and Time
                    </label>

                    <label>
                        <input
                            type="checkbox"
                            name="hideLabel"
                            checked={step.hideLabel}
                            onChange={(e) => handleContactInputs(e, step.id, id)}
                        />{" "}
                        Hide Label
                    </label>

                    <label>
                        <input
                            type="checkbox"
                            name="hideCalender"
                            checked={step.hideCalender}
                            onChange={(e) => handleContactInputs(e, step.id, id)}
                        />{" "}
                        Hide Calender
                    </label>


                    <h4>Format:</h4>
                    <select
                        value={step.format || ""}
                        onChange={(e) => {
                            handleContactInputs(e, step.id, id);
                        }}
                        name="format"
                    >
                        <option value="">Select</option>
                        {!step.isTime ? (<>
                            <option value="dd/MM/yyyy">DD/MM/YYYY</option>
                            <option value="MM/dd/yyyy">MM/DD/YYYY</option>
                            <option value="yyyy/MM/dd">YYYY/MM/DD</option>
                        </>) : (<>
                            <option value="dd/MM/yyyy HH:mm">DD/MM/YYYY HH/MM</option>
                            <option value="dd/MM/yyyy hh:mm a">DD/MM/YYYY H/MM A</option>
                            <option value="MM/dd/yyyy HH:mm">MM/DD/YYYY HH/MM</option>
                            <option value="dd/MM/yyyy hh:mm a">DD/MM/YYYY H/MM A</option>
                            <option value="yyyy/MM/dd HH:mm">YYYY/MM/DD HH/MM</option>
                            <option value="dd/MM/yyyy hh:mm a">DD/MM/YYYY H/MM A</option>
                        </>)}
                    </select>


                    <h4>Calender Type:</h4>
                    <select
                        value={step.calenderType || ""}
                        onChange={(e) => {
                            handleContactInputs(e, step.id, id);
                        }}
                        name="calenderType"
                    >
                        <option value="">Auto</option>

                        <option value="US"> Week starts Sunday weedend ends Monday</option>
                        <option value="Hebrew">Week starts Sunday weedend ends Sunday</option>
                        <option value="ISO 8601">Week starts Monday</option>
                        <option value="Arabic">Week starts Saturday weekend ends sunday </option>

                    </select>
                    {!step.hideCalender ? (<>

                        <h4>Min Date:</h4>
                        <DatePicker
                            onChange={(e) => {

                                handleContactInputs({ target: { name: "minDate", value: e } }, step.id, id)
                            }}
                            value={step.minDate ? new Date(step.minDate) : null}
                            maxDate={step.maxDate ? new Date(step.maxDate) : null} />
                        <h4>Max Date:</h4>

                        <DatePicker
                            onChange={(e) => {

                                handleContactInputs({ target: { name: "maxDate", value: e } }, step.id, id)
                            }}
                            value={step.maxDate ? new Date(step.maxDate) : null}
                            minDate={step.minDate ? new Date(step.minDate) : null} />
                        <div className="feildHeader" style={{ backgroundColor: "transparent", color: "black" }}>
                            <span
                                className="pointer"
                                onClick={() => setIsDisableDaysOpen(!isDisableDaysOpen)}
                            >
                                {isDisableDaysOpen ? "Disable Days" : "Disable Days ^"}
                            </span>
                        </div>
                        <Collapse in={isDisableDaysOpen}>
                            <div>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={daysDisabled && Array.isArray(daysDisabled) && daysDisabled.includes(0)}
                                        onChange={(e) => handleDisableDays(0)}
                                    />{" "}
                                    Sunday
                                </label>

                                <label>
                                    <input
                                        type="checkbox"
                                        checked={daysDisabled && Array.isArray(daysDisabled) && daysDisabled.includes(1)}
                                        onChange={(e) => handleDisableDays(1)}
                                    />{" "}
                                    Monday
                                </label>

                                <label>
                                    <input
                                        type="checkbox"
                                        checked={daysDisabled && Array.isArray(daysDisabled) && daysDisabled.includes(2)}
                                        onChange={(e) => handleDisableDays(2)}
                                    />{" "}
                                    Tuesday
                                </label>

                                <label>
                                    <input
                                        type="checkbox"
                                        checked={daysDisabled && Array.isArray(daysDisabled) && daysDisabled.includes(3)}
                                        onChange={(e) => handleDisableDays(3)}
                                    />{" "}
                                    Wednesday
                                </label>

                                <label>
                                    <input
                                        type="checkbox"
                                        checked={daysDisabled && Array.isArray(daysDisabled) && daysDisabled.includes(4)}
                                        onChange={(e) => handleDisableDays(4)}
                                    />{" "}
                                    Thursday
                                </label>

                                <label>
                                    <input
                                        type="checkbox"
                                        checked={daysDisabled && Array.isArray(daysDisabled) && daysDisabled.includes(5)}
                                        onChange={(e) => handleDisableDays(5)}
                                    />{" "}
                                    Friday
                                </label>

                                <label>
                                    <input
                                        type="checkbox"
                                        checked={daysDisabled && Array.isArray(daysDisabled) && daysDisabled.includes(6)}
                                        onChange={(e) => handleDisableDays(6)}
                                    />{" "}
                                    Saturday
                                </label>
                            </div >
                        </Collapse>
                    </>) : (<></>)}





                    {toggleError(step)}
                </div>



            </Collapse>
        </>
    );
}

export default AddContactDateField;
