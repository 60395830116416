import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Bar, Doughnut } from "react-chartjs-2";
import moment from "moment";

import { Container, Row, Col } from "react-bootstrap";
import { Chart, ArcElement, CategoryScale, registerables } from "chart.js";
import LineChart from "./LineChartStats";
Chart.register(ArcElement);
Chart.register(CategoryScale);
Chart.register(...registerables);

function AnalyzaQuestions(props) {
  const {
    filterObj,
    additionalFilters,
    getQuizAnalyzeQuestions,
    quizAnalyzeQuestions,
    quizId,
    arrayOfData,
  } = props;

  const [answersData, setAnswersData] = useState([]);
  const [metaInfo, setMetaInfo] = useState([]);
  const [arrayOfQuestions, setArrayOfQuestions] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);

  useEffect(() => {
    const tempQuestionArray = arrayOfData
      .filter((item) => item.type === "question")
      .map((item) => {
        return {
          questionType: item.questionType,
          title: item.title.replace(/(<([^>]+)>)/gi, ""),
        };
      });
    setArrayOfQuestions(tempQuestionArray);
  }, [arrayOfData]);

  useEffect(() => {
    const fromDate = moment().format("YYYY-MM-DD");
    let toDate = moment().subtract(30, "days");
    toDate = moment(toDate).format("YYYY-MM-DD");
    getQuizAnalyzeQuestions({
      id: quizId,
      fromDate,
      toDate,
      filters: filterObj,
      additionalFilters,
    });
  }, [filterObj, additionalFilters]);

  useEffect(() => {
    if (quizAnalyzeQuestions && quizAnalyzeQuestions.answers) {
      setAnswersData(quizAnalyzeQuestions.answers);
      const onlyQuestionsTitles = arrayOfData
        .filter((step) => step.type === "question")
        .map((question) => question.title.replace(/(<([^>]+)>)/gi, ""));

      const allquestionDataForlineChart =
        quizAnalyzeQuestions.answerDistribution
          .filter((item) => onlyQuestionsTitles.includes(item.question_title))
          .sort((a, b) => {
            const indexA = onlyQuestionsTitles.indexOf(a.question_title);
            const indexB = onlyQuestionsTitles.indexOf(b.question_title);
            return indexA - indexB;
          })
          .map((question) => {
            const { question_title, answers } = question;
            const sum = answers;
            return { question_title, sum };
          });
      setLineChartData(allquestionDataForlineChart);
    }
    if (quizAnalyzeQuestions && quizAnalyzeQuestions.metadata) {
      setMetaInfo(quizAnalyzeQuestions.metadata);
    }
  }, [quizAnalyzeQuestions]);

  const pieOptions = {
    autoPadding: false,
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 0,
      },
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        // display: false,
        position: "right",
        // borderRadius: "10px",
        labels: {
          boxWidth: 10,
          borderRadius: 10,
          items: {
            borderRadius: 10,
          },
        },
      },
    },
  };

  const BarOptions = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          stepSize: 1,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  return (
    <div className="tab-content-inner">
      {" "}
      <LineChart
        quizAnalyzeLineChart={lineChartData}
        nameOfXKey="question_title"
        nameOfYKey={["sum"]}
        label="Answers Distribution"
      />
      <br />
      {answersData.map((answer, index) => {
        const answerLabels = Object.keys(answer.answers);
        const answerData = Object.values(answer.answers);
        const selectedStep = arrayOfData.find(
          (el) => el.title.replace(/(<([^>]+)>)/gi, "") == answer.question_title
        );
        let questionType = "";
        if (selectedStep) {
          if (selectedStep.questionType) {
            questionType = ` -  ${selectedStep.questionType}`;
          }
          if (
            selectedStep.questionType == "singleSelect" ||
            selectedStep.questionType == "multiSelect"
          ) {
            const otherOption = selectedStep.options.find(
              (option) => option.isOtherOption
            );
            if (otherOption) {
              if (answerLabels[answerLabels.length - 1] == "Deleted Other") {
                answerLabels[answerLabels.length - 1] =
                  otherOption.title.replace(/(<([^>]+)>)/gi, "");
              }
            }
          }
        }
        const colorArray = ["#0046B5", "#03A6CA", "#BE1EB9", "#e8eef1"];
        const pieData = {
          labels: answerLabels,
          datasets: [
            {
              data: answerData,
              backgroundColor: colorArray,
              hoverBackgroundColor: colorArray,
            },
          ],
        };

        const barData = {
          labels: answerLabels,

          datasets: [
            {
              label: "",
              data: answerData,
              backgroundColor: colorArray[0],
            },
          ],
        };
        return (
          <Container
            key={`graph-${answer.question_title.replaceAll(" ", "_")}`}
          >
            <h2 style={{ textAlign: "center" }}>
              {answer.question_title}
              <span className="fw-lighter fs-5">{questionType}</span>
            </h2>
            <Row>
              <Col>
                <div className="analyze-question-chart-wrapper">
                  <div style={{ width: "auto", height: "250px" }}>
                    <Doughnut data={pieData} options={pieOptions} />
                  </div>
                </div>
              </Col>
              <Col>
                <div className="analyze-question-chart-wrapper">
                  <div style={{ width: "auto", height: "400px" }}>
                    <Bar data={barData} options={BarOptions} />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        );
      })}
      {metaInfo.map((meta, index) => {
        return (
          <Container
            key={`metadata-${meta.metadata_title.replaceAll(" ", "_")}`}
          >
            <h2 style={{ textAlign: "center" }}>{meta.metadata_title}</h2>
            <div className="w-75 m-auto">
              <table className="table  table-striped">
                <thead className="thead-light">
                  <tr>
                    <th scope="col" className="text-center">
                      #
                    </th>
                    <th scope="col" className="text-center">
                      Last Answers
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {meta.metaArray.map((item, i) => {
                    return (
                      <tr
                        key={`graph-${
                          typeof item === "text"
                            ? item.replaceAll(" ", "_")
                            : i + "-index"
                        }`}
                      >
                        <th scope="row" className="text-center">
                          {i + 1}
                        </th>
                        <td className="text-center">
                          {item &&
                          item.endsWith(".png") &&
                          item.includes(
                            "https://smart-hippo.s3.eu-central-1.amazonaws.com/knowys/signatures/"
                          ) ? (
                            <>
                              {" "}
                              <img
                                style={{ maxWidth: "200px" }}
                                src={item}
                                alt="signature"
                              />
                            </>
                          ) : (
                            item
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Container>
        );
      })}
    </div>
  );
}

export default withTranslation()(AnalyzaQuestions);
