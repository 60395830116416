import React from "react";
import Fonts from "../../../utils/Fonts/Fonts";
import ColorPicker from "../ColorPicker";
import SelectSize from "../SelectSize";
import { withTranslation } from "react-i18next";

import { useMediaQuery } from "react-responsive";

function AlertStyle({
  selectedTheme,
  context,
  handleChange,
  props,
  handleColorsToChange,
  fromTheme,
}) {
  const { settings, fonts, t, id } = props;
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  if (!selectedTheme) return <></>;
  let styleObject = selectedTheme[context];
  if (!styleObject && props.style && props.style[context]) {
    styleObject = props.style[context];
  } else if (!styleObject) {
    styleObject = {
      color: "#fff",
      fontSize: "14",
      fontFamily: "Open Sans",
      textAlign: "center",
      width: "auto",
      mobileWidth: "auto",
      tabletWidth: "auto",
      correct: {
        text: "Correct!",
        color: "#fff",
        background: "rgba(0,0,0,0.5)",

        borderColor: "black",
        borderSize: "0",
      },
      incorrect: {
        text: "Incorrect",
        background: "rgba(0,0,0,0.5)",
        color: "#fff",
        borderColor: "black",
        borderSize: "0",
        alertLength: 2000,
      },
    };
  }
  return (
    <div>
      <div className="fontStyle">
        <h3>{t("theme-accordion-font-style")} </h3>
        <Fonts
          templatesFonts={fonts}
          selected={
            styleObject.fontFamily.replace(/\+/g, " ")
              ? styleObject.fontFamily.replace(/\+/g, " ")
              : settings.subtitle.fontFamily.replace(/\+/g, " ") || "open sans"
          }
          saveFont={(font) => {
            props.changeMainStyle(
              { target: { name: "fontFamily", value: font } },
              context
            );
            handleChange(
              { target: { name: "fontFamily", value: font } },
              context
            );
          }}
        />

        <SelectSize
          name="fontSize"
          fromTheme={fromTheme}
          label={t("general-font-size")}
          value={{
            desktop: styleObject.fontSize,
            mobile: styleObject.mobileFontSize || styleObject.fontSize,
            tablet: styleObject.tabletFontSize || styleObject.fontSize,
          }}
          handleChange={(size, name = "fontSize") => {
            props.changeMainStyle({ target: { name, value: size } }, context);
            handleChange({ target: { name, value: size } }, context);
          }}
          changedDetails={{ toChange: context }}
        />

        <SelectSize
          name="width"
          label="Width"
          fromTheme={fromTheme}
          value={{
            desktop: styleObject.width,
            mobile: styleObject.mobileWidth || styleObject.width,
            tablet: styleObject.tabletWidth || styleObject.width,
          }}
          handleChange={(size, name = "Width") => {
            props.changeMainStyle({ target: { name, value: size } }, context);
            handleChange({ target: { name, value: size } }, context);
          }}
          changedDetails={{ toChange: context }}
        />
      </div>

      <div className="rightBar-theme-alert-bg">
        <h3>{t("rightBar-theme-alert-correct")} </h3>
        <div className="answerColor">
          <input
            type="text"
            name="text"
            value={styleObject.correct.text}
            onChange={(e) => {
              props.changeMainStyle(e, `${context}.correct`);
              handleChange(e, `${context}.correct`);
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            columnGap: "16px",
            margin: "0px 0 10px 0",
            justifyContent: "space-between",
          }}
        >
          <div
            className="fontStyle"
            style={{ width: "20%", placeContent: "center", margin: "auto" }}
          >
            <h3>{t("theme-accordion-color")}</h3>
            <ColorPicker
              isButtonStyle={true}
              setRight="50px"
              color={styleObject.correct.color}
              handleChange={(e) => {
                handleColorsToChange(e, "color", `${context}.correct`);
              }}
            />
          </div>

          <div
            className="fontStyle"
            style={{ width: "30%", placeContent: "center" }}
          >
            <h3>{t("theme-accordion-background-color")}</h3>

            <ColorPicker
              isButtonStyle={true}
              setRight="0px"
              color={styleObject.correct.background}
              handleChange={(e) => {
                handleColorsToChange(e, "background", `${context}.correct`);
              }}
            />
          </div>

          <div
            className="space line"
            style={{ width: "30%", placeContent: "center", margin: "autp" }}
          >
            <h3>{t("theme-accordion-border-size")} </h3>
            <label style={{ width: "80%" }}>
              <input
                type="number"
                name="borderSize"
                value={
                  styleObject.correct.borderSize
                    ? styleObject.correct.borderSize
                    : "0"
                }
                onChange={(e) => {
                  props.changeMainStyle(e, `${context}.correct`);
                  handleChange(e, `${context}.correct`);
                }}
                border="1"
              />
              px
            </label>
          </div>
        </div>
      </div>

      <div className="rightBar-theme-alert-bg">
        <h3>{t("rightBar-theme-alert-incorrect")} </h3>
        <div className="answerColor">
          <input
            type="text"
            name="text"
            value={styleObject.incorrect.text}
            onChange={(e) => {
              props.changeMainStyle(e, `${context}.incorrect`);
              handleChange(e, `${context}.incorrect`);
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            columnGap: "16px",
            margin: "0px 0 10px 0",
            justifyContent: "space-between",
          }}
        >
          <div
            className="fontStyle"
            style={{ width: "20%", placeContent: "center", margin: "autp" }}
          >
            <h3>{t("theme-accordion-color")}</h3>
            <ColorPicker
              setRight="50px"
              isButtonStyle={true}
              color={styleObject.incorrect.color}
              handleChange={(e) => {
                handleColorsToChange(e, "color", `${context}.incorrect`);
              }}
            />
          </div>

          <div
            className="fontStyle"
            style={{ width: "30%", placeContent: "center", margin: "autp" }}
          >
            <h3>{t("theme-accordion-background-color")}</h3>

            <ColorPicker
              isButtonStyle={true}
              setRight="0px"
              color={styleObject.incorrect.background}
              handleChange={(e) => {
                handleColorsToChange(e, "background", `${context}.incorrect`);
              }}
            />
          </div>

          <div
            className="space line"
            style={{ width: "30%", placeContent: "center", margin: "autp" }}
          >
            <h3>{t("theme-accordion-border-size")} </h3>

            <label style={{ width: "80%" }}>
              <input
                type="number"
                name="borderSize"
                value={
                  styleObject.incorrect.borderSize
                    ? styleObject.incorrect.borderSize
                    : "0"
                }
                onChange={(e) => {
                  props.changeMainStyle(e, `${context}.incorrect`);
                  handleChange(e, `${context}.incorrect`);
                }}
                border="1"
              />
              px
            </label>
          </div>
        </div>
      </div>

      <div className="fontStyle">
        <SelectSize
          label={t("rightBar-theme-alert-duration") + " (sec)"}
          name="alertLength"
          fromTheme={fromTheme}
          value={{
            desktop: styleObject.alertLength / 1000,
            mobile:
              styleObject.mobileAlertLength / 1000 ||
              styleObject.alertLength / 1000,
            tablet:
              styleObject.tabletAlertLength / 1000 ||
              styleObject.alertLength / 1000,
          }}
          isMilisecons={true}
          handleChange={(size, name = "alertLength") => {
            selectedTheme == "override-edit"
              ? (function () {
                  handleChange(
                    { target: { name, value: size * 1000 } },
                    id,
                    context
                  );
                })()
              : (function () {
                  props.changeMainStyle(
                    { target: { name, value: size * 1000 } },
                    context
                  );
                  handleChange(
                    { target: { name, value: size * 1000 } },
                    context
                  );
                })();
          }}
          changedDetails={{ toChange: context }}
        />
      </div>
    </div>
  );
}

export default withTranslation()(AlertStyle);
