import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import {
  MdComputer,
  MdTabletMac,
  MdSmartphone,
} from "react-icons/md";

function PlatformSelect(props) {
  const { mode, setMode } = props;
  const platformOptions = [
    {
      value: "desktop",
      label: <MdComputer size={20}  />,
    },
    {
      value: "tablet",
      label: <MdTabletMac size={20}  />,
    },
    {
      value: "mobile",
      label: <MdSmartphone size={20}  />,
    },
  ];
  useEffect(() => {
    setMode(platformOptions[0]);
  }, []);

  return (
    <ReactSelect
      placeholder=""
      defaultValue={platformOptions[0]}
      value={mode}
      style={{width:"44px"}}
      className={props.showPlatformSelect ? "platform-selection" : ""}
      options={platformOptions}
      onChange={(newMode) => setMode(newMode)}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
    />
  );
}

export default PlatformSelect;
