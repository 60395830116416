import React from "react";
import { connect } from "react-redux";
import { login, setGtmStatusAction } from "../../redux/actions";
import "./login.css";
import { withTranslation } from "react-i18next";
import validator from "validator";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import LoginForm from "./login";
import Register from "./../Register";
import TextTransition, { presets } from "react-text-transition";


class Login extends React.Component {
  state = {
    textFastIndex: 0,
    email: "",
    password: "",
    valid: {
      email: true,
      password: true,
    },
  };
  componentDidMount() {
    // document.title = "Login";
    this.props.actions.setGtmStatus({ page: "login", events: null });

    setInterval(() => {
      this.setState({
        textFastIndex: this.state.textFastIndex + 1
      });
    }, 3500);
  }

  render() {
    /*     const { email, password, valid } = this.state;
     */
    const { t, defaultTab } = this.props;
    // this.checkIfLoginSuccess();
    // console.log(defaultTab)
    const TEXTS = [
      "Forms",
      "Surveys",
      "Quiz"
    ];

    return (
      <main className="register">
        <header>
          <a href="https://knowyyy.com/">
            <img
              src="img/logo.png"
              alt="KNOWY - KNOW YOUR CLIENT"
              style={{ height: "40px" }}
            />
          </a>
        </header>
        <section style={{ height: "94vh" }}>
          <div className="container-fluid" style={{ height: "100%" }}>
            <div className="row" style={{ height: "100%" }}>
              <div className="col-sm-4 text-black registerContent">
                {/*<div className="px-5 ms-xl-4">*/}
                {/*  <i className="fas fa-crow fa-2x me-3 pt-5 mt-xl-4" style={{color: "#709085"}}></i>*/}
                {/*  <span className="h1 fw-bold mb-0">Logo</span>*/}
                {/*</div>*/}

                <div
                  className="d-flex align-items-center h-custom-2 px-5 ms-xl-4 mt-5 pt-5 pt-xl-0 mt-xl-n5"
                  style={{ width: "100%" }}
                >
                  <div style={{ width: "80%", margin: "auto" }}>
                    {/*<Tabs*/}
                    {/*  defaultActiveKey={defaultTab || "login"}*/}
                    {/*  id="uncontrolled-tab-example"*/}
                    {/*  className="tab"*/}
                    {/*>*/}
                    {/*  <Tab eventKey="login" title={t("login-tab")}>*/}
                    {
                      (defaultTab == "login" || !defaultTab) ?
                        <LoginForm history={this.props.history} />
                        : <></>}
                    {/*  </Tab>*/}
                    {/*  <Tab eventKey="register" title="Register">*/}
                    {(defaultTab == "register") ?
                      <Register history={this.props.history} />
                      : <></>}
                    {/*  </Tab>*/}
                    {/*</Tabs>*/}
                  </div>
                </div>
              </div>
              <div className="col-sm-8 px-0 d-none d-sm-block registerContentImg">
                {/*<TextTransition springConfig={presets.wobbly}>*/}
                {/*  {TEXTS[index % TEXTS.length}*/}
                {/*</TextTransition>*/}
                <div style={{ position: "absolute", fontSize: "45px", width: "60%", fontWeight: "300", textAlign: "center" }}>
                  Build {" "}
                  <TextTransition style={{ color: "red" }}
                    children={TEXTS[this.state.textFastIndex % TEXTS.length]}
                    springConfig={presets.gentle}
                    className="big"
                    delay={300}
                    // springConfig={{ stiffness: 50, damping: 20 }}
                    inline
                  />, integrate easy and analyze all by yourself
                </div>
                <img
                  src="/img/loginbg3.png"
                  alt="Login image"
                  className="w-100"
                  style={{
                    height: "94vh",
                    objectFit: "cover",
                    objectPosition: "right",
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}
const mapStateToProps = (state) => {
  const { message } = state.loginDetails;
  return { message };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      login: (userDetails) => dispatch(login(userDetails)),
      setGtmStatus: (status) => dispatch(setGtmStatusAction(status)),
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Login)
);
