import React, { useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import Fonts from "../../../../../../utils/Fonts/Fonts";
import { withTranslation } from "react-i18next";
import SelectSize from "../../../../SelectSize";
import ColorPicker from "../../../../ColorPicker";

function MatrixStyle(props) {
  const {
    data,
    matrixData = {},
    id,
    handleChangeMatrixData,
    styleObject = {},
    stepData,
    handleChange,
    handleChangeQuestion,
    t,
  } = props;
  if (!stepData.style.matrix)
    stepData.style.matrix = {
      rowColor: "#000",
      columnColor: "#000",
      rowFontSize: "28",
      mobileRowFontSize: "28",
      tabletRowFontSize: "28",
      columnFontSize: "28",
      mobileColumnFontSize: "28",
      tabletColumnFontSize: "28",
      fontFamily: "",
      borderSize: "0",
      borderColor: "#000",
    };
  const { matrix } = stepData.style;
  const { type, fonts } = data;
  const { columns = [] } = matrixData;
  const [isScoreOpen, setIsScoreOpen] = useState(false);
  const drawExtraDataByQuizType = () => {
    switch (type) {
      case "scored":
        return columns.map((column, index) => {
          const { value, score } = column;
          return (
            <>
              <h2>{value.replace(/(<([^>]+)>)/gi, "")}</h2>
              <div className="form-group">
                <label>Score:</label>
                <input
                  type="number"
                  name="score"
                  className="form-control"
                  onChange={(e) =>
                    handleChangeMatrixData(
                      {
                        target: {
                          name: "score",
                          value: parseInt(e.target.value),
                        },
                      },
                      id,
                      index,
                      "columns"
                    )
                  }
                  value={score}
                />
              </div>
            </>
          );
        });
      case "personality":
        return <></>;
      case "assessment":
        return <></>;
    }
  };

  const handleColorsToChange = (e, name) => {
    const { r, g, b, a } = e.rgb;
    const value = `rgba(${r}, ${g}, ${b}, ${a})`;
    handleChange({ target: { name, value } }, id, "matrix");
  };

  return (
    <div className="matrix-extra-data">
      <div className="fontStyle">
        <h3>{t("theme-accordion-font-style")} </h3>
        <div className="selectFont">
          <Fonts
            templatesFonts={fonts}
            selected={matrix.fontFamily.replace(/\+/g, " ")}
            saveFont={(font) => {
              (function () {
                handleChange(
                  { target: { name: "fontFamily", value: font } },
                  id,
                  "matrix"
                );
              })();
            }}
          />
        </div>
      </div>
      <div className="fontStyle">
        <SelectSize
          fromTheme={true}
          name="rowFontSize"
          label={t("general-font-size-row")}
          value={{
            desktop: matrix.rowFontSize,
            mobile: matrix.mobileRowFontSize || matrix.rowFontSize,
            tablet: matrix.tabletRowFontSize || matrix.rowFontSize,
          }}
          handleChange={(size, name = "rowFontSize") => {
            (function () {
              handleChange({ target: { name, value: size } }, id, "matrix");
            })();
          }}
          changedDetails={{ toChange: "matrix" }}
        />
      </div>
      <div className="fontStyle">
        <SelectSize
          fromTheme={true}
          name="columnFontSize"
          label={t("general-font-size-column")}
          value={{
            desktop: matrix.columnFontSize,
            mobile: matrix.mobileColumnFontSize || matrix.columnFontSize,
            tablet: matrix.tabletColumnFontSize || matrix.columnFontSize,
          }}
          handleChange={(size, name = "columnFontSize") => {
            (function () {
              handleChange({ target: { name, value: size } }, id, "matrix");
            })();
          }}
          changedDetails={{ toChange: "matrix" }}
        />
      </div>
      <div className="row" style={{ alignItems: "center" }}>
        <div className="space line col-5">
          <h3>{t("theme-accordion-border-size")} </h3>
          <label style={{ width: "100%" }}>
            <input
              type="number"
              name="borderSize"
              defaultValue={matrix.borderSize ? matrix.borderSize : "0"}
              onChange={(e) => {
                handleChange(
                  { target: { name: "borderSize", value: e.target.value } },
                  id,
                  "matrix"
                );
              }}
              border="1"
            />
            px
          </label>
        </div>
        <div className="buttonColor col-5">
          <ColorPicker
            isButtonStyle={false}
            setRight="0px"
            color={matrix.borderColor}
            name="borderColor"
            handleChange={(e) => handleColorsToChange(e, "borderColor")}
          />
        </div>
      </div>
      <div className="row">
        <div className="buttonColor col-5">
          <h3>{t("general-row")}</h3>
          <ColorPicker
            isButtonStyle={false}
            setRight="0px"
            color={matrix.rowColor}
            name="rowColor"
            handleChange={(e) => handleColorsToChange(e, "rowColor")}
          />
        </div>
        <div className="buttonColor col-5">
          <h3>{t("general-column")}</h3>
          <ColorPicker
            isButtonStyle={false}
            setRight="0px"
            color={matrix.columnColor}
            name="columnColor"
            handleChange={(e) => handleColorsToChange(e, "columnColor")}
          />
        </div>
      </div>
      <br />
      <div className="fieldList">
        <label>
          <input
            type="checkbox"
            name="required"
            checked={stepData.required}
            onChange={(e) =>
              handleChangeQuestion(
                { target: { value: e.target.checked, name: "required" } },
                id
              )
            }
          />{" "}
          Required
        </label>
      </div>
      {stepData.required ? (
        <div>
          <h3>Error Message:</h3>
          <input
            type="text"
            name="errorMsg"
            value={stepData.errorMsg}
            onChange={(e) => handleChangeQuestion(e, id)}
            className="form-control"
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default withTranslation()(MatrixStyle);
