export default {
  REGISTER: "REGISTER",
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  INIT: "INIT",
  SEARCH_FOR_IMAGE: "SEARCH_FOR_IMAGE",
  RESET_IMAGES: "RESET_IMAGES",
  CHANGE_SITE_WIDTH: "CHANGE_SITE_WIDTH",
  EDIT_QUIZ: "EDIT_QUIZ",
  GET_TEMPLATES: "GET_TEMPLATES",
  GET_TEMPLATE_BY_ID: "GET_TEMPLATE_BY_ID",
  GET_USER_QUIZES: "GET_USER_QUIZES",
  GET_QUIZ_DETAILS_BY_QUIZ_ID: "GET_QUIZ_DETAILS_BY_QUIZ_ID",
  DELETE_QUIZ: "DELETE_QUIZ",
  GET_QUIZ_DETAILS_BY_USER_ID_AND_SLUG: "GET_QUIZ_DETAILS_BY_USER_ID_AND_SLUG",
  SAVE_ANSWER: "SAVE_ANSWER",
  GET_QUIZ_ANALYZE: "GET_QUIZ_ANALYZE",
  GET_QUIZ_ANALYZE_STATS: "GET_QUIZ_ANALYZE_STATS",
  GET_QUIZ_ANALYZE_LINE_CHART: "GET_QUIZ_ANALYZE_LINE_CHART",
  GET_QUIZ_ANALYZE_GRAPH: "GET_QUIZ_ANALYZE_GRAPH",
  GET_QUIZ_ANALYZE_TABLE: "GET_QUIZ_ANALYZE_TABLE",
  GET_QUIZ_ANALYZE_HEADERS: "GET_QUIZ_ANALYZE_HEADERS",
  GET_QUIZ_ANALYZE_QUESTIONS: "GET_QUIZ_ANALYZE_QUESTIONS",
  GET_FOLDERS: "GET_FOLDERS",
  CREATE_FOLDER: "CREATE_FOLDER",
  SAVE_CUSTOM_STYLE: "SAVE_CUSTOM_STYLE",
  GET_THEMES: "GET_THEMES",
  CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
  SET_EDITOR_TOOLBAR_REF: "SET_EDITOR_TOOLBAR_REF",
  GET_API_KEYS_SUCCESS: "GET_API_KEYS_SUCCESS",
  SET_ERROR: "SET_ERROR",
  CHANGE_RIGHT_BAR_VIEW: "CHANGE_RIGHT_BAR_VIEW",
  SAVE_SCRIPTS_STYLES_SUCCESS: "SAVE_SCRIPTS_STYLES_SUCCESS",
  SAVE_EMBEDD_OBJECT: "SAVE_EMBEDD_OBJECT",
  SAVE_THEME: "SAVE_THEME",
  GET_USER_INFO: "GET_USER_INFO",
  UPDATE_USER_INFO: "UPDATE_USER_INFO",
  PASSWORD_UPDATE: "PASSWORD_UPDATE",
  LOGIC_STEP_UPDATE: "LOGIC_STEP_UPDATE",
  PRODUCTS_LOGIC_UPDATE: "PRODUCTS_LOGIC_UPDATE",
  LOGIC_RESULT_UPDATE: "LOGIC_RESULT_UPDATE",
  ACCESSIBILITY: "ACCESSIBILITY",
  ACCESSIBILITY_CONFIGURATION: "ACCESSIBILITY_CONFIGURATION",

  GET_INTEGRATION_APPS: "GET_INTEGRATION_APPS",
  GET_INTEGRATION_MODULES: "GET_INTEGRATION_MODULES",
  GET_INTEGRATION_CONNECTION_MODULES: "GET_INTEGRATION_CONNECTION_MODULES",
  GET_MDULES_CONNECTIONS_OPTIONS: "GET_MDULES_CONNECTIONS_OPTIONS",
  GET_INTEGRATION_TRIGGERS: "GET_INTEGRATION_TRIGGERS",
  GET_INTEGRATION_TRIGGERS_ACTIVE: "GET_INTEGRATION_TRIGGERS_ACTIVE",
  GET_IS_USER_INTEGRATED: "GET_IS_USER_INTEGRATED",
  GET_MDULES_FIELDS_OPTIONS: "GET_MDULES_FIELDS_OPTIONS",
  GET_MDULES_CONNECTIONS_MORE_OPTIONS: "GET_MDULES_CONNECTIONS_MORE_OPTIONS",
  GET_PACKGES: "GET_PACKGES",
  SET_SESSION_URL: "SET_SESSION_URL",
  SET_PAYMENT_DETAILS: "SET_PAYMENT_DETAILS",
  SET_CONNECT_EDIT_OBJECT: "SET_CONNECT_EDIT_OBJECT",
  GET_UNPUBLISH_CONDITION: "GET_UNPUBLISH_CONDITION",
  SET_GTM_STATUS: "SET_GTM_STATUS",
  SET_MEDIA_QUERY: "SET_MEDIA_QUERY",
  SET_IS_MOBILE_PREVIEW: "SET_IS_MOBILE_PREVIEW",
  TOGGLE_PREVIEW_DESIGN_MODE: "TOGGLE_PREVIEW_DESIGN_MODE",
  SAVE_VISIT_KEY: "SAVE_VISIT_KEY",
  CHANGE_PERMITIONS: "CHANGE_PERMITIONS",
  GET_ORGS_USERS: "GET_ORGS_USERS",
  GET_ORGS_FEEDS: "GET_ORGS_FEEDS",
  GET_WORKSPACES: "GET_WORKSPACES",
  GET_WORKSPACE_QUIZES: "GET_WORKSPACE_QUIZES",

  PRODUCTS_LOGIC_UPDATE: "PRODUCTS_LOGIC_UPDATE",

  CHANGE_QUILL_TOOLBAR: "CHANGE_QUILL_TOOLBAR",

};
