import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Dropdown } from "react-bootstrap";
import { withTranslation } from "react-i18next";
function ConfirmDelete(props) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const { id, deleteFromArray, t } = props;

  const handleClick = () => {
    deleteFromArray(id);
  };
  return (
    <>
      <Dropdown.Item onClick={() => setIsOpen(true)}>
        <i
          className="bi bi-trash left-bar-dropdown-item-icon"
          style={{ display: "inline-block" }}
        ></i>
        {t("general-delete")}
      </Dropdown.Item>

      <Modal show={modalIsOpen} onHide={() => setIsOpen(false)} centered>
        <h2>{t("leftBar-delete-slide")} </h2>
        <h3>{t("leftBar-delete-slide-desc")} </h3>
        <div style={{ paddingBottom: "15px" }}>
          <span>{t("leftBar-delete-slide-desc-undo")} </span>
        </div>
        <button
          type="button"
          value={t("general-delete")}
          onClick={handleClick}
          className="btn-popup-delete"
        >
          {t("general-delete")}
        </button>
        <span
          className="close close_multi"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          {t("leftBar-delete-wait-keep-it")}
        </span>
      </Modal>
    </>
  );
}

export default withTranslation()(ConfirmDelete);
