const envType = process.env.NODE_ENV;
const varcel = process.env.REACT_APP_VERCEL_URL;

export let websiteUrl = "https://app.knowyyy.com";
export let apiUrl = "https://api.knowyyy.com";
export let configHostUrl = "knowyyy.com";
export let integrationServer = "https://integration.knowyyy.com";
export let feedsServer = "https://feed.knowyyy.com";

// export let authServer = "https://auth.knowyyy.com";
export let authServer = "http://localhost:4004";
export const highlightProjectId = "jdk9xoe5";

if (envType === "development") {
  websiteUrl = "http://localhost:3000";
  apiUrl = "http://localhost:4000";
  configHostUrl = "localhost:3000";

  authServer = "https://auth.smarthippo.io";

  // integrationServer = "http://localhost:4001";
  feedsServer = "http://localhost:4007";

  // authServer = "http://localhost:4004";
} else if (varcel) {
  websiteUrl = "https://app.smarthippo.io";
  apiUrl = "https://api.smarthippo.io";
  configHostUrl = "smarthippo.io";
  integrationServer = "https://integration.knowyyy.com";
  authServer = "https://auth.smarthippo.io";
  feedsServer = "https://feed.smarthippo.io";
}
