import React, { useEffect, useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import { IoTrashOutline } from "react-icons/io5";
import TextInput from "react-autocomplete-input";
import AcTest from "../../../AcTest/Index";

function AddContactFileField(props) {
    const {
        step,
        deleteInput,
        id,
        toggleError,
        handleInputName,
        index,
        handleInputVariableName,
        handleContactInputs,
        type,
        dataWithVariables,
    } = props;

    const [inputCollapse, setInputCollapse] = useState(false);

    return (
        <>
            <div className="feildHeader">
                <span
                    className="pointer"
                    onClick={() => setInputCollapse(!inputCollapse)}
                >
                    {handleInputName(step, index)}
                </span>

                <IoTrashOutline
                    type="button"
                    title="rightBar-customize-override"
                    onClick={() => deleteInput(id, step.id)}
                    color={"rgba(0, 68, 179, 0.42)"}
                    size={22}
                />
                {/*<a*/}
                {/*  title=""*/}
                {/*  className="bin"*/}
                {/*  onClick={() => deleteInput(id, step.id)}*/}
                {/*></a>*/}
            </div>
            <Collapse in={inputCollapse}>
                <div className="fieldOptions" id={` item${index}`}>

                    <h4>Label:</h4>
                    {/* <input
            type="text"
            name="label"
            value={step.label}
            placeholder="Name:"
            onBlur={(e) => handleInputVariableName(e, step.id, id)}
            onChange={(e) => props.handleContactInputs(e, step.id, id)}
          /> */}

                    <AcTest
                        changeOnSelect={(trigger, slug) => "{{" + slug + "}}"}
                        trigger={["{{"]}
                        options={{ "{{": [...dataWithVariables] }}
                        type="text"
                        name="label"
                        value={step.label}
                        placeholder="Name:"
                        className="autocomplete-form-input"
                        rows={1}
                        maxOptions={50}
                        onBlur={(e) => handleInputVariableName({ target: { name: "label", value: e } }, step.id, id)}
                        onChange={(e) => props.handleContactInputs({ target: { name: "label", value: e } }, step.id, id)}
                    />
                    <div>

                        <div className="d-block">
                            <label htmlFor="">
                                File types</label> </div>
                        <div className="d-flex w-100 justify-content-around btn-files-container">

                            <button className={`btn ${step.fileType === "allFiles" ? 'btn-choise ' : 'btn-no-choise '}`} onClick={() => props.handleContactInputs({ target: { name: "fileType", value: 'allFiles' } }, step.id, id)}>All Files</button>
                            <button className={`btn ${step.fileType === "predefined" ? 'btn-choise ' : 'btn-no-choise'}`} onClick={() => props.handleContactInputs({ target: { name: "fileType", value: 'predefined' } }, step.id, id)}>Predefined</button>
                            <button className={`btn ${step.fileType === "custom" ? 'btn-choise ' : 'btn-no-choise'}`} onClick={() => props.handleContactInputs({ target: { name: "fileType", value: 'custom' } }, step.id, id)}>Custom</button>


                        </div>




                    </div>

                    <Collapse in={step.fileType === "predefined"}>

                        <div className="bg-white">

                            <div className="d-block">
                                <label htmlFor="">
                                    Predifined File types</label> </div>

                            <div className="d-flex w-100 justify-content-around btn-files-container">

                                <button className={`btn ${step.predifinedFileType === "images" ? 'btn-choise ' : 'btn-no-choise '}`} onClick={() => props.handleContactInputs({ target: { name: "predifinedFileType", value: 'images' } }, step.id, id)}>Images</button>
                                <button className={`btn ${step.predifinedFileType === "docs" ? 'btn-choise ' : 'btn-no-choise'}`} onClick={() => props.handleContactInputs({ target: { name: "predifinedFileType", value: 'docs' } }, step.id, id)}>Docs</button>
                                <button className={`btn ${step.predifinedFileType === "compressed" ? 'btn-choise ' : 'btn-no-choise'}`} onClick={() => props.handleContactInputs({ target: { name: "predifinedFileType", value: 'compressed' } }, step.id, id)}>Compressed</button>


                            </div>


                        </div>


                    </Collapse>

                    <Collapse in={step.fileType === "custom"}>

                        <div className="bg-white">
                            <h4>File types (comma separated)</h4>


                            <AcTest
                                changeOnSelect={(trigger, slug) => "{{" + slug + "}}"}
                                trigger={["{{"]}
                                options={{ "{{": [...dataWithVariables] }}
                                type="text"
                                name="customFileTypes"
                                value={step.customFileTypes}
                                placeholder="png,jpg,doc,pdf"
                                className="autocomplete-form-input"
                                rows={1}
                                maxOptions={50}
                                onBlur={(e) => handleInputVariableName({ target: { name: "customFileTypes", value: e } }, step.id, id)}
                                onChange={(e) => props.handleContactInputs({ target: { name: "customFileTypes", value: e } }, step.id, id)}
                            />
                        </div>
                    </Collapse>


                    <label>
                        <input
                            type="checkbox"
                            name="hideLabel"
                            checked={step.hideLabel}
                            onChange={(e) => handleContactInputs(e, step.id, id)}
                        />{" "}
                        Hide Label
                    </label>


                    <label>
                        <input
                            type="checkbox"
                            name="required"
                            checked={step.required}
                            onChange={(e) => handleContactInputs(e, step.id, id)}
                        />{" "}
                        Required
                    </label>

                    <label>
                        <input
                            type="checkbox"
                            name="customFileSize"
                            checked={step.customFileSize}
                            onChange={(e) => handleContactInputs(e, step.id, id)}
                        />{" "}
                        Custom File Size
                    </label>

                    <Collapse in={step.customFileSize}><div className="bg-white">
                        <label className="align-self-center checbox-description">
                            min file size
                        </label>
                        <div className="d-flex ">
                            <input
                                // {...inputStyle}
                                style={{ width: "80%", marginRight: "10px" }}
                                value={step.minFileSize}
                                type="number"
                                max={10000}
                                name={"minFileSize"}
                                onChange={(e) => {
                                    const { value: v, max } = e.target;
                                    if (max && parseInt(v) > parseInt(max)) e.target.value = max;
                                    props.handleContactInputs(e, step.id, id);
                                }}
                                placeholder={"0"}
                                className=""
                            // style={{ width: "200px" }}
                            />
                            {/* {input.hideLabel ? ( */}
                            <label className="align-self-center checbox-description">
                                KB
                            </label>



                        </div>


                        <label className="align-self-center checbox-description">
                            max file size
                        </label>
                        <div className="d-flex ">
                            <input
                                // {...inputStyle}
                                style={{ width: "80%", marginRight: "10px" }}
                                max={10000}
                                type="number"
                                name={"maxFileSize"}
                                value={step.maxFileSize}
                                placeholder={"0"}
                                className=""
                                onChange={(e) => {
                                    const { value: v, max } = e.target;
                                    if (max && parseInt(v) > parseInt(max)) e.target.value = max;
                                    props.handleContactInputs(e, step.id, id);
                                }}
                            // style={{ width: "200px" }}
                            />
                            {/* {input.hideLabel ? ( */}
                            <label className="align-self-center checbox-description">
                                KB
                            </label>



                        </div> </div></Collapse>

                    <div>
                        <label className="align-self-center checbox-description">
                            Max files
                        </label>
                        <div className="d-flex ">
                            <input

                                style={{ width: "80%", marginRight: "10px" }}
                                max={5}
                                min={1}
                                type="number"
                                name={"maxNumberOfFiles"}
                                value={step.maxNumberOfFiles || 1}
                                placeholder={1}
                                className=""
                                disabled
                            // onChange={(e) => {
                            //     const { value: v, max } = e.target;

                            //     if (max && parseInt(v) > parseInt(max)) e.target.value = max;
                            //     props.handleContactInputs(e, step.id, id);
                            // }}
                            // style={{ width: "200px" }}
                            />
                            {/* {input.hideLabel ? ( */}
                            <button style={{ height: "2.5em" }} className="btn btn-primary m-1"
                                onClick={() => {
                                    if (step.maxNumberOfFiles && parseInt(step.maxNumberOfFiles) > 1) {
                                        props.handleContactInputs({ target: { name: "maxNumberOfFiles", value: parseInt(step.maxNumberOfFiles) - 1 } }, step.id, id)
                                    }
                                }}
                            >-</button>
                            <button style={{ height: "2.5em" }} className="btn btn-primary m-1"
                                onClick={() => {
                                    if (!step.maxNumberOfFiles || parseInt(step.maxNumberOfFiles) < 5) {
                                        props.handleContactInputs({ target: { name: "maxNumberOfFiles", value: step.maxNumberOfFiles ? parseInt(step.maxNumberOfFiles) + 1 : 2 } }, step.id, id)
                                    }
                                }}
                            >+</button>
                        </div>
                    </div>


                    <label>Max files error message:</label>
                    <input
                        type="text"
                        name="maxFileErrorMessage"
                        value={step.maxFileEerrorMessage}
                        placeholder="You reached the maximum files limit"
                        onChange={(e) => props.handleContactInputs(e, step.id, id)}
                        className="form-control"
                    />

                    {toggleError(step)}
                </div>
            </Collapse>
        </>
    );
}

export default AddContactFileField;