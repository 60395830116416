import * as React from "react";
import { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../redux/actions";
import { AppLinks } from "../../appRouter/appRouter";
import { userRoutes, guestRoutes } from "../../appRouter/router.config";
import "./navbar.css";
import NavBarModal from "../CreateQuiz/NavBar/navBarModal";

class Navbar extends Component {

    state = {
        inputField: "",
        isVerify: false,
        showMenu: false
    }

    toggleMenu = () => {
        const { showMenu } = this.state;
        this.setState({ showMenu: !showMenu });
    }


    render() {
        const token = localStorage.getItem("token") || ""
        const { name = "", active,helpLabel } = this.props

        if (!token) return <Redirect to={"/login"} />
        return (
            <header>
                <img src="img/logo-inner.png" alt="KNOWY - KNOW YOUR CLIENT" style={{maxHeight:"45px"}} />
                <a href="https://help.knowyyy.com" target="_blank" title={helpLabel} className="helpCenter">{helpLabel}</a>
                <NavBarModal active={active} />
            </header>
        )
    }


}

const mapStateToProps = (state) => {
  let { name } = state.loginDetails;
  return { name };
};
const mapDispatchToprops = (dispatch) => {
  return {
    actions: {
      logout: () => dispatch(logout()),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToprops)(Navbar);
