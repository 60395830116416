import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
// import services from "./services";

export default function Select({
  parameter,
  moduleName,
  selectedIntegrationAppId,
  selectedConnection,
  setParams,
  params,
  getModulesConnectionsOptions,
  moduleConnectionsOptions,
  selectedTrigger,
  setSelectedTrigger,
  onChange,
  isMulti,
  moduleAdditionalptions,
}) {
  const { name, required, label, isTriggered, name: pName } = parameter;
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [additionalOptions, setAdditionalOptions] = useState({});

  useEffect(() => {
    if (Array.isArray(parameter.options)) setOptions(parameter.options);
  }, []);

  useEffect(() => {
    setAdditionalOptions(moduleAdditionalptions);
  }, [moduleAdditionalptions]);

  useEffect(() => {

    if (
      moduleConnectionsOptions &&
      moduleConnectionsOptions.length &&
      !Array.isArray(parameter.options) &&
      parameter.options.startsWith("rpc://")
    ) {
      setIsLoading(false);
      if (Array.isArray(moduleConnectionsOptions))
        return setOptions(moduleConnectionsOptions);
      setOptions([]);
    }
  }, [moduleConnectionsOptions]);

  const maybeLoadOptions = () => {
    if (selectedConnection && selectedConnection.id) {
      setIsLoading(true);
      if (
        !Array.isArray(parameter.options) &&
        parameter.options.startsWith("rpc://") &&
        parameter.isTriggered != "true"
      ) {
        const relevantModuleName =
          !Array.isArray(parameter.options) &&
            parameter.options.startsWith("rpc://")
            ? parameter.options.replace("rpc://", "")
            : moduleName;

        getModulesConnectionsOptions(
          relevantModuleName,
          selectedIntegrationAppId,
          selectedConnection.id
        );
      }
    }
  };

  useEffect(() => {
    maybeLoadOptions();
  }, [selectedConnection]);

  if (Array.isArray(options) && selectedTrigger && !isTriggered) {
    // alert(selectedTrigger.connection_config[selectedTrigger.module_id][name]);

    return (
      <ReactSelect
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? "blue" : "#0046B5",
            height: "42px",
          }),
        }}
        name={name}
        isMulti={isMulti ? true : false}
        // onBlur={validateAll}
        required={required}
        // validateValue={userModuleData[name]}
        value={
          isMulti
            ? selectedTrigger.connection_config[selectedTrigger.module_id][
            pName
            ]
            : options.filter(
              (option) =>
                option.value ===
                selectedTrigger.connection_config[selectedTrigger.module_id][
                pName
                ]
            )
        }
        isSearchable={true}
        onChange={(e) => {
          if (isMulti) {
            return onChange({ value: e }, parameter);
          }
          onChange(e, parameter);
        }}
        options={options}
      />
    );
  }
  if (Array.isArray(options) && !isTriggered)
    return (
      <ReactSelect
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? "blue" : "#0046B5",
            height: "42px",
          }),
        }}
        name={name}
        isMulti={isMulti ? true : false}
        // onBlur={validateAll}
        required={required}
        // validateValue={userModuleData[name]}
        isSearchable={true}
        // onChange={onChange}
        onChange={(e) => {
          if (isMulti) {
            return onChange({ value: e }, parameter);
          }
          onChange(e, parameter);
        }}
        options={options}
      />
    );
  return (
    // <ReactSelect
    //   name={name}
    //   isMulti={isMulti ? true : false}
    //   // onBlur={validateAll}
    //   // validateValue={userModuleData[module_parameter.name]}
    //   required={required}
    //   isSearchable={false}
    //   autoload={true}
    //   isLoading={isLoading}
    //   options={options}
    //   // onFocus={maybeLoadOptions}
    //   onChange={onChange}
    // />
    <ReactSelect
      name={name}
      isMulti={isMulti ? true : false}
      // onBlur={validateAll}
      required={required}
      // validateValue={userModuleData[name]}
      isSearchable={true}
      // onChange={onChange}
      onChange={(e) => {
        if (isMulti) {
          return onChange({ value: e }, parameter);
        }
        onChange(e, parameter);
      }}
      options={additionalOptions[name] || []}
    />
  );
}
