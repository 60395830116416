import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import ReactSelect from "react-select";
import Slider from "rc-slider";

const ProductLogicTab = (props) => {
  const {
    logicType,
    addNewLogic,
    logicStepData,
    stepName,
    changeLogicsDefaultRedirect,
    arrayOfData,
    otherCasesRedirect,
    step,
    addRule,
    removeLogic,
    showConditions,
    handleLogicDetails,
    variables,
    customScripts = [],
    t,
    feedProducts = [],
  } = props;

  const [productsList, setProductsList] = useState([]);
  const [productsCategoriesList, setProductsCategoriesList] = useState([]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);

  useEffect(() => {
    const tempProductsList = feedProducts.map((product) => {
      const { product_id, title } = product;
      return { label: title, value: product_id };
    });
    const tempProductsCategoriesList = Object.values(
      feedProducts.reduce((obj, itr) => {
        const { category } = itr;
        obj[category] = { label: category, value: category };
        return obj;
      }, {})
    );

    const { min, max } = feedProducts.reduce(
      (minAndMax, product) => {
        const { price_value } = product;

        minAndMax.max =
          price_value > minAndMax.max ? price_value : minAndMax.max;
        minAndMax.min =
          price_value < minAndMax.min ? price_value : minAndMax.min;
        return minAndMax;
      },
      { min: 0, max: 0 }
    );
    setMaxPrice(max);
    setMinPrice(min);
    setProductsCategoriesList(tempProductsCategoriesList);
    setProductsList(tempProductsList);
  }, []);

  const handleSliderMarks = (minPrice, maxPrice) => {
    const newMarks = {};
    newMarks[minPrice] = {
      style: { color: "black" },
      label: minPrice,
    };
    newMarks[maxPrice] = {
      style: { color: "black" },
      label: maxPrice,
    };
    return newMarks;
  };

  return (
    <div className="logic-fields ">
      {logicStepData.length ? (
        // conditions start
        <>
          <div
            style={{ width: "100%", maxHeight: "400px", overflowY: "scroll" }}
          >
            {logicStepData.map((logic, index) => {
              const {
                to = [],
                then,
                redirectUrl,
                mathOperation = "+",
                amount = 0,
                conditions,
                minValue = minPrice,
                maxValue = maxPrice,
                varValue = "",
                thenFilterType = "amount",
                includesOrExcludes = "includes",
                mathSign = "+",
              } = logic;
              let selectValue = [];
              if (then === "product") {
                selectValue = productsList.filter((product) => {
                  if (Array.isArray(to)) {
                    return to.find((itr) => itr.value == product.value);
                  }
                });
              }
              if (then === "category") {
                selectValue = productsCategoriesList.filter((category) => {
                  if (Array.isArray(to)) {
                    return to.find((itr) => itr.value == category.value);
                  }
                });
              }

              return (
                <div
                  className="conditionsBox"
                  key={`logic__${step.id}__${index}`}
                >
                  {showConditions(step, logic, logicType, index)}
                  <div className="d-flex">
                    <div className="d-flex col-2">
                      <label>{t("leftBar-welcome-logicModal-then")} </label>
                    </div>
                    <div className="d-flex col-9 row">
                      <div className="col-6">
                        <select
                          value={then}
                          name="then"
                          className="form-control product-logic-input"
                          onChange={(e) =>
                            handleLogicDetails(e, index, logicType)
                          }
                        >
                          <option value="product">Product</option>
                          <option value="category">Category</option>
                          <option value="priceRange">Price Range</option>
                        </select>
                      </div>
                      <div className="col-6">
                        <select
                          name="thenFilterType"
                          value={thenFilterType}
                          className="form-control product-logic-input"
                          onChange={(e) =>
                            handleLogicDetails(e, index, logicType)
                          }
                        >
                          <option value="amount">Score</option>
                          <option value="filter">Filter</option>
                          {/* <option value="includes">Includes</option>
                        <option value="excludes">Excludes</option> */}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="d-flex col-2"></div>
                    <div
                      className="d-flex col-9 row"
                      style={{ maxHeight: "219px", minHeight: "60px" }}
                    >
                      {thenFilterType === "filter" && (
                        <div className="col-4">
                          <select
                            name="includesOrExcludes"
                            value={includesOrExcludes}
                            className="form-control product-logic-input"
                            onChange={(e) =>
                              handleLogicDetails(e, index, logicType)
                            }
                          >
                            <option value="includes">Includes</option>
                            <option value="excludes">Excludes</option>
                          </select>
                        </div>
                      )}
                      {then === "product" ? (
                        <div className="col-8">
                          <ReactSelect
                            isMulti={true}
                            placeholder="Select Product..."
                            name="to"
                            isCreatable={false}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              control: (base) => ({
                                ...base,
                                overflowY: selectValue.length ? "auto" : "none",
                                maxHeight: "200px",
                                marginRight: "-19px",
                                minHeight: "60px",
                              }),
                            }}
                            className="product-logic-input"
                            isSearchable={true}
                            options={productsList}
                            value={selectValue}
                            onChange={(value) => {
                              handleLogicDetails(
                                { target: { name: "to", value } },
                                index,
                                logicType
                              );
                            }}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                      {then === "category" ? (
                        <div className="col-8">
                          <ReactSelect
                            isMulti={true}
                            placeholder="Select Category..."
                            name="to"
                            isCreatable={false}
                            className="product-logic-input"
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              control: (base) => ({
                                ...base,
                                overflowY: selectValue.length ? "auto" : "none",
                                maxHeight: "200px",
                                marginRight: "-19px",
                                minHeight: "60px",
                              }),
                            }}
                            isSearchable={true}
                            options={productsCategoriesList}
                            value={selectValue}
                            onChange={({ value }) => {
                              handleLogicDetails(
                                { target: { name: "to", value } },
                                index,
                                logicType
                              );
                            }}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                      {then === "priceRange" ? (
                        <>
                          <div className="col-5">
                            <Slider
                              range
                              value={[minValue, maxValue]}
                              min={minPrice}
                              max={maxPrice}
                              marks={handleSliderMarks(minPrice, maxPrice)}
                              onChange={([min, max]) => {
                                handleLogicDetails(
                                  { target: { name: "minValue", value: min } },
                                  index,
                                  logicType
                                );
                                handleLogicDetails(
                                  { target: { name: "maxValue", value: max } },
                                  index,
                                  logicType
                                );
                              }}
                              allowCross={false}
                              style={{
                                margin: "30px 20px",
                              }}
                              trackStyle={{ background: "black" }}
                              handleStyle={{ background: "black" }}
                              // className="product-logic-input"
                              railStyle={{ background: "#abe2fb" }}
                            />
                          </div>
                          <div className="col-3">
                            <div
                              style={{
                                marginTop: "30px",
                              }}
                            >
                              Selected Range: {minValue} - {maxValue}
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {thenFilterType === "amount" && (
                        <div className="col-2">
                          <select
                            name="mathSign"
                            value={mathSign}
                            className="form-control product-logic-input"
                            onChange={(e) =>
                              handleLogicDetails(e, index, logicType)
                            }
                          >
                            <option value="plus">+</option>
                            <option value="minus">-</option>
                          </select>
                        </div>
                      )}

                      {thenFilterType === "amount" && (
                        <div className="col-2">
                          <input
                            type="number"
                            value={amount}
                            name="amount"
                            className="form-control product-logic-input"
                            placeholder="Amount"
                            onChange={(e) =>
                              handleLogicDetails(e, index, logicType)
                            }
                          />
                        </div>
                      )}
                    </div>
                    {conditions.length > 1 ? (
                      <div className="col-1 d-flex justify-content-between"></div>
                    ) : (
                      ""
                    )}
                  </div>
                  <a
                    title=""
                    className="add"
                    onClick={() => addRule(index, logicType)}
                  >
                    <span>+</span> {t("general-add")}
                  </a>
                  <div className="conditions-footer">
                    <a title="" className="down2"></a>
                    <a title="" className="up"></a>
                    <a
                      title=""
                      className="delete pointer"
                      onClick={() => removeLogic(index, logicType)}
                    >
                      {t("leftBar-welcome-logicModal-delete-condition")}
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <></>
      )}

      <div className="d-flex justify-content-end w-100">
        <a
          title=""
          className="addCondition"
          onClick={() => addNewLogic(logicType)}
        >
          {t("leftBar-welcome-logicModal-add-condition")}
        </a>
      </div>
    </div>
  );
};

export default withTranslation()(ProductLogicTab);
