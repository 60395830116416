import React from "react";

class WorkspaceItem extends React.Component {
    render() {
        const { workspace_name, workspace_id } = this.props.workspace
        return (

            <li onClick={() => this.props.changeFolderFilter(workspace_id, workspace_name, true)}>
                {this.props.selectedFolderId == workspace_id ? <a role="button" className={"active"}>{workspace_name}</a> : <a role="button">{workspace_name}</a>}

            </li>
        )
    }
}

export default WorkspaceItem