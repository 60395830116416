import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getTemplateDetailsById } from "../../redux/actions";
import { mainAxios } from "../../Axios/mainAxios";
import scoreIcon from "../../images/sxored.png";
import assesmentIcon from "../../images/assesment.png";
import personalityIcon from "../../images/personality.png";
import {AiOutlineEdit,AiOutlineEye} from "react-icons/ai"

class Template extends React.Component {

  constructor() {
    super();
    this.state = {
      isHovered: false
    };
    this.handleHover = this.handleHover.bind(this);
  }

  handleSelection = async () => {
    const { id, thumbnails } = this.props.data;
    try {
      this.props.goToEdit(id, this.props.folderId);
      // const { data } = await mainAxios.get(
      //   `/templates/getQuizId/${id}?folder=${this.props.folderId}`
      // );
      // const { id: quizId } = data;
      // if (quizId) return this.props.goToEdit(quizId);
    } catch (err) { }
  };

  handleHover() {
    this.setState(prevState => ({
      isHovered: !prevState.isHovered
    }));
  }

  render() {
    let showPreviewModal = this.props.showPreviewModal;
    let { thumbnails, title, description, quiz_type } = this.props.data;
    const btnClass = this.state.isHovered ? "" : "display";

    thumbnails = thumbnails ? thumbnails : "../img/pic.jpg";
    return (
      <div
        style={{ cursor: "pointer" }}
        className="item d-flex align-items-center flex-column"
      >
        <div
          style={{ cursor: "pointer", justifyContent: "center", width: "100%" }}
          className="item d-flex align-items-center"
          onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}
        >

          <img src={thumbnails || "../img/pic.jpg"} style={{ opacity: this.state.isHovered? "50%":"100%", maxWidth: "243px", width:"100%", maxHeight: "140px", borderRadius: "12px 12px 0px 0px" }} />

          <div
            className={btnClass}
            style={{
              position: "absolute",
              display: "flex",
              gap: "15px"
            }}>
            <div className="template-preview" onClick={() => { showPreviewModal(); this.handleHover() }}><AiOutlineEdit/>Preview</div>
            <div className="template-preview edit" onClick={this.handleSelection}><AiOutlineEye/>Edit</div>
          </div>
        </div>

        <h3 onClick={() => showPreviewModal()}>{title}</h3>
        <div className="quizType" style={{display: "flex", alignSelf: "baseline", alignItems: "center", margin: "0px 10px 5px 20px", width: "85%"}}>
          <img
              style={{ maxWidth: "25px", maxHeight: "25px" }}
              src={
                quiz_type === "scored"
                    ? scoreIcon
                    : quiz_type === "assessment"
                        ? assesmentIcon
                        : personalityIcon
              }
              alt="quizIcon"
          />
          <p style={{margin:"0", color: "#78889F", fontSize: ".9rem"}}>
            {quiz_type === "scored"
                ? "Score"
                : quiz_type.charAt(0).toUpperCase() + quiz_type.slice(1)}
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      getTemplateDetailsById: (id) => dispatch(getTemplateDetailsById(id)),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Template);
