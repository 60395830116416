import React, { useState, useEffect } from "react";
import { FaPlus, FaPen } from "react-icons/fa";
import { IoMdDoneAll } from "react-icons/io"
import { IoTrashOutline } from "react-icons/io5";

import moment from "moment";

export default function TriggerList(props) {
  const { setExistingTrigger, updateTriggerActivity, itr, setIsEdit, setSelectedTrigger, getTriggerFullDetails, closeEdit, isEdit, updateTriggerName, deleteTrigger } = props;

  const [isEditName, setIsEditName] = useState(false)
  const [triggerName, setTriggerName] = useState(itr.trigger_name ? itr.trigger_name : "Integration " + itr.appName)

  return (
    <div className="mt-2 d-flex justify-content-between align-items-baseline w-100 "
      style={{ background: "rgb(232, 238, 241)", padding: '5px', borderRadius: '4px', margin: '5px' }} key={triggerName.replaceAll(" ", "")}>
      <div className="d-flex align-items-center">
        <button
          className="btn btn-secondary trigger-btn"
        // onClick={() => setExistingTrigger(itr)}
        >
          {itr.img}
        </button>
        <button className="btn btn-secondary trigger-btn" disabled>
          <FaPlus size={40} />
        </button>
        {!isEditName ? (
          <div className="d-inline-flex m-4 flex-column edit-trigger-name-container">

            <h4 className="d-inline">{triggerName}</h4>
            <span>{moment(itr.create_at).format("DD-MM-YYYY hh:mm:ss")}</span>
            <button className="edit-trigger-name-btn"
              onClick={() => setIsEditName(true)}><FaPen size={13} color='grey' /></button>
          </div>
        ) : <div className="d-inline-flex m-4 edit-trigger-name-container">
          <input type="text" value={triggerName}
            onChange={(e) => setTriggerName(e.target.value)}
          /> <button className="btn btn-link"
            onClick={() => {

              if (triggerName == itr.trigger_name || triggerName == "Integration " + itr.appName || !triggerName) {
                setTriggerName((itr.trigger_name ? itr.triggerName : "Integration " + itr.appName))
                setIsEditName(false)
              } else {
                updateTriggerName(itr.id, triggerName)
                setIsEditName(false)
              }
            }}
          ><IoMdDoneAll size={15} /></button>
        </div>}


        <div className="addSwitch trigger-switch d-flex align-items-baseline">
          <label className="switch">
            <input
              type="checkbox"
              id={`disable-enable-trigger-${itr.id}`}
              checked={itr.is_active}
              onChange={() => updateTriggerActivity(itr.id, !itr.is_active)}
            />
            <span className="slider round"></span>
          </label>
          <button className="" onClick={() => {
            if (isEdit) {
              closeEdit()
            }
            setIsEdit(true)
            setSelectedTrigger(itr)
            setExistingTrigger(itr)
            getTriggerFullDetails(itr.id)
          }}><FaPen size={15} color='grey' /></button>
        </div>
      </div>
      {/* <label className="checkboxContainer">
        <input
          type="checkbox"
          id={`disable-enable-trigger-${itr.id}`}
          onChange={() => updateTriggerActivity(itr.id, !itr.is_active)}
          checked={itr.is_active}
        />
        <span className="checkmark"></span>
        <span>Active</span>
      </label> */}


      <button className="btn btn-link"
        onClick={() => {
          if (window.confirm("Are you sure you want to delete this trigger?")) {
            deleteTrigger(itr.id)
          }
        }}> <IoTrashOutline size={20} color="#03A6CA" /></button>
    </div>
  );
}
