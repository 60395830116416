import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ConfirmDelete from "../Modals/ConfirmDelete";
import "../main.css";
import {
  changeFolder,
  duplicateQuiz,
  deleteQuiz,
} from "../../../redux/actions";
import { withTranslation } from "react-i18next";
import { configHostUrl, websiteUrl, envType } from "../../../config";
import assesmentIcon from "../../../images/assesment.png";
import { quizSubDomainName } from "../../../utils";
import personalityIcon from "../../../images/personality.png";
import scoreIcon from "../../../images/sxored.png";
import dotsIcon from "../../../images/dots.png";
import linkIcon from "../../../images/link.png";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
class QuizItem extends React.Component {
  state = {
    isModalOpen: false,
    deleteValue: "",
    subDomain: "",
  };

  dropDown;
  constructor(props) {
    super(props);
    this.dropDown = React.createRef();
  }

  componentDidMount() {
    const { published, subdomain_name, slug, user_id, ws_id } = this.props.data;
    let subdomainPath;

    if (!published || parseInt(published) != 1) {
      return;
    }
    if (configHostUrl === "knowyyy.com") {
      subdomainPath = `https://${subdomain_name}.${configHostUrl}/page/${slug}`;
    } else {
      subdomainPath = websiteUrl.includes("https://")
        ? quizSubDomainName(
            websiteUrl,
            8,
            `${window.location.host}/quiz/${user_id}/${slug}`
          )
        : quizSubDomainName(
            websiteUrl,
            7,
            `${window.location.host}/quiz/${user_id}/${slug}`
          );
    }
    // window.open(`${subdomainPath}`, "_blank");
    this.setState({ subDomain: subdomainPath });
  }
  handleDuplicateQuiz = () => {
    this.props.actions.duplicateQuiz(this.props.data.id);
  };

  handleDropDown = () => {
    // const node = this.dropDown.current;
    // node.firstChild.firstChild.style.color = "#0046B5";
    //
    // if (!node.classList.contains("show")) {
    //   node.firstChild.firstChild.style.color = "#BE1EB9";
    // }
  };

  handleModalState = () => {
    this.setState({ isModalOpen: true });
  };
  handleChange = (e) => {
    const { value } = e.target;

    if (value === "DELETE") {
      this.setState({ deleteValue: value });
    } else {
      this.setState({ deleteValue: null });
    }
  };

  handleQuizDelete = () => {
    const { deleteValue } = this.state;
    if (deleteValue !== "DELETE") return;

    this.props.actions.deleteQuiz(this.props.data.id);
  };

  beautifyQuizTypeName = () => {
    const { type } = this.props.data;
    if (type === "scored") return "Score";
    else if (type === "ecom") return "Ecommerce";
    return type.charAt(0).toUpperCase() + type.slice(1);
  };

  render() {
    let {
      id,
      title,
      edited_at,
      slug,
      published,
      directory_id,
      image_path,
      type,
      subdomain_name,
      ws_id,
    } = this.props.data;

    image_path = image_path
      ? `https://smart-hippo.s3.eu-central-1.amazonaws.com/${image_path}`
      : "img/pic.jpg";
    const url = published ? `/quiz/${slug}` : `/preview`;
    const { t, wsId } = this.props;
    const { isModalOpen, subDomain } = this.state;
    // alert('fff')
    return (
      <>
        <div className={`item ${published == "1" ? "itemPlay" : ""}`}>
          {/*<div className={`item`}>*/}
          <Link to={`/edit/${id}`}>
            {published == "1" ? (
              <div className={"published-ribbon"}>
                <div className={"published-text"}>Published</div>{" "}
              </div>
            ) : (
              ""
            )}
            <div className="pic">
              <img src={image_path} />
            </div>
          </Link>
          <Link to={`/edit/${id}`}>
            <h3>{title}</h3>
          </Link>
          <span className="date">
            {t("dashboard-updated")}
            {edited_at}
          </span>
          <div className="quizType">
            <img
              src={
                type === "scored"
                  ? scoreIcon
                  : type === "assessment"
                  ? assesmentIcon
                  : personalityIcon
              }
              alt="quizIcon"
            />
            <p>{this.beautifyQuizTypeName()}</p>
          </div>

          <div className="item-tools">
            <div className="left-icons">
              <a title="Duplicate" onClick={this.handleDuplicateQuiz}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.5 0H5.5C4.83696 0 4.20108 0.263398 3.73224 0.732239C3.2634 1.20108 3 1.83696 3 2.5V3H2.5C1.83696 3 1.20108 3.2634 0.732239 3.73224C0.263398 4.20108 0 4.83696 0 5.5V13.5C0 14.163 0.263398 14.7989 0.732239 15.2678C1.20108 15.7366 1.83696 16 2.5 16H10.5C11.163 16 11.7989 15.7366 12.2678 15.2678C12.7366 14.7989 13 14.163 13 13.5V13H13.5C14.163 13 14.7989 12.7366 15.2678 12.2678C15.7366 11.7989 16 11.163 16 10.5V2.5C16 1.83696 15.7366 1.20108 15.2678 0.732239C14.7989 0.263398 14.163 0 13.5 0ZM12 13.5C12 13.8978 11.842 14.2794 11.5607 14.5607C11.2794 14.842 10.8978 15 10.5 15H2.5C2.10218 15 1.72064 14.842 1.43933 14.5607C1.15803 14.2794 1 13.8978 1 13.5V5.5C1 5.10218 1.15803 4.72064 1.43933 4.43933C1.72064 4.15803 2.10218 4 2.5 4H3V10.5C3 11.163 3.2634 11.7989 3.73224 12.2678C4.20108 12.7366 4.83696 13 5.5 13H12V13.5ZM15 10.5C15 10.8978 14.842 11.2794 14.5607 11.5607C14.2794 11.842 13.8978 12 13.5 12H5.5C5.10218 12 4.72064 11.842 4.43933 11.5607C4.15803 11.2794 4 10.8978 4 10.5V2.5C4 2.10218 4.15803 1.72064 4.43933 1.43933C4.72064 1.15803 5.10218 1 5.5 1H13.5C13.8978 1 14.2794 1.15803 14.5607 1.43933C14.842 1.72064 15 2.10218 15 2.5V10.5Z"
                    fill="#78889F"
                  />
                </svg>
              </a>

              {parseInt(published) == 1 ? (
                <a
                  href={subDomain}
                  target="_blank"
                  title="Link To Quiz"
                  className="pointer"
                >
                  <svg
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.7599 8.83441C10.6079 8.83441 10.4559 8.77652 10.3401 8.66044C9.41412 7.73449 7.90748 7.73419 6.98123 8.66044C6.74967 8.8923 6.37323 8.8923 6.14167 8.66044C5.90981 8.42858 5.90981 8.05273 6.14167 7.82088C7.53075 6.4315 9.79086 6.4318 11.1796 7.82088C11.4115 8.05273 11.4115 8.42858 11.1796 8.66044C11.0639 8.77652 10.9116 8.83441 10.7599 8.83441Z"
                      fill="#0046B5"
                    />
                    <path
                      d="M10.3403 12.2215C9.42804 12.2215 8.51575 11.8741 7.82136 11.1797C7.5895 10.9479 7.5895 10.572 7.82136 10.3402C8.05292 10.1083 8.42936 10.1083 8.66092 10.3402C9.58687 11.2661 11.0935 11.2664 12.0198 10.3402C12.2513 10.1083 12.6278 10.1083 12.8593 10.3402C13.0912 10.572 13.0912 10.9479 12.8593 11.1797C12.1646 11.8741 11.2523 12.2215 10.3403 12.2215Z"
                      fill="#0046B5"
                    />
                    <path
                      d="M12.4394 11.3534C12.2874 11.3534 12.1354 11.2955 12.0196 11.1794C11.7877 10.9476 11.7877 10.5717 12.0196 10.3399L15.3363 7.02317C15.5678 6.79131 15.9443 6.79131 16.1758 7.02317C16.4077 7.25503 16.4077 7.63088 16.1758 7.86273L12.8592 11.1794C12.7434 11.2955 12.5914 11.3534 12.4394 11.3534Z"
                      fill="#0046B5"
                    />
                    <path
                      d="M7.44329 16.3492C7.29129 16.3492 7.13929 16.2913 7.0235 16.1752C6.79164 15.9434 6.79164 15.5675 7.0235 15.3357L8.87749 13.4817C9.10905 13.2498 9.48549 13.2498 9.71705 13.4817C9.94891 13.7135 9.94891 14.0894 9.71705 14.3212L7.86307 16.1752C7.74729 16.2913 7.59528 16.3492 7.44329 16.3492Z"
                      fill="#0046B5"
                    />
                    <path
                      d="M3.24504 12.1508C3.09304 12.1508 2.94104 12.0929 2.82526 11.9768C2.5934 11.745 2.5934 11.3691 2.82526 11.1373L6.14195 7.82087C6.37381 7.58902 6.74995 7.58902 6.98151 7.82087C7.21337 8.05273 7.21337 8.42858 6.98151 8.66044L3.66482 11.9768C3.54875 12.0926 3.39675 12.1508 3.24504 12.1508Z"
                      fill="#0046B5"
                    />
                    <path
                      d="M9.70305 5.69258C9.55105 5.69258 9.39935 5.63469 9.28327 5.51891C9.05141 5.28705 9.05141 4.9112 9.28327 4.67905L11.1373 2.82477C11.3691 2.59291 11.745 2.59291 11.9771 2.82477C12.209 3.05663 12.209 3.43247 11.9771 3.66463L10.1231 5.51891C10.0071 5.63439 9.85475 5.69258 9.70305 5.69258Z"
                      fill="#0046B5"
                    />
                    <path
                      d="M5.34398 17.1995C4.42575 17.1995 3.50752 16.858 2.825 16.1752C1.45937 14.8096 1.45937 12.5026 2.825 11.137C3.05656 10.9051 3.433 10.9051 3.66456 11.137C3.89642 11.3688 3.89642 11.7447 3.66456 11.9765C2.75434 12.8867 2.75434 14.4252 3.66456 15.3354C4.57478 16.2456 6.11319 16.2456 7.02341 15.3354C7.25497 15.1035 7.63141 15.1035 7.86297 15.3354C8.09483 15.5672 8.09483 15.9431 7.86297 16.1749C7.18045 16.858 6.26222 17.1995 5.34398 17.1995Z"
                      fill="#0046B5"
                    />
                    <path
                      d="M15.7559 8.0367C15.6039 8.0367 15.4519 7.97881 15.3362 7.86273C15.1043 7.63087 15.1043 7.25503 15.3362 7.02317C16.2464 6.11295 16.2464 4.57455 15.3362 3.66433C14.4259 2.75411 12.8875 2.75411 11.9773 3.66433C11.7458 3.89619 11.3693 3.89619 11.1378 3.66433C10.9059 3.43247 10.9059 3.05662 11.1378 2.82477C12.5034 1.45914 14.8104 1.45914 16.176 2.82477C17.5417 4.19039 17.5417 6.49741 16.176 7.86303C16.0599 7.97881 15.9079 8.0367 15.7559 8.0367Z"
                      fill="#0046B5"
                    />
                  </svg>
                </a>
              ) : (
                ""
              )}
            </div>
            <Dropdown
              ref={this.dropDown}
              id={`dashboard-quiz-threeDots-menu-wrapper-${id}`}
              onClick={this.handleDropDown}
            >
              <Dropdown.Toggle className="dashboard-quiz-threeDots-menu-toggle">
                <i className="bi bi-three-dots-vertical"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => this.props.handleToggelModal(id)}>
                  {t("dashboard-quiz-menu-move-to-folder")}
                </Dropdown.Item>

                {this.props.workspaces.length > 0 && !ws_id && (
                  <Dropdown.Item
                    onClick={() => this.props.handleToggelModal(id, true)}
                  >
                    Move to workspace
                  </Dropdown.Item>
                )}
                <Dropdown.Item onClick={() => this.handleModalState()}>
                  {t("dashboard-quiz-menu-delete")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div>
            <Modal
              show={isModalOpen}
              onHide={() => this.setState({ isModalOpen: false })}
              centered
            >
              <div style={{ padding: "40px" }}>
                <h2>{t("dashboard-quiz-delete-modal-h2")}</h2>
                <p>{t("dashboard-quiz-delete-modal-p")} </p>
                <input
                  type="text"
                  name="textField"
                  onChange={(e) => this.handleChange(e)}
                />
                <button
                  type="button"
                  value={t("general-delete")}
                  onClick={this.handleQuizDelete}
                  className={
                    this.state.deleteValue !== "DELETE"
                      ? "btn-popup-delete btn-popup-delete disabled"
                      : "btn-popup-delete"
                  }
                >
                  {t("general-delete")}
                </button>
                <span
                  className="close close_multi"
                  onClick={() => this.setState({ isModalOpen: false })}
                >
                  {t("dashboard-quiz-delete-modal-button")}
                </span>
              </div>
            </Modal>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { workspaces } = state;
  return { workspaces };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      changeFolder: (quizId, folderId) =>
        dispatch(changeFolder(quizId, folderId)),
      duplicateQuiz: (quizId) => dispatch(duplicateQuiz(quizId)),
      deleteQuiz: (id) => dispatch(deleteQuiz(id)),
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(QuizItem)
);
