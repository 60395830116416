import React from "react";


export default function ModuleItem({
  moduleDetails,
  appImage,
  setSelectedModule,
  selectedModule,
}) {
  const {
    id,
    name,
    label,
    integration_app_connection_id,
    module_interface,
    module_json,
    module_parameters,
    type,
  } = moduleDetails;
  return (
    <div className={selectedModule && selectedModule.id == id ? "connectAppDivSelected" : "connectAppDiv"} onClick={() => setSelectedModule(moduleDetails)} >
      <img width={24} src={appImage} />
      <span className={"appListTxt"}> {label}</span>
    </div>
  );
}
