import { mainAxios, integrationAxios, feedsAxios } from "../Axios/mainAxios";
import { integrationServer, apiUrl } from "../config";
export const registerService = async (userDetails) => {
  try {
    const { data } = await mainAxios.post("/auth/register", userDetails);
    return data;
  } catch (err) {
    console.log(err);
    return {
      message: "",
      name: "",
      userName: "",
      user_id: "",
      orgId: "",
      roleId: "",
    };
  }
};

export const loginService = async (userDetails) => {
  try {
    const { data } = await mainAxios.post("/auth/login", userDetails);
    return data;
  } catch (err) {
    console.log(err);
    return {
      message: "",
      name: "",
      userName: "",
      user_id: "",
      orgId: "",
      roleId: "",
      orgArray: [],
    };
  }
};

export const initService = async (authServer) => {
  try {
    const { data } = await mainAxios.get("/auth/verify", {
      headers: { authpath: authServer },
    });
    return data;
  } catch (err) {
    return {
      name: "",
      user_name: "",
      id: "",
      orgId: "",
      roleId: "",
      orgArray: [],
    };
  }
};

export const searchForImageService = async (searchDetails) => {
  try {
    const { data } = await mainAxios.post(`/images/search/`, searchDetails);
    let { videos, images, hasImages } = data;
    return { images, hasImages };
  } catch (err) {
    console.log(err.message);
    return [];
  }
};
export const deleteImageService = async (fileName, searchDetails) => {
  try {
    const { data } = await mainAxios.post(`/images/delete/`, {
      searchDetails,
      fileName,
    });
    let { videos, images, hasImages } = data;
    return { images, hasImages };
  } catch (err) {
    console.log(err.message);
    return [];
  }
};
export const uploadImageService = async (formData) => {
  try {
    const { data } = await mainAxios.post(`${apiUrl}/images/upload`, formData);
    let { videos, images, hasImages } = data;
    return { images, hasImages };
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const editQuizService = async (quizDetails) => {
  try {
    const { data } = await mainAxios.post("/quiz/edit", quizDetails);
    return data;
  } catch (err) {
    console.log(err.message);
    return "Error with the connection, please try again later";
  }
};

export const getTemplatesService = async () => {
  try {
    const { data } = await mainAxios.get("/templates/all");
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};
export const getThemesService = async () => {
  try {
    const { data } = await mainAxios.get("/themes/all");
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const getTemplateDetailsByIdService = async (id) => {
  try {
    const { data } = await mainAxios.get(`/templates/${id}`);
    return data;
  } catch (err) {
    console.log(err.message);
    return {};
  }
};

export const getUserQuizesService = async () => {
  try {
    const { data } = await mainAxios.get(`/quiz/all`);
    return data;
  } catch (err) {
    console.log(err.message);
    return { quizes: [], folders: [] };
  }
};

export const getQuizDetailsByIdService = async (quizId, wsId) => {
  try {
    const path = wsId ? `/quiz/${quizId}` : `/quiz/${quizId}`;
    const { data } = await mainAxios.get(path);
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const deleteQuizService = async (quizId) => {
  try {
    const { data } = await mainAxios.delete(`/quiz/${quizId}`);

    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};
export const duplicateQuizService = async (quizId) => {
  try {
    const { data } = await mainAxios.post(`/quiz/duplicate/${quizId}`);
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const getQuizDetailsByUserIdAndSlugService = async (
  userId,
  slug,
  handler,
  isScreenShot,
  fullUrl
) => {
  const uniqueKey = localStorage.getItem("key") || "";
  const headers = {
    resolution: JSON.stringify({
      heigth: window.screen.height,
      width: window.screen.width,
    }),
    uniqueKey,
    userId,
  };
  try {
    const { data } = await mainAxios.get(
      `/guest/${userId}/${slug}/${handler}?isScreenShot=${isScreenShot}&fullUrl=${fullUrl}`,
      { headers }
    );
    return data;
  } catch (err) {
    console.log(err.message);
    return {};
  }
};

export const saveAnswerService = async ({
  quizId,
  questionId: id,
  selectedOptions,
  answers,
  numberOfQuestions,
  formDetails,
  type,
  title,
  visitKey,
}) => {
  const uniqueKey = localStorage.getItem("key") || "";
  const visitGuid = sessionStorage.getItem("visitKey") || visitKey || "";
  const headers = { uniqueKey };
  try {
    const { data } = await mainAxios.post(
      `/guest/saveAnswer`,
      {
        quizId,
        formDetails,
        id,
        type,
        selectedOptions,
        visitGuid,
        answers,
        numberOfQuestions,
        title,
      },
      { headers }
    );
    return data;
  } catch (err) {
    console.log(err.message);
    return {};
  }
};

export const checkTriggersService = async (quizId, visitKey) => {
  const uniqueKey = localStorage.getItem("key") || "";
  const visitGuid = sessionStorage.getItem("visitKey") || visitKey || "";
  const headers = { uniqueKey };

  try {
    const { data } = await mainAxios.post(
      `/guest/checkTriggers`,
      {
        quizId,
        visitGuid,
        path: `${integrationServer}/appModules/modules/executeAction`,
      },
      { headers }
    );
    return data;
  } catch (err) {
    console.log(err.message);
    return {};
  }
};

export const getQuizAnalyzeGraphService = async (analyzeSearchDetails) => {
  try {
    const { data } = await mainAxios.post(`/quiz/analyze/graph`, {
      ...analyzeSearchDetails,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const getQuizAnalyzeStatsService = async (
  quizId,
  filterObj,
  additionalFilters
) => {
  try {
    const { data } = await mainAxios.post(`/quiz/analyze/stats`, {
      quizId,
      filters: filterObj,
      additionalFilters,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};
export const getQuizAnalyzeLineChartService = async (
  quizId,
  filterObj,
  additionalFilters
) => {
  try {
    const { data } = await mainAxios.post(`/quiz/analyze/lineChart`, {
      quizId,
      filters: filterObj,
      additionalFilters,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const getQuizAnalyzeTableService = async (analyzeSearchDetails) => {
  try {
    const { data } = await mainAxios.post(`/quiz/analyze/table`, {
      ...analyzeSearchDetails,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const getQuizAnalyzeQuestionsService = async (analyzeSearchDetails) => {
  try {
    const { data } = await mainAxios.post(`/quiz/analyze/questions`, {
      ...analyzeSearchDetails,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const createFolderService = async (folderName) => {
  try {
    const { data } = await mainAxios.post(`/folders/create`, { folderName });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const changeFolderService = async (quizId, folderId) => {
  try {
    const { data } = await mainAxios.post(`/folders/change`, {
      quizId,
      folderId,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};
export const deleteFolderService = async (folderId) => {
  try {
    const { data } = await mainAxios.post(`/folders/delete`, { folderId });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const saveCustomStyleService = async (customStyle, quizId) => {
  try {
    const { data } = await mainAxios.post(`/quiz/saveStyle`, {
      customStyle,
      quizId,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const saveCustomScriptsService = async (customScripts, quizId) => {
  try {
    const { data } = await mainAxios.post(`/quiz/saveScripts`, {
      customScripts,
      quizId,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const deleteCustomScriptsService = async (scriptId, quizId) => {
  try {
    const { data } = await mainAxios.post(`/quiz/deleteScript`, {
      scriptId,
      quizId,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const getApiKeysService = async () => {
  try {
    const { data } = await mainAxios.get(`/keys/getKeys`);
    return data;
  } catch (err) {
    console.log(err.message);
    return {};
  }
};

export const createNewApiKeyService = async (keyObj) => {
  try {
    const { data } = await mainAxios.post(`/keys/create`, keyObj);
    return data;
  } catch (err) {
    console.log(err.message);
    return {};
  }
};

export const showApiKeyService = async (id) => {
  try {
    const { data } = await mainAxios.post(`/keys/showKey`, { showId: id });
    return data;
  } catch (err) {
    console.log(err.message);
    return {};
  }
};

export const toggleKeyActivationService = async (is_active, key_id) => {
  try {
    const { data } = await mainAxios.post(`/keys/active`, {
      is_active,
      key_id,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return {};
  }
};

export const deleteKeyService = async (key_user, key_id) => {
  try {
    const { data } = await mainAxios.post(`/keys/delete`, {
      key_user,
      key_id,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return {};
  }
};

export const createSubdomainService = async (subdomainName, quizId) => {
  try {
    const { data } = await mainAxios.post(`/users/createSubdomain`, {
      subdomainName,
      quizId,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};
export const saveThemeService = async (themeStyle, themeName, themeId) => {
  try {
    const { data } = await mainAxios.post(`/themes/saveTheme`, {
      themeStyle,
      themeId,
      themeName,
      themeType: 1,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const getUserInfoService = async () => {
  try {
    const { data } = await mainAxios.get("/profile/getUserInfo");

    return data;
  } catch (err) {
    console.log(err);
    return null;
  }
};
/* sagi */
export const updateUserInfoService = async (firstName, lastName, lang) => {
  try {
    const { data } = await mainAxios.post("/profile/updateUserInfo", {
      firstName,
      lastName,
      lang,
    });
    return data;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const updatePasswordService = async (oldPassword, newPassword) => {
  try {
    const { data } = await mainAxios.post("/profile/updateUserPassword", {
      oldPassword,
      newPassword,
    });

    return data;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const saveLogicsService = async (quizId, logicsObj) => {
  try {
    const { data } = await mainAxios.post(`/logic/save`, {
      logicsObj,
      quizId,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};
export const saveProductsLogicsService = async (quizId, productsLogic) => {
  try {
    const { data } = await mainAxios.post(`/feeds/logics/save`, {
      productsLogic,
      quizId,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const getStepLogicService = async (quizId, stepId) => {
  try {
    const { data } = await mainAxios.post(`/logic/getStepLogic`, {
      quizId,
      stepId,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};
export const getProductsLogicService = async (quizId) => {
  try {
    const { data } = await mainAxios.get(`/feeds/logics/all?quizId=${quizId}`);
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const calculateLogicService = async (
  quizId,
  stepId,
  conditionsPosition,
  quizData,
  visitKey
) => {
  try {
    const visitGuid = sessionStorage.getItem("visitKey") || visitKey || "";
    const { data } = await mainAxios.post(`/logic/calculate`, {
      quizId,
      stepId,
      conditionsPosition,
      quizData,
      visitGuid,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const handleAccessibilityConfigurationService = async (settings) => {
  try {
    const { data } = await mainAxios.post(
      "/accessibility/updateAccessibility",
      settings
    );
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

// integration server api requests

export const getAppsService = async () => {
  try {
    const { data } = await mainAxios.post(`/connect/getApps`, {
      hmPath: integrationServer,
    });

    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const getModulesService = async (appId) => {
  try {
    const { data } = await mainAxios.post(`/connect/getApps`, {
      hmPath: integrationServer,
      appId,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const getModulesConnectionsService = async (moudleId) => {
  try {
    const { data } = await mainAxios.post(`/connect/getUserConnections`, {
      moudleId,
      path: integrationServer,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const getModulesConnectionsOptionsService = async (connectionObj) => {
  try {
    const { data } = await mainAxios.post(`/connect/getConnectionParams`, {
      connectionObj,
      path: integrationServer,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const createConnectionService = async (
  userConnectionData,
  triggerConnectionID
) => {
  try {
    const { data } = await mainAxios.post(`/connect/setConnection`, {
      userConnectionData,
      triggerConnectionID,
      path: integrationServer,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const setTriggerService = async (
  userConnectionParams,
  userConnectionData,
  triggerConnectionID,
  quizId,
  selectedTrigger,
  appId
) => {
  try {
    const { data } = await mainAxios.post(`/connect/setTrigger`, {
      userConnectionParams,
      triggerConnectionID,
      userConnectionData,
      // path: `${integrationServer}/appModules/setTrigger`,
      quizId,
      selectedTrigger,
      appId,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const getAllTriggeraService = async (connectionId, moduleId, quizId) => {
  try {
    const { data } = await mainAxios.post(`/connect/getTriggers`, {
      connectionId,
      moduleId,
      quizId,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const getAllTriggersForQuizService = async (quizId) => {
  try {
    const { data } = await mainAxios.post(`/connect/getAllTriggers`, {
      quizId,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const updateTriggerActivityService = async (triggrtId, isActive) => {
  try {
    const { data } = await mainAxios.post(`/connect/updateActivity`, {
      triggrtId,
      isActive,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const getIsUserIntegratedService = async () => {
  try {
    const { data } = await mainAxios.get(`/connect/getIsUserIntegrated`);
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const activateIntegratetionService = async () => {
  try {
    const { data } = await mainAxios.post(`/connect/activateIntegratetion`, {
      path: integrationServer,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const triggerRpcService = async (
  connectionObj,
  triggerArr,
  moduleParams,
  parameters
) => {
  try {
    const { data } = await mainAxios.post(`/connect/triggerRpc`, {
      connectionObj,
      triggerArr,
      moduleParams,
      parameters,
      path: `${integrationServer}/appModules/modules/triggerRpc`,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

// subscriptions

export const getPackagesService = async () => {
  try {
    const { data } = await mainAxios.get(`/subscription/getPackages`);
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const getPackageCheckoutService = async (packageId, domain) => {
  try {
    const { data } = await mainAxios.post(`/subscription/getPackageCheckout`, {
      packageId,
      domain,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const getPaymentDetailsService = async () => {
  try {
    const { data } = await mainAxios.get(`/subscription/getPaymentDetails`);
    return data;
  } catch (err) {
    console.log(err.message);
    return {};
  }
};

export const getPaymentCheckoutService = async (domain) => {
  try {
    const { data } = await mainAxios.post(`/subscription/getPaymentCheckout`, {
      domain,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const changeSubscriptionPlanService = async (planId) => {
  try {
    const { data } = await mainAxios.post(
      `/subscription/changeSubscriptionPlan`,
      {
        planId,
      }
    );
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const getTriggerFullDetailsService = async (triggerId) => {
  try {
    const { data } = await mainAxios.post(`/connect/getTriggerFullDetails`, {
      triggerId,
      path: integrationServer,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const updateTriggerNameService = async (
  triggerId,
  triggerName,
  quizId
) => {
  try {
    const { data } = await mainAxios.post(`/connect/updateTriggerName`, {
      triggerId,
      triggerName,
      quizId,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const deleteTriggerService = async (quizId, triggerId) => {
  try {
    const { data } = await mainAxios.post(`/connect/deleteTrigger`, {
      quizId,
      triggerId,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const sendAnalitixService = async (downloadLocation) => {
  try {
    const { data } = await mainAxios.post(`/images/analitix`, {
      downloadLocation,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const createQuizFromTemplateService = async (templateId, folderId) => {
  try {
    const { data } = await mainAxios.get(
      `/templates/getQuizId/${templateId}?folder=${folderId}`
    );
    return data;
  } catch (err) {
    console.log(err.message);
    return {};
  }
};

export const createQuizFromScratchService = async (
  projectTypeId,
  projectName,
  folderId,
  selectedFeed
) => {
  try {
    const { data } = await mainAxios.get(
      `/templates/getQuizId/${projectTypeId}?projectName=${projectName}&folder=${folderId}&feedId=${selectedFeed}`
    );
    return data;
  } catch (err) {
    console.log(err.message);
    return {};
  }
};

export const createNewThemeService = async (theme) => {
  try {
    const { data } = await mainAxios.post("themes/duplicate", theme);
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const updateUnpublishConditionsService = async (conditions) => {
  try {
    const { data } = await mainAxios.post(
      "quiz/updateUnpublishConditions",
      conditions
    );
    return data;
  } catch (err) {
    console.log(err.message);
    return {};
  }
};

export const getUnpublishConditionsService = async (quizId) => {
  try {
    const { data } = await mainAxios.get(
      `quiz/getUnpublishConditions?qid=${quizId}`
    );
    return data;
  } catch (err) {
    console.log(err.message);
    return {};
  }
};

export const purgeAllDataService = async (quizId) => {
  try {
    const { data } = await mainAxios.post(`quiz/purgeAllData`, { quizId });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};
export const purgeSingleEntryService = async (quizId, guid) => {
  try {
    const { data } = await mainAxios.post(`quiz/purgeSingleEntry`, {
      quizId,
      guid,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const downloadExcellService = async (analyzeSearchDetails) => {
  try {
    const { data } = await mainAxios.post(`quiz/analyze/download`, {
      ...analyzeSearchDetails,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const getStatesByCountryCode = async (countryCode = "") => {
  try {
    const { data } = await mainAxios.get(
      `/countries/states?countryCode=${countryCode}`
    );
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const changeOrgService = async (orgId, authServer) => {
  try {
    const { data } = await mainAxios.post(
      `/auth/changeOrg`,
      {
        orgId,
      },
      { headers: { authpath: authServer } }
    );

    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const sendInviteService = async (emailArray, authServer, websiteUrl) => {
  try {
    const { data } = await mainAxios.post(
      `/auth/sendInvite`,
      { emailArray, websiteUrl },
      { headers: { authpath: authServer } }
    );

    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const getOrgsUsersService = async (authServer) => {
  try {
    const { data } = await mainAxios.get(`/auth/getOrgUsers`, {
      headers: { authpath: authServer },
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};
export const getOrgsFeedsService = async () => {
  try {
    const { data } = await mainAxios.get(`/feeds/getOrgFeeds`);
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};
export const createNewFeedService = async (feedName) => {
  try {
    const { data } = await mainAxios.post(`/feeds/create`, { feedName });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};
export const updateFeedService = async (feedData) => {
  try {
    const { data } = await mainAxios.post(`/feeds/update`, { ...feedData });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};
export const uploadSCVProductsFileService = async (formData, feedId) => {
  try {
    const { data } = await feedsAxios.post(
      `/products/csv?feedId=${feedId}`,
      formData
    );
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};
export const deleteFeedErrorService = async (feedErrorId) => {
  try {
    const { data } = await mainAxios.post(`/feeds/deleteFeedError`, {
      feedErrorId,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};
export const deleteFeedService = async (feedId) => {
  try {
    const { data } = await mainAxios.post(`/feeds/deleteFeed`, {
      feedId,
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const parseInviteService = async (parse, authServer) => {
  try {
    const { data } = await mainAxios.post(
      `/auth/parseInvite`,
      { parse },
      { headers: { authpath: authServer } }
    );
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const createWorkSpaceService = async (wsName, authServer) => {
  try {
    const { data } = await mainAxios.post(
      `/auth/createWorkSpace`,
      { wsName, description: "" },
      { headers: { authpath: authServer } }
    );
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const getWorkSpacesService = async (authServer) => {
  try {
    const { data } = await mainAxios.get(`/auth/getUserWs`, {
      headers: { authpath: authServer },
    });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const getWsQuizesService = async (authPath, wsId) => {
  try {
    const { data } = await mainAxios.post(
      `/quiz/workspacesQuizes`,
      { wsId },
      { headers: { authpath: authPath } }
    );
    return data;
  } catch (err) {
    console.log(err.message);
    return { quizes: [], folders: [] };
  }
};

export const sendWSInviteService = async (
  emailArray,
  authServer,
  websiteUrl,
  wsId
) => {
  try {
    const { data } = await mainAxios.post(
      `/auth/addUsersToWs`,
      { invitations: emailArray, path: websiteUrl, wsId },
      { headers: { authpath: authServer } }
    );

    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const moveQuizToWsService = async (quizId, wsId) => {
  try {
    const { data } = await mainAxios.post(`/quiz/moveToWs`, { quizId, wsId });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};

export const importProductsService = async (feedId) => {
  try {
    const { data } = await mainAxios.post(`/feeds/importProducts`, { feedId });
    return data;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};
