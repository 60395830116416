import React from "react";
import {
  TbTransitionTop,
  TbTransitionRight,
  TbTransitionLeft,
  TbTransitionBottom,
} from "react-icons/tb";
import ReactSlider from "rc-slider";

function Transitions(props) {
  const {
    useTransitions,
    transitionDirection,
    transitionDuration,
    handleSlidersTransitions,
  } = props;

  const handleSliderMarks = () => {
    const newMarks = {};
    newMarks[0.1] = {
      style: { color: "black" },
      label: 0.1,
    };
    newMarks[2] = {
      style: { color: "black" },
      label: 2,
    };
    return newMarks;
  };

  const handleSliderDirection = (value) => {
    handleSlidersTransitions({
      target: { name: "transitionDirection", value },
    });
  };
  return (
    <div>
      <div className="addSwitch override">
        <label className="switch">
          <input
            type="checkbox"
            name="useTransitions"
            checked={useTransitions ? true : false}
            onChange={handleSlidersTransitions}
          />
          <span className="slider round"></span>
        </label>
        Use Transitions
      </div>
      {useTransitions && (
        <div>
          <div className="row">
            <div className="col-4">
              <label>Direction:</label>
            </div>
            <div className="col-2">
              <a role="button" onClick={() => handleSliderDirection("moveUp")}>
                <TbTransitionTop
                  size={30}
                  color={transitionDirection === "moveUp" ? "black" : "grey"}
                />
              </a>
            </div>
            <div className="col-2">
              <a
                role="button"
                onClick={() => handleSliderDirection("moveDown")}
              >
                <TbTransitionBottom
                  size={30}
                  color={transitionDirection === "moveDown" ? "black" : "grey"}
                />
              </a>
            </div>
            <div className="col-2">
              <a
                role="button"
                onClick={() => handleSliderDirection("moveRight")}
              >
                <TbTransitionRight
                  size={30}
                  color={transitionDirection === "moveRight" ? "black" : "grey"}
                />
              </a>
            </div>
            <div className="col-2">
              <a
                role="button"
                onClick={() => handleSliderDirection("moveLeft")}
              >
                <TbTransitionLeft
                  size={30}
                  color={transitionDirection === "moveLeft" ? "black" : "grey"}
                />
              </a>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-4">
              <label>Duration</label>
            </div>
            <div className="col-8">
              <ReactSlider
                min={0.1}
                max={2}
                step={0.1}
                value={transitionDuration}
                name="transitionDuration"
                trackStyle={{ background: "black" }}
                handleStyle={{ background: "black" }}
                railStyle={{ background: "#abe2fb" }}
                marks={handleSliderMarks()}
                onChange={(value) => {
                  handleSlidersTransitions({
                    target: { name: "transitionDuration", value },
                  });
                }}
              />
            </div>
          </div>
          <br />
          Selected duration: {transitionDuration}
        </div>
      )}
    </div>
  );
}

export default Transitions;
