import React, { useState, useEffect } from "react";

function RightBarTabsContainer(props) {
  const { formTabsObj, defaultTab } = props;
  const [selectedTabName, setSelectedTabName] = useState(null);

  useEffect(() => {
    if (formTabsObj) {
      if (defaultTab) {
        setSelectedTabName(defaultTab);
      } else
        setSelectedTabName(Object.keys(formTabsObj)[0]);
    }
  }, [defaultTab]);



  return (
    <div style={{height:"100%"}}>
      <ul className="nav nav-pills knowyNav">
        {Object.keys(formTabsObj).map((tabName) => {
          return (
            <li className="nav-item flex-fill" key={tabName.replaceAll(" ", "_")}>
              <a

                className={`nav-link  ${selectedTabName == tabName ? "active knowyNav-active" : ""

                  }`}
                aria-current="page"
                onClick={() => {
                  setSelectedTabName(tabName);
                }}
              >
                {tabName}
              </a>
            </li>
          );
        })}
      </ul>
      {formTabsObj[selectedTabName]}
    </div>
  );
}

export default RightBarTabsContainer;
