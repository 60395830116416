import React, { useState } from "react";
import { connect } from "react-redux";
import LeftBar from "./LeftBar";
import RightBar from "./RightBar";
import ToolBar from "./ToolBar";
import NavBar from "./NavBar";
import { array_move } from "../../utils";
import ViewQuestion from "./View/Question/ViewQuestion";
import ViewContact from "./View/ViewContact";
import ViewStep from "./View/ViewStep";
import {
  editQuiz,
  getQuizDetailsById,
  saveLogicsAction,
  resetLogicsAction,
  getIsUserIntegratedAction,
  activateIntegratetionAction,
  changeRightBarView,
  setGtmStatusAction,
  resetQuizDetailsAction,
  setMediaQuery,
  setIsMobilePreview,
  saveProductsLogicsAction,
} from "../../redux/actions";
import Nav from "./Nav";
import Configuration from "./Configuration";
import GoogleFontLoader from "react-google-font";
import AlertMessage from "../AlertMessage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import KnowyLogo from "../../images/knowy-logo-animation.svg";
//import models
import { Question, Option } from "./Models/Question";
import { Contact, Input } from "./Models/Contact";
import { Result } from "./Models/Result";
import { Text } from "./Models/Text";
import Analyze from "./Analyze";
import { withTranslation } from "react-i18next";
import { addStyleSettings } from "../../utils/patchFunctions";
// import ReactShadowRoot from "react-shadow-root";
import { transformCustomCss } from "../../utils";
// import Avatar from "@material-ui/core/Avatar";
import { IoDesktopOutline } from "react-icons/io5";
import { ImMobile } from "react-icons/im";
import ConnecApps from "./Connect";
import { GrUndo, GrRedo } from "react-icons/gr";
import { FaLessThanEqual } from "react-icons/fa";
import handleHighlightService from "../../utils/recoredSessionService";
import ShareSettings from "./Share";

// const { constructableStylesheetsSupported } = ReactShadowRoot;
class CreateQuiz extends React.Component {
  editInterval = setInterval(() => {
    if (this.state.changed) {
      if (!document.hidden) {
        this.props.actions.editQuiz(this.state);
        toast.success("Auto Save successfully!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        this.setState({ changed: false, screenShot: false });
      }
    }
  }, 120000);
  state = {
    title: "Quiz title",
    slug: "",
    favicon: "",
    direction: "",
    published: false,
    viewMode: "desktop",
    mode: "build",
    isEdit: true,
    variables: [
      { name: "UTM_Source", value: "", type: "UTM" },
      { name: "UTM_Medium", value: "", type: "UTM" },
      { name: "UTM_Campaign", value: "", type: "UTM" },
      { name: "UTM_Term", value: "", type: "UTM" },
      { name: "UTM_Content", value: "", type: "UTM" },
    ],
    rightBarActive: "start",
    shareDetails: {
      title: "",
      description: "",
      imageUrl: "",
    },
    fonts: [{ font: "Open Sans" }],
    seo: {
      title: "",
      description: "",
    },
    feedback: {
      showFeedback: false,
      correctMessage: "Correct!",
      incorrectMessage: "Incorrect!",
    },
    customStyle: "",
    customScripts: {},
    header: {
      show: true,
      hideLinks: false,
      background: "",
      links: {},
      logoImageSrc: "",
      size: "30",
      left: 0,
      logoPosition: "left",
      fontFamily: "Open Sans",
      fontWeight: "",
      fontStyle: "",
      fontSize: "",
      textAlign: "center",
      height: "150",
    },
    footer: {
      show: true,
      hideLinks: false,
      background: "",
      links: {},
      logoImageSrc: "",
      size: "30",
      left: 0,
      logoPosition: "left",
      fontFamily: "Open Sans",
      fontWeight: "",
      fontStyle: "",
      fontSize: "15px",
      textAlign: "center",
    },
    settings: {
      pagination: false,
      progressBar: false,
      backgroundColor: "rgb(255,255,255)",
      backgroundStyle: "color",
      backgroundImage: "",
      backgroundSize: "auto",
      backgroundPosition: "auto",
      backgroundColorType: "gradient",
      gradientColors: { color1: "", color2: "" },
      gradientType: "linear-gradient",
      gradientDeg: "45deg",
      selectedGradientType: "grad1",
      backgroundOverlayColor: "rgba(0,0,0,0.1)",
      backgroundOverlayOpacity: "0",
      callToActionTarget: false,
      callToActionLink: "",
      title: {
        color: "rgba(0, 0, 0, 1)",
        fontWeight: "",
        fontSize: "",
        fontType: "",
        fontFamily: "Open Sans",
        fontStyle: "",
        padding: { top: "0", right: "0", bottom: "0", left: "0" },
      },
      subtitle: {
        color: "#000",
        fontWeight: "",
        fontSize: "",
        fontType: "",
        fontFamily: "Open Sans",
        fontStyle: "",
        padding: { top: "0", right: "0", bottom: "0", left: "0" },
      },
      answers: {
        buttonsTextColor: "#000",
        buttonsBackgroundColor: "",
        buttonsBorderSize: "0",
        buttonsBorderRadius: "0",
        buttonsBorderColor: "",
        buttonsHoverTextColor: "",
        buttonsHoverBackgroundColor: "",
        buttonsHoverBorderSize: "0",
        buttonsHoverBorderRadius: "0",
        buttonsHoverBorderColor: "",
        fontSize: "20",
        fontFamily: "Open Sans",
        fontStyle: "",
        fontWeigth: "",
        padding: { top: "0", right: "0", bottom: "0", left: "0" },
        picSize: "200",
        textContainerSize: "150",
      },
      forms: {
        color: "rgba(0, 0, 0, 1)",
        fontWeight: "",
        fontSize: "",
        fontType: "",
        fontFamily: "Open Sans",
        fontStyle: "",
        padding: { top: "0", right: "0", bottom: "0", left: "0" },
        formTextAlign: "center",
        formWidth: "auto",
        minimalStyle: true,
      },
      button: {
        color: "white",
        background: "#000",
        borderSize: "0",
        borderColor: "",
        borderRadius: "0",
        hoverColor: "",
        hoverBackground: "",
        hoverBorderSize: "0",
        hoverBorderColor: "",
        hoverBorderRadius: "0",
        fontWeight: "",
        fontSize: "14",
        fontType: "",
        fontFamily: "Open Sans",
        fontStyle: "",
        padding: { top: "0", right: "0", bottom: "0", left: "0" },
        textAlign: "center",
      },
      backButton: {
        color: "#000",
        background: "rgba(0, 0, 0, 0)",
        borderSize: "0",
        borderColor: "rgba(0, 0, 0, 0)",
        borderRadius: "0",
        hoverColor: "",
        hoverBackground: "",
        hoverBorderSize: "0",
        hoverBorderColor: "",
        hoverBorderRadius: "0",
        fontWeight: "",
        fontSize: "14",
        fontType: "",
        fontFamily: "Open Sans",
        fontStyle: "",
        padding: { top: "0", right: "0", bottom: "0", left: "0" },
        textAlign: "center",
        buttonText: "<<",
      },
      alert: {
        fontSize: "14",
        fontFamily: "Open Sans",
        textAlign: "center",
        width: "auto",
        correct: {
          text: "Correct!",
          color: "#fff",
          background: "rgba(0,0,0,0.5)",

          borderColor: "black",
          borderSize: "0",
        },
        incorrect: {
          text: "Incorrect",
          background: "rgba(0,0,0,0.5)",
          color: "#fff",
          borderColor: "black",
          borderSize: "0",
        },
        alertLength: 2000,
      },
      bar: {
        barType: "",
        fontSize: "14",
        color: "rgba(0, 0, 0, 1)",
        textAlign: "center",
        fontFamily: "Open Sans",
        showPercent: true,
      },
    },
    arrayOfData: [
      {
        id: `Start`,
        type: "start",
        title: "Welcome",
        description: "",
        buttonText: "Start Quiz",
        image: "https://www.freeiconspng.com/uploads/no-image-icon-11.PNG",
        showMedia: false,
        buttonPosition: "center",
        mediaImageSrc: "",
        startWithEnterKey: false,
        style: {
          // title: {
          //   color: "#000",
          //   fontWeight: "",
          //   fontSize: "28",
          //   fontType: "",
          //   fontFamily: "",
          //   fontStyle: "",
          //   textAlign: "center",
          //   padding: { top: "0", right: "0", bottom: "0", left: "0" },
          // },
          subtitle: {
            color: "#000",
            fontWeight: "",
            fontSize: "16",
            fontType: "",
            fontFamily: "",
            fontStyle: "",
            textAlign: "center",
            padding: { top: "0", right: "0", bottom: "0", left: "0" },
          },
          media: {
            size: "25",
            borderSize: "0",
            borderRadius: "0",
            borderColor: "",
            padding: { top: "0", right: "0", bottom: "0", left: "0" },
          },
          button: {
            color: "#000",
            background: "",
            borderSize: "0",
            borderColor: "",
            borderRadius: "0",
            hoverColor: "",
            hoverBackground: "",
            hoverBorderSize: "0",
            hoverBorderColor: "",
            hoverBorderRadius: "0",
            fontWeight: "",
            fontSize: "14",
            fontType: "",
            fontFamily: "",
            fontStyle: "",
            padding: { top: "0", right: "0", bottom: "0", left: "0" },
            textAlign: "center",
          },
          answers: {
            picSize: "200",
            buttonsBorderSize: "0",
            buttonsBorderRadius: "0",
            buttonsBorderColor: "",
            textContainerSize: "150",
          },
          forms: {
            color: "rgba(0, 0, 0, 1)",
            fontWeight: "",
            fontSize: "",
            fontType: "",
            fontFamily: "Open Sans",
            fontStyle: "",
            padding: { top: "0", right: "0", bottom: "0", left: "0" },
            formTextAlign: "center",
            formWidth: "auto",
          },
          title: {
            color: "rgba(0, 0, 0, 1)",
            fontWeight: "",
            fontSize: "",
            fontType: "",
            fontFamily: "Open Sans",
            fontStyle: "",
            padding: { top: "0", right: "0", bottom: "0", left: "0" },
          },

          alert: {
            fontSize: "14",
            fontFamily: "Open Sans",
            textAlign: "center",
            width: "auto",
            correct: {
              text: "Correct!",
              color: "#fff",
              background: "rgba(0,0,0,0.5)",

              borderColor: "black",
              borderSize: "0",
            },
            incorrect: {
              text: "Incorrect",
              background: "rgba(0,0,0,0.5)",
              color: "#fff",
              borderColor: "black",
              borderSize: "0",
            },
          },
        },
      },
    ],
    changed: false,
    selectedTheme: {},
    screenShot: false,
    undoArray: [],
    redoArray: [],
    redirectToEmbed: false,
    duplications: {
      noDuplicates: false,
      duplicationsTitle: "",
      duplicationsBody: "",
    },
  };

  componentDidMount() {
    window.addEventListener("keydown", this.moveStepsWithKeys);
    // document.title = "Create quiz";

    const { quizId, wsId } = this.props.match.params;
    this.props.actions.getQuizDetailsById(quizId, wsId);
    toast.configure({
      pauseOnFocusLoss: false,
    });
    this.props.actions.getIsUserIntegrated();

    if (
      this.props.gtmStatus.page &&
      !["editor", "connect", "analyze", "config"].includes(
        this.props.gtmStatus.page
      )
    ) {
      this.props.actions.setGtmStatus({
        page: "editor",
        userId: this.props.userId,
        events: null,
      });
      handleHighlightService(this.props.userId, this.props.userName);
    }
  }

  componentDidUpdate(previousProps, previousState) {
    if (
      !this.props.selectedDetails ||
      typeof this.props.selectedDetails.status === undefined
    )
      return;
    if (this.props.selectedDetails.status === false) {
      return this.props.history.push("/dashboard");
    }
    if (!this.props.selectedDetails.quizDetails) return;
    let { id, arrayOfData = [] } = this.props.selectedDetails.quizDetails;

    if (id !== previousState.id) {
      if (!arrayOfData[0].slideName) {
        arrayOfData = arrayOfData.map((step, index) => {
          step.slideName = step.title.replace(/<[^>]+>/g, "");
          if (!step.slideName) step.slideName = `Slide ${index + 1}`;
          return step;
        });
      }
      let { transitions } = this.props.selectedDetails.quizDetails;
      if (!transitions) {
        transitions = {
          useTransitions: false,
          transitionDirection: "moveUp",
          transitionDuration: 0.6,
        };
      }
      this.setState({
        transitions,
        ...this.props.selectedDetails.quizDetails,
        changed: false,
        screenShot: false,
        onEdit: arrayOfData[0] || {},
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.moveStepsWithKeys);
    clearInterval(this.editInterval);

    this.props.actions.resetQuizDetails();
  }

  moveStepsWithKeys = (e) => {
    return; //Todo: disable when writing.
    const key = e.which;
    const selectedIndex = this.state.arrayOfData.findIndex(
      (step) => step.id === this.state.onEdit.id
    );
    if (key == 107) {
      if (selectedIndex < this.state.arrayOfData.length - 1) {
        this.changeTheOnEditObject(
          this.state.arrayOfData[selectedIndex + 1].id
        );
        this.props.actions.changeRightBarView("pageLayout", "", "customize");
      }
    } else if (key == 109) {
      if (selectedIndex > 0) {
        this.changeTheOnEditObject(
          this.state.arrayOfData[selectedIndex - 1].id
        );
        this.props.actions.changeRightBarView("pageLayout", "", "customize");
      }
      this.props.actions.changeRightBarView("pageLayout", "", "customize");
    } else if (key == 90 && e.ctrlKey) {
      // alert("Ctrl+z");
      this.handleUndoRedo("undo");
    } else if (key == 89 && e.ctrlKey) {
      // alert("Ctrl+y");
      this.handleUndoRedo("redo");
    }
  };

  saveEditedQuiz = () => {
    this.setState({ changed: false });
    this.props.actions.editQuiz(this.state);
  };

  changeTheme = (selectedTheme, redoUndo = false) => {
    const valueBefore = JSON.parse(JSON.stringify(this.state.selectedTheme));
    const valueNew = JSON.parse(JSON.stringify(selectedTheme));

    if (!redoUndo && JSON.stringify(valueNew) !== JSON.stringify(valueBefore)) {
      const undoObj = { func: "changeTheme" };
      undoObj.before = valueBefore;
      undoObj.after = valueNew;
      const temp = this.updateUndoRedoData(undoObj);
      return this.setState({
        changed: true,
        settings: selectedTheme.theme_data,
        screenShot: true,
        selectedTheme: valueNew,
        undoArray: temp,
        redoArray: [],
      });
    }
    this.setState({
      changed: true,
      settings: selectedTheme.theme_data,
      screenShot: true,
      selectedTheme,
    });
  };

  handleSaveVariables = (variables) => {
    this.setState({ changed: false, variables });
    this.props.actions.editQuiz({ ...this.state, variables });
  };

  handleCustomStyle = (e) => {
    const { name, value } = e.target;
    this.setState({ changed: true, screenShot: true, [name]: value });
  };

  createScript = (script) => {
    let { customScripts } = this.state;
    if (!customScripts) customScripts = {};
    customScripts[script.id] = script;
    this.setState({ changed: true, screenShot: true, customScripts });
  };

  updateScript = (e, scriptId) => {
    const { customScripts } = this.state;
    let { value, name, checked } = e.target;
    value = name === "active" ? checked : value;
    customScripts[scriptId][name] = value;
    this.setState({ changed: true, screenShot: true, customScripts });
  };

  deleteScript = (newCustomScripts) => {
    this.setState({
      changed: true,
      screenShot: true,
      customScripts: newCustomScripts,
    });
    this.props.actions.editQuiz({
      ...this.state,
      customScripts: newCustomScripts,
    });
  };

  changeSeoDetails = (e) => {
    const { name, value } = e.target;
    const { seo } = this.state;
    this.setState({ changed: true, seo: { ...seo, [name]: value } });
  };

  changeDuplicationsDetiles = (e) => {
    const { name, value } = e.target;
    const { duplications } = this.state;
    this.setState({
      changed: true,
      duplications: { ...duplications, [name]: value },
    });
  };

  handleSlidersTransitions = (e) => {
    const { transitions = {} } = this.state;
    let { value, name, checked, type } = e.target;
    if (type === "checkbox") value = checked;

    transitions[name] = value;
    this.setState({ transitions, changed: true });
  };

  changeNavs = (e, nav, id) => {
    const { links, fontFamily: oldFont } = this.state[nav];
    let { name, value, checked } = e.target;
    if (name === "show") {
      return this.setState({
        changed: true,
        screenShot: true,
        [nav]: { ...this.state[nav], [name]: checked },
      });
    } else if (name === "fontFamily") {
      const { settings, arrayOfData } = this.state;
      const fonts = this.handleFontsToLoad(
        oldFont,
        value,
        arrayOfData,
        settings
      );
      return this.setState({
        changed: true,
        screenShot: true,
        fonts,
        [nav]: { ...this.state[nav], fontFamily: value },
      });
    } else {
      if (id) {
        links[id][name] = value;
        return this.setState({
          changed: true,
          screenShot: true,
          [nav]: { ...this.state[nav], links },
        });
      } else
        return this.setState({
          changed: true,
          screenShot: true,
          [nav]: { ...this.state[nav], [name]: value },
        });
    }
  };

  addLinkToNav = (e, nav) => {
    const { links } = this.state[nav];
    if (!links) return;
    const id = `Link${Math.random() * 999999}`;
    const newLink = { id, title: "", link: "" };
    links[id] = newLink;
    this.setState({
      changed: true,
      screenShot: true,
      [nav]: { ...this.state[nav], links },
    });
  };

  removeLinkFromNav = (e, nav, id) => {
    const { links } = this.state[nav];
    if (!links) return;
    delete links[id];
    this.setState({
      changed: true,
      screenShot: true,
      [nav]: { ...this.state[nav], links },
    });
  };

  handleQuestionStyle = (
    e,
    questionId,
    toChange,
    paddingPosition,
    redoUndo = false
  ) => {
    let { arrayOfData, settings, onEdit } = this.state;

    const { name, value } = e.target;

    const questionIndex = arrayOfData.findIndex(
      (step) => step.id === questionId
    );
    let valueBefore;
    const selectedStep = arrayOfData[questionIndex];
    const undoObj = { func: "handleQuestionStyle" };
    const tempAfter = JSON.parse(
      JSON.stringify({
        e: { target: { name, value } },
        questionId,
        toChange,
        paddingPosition,
      })
    );
    const tempBefore = JSON.parse(
      JSON.stringify({
        e: { target: { name, value } },
        questionId,
        toChange,
        paddingPosition,
      })
    );

    if (paddingPosition) {
      valueBefore = selectedStep.style[toChange][name][paddingPosition];
      arrayOfData[questionIndex].style[toChange][name][paddingPosition] = value;

      if (!redoUndo && value !== valueBefore) {
        tempBefore.e.target.value = valueBefore;
        tempAfter.e.target.value = value;
        undoObj.before = tempBefore;
        undoObj.after = tempAfter;
        const temp = this.updateUndoRedoData(undoObj);
        return this.setState({
          changed: true,
          arrayOfData,
          undoArray: temp,
          redoArray: [],
        });
      }
      onEdit = selectedStep;
      onEdit[name] = value;
      return this.setState({ changed: true, arrayOfData });
    }

    if (name === "fontFamily") {
      const oldFont = arrayOfData[questionIndex].style[toChange][name];
      arrayOfData[questionIndex].style[toChange][name] = value;
      const updatedFonts = this.handleFontsToLoad(
        oldFont,
        value,
        arrayOfData,
        settings
      );

      if (!redoUndo && value !== oldFont) {
        tempBefore.e.target.value = oldFont;
        tempAfter.e.target.value = value;
        undoObj.before = tempBefore;
        undoObj.after = tempAfter;
        const temp = this.updateUndoRedoData(undoObj);
        return this.setState({
          changed: true,
          arrayOfData,
          fonts: updatedFonts,
          undoArray: temp,
          redoArray: [],
        });
      }
      onEdit = selectedStep;
      onEdit[name] = value;

      return this.setState({
        changed: true,
        arrayOfData,
        fonts: updatedFonts,
        onEdit,
      });
    } else {
      valueBefore = selectedStep.style[toChange][name];
      arrayOfData[questionIndex].style[toChange][name] = value;
      if (!redoUndo && value !== valueBefore) {
        tempBefore.e.target.value = valueBefore;
        tempAfter.e.target.value = value;
        undoObj.before = tempBefore;
        undoObj.after = tempAfter;
        const temp = this.updateUndoRedoData(undoObj);
        return this.setState({
          changed: true,
          arrayOfData,
          undoArray: temp,
          redoArray: [],
        });
      }
      onEdit = selectedStep;
      onEdit[name] = value;
      this.setState({ changed: true, arrayOfData, onEdit });
    }
  };

  changeMainStyle = (e, toChange, handler, redoUndo = false) => {
    const { name, value } = e.target;
    let { arrayOfData } = this.state;
    let settings = Object.assign(this.state.settings);
    let valueBefore;
    if (!settings[toChange]) {
      settings = addStyleSettings(settings, toChange);
    }
    if (!settings[toChange]) return;

    if (handler) {
      valueBefore = settings[toChange][name][handler];
      settings[toChange][name][handler] = value;
    } else if (name === "fontFamily") {
      const oldFont = settings[toChange][name];
      const fonts = this.handleFontsToLoad(
        oldFont,
        value,
        arrayOfData,
        settings
      );
      // settings[toChange][name] = value;
      valueBefore = settings[toChange][name];
      settings[toChange] = { ...settings[toChange], [name]: value };
      return this.setState({
        changed: true,
        screenShot: true,
        settings,
        fonts,
      });
    } else {
      valueBefore = settings[toChange][name];
      settings[toChange] = { ...settings[toChange], [name]: value };
      // settings[toChange][name] = value;
    }

    if (!redoUndo && value !== valueBefore) {
      const undoObj = { func: "changeMainStyle" };
      const tempAfter = Object.assign({}, { e, toChange, handler });
      const tempBefore = Object.assign({}, { e, toChange, handler });
      tempBefore.e.target.value = valueBefore;
      tempAfter.e.target.value = value;
      undoObj.before = tempBefore;
      undoObj.after = tempAfter;
      const temp = this.updateUndoRedoData(undoObj);
      return this.setState({
        changed: true,
        screenShot: true,
        settings,
        undoArray: temp,
        redoArray: [],
      });
    }

    this.setState({ changed: true, screenShot: true, settings });
  };

  handleFontsToLoad = (oldFont, newFont, arrayOfData, settings) => {
    const { fonts } = this.state;
    const oldInUse = arrayOfData.find((step) => {
      if (step.type === "question") {
        const { title, answers, subtitle } = step.style;
        if (
          title.fontFamily === oldFont ||
          subtitle.fontFamily === oldFont ||
          answers.fontFamily === oldFont
        ) {
          return true;
        }
      }
    });
    if (!oldInUse) {
      const { title, subtitle, answers } = settings;
      if (
        title.fontFamily !== oldFont ||
        subtitle.fontFamily !== oldFont ||
        answers.fontFamily !== oldFont
      ) {
        const oldFontIndex = fonts.findIndex((step) => step.font === oldFont);
        fonts.splice(oldFontIndex, 1);
      }
    }
    const exists = fonts.find((itr) => itr.font === newFont);
    if (!exists) fonts.push({ font: newFont });
    return fonts;
  };

  changeMode = (mode, redirectToEmbed = false) => {
    if (mode === "connect") {
      if (this.props.gtmStatus.page && this.props.gtmStatus.page !== "connect")
        this.props.actions.setGtmStatus({
          page: "connect",
          userId: this.props.userId,
          events: null,
        });
    } else if (mode === "configure") {
      if (this.props.gtmStatus.page && this.props.gtmStatus.page !== "config")
        this.props.actions.setGtmStatus({
          page: "config",
          userId: this.props.userId,
          events: null,
        });
    } else if (mode === "analyze") {
      if (this.props.gtmStatus.page && this.props.gtmStatus.page !== "analyze")
        this.props.actions.setGtmStatus({
          page: "analyze",
          userId: this.props.userId,
          events: null,
        });
    } else {
      if (this.props.gtmStatus.page && this.props.gtmStatus.page !== "editor")
        this.props.actions.setGtmStatus({
          page: "editor",
          userId: this.props.userId,
          events: null,
        });
    }
    this.setState({ mode, redirectToEmbed });
  };

  changeLogoSettings = (e) => {
    let { name, value, checked } = e.target;
    const { logo } = this.state;
    if (name === "show") {
      value = checked;
      return this.setState({
        changed: true,
        screenShot: true,
        logo: { ...logo, [name]: value },
      });
    }
    switch (value) {
      case "left-top": {
        return this.setState({
          changed: true,
          screenShot: true,
          logo: {
            ...logo,
            left: "0%",
            right: "auto",
            top: "0%",
            bottom: "auto",
            logoPosition: value,
          },
        });
      }
      case "center-top": {
        return this.setState({
          changed: true,
          screenShot: true,
          logo: {
            ...logo,
            left: "auto",
            right: "auto",
            top: "0%",
            bottom: "auto",
            logoPosition: value,
          },
        });
      }
      case "right-top": {
        return this.setState({
          changed: true,
          screenShot: true,
          logo: {
            ...logo,
            left: "auto",
            right: "0%",
            top: "0%",
            bottom: "auto",
            logoPosition: value,
          },
        });
      }
      case "left-bottom": {
        return this.setState({
          changed: true,
          screenShot: true,
          logo: {
            ...logo,
            left: "0%",
            right: "auto",
            top: "auto",
            bottom: "0%",
            logoPosition: value,
          },
        });
      }
      case "center-bottom": {
        return this.setState({
          changed: true,
          screenShot: true,
          logo: {
            ...logo,
            left: "auto",
            right: "auto",
            top: "auto",
            bottom: "0%",
            logoPosition: value,
          },
        });
      }
      case "right-bottom": {
        return this.setState({
          changed: true,
          screenShot: true,
          logo: {
            ...logo,
            left: "auto",
            right: "0%",
            top: "auto",
            bottom: "0%",
            logoPosition: value,
          },
        });
      }
      default: {
        this.setState({
          changed: true,
          screenShot: true,
          logo: { ...logo, [name]: value },
        });
      }
    }
  };

  changeSlug = (e) => {
    const { name, value } = e.target;
    this.setState({ changed: true, [name]: value });
  };

  changeBackgroudnColorType = (backgroundColorType) => {
    const { settings } = this.state;
    this.setState({
      changed: true,
      screenShot: true,
      settings: { ...settings, backgroundColorType },
    });
  };

  changeTitle = (value) => {
    this.setState({ changed: true, ["title"]: value });
  };

  changeViewMode = (value) => {
    this.setState({ viewMode: value });
  };

  changeConfigureDetails = (e) => {
    const { name, value } = e.target;
    if (value || value === "" || name === "removeBranding") {
      this.setState({ [name]: value });
    }
  };

  changeQuizDetailsToEdit = () => {
    this.changeStep("start");
  };

  addRowToMatrix = (stepId) => {
    const { arrayOfData } = this.state;
    const stepIndex = arrayOfData.findIndex((itr) => itr.id == stepId);
    if (stepIndex == -1) return;
    let { columns = [] } = arrayOfData[stepIndex].matrix;
    columns = columns.map((column) => {
      return { id: column.id };
    });
    const newRow = {
      id: `M_R_${Math.floor(Math.random() * 999999)}`,
      value: "",
      columns,
    };
    arrayOfData[stepIndex].matrix.rows.push(newRow);
    this.setState({ changed: true, arrayOfData });
  };

  addColumnToMatrix = (stepId) => {
    const { arrayOfData } = this.state;
    const stepIndex = arrayOfData.findIndex((itr) => itr.id == stepId);
    if (stepIndex == -1) return;
    const newColumn = {
      id: `M_C_${Math.floor(Math.random() * 999999)}`,
      value: "",
    };
    arrayOfData[stepIndex].matrix.columns.push(newColumn);
    let { columns = [], rows = [] } = arrayOfData[stepIndex].matrix;
    columns = columns.map((column) => {
      return { id: column.id };
    });
    arrayOfData[stepIndex].matrix.rows = rows.map((row) => {
      row.columns = columns;
      return row;
    });
    this.setState({ changed: true, arrayOfData });
  };

  handleChangeMatrixData = (e, stepId, itemIndex, section) => {
    const { name, value } = e.target;
    const { arrayOfData } = this.state;
    const stepIndex = arrayOfData.findIndex((itr) => itr.id == stepId);
    if (stepIndex == -1) return;
    arrayOfData[stepIndex].matrix[section][itemIndex][name] = value;
    if (section == "columns") {
      let { columns = [], rows = [] } = arrayOfData[stepIndex].matrix;
      columns = columns.map((column) => {
        return { id: column.id };
      });
      arrayOfData[stepIndex].matrix.rows = rows.map((row) => {
        row.columns = columns;
        return row;
      });
    }
    this.setState({ changed: true, arrayOfData });
  };

  deleteMatrixItem = (stepId, itemIndex, section) => {
    const { arrayOfData } = this.state;
    const stepIndex = arrayOfData.findIndex((itr) => itr.id == stepId);
    if (stepIndex == -1) return;
    arrayOfData[stepIndex].matrix[section].splice(itemIndex, 1);
    if (section == "columns") {
      let { columns = [], rows = [] } = arrayOfData[stepIndex].matrix;
      columns = columns.map((column) => {
        return { id: column.id };
      });
      arrayOfData[stepIndex].matrix.rows = rows.map((row) => {
        row.columns = columns;
        return row;
      });
    }
    this.setState({ changed: true, arrayOfData });
  };

  handleMatrixConfig = (e, stepId) => {
    const { arrayOfData } = this.state;
    const stepIndex = arrayOfData.findIndex((itr) => itr.id == stepId);
    if (stepIndex == -1) return;
    let { name, value, type, checked } = e.target;
    if (type == "checkbox") value = checked;
    arrayOfData[stepIndex].matrix[name] = value;
    this.setState({ changed: true, arrayOfData });
  };

  addOptionToQuestion = (id, currentAnswerIndex, isOtherOption = false) => {
    const { arrayOfData } = this.state;
    const index = arrayOfData.findIndex((step) => step.id === id);
    const optionNumber = arrayOfData[index].options.length + 1;
    const newOption = new Option(`option${optionNumber}`, false);
    if (isOtherOption) {
      newOption.isOtherOption = isOtherOption;
      newOption.title = "Other";
    }
    arrayOfData[index].options.push(newOption);
    arrayOfData[index].options = array_move(
      arrayOfData[index].options,
      arrayOfData[index].options.length - 1,
      currentAnswerIndex + 1
    );
    this.setState({ changed: true, arrayOfData, onEdit: arrayOfData[index] });
  };

  handleChangeSliderData = (e, stepId) => {
    const { name, value } = e.target;
    const { arrayOfData } = this.state;
    const stepIndex = arrayOfData.findIndex((itr) => itr.id == stepId);
    if (stepIndex == -1) return;
    arrayOfData[stepIndex].slider = {
      ...arrayOfData[stepIndex].slider,
      [name]: value,
    };
    this.setState({ changed: true, arrayOfData });
  };

  handleChange = (e, id, redoUndo = false) => {
    let { arrayOfData, onEdit } = this.state;
    let { name, value, type, checked } = e.target;
    let selectedStep;
    let valueBefore;
    arrayOfData = arrayOfData.map((step) => {
      value = type === "number" ? parseInt(value) : value;
      value = type === "checkbox" ? checked : value;
      if (step.id === id) {
        if (name === "isOtherOption" && step.withOtherOption) return step;
        valueBefore = step[name];
        step[name] = value;
        if (name === "slideName") step.created = false;
        selectedStep = step;
        if (name === "withOtherOption") {
          step.isOtherOption = value;
          const otherOptionIndex = step.options.findIndex(
            (option) => option.isOtherOption
          );
          if (value) {
            if (otherOptionIndex !== -1) {
              step.options[otherOptionIndex].hide = false;
            } else {
              this.addOptionToQuestion(id, step.options.length - 1, true);
            }
          } else {
            step.customOtherTextRequired = false;
            if (otherOptionIndex !== -1) {
              step.options[otherOptionIndex].hide = true;
            }
          }
        }
      }

      return step;
    });
    if (!redoUndo && value !== valueBefore) {
      const undoObj = { id, func: "handleChange" };

      const tempAfter = Object.assign(
        {},
        { target: { name, value, type, checked } }
      );
      const tempBefore = Object.assign(
        {},
        { target: { name, value, type, checked } }
      );
      // const tempAfter = JSON.parse(JSON.stringify(e))
      // const tempBefore = JSON.parse(JSON.stringify(e))
      if (tempBefore.target.type === "checkbox") {
        tempBefore.target.checked = !tempBefore.target.checked;
      } else {
        tempBefore.target.value = valueBefore;
        tempAfter.target.value = value;
      }
      undoObj.before = tempBefore;
      undoObj.after = tempAfter;
      const temp = this.updateUndoRedoData(undoObj);
      return this.setState({
        changed: true,
        arrayOfData,
        undoArray: temp,
        redoArray: [],
        screenShot: true,
      });
    }
    onEdit = selectedStep;
    onEdit[name] = value;
    if (name === "slideName") onEdit.created = false;
    this.setState({ changed: true, arrayOfData, onEdit, screenShot: true });
  };

  updateUndoRedoData = (obj) => {
    let { undoArray = [] } = this.state;
    if (undoArray.length > 20) {
      undoArray = undoArray.slice(1);
    }
    if (
      undoArray.length &&
      (obj.func === "handleQuestionStyle" || obj.func === "changeTheme")
    ) {
      const lastObj = undoArray[undoArray.length - 1];

      if (
        !obj.func === "changeTheme" &&
        lastObj.after.toChange === obj.after.toChange &&
        lastObj.after.e.target.name === obj.after.e.target.name &&
        parseInt(lastObj.after.e.target.value) != NaN
      ) {
        lastObj.after.e.target.value = obj.after.e.target.value;
        return undoArray;
      }
    }

    undoArray.push(obj);

    return undoArray;
  };

  handleUndoRedo = (actionType) => {
    let { arrayOfData, undoArray, redoArray } = this.state;

    let tempRedo = redoArray;
    let tempUndo = undoArray;
    if (actionType === "undo" && undoArray.length > 0) {
      const lastData = tempUndo.pop();
      tempRedo.push(lastData);
      if (tempRedo.length > 20) {
        tempRedo = tempRedo.slice(1);
      }

      if (lastData.func === "handleChange")
        this.handleChange(lastData.before, lastData.id, true);
      if (lastData.func === "changeMainStyle")
        this.changeMainStyle(
          lastData.before.e,
          lastData.before.toChange,
          lastData.before.handler,
          true
        );
      if (lastData.func === "handleQuestionStyle")
        this.handleQuestionStyle(
          lastData.before.e,
          lastData.before.questionId,
          lastData.before.toChange,
          lastData.before.paddingPosition,
          true
        );
      if (lastData.func === "handleContactInputs")
        this.handleContactInputs(
          lastData.before.e,
          lastData.before.inputId,
          lastData.before.contactId,
          true
        );
      if (lastData.func === "handleContactLocationInputs")
        this.handleContactLocationInputs(
          lastData.before.e,
          lastData.before.locationId,
          lastData.before.inputId,
          lastData.before.contactId,
          true
        );
      if (lastData.func === "changeQuestionsOptionsDetails")
        this.changeQuestionsOptionsDetails(
          lastData.before.e,
          lastData.before.index,
          lastData.before.id,
          true
        );
      if (lastData.func === "changeTheme")
        this.changeTheme(lastData.before, true);

      this.setState({
        changed: true,
        arrayOfData,
        undoArray: tempUndo,
        redoArray: tempRedo,
      });
    } else if (actionType === "redo" && redoArray.length > 0) {
      const lastData = tempRedo.pop();
      tempUndo.push(lastData);
      if (tempUndo.length > 20) {
        tempUndo = tempUndo.slice(1);
      }

      if (lastData.func === "handleChange")
        this.handleChange(lastData.after, lastData.id, true);
      if (lastData.func === "changeMainStyle")
        this.changeMainStyle(
          lastData.after.e,
          lastData.after.toChange,
          lastData.after.handler,
          true
        );
      if (lastData.func === "handleQuestionStyle")
        this.handleQuestionStyle(
          lastData.after.e,
          lastData.after.questionId,
          lastData.after.toChange,
          lastData.after.paddingPosition,
          true
        );
      if (lastData.func === "handleContactInputs")
        this.handleContactInputs(
          lastData.after.e,
          lastData.after.inputId,
          lastData.after.contactId,
          true
        );
      if (lastData.func === "handleContactLocationInputs")
        this.handleContactLocationInputs(
          lastData.after.e,
          lastData.after.locationId,
          lastData.after.inputId,
          lastData.after.contactId,
          true
        );
      if (lastData.func === "changeQuestionsOptionsDetails")
        this.changeQuestionsOptionsDetails(
          lastData.after.e,
          lastData.after.index,
          lastData.after.id,
          true
        );
      if (lastData.func === "changeTheme")
        this.changeTheme(lastData.after, true);
      this.setState({
        changed: true,
        arrayOfData,
        undoArray: tempUndo,
        redoArray: tempRedo,
      });
    }
  };

  changeQuestionsOptionsDetails = (e, index, id, redoUndo = false) => {
    let { arrayOfData, onEdit } = this.state;
    const { name, value, type } = e.target;
    const originalIndex = index;

    let valueBefore;
    const selectedStep = arrayOfData.find((step) => step.id === id);
    arrayOfData = arrayOfData.map((step) => {
      if (step.id === id) {
        if (name === "correct") {
          step.options.forEach((opt, i) => {
            if (i === index) {
              valueBefore = opt.correct;

              opt.correct = value;
              // } else {
              //   opt.correct = !value;
            }
          });
        } else {
          valueBefore = step.options[index][name];
          step.options[index][name] = value;
        }
      }

      return step;
    });

    if (!redoUndo && value !== valueBefore) {
      const undoObj = { func: "changeQuestionsOptionsDetails" };
      const tempAfter = JSON.parse(
        JSON.stringify({ e, index: originalIndex, id })
      );
      const tempBefore = JSON.parse(
        JSON.stringify({ e, index: originalIndex, id })
      );
      tempBefore.e.target.value = valueBefore;
      tempAfter.e.target.value = value;
      undoObj.before = tempBefore;
      undoObj.after = tempAfter;
      const temp = this.updateUndoRedoData(undoObj);
      return this.setState({
        changed: true,
        arrayOfData,
        undoArray: temp,
        redoArray: [],
      });
    }
    onEdit = selectedStep ? selectedStep : onEdit;

    this.setState({ changed: true, arrayOfData, onEdit });
  };

  handleContactInputs = (e, inputId, contactId, redoUndo = false) => {
    let { arrayOfData, onEdit } = this.state;
    const { name, checked, value } = e.target;
    let valueBefore;
    const contactIndex = arrayOfData.findIndex((step) => step.id === contactId);
    if (contactIndex === -1) return;

    const selectedStep = arrayOfData[contactIndex];
    const inputIndex = arrayOfData[contactIndex].inputs.findIndex(
      (input) => input.id == inputId
    );
    if (inputIndex === -1) return;
    const checkboxesNames = [
      "required",
      "hideLabel",
      "isTextarea",
      "isTime",
      "hideCalender",
      "defaultChecked",
      "isRtl",
      "isMultiSelect",
      "isAutoComplete",
      "withValidation",
      "isValidationError",
      "hideCountryList",
      "customFileSize",
      "useDefaultValue",
    ];
    if (checkboxesNames.includes(name)) {
      valueBefore = arrayOfData[contactIndex].inputs[inputIndex][name];
      arrayOfData[contactIndex].inputs[inputIndex][name] = checked;
    } else if (name === "type") {
      arrayOfData[contactIndex].inputs[inputIndex].placeholder =
        this.setDefaultInputField(value);
      arrayOfData[contactIndex].inputs[inputIndex].label =
        this.setDefaultInputField(value);
      arrayOfData[contactIndex].inputs[inputIndex].validationType = value;
      valueBefore = arrayOfData[contactIndex].inputs[inputIndex].type;
      arrayOfData[contactIndex].inputs[inputIndex].type = value;

      if (value !== "email" && value !== "phoneNumber" && value !== "number") {
        arrayOfData[contactIndex].inputs[inputIndex].type = "text";
      }
    } else if (name === "validationType") {
      valueBefore = arrayOfData[contactIndex].inputs[inputIndex].type;
      arrayOfData[contactIndex].inputs[inputIndex].type = value;
    } else {
      valueBefore = arrayOfData[contactIndex].inputs[inputIndex][name];
      arrayOfData[contactIndex].inputs[inputIndex][name] = value;
    }
    const newArrayOfData = JSON.parse(JSON.stringify(arrayOfData));

    if (!redoUndo && value !== valueBefore) {
      const undoObj = { func: "handleContactInputs" };

      const tempAfter = JSON.parse(
        JSON.stringify({
          e: { target: { name, checked, value } },
          inputId,
          contactId,
        })
      );
      const tempBefore = JSON.parse(
        JSON.stringify({
          e: { target: { name, checked, value } },
          inputId,
          contactId,
        })
      );

      tempBefore.e.target.value = valueBefore;
      tempAfter.e.target.value = value;
      undoObj.before = tempBefore;
      undoObj.after = tempAfter;
      const temp = this.updateUndoRedoData(undoObj);
      return this.setState({
        changed: true,
        arrayOfData,
        undoArray: temp,
        redoArray: [],
      });
    }
    onEdit = selectedStep;
    this.setState({
      changed: true,
      arrayOfData,
      onEdit,
    });
  };

  handleContactLocationInputs = (
    e,
    locationId,
    inputId,
    contactId,
    redoUndo = false
  ) => {
    let { arrayOfData, onEdit } = this.state;
    const { name, checked, value } = e.target;
    let valueBefore;
    const contactIndex = arrayOfData.findIndex((step) => step.id === contactId);
    if (contactIndex === -1) return;

    const selectedStep = arrayOfData[contactIndex];
    const locationIndex = arrayOfData[contactIndex].inputs.findIndex(
      (location) => location.id == locationId
    );
    if (locationIndex === -1) return;

    const inputIndex = arrayOfData[contactIndex].inputs[
      locationIndex
    ].inputs.findIndex((input) => input.id == inputId);

    if (inputId === -1) return;

    const checkboxesNames = [
      "show",
      "required",
      "limitCountries",
      "useApiCalls",
    ];

    if (checkboxesNames.includes(name)) {
      valueBefore =
        arrayOfData[contactIndex].inputs[locationIndex].inputs[inputIndex][
          name
        ];
      arrayOfData[contactIndex].inputs[locationIndex].inputs[inputIndex][name] =
        checked;
    } else {
      valueBefore =
        arrayOfData[contactIndex].inputs[locationIndex].inputs[inputIndex][
          name
        ];
      arrayOfData[contactIndex].inputs[locationIndex].inputs[inputIndex][name] =
        value;
    }
    const newArrayOfData = JSON.parse(JSON.stringify(arrayOfData));

    if (!redoUndo && value !== valueBefore) {
      const undoObj = { func: "handleContactLocationInputs" };

      const tempAfter = JSON.parse(
        JSON.stringify({
          e: { target: { name, checked, value } },
          locationId,
          inputId,
          contactId,
        })
      );
      const tempBefore = JSON.parse(
        JSON.stringify({
          e: { target: { name, checked, value } },
          locationId,
          inputId,
          contactId,
        })
      );

      tempBefore.e.target.value = valueBefore;
      tempAfter.e.target.value = value;
      undoObj.before = tempBefore;
      undoObj.after = tempAfter;
      const temp = this.updateUndoRedoData(undoObj);
      return this.setState({
        changed: true,
        arrayOfData,
        undoArray: temp,
        redoArray: [],
      });
    }
    onEdit = selectedStep;
    this.setState({
      changed: true,
      arrayOfData,
      onEdit,
    });
  };

  saveProductsLogics = (newProductsLogics) => {
    this.props.actions.saveProductsLogics(
      this.props.match.params.quizId,
      newProductsLogics
    );
  };

  // refactored logic to server
  saveStepLogics = (stepId, newLogics) => {
    const { arrayOfData } = this.state;
    // const stepIndex = arrayOfData.findIndex((step) => step.id == stepId);

    // arrayOfData[stepIndex].logics = newLogics;
    // this.setState({ arrayOfData });
    const editLogicObj = {
      stepId,
      logics: newLogics,
    };
    this.props.actions.saveLogic(this.props.match.params.quizId, editLogicObj);
    // this.props.actions.editQuiz({ ...this.state, arrayOfData });
  };

  setDefaultInputField = (value) => {
    switch (value) {
      case "fullName":
        return "Full Name";
      case "firstName":
        return "First Name";
      case "lastName":
        return "Last Name";
      case "email":
        return "Email";
      case "phoneNumber":
        return "Phone Number";
      case "other":
        return "Other";
    }
  };

  handleSteps = (step, style) => {
    const { type } = step;
    switch (type) {
      case "question": {
        return (
          // <ViewQuestion
          //   data={this.state}
          //   onDraw={step}
          //   direction={this.state.direction}
          //   changeQuestionsOptionsDetails={this.changeQuestionsOptionsDetails}
          //   handleChangeQuestion={this.handleChange}
          //   addOptionToQuestion={this.addOptionToQuestion}
          //   deleteFromArray={this.deleteFromArray}
          //   addRowToMatrix={this.addRowToMatrix}
          //   addColumnToMatrix={this.addColumnToMatrix}
          //   handleChangeMatrixData={this.handleChangeMatrixData}
          //   deleteMatrixItem={this.deleteMatrixItem}
          //   handleChange={this.handleChange}
          //   style={style}
          //   changeTheOnEditObject={this.changeTheOnEditObject}
          //   isEdit={this.state.isEdit}
          //   changeOptionsIndexes={this.changeOptionsIndexes}
          //   deleteOptionFromQuestion={this.deleteOptionFromQuestion}
          // />

          <ViewStep
            data={this.state}
            onDraw={step}
            direction={this.state.direction}
            changeQuestionsOptionsDetails={this.changeQuestionsOptionsDetails}
            handleChangeQuestion={this.handleChange}
            addOptionToQuestion={this.addOptionToQuestion}
            deleteFromArray={this.deleteFromArray}
            addRowToMatrix={this.addRowToMatrix}
            addColumnToMatrix={this.addColumnToMatrix}
            handleChangeMatrixData={this.handleChangeMatrixData}
            deleteMatrixItem={this.deleteMatrixItem}
            handleChange={this.handleChange}
            style={style}
            changeTheOnEditObject={this.changeTheOnEditObject}
            isEdit={this.state.isEdit}
            changeOptionsIndexes={this.changeOptionsIndexes}
            deleteOptionFromQuestion={this.deleteOptionFromQuestion}
            stepType={"question"}
          />
        );
      }
      case "result": {
        return (
          <ViewStep
            data={this.state}
            onDraw={step}
            direction={this.state.direction}
            handleChangeResult={this.handleChange}
            addOptionToQuestion={this.addOptionToQuestion}
            handleChange={this.handleChange}
            style={style}
            isEdit={this.state.isEdit}
            changeTheOnEditObject={this.changeTheOnEditObject}
            stepType={"result"}
          />
        );
      }
      case "contact": {
        return (
          <ViewStep
            data={this.state}
            onDraw={step}
            direction={this.state.direction}
            handleChangeContactDetails={this.handleChange}
            addInputToContact={this.addInputToContact}
            deleteInputFromContact={this.deleteInputFromContact}
            deleteFromArray={this.deleteFromArray}
            handleChange={this.handleChange}
            saveContact={this.props.saveContact}
            style={style}
            changeTheOnEditObject={this.changeTheOnEditObject}
            isEdit={this.state.isEdit}
            stepType={"contact"}
          />
        );
      }
      case "start": {
        return (
          // <ViewStart
          //   data={this.state}
          //   onDraw={step}
          //   direction={this.state.direction}
          //   handleChange={this.handleChange}
          //   style={style}
          //   changeTheOnEditObject={this.changeTheOnEditObject}
          //   isEdit={this.state.isEdit}
          // />
          <ViewStep
            data={this.state}
            onDraw={step}
            direction={this.state.direction}
            handleChangeResult={this.handleChange}
            addOptionToQuestion={this.addOptionToQuestion}
            handleChange={this.handleChange}
            style={style}
            isEdit={this.state.isEdit}
            changeTheOnEditObject={this.changeTheOnEditObject}
            stepType={"start"}
          />
        );
      }
      case "text": {
        return (
          // <ViewStart
          //   data={this.state}
          //   onDraw={step}
          //   direction={this.state.direction}
          //   handleChange={this.handleChange}
          //   style={style}
          //   changeTheOnEditObject={this.changeTheOnEditObject}
          //   isEdit={this.state.isEdit}
          // />
          <ViewStep
            data={this.state}
            onDraw={step}
            direction={this.state.direction}
            handleChangeResult={this.handleChange}
            addOptionToQuestion={this.addOptionToQuestion}
            handleChange={this.handleChange}
            style={style}
            isEdit={this.state.isEdit}
            changeTheOnEditObject={this.changeTheOnEditObject}
            stepType={"text"}
          />
        );
      }
    }
  };

  editBackgroundColors = (e, name) => {
    const { gradientColors } = this.state.settings;
    // const { name, value } = e.target
    // const value = e.hex;
    const { r, g, b, a } = e.rgb;
    const value = `rgba(${r}, ${g}, ${b}, ${a})`;
    gradientColors[name] = value;
    this.setState({
      changed: true,
      screenShot: true,
      settings: { ...this.state.settings, gradientColors },
    });
  };

  handleQuestionFeedback = (e, questionId) => {
    const { arrayOfData } = this.state;
    const { name, value } = e.target;
    const questionIndex = arrayOfData.findIndex(
      (step) => step.id === questionId
    );
    arrayOfData[questionIndex].feedback[name] = value;
    this.setState({ changed: true, arrayOfData });
  };

  handleGradientType = (name) => {
    const { settings } = this.state;
    switch (name) {
      case "grad1":
        return this.setState({
          changed: true,
          screenShot: true,
          settings: {
            ...settings,
            gradientType: "linear-gradient",
            gradientDeg: "45deg",
            selectedGradientType: name,
          },
        });
      case "grad2":
        return this.setState({
          changed: true,
          screenShot: true,
          settings: {
            ...settings,
            gradientType: "radial-gradient",
            gradientDeg: "circle",
            selectedGradientType: name,
          },
        });
      case "grad3":
        return this.setState({
          changed: true,
          screenShot: true,
          settings: {
            ...settings,
            gradientType: "linear-gradient",
            gradientDeg: "180deg",
            selectedGradientType: name,
          },
        });
      case "grad4":
        return this.setState({
          changed: true,
          screenShot: true,
          settings: {
            ...settings,
            gradientType: "linear-gradient",
            gradientDeg: "135deg",
            selectedGradientType: name,
          },
        });

      default:
        break;
    }
  };

  handleChangeResultShare = (e, resultId) => {
    const { arrayOfData } = this.state;
    const { name, checked } = e.target;
    const resultIndex = arrayOfData.findIndex((step) => step.id === resultId);
    arrayOfData[resultIndex].share[name] = checked;
    this.setState({ changed: true, arrayOfData });
  };

  addTextToView = () => {
    let { arrayOfData } = this.state;
    const numberOfTexts = arrayOfData.filter((step) => step.type === "text");
    const counter = numberOfTexts.length + 1;
    const title = `Text${counter}`;
    const onEdit = new Text(title, counter);
    const oldId = this.state.onEdit.id;
    const oldIndex = arrayOfData.findIndex((step) => step.id === oldId);
    onEdit.slideName = title;
    onEdit.created = true;
    arrayOfData.push(onEdit);
    arrayOfData = array_move(arrayOfData, arrayOfData.length - 1, oldIndex + 1);
    arrayOfData.sort((a, b) => {
      return a.stepIndex - b.stepIndex;
    });
    this.setState({
      arrayOfData,
      changed: true,
      onEdit,
      currentStep: "text",
      arrayOfData,
      rightBarActive: "text",
    });
  };

  addQuestionToView = (qt) => {
    let { arrayOfData } = this.state;
    const numberOfQuestions = arrayOfData.filter(
      (step) => step.type === "question"
    );

    const counter = numberOfQuestions.length + 1;
    const title = `Question${counter}`;
    const onEdit = new Question(title, counter);
    const option1 = new Option("option1", true);
    const option2 = new Option("option2", false);
    onEdit.options.push(option1, option2);
    onEdit.questionType = qt;
    onEdit.slideName = title;
    onEdit.created = true;
    onEdit.errorMsg = qt === "matrix" ? "Matrix is required" : "";

    onEdit.errorMsg = qt === "Slider" ? "Slider is required" : "";
    if (qt === "slider") {
      onEdit.slider = {
        min: 1,
        max: 10,
        stepSize: 1,
        defaultValue: 5,
        displayCurrentValue: false,
        isPercentage: true,
        baseColor: "#0046B5",
        selectedColor: "rgb(190, 30, 185)",
      };
    }

    onEdit.withNextButton = qt === "rating" ? true : false;

    const oldId = this.state.onEdit.id;
    const oldIndex = arrayOfData.findIndex((step) => step.id === oldId);

    arrayOfData.push(onEdit);
    arrayOfData = array_move(arrayOfData, arrayOfData.length - 1, oldIndex + 1);

    arrayOfData.sort((a, b) => {
      return a.stepIndex - b.stepIndex;
    });
    this.setState({
      changed: true,
      onEdit,
      currentStep: "question",
      arrayOfData,
      rightBarActive: "question",
    });
  };

  addResultToView = (resultType) => {
    let { arrayOfData } = this.state;
    const numberOfResults = arrayOfData.filter(
      (step) => step.type === "result"
    );
    const counter = numberOfResults.length + 1;
    const title = `Result${counter}`;
    const onEdit = new Result(title, counter);
    onEdit.slideName = title;
    onEdit.resultType = resultType;
    onEdit.created = true;

    arrayOfData.push(onEdit);
    this.setState({
      changed: true,
      onEdit,
      currentStep: "result",
      arrayOfData,
      rightBarActive: "result",
    });
  };

  addContactToView = () => {
    let { arrayOfData } = this.state;
    const numberOfContacts = arrayOfData.filter(
      (step) => step.type === "contact"
    );
    const counter = numberOfContacts.length + 1;
    const title = `Contacts${counter}`;
    const inputId = `I_${Math.random() * 999999}`;
    const onEdit = new Contact(title, counter);
    const inputToEdit = new Input(inputId);
    onEdit.inputs.push(inputToEdit);
    onEdit.slideName = title;
    onEdit.created = true;
    const oldId = this.state.onEdit.id;
    const oldIndex = arrayOfData.findIndex((step) => step.id === oldId);

    arrayOfData.push(onEdit);

    arrayOfData = array_move(arrayOfData, arrayOfData.length - 1, oldIndex + 1);
    arrayOfData.sort((a, b) => {
      return a.stepIndex - b.stepIndex;
    });
    this.setState({
      changed: true,
      onEdit,
      currentStep: "contact",
      arrayOfData,
      rightBarActive: "contact",
    });
  };

  addInputToContact = (inputDetails, contactId) => {
    const { id } = inputDetails;
    const { arrayOfData } = this.state;

    const index = arrayOfData.findIndex((step) => step.id === contactId);

    arrayOfData[index].inputs.push(inputDetails);

    this.setState({ changed: true, arrayOfData });
  };

  addLocationsContainerToContact = (locationDetails, contactId) => {
    const { id } = locationDetails;
    const { arrayOfData } = this.state;

    const index = arrayOfData.findIndex((step) => step.id === contactId);
    arrayOfData[index].locationsInputs =
      arrayOfData[index].locationsInputs || [];
    arrayOfData[index].locationsInputs.push(locationDetails);

    this.setState({ changed: true, arrayOfData });
  };

  deleteInputFromContact = (contactId, inputId) => {
    const { arrayOfData } = this.state;
    const index = arrayOfData.findIndex((step) => step.id === contactId);
    if (index === -1) return;
    const inputIndex = arrayOfData[index].inputs.findIndex(
      (step) => step.id === inputId
    );
    if (index === -1) return;
    arrayOfData[index].inputs.splice(inputIndex, 1);
    this.setState({ changed: true, arrayOfData });
  };

  deleteLocationContainerFromContact = (contactId, locationContainerId) => {
    const { arrayOfData } = this.state;
    const index = arrayOfData.findIndex((step) => step.id === contactId);
    if (index === -1) return;
    const inputIndex = arrayOfData[index].inputs.findIndex(
      (location) => location.id === locationContainerId
    );
    if (inputIndex === -1) return;
    arrayOfData[index].inputs.splice(inputIndex, 1);
    this.setState({ changed: true, arrayOfData });
  };

  deleteFromArray = (id) => {
    let { arrayOfData } = this.state;
    const index = arrayOfData.findIndex((step) => step.id === id);
    arrayOfData.splice(index, 1);
    const onEdit = index ? arrayOfData[index - 1] : arrayOfData[index];
    const rightBarActive = onEdit.type;
    this.setState({ changed: true, arrayOfData, onEdit, rightBarActive });
  };

  deleteOptionFromQuestion = (index, questionId) => {
    const { arrayOfData } = this.state;
    const questionIndex = arrayOfData.findIndex(
      (step) => step.id === questionId
    );
    arrayOfData[questionIndex].options.splice(index, 1);
    this.setState({ changed: true, arrayOfData });
  };

  changeInputIndexes = (index1, index2, questionId) => {
    let { arrayOfData } = this.state;
    const questionIndex = arrayOfData.findIndex(
      (step) => step.id === questionId
    );
    arrayOfData[questionIndex].inputs = array_move(
      arrayOfData[questionIndex].inputs,
      index1,
      index2
    );
    this.setState({ changed: true, arrayOfData });
  };
  changeOptionsIndexes = (index1, index2, questionId) => {
    let { arrayOfData } = this.state;
    const questionIndex = arrayOfData.findIndex(
      (step) => step.id === questionId
    );
    arrayOfData[questionIndex].options = array_move(
      arrayOfData[questionIndex].options,
      index1,
      index2
    );
    const otherOptionIndex = arrayOfData[questionIndex].options.findIndex(
      (option) => option.isOtherOption
    );
    if (otherOptionIndex !== -1) {
      arrayOfData[questionIndex].options.push(
        arrayOfData[questionIndex].options[otherOptionIndex]
      );
      arrayOfData[questionIndex].options.splice(otherOptionIndex, 1);
    }

    this.setState({ changed: true, arrayOfData });
  };

  draw = () => {
    let { arrayOfData, settings, onEdit } = this.state;

    let {
      backgroundColor,
      backgroundImage,
      backgroundSize,
      backgroundPosition,
      backgroundColorType,
      backgroundMediaType,
      backgroundOverlayOpacity,
      backgroundOverlayColor,
      backgroundVideoSrc,
      gradientColors,
      gradientType,
      gradientDeg,
    } = settings;

    const stringOfColors = Object.values(gradientColors).toString();
    let style = {};
    if (backgroundColorType === "gradient") {
      style = {
        background: `${gradientType}(${gradientDeg}, ${stringOfColors})`,
      };
    }
    if (backgroundMediaType === "image" || backgroundColorType === "color") {
      style = {
        backgroundColor,
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize,
        backgroundPosition,
        backgroundOverlayOpacity,
        backgroundOverlayColor,
        backgroundMediaType: "image",
      };
    }
    //todo: we don't need backgroundColorType and backgroundMediaType - we need to change all places from backgroundColorType to backgroundMediaType - but also need to update json
    if (backgroundMediaType == "video") {
      style = {
        backgroundColor,
        backgroundImage: backgroundImage,
        backgroundRepeat: "no-repeat",
        backgroundSize,
        backgroundPosition,
        backgroundOverlayOpacity,
        backgroundOverlayColor,
        backgroundMediaType: "video",
        backgroundVideoSrc: backgroundVideoSrc,
      };
    }

    return arrayOfData
      .filter((step) => onEdit.id == step.id)
      .map((step, index) => {
        return (
          <div
            id={step.id}
            key={step.id}
            className={onEdit.id == step.id ? "active" : "nonActive"}
          >
            {this.handleSteps(step, style)}
          </div>
        );
      });
  };

  changeIndexes = (oldIndex, newIndex) => {
    let { arrayOfData } = this.state;
    arrayOfData = array_move(arrayOfData, oldIndex, newIndex);
    this.setState({
      changed: true,
      arrayOfData: JSON.parse(JSON.stringify(arrayOfData)),
    });
  };

  changeTheOnEditObject = (id) => {
    const { arrayOfData } = this.state;
    if (id === "settingsScreen") {
      const rightBarActive = "styleSettings";
      this.setState({ changed: true, rightBarActive });
    } else {
      const onEdit = arrayOfData.find((step) => step.id === id);
      if (!onEdit) return;
      const rightBarActive = onEdit.type;
      this.setState((prevState) => ({
        changed: true,
        rightBarActive,
        onEdit,
      }));
    }
  };

  duplicateStep = (id) => {
    const { arrayOfData } = this.state;
    let stepToDuplicate = arrayOfData.find((step) => step.id === id);
    let stepToDuplicateIndex = arrayOfData.findIndex((step) => step.id === id);
    if (!stepToDuplicate) return;
    const firstIdLetter = id.charAt(0);
    stepToDuplicate = JSON.stringify(stepToDuplicate);
    stepToDuplicate = JSON.parse(stepToDuplicate);
    stepToDuplicate.id = `${firstIdLetter}_${Math.floor(
      Math.random() * 999999
    )}`;
    stepToDuplicate.title = `Copy_${stepToDuplicate.title}`;
    stepToDuplicate.created = true;
    if (stepToDuplicate.type === "question") {
      stepToDuplicate.options.forEach((element) => {
        element.id = `O_${Math.round(Math.floor(Math.random() * 999999))}`;
        element.logics = {};
      });
    } else if (stepToDuplicate.type === "contact") {
      stepToDuplicate.inputs.forEach((element) => {
        if (element.type === "location") {
          element.inputs.forEach((locationInput) => {
            locationInput.id = `L_I_${Math.round(Math.random() * 999999)}`;
            locationInput.label = `Copy_${locationInput.label}`;
          });
        } else if (
          //If the element is an unique type so we replace it with regular other input;
          (element.type === "email" && element.validationType === "email") ||
          element.type === "phoneNumber" ||
          element.validationType == "firstName" ||
          element.validationType === "lastName"
        ) {
          element.type = "text";
          element.validationType = "other";
        }

        element.id = `O_${Math.round(Math.floor(Math.random() * 999999))}`;
        element.label = `Copy_${element.label}`;
        element.logics = {};
      });
    }

    // arrayOfData.push(stepToDuplicate);
    arrayOfData.splice(stepToDuplicateIndex + 1, 0, stepToDuplicate);
    this.setState({ changed: true, arrayOfData });
  };

  handleShareDetails = (e) => {
    const { shareDetails } = this.state;
    const { name, value } = e.target;
    this.setState({
      changed: true,
      shareDetails: { ...shareDetails, [name]: value },
    });
  };

  updateMainSettings = (e) => {
    const { settings } = this.state;
    let { name, value, checked } = e.target;
    if (name === "pagination" || name === "progressBar")
      settings[name] = checked;
    else settings[name] = value;

    this.setState({ changed: true, settings });
  };

  updateMainFeedbacks = (e) => {
    const { feedback } = this.state;
    let { name, value, checked } = e.target;
    if (name === "showFeedback") {
      value = checked;
    }
    feedback[name] = value;
    this.setState({ changed: true, feedback });
  };

  editQuiz = () => {
    if (!this.state.changed) {
      toast.warning("No changes!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    } else {
      this.props.actions.editQuiz(this.state);
      toast.success("Saved successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      this.setState({ changed: false });
    }
  };

  publishQuiz = (isPublish = 0) => {
    if (isPublish == 1)
      this.props.actions.setGtmStatus({
        page: "editor-publish",
        userId: this.props.userId,
        events: { publish: true },
      });
    this.setState({ changed: false, published: isPublish });
    this.props.actions.editQuiz({ ...this.state, published: isPublish });
  };

  handleModeToRender = () => {
    const { saveQuizMessage, selectedDetails, mode, direction } = this.state;
    switch (mode) {
      case "build":
        return (
          <section className="dashboardContent">
            <LeftBar
              data={this.state}
              addQuestionToView={this.addQuestionToView}
              addTextToView={this.addTextToView}
              changeQuizDetailsToEdit={this.changeQuizDetailsToEdit}
              addResultToView={this.addResultToView}
              addContactToView={this.addContactToView}
              deleteFromArray={this.deleteFromArray}
              changeIndexes={this.changeIndexes}
              changeTheOnEditObject={this.changeTheOnEditObject}
              duplicateStep={this.duplicateStep}
              addconditionToStep={this.addconditionToStep}
              handleStepLogic={this.handleStepLogic}
              removeconditionFromStep={this.removeconditionFromStep}
              handleLogicDetails={this.handleLogicDetails}
              saveStepLogics={this.saveStepLogics}
              resetLogics={this.props.actions.resetLogics}
              handleChange={this.handleChange}
              saveProductsLogics={this.saveProductsLogics}
            />

            <div id={"mainEditor"} className={"mainEditorArea"}>
              {/* <ReactShadowRoot styleSheets={sheet}> */}
              {/* <style type="text/css">{appcss}</style> */}
              {/* <link href="/assets/css/hippoloader.css" rel="stylesheet" /> */}
              <style type="text/css">
                {transformCustomCss(this.state.customStyle, "#mainEditor")}
              </style>

              <span>{saveQuizMessage}</span>

              <div className="toolbar-and-resolution">
                {/*<div>*/}
                {/*  <ToolBar*/}
                {/*    data={this.state}*/}
                {/*    changeQuestionsOptionsDetails={*/}
                {/*      this.changeQuestionsOptionsDetails*/}
                {/*    } //*/}
                {/*    handleChange={this.handleChange}*/}
                {/*    addOptionToQuestion={this.addOptionToQuestion}*/}
                {/*    deleteFromArray={this.deleteFromArray}*/}
                {/*    handleContactInputs={this.handleContactInputs}*/}
                {/*    addInputToContact={this.addInputToContact}*/}
                {/*    deleteInputFromContact={this.deleteInputFromContact}*/}
                {/*    updateMainSettings={this.updateMainSettings}*/}
                {/*    changeLogoSettings={this.changeLogoSettings}*/}
                {/*    editBackgroundColors={this.editBackgroundColors}*/}
                {/*    addLinkToNav={this.addLinkToNav}*/}
                {/*    removeLinkFromNav={this.removeLinkFromNav}*/}
                {/*    changeNavs={this.changeNavs}*/}
                {/*    deleteOptionFromQuestion={this.deleteOptionFromQuestion}*/}
                {/*    changeInputIndexes={this.changeInputIndexes}*/}
                {/*    handleQuestionFeedback={this.handleQuestionFeedback}*/}
                {/*    updateMainFeedbacks={this.updateMainFeedbacks}*/}
                {/*    handleChangeResultShare={this.handleChangeResultShare}*/}
                {/*    handleShareDetails={this.handleShareDetails}*/}
                {/*    handleQuestionStyle={this.handleQuestionStyle}*/}
                {/*    handleGradientType={this.handleGradientType}*/}
                {/*    changeBackgroudnColorType={this.changeBackgroudnColorType}*/}
                {/*    changeMainStyle={this.changeMainStyle}*/}
                {/*    handleMatrixConfig={this.handleMatrixConfig}*/}
                {/*    changeTheme={this.changeTheme}*/}
                {/*    selectedTheme={this.state.selectedTheme}*/}
                {/*  />*/}
                {/*</div>*/}
                <div className="d-flex">
                  <button
                    className="btn btn-link"
                    disabled={!this.state.undoArray.length}
                    onClick={() => this.handleUndoRedo("undo")}
                  >
                    <GrUndo color="grey" size={21} />
                  </button>
                  <button
                    className="btn btn-link"
                    disabled={!this.state.redoArray.length}
                    onClick={() => this.handleUndoRedo("redo")}
                  >
                    {" "}
                    <GrRedo color="grey" size={21} />
                  </button>
                </div>
                <div className="resolution" style={{ display: "flex" }}>
                  {/*<Avatar className={useTitleCustomStyle? classes.editThemeCheck : classes.editTheme}>*/}

                  {/*<Avatar*/}
                  {/*  className={*/}
                  {/*    this.state.viewMode == "mobile"*/}
                  {/*      ? "mobile-circle on"*/}
                  {/*      : "mobile-circle"*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*<MdTune type="checkbox" title={t("rightBar-customize-override")} onClick={(e) => handleChangeQuestion({ target: { name: "useTitleCustomStyle", type : "checkbox", checked: useTitleCustomStyle?false:true  }},id)} color={useTitleCustomStyle?"#78889f":"white"} size={18} />*/}
                  <ImMobile
                    style={{ margin: "10px 10px 0px 0px" }}
                    type="button"
                    onClick={() => {
                      this.changeViewMode("mobile");
                      this.props.actions.setIsMobilePreview(true);
                    }}
                    color={
                      this.state.viewMode == "mobile"
                        ? "#0046B5"
                        : "rgba(0, 70, 181, 0.4)"
                    }
                    size={18}
                  />
                  {/*<EmailIcon />*/}
                  {/*</Avatar>*/}

                  {/*<Avatar*/}
                  {/*  className={*/}
                  {/*    this.state.viewMode == "desktop"*/}
                  {/*      ? "desktop-circle on"*/}
                  {/*      : "desktop-circle"*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*<MdTune type="checkbox" title={t("rightBar-customize-override")} onClick={(e) => handleChangeQuestion({ target: { name: "useTitleCustomStyle", type : "checkbox", checked: useTitleCustomStyle?false:true  }},id)} color={useTitleCustomStyle?"#78889f":"white"} size={18} />*/}
                  <IoDesktopOutline
                    style={{ margin: "10px 10px 0px 0px" }}
                    type="button"
                    onClick={() => {
                      this.changeViewMode("desktop");
                      this.props.actions.setIsMobilePreview(false);
                    }}
                    color={
                      this.state.viewMode == "desktop"
                        ? "#0046B5"
                        : "rgba(0, 70, 181, 0.4)"
                    }
                    size={18}
                  />
                  {/*<EmailIcon />*/}
                  {/*</Avatar>*/}
                </div>
              </div>

              <div
                id={"mainContent"}
                style={{ backgroundColor: "#f5f5f5", height: "100%" }}
              >
                <div
                  id={"mainContentDiv"}
                  className={
                    this.state.viewMode == "desktop"
                      ? `editorContent ${direction}`
                      : `editorContent ${direction} mobile`
                  }
                >
                  {this.draw()}
                </div>
              </div>
              {/* </ReactShadowRoot> */}
            </div>
            <RightBar
              data={this.state}
              changeQuestionsOptionsDetails={this.changeQuestionsOptionsDetails} //
              handleChange={this.handleChange}
              addOptionToQuestion={this.addOptionToQuestion}
              deleteFromArray={this.deleteFromArray}
              handleContactInputs={this.handleContactInputs}
              addInputToContact={this.addInputToContact}
              deleteInputFromContact={this.deleteInputFromContact}
              updateMainSettings={this.updateMainSettings}
              changeLogoSettings={this.changeLogoSettings}
              editBackgroundColors={this.editBackgroundColors}
              addLinkToNav={this.addLinkToNav}
              removeLinkFromNav={this.removeLinkFromNav}
              changeNavs={this.changeNavs}
              deleteOptionFromQuestion={this.deleteOptionFromQuestion}
              changeInputIndexes={this.changeInputIndexes}
              handleQuestionFeedback={this.handleQuestionFeedback}
              updateMainFeedbacks={this.updateMainFeedbacks}
              handleChangeResultShare={this.handleChangeResultShare}
              handleShareDetails={this.handleShareDetails}
              handleQuestionStyle={this.handleQuestionStyle}
              handleGradientType={this.handleGradientType}
              changeBackgroudnColorType={this.changeBackgroudnColorType}
              changeMainStyle={this.changeMainStyle}
              handleMatrixConfig={this.handleMatrixConfig}
              changeTheme={this.changeTheme}
              selectedTheme={this.state.selectedTheme}
              addLocationsContainerToContact={
                this.addLocationsContainerToContact
              }
              deleteLocationContainerFromContact={
                this.deleteLocationContainerFromContact
              }
              handleContactLocationInputs={this.handleContactLocationInputs}
              handleChangeMatrixData={this.handleChangeMatrixData}
              handleChangeSliderData={this.handleChangeSliderData}
              handleSlidersTransitions={this.handleSlidersTransitions}
            />
          </section>
        );
      case "configure": {
        return (
          <div style={{ backgroundColor: "#f5f5f5" }}>
            <Configuration
              data={this.state}
              changeConfigureDetails={this.changeConfigureDetails}
              changeSeoDetails={this.changeSeoDetails}
              handleCustomStyle={this.handleCustomStyle}
              createScript={this.createScript}
              updateScript={this.updateScript}
              handleSaveVariables={this.handleSaveVariables}
              saveEditedQuiz={this.saveEditedQuiz}
              toast={toast}
              deleteScript={this.deleteScript}
              publishQuiz={this.publishQuiz}
              changeDuplicationsDetiles={this.changeDuplicationsDetiles}
            />
          </div>
        );
      }
      case "analyze":
        return (
          <div style={{ height: "100%", overflowX: "auto" }}>
            <Analyze data={this.state} />
          </div>
        );
      case "connect":
        return (
          <div style={{ backgroundColor: "#f5f5f5", height: "100%" }}>
            <ConnecApps
              data={this.state}
              isUserIntegrations={this.props.isUserIntegrations}
              activateIntegratetion={this.props.actions.activateIntegratetion}
              userId={this.props.userId}
            />
          </div>
        );
      case "share": {
        return (
          <div style={{ backgroundColor: "#f5f5f5" }}>
            <ShareSettings
              data={this.state}
              changeConfigureDetails={this.changeConfigureDetails}
              changeSeoDetails={this.changeSeoDetails}
              handleCustomStyle={this.handleCustomStyle}
              createScript={this.createScript}
              updateScript={this.updateScript}
              handleSaveVariables={this.handleSaveVariables}
              saveEditedQuiz={this.saveEditedQuiz}
              toast={toast}
              deleteScript={this.deleteScript}
              publishQuiz={this.publishQuiz}
            />
          </div>
        );
      }
      default:
        break;
    }
  };

  render() {
    const { t } = this.props;
    // if (!this.state.id || !this.state.loading)
    if (!this.state.id)
      return (
        <div
          className="h-100 d-flex align-items-center justify-content-center"
          style={{ backgroundColor: "#F2F1FF" }}
        >
          <div style={{ display: "block", width: "400px" }}>
            {/*<KnowyLogo />*/}
            <object type="image/svg+xml" data={KnowyLogo}>
              svg-animation
            </object>

            <div style={{ display: "block", textAlign: "center" }}>
              <h1>DID YOU KNOWY?</h1>
            </div>
            <div style={{ display: "block" }}></div>
            <div
              style={{
                display: "block",
                color: "#264FA2",
                paddingTop: "20px",
                textAlign: "center",
              }}
            >
              Loading...
            </div>
          </div>
        </div>
      );

    return (
      <main
        className={`interface ${
          this.state.mode !== "build" ? "allowoverflow" : ""
        }`}
      >
        <GoogleFontLoader fonts={this.state.fonts} />
        <NavBar
          helpLabel={t("help-center")}
          data={this.state}
          changeTitle={this.changeTitle}
          changeViewMode={this.changeViewMode}
          selectedDetails={this.props.selectedDetails}
          editQuiz={this.editQuiz}
          changeMode={this.changeMode}
          userId={this.props.userId}
          publishQuiz={this.publishQuiz}
          handleUndoRedo={this.handleUndoRedo}
        />
        <div style={{ height: "100%" }}>
          <ToastContainer />
          {this.handleModeToRender()}
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    saveQuizMessage,
    selectedDetails,
    loginDetails,
    accessibility,
    isUserIntegrations,
    gtmStatus,
  } = state;
  const { user_id: userId, name: userName } = loginDetails;
  return {
    saveQuizMessage,
    selectedDetails,
    userId,
    accessibility,
    isUserIntegrations,
    gtmStatus,
    userName,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      editQuiz: (quizDetails) => dispatch(editQuiz(quizDetails)),
      getQuizDetailsById: (quizId, wsId = null) =>
        dispatch(getQuizDetailsById(quizId, wsId)),
      saveLogic: (quizId, logic) => dispatch(saveLogicsAction(quizId, logic)),
      saveProductsLogics: (quizId, logic) =>
        dispatch(saveProductsLogicsAction(quizId, logic)),
      resetLogics: () => dispatch(resetLogicsAction()),
      getIsUserIntegrated: () => dispatch(getIsUserIntegratedAction()),
      activateIntegratetion: (id) => dispatch(activateIntegratetionAction(id)),
      changeRightBarView: (context, subContextId, rightBarViewTab) =>
        dispatch(changeRightBarView(context, subContextId, rightBarViewTab)),
      setGtmStatus: (status) => dispatch(setGtmStatusAction(status)),
      resetQuizDetails: () => dispatch(resetQuizDetailsAction()),
      setIsMobilePreview: (isMobilePreview) =>
        dispatch(setIsMobilePreview(isMobilePreview)),
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CreateQuiz)
);
