import React, { useEffect, useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import { IoTrashOutline } from "react-icons/io5";
import TextInput from "react-autocomplete-input";
import AcTest from "../../../AcTest/Index";

function AddContactSignatureField(props) {
    const {
        step,
        deleteInput,
        id,
        toggleError,
        handleInputName,
        index,
        handleInputVariableName,
        handleContactInputs,
        type,
        dataWithVariables,
    } = props;
    const [inputCollapse, setInputCollapse] = useState(false);
    const [isInputOther, setIsInputOther] = useState(false);

    return (
        <>
            <div className="feildHeader">
                <span
                    className="pointer"
                    onClick={() => setInputCollapse(!inputCollapse)}
                >
                    {handleInputName(step, index)}
                </span>

                <IoTrashOutline
                    type="button"
                    title="rightBar-customize-override"
                    onClick={() => deleteInput(id, step.id)}
                    color={"rgba(0, 68, 179, 0.42)"}
                    size={22}
                />
                {/*<a*/}
                {/*  title=""*/}
                {/*  className="bin"*/}
                {/*  onClick={() => deleteInput(id, step.id)}*/}
                {/*></a>*/}
            </div>
            <Collapse in={inputCollapse}>
                <div className="fieldOptions" id={` item${index}`}>

                    <h4>Label:</h4>
                    {/* <input
            type="text"
            name="label"
            value={step.label}
            placeholder="Name:"
            onBlur={(e) => handleInputVariableName(e, step.id, id)}
            onChange={(e) => props.handleContactInputs(e, step.id, id)}
          /> */}

                    <AcTest
                        changeOnSelect={(trigger, slug) => "{{" + slug + "}}"}
                        trigger={["{{"]}
                        options={{ "{{": [...dataWithVariables] }}
                        type="text"
                        name="label"
                        value={step.label}
                        placeholder="Name:"
                        className="autocomplete-form-input"
                        rows={1}
                        maxOptions={50}
                        onBlur={(e) => handleInputVariableName({ target: { name: "label", value: e } }, step.id, id)}
                        onChange={(e) => props.handleContactInputs({ target: { name: "label", value: e } }, step.id, id)}
                    />
                    <div className="d-flex w-100 justify-content-around">
                        <button className={`btn ${step.width === "25%" ? 'btn-primary' : 'btn-secondary'}`} onClick={() => props.handleContactInputs({ target: { name: "width", value: '25%' } }, step.id, id)}>25%</button>
                        <button className={`btn ${step.width === "50%" ? 'btn-primary' : 'btn-secondary'}`} onClick={() => props.handleContactInputs({ target: { name: "width", value: '50%' } }, step.id, id)}>50%</button>
                        <button className={`btn ${step.width === "100%" ? 'btn-primary' : 'btn-secondary'}`} onClick={() => props.handleContactInputs({ target: { name: "width", value: '100%' } }, step.id, id)}>100%</button>
                    </div>

                    <label>
                        <input
                            type="checkbox"
                            name="hideLabel"
                            checked={step.hideLabel}
                            onChange={(e) => handleContactInputs(e, step.id, id)}
                        />{" "}
                        Hide Label
                    </label>


                    <label>
                        <input
                            type="checkbox"
                            name="required"
                            checked={step.required}
                            onChange={(e) => handleContactInputs(e, step.id, id)}
                        />{" "}
                        Required
                    </label>

                    {toggleError(step)}
                </div>
            </Collapse>
        </>
    );
}

export default AddContactSignatureField;