import React from "react";
import ChooseImageModal from "../../../ChooseImageModal";
import ChooseImage from "../../../ChooseImageModal";
import { withTranslation } from "react-i18next";
import SelectSize from "../../../SelectSize";
import ColorPicker from "../../../ColorPicker";
import { IoTrashOutline } from "react-icons/io5";
import AltImagesTextArea from "../../../AltImagesTextArea.jsx";
import Slider from "rc-slider";

class PageLayout extends React.Component {
  changeImage = (image, mediaAltText = "") => {
    const { id } = this.props.data.onEdit;
    this.props.handleChangeQuestion(
      { target: { name: "mediaImageSrc", value: image } },
      id
    );
    this.props.handleChangeQuestion(
      { target: { name: "mediaType", value: "image" } },
      id
    );
    this.props.handleChangeQuestion(
      { target: { name: "mediaVideoSrc", value: "" } },
      id
    );
    this.props.handleChangeQuestion(
      { target: { name: "mediaAltText", value: mediaAltText } },
      id
    );
  };

  changeAltText = (e) => {
    const { id } = this.props.data.onEdit;
    this.props.handleChangeQuestion(e, id);
  };

  changeVideo = (video, thumbnail) => {
    const { id } = this.props.data.onEdit;
    this.props.handleChangeQuestion(
      { target: { name: "mediaVideoSrc", value: video } },
      id
    );
    this.props.handleChangeQuestion(
      { target: { name: "mediaType", value: "video" } },
      id
    );
    this.props.handleChangeQuestion(
      { target: { name: "mediaImageSrc", value: thumbnail } },
      id
    );
    this.props.handleChangeQuestion(
      { target: { name: "mediaAltText", value: "" } },
      id
    );
  };

  onSwatchHover = (color, toChange) => {
    const { id } = this.props.data.onEdit;
    const { r, g, b, a } = color.rgb;
    this.props.handleChangeQuestion(
      { target: { name: toChange, value: `rgba(${r}, ${g}, ${b}, ${a})` } },
      id
    );
  };
  handleImageOpacity = (opacity) => {
    const { id } = this.props.data.onEdit;
    this.props.handleChangeQuestion(
      { target: { name: "mediaImageOverlayOpacity", value: opacity } },
      id
    );
  };

  toggleMediaType = (e) => {
    const { id } = this.props;
    const { name, checked } = e.target;
    this.props.handleChangeQuestion({ target: { name, value: checked } }, id);
  };
  handleWelcomePageView = () => {
    const { handleChangeQuestion, t } = this.props;

    const { type, startWithEnterKey, id } = this.props.data.onEdit;
    if (type !== "start" || type !== "text") return <></>;
    return (
      <div className="space line">
        <label className="checkbox">
          <input
            type="checkbox"
            name="startWithEnterKey"
            checked={startWithEnterKey}
            onChange={(e) => handleChangeQuestion(e, id)}
          />
          {t("rightBar-customize-checkbox")} <b>Enter ↵</b>
        </label>
      </div>
    );
  };

  //function PageLayout(props) {
  render() {
    const {
      questionType = "",
      id,
      handleChangeQuestion,
      data = {},
      t,
    } = this.props;
    const {
      type,
      showMedia,
      layout = "1",
      withSubtitle = false,
      withFooter,
      withHeader,
      withPagination,
      withProgressBar,
      mediaImageSrc,
      mediaImageOverlayColor,
      mediaImageOverlayOpacity,
      mediaAltText = "",
      withCallToAction,
      resultType,
      maxProductsOnPage,
      defaultProductLink,
      linkButtonText,
      showProductPrice = true,
      showProductDescription = true,
      showProductCTA = true,
      showProductCategory = false,
    } = this.props.data.onEdit;
    return (
      <>
        {type === "question" ? (
          <div className="answerType  d-none">
            <h3>{t("question-rightBar-answer-type")} </h3>
            <select
              name="questionType"
              className="form-control"
              value={questionType}
              onChange={(e) => handleChangeQuestion(e, id)}
            >
              <option value="singleSelect">
                {t("question-rightBar-single-Select")}
              </option>
              {/* <option value="multiSelect">Multi Select</option> */}
              <option value="rating">{t("question-rightBar-rating")}</option>
              <option value="matrix">{t("question-rightBar-matrix")}</option>
            </select>
          </div>
        ) : (
          <></>
        )}
        {this.handleWelcomePageView()}
        <div className="">
          <div className="addSwitch">
            <label className="switch">
              <input
                type="checkbox"
                name="withSubtitle"
                checked={withSubtitle}
                onChange={(e) => handleChangeQuestion(e, id)}
              />
              <span className="slider round"></span>
            </label>
            {t("rightBar-customize-Subtitle")}
          </div>
          {/* <div className="addSwitch">
            <label className="switch">
              <input
                type="checkbox"
                name="withPagination"
                checked={withPagination}
                onChange={(e) => handleChangeQuestion(e, id)}
              />
              <span className="slider round"></span>
            </label>
            {t("rightBar-customize-Pagination")}
          </div> */}
          <div className="addSwitch">
            <label className="switch">
              <input
                type="checkbox"
                name="withProgressBar"
                checked={withProgressBar}
                onChange={(e) => handleChangeQuestion(e, id)}
              />
              <span className="slider round"></span>
            </label>
            {t("rightBar-customize-ProgressBar")}
          </div>
          <div className="addSwitch">
            <label className="switch">
              <input
                type="checkbox"
                name="withHeader"
                checked={withHeader}
                onChange={(e) => handleChangeQuestion(e, id)}
              />
              <span className="slider round"></span>
            </label>
            {t("rightBar-customize-Header")}
          </div>
          <div className="addSwitch">
            <label className="switch">
              <input
                type="checkbox"
                name="withFooter"
                checked={withFooter}
                onChange={(e) => handleChangeQuestion(e, id)}
              />
              <span className="slider round"></span>
            </label>
            {t("rightBar-customize-Footer")}
          </div>
          {type !== "start" ? (
            <div className="addSwitch">
              <label className="switch">
                <input
                  type="checkbox"
                  name="withPagination"
                  checked={withPagination}
                  onChange={(e) => handleChangeQuestion(e, id)}
                />
                <span className="slider round"></span>
              </label>
              {t("rightBar-customize-back-button")}
            </div>
          ) : (
            <></>
          )}
          <div className="addSwitch">
            <label className="switch">
              <input
                onChange={this.toggleMediaType}
                checked={showMedia}
                name="showMedia"
                type="checkbox"
              />
              <span className="slider round"></span>
            </label>
            {t("rightBar-customize-AddMainImage")}
          </div>

          {type == "result" ? (
            <div className="addSwitch">
              <label className="switch">
                <input
                  type="checkbox"
                  name="withCallToAction"
                  checked={withCallToAction}
                  onChange={(e) => handleChangeQuestion(e, id)}
                />
                <span className="slider round"></span>
              </label>
              Call To action button
            </div>
          ) : (
            <></>
          )}

          {showMedia ? (
            <div className="pageLayout">
              <div className="chooseImage">
                <div className="imageBox">
                  <ChooseImageModal
                    index={"chooseimage"}
                    size={{
                      height: "auto",
                      maxWidth: "100%",
                      width: "auto",
                      maxHeight: "100%",
                      margin: "auto",
                    }}
                    borderStyle={{
                      border: "2px solid #DEDCF4",
                      width: "100%",
                      height: "120px",
                      margin: "10px 0px",
                      position: "relative",
                      alignItems: "center",
                      display: "flex",
                      backgroundColor: "rgb(240 235 235)",
                    }}
                    image={mediaImageSrc || "/img/picFrame.png"}
                    id={id}
                    changeImage={this.changeImage}
                    changeVideo={this.changeVideo}
                  />
                  <div className="imageBoxRemove">
                    <button
                      className="btn- btn-link remove-image-icon"
                      onClick={() => this.changeImage("")}
                    >
                      <IoTrashOutline size={20} />{" "}
                    </button>
                  </div>
                  <div className="fontStyle">
                    <SelectSize
                      fromTheme={true}
                      showPlatformSelect={false}
                      name="mediaImageOverlayOpacity"
                      label={t("theme-image-overlay-opacity")}
                      value={mediaImageOverlayOpacity || `0`}
                      handleChange={(size) => this.handleImageOpacity(size)}
                      changedDetails={{ toChange: "background" }}
                    />
                  </div>
                  <h3>{t("theme-image-overlay-color")}</h3>
                  <div className="m-2">
                    <ColorPicker
                      setRight="inherit"
                      color={mediaImageOverlayColor}
                      name="mediaImageOverlayColor"
                      handleChange={(e) =>
                        this.onSwatchHover(e, "mediaImageOverlayColor")
                      }
                    />
                  </div>

                  <h3> {t("rightBar-customize-page-layout")} </h3>
                  <div className="layout">
                    <a
                      type="button"
                      onClick={(e) =>
                        handleChangeQuestion(
                          { target: { name: "layout", value: "1" } },
                          id
                        )
                      }
                      title=""
                      className={layout === "1" ? "active" : ""}
                    >
                      <img
                        src={
                          layout === "1"
                            ? "/img/layout1-active.png"
                            : "/img/layout1.png"
                        }
                      />
                    </a>
                    <a
                      type="button"
                      onClick={(e) =>
                        handleChangeQuestion(
                          { target: { name: "layout", value: "2" } },
                          id
                        )
                      }
                      title=""
                      className={layout === "2" ? "active" : ""}
                    >
                      <img
                        src={
                          layout === "2"
                            ? "/img/layout2-active.png"
                            : "/img/layout2.png"
                        }
                      />
                    </a>

                    <a
                      type="button"
                      onClick={(e) =>
                        handleChangeQuestion(
                          { target: { name: "layout", value: "3" } },
                          id
                        )
                      }
                      title=""
                      className={layout === "3" ? "active" : ""}
                    >
                      <img
                        src={
                          layout === "3"
                            ? "/img/layout3-active.png"
                            : "/img/layout3.png"
                        }
                      />
                    </a>
                    <a
                      type="button"
                      onClick={(e) =>
                        handleChangeQuestion(
                          { target: { name: "layout", value: "4" } },
                          id
                        )
                      }
                      title=""
                      className={layout === "4" ? "active" : ""}
                    >
                      <img
                        src={
                          layout === "4"
                            ? "/img/layout4-active.png"
                            : "/img/layout4.png"
                        }
                      />
                    </a>
                    <a
                      type="button"
                      onClick={(e) =>
                        handleChangeQuestion(
                          { target: { name: "layout", value: "5" } },
                          id
                        )
                      }
                      title=""
                      className={layout === "5" ? "active" : ""}
                    >
                      <img
                        src={
                          layout === "5"
                            ? "/img/layout5-active.png"
                            : "/img/layout5.png"
                        }
                      />
                    </a>
                    <a
                      type="button"
                      onClick={(e) =>
                        handleChangeQuestion(
                          { target: { name: "layout", value: "6" } },
                          id
                        )
                      }
                      title=""
                      className={layout === "6" ? "active" : ""}
                    >
                      <img
                        src={
                          layout === "6"
                            ? "/img/layout6-active.png"
                            : "/img/layout6.png"
                        }
                      />
                    </a>
                  </div>
                  <div style={{ width: "100%" }}>
                    <AltImagesTextArea
                      mediaAltText={mediaAltText}
                      changeImagesTextArea={this.changeAltText}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }
}

export default withTranslation()(PageLayout);
