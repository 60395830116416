import React, { useState } from "react";

const SummaryTableColumnHeader = ({ column }) => {
  const [selectedColumn, setSelectedColumn] = useState("");
  return (
    <>
      {column.Header === selectedColumn ? (
        <th
          {...column.getHeaderProps()}
          className="summary-table-th column-width-change"
          onClick={() => setSelectedColumn("")}
        >
          {column.Header === "Id"
            ? column.render("Header")
            : `${column.render("Header")} >`}
        </th>
      ) : (
        <th
          {...column.getHeaderProps()}
          onClick={() => setSelectedColumn(column.Header)}
          className="summary-table-th "
        >
          {column.Header === "Id"
            ? column.render("Header")
            : `${column.render("Header")} >`}
        </th>
      )}
    </>
  );
};

export default SummaryTableColumnHeader;
