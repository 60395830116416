export function CalculateProductLogics(
  stepId,
  quizData,
  productsLogicsHistory = [],
  stepLogic = []
) {
  let { answers, variables } = quizData;

  const logicResultObj = {
    stepId,
    type: null,
    target: null,
    minValue: 0,
    maxValue: 0,
    amount: null,
  };
  if (!Array.isArray(stepLogic) || !stepLogic.length) {
    return;
  }
  const releventConditionBlock = stepLogic.find((logic) => {
    const { conditions } = logic;
    const conditionCombined = conditions
      .map((condition) => {
        let relevantQuestion;
        let releventVariable;
        let releventContact;
        let resultsresultsIds;
        let multiPrefix;
        let { operation, sectionId, value, operator } = condition;
        relevantQuestion = answers.find((itr) => itr.questionId == sectionId);
        if (!relevantQuestion && answers.length) {
          if (
            answers.find((itr) =>
              itr.arrayOfResult.find((row) => row.subQuestionId == sectionId)
            )
          ) {
            relevantQuestion = JSON.parse(
              JSON.stringify(
                answers.find((itr) =>
                  itr.arrayOfResult.find(
                    (row) => row.subQuestionId == sectionId
                  )
                )
              )
            );
          }
          if (relevantQuestion) {
            relevantQuestion.arrayOfResult =
              relevantQuestion.arrayOfResult.filter(
                (row) => row.subQuestionId == sectionId
              );
          }
        }

        if (!relevantQuestion) {
          releventVariable = variables.find((itr) => itr.name == sectionId);
        }

        if (!relevantQuestion && !releventVariable && sectionId !== "score") {
          releventContact = Object.values(quizData.contacts).find(
            (itr) => itr.label == sectionId
          );
        }

        let valid = true;
        if (relevantQuestion) {
          const { arrayOfResult } = relevantQuestion;
          if (
            operation == "one_of" ||
            operation == "none_of" ||
            operation == "all_of" ||
            operation == "exact"
          ) {
            const valueArray = JSON.parse(value); // the array from logics
            resultsresultsIds = arrayOfResult // user answer
              .filter((el) => el.id)
              .map(({ id }) => id);

            //One of
            if (operation == "one_of") {
              valid = valueArray.find((id) => resultsresultsIds.includes(id));
              valid = valid ? true : false;
            }
            // All of
            else if (operation == "none_of") {
              valid = valueArray.find((id) => resultsresultsIds.includes(id));
              valid = valid ? false : true;
            } else if (operation == "all_of") {
              valueArray.forEach((id) => {
                if (!resultsresultsIds.includes(id)) valid = false;
              });
            } else if (operation == "exact") {
              valueArray.sort();
              resultsresultsIds.sort();
              valid =
                JSON.stringify(valueArray) ===
                JSON.stringify(resultsresultsIds);
            } else {
              valueArray.forEach((id) => {
                id = id ? `'${id}'` : "";
                if (
                  !eval(`${multiPrefix}[${resultsresultsIds}].includes(${id})`)
                )
                  valid = false;
              });
            }
            // if (resultsresultsIds.length < valueArray.length) {
            //   valid = false;
            // }
          } else if (operation == "in" || operation == "not in") {
            const multiPrefix = operation == "in" ? "" : "!";
            const valueArray = JSON.parse(value);
            resultsresultsIds = arrayOfResult
              .filter((el) => el.id)
              .map(({ id }) => `'${id}'`);

            valueArray.forEach((id) => {
              id = id ? `'${id}'` : "";
              if (!eval(`${multiPrefix}[${resultsresultsIds}].includes(${id})`))
                valid = false;
            });
            if (
              !multiPrefix &&
              resultsresultsIds.length < JSON.parse(value).length
            )
              valid = false;
          } else {
            arrayOfResult.forEach(({ id, value: val }) => {
              if (
                operation == ">" ||
                operation == "<" ||
                operation == ">=" ||
                operation == "<="
              ) {
                value = !isNaN(value) ? parseInt(value) : `'${value}'`;
                val = !isNaN(val) ? parseInt(val) : `'${val}'`;
                if (!eval(`${value}${operation}${val}`)) valid = false;
              } else {
                if (!eval(`'${value}'${operation}'${id}'`)) valid = false;
              }
            });
          }
          return `${operator || ""}${valid}`;
        } else if (releventVariable) {
          if (!eval(`'${value}'${operation}'${releventVariable.value}'`))
            valid = false;
          return `${operator || ""}${valid}`;
        } else if (releventContact) {
          if (
            operation == "one_of" ||
            operation == "none_of" ||
            operation == "all_of" ||
            operation == "exact"
          ) {
            const valueArray = JSON.parse(value); // the array from logics

            resultsresultsIds = Array.isArray(releventContact.value)
              ? releventContact.value.map((option) => `${option.value}`)
              : [releventContact.value];
            //One of
            if (operation == "one_of") {
              valid = valueArray.find((id) => resultsresultsIds.includes(id));
              valid = valid ? true : false;
            }
            // All of
            else if (operation == "none_of") {
              valid = valueArray.find((id) => resultsresultsIds.includes(id));
              valid = valid ? false : true;
            } else if (operation == "all_of") {
              valueArray.forEach((id) => {
                if (!resultsresultsIds.includes(id)) valid = false;
              });
            } else if (operation == "exact") {
              valueArray.sort();
              resultsresultsIds.sort();
              valid =
                JSON.stringify(valueArray) ===
                JSON.stringify(resultsresultsIds);
            } else {
              valueArray.forEach((id) => {
                id = id ? `'${id}'` : "";
                if (
                  !eval(`${multiPrefix}[${resultsresultsIds}].includes(${id})`)
                )
                  valid = false;
              });
            }
            // if (resultsresultsIds.length < JSON.parse(value).length) {
            //   valid = false;
            // }
          } else if (operation == "in" || operation == "not in") {
            const multiPrefix = operation == "in" ? "" : "!";

            const valueArray = releventContact.value.map(
              (option) => `'${option.value}'`
            );

            if (!eval(`${multiPrefix}[${valueArray}].includes(${value})`))
              valid = false;
          } else if (operation == "signed" || operation == "not_signed") {
            if (
              operation == "signed" &&
              !releventContact.value.includes("data:image/png;base64,")
            )
              valid = false;
            else if (
              operation == "not_signed" &&
              releventContact.value.includes("data:image/png;base64,")
            )
              valid = false;
          } else if (!eval(`'${value}'${operation}'${releventContact.value}'`))
            valid = false;
          return `${operator || ""}${valid}`;
        } else {
          return `${operator || ""}true`;
        }
      })
      .join("");
    return eval(conditionCombined);
  });
  if (releventConditionBlock) {
    const {
      then,
      to,
      amount = "0",
      minValue,
      maxValue,
      thenFilterType = "amount",
      includesOrExcludes = "includes",
      mathSign = "+",
    } = releventConditionBlock;
    const amountValue = !Number.isNaN(parseInt(amount)) ? parseInt(amount) : 0;
    logicResultObj.type = then;
    logicResultObj.amount = amountValue;
    logicResultObj.thenFilterType = thenFilterType;
    logicResultObj.includesOrExcludes = includesOrExcludes;
    logicResultObj.mathSign = mathSign;
    if (["product", "category"].includes(then)) {
      logicResultObj.target = to;
    } else if (["priceRange"].includes(then)) {
      logicResultObj.minValue = minValue;
      logicResultObj.maxValue = maxValue;
    }
    productsLogicsHistory.push(logicResultObj);
  }
  return productsLogicsHistory;
}

export function ReduceAllProductsLogicHistory(
  productsLogicsHistory = [],
  products = []
) {
  const sumAllTypes = productsLogicsHistory.reduce(
    (obj, itr) => {
      const {
        type, // product || category || priceRange
        target = [], // only when type = product / category
        amount, // only when thenFilterType = amount
        minValue, // only when type = priceRange
        maxValue, // only when type = priceRange
        thenFilterType = "amount", // amount || filter
        includesOrExcludes = "includes", // || includes || excludes
        mathSign = "plus", // + || -
      } = itr;
      //Amount
      if (
        ["product", "category"].includes(type) &&
        thenFilterType === "amount"
      ) {
        if (!Array.isArray(target)) return obj;
        target.forEach((product) => {
          const { value } = product;
          const currentTypeKey = `type-${type}-target-${value}-minValue-${minValue}-maxValue-${maxValue}`;
          obj.amount[currentTypeKey] = obj.amount[currentTypeKey] || {
            ...itr,
            target: value,
            amount: 0,
          };
          if (mathSign === "plus") obj.amount[currentTypeKey].amount += amount;
          else if (mathSign === "minus")
            obj.amount[currentTypeKey].amount -= amount;
        });
      } else if (type == "priceRange" && thenFilterType === "amount") {
        const currentTypeKey = `type-${type}-target-${target}-minValue-${minValue}-maxValue-${maxValue}`;
        obj.amount[currentTypeKey] = obj.amount[currentTypeKey] || {
          ...itr,
          amount: 0,
        };
        if (mathSign === "plus") obj.amount[currentTypeKey].amount += amount;
        else if (mathSign === "minus")
          obj.amount[currentTypeKey].amount -= amount;
      } else if (
        ["product", "category"].includes(type) &&
        thenFilterType === "filter"
      ) {
        if (!Array.isArray(target)) return obj;

        obj.includesOrExcludes.push({
          ...itr,
          target: target.map((itr) => `'${itr.value}'`),
        });
      } else if (type === "priceRange" && thenFilterType === "filter") {
        obj.includesOrExcludes.push(itr);
      }
      return obj;
    },
    { amount: {}, includesOrExcludes: [] }
  );
  console.log(sumAllTypes.includesOrExcludes);
  products = includesOrExcludesProducts(
    sumAllTypes.includesOrExcludes,
    products
  );
  products = sortByAmount(Object.values(sumAllTypes.amount), products);
  products = handleProductsVeriations(products);
  return products;
}

export function handleProductsVeriations(products) {
  return products.reduce((allProducts, product) => {
    const { variation_id } = product;
    if (variation_id) {
      const variationParentIndex = allProducts.findIndex(
        (itr) => itr.variation_id == variation_id
      );
      if (variationParentIndex != -1) {
        allProducts[variationParentIndex].variations.push(product);
        return allProducts;
      }
    }
    const productCopy = JSON.parse(JSON.stringify(product));
    product.variations = [productCopy];
    allProducts.push(product);
    return allProducts;
  }, []);
}

export function includesOrExcludesProducts(includesOrExcluedsArray, products) {
  includesOrExcluedsArray.forEach((config) => {
    const { type, target, minValue, maxValue, includesOrExcludes } = config;
    products = products.filter((product) => {
      let operator = includesOrExcludes == "excludes" ? "!" : "";
      switch (type) {
        case "product":
          return eval(
            `${operator}[${target}].includes('${product.product_id}')`
          );
        case "category":
          return eval(
            `${operator}[${target}].includes('${product.product_id}')`
          );
        case "priceRange":
          if (
            (includesOrExcludes == "includes" &&
              product.price_value >= minValue &&
              product.price_value <= maxValue) ||
            (includesOrExcludes == "excludes" &&
              product.price_value <= minValue) ||
            (includesOrExcludes == "excludes" &&
              product.price_value >= maxValue)
          ) {
            return true;
          }
      }
    });
  });
  return products;
}

export function sortByAmount(sumAllTypes, products) {
  products.map((product) => {
    const productConfig = sumAllTypes.find((config) => {
      if (config.type === "product" && product.product_id === config.target)
        return config;
      else if (config.type === "category" && product.category === config.target)
        return config;
      else if (config.type === "priceRange") {
        if (
          product.price_value >= config.minValue &&
          product.price_value <= config.maxValue
        )
          return config;
        if (
          product.price_value >= config.minValue &&
          product.price_value <= config.maxValue
        )
          return config;
      }
    });
    product.totalAmount = 0;
    if (productConfig) {
      product.totalAmount = productConfig.amount;
    }
    return product;
  });

  return products.sort((a, b) => {
    return b.totalAmount - a.totalAmount;
  });
}
