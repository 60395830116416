import axios from "axios";
import { apiUrl, integrationServer, authServer, feedsServer } from "../config";

//Add to every request the token header
const mainAxios = axios.create({
  baseURL: `${apiUrl}/`,
});

mainAxios.interceptors.request.use((config) => {
  const token = localStorage.getItem("token") || "";
  if (token) {
    config.headers["token"] = token;
    config.headers.charset = "utf-8";
  }
  if (authServer) {
    config.headers["authpath"] = authServer;
  }

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  if (Object.keys(params).length) {
    const newParams = Object.entries(params).reduce((acc, [key, value]) => {
      acc[encodeURI(key.toLowerCase())] = encodeURI(value);
      return acc;
    }, {});

    config.headers["url-params"] = JSON.stringify(newParams);
  }

  return config;
});

const integrationAxios = axios.create({
  baseURL: `${integrationServer}/`,
});

const feedsAxios = axios.create({
  baseURL: `${feedsServer}/`,
});

feedsAxios.interceptors.request.use((config) => {
  const token = localStorage.getItem("token") || "";
  if (token) {
    config.headers["token"] = token;
    config.headers.charset = "utf-8";
  }
  if (authServer) {
    config.headers["authpath"] = authServer;
  }

  return config;
});

export { mainAxios, integrationAxios, feedsAxios };
