import TagManager from 'react-gtm-module'
import store from '../redux/store'

let isGtm = false





const handleGtmCall = (newGtm) => {
    const globalState = store.getState()
    const oldGtm = globalState.gtmStatus
    const oldPage = oldGtm.page ? oldGtm.page : "";
    const newPage = newGtm.page ? newGtm.page : "";
    if (oldPage !== newPage) {
        let tagManagerArgs = null;

        if (newPage && newPage !== "view") {
            tagManagerArgs = {
                gtmId: 'GTM-WD9L4QK', dataLayer: newGtm
            }

            if (!isGtm) {
                TagManager.initialize(tagManagerArgs);
                isGtm = true
            } else {
                TagManager.dataLayer({ dataLayer: tagManagerArgs.dataLayer })
            }
        }
    }
}

export default handleGtmCall 