import React, { useState } from "react";
import { connect } from "react-redux";
import {
  deleteQuiz,
  createQuizFromScratchAction,
  getOrgFeeds,
} from "../../../redux/actions";
import Modal from "react-bootstrap/Modal";
import { withTranslation } from "react-i18next";
import {
  AiOutlineInfoCircle,
  AiOutlineAppstoreAdd,
  AiOutlineUser,
  AiOutlineCrown,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { Redirect, Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import i18n from "../../../translate/i18n";
import { BiFileBlank } from "react-icons/bi";
import { HiOutlineTemplate } from "react-icons/hi";

class CreateQuizFromScratch extends React.Component {
  state = {
    step: 1,
    modalIsOpen: this.props.openModal,
    redierctsToTemplate: false,
    selectedFeed: null,
  };

  changeProjectType = (projectTypeId) => {
    this.setState({ projectTypeId, selectedFeed: null });
    if (projectTypeId === 20) {
      this.props.actions.getOrgFeeds();
    }
  };

  canCreateQuiz = () => {
    if (this.state.projectTypeId === 20 && !this.state.selectedFeed)
      return false;
    if (this.state.projectTypeId && this.state.projectName) return true;
    return false;
  };

  render() {
    const renderTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        {props}
      </Tooltip>
    );

    const { t, folderId } = this.props;
    const { goToEdit } = this.props.actions;

    const handleClick = async () => {
      if (!this.state.projectName)
        return this.setState({
          errorWithProjectName: true,
        });
      if (!this.state.projectName || !this.state.projectTypeId) return;
      if (this.state.projectTypeId === 20 && !this.state.selectedFeed) return;
      try {
        return goToEdit(
          this.state.projectTypeId,
          this.state.projectName,
          this.props.folderId,
          this.props.history,
          this.state.selectedFeed
        );
      } catch (err) {}
    };

    const moveToNextStep = () => {
      this.setState({
        step: 2,
      });
    };

    const redierctsToTemplate = () => {
      return (
        <Redirect
          to={{
            pathname: "/templates",
            state: { folderId },
          }}
        />
      );
    };
    // const {t} = props;
    return (
      <>
        {this.state.redierctsToTemplate ? redierctsToTemplate() : ""}

        <main className="">
          <Modal
            style={{ direction: i18n.language == "he" ? "rtl" : "ltr" }}
            show={this.props.openModal}
            onHide={() => {
              this.setState({ step: 1, projectTypeId: "" });
              this.props.handleCreateNewBtnClick();
            }}
            centered
          >
            <div style={{ paddingTop: "40px", paddingBottom: "40px" }}>
              {this.state.step == 1 ? (
                <>
                  <h2 style={{ paddingBottom: "30px" }}>
                    {t("dashboard-create-new-knowy")}
                  </h2>
                  <div
                    className="d-flex justify-content-center"
                    style={{ columnGap: "20px" }}
                  >
                    <div
                      className="d-flex align-items-center align-content-center flex-column steps-btn choose-create-btn"
                      onClick={moveToNextStep}
                    >
                      <div className="p-2">
                        <BiFileBlank size={50} />
                      </div>
                      <div className="p-2">
                        <span>
                          {" "}
                          {t("dashboard-template-create-from-scratch")}
                        </span>
                      </div>
                    </div>

                    <div
                      className="d-flex align-items-center align-content-center flex-column steps-btn choose-create-btn"
                      onClick={() =>
                        this.setState({ redierctsToTemplate: true })
                      }
                    >
                      <div className="p-2">
                        <HiOutlineTemplate size={50} />
                      </div>
                      <div className="p-2">
                        <span>
                          {" "}
                          {t("dashboard-template-create-from-template")}
                        </span>
                      </div>
                    </div>
                  </div>

                  <span
                    className="close close_multi"
                    onClick={() => this.props.handleCreateNewBtnClick()}
                  >
                    {t("general-cancel")}
                  </span>
                </>
              ) : (
                <></>
              )}
              {this.state.step == 2 ? (
                <>
                  <h2 style={{ paddingBottom: "30px" }}>
                    {t("dashboard-create-new-knowy")}
                  </h2>
                  <div className="fields">
                    <input
                      type="text"
                      name="projectName"
                      value={this.state.projectName}
                      style={{
                        border:
                          this.state.errorWithProjectName &&
                          !this.state.projectName
                            ? "red solid 1px"
                            : "",
                        margin:
                          this.state.errorWithProjectName &&
                          !this.state.projectName
                            ? "0"
                            : "0 0 20px 0",
                        width: "80%",
                      }}
                      onChange={(e) =>
                        this.setState({ projectName: e.target.value })
                      }
                      placeholder={t("dashboard-template-project-name")}
                    />
                    <span
                      style={{
                        display:
                          this.state.errorWithProjectName &&
                          !this.state.projectName
                            ? "block"
                            : "none",
                        color: "red",
                        textAlign: "left",
                        width: "80%",
                        paddingBottom: "20px",
                        margin: "auto",
                      }}
                    >
                      Enter project name
                    </span>
                  </div>

                  <h3>{t("dashboard-template-choose-knowy-type")}</h3>

                  <div
                    className="d-flex flex-row justify-content-center"
                    style={{ columnGap: "15px", paddingTop: "10px" }}
                  >
                    {/*start quiz button*/}
                    <div style={{ width: "125px" }}>
                      <a
                        type="button"
                        onClick={() => this.changeProjectType(1)}
                        className="creat"
                      >
                        <div
                          className={
                            this.state.projectTypeId === 1 ? "active" : ""
                          }
                        >
                          <div style={{ position: "absolute" }}>
                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip(
                                t("dashboard-template-assessment-label")
                              )}
                            >
                              <div>
                                <AiOutlineInfoCircle />
                              </div>
                            </OverlayTrigger>
                          </div>
                          <div
                            style={{
                              margin: "auto",
                              width: "60%",
                              height: "80px",
                            }}
                          >
                            <AiOutlineAppstoreAdd size={40} />
                            <br />
                            {t("dashboard-template-assessment")}
                          </div>
                        </div>
                        {/*<p>{t("dashboard-template-lorem")}</p>*/}
                      </a>
                    </div>
                    {/* end quiz button*/}

                    {/*start form button*/}
                    <div style={{ width: "125px" }}>
                      <a
                        type="button"
                        onClick={() => this.changeProjectType(2)}
                        className="creat"
                      >
                        <div
                          className={
                            this.state.projectTypeId === 2 ? "active" : ""
                          }
                        >
                          <div style={{ position: "absolute" }}>
                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip(
                                t("dashboard-template-personality-label")
                              )}
                            >
                              <div>
                                <AiOutlineInfoCircle />
                              </div>
                            </OverlayTrigger>
                          </div>
                          <div
                            style={{
                              margin: "auto",
                              width: "60%",
                              height: "80px",
                            }}
                          >
                            <AiOutlineUser size={40} />
                            <br />
                            {t("dashboard-template-personality")}
                          </div>
                        </div>
                      </a>
                    </div>
                    <div style={{ width: "125px" }}>
                      <a
                        type="button"
                        onClick={() => this.changeProjectType(3)}
                        className="creat"
                      >
                        <div
                          className={
                            this.state.projectTypeId === 3 ? "active" : ""
                          }
                        >
                          <div style={{ position: "absolute" }}>
                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip(
                                t("dashboard-template-scored-label")
                              )}
                            >
                              <div>
                                <AiOutlineInfoCircle />
                              </div>
                            </OverlayTrigger>
                          </div>
                          <div
                            style={{
                              margin: "auto",
                              width: "60%",
                              height: "80px",
                            }}
                          >
                            <AiOutlineCrown size={40} />
                            <br />
                            {t("dashboard-template-scored")}
                          </div>
                        </div>
                      </a>
                    </div>
                    <div style={{ width: "125px" }}>
                      <a
                        type="button"
                        onClick={() => this.changeProjectType(20)}
                        className="creat"
                      >
                        <div
                          className={
                            this.state.projectTypeId === 20 ? "active" : ""
                          }
                        >
                          <div style={{ position: "absolute" }}>
                            <OverlayTrigger
                              placement="right"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip(
                                t("dashboard-template-assessment-label")
                              )}
                            >
                              <div>
                                <AiOutlineInfoCircle />
                              </div>
                            </OverlayTrigger>
                          </div>
                          <div
                            style={{
                              margin: "auto",
                              width: "60%",
                              height: "80px",
                            }}
                          >
                            <AiOutlineShoppingCart size={40} />
                            <br />
                            {t("dashboard-template-ecomm")}
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>

                  {this.state.projectTypeId === 20 && (
                    <div className="row" style={{ textAlign: "center" }}>
                      <h3>Products feed</h3>
                      {this.props.state.orgFeeds.length ? (
                        <select
                          onChange={(e) =>
                            this.setState({ selectedFeed: e.target.value })
                          }
                          style={{
                            width: "80%",
                            paddingBottom: "20px",
                            margin: "auto",
                          }}
                          className="select-feed-input"
                        >
                          <option value="">Select feed...</option>
                          {this.props.state.orgFeeds.map((feed) => {
                            const { feed_id, feed_name } = feed;
                            return <option value={feed_id}>{feed_name}</option>;
                          })}
                        </select>
                      ) : (
                        <div>
                          <br />
                          <span>
                            You don’t have any product feed yet,{" "}
                            <Link to="/organization">
                              click here to add new feed
                            </Link>
                          </span>
                        </div>
                      )}
                    </div>
                  )}

                  <br />
                  <input
                    type="button"
                    onClick={handleClick}
                    value={t("dashboard-template-create")}
                    className={
                      this.canCreateQuiz() ? "btn-popup" : "btn-popup disabled"
                    }
                    disabled={!this.canCreateQuiz()}
                  />
                  <span
                    className="close close_multi"
                    onClick={() => {
                      this.setState({ step: 1, projectTypeId: "" });
                      this.props.handleCreateNewBtnClick();
                    }}
                  >
                    {t("general-cancel")}
                  </span>
                </>
              ) : (
                <></>
              )}
            </div>
          </Modal>
        </main>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  state.orgFeeds = Array.isArray(state.orgFeeds) ? state.orgFeeds : [];
  return { state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      deleteQuiz: (id) => dispatch(deleteQuiz(id)),
      getOrgFeeds: () => dispatch(getOrgFeeds()),
      goToEdit: (projectTypeId, projectName, folderId, history, selectedFeed) =>
        dispatch(
          createQuizFromScratchAction(
            projectTypeId,
            projectName,
            folderId,
            history,
            selectedFeed
          )
        ),
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CreateQuizFromScratch)
);
