import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ConfirmDelete from "../Modals/ConfirmDelete";
import "../main.css";
import { changeFolder, duplicateQuiz } from "../../../redux/actions";
import { withTranslation } from "react-i18next";

import { AiOutlineFolderOpen } from "react-icons/ai";
import {RxCopy} from "react-icons/rx";
class QuizItem extends React.Component {
  handleDuplicateQuiz = () => {
    this.props.actions.duplicateQuiz(this.props.data.id);
  };

  render() {
    let {
      id,
      title,
      edited_at,
      slug,
      published,
      directory_id,
      created_at,
      image_path,
    } = this.props.data;
    image_path = image_path
      ? `https://smart-hippo.s3.eu-central-1.amazonaws.com/${image_path}`
      : "img/pic.jpg";
    const url = published == 1 ? `/quiz/${slug}` : `/preview`;
    const { t } = this.props;
    return (
      <tr>
        <td>
          <Link to={`/edit/${id}`}>
            <div className="pic">
              <img src={image_path} />
            </div>
          </Link>
        </td>
        <td>
          <Link to={`/edit/${id}`}>
            <h3>{title}</h3>
          </Link>
        </td>
        <td>{created_at}</td>
        <td>{edited_at}</td>
        {/*<td>12</td>*/}
        {published == "1" ? (
          <td className="published">{t("nav-published")} </td>
        ) : (
          <td>{t("dashboard-draft")} </td>
        )}

        {/*<td>*/}
        {/*  <a href="#" title="" className="pause"></a>*/}
        {/*</td>*/}
        <td>
          <div className="item-tools">
            <a title="Duplicate" className="pointer duplicate" onClick={() => this.handleDuplicateQuiz()}>
              <RxCopy/>
              {/*<svg*/}
              {/*  width="16"*/}
              {/*  height="16"*/}
              {/*  viewBox="0 0 16 16"*/}
              {/*  xmlns="http://www.w3.org/2000/svg"*/}
              {/*>*/}
              {/*  <path*/}
              {/*    d="M13.5 0H5.5C4.83696 0 4.20108 0.263398 3.73224 0.732239C3.2634 1.20108 3 1.83696 3 2.5V3H2.5C1.83696 3 1.20108 3.2634 0.732239 3.73224C0.263398 4.20108 0 4.83696 0 5.5V13.5C0 14.163 0.263398 14.7989 0.732239 15.2678C1.20108 15.7366 1.83696 16 2.5 16H10.5C11.163 16 11.7989 15.7366 12.2678 15.2678C12.7366 14.7989 13 14.163 13 13.5V13H13.5C14.163 13 14.7989 12.7366 15.2678 12.2678C15.7366 11.7989 16 11.163 16 10.5V2.5C16 1.83696 15.7366 1.20108 15.2678 0.732239C14.7989 0.263398 14.163 0 13.5 0ZM12 13.5C12 13.8978 11.842 14.2794 11.5607 14.5607C11.2794 14.842 10.8978 15 10.5 15H2.5C2.10218 15 1.72064 14.842 1.43933 14.5607C1.15803 14.2794 1 13.8978 1 13.5V5.5C1 5.10218 1.15803 4.72064 1.43933 4.43933C1.72064 4.15803 2.10218 4 2.5 4H3V10.5C3 11.163 3.2634 11.7989 3.73224 12.2678C4.20108 12.7366 4.83696 13 5.5 13H12V13.5ZM15 10.5C15 10.8978 14.842 11.2794 14.5607 11.5607C14.2794 11.842 13.8978 12 13.5 12H5.5C5.10218 12 4.72064 11.842 4.43933 11.5607C4.15803 11.2794 4 10.8978 4 10.5V2.5C4 2.10218 4.15803 1.72064 4.43933 1.43933C4.72064 1.15803 5.10218 1 5.5 1H13.5C13.8978 1 14.2794 1.15803 14.5607 1.43933C14.842 1.72064 15 2.10218 15 2.5V10.5Z"*/}
              {/*    fill="#0046B5"*/}
              {/*  />*/}
              {/*</svg>*/}
            </a>
            {/*<a href="#" title="Statistics">*/}
            {/*  <svg*/}
            {/*    width="18"*/}
            {/*    height="18"*/}
            {/*    viewBox="0 0 18 18"*/}
            {/*    xmlns="http://www.w3.org/2000/svg"*/}
            {/*  >*/}
            {/*    <path*/}
            {/*      d="M8 17.5C6.01088 17.5 4.10324 16.7098 2.69672 15.3033C1.29019 13.8968 0.5 11.9891 0.5 10C0.5 8.01088 1.29019 6.10324 2.69672 4.69672C4.10324 3.29019 6.01088 2.5 8 2.5C8.13261 2.5 8.25975 2.55266 8.35352 2.64642C8.44728 2.74019 8.5 2.86739 8.5 3V9.5H15C15.1326 9.5 15.2597 9.55266 15.3535 9.64642C15.4473 9.74019 15.5 9.86739 15.5 10C15.4979 11.9885 14.707 13.8949 13.3009 15.301C11.8948 16.707 9.98847 17.4979 8 17.5ZM7.5 3.51898C6.26073 3.61465 5.07489 4.06366 4.08301 4.81274C3.09113 5.56183 2.33483 6.57961 1.90375 7.74542C1.47267 8.91123 1.38483 10.1762 1.65076 11.3904C1.91668 12.6046 2.52521 13.717 3.40411 14.5959C4.28302 15.4748 5.39544 16.0833 6.60962 16.3492C7.8238 16.6152 9.08883 16.5273 10.2546 16.0963C11.4205 15.6652 12.4382 14.9089 13.1873 13.917C13.9363 12.9251 14.3853 11.7393 14.481 10.5H8C7.86739 10.5 7.74025 10.4473 7.64648 10.3536C7.55272 10.2598 7.5 10.1326 7.5 10V3.51898Z"*/}
            {/*      fill="#0046B5"*/}
            {/*    />*/}
            {/*    <path*/}
            {/*      d="M17 8.5H10C9.86739 8.5 9.74025 8.44734 9.64648 8.35358C9.55272 8.25981 9.5 8.13261 9.5 8V1C9.5 0.867392 9.55272 0.740192 9.64648 0.646423C9.74025 0.552655 9.86739 0.5 10 0.5C11.9885 0.502118 13.8948 1.29297 15.3009 2.69904C16.707 4.1051 17.4979 6.01153 17.5 8C17.5 8.13261 17.4473 8.25981 17.3535 8.35358C17.2597 8.44734 17.1326 8.5 17 8.5ZM10.5 7.5H16.481C16.3597 5.95411 15.6907 4.50229 14.5942 3.40582C13.4978 2.30936 12.0459 1.64024 10.5 1.51898V7.5Z"*/}
            {/*      fill="#0046B5"*/}
            {/*    />*/}
            {/*  </svg>*/}
            {/*</a>*/}
            <a title="Folder" className="pointer folder" onClick={() => this.props.handleToggelModal(id)}>
              {" "}
            <AiOutlineFolderOpen/>
            </a>
            <ConfirmDelete render={this.props.render} data={{ id, title }} t={t} />
          </div>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  return { state };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      changeFolder: (quizId, folderId) =>
        dispatch(changeFolder(quizId, folderId)),
      duplicateQuiz: (quizId) => dispatch(duplicateQuiz(quizId)),
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(QuizItem)
);
