import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import validator from "validator";
import { login } from "../../redux/actions";

class LoginForm extends React.Component {
  state = {
    email: "",
    password: "",
    valid: {
      email: true,
      password: true,
    },
    errMsg: null,
  };

  componentDidUpdate(prevProps, prevState) {
    const previousReduxState = JSON.stringify(prevProps.loginDetails);
    const currentReduxState = JSON.stringify(this.props.loginDetails);

    if (previousReduxState === currentReduxState) {
      if (this.state.errMsg && this.state.valid.password) {
        this.setState({
          ...this.state,
          valid: {
            email: true,
            password: false,
          },
          errMsg: this.props.message,
        });
      }

      return;
    }
    if (this.props.message) {
      this.setState({
        ...this.state,
        errMsg: this.props.message,
        valid: {
          email: true,
          password: false,
        },
      });

      return;
    }
    if (this.props.loginDetails.user_id) this.props.history.push("/dashboard");
  }

  handleChange = (e) => {
    const { value, name } = e.target;

    this.setState({ [name]: value });
  };
  handleLogin = async (e) => {
    const { login } = this.props.actions;
    this.onEmailBlur();
    this.onPasswordBlur();
    const { valid, email, password } = this.state;
    if (!valid.email || !valid.password) return;
    await login({ email, password });
  };

  onEmailBlur = () => {
    const { valid, email } = this.state;
    valid.email = validator.isEmail(email);
    this.setState({ valid });
  };
  onPasswordBlur = () => {
    const { valid, password } = this.state;
    let passwordRegex = /^(?=.*[A-Z])(?=.*[a-zA-Z])[a-zA-Z\d!@#$%^&*()_+]{8,20}$/;
    valid.password = passwordRegex.test(password);
    this.setState({ valid });
  };

  checkIfLoginSuccess = () => {
    const { user_id } = this.props.loginDetails;
    if (user_id) this.props.history.push("/dashboard");
  };

  render() {
    const { email, password, valid } = this.state;
    const { t } = this.props;
    this.checkIfLoginSuccess();
    return (
      <>
        <h1>{t("login-title")}</h1>

        <form name="login" style={{ width: "80%", margin: "auto" }}>
          <span className="errorMsg">
            Sorry we coudn’t find an account with this details. You can{" "}
            <a href="#">reset password </a>or <a href="#">register</a> new user.
          </span>
          <div className={!valid.email ? "error" : ""}>
            <input
              type="email"
              name="email"
              value={email}
              onChange={this.handleChange}
              placeholder={t("login-email-placeholder")}
              onBlur={this.onEmailBlur}
              required
            />
            <span className="errorMsg">{t("login-email-error-message")}</span>
          </div>
          <div className={!valid.password ? "error" : ""}>
            <input
              type="password"
              name="password"
              value={password}
              autoComplete="off"
              onBlur={this.onPasswordBlur}
              onChange={this.handleChange}
              placeholder={t("login-password-placeholder")}
              required
            />
            <span className="errorMsg">
              {this.state.errMsg || t("login-password-error-message")}
            </span>
          </div>
          <input
            type="button"
            value="Login"
            onClick={this.handleLogin}
            className="login-btn"
          />
          {/* <span>OR</span> */}
          {/* <a href="#" title="Login with Google" className="google-btn"><img src="img/google.png" alt="Google" />{t('login-google')}</a> */}
        </form>
        <div className="px-5 ms-xl-4">
          <p>
            <a href="/resetPassword" title="Reset password">
              {t("login-reset-password")}
            </a>
          </p>
        </div>
        <div className="px-5 ms-xl-4 mt-5">
          <p>
            New here?{" "}
            <a href="/register" title="Register now">
              Register now
            </a>{" "}
          </p>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { message } = state.loginDetails;
  return { message, loginDetails: state.loginDetails };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      login: (userDetails) => dispatch(login(userDetails)),
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(LoginForm)
);
