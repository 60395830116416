import React, { useState } from "react";
import Fonts from "../../../utils/Fonts/Fonts";
import ColorPicker from "../ColorPicker";
import SelectSize from "../SelectSize";
import { withTranslation } from "react-i18next";
import Justify from "../../justify/justify";
import { useMediaQuery } from "react-responsive";
import { ImFont, ImFontSize, ImParagraphJustify } from "react-icons/im";
import { BiFontColor } from "react-icons/bi";
import {
  RiTextSpacing,
  RiArrowUpSLine,
  RiArrowDownSLine,
} from "react-icons/ri";
import PaddingEdit from "./PaddingEdit";
import Modal from "react-bootstrap/esm/Modal";
import { position } from "glamor/utils";
import PlatformSelect from "../../PlatformSelect";

function TitleAndSubTitle({
  selectedTheme,
  context,
  handleChange,
  props,
  handleColorsToChange,
  fromTheme,
}) {
  const { settings, fonts, t, id } = props;

  const [modalName, setModalName] = useState("");
  const [mode, SetMode] = useState({});

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  //@@ function that return diffrent block of padding depanding if theme or customize (modal)
  //being called from inside the component returns HTML
  const drawPaddingEditSection = () => {
    if (selectedTheme !== "override-edit") {
      return (
        <div className="fontStyle space line">
          <div
            className="row platform-selection-container"
            style={{ justifyContent: "flex-start" }}
          >
            <h3 className="col-6"> {t("theme-accordion-space")}</h3>
            <PlatformSelect showPlatformSelect={true} setMode={SetMode} />
          </div>
          <div className="" style={{ marginTop: "5px" }}>
            <PaddingEdit
              paddingObject={styleObject.padding}
              selectedTheme={selectedTheme}
              context={context}
              id={id}
              mode={mode}
              handleChange={handleChange}
              changeMainStyle={props.changeMainStyle}
              t={t}
            />
          </div>
        </div>
      );
    } else
      return (
        <>
          <div className="fontStyle space space-modal line">
            <div className="row platform-selection-container">
              <h3 className="col-6"> {t("theme-accordion-space")}</h3>
              <PlatformSelect showPlatformSelect={true} setMode={SetMode} />
            </div>
            <div style={{ marginTop: "5px" }}>
              <PaddingEdit
                paddingObject={styleObject.padding}
                selectedTheme={selectedTheme}
                context={context}
                id={id}
                mode={mode}
                handleChange={handleChange}
                changeMainStyle={props.changeMainStyle}
                t={t}
              />
            </div>
          </div>
        </>
      );
  };

  //@@ function that return diffrent block of font size depanding if theme or customize (modal)
  //being called from inside the component returns HTML

  const drawFontSizeEditSection = () => {
    if (selectedTheme !== "override-edit") {
      return (
        <div className="fontStyle">
          <SelectSize
            fromTheme={fromTheme}
            name="fontSize"
            label={t("general-font-size")}
            value={{
              desktop: styleObject.fontSize,
              mobile: styleObject.mobileFontSize || styleObject.fontSize,
              tablet: styleObject.tabletFontSize || styleObject.fontSize,
            }}
            handleChange={(size, name = "fontSize") => {
              selectedTheme == "override-edit"
                ? (function () {
                    handleChange(
                      { target: { name, value: size } },
                      id,
                      context
                    );
                  })()
                : (function () {
                    props.changeMainStyle(
                      { target: { name, value: size } },
                      context
                    );
                    handleChange({ target: { name, value: size } }, context);
                  })();
            }}
            changedDetails={{ toChange: context }}
          />
        </div>
      );
    } else {
      return (
        <div className="fontSizeDiv">
          <SelectSize
            fromTheme={true}
            name="fontSize"
            label={t("general-font-size")}
            value={{
              desktop: styleObject.fontSize,
              mobile: styleObject.mobileFontSize || styleObject.fontSize,
              tablet: styleObject.tabletFontSize || styleObject.fontSize,
            }}
            handleChange={(size, name = "fontSize") => {
              selectedTheme == "override-edit"
                ? (function () {
                    handleChange(
                      { target: { name, value: size } },
                      id,
                      context
                    );
                  })()
                : (function () {
                    props.changeMainStyle(
                      { target: { name, value: size } },
                      context
                    );
                    handleChange({ target: { name, value: size } }, context);
                  })();
            }}
            changedDetails={{ toChange: context }}
          />
        </div>
      );
    }
  };

  if (!selectedTheme) return <></>;
  const styleObject =
    selectedTheme == "override-edit"
      ? props.style[context]
      : selectedTheme[context];

  if (
    selectedTheme == "override-edit" &&
    !styleObject.isOverride &&
    typeof handleChange === "function"
  ) {
    Object.entries(settings[context]).forEach(([key, value]) => {
      if (props.style[context][key]) return;
      handleChange({ target: { name: key, value } }, id, context);
    });
    handleChange({ target: { name: "isOverride", value: true } }, id, context);
  }

  return (
    <div className="toolbar">
      {isTabletOrMobile ? (
        <div className="">
          <div
            className=""
            style={{ display: "grid", margin: "0 10px 0 10px" }}
          >
            <span>
              <ImFont size={"2em"} />
            </span>
            <span>{t("theme-accordion-font-style")}</span>
          </div>

          <div
            className=""
            style={{ display: "grid", margin: "0 10px 0 10px" }}
          >
            <span>
              <ImFontSize size={"2em"} />
            </span>
            <span>{t("theme-accordion-font-size")}</span>
          </div>

          <div
            className=""
            style={{ display: "grid", margin: "0 10px 0 10px" }}
          >
            <span>
              <BiFontColor size={"2em"} />
            </span>
            <span>{t("theme-accordion-color")}</span>
          </div>

          <div
            className=""
            style={{ display: "grid", margin: "0 10px 0 10px" }}
          >
            <span>
              <ImParagraphJustify size={"2em"} />
            </span>
            <span>Justify</span>
          </div>

          <div
            className=""
            style={{ display: "grid", margin: "0 10px 0 10px" }}
          >
            <span>
              <RiTextSpacing size={"2em"} />
            </span>
            <span>{t("theme-accordion-space")}</span>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="fontStyle">
        {/*{fromTheme ? <h3>{t("theme-accordion-font-style")} </h3> : ""}*/}
        <h3>{t("theme-accordion-font-style")} </h3>
        <div className="selectFont">
          <Fonts
            templatesFonts={fonts}
            selected={
              styleObject.fontFamily.replace(/\+/g, " ")
                ? styleObject.fontFamily.replace(/\+/g, " ")
                : settings[context].fontFamily.replace(/\+/g, " ")
            }
            saveFont={(font) => {
              selectedTheme == "override-edit"
                ? (function () {
                    handleChange(
                      { target: { name: "fontFamily", value: font } },
                      id,
                      context
                    );
                  })()
                : (function () {
                    props.changeMainStyle(
                      { target: { name: "fontFamily", value: font } },
                      context
                    );
                    handleChange(
                      { target: { name: "fontFamily", value: font } },
                      context
                    );
                  })();
            }}
          />
        </div>
      </div>
      {drawFontSizeEditSection()}
      <div className="fontStyle" style={{ display: "flex", marginTop: "15px" }}>
        {/*{fromTheme ? (*/}
        {/*  <h3 style={{ paddingRight: "20px" }}>{t("theme-accordion-color")}</h3>*/}
        {/*) : (*/}
        <h3 style={{ paddingRight: "20px" }}>{t("theme-accordion-color")}</h3>
        {/*)}*/}
        <div>
          <ColorPicker
            setRight="30px"
            borderStyle={"1px solid black"}
            isButtonStyle={false}
            color={styleObject.color}
            handleChange={(e) => {
              handleColorsToChange(e, "color", context);
            }}
          />
        </div>

        <Justify
          handleStyleFunctionName={
            selectedTheme == "override-edit"
              ? "handleQuestionStyle"
              : "changeMainStyle"
          }
          targetName={"textAlign"}
          value={context}
          id={selectedTheme == "override-edit" ? id : null}
          // actionFunction={
          //   selectedTheme == "override-edit"
          //     ? handleChange
          //     : props.changeMainStyle
          // }
          actionFunction={
            selectedTheme == "override-edit"
              ? handleChange
              : props.changeMainStyle
          }
          selectedJustify={
            selectedTheme === "override-edit"
              ? styleObject.textAlign
              : props.settings[context].textAlign
          }
        />
      </div>

      {drawPaddingEditSection()}
    </div>
  );
}
export default withTranslation()(TitleAndSubTitle);
