import React from "react";
import ReactDOM from "react-dom";
import Styles from "./editTitle.css";

class EditTitle extends React.Component {
  state = {
    editableState: false,
  };
  switchState(e) {
    e.preventDefault();

    if (this.state.editableState) {
      if (this.title.innerText) {
        this.props.changeTitle(this.title.innerText);
      } else {
        this.title.innerText = this.props.title;
        alert("Quiz title cannot be empty.");
      }
    }
    this.setState({
      editableState: !this.state.editableState,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.editableState) {
      this.title.focus();
    }
  }
  handleClickOutside(event) {
    const domNode = ReactDOM.findDOMNode(this.titleHolder);
    if (
      (!domNode || !domNode.contains(event.target)) &&
      this.state.editableState
    ) {
      if (this.title.innerText) {
        this.props.changeTitle(this.title.innerText);
      } else {
        this.title.innerText = this.props.title;
        alert("Quiz title cannot be empty.");
      }
      this.setState({
        editableState: false,
      });
    }
  }

  AddOptionOnEnter = (e) => {
    if (e.which === 13) {
      this.switchState(e);
    }
  };

  outsideClickHandler = this.handleClickOutside.bind(this);

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.outsideClickHandler, false);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.outsideClickHandler, false);
  }

  render() {
    return (
      <div
        style={{ width: "250px", overflow: "hidden" }}
        className={this.state.editableState ? "editable" : ""}
        ref={(titleHolder) => {
          this.titleHolder = titleHolder;
        }}
        onClick={(e) => this.switchState(e)}
      >
        <span
          className={Styles.editable}
          contentEditable={this.state.editableState}
          ref={(title) => {
            this.title = title;
          }}
          onKeyDown={this.AddOptionOnEnter}
        >
          {this.props.title}
        </span>
      </div>
    );
  }
}

export default EditTitle;
