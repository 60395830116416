import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";

import { Link } from "react-router-dom";
import { websiteUrl } from "../../../../config";

// import services from "./services";

export default function ConnectionsModal({
  show,
  handleClose,
  moduleDetails,
  setModuleConnections,
  createConnectionServer,
  refreshConnections,
  selectedApp,
  selectedConnection,
  setSelectedConnection,
  moduleConnections,
}) {
  const [label, setLabel] = useState("");
  const [connectionParams, setConnectionParams] = useState({});
  const [apiKey, setApiKey] = useState("");
  const [createButtonDisabled, setcreateButtonDisabled] = useState(true);
  const [modalSelectConnection, setModalSelectConnection] = useState("");
  const [isCreateNew, setIsCreateNew] = useState(false);

  // const [postMessegeListener, setPostMessegeListener] = useState(null);

  useEffect(() => {
    if (label) setcreateButtonDisabled(false);
    else setcreateButtonDisabled(true);
  }, [label, apiKey]);

  useEffect(() => {
    window.addEventListener("message", listener1, false);

    return () => {
      window.removeEventListener("message", listener1);
    };
  }, []);

  const listener1 = (event) => {
    if (event.data == "check connections") {
      refreshConnections(moduleDetails.id);
    }
  };

  const createConnection = () => {
    if (label && moduleDetails.integration_app_connection_id) {
      createConnectionServer(
        { label, ...connectionParams },
        moduleDetails.integration_app_connection_id
      );

      handleClose();
    }
  };

  const drawConnectionFields = (parameter) => {
    const { type, label, name, required, help } = parameter;
    switch (type) {
      case "text":
        return (
          <div className="row" title={help}>
            <label htmlFor="" className="col-12">
              {label}:
            </label>
            <input
              type={type}
              required={required}
              onChange={(e) =>
                setConnectionParams({
                  ...connectionParams,
                  [name]: e.target.value,
                })
              }
              value={connectionParams[name]}
              className="col-6"
              name={name}
            />
          </div>
        );

      default:
        break;
    }
  };

  let { connection_parameters, is_auth } = moduleDetails;
  connection_parameters = JSON.parse(connection_parameters);
  return (
    <>
      <Modal dialogClassName="connect-modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          {/*<Modal.Title>Create connection</Modal.Title>*/}
        </Modal.Header>
        <div
          className="connect-modal-head"
          style={{ padding: "0px 0px 30px 0px" }}
        >
          {/* <span style={{ fontSize: "26px" }}>Create connection</span>
          <br />
          <span style={{ fontSize: "20px" }}>{moduleDetails.label}</span> */}
          <div className="head">
            <img src={selectedApp.image} alt="" />{" "}
            <h1> {selectedApp.label} Connect</h1>
          </div>
        </div>
        {(!moduleConnections.length || isCreateNew) && is_auth == "0" ? (
          <Modal.Body>
            <div className="container">
              <div className="row">
                <label htmlFor="" className="col-12">
                  Connection name:
                </label>
                <input
                  type="text"
                  onChange={(e) => setLabel(e.target.value)}
                  value={label}
                  className="col-6"
                  name="label"
                />
              </div>

              {connection_parameters.map((parameter) =>
                drawConnectionFields(parameter)
              )}
            </div>
          </Modal.Body>
        ) : (
          <></>
        )}

        {moduleConnections.length && !isCreateNew ? (
          <>
            <Modal.Body>
              <div className="container">
                <div className="row">
                  <label htmlFor="" className="col-12">
                    Select connection:
                  </label>
                  <select
                    name="connection"
                    id=""
                    className="col-6"
                    onChange={(e) => {
                      // setSelectedConnection(e.target.value);
                      setModalSelectConnection(e.target.value);
                    }}
                  >
                    <option value="">Select connection</option>
                    {moduleConnections.map((connection) => (
                      <option value={connection.id}>{connection.label}</option>
                    ))}
                  </select>
                </div>
              </div>
            </Modal.Body>
          </>
        ) : (
          <></>
        )}
        <Modal.Footer className="connect-modal-footer">
          {/*<Button variant="secondary" onClick={handleClose}>*/}
          {/*  Close*/}
          {/*</Button>*/}
          {!moduleConnections.length || isCreateNew ? (
            <>
              {is_auth == "0" ? (
                <Button
                  variant="primary"
                  disabled={createButtonDisabled}
                  onClick={createConnection}
                >
                  Create
                </Button>
              ) : (
                <Button
                  variant="primary"
                  // disabled={createButtonDisabled}
                  onClick={() => {
                    const popup = window.open(
                      connection_parameters.connectionUrl
                    );
                    popup.postMessage(
                      "check connections",
                      "http://localhost:3000"
                    );
                  }}
                >
                  Connect
                </Button>
              )}
            </>
          ) : (
            <></>
          )}
          {moduleConnections.length && !isCreateNew ? (
            <>
              <Button
                variant="primary"
                disabled={modalSelectConnection ? false : true}
                onClick={() => {
                  const selectedConn = moduleConnections.find(
                    (conn) => conn.id == modalSelectConnection
                  );
                  if (selectedConn) setSelectedConnection(selectedConn);
                  handleClose();
                }}
              >
                Select
              </Button>

              <button
                className="new-connection-btn"
                onClick={() => setIsCreateNew(true)}
              >
                <span className="purple">+</span> Add new connection
              </button>
            </>
          ) : (
            <></>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
