import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

function AltImagesTextArea(props) {
  let { mediaAltText = "", changeImagesTextArea, t } = props;
  const maxLength = 150;
  const [charactersCount, setCharactersCount] = useState(0);

  useEffect(() => {
    //add validation
    mediaAltText = mediaAltText || "";
    if (mediaAltText.length > maxLength) {
      mediaAltText = mediaAltText.slice(0, maxLength - 3);
      mediaAltText += "...";
    }
    setCharactersCount(mediaAltText.length);
  }, [mediaAltText]);

  const onChange = (e) => {
    const { value } = e.target;
    if (value.length > maxLength) {
      value = value.slice(0, maxLength - 3);
      value += "...";
    }
    setCharactersCount(value.length);
    changeImagesTextArea(e);
  };
  return (
    <div>
      <h3>{t("rightBar-customize-page-layout-alt-images")}</h3>
      <textarea
        name="mediaAltText"
        cols="50"
        rows="5"
        value={mediaAltText}
        onChange={onChange}
        maxLength={maxLength}
        style={{width:"100%"}}
      />
      <br />
      {charactersCount} / {maxLength}
    </div>
  );
}

export default withTranslation()(AltImagesTextArea);
