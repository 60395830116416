
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./poweredby.css";

import { withTranslation } from "react-i18next";
const PoweredBy = (props) => {
    const { t } = props;

  return (
      <div className="poweredByDiv">
          <a href="https://knowyyy.com" target="_blank">Powered by Knowy</a></div>
  );
};


export default withTranslation()(
  connect()(PoweredBy)
);
