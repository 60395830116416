import React from "react";
import ConfigActionButtons from "./ConfigActionButtons";
import { withTranslation } from "react-i18next";
class Seo extends React.Component {
  handleChangeFavicon = (image) => {
    this.props.changeConfigureDetails({
      target: { name: "favicon", value: image },
    });
  };

  render() {
    const { title, description } = this.props.data.seo;
    const { title: quizTitle } = this.props.data;
    const { t } = this.props;
    return (
      <div className="">
        <h2>{t("configuration-seo")}</h2>

        <div className="fields">
          <label>{t("configuration-seo-page-title")}</label>
          <input
            type="text"
            name="title"
            value={title}
            onChange={(e) => this.props.changeSeoDetails(e)}
            className="form-control"
          />
        </div>
        <div className="fields start-flex">
          <label>{t("configuration-seo-description")}</label>
          <textarea
            rows="6"
            type="text"
            name="description"
            value={description}
            onChange={(e) => this.props.changeSeoDetails(e)}
            className="form-control"
          />
        </div>
        <ConfigActionButtons
          selectedFunc={this.props.saveEditedQuiz}
          toast={this.props.toast}
        />
      </div>
    );
  }
}

export default withTranslation()(Seo);
