import React, { useState } from "react";

export default function AboutColumn(props) {
  const { selectedApp, selectedModule, moduleConnections, setShow } = props;

  if (!selectedApp) return <></>;
  return (
    <>
      <div className="col-3 mt-5 connect-about-app">
        {" "}
        <div className="head">
          <img src={selectedApp.image} alt="" />
          <h1>{selectedApp.label}</h1>
        </div>
        {selectedModule ? (
            <>
              <h5 className="font-weight-bold" style={{paddingTop:"10px"}}>
                {" "}
                <strong>About {selectedModule.label}</strong>
              </h5>
              <p>{selectedModule.description}</p>{" "}

            </>
        ) : (
            <></>
        )}
        <h5 className="font-weight-bold" style={{paddingTop:"15px"}}>
          {" "}
          <strong>About {selectedApp.label}</strong>
        </h5>
        <p>{selectedApp.description}</p>{" "}

        {selectedModule ? (
        <button className="btn btn-success" onClick={() => setShow(true)}>
          Connect to {selectedApp.label}
        </button>
        ) : (
            <></>
        )}
      </div>
    </>
  );
}
