import React, { useState, useEffect } from "react";
import ConnectionsModal from "./Connections/ConnectionsModal";
import ModuleItem from "./Modules/ModuleItem";
import AppItem from "./AppItem";

// import appServices from "./services";
// import connectionSevices from "../Connections/services";
import ConnectionItem from "./Connections/ConnectionItem";
import Parameters from "./Modules/Parameters/Index.jsx";
// import paramsServices from "../Modules/parameters/services";
import { withTranslation } from "react-i18next";
import "./connectApps.css";
import {
  getAppsAction,
  getModulesAction,
  getModulesConnectionsAction,
  getModulesConnectionsOptionsAction,
  createConnectionAction,
  setTriggerAction,
  getAllTriggersAction,
  updateTriggerActivityAction,
  triggerRpcAction,
  getAllTriggersForQuizAction,
  getTriggerFullDetailsAction,
  resetEditObjectAction,
  updateTriggerNameAction,
  deleteTriggerAction,
} from "../../../redux/actions";
import { connect } from "react-redux";
import { stripTags } from "../../../utils";
import AboutColumn from "./AboutColumn";
import { disabled } from "glamor";
import RightBarTabsContainer from "../RightBar/RightBarTabContainer";
import TriggerList from "./TriggerList";

function AppsList(props) {
  const {
    integrationApps,
    actions,
    integrationsModules,
    integrationsModulesConnections,
    moduleConnectionsOptions,
    integrationTriggers,
    arrayOfData,
    variables,
    moduleFieldsOptions,
    moduleAdditionalptions,
    connectEditObject,
  } = props;
  const [apps, setApps] = useState([]);
  const [appModules, setAppModules] = useState([]);
  const [moduleConnections, setModuleConnections] = useState([]);
  const [selectedApp, setSelectedApp] = useState(null);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [show, setShow] = useState(false);
  const [params, setParams] = useState({});
  const [isValidated, setIsValidated] = useState(true);

  const [currentTriggers, setCurrentTriggers] = useState([]);

  const [selectedTrigger, setSelectedTrigger] = useState(null);

  const [connectionButtonDisabled, setConnectionButtonDisabled] =
    useState(true);
  const [dataWithVariables, setDataWithVariables] = useState([]);
  useEffect(() => {
    actions.getApps();
    getTriggers();
    const matrixQuestions = arrayOfData.reduce((allMatrix, step) => {
      if (step.questionType === "matrix") {
        step.matrix.rows.forEach((row) => {
          allMatrix.push(
            `${stripTags(step.slideName)}_${stripTags(row.value)}`
          );
        });
      }
      return allMatrix;
    }, []);
    const questionsSteps = arrayOfData
      .filter((itr) => itr.type === "question" && itr.questionType != "matrix")
      .map((itr) => stripTags(itr.title));
    let contactSteps = arrayOfData.filter((itr) => itr.type === "contact");
    const allInputs = contactSteps.reduce((allInputs, step) => {
      const { inputs = [] } = step;
      inputs.forEach((input) => {
        if (input.type === "location") {
          input.inputs.forEach((locationInput) => {
            if (locationInput.show) {
              allInputs.push(`${input.label}_${locationInput.label}`);
            }
          });
        } else allInputs.push(input.label);
      });
      return allInputs;
    }, []);

    const variablesArray = variables.map((variable) => variable.name);

    setDataWithVariables([
      ...questionsSteps,
      ...matrixQuestions,
      ...allInputs,
      ...variablesArray,
      "score",
    ]);
  }, []);

  useEffect(() => {
    setApps(integrationApps);
    setAppModules([]);
    setModuleConnections([]);
    setParams({});
    // setCurrentTriggers([]);
    setSelectedTrigger(null);
  }, [integrationApps]);

  useEffect(() => {
    setAppModules(integrationsModules);
    setModuleConnections([]);
    setParams({});
    // setCurrentTriggers([]);
    setSelectedTrigger(null);
  }, [integrationsModules]);

  useEffect(() => {
    if (selectedApp && selectedApp.id) {
      actions.getModules(selectedApp.id);
      setModuleConnections([]);
      setSelectedModule(null);
      setSelectedConnection(null);
      setParams({});
    }
  }, [selectedApp]);

  useEffect(() => {
    if (selectedModule && selectedModule.id) {
      actions.getModulesConnections(selectedModule.id);
    }
  }, [selectedModule]);

  useEffect(() => {
    setModuleConnections(integrationsModulesConnections);
    setParams({});
  }, [integrationsModulesConnections]);

  useEffect(() => {
    if (selectedConnection) {
      setConnectionButtonDisabled(false);
      setParams({});
      getTriggers();
    }
  }, [selectedConnection]);

  // useEffect(() => {
  //   if (moduleConnectionsOptions) {
  //   }
  // }, [moduleConnectionsOptions]);
  useEffect(() => {
    if (integrationTriggers) {
      setCurrentTriggers(integrationTriggers);
    }
  }, [integrationTriggers]);

  const closeConnectionModal = () => {
    setShow(false);
  };

  const getTriggers = () => {
    actions.getAllTriggers(
      // selectedConnection.id,
      // selectedModule.id,
      props.selectedDetails.quizDetails.id
    );
  };

  const validatePreSend = () => {
    let isValid = true;

    let parameters =
      selectedModule && selectedModule.module_parameters
        ? JSON.parse(selectedModule.module_parameters)
        : [];

    if (
      connectEditObject &&
      connectEditObject.module &&
      connectEditObject.module.module_parameters
    ) {
      parameters = JSON.parse(connectEditObject.module.module_parameters);
    }
    const requiredParams = parameters.filter((itr) => itr.required);
    if (!parameters.length) {
      isValid = false;
    } else {
      if (
        !Object.keys(params).length ||
        Object.keys(params).length < requiredParams.length
      ) {
        isValid = false;
      }
      Object.keys(params).forEach((key) => {
        if (requiredParams.includes(key) && !params[key]) {
          isValid = false;
        }
      });
    }
    if (!isValid) {
      alert("Please fill all the required fields");
      return;
    }
    // setIsValidated(isValid);
    setTriger(isValid);
  };

  const setTriger = (parameters) => {
    // console.log(selectedConnection);
    const quizId = props.selectedDetails.quizDetails.id;

    if (selectedTrigger) {
      selectedTrigger.img = null;
      // selectedTrigger.connection_config[selectedTrigger.module_id] = params
    }
    const filteredParams = {};
    Object.entries(params).forEach(([k, v]) => {
      if (v) {
        filteredParams[k] = v;
      }
    });
    actions.setTrigger(
      filteredParams,
      selectedConnection ? selectedConnection : connectEditObject.connection,
      selectedModule ? selectedModule.id : selectedTrigger.module_id,
      quizId,
      selectedTrigger,
      selectedApp ? selectedApp.id : selectedTrigger.app_id
    );
    setSelectedApp(null);
    setSelectedModule(null);
    setSelectedConnection(null);
    setAppModules([]);
    setModuleConnections([]);
    setParams({});
  };

  const updateTriggerName = (id, name) => {
    actions.updateTriggerName(props.selectedDetails.quizDetails.id, id, name);
  };

  const setExistingTrigger = (trigger) => {
    const tempParams = {};

    const module_id = trigger.module_id;

    Object.entries(trigger.connection_config[module_id]).forEach(
      ([key, value]) => {
        if (key != "name" && key != "selectedUserConnectionId")
          tempParams[key] = value;
      }
    );
    setSelectedTrigger(trigger);
    setParams(tempParams);
  };
  //   const handleModuleWithConnection = () => {
  //     if (selectedConnection && selectedConnection.id) {
  //       actions.getModulesConnectionsOptions(
  //         selectedModule.name,
  //         selectedApp.id,
  //         selectedConnection.id,
  //         params
  //       );
  //     }
  //   };
  const createNew = (
    <div className="connectApp row bg-white p-4" style={{ height: "100%" }}>
      <div
        className={`${
          selectedApp ? "col-2 allow-to-scroll" : "col custom-columns"
        }`}
        style={{ height: "90vh" }}
      >
        {apps.map((itr) => {
          return (
            <AppItem
              key={`${itr.label.replaceAll(" ", "")}-${itr.id}`}
              selectedApp={selectedApp}
              appDetails={itr}
              setSelectedApp={setSelectedApp}
            />
          );
        })}
      </div>
      {selectedApp ? (
        <div className="col-2 mt-5">
          {/*<h1>Choose module:</h1>*/}
          {appModules.map((itr) => {
            return (
              <ModuleItem
                moduleDetails={itr}
                selectedModule={selectedModule}
                appImage={selectedApp.image}
                setSelectedModule={setSelectedModule}
              />
            );
          })}
        </div>
      ) : (
        <></>
      )}
      {selectedModule && selectedConnection ? (
        <div
          className="col-7 mt-5 connect-parameters"
          style={{
            background: "#F2F1FF",
            borderRadius: "7px",
            padding: "35px",
            height: "100%",
            paddingBottom: "130px",
            overflowY: "auto",
          }}
        >
          <h4>
            {selectedConnection.label}{" "}
            <button
              className="btn btn-link btn-lg"
              onClick={() => {
                setSelectedConnection(null);
                setShow(true);
              }}
            >{`[Change connection]`}</button>
          </h4>
          <br />
          {selectedModule.module_parameters && (
            <Parameters
              moduleParameters={selectedModule.module_parameters}
              moduleName={selectedModule.name}
              params={params}
              selectedIntegrationAppId={selectedApp.id}
              selectedConnection={selectedConnection}
              setParams={setParams}
              getModulesConnectionsOptions={
                actions.getModulesConnectionsOptions
              }
              moduleConnectionsOptions={moduleConnectionsOptions}
              selectedTrigger={selectedTrigger}
              setSelectedTrigger={setSelectedTrigger}
              dataWithVariables={dataWithVariables}
              moduleFieldsOptions={moduleFieldsOptions}
              triggerRpc={actions.triggerRpc}
              moduleAdditionalptions={moduleAdditionalptions}
            />
          )}

          <div className="mt-3">
            <button
              disabled={!isValidated}
              className={
                isValidated ? "btn btn-lg purple-btn" : "btn btn-success"
              }
              onClick={() => validatePreSend()}
            >
              {selectedTrigger ? "Update" : "Create"}
            </button>
          </div>
        </div>
      ) : (
        <>
          <AboutColumn
            selectedApp={selectedApp}
            selectedModule={selectedModule}
            moduleConnections={moduleConnections}
            setShow={setShow}
          />
        </>
      )}
      {selectedModule ? (
        <ConnectionsModal
          selectedConnection={selectedConnection}
          setSelectedConnection={setSelectedConnection}
          show={show}
          moduleDetails={selectedModule}
          refreshConnections={actions.getModulesConnections}
          setModuleConnections={setModuleConnections}
          handleClose={closeConnectionModal}
          createConnectionServer={actions.createConnection}
          selectedApp={selectedApp}
          moduleConnections={moduleConnections}
        />
      ) : (
        <></>
      )}
    </div>
  );

  const myTriggers = (
    <>
      <TriggerList
        apps={apps}
        currentTriggers={currentTriggers}
        setExistingTrigger={setExistingTrigger}
        updateTriggerActivity={props.actions.updateTriggerActivity}
        selectedConnection={selectedConnection}
        selectedModule={selectedModule}
        params={params}
        actions={actions}
        selectedApp={selectedApp}
        isValidated={isValidated}
        connectEditObject={connectEditObject}
        setParams={setParams}
        moduleConnectionsOptions={moduleConnectionsOptions}
        dataWithVariables={dataWithVariables}
        moduleFieldsOptions={moduleFieldsOptions}
        moduleAdditionalptions={moduleAdditionalptions}
        validatePreSend={validatePreSend}
        updateTriggerName={updateTriggerName}
        deleteTrigger={(id) =>
          props.actions.deleteTrigger(props.selectedDetails.quizDetails.id, id)
        }
      />
    </>
  );

  const tabsObj = { "Create New +": createNew, "My Triggers": myTriggers };

  const defaultTab = currentTriggers.length ? "My Triggers" : "Create New +";

  return (
    <div className="connect-main-container" style={{ height: "100%" }}>
      <RightBarTabsContainer formTabsObj={tabsObj} defaultTab={defaultTab} />
    </div>
  );
}

const mapStateToProps = (state) => {
  const {
    integrationApps,
    integrationsModules,
    integrationsModulesConnections,
    moduleConnectionsOptions,
    selectedDetails,
    integrationTriggers,
    moduleFieldsOptions,
    moduleAdditionalptions,
    connectEditObject,
  } = state;

  return {
    integrationApps,
    integrationsModules,
    integrationsModulesConnections,
    moduleConnectionsOptions,
    selectedDetails,
    integrationTriggers,
    moduleFieldsOptions,
    moduleAdditionalptions,
    connectEditObject,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      getApps: () => dispatch(getAppsAction()),
      getModules: (appId) => dispatch(getModulesAction(appId)),
      getModulesConnections: (moduleId) =>
        dispatch(getModulesConnectionsAction(moduleId)),
      getModulesConnectionsOptions: (
        moduleName,
        moduleId,
        connectionId,
        params = {},
        isFields = false
      ) =>
        dispatch(
          getModulesConnectionsOptionsAction({
            moduleName,
            moduleId,
            connectionId,
            params,
            isFields,
          })
        ),
      createConnection: (connectionDetails, appId) =>
        dispatch(createConnectionAction(connectionDetails, appId)),
      setTrigger: (
        paramsObj,
        connectionObj,
        appID,
        quizId,
        selectedTrigger,
        appId
      ) =>
        dispatch(
          setTriggerAction(
            paramsObj,
            connectionObj,
            appID,
            quizId,
            selectedTrigger,
            appId
          )
        ),
      // getAllTriggers: (conn, module, quiz) =>
      //   dispatch(getAllTriggersAction(conn, module, quiz)),
      getAllTriggers: (quiz) => dispatch(getAllTriggersForQuizAction(quiz)),

      updateTriggerActivity: (triggerId, isActive) =>
        dispatch(updateTriggerActivityAction(triggerId, isActive)),
      triggerRpc: (connectionObj, triggerArr, moduleParams, options) =>
        dispatch(
          triggerRpcAction(connectionObj, triggerArr, moduleParams, options)
        ),
      getTriggerFullDetails: (triggerId) =>
        dispatch(getTriggerFullDetailsAction(triggerId)),
      resetEditObject: () => dispatch(resetEditObjectAction()),
      updateTriggerName: (triggerId, name, quizId) =>
        dispatch(updateTriggerNameAction(triggerId, name, quizId)),
      deleteTrigger: (quizId, triggerId) =>
        dispatch(deleteTriggerAction(quizId, triggerId)),
    },
  };
};
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(AppsList)
);
