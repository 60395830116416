import { websiteUrl } from "../../../config";

export class Question {
  constructor(title, counter) {
    this.id = `Q_${Math.round(Math.random() * 999999)}`;
    this.type = "question";
    this.counter = counter;
    this.stepIndex = 1;
    this.title = title;
    this.subtitle = "Subtitle";
    this.feedback = { focus: "", correctMessage: "", incorrectMessage: "" };
    this.showFeedback = true;
    this.showMedia = false;
    this.mediaType = "video";
    this.mediaVideoSrc = "";
    this.mediaImageSrc = "";
    this.layout = "1";
    this.required = false;
    this.questionType = "singleSelect";
    this.buttonText = "Next!";
    this.options = [];
    this.matrix = {
      rows: [
        {
          id: `M_R_${Math.floor(Math.random() * 999999999999)}`,
          value: "",
          columns: [
            { id: `M_C_${Math.floor(Math.random() * 999999999999)}` },
            {
              id: `M_C_${Math.floor(Math.random() * 999999999999)}`,
              value: "",
            },
            {
              id: `M_C_${Math.floor(Math.random() * 999999999999)}`,
              value: "",
            },
          ],
        },
        {
          id: `M_R_${Math.floor(Math.random() * 999999999999)}`,
          value: "",
          columns: [
            { id: `M_C_${Math.floor(Math.random() * 999999999999)}` },
            {
              id: `M_C_${Math.floor(Math.random() * 999999999999)}`,
              value: "",
            },
            {
              id: `M_C_${Math.floor(Math.random() * 999999999999)}`,
              value: "",
            },
          ],
        },
        {
          id: `M_R_${Math.floor(Math.random() * 999999999999)}`,
          value: "",
          columns: [
            { id: `M_C_${Math.floor(Math.random() * 999999999999)}` },
            {
              id: `M_C_${Math.floor(Math.random() * 999999999999)}`,
              value: "",
            },
            {
              id: `M_C_${Math.floor(Math.random() * 999999999999)}`,
              value: "",
            },
          ],
        },
      ],
      columns: [
        { id: `M_C_${Math.floor(Math.random() * 999999999999)}`, value: "" },
        { id: `M_C_${Math.floor(Math.random() * 999999999999)}`, value: "" },
        { id: `M_C_${Math.floor(Math.random() * 999999999999)}`, value: "" },
      ],
      allowMultipleSelection: false,
    };
    this.logics = {
      before: [],
      after: [],
      otherCasesRedirect: {
        before: "",
        after: "",
      },
    };
    this.ratingIcon = "FaStar";
    this.ratingIconColor = "black";
    this.ratingIconEmptyColor = "grey";
    this.ratingIconSize = 24;
    this.style = {
      title: {
        color: "#000",
        fontWeight: "",
        fontSize: "28",
        mobileFontSize: "28",
        tabletFontSize: "28",
        fontType: "",
        fontFamily: "",
        fontStyle: "",
        textAlign: "center",
        padding: {
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
          mobileTop: "0",
          mobileRight: "0",
          mobileBottom: "0",
          mobileLeft: "0",
          tabletTop: "0",
          tabletRight: "0",
          tabletBottom: "0",
          tabletLeft: "0",
        },
      },
      subtitle: {
        color: "#000",
        fontWeight: "",
        fontSize: "16",
        mobileFontSize: "16",
        tabletFontSize: "16",
        fontType: "",
        fontFamily: "",
        fontStyle: "",
        textAlign: "center",
        padding: {
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
          mobileTop: "0",
          mobileRight: "0",
          mobileBottom: "0",
          mobileLeft: "0",
          tabletTop: "0",
          tabletRight: "0",
          tabletBottom: "0",
          tabletLeft: "0",
        },
      },
      answers: {
        buttonsTextColor: "black",
        buttonsBackgroundColor: "",
        buttonsBorderSize: "",
        buttonsBorderRadius: "",
        buttonsBorderColor: "",
        buttonsHoverTextColor: "",
        buttonsHoverBackgroundColor: "",
        buttonsHoverBorderSize: "",
        buttonsHoverBorderRadius: "",
        buttonsHoverBorderColor: "",
        fontSize: "20",
        mobileFontSize: "20",
        tabletFontSize: "20",
        fontFamily: "",
        fontStyle: "",
        fontWeigth: "",
        padding: {
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
          mobileTop: "0",
          mobileRight: "0",
          mobileBottom: "0",
          mobileLeft: "0",
          tabletTop: "0",
          tabletRight: "0",
          tabletBottom: "0",
          tabletLeft: "0",
        },
      },
      media: {
        size: "25",
        borderSize: "0",
        borderRadius: "0",
        borderColor: "",
        padding: {
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
          mobileTop: "0",
          mobileRight: "0",
          mobileBottom: "0",
          mobileLeft: "0",
          tabletTop: "0",
          tabletRight: "0",
          tabletBottom: "0",
          tabletLeft: "0",
        },
      },
      button: {
        color: "#000",
        background: "",
        borderSize: "0",
        borderColor: "",
        borderRadius: "0",
        hoverColor: "",
        hoverBackground: "",
        hoverBorderSize: "0",
        hoverBorderColor: "",
        hoverBorderRadius: "0",
        fontWeight: "",
        fontSize: "",
        mobileFontSize: "",
        tabletFontSize: "",
        fontType: "",
        fontFamily: "",
        fontStyle: "",
        padding: {
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
          mobileTop: "0",
          mobileRight: "0",
          mobileBottom: "0",
          mobileLeft: "0",
          tabletTop: "0",
          tabletRight: "0",
          tabletBottom: "0",
          tabletLeft: "0",
        },
        textAlign: "center",
      },
      forms: {
        color: "rgba(0, 0, 0, 1)",
        fontWeight: "",
        fontSize: "",
        mobileFontSize: "",
        tabletFontSize: "",
        fontType: "",
        fontFamily: "Open Sans",
        fontStyle: "",
        padding: { top: "0", right: "0", bottom: "0", left: "0" },
        textAlign: "center",
        formTextAlign: "center",
        formWidth: "auto",
      },
      alert: {
        fontSize: "14",
        mobileFontSize: "14",
        tabletFontSize: "14",
        fontFamily: "Open Sans",
        textAlign: "center",
        width: "auto",
        correct: {
          text: "Correct!",

          background: "rgba(0,0,0,0.5)",
          color: "#fff",
          borderColor: "black",
          borderSize: "0",
        },
        incorrect: {
          text: "Incorrect",
          background: "rgba(0,0,0,0.5)",
          color: "#fff",
          borderColor: "black",
          borderSize: "0",
        },
      },
      bar: {
        barType: "",
        fontSize: "14",
        mobileFontSize: "14",
        tabletFontSize: "14",
        color: "rgba(0, 0, 0, 1)",
        textAlign: "center",
      },
      matrix: {
        rowColor: "#000",
        columnColor: "#000",
        rowFontSize: "28",
        mobileRowFontSize: "28",
        tabletRowFontSize: "28",
        columnFontSize: "28",
        mobileColumnFontSize: "28",
        tabletColumnFontSize: "28",
        fontFamily: "",
        borderSize: "0",
        borderColor: "#000",
      },
    };
    this.answerWithImages = true;
    this.withHeader = false;
    this.withFooter = false;
    this.withPagination = false;
    this.withProgressBar = false;
    this.useTitleCustomStyle = false;
    this.useSubTitleCustomStyle = false;
    this.useAnswersCustomStyle = false;
    this.required = true;
  }
}

export class Option {
  constructor(title, correct) {
    this.id = `O_${Math.round(Math.random() * 999999)}`;
    this.title = title;
    this.score = 0;
    this.correct = correct;
    this.redirectTo = "";
    this.src = "";
    this.optionType = "text";
    this.placeholder = "";
  }
}
