import React, { useEffect, useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";
import CountrySelect from "./CountrySelect";
import { IoTrashOutline, IoClose } from "react-icons/io5";
import Modal from "react-bootstrap/esm/Modal";
import TextInput from "react-autocomplete-input";
import JsonAtributePicker from "./JsonAtributePicker";
import AcTest from "../../../AcTest/Index";

function AddContactSelectField(props) {
  const {
    step,
    deleteInput,
    id,
    toggleError,
    handleInputName,
    index,
    handleInputVariableName,
    handleContactInputs,
    type,
    dataWithVariables,
    jsonVarsArray
  } = props;
  const [inputCollapse, setInputCollapse] = useState(false);
  const [selectOptions, setSelectOptions] = useState([]);
  const [newOptionValue, setNewOptionValue] = useState("");
  const [isShowModal, setIsShowModal] = useState(false);
  const [bulkAnswersAsString, setBulkAnswersAsString] = useState("");
  const [jsonVars, setJsonVars] = useState([]);
  const [showJsonModal, setShowJsonModal] = useState(false);

  useEffect(() => {
    if (step.selectOptions) {
      setSelectOptions(step.selectOptions);
    }
  }, []);

  useEffect(() => {
    if (jsonVarsArray) {
      setJsonVars(jsonVarsArray);
    }
  }, [jsonVarsArray]);

  const handleNewOption = () => {
    if (!newOptionValue) {
      alert("cant add empty option");
      return;
    }
    const newOptions = [
      ...selectOptions,
      {
        id: `SO_${Math.random() * 999999}`,
        name: newOptionValue,
      },
    ];
    const duplicatedValues = isArrayContainsDuplicateValues(newOptions);
    if (duplicatedValues.length)
      return alert(`${newOptionValue} is duplicated`);
    setSelectOptions(newOptions);
    handleContactInputs(
      { target: { name: "selectOptions", checked: false, value: newOptions } },
      step.id,
      id
    );
    setNewOptionValue("");
  };

  const deleteOptions = (optionId) => {
    const selectedIndex = selectOptions.findIndex((opt) => opt.id === optionId);
    if (selectedIndex >= 0) {
      selectOptions.splice(selectedIndex, 1);
      const newOptions = JSON.parse(JSON.stringify(selectOptions));
      setSelectOptions(newOptions);
      handleContactInputs(
        {
          target: { name: "selectOptions", checked: false, value: newOptions },
        },
        step.id,
        id
      );
    }
  };

  const OpenBulkAnswersModal = () => {
    setIsShowModal(true);
    setBulkAnswersAsString(
      selectOptions.map((option) => option.name).join("\n")
    );
  };

  const isArrayContainsDuplicateValues = (arrayToCheck) => {
    //return []; //Remove to active validation;
    const reducedArray = arrayToCheck.reduce(
      (obj, itr) => {
        if (obj.allValues[itr.name]) {
          obj.duplicatedValues.push(itr.name);
        }
        obj.allValues[itr.name] = itr.name;
        return obj;
      },
      { duplicatedValues: [], allValues: {} }
    );
    return reducedArray.duplicatedValues;
  };

  const saveBulkAnswers = () => {
    const newOptions = bulkAnswersAsString
      .split("\n")
      .filter((itr) => itr)
      .map((option) => {
        return {
          name: option,
        };
      });
    const duplicatedValues = isArrayContainsDuplicateValues(newOptions);
    if (duplicatedValues.length)
      return alert(`Duplicated values found: ${duplicatedValues.join(", ")}`);
    setSelectOptions(newOptions);
    handleContactInputs(
      { target: { name: "selectOptions", checked: false, value: newOptions } },
      step.id,
      id
    );
    setIsShowModal(false);
  };


  const addIttirateJsonVars = (jsonVar, JsonName) => {
    const newVarName = `{{${JsonName + jsonVar}}}`;
    setSelectOptions([...selectOptions, { name: newVarName }]);
    handleContactInputs(
      { target: { name: "selectOptions", checked: false, value: [...selectOptions, { name: newVarName }] } },
      step.id,
      id
    );
    setShowJsonModal(false)
  }



  const AddOptionOnEnter = (e) => {
    if (e.which === 13 && newOptionValue) {
      handleNewOption();
    }
  };
  return (
    <>
      <div className="feildHeader">
        <span
          className="pointer"
          onClick={() => setInputCollapse(!inputCollapse)}
        >
          {handleInputName(step, index)}
        </span>
        <IoTrashOutline
          type="button"
          title="rightBar-customize-override"
          onClick={() => deleteInput(id, step.id)}
          color={"rgba(0, 68, 179, 0.42)"}
          size={22}
        />
        {/*<a*/}
        {/*  title=""*/}
        {/*  className="bin"*/}
        {/*  onClick={() => deleteInput(id, step.id)}*/}
        {/*></a>*/}
      </div>
      <Collapse in={inputCollapse}>
        <div className="fieldOptions" id={` item${index}`}>
          <h4>Label:</h4>
          {/* <input
            type="text"
            name="label"
            value={step.label}
            placeholder="Name:"
            onBlur={(e) => handleInputVariableName(e, step.id, id)}
            onChange={(e) => props.handleContactInputs(e, step.id, id)}
          /> */}

          <AcTest
            changeOnSelect={(trigger, slug) => "{{" + slug + "}}"}
            trigger={["{{"]}
            options={{ "{{": [...dataWithVariables] }}
            type="text"
            name="label"
            value={step.label}
            placeholder="Name:"
            className="autocomplete-form-input"
            rows={1}
            maxOptions={50}
            onChange={(e) => props.handleContactInputs({ target: { name: "label", value: e } }, step.id, id)}
            onBlur={(e) => handleInputVariableName({ target: { name: "label", value: e } }, step.id, id)}

          />
          <h4>Placeholder:</h4>
          {/* <input
            type="text"
            placeholder=""
            name="placeholder"
            value={step.placeholder}
            onChange={(e) => handleContactInputs(e, step.id, id)}
          /> */}
          <AcTest
            changeOnSelect={(trigger, slug) => "{{" + slug + "}}"}
            trigger={["{{"]}
            options={{ "{{": [...dataWithVariables] }}
            type="text"
            name="placeholder"
            value={step.placeholder}
            placeholder="Placehoder:"
            className="autocomplete-form-input"
            rows={1}
            maxOptions={50}
            onChange={(e) => props.handleContactInputs({ target: { name: "placeholder", value: e } }, step.id, id)}
          />

          <h4>Add New Option</h4>
          <div style={{ display: "flex" }}>
            {/* <input
              type="text"
              placeholder=""
              name="newOptionValue"
              value={newOptionValue}
              onChange={(e) => {
                setNewOptionValue(e.target.value);
              }}
              onKeyDown={AddOptionOnEnter}
            /> */}

            <TextInput
              changeOnSelect={(trigger, slug) => "{{" + slug + "}}"}
              trigger={["{{"]}
              options={{ "{{": [...dataWithVariables] }}
              type="text"
              name="newOptionValue"
              value={newOptionValue}
              placeholder=""
              className="autocomplete-form-input"
              rows={1}
              maxOptions={50}
              onChange={(e) => setNewOptionValue(e)}
              onKeyDown={AddOptionOnEnter}
            />
            <button
              style={{ padding: "5px", margin: "5px 0px 5px 5px", lineHeight: 0, height: "25px", backgroundColor: "#0046B5" }}

              className="btn btn-secondary"
              onClick={() => handleNewOption()}
            >
              +
            </button>
          </div>
          <div className="d-flex flex-column">
            <a className="" onClick={OpenBulkAnswersModal}>
              + Bulk Answers
            </a>
            <a onClick={() => setShowJsonModal(true)}> + Itirate Json Data</a>
          </div>

          <div>
            {selectOptions.map((el) => {
              const name = el.name;
              return (
                <span key={el.id} className="d-flex addOptionSelect border">
                  {name}
                  <span
                    className="p-1 pointer"
                    onClick={() => deleteOptions(el.id)}
                  >
                    <IoClose size={20} />
                  </span>
                </span>
              );
            })}
          </div>

          <label>
            <input
              type="checkbox"
              name="isRtl"
              checked={step.isRtl}
              onChange={(e) => handleContactInputs(e, step.id, id)}
            />{" "}
            RTL
          </label>

          <label>
            <input
              type="checkbox"
              name="isMultiSelect"
              checked={step.isMultiSelect}
              onChange={(e) => handleContactInputs(e, step.id, id)}
            />{" "}
            Multi Select
          </label>

          <label>
            <input
              type="checkbox"
              name="isAutoComplete"
              checked={step.isAutoComplete}
              onChange={(e) => handleContactInputs(e, step.id, id)}
            />{" "}
            Auto Complete
          </label>

          <label>
            <input
              type="checkbox"
              name="required"
              checked={step.required}
              onChange={(e) => handleContactInputs(e, step.id, id)}
            />{" "}
            Required
          </label>

          <label>
            <input
              type="checkbox"
              name="hideLabel"
              checked={step.hideLabel}
              onChange={(e) => handleContactInputs(e, step.id, id)}
            />{" "}
            Hide Label
          </label>
          {toggleError(step)}
        </div>
      </Collapse>

      <Modal
        size="md"
        show={isShowModal}
        onHide={() => {
          setIsShowModal(false);
        }}
        centered
      >
        <div>
          <h2>Add answers in bulk</h2>
          <p>Each answer must be on a separate line.</p>

          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            value={bulkAnswersAsString}
            onChange={(e) => setBulkAnswersAsString(e.target.value)}
          ></textarea>
          <div>
            <button
              className="btn btn-primary"
              style={{ marginRight: "30px" }}
              onClick={saveBulkAnswers}
            >
              Save
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => setIsShowModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        size="md"
        show={showJsonModal}
        onHide={() => {
          setShowJsonModal(false);
        }}
        centered
      >
        <div>
          <h2> Itirate Json Data </h2>
          <JsonAtributePicker jsonVars={jsonVars} itirate={true} setShowJsonModal={setShowJsonModal} addIttirateJsonVars={addIttirateJsonVars} />


        </div>
      </Modal>
    </>
  );
}

export default AddContactSelectField;
