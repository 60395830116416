import React, { useEffect, useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import CountrySelect from "./CountrySelect";
import { IoTrashOutline } from "react-icons/io5";
import InputValidationConstans from "../../../../utils/json/inputValidationConstans.json";
import TextInput from "react-autocomplete-input";
import AcTest from "../../../AcTest/Index";
import locationInputsConstants from "../../../../utils/json/locationsInputsConstants.json";

function AddContactLocationField(props) {
  const {
    step,
    deleteLocationContainerFromContact,
    id,
    toggleError,
    handleInputName,
    index,
    handleInputVariableName,
    handleContactInputs,
    allInputsTypes,
    type,
    dataWithVariables,
    handleContactLocationInputs,
  } = props;
  const { inputs = [] } = step;
  const [inputCollapse, setInputCollapse] = useState(false);
  const [isInputOther, setIsInputOther] = useState(false);

  useEffect(() => {}, [dataWithVariables]);

  useEffect(() => {
    if (step.validationType === "other") {
      setIsInputOther(true);
    } else {
      setIsInputOther(false);
    }
  }, [step.validationType]);

  const drawPlaceHolderInput = (input) => {
    return (
      <>
        <h4>Placeholder:</h4>

        <AcTest
          changeOnSelect={(trigger, slug) => "{{" + slug + "}}"}
          trigger={["{{"]}
          options={{ "{{": [...dataWithVariables] }}
          type="text"
          name="placeholder"
          value={input.placeholder}
          placeholder="Placeholder:"
          className="autocomplete-form-input"
          rows={1}
          maxOptions={50}
          onChange={(e) =>
            handleContactLocationInputs(
              { target: { name: "placeholder", value: e } },
              step.id,
              input.id,
              id
            )
          }
        />
      </>
    );
  };
  const drawLabelInput = (input) => {
    return (
      <>
        <h4>Label:</h4>
        <AcTest
          changeOnSelect={(trigger, slug) => "{{" + slug + "}}"}
          trigger={["{{"]}
          options={{ "{{": [...dataWithVariables] }}
          type="text"
          name="label"
          value={input.label}
          placeholder="Label:"
          className="autocomplete-form-input"
          rows={1}
          maxOptions={50}
          onChange={(e) =>
            handleContactLocationInputs(
              { target: { name: "label", value: e } },
              step.id,
              input.id,
              id
            )
          }
        />
      </>
    );
  };

  const drawRequiredInput = (input) => {
    return (
      <label>
        <input
          type="checkbox"
          name="required"
          checked={input.required}
          onChange={(e) =>
            handleContactLocationInputs(e, step.id, input.id, id)
          }
        />{" "}
        Required
      </label>
    );
  };
  const drawFreeTextToggle = (input) => {
    const countryInput = inputs.find((itr) => itr.type === "Country");
    if (!countryInput.show) {
      if (input.useApiCalls)
        handleContactLocationInputs(
          { target: { name: "useApiCalls", checked: false } },
          step.id,
          input.id,
          id
        );
      return <></>;
    }
    return (
      <div className="addSwitch">
        Free Text
        <label className="switch">
          <input
            type="checkbox"
            name="useApiCalls"
            checked={input.useApiCalls}
            onChange={(e) => {
              handleContactLocationInputs(
                {
                  target: {
                    name: "type",
                    value: e.target.checked ? "State/Region" : "text",
                  },
                },
                step.id,
                input.id,
                id
              );
              handleContactLocationInputs(e, step.id, input.id, id);
            }}
          />
          <span className="slider round"></span>
        </label>
        Use Api Calls
      </div>
    );
  };
  const drawValueTypeToggle = (input) => {
    return (
      <div className="addSwitch">
        Country Name
        <label className="switch">
          <input
            type="checkbox"
            name="valueType"
            checked={input.valueType == "alpha2code"}
            onChange={(e) => {
              const { checked } = e.target;
              const valueType = checked ? "alpha2code" : "countryName";
              handleContactLocationInputs(
                { target: { name: "valueType", value: valueType } },
                step.id,
                input.id,
                id
              );
            }}
          />
          <span className="slider round"></span>
        </label>
        Alpha 2 Code
      </div>
    );
  };

  const drawErrorMessageInput = (input) => {
    if (input.required) {
      return (
        <>
          <label>Error Message:</label>
          <input
            type="text"
            name="errorMessage"
            value={input.errorMessage}
            onChange={(e) => handleContactInputs(e, input.id, id)}
            className="form-control"
          />
        </>
      );
    } else return <></>;
  };

  const drawDefaultCountryInput = (input) => {
    return (
      <div>
        <h4>Default Country:</h4>
        <CountrySelect
          onChange={(e) => {
            e.target.name = "defaultCountry";
            props.handleContactLocationInputs(e, step.id, input.id, id);
          }}
          context="location"
          placeholder="Choose country"
          countriesList={input.defaultCountry || {}}
          controlShouldRenderValue={true}
          hideSelectedOptions={true}
          isMulti={false}
          style={{
            width: "100%",
            marginBottom: "5px",
            background: "#ffffff",
            border: "1px solid #78889f",
            borderRadius: "7px",
            height: "35px",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "35px",
            color: "#78889f",
            padding: "0 5px",
            overflow: "hidden",
            resize: "none",
          }}
          className="default-country-react-select"
        />
      </div>
    );
  };

  return (
    <>
      <div className="feildHeader">
        <span
          className="pointer"
          onClick={() => setInputCollapse(!inputCollapse)}
        >
          {handleInputName(step, index)}
        </span>

        <IoTrashOutline
          type="button"
          title="rightBar-customize-override"
          onClick={() => deleteLocationContainerFromContact(id, step.id)}
          color={"rgba(0, 68, 179, 0.42)"}
          size={22}
        />
      </div>
      <Collapse in={inputCollapse}>
        <div className="fieldOptions" id={` item${index}`}>
          <h4>Label:</h4>

          <AcTest
            changeOnSelect={(trigger, slug) => "{{" + slug + "}}"}
            trigger={["{{"]}
            options={{ "{{": [...dataWithVariables] }}
            type="text"
            name="label"
            value={step.label}
            placeholder="Name:"
            className="autocomplete-form-input"
            rows={1}
            maxOptions={50}
            onBlur={(e) =>
              handleInputVariableName(
                { target: { name: "label", value: e } },
                step.id,
                id
              )
            }
            onChange={(e) =>
              props.handleContactInputs(
                { target: { name: "label", value: e } },
                step.id,
                id
              )
            }
          />
          {inputs.map((input) => {
            return (
              <>
                <label>
                  <input
                    type="checkbox"
                    checked={input.show}
                    name="show"
                    onChange={
                      locationInputsConstants[input.locationType].canBeHidden
                        ? (e) =>
                            handleContactLocationInputs(
                              e,
                              step.id,
                              input.id,
                              id
                            )
                        : null
                    }
                  />{" "}
                  {input.locationType}
                </label>
                <Collapse in={input.show}>
                  <div>
                    {locationInputsConstants[input.locationType].showLabel &&
                      drawLabelInput(input)}
                    {locationInputsConstants[input.locationType]
                      .showPlaceHolder && drawPlaceHolderInput(input)}
                    {locationInputsConstants[input.locationType]
                      .showDefaultCountry && drawDefaultCountryInput(input)}
                    {locationInputsConstants[input.locationType].showRequired &&
                      drawRequiredInput(input)}
                    {drawErrorMessageInput(input)}
                    {locationInputsConstants[input.locationType]
                      .showCountriesDropDown ? (
                      <div>
                        <label>
                          <input
                            type="checkbox"
                            name="limitCountries"
                            checked={input.limitCountries}
                            onChange={(e) =>
                              handleContactLocationInputs(
                                e,
                                step.id,
                                input.id,
                                id
                              )
                            }
                          />{" "}
                          Limit Countries
                        </label>
                        <Collapse in={input.limitCountries}>
                          <div>
                            <CountrySelect
                              onChange={(e) =>
                                props.handleContactLocationInputs(
                                  e,
                                  step.id,
                                  input.id,
                                  id
                                )
                              }
                              context="location"
                              placeholder={input.placeholder}
                              countriesList={input.countriesList}
                            />
                          </div>
                        </Collapse>
                      </div>
                    ) : (
                      <></>
                    )}

                    {locationInputsConstants[input.locationType]
                      .ShowFreeTextToggle && drawFreeTextToggle(input)}
                    {locationInputsConstants[input.locationType]
                      .showValueTypeToggle && drawValueTypeToggle(input)}
                  </div>
                </Collapse>
                <br />
              </>
            );
          })}
        </div>
      </Collapse>
    </>
  );
}

export default AddContactLocationField;
