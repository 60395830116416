import React, { useEffect, useState } from "react";
import Template from "./Template";
import { connect } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import {
  getTemplates,
  createQuizFromTemplateAction,
  createQuizFromScratchAction,
  setGtmStatusAction,
  setIsMobilePreview,
} from "../../redux/actions";
import Navbar from "../Navbar";
import "./templates.css";
import CreateQuizFromScratch from "../Dashboard/Modals/CreateQuizFromScratch";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
// import Avatar from "@material-ui/core/Avatar";
import Avatar from "@mui/joy/Avatar";
import { ImMobile } from "react-icons/im";
import { IoApps, IoDesktopOutline, IoMenu } from "react-icons/io5";
import { TfiLayersAlt } from "react-icons/tfi";
import assesmentIcon from "../../images/assesment.png";

import personalityIcon from "../../images/personality.png";
import scoreIcon from "../../images/sxored.png";
// import numberOfQ from "../../images/number-of-q.png";

function TemplatesList(props) {
  const { templates = [], t } = props;
  const [selectedCategoryTemplates, setSelectedCategoryTemplates] = useState(
    []
  );
  const [selectedCategory, setSelectedCategory] = useState("0");
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const [folderId, setFolderId] = useState(props.history.location.state.folder);
  const [previeResolution, setPreviewResolution] = useState("desktop");

  useEffect(() => {
    props.actions.getTemplates();
    if (
      props.userId &&
      props.gtmStatus.page &&
      props.gtmStatus.page !== "templates"
    )
      props.actions.setGtmStatus({
        page: "templates",
        userId: props.userId,
        events: null,
      });
    props.actions.setIsMobilePreview(false);
  }, []);

  //load template for the first time
  useEffect(() => {
    if (!templates[0]) return;
    const selectedCategory =
      templates.find((itr) => itr.template_category_id === "0") || {};
    setSelectedCategoryTemplates(selectedCategory.templates || []);
  }, [templates]);

  const changePreviewModal = (handleEditSelection) => {
    //Todo: get the handleSelection/template id to use it on the use this template button
    setSelectedTemplate(handleEditSelection);
    setShowPreviewModal(true);
    // this.handleSelection
  };
  const chooseCategory = (categoryId) => {
    setSelectedCategory(categoryId);
    const selectedCategory =
      templates.find((itr) => itr.template_category_id === categoryId) || {};
    setSelectedCategoryTemplates(selectedCategory.templates || []);
  };

  const goToEdit = (templateId, folderId) => {
    props.actions.createQuizFromTemplate(templateId, folderId, props.history);
  };
  // const thisIsMyCopy = '<div id=157d08a5-b768-4ceb-be58-2dccd03589ab data-url="https://example.knowyyy.com/page/quiz9740399774" data-width="100%"></div><script>initEmbed("157d08a5-b768-4ceb-be58-2dccd03589ab", "fullpage", ["https://app.knowyyy.com"]);</script>'
  return (
    <main className="template-gallery">
      {selectedTemplate && (
        <Modal
          show={showPreviewModal && selectedTemplate}
          centered
          dialogClassName="template-gallery-modal"
          onHide={() => setShowPreviewModal(false)}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="">
            <div className="main-content">
              <div className="left-side">
                <h2>{selectedTemplate.title}</h2>
                <div className="description">
                  {selectedTemplate.description}
                </div>
                <div className="w-100 d-flex justify-content-between mt-3">
                  <div className="quizType d-flex w-50 justify-content-around">
                    <img
                      style={{ maxWidth: "30px", maxHeight: "30px" }}
                      src={
                        selectedTemplate.quiz_type === "scored"
                          ? scoreIcon
                          : selectedTemplate.quiz_type === "assessment"
                          ? assesmentIcon
                          : personalityIcon
                      }
                      alt="quizIcon"
                    />
                    <p>
                      {selectedTemplate.quiz_type === "scored"
                        ? "Score"
                        : selectedTemplate.quiz_type.charAt(0).toUpperCase() +
                          selectedTemplate.quiz_type.slice(1)}
                    </p>
                  </div>

                  <div className="quizType d-flex w-50 justify-content-around">
                    <TfiLayersAlt size={30} />
                    {/*<img*/}
                    {/*  style={{ maxWidth: "30px", maxHeight: "30px" }}*/}
                    {/*  src={numberOfQ*/}
                    {/*  }*/}
                    {/*  alt="number-of-questions"*/}
                    {/*/>*/}
                    <p>{selectedTemplate.number_of_slides} Questions</p>
                  </div>
                </div>
                <button
                  className="btn"
                  onClick={() => goToEdit(selectedTemplate.id, folderId)}
                >
                  Use this template
                </button>
              </div>
              <div className="right-side">
                <div className="resolution-preview">
                  {/*<Avatar*/}
                  {/*  className={*/}
                  {/*    previeResolution == "mobile"*/}
                  {/*      ? "mobile-circle on"*/}
                  {/*      : "mobile-circle"*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  /!*<MdTune type="checkbox" title={t("rightBar-customize-override")} onClick={(e) => handleChangeQuestion({ target: { name: "useTitleCustomStyle", type : "checkbox", checked: useTitleCustomStyle?false:true  }},id)} color={useTitleCustomStyle?"#78889f":"white"} size={18} />*!/*/}
                  {/*  <ImMobile*/}
                  {/*    type="button"*/}
                  {/*    onClick={() => setPreviewResolution("mobile")}*/}
                  {/*    color={previeResolution == "mobile" ? "#78889f" : "white"}*/}
                  {/*    size={18}*/}
                  {/*  />*/}
                  {/*  /!*<EmailIcon />*!/*/}
                  {/*</Avatar>*/}

                  {/*<Avatar*/}
                  {/*  className={*/}
                  {/*    previeResolution == "desktop"*/}
                  {/*      ? "desktop-circle on"*/}
                  {/*      : "desktop-circle"*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  /!*<MdTune type="checkbox" title={t("rightBar-customize-override")} onClick={(e) => handleChangeQuestion({ target: { name: "useTitleCustomStyle", type : "checkbox", checked: useTitleCustomStyle?false:true  }},id)} color={useTitleCustomStyle?"#78889f":"white"} size={18} />*!/*/}
                  {/*  <IoDesktopOutline*/}
                  {/*    type="button"*/}
                  {/*    onClick={() => setPreviewResolution("desktop")}*/}
                  {/*    color={previeResolution == "desktop" ? "#78889f" : "white"}*/}
                  {/*    size={18}*/}
                  {/*  />*/}
                  {/*  /!*<EmailIcon />*!/*/}
                  {/*</Avatar>*/}

                  <div className="sortBtns">
                    <span
                      onClick={() => setPreviewResolution("mobile")}
                      style={{
                        background:
                          previeResolution == "mobile" ? "#FFFFFF" : "",
                        filter:
                          previeResolution == "mobile"
                            ? "drop-shadow(4px 0px 5px rgba(0, 0, 0, 0.08))"
                            : "",
                      }}
                      // className={displayType == "grid" ? "active" : ""}
                    >
                      {/*<img src="img/icon-gallery.png" />*/}
                      <ImMobile
                        type="button"
                        onClick={() => {
                          setPreviewResolution("mobile");
                          props.actions.setIsMobilePreview(true);
                        }}
                        color={
                          previeResolution == "mobile" ? "#0046B5" : "#78889f"
                        }
                        size={18}
                      />
                    </span>
                    <span
                      onClick={() => setPreviewResolution("desktop")}
                      style={{
                        background:
                          previeResolution == "desktop" ? "#FFFFFF" : "",
                        filter:
                          previeResolution == "desktop"
                            ? "drop-shadow(4px 0px 5px rgba(0, 0, 0, 0.08))"
                            : "",
                      }}
                      // className={displayType == "list" ? "active" : ""}
                    >
                      {/*<img src="img/icon-list.png" />*/}
                      <IoDesktopOutline
                        type="button"
                        onClick={() => {
                          setPreviewResolution("desktop");
                          props.actions.setIsMobilePreview(true);
                        }}
                        color={
                          previeResolution == "desktop" ? "#0046B5" : "#78889f"
                        }
                        size={18}
                      />
                    </span>
                  </div>
                </div>

                <div
                  style={{
                    width: previeResolution === "desktop" ? "100%" : "350px",
                    margin: "auto",
                    height: "100%",
                  }}
                >
                  <div
                    id="157d08a5-b768-4ceb-be58-2dccd03589ab"
                    data-url={
                      selectedTemplate.preview_url ||
                      "https://example.knowyyy.com/page/quiz9740399774"
                    }
                    data-width="100%"
                    style={{ width: "100%", height: "100%" }}
                  ></div>
                  <Helmet>
                    {/*<script src="https://app.knowyyy.com/assets/js/hippoloader.js"></script>*/}
                    <script async>
                      {/*initEmbed("157d08a5-b768-4ceb-be58-2dccd03589ab", "custom", ["https://app.knowyyy.com",250, 250, 250, 250]);*/}
                      {/*  loadCss(options);*/}
                      div_embed =
                      document.getElementById("157d08a5-b768-4ceb-be58-2dccd03589ab");
                      hd_url = div_embed.getAttribute("data-url"); hd_iframe =
                      document.createElement("iframe");
                      hd_iframe.setAttribute("width", "100%");
                      hd_iframe.setAttribute("height", "100%"); hd_iframe.src =
                      hd_url; div_embed.classList.add("embed-container");
                      hd_iframe.classList.add("embed-body");
                      div_embed.appendChild(hd_iframe);
                    </script>
                  </Helmet>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
      <Navbar active="Dashboard" helpLabel={t("help-center")} />
      <section className="dashboardContent">
        <div className="leftbar">
          <a
            href="/dashboard"
            title="Back to Dashboard"
            className="dashboardBack"
          >
            {t("dashboard-template-back-to-dashboard")}
          </a>
          <h2 className="folderTitle"> {t("dashboard-template-categories")}</h2>
          <ul className="folders-list gallerys">
            {templates.map((category) => {
              const {
                template_category_id,
                template_category_name,
                templates = [],
              } = category;

              return (
                <li
                  key={template_category_name}
                  className={
                    template_category_id == selectedCategory ? "selected" : ""
                  }
                >
                  <a
                    onClick={() => chooseCategory(template_category_id)}
                    type="button"
                    title=""
                  >
                    {template_category_name} <span>{templates.length}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="midContent">
          <div className="topBar">
            <h3>{t("dashboard-template-title")}</h3>
            <div>
              <label>{t("dashboard-template-search-template")}</label>
              <input type="search" />
            </div>
            {/*  <div className="sortBtns">
              <span className="active">
                <img src="img/icon-gallery.png" />
              </span>
              <span>
                <img src="img/icon-list.png" />
              </span>
            </div> */}
          </div>
          <div className="templateGallery-header">
            <h2>{t("dashboard-template-all-templates")}</h2>
            <CreateQuizFromScratch
              history={props.history}
              folderId={folderId}
            />
          </div>
          <div className="dashboard-items">
            {selectedCategoryTemplates.map((template, index) => {
              return (
                <Template
                  key={index}
                  goToEdit={goToEdit}
                  data={template}
                  folderId={folderId}
                  showPreviewModal={() => changePreviewModal(template)}
                />
              );
            })}
          </div>
        </div>
      </section>
    </main>
  );
}

const mapStateToProps = (state) => {
  let { templates = [], loginDetails, gtmStatus } = state;
  return { templates, userId: loginDetails.user_id, gtmStatus };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      getTemplates: () => dispatch(getTemplates()),
      createQuizFromTemplate: (templateId, folderId, history) =>
        dispatch(createQuizFromTemplateAction(templateId, folderId, history)),
      setGtmStatus: (status) => dispatch(setGtmStatusAction(status)),
      setIsMobilePreview: (isMobilePreview) =>
        dispatch(setIsMobilePreview(isMobilePreview)),
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(TemplatesList)
);
