import upArrow from "../../images/arrowUp.png";
import accessibilitySelectEnglish from "../../images/united-states-of-america-flag.png";
import israelFlag from "../../images/israel-flag.png";
import usaFlag from "../../images/united-states-of-america-flag.png";
import { BiDroplet } from "react-icons/bi";
import { MdWaterDrop } from "react-icons/md";
import { IconContext } from "react-icons";
import { RiContrastLine } from "react-icons/ri";
import { FaTextHeight } from "react-icons/fa";
import { MdOutlineInvertColorsOff } from "react-icons/md";
import { VscDebugRestart } from "react-icons/vsc";
import { CgFileDocument } from "react-icons/cg";
import { AiOutlineClose } from "react-icons/ai";
import { FaUniversalAccess, FaAccessibleIcon } from "react-icons/fa";
import { MdOutlineAccessible, MdOutlineAccessibilityNew } from "react-icons/md";
import "./accessibility.css";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import i18n from "i18next";
import { mainAxios } from "../../Axios/mainAxios";
import {
  changeAccessibilitySettings,
  changeLanguage,
} from "../../redux/actions";
import { withTranslation } from "react-i18next";
const Accessibility = (props) => {
  const [showLanguageOptions, setShowlanguageOptions] = useState(false);
  const [showAccessibilityPopUp, setShowAccessibilityPopup] = useState(false);
  const [userLanguage, setUserLanguage] = useState(null);
  const [accessibilitySettings, setAccessibilitySettings] = useState({});
  const [showStatement, setShowStatement] = useState(false);
  const [fontSizeChange, setFontSizeChange] = useState(0);
  const [accessibilityIconPosition, setAccessibilityIconPosition] = useState(
    {}
  );
  const [accessibilityPopupPosition, setAccessibilityPopupPosition] =
    useState("left");
  useEffect(() => {
    const fetchAccessibilitySettings = async () => {
      try {
        const { quizId } = props;
        const { data } = await mainAxios.get(
          `/accessibility/getAccessibilitySettings/${quizId}`
        );

        setAccessibilitySettings(data);
        handleAccessibilityPosition(data.position);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAccessibilitySettings();
  }, []);
  const handleAccessibilityPosition = (position) => {
    switch (position) {
      case "ImArrowDownLeft2":
        setAccessibilityIconPosition({
          position: "fixed",
          top: "90%",
          left: "20px",
        });
        break;
      case "ImArrowDownRight2":
        setAccessibilityIconPosition({
          position: "fixed",
          top: "90%",
          right: "20px",
        });
        setAccessibilityPopupPosition("right");
        break;

      case "ImArrowUpLeft2":
        setAccessibilityIconPosition({
          position: "fixed",
          bottom: "90%",
          left: "20px",
        });
        break;
      case "ImArrowUpRight2":
        setAccessibilityIconPosition({
          position: "fixed",
          bottom: "90%",
          right: "20px",
        });
        setAccessibilityPopupPosition("right");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!userLanguage) return;
    i18n.changeLanguage(userLanguage);
  }, [userLanguage]);

  const handleFontSize = (change) => {
    const { actions, accessibility } = props;
    if (change === "decrement") {
      if (fontSizeChange > 0) {
        setFontSizeChange(fontSizeChange - 1);
        actions.changeAccessibilitySettings({
          fontSize: accessibility.fontSize - 1,
        });
      }
    } else if (change === "increment") {
      setFontSizeChange(fontSizeChange + 1);
      actions.changeAccessibilitySettings({
        fontSize: accessibility.fontSize + 1,
      });
    }
  };

  const checkUserIcon = (iconFromDb) => {
    switch (iconFromDb) {
      case "FaUniversalAccess":
        return (
          <FaUniversalAccess
            onClick={() => setShowAccessibilityPopup(!showAccessibilityPopUp)}
            style={{ padding: "10px" }}
          />
        );
        break;
      case "FaAccessibleIcon":
        return (
          <FaAccessibleIcon
            onClick={() => setShowAccessibilityPopup(!showAccessibilityPopUp)}
            style={{ padding: "10px" }}
          />
        );
        break;
      case "MdOutlineAccessible":
        return (
          <MdOutlineAccessible
            onClick={() => setShowAccessibilityPopup(!showAccessibilityPopUp)}
            style={{ padding: "10px" }}
          />
        );
        break;
      case "MdOutlineAccessibilityNew":
        return (
          <MdOutlineAccessibilityNew
            onClick={() => setShowAccessibilityPopup(!showAccessibilityPopUp)}
            style={{ padding: "10px" }}
          />
        );
        break;
      default:
        return;
        break;
    }
  };
  const { t } = props;
  const hebrewRegex = /[\u0590-\u05FF]/;
  const checkStatementLanguage = hebrewRegex.test(
    accessibilitySettings.statement
  );
  return (
    <>
      {!accessibilitySettings.hide_accessibility ? (
        <>
          <div className="accessibility-icon" style={accessibilityIconPosition}>
            <IconContext.Provider
              value={{
                style: {
                  backgroundColor: `${accessibilitySettings.icon_background_color}`,
                  color: `${accessibilitySettings.icon_color}`,
                  borderRadius: "50%",
                },

                size: 50,
              }}
            >
              {checkUserIcon(accessibilitySettings.accessibility_icon)}
            </IconContext.Provider>
          </div>
        </>
      ) : (
        <></>
      )}
      {showAccessibilityPopUp ? (
        <>
          <div
            className="accessibility-container"
            style={{
              border: `2px solid ${accessibilitySettings.icon_background_color}`,
              [accessibilityPopupPosition]: "20px",
            }}
          >
            <div
              className="accessibility-header"
              style={{
                backgroundColor: `${accessibilitySettings.icon_background_color}`,
              }}
            >
              <div className="left">
                <IconContext.Provider
                  value={{
                    color: "white",
                  }}
                >
                  <AiOutlineClose
                    size={20}
                    onClick={() =>
                      setShowAccessibilityPopup(!showAccessibilityPopUp)
                    }
                  />
                </IconContext.Provider>

                <h2
                  style={{ color: `${accessibilitySettings.icon_color}` }}
                  className="accessibility-title"
                >
                  {t("accessibility:accessibility-title")}
                </h2>
              </div>
              <div
                className="right"
                onClick={() => setShowlanguageOptions(!showLanguageOptions)}
              >
                <img
                  src={upArrow}
                  alt=""
                  style={{ width: "80%", height: "80%" }}
                />
                <img
                  className="accessibility-language-image"
                  src={accessibilitySelectEnglish}
                  alt="language select"
                />
              </div>
            </div>
            <div className="accessibility-body">
              {/* language options  */}
              {showLanguageOptions ? (
                <>
                  <div
                    className="language-popup"
                    style={{
                      backgroundColor:
                        accessibilitySettings.icon_background_color,
                    }}
                  >
                    <div className="flags-wrapper">
                      <div
                        className="flag israel"
                        onClick={() => setUserLanguage("he")}
                      >
                        <h5
                          style={{
                            color: `${accessibilitySettings.icon_color}`,
                          }}
                        >
                          {" "}
                          {t("accessibility:accessibility-hebrew")}{" "}
                        </h5>
                        <img src={israelFlag} alt="israelFlag" />
                      </div>
                      <div
                        className="flag usa"
                        onClick={() => setUserLanguage("en")}
                      >
                        <h5
                          style={{
                            color: `${accessibilitySettings.icon_color}`,
                          }}
                        >
                          {t("accessibility:accessibility-english")}{" "}
                        </h5>
                        <img src={usaFlag} alt="usaFlag" />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              <div className="accessibility-color-adjustments">
                <p>{t("accessibility:accessibility-color-adjustment")} </p>
                <div className="accessibility-color-saturation-top">
                  <div
                    className="accessibility-box low-saturation "
                    style={{
                      border: `1px solid ${accessibilitySettings.icon_background_color}`,
                    }}
                    onClick={() =>
                      props.actions.changeAccessibilitySettings({
                        lowSaturation: !props.accessibility.lowSaturation,
                        highSaturation: false,
                        monochrome: false,
                        highContrast: false,
                      })
                    }
                  >
                    <IconContext.Provider
                      value={{
                        color: `${accessibilitySettings.icon_background_color}`,
                      }}
                    >
                      <BiDroplet size={20} />
                    </IconContext.Provider>
                    <h3>{t("accessibility:accessibility-low-saturation")}</h3>
                  </div>
                  <div
                    className="accessibility-box high-saturation"
                    style={{
                      border: `1px solid ${accessibilitySettings.icon_background_color}`,
                    }}
                    onClick={() =>
                      props.actions.changeAccessibilitySettings({
                        highSaturation: !props.accessibility.highSaturation,
                        lowSaturation: false,
                        monochrome: false,
                        highContrast: false,
                      })
                    }
                  >
                    <IconContext.Provider
                      value={{
                        color: `${accessibilitySettings.icon_background_color}`,
                      }}
                    >
                      <MdWaterDrop size={20} />
                    </IconContext.Provider>
                    <h3>{t("accessibility:accessibility-high-saturation")}</h3>
                  </div>
                </div>
                <div className="accessibility-color-saturation-bottom">
                  <div
                    className="accessibility-box  "
                    style={{
                      border: `1px solid ${accessibilitySettings.icon_background_color}`,
                    }}
                    onClick={() =>
                      props.actions.changeAccessibilitySettings({
                        highContrast: !props.accessibility.highContrast,
                        highSaturation: false,
                        lowSaturation: false,
                        monochrome: false,
                      })
                    }
                  >
                    <IconContext.Provider
                      value={{
                        color: `${accessibilitySettings.icon_background_color}`,
                      }}
                    >
                      <RiContrastLine size={20} />
                    </IconContext.Provider>
                    <h3>{t("accessibility:accessibility-high-contrast")}</h3>
                  </div>
                  <div
                    className="accessibility-box "
                    style={{
                      border: `1px solid ${accessibilitySettings.icon_background_color}`,
                    }}
                    onClick={() =>
                      props.actions.changeAccessibilitySettings({
                        monochrome: !props.accessibility.monochrome,
                        highContrast: false,
                        highSaturation: false,
                        lowSaturation: false,
                      })
                    }
                  >
                    <IconContext.Provider
                      value={{
                        color: `${accessibilitySettings.icon_background_color}`,
                      }}
                    >
                      <MdOutlineInvertColorsOff size={20} />
                    </IconContext.Provider>
                    <h3>{t("accessibility:accessibility-monochrome")}</h3>
                  </div>
                </div>
              </div>
              <div className="accessibility-content-adjustments">
                <p className="title">
                  {t("accessibility:accessibility-content-adjustments")}
                </p>
                <div
                  className="accessibility-box 
                  accessibility-readable-font"
                  style={{
                    border: `1px solid ${accessibilitySettings.icon_background_color}`,
                  }}
                  onClick={() =>
                    props.actions.changeAccessibilitySettings({
                      fontFamilyAriel: !props.accessibility.fontFamilyAriel,
                    })
                  }
                >
                  <IconContext.Provider
                    value={{
                      color: `${accessibilitySettings.icon_background_color}`,
                    }}
                  >
                    <FaTextHeight size={20} />
                  </IconContext.Provider>
                  <h3> {t("accessibility:accessibility-readable-font")}</h3>
                </div>
                {/* <div className="accessibility-box hidden"></div> */}
              </div>
              <div className="accessibility-content  ">
                <h4>{t("accessibility:accessibility-font-size")} </h4>
                <div className="accessibility-change">
                  <button
                    className="accessibility-btn decrement "
                    style={{
                      backgroundColor: `${accessibilitySettings.icon_background_color}`,
                    }}
                    onClick={() => handleFontSize("decrement")}
                  >
                    -
                  </button>
                  <div>{fontSizeChange}</div>
                  <button
                    className="accessibility-btn  increment "
                    style={{
                      backgroundColor: `${accessibilitySettings.icon_background_color}`,
                    }}
                    onClick={() => handleFontSize("increment")}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
            <div className="accessibility-footer">
              <div
                className="reset-settings"
                style={{
                  backgroundColor: `${accessibilitySettings.icon_background_color}`,
                }}
              >
                <IconContext.Provider value={{ color: "#ffffff" }}>
                  <VscDebugRestart size={20} />
                </IconContext.Provider>

                <p
                  onClick={() =>
                    props.actions.changeAccessibilitySettings({
                      fontSize: 0,
                      lowSaturation: false,
                      highSaturation: false,
                      monochrome: false,
                      highContrast: false,
                      fontFamilyAriel: false,
                    })
                  }
                >
                  {t("accessibility:accessibility-reset")}
                </p>
              </div>
              <div
                className="statement"
                style={{
                  backgroundColor: `${accessibilitySettings.icon_background_color}`,
                }}
                onClick={() => setShowStatement(!showStatement)}
              >
                <IconContext.Provider value={{ color: "#ffffff" }}>
                  <CgFileDocument size={20} />
                </IconContext.Provider>
                <p> {t("accessibility:accessibility-statement")} </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {showStatement && (
        <div className="accessibility-statement-popup">
          <IconContext.Provider
            value={{
              color: "black",
            }}
          >
            <AiOutlineClose
              size={25}
              onClick={() => setShowStatement(!showStatement)}
            />
          </IconContext.Provider>
          <p
            className="statement"
            style={{
              direction: checkStatementLanguage ? "rtl" : "ltr",
              fontWeight: "bold",
            }}
          >
            <span
              style={{
                color: `${accessibilitySettings.icon_background_color}`,
              }}
            >
              {t("accessibility:accessibility-statement")}
            </span>
            {accessibilitySettings.statement}
          </p>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { accessibility, accessibilityConfiguration } = state;
  return { accessibility, accessibilityConfiguration };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      changeAccessibilitySettings: (toChange) =>
        dispatch(changeAccessibilitySettings(toChange)),
      changeLanguage: (language) => dispatch(changeLanguage(language)),
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Accessibility)
);
