import React from "react";
import "../Add.css";
import Pagination from "../../Pagination";
import ProgressBar from "../../ProgressBar";
import { connect } from "react-redux";
import { changeRightBarView, saveAnswer } from "../../../../redux/actions";
import { css } from "glamor";
import { IconPickerItem } from "../../../../utils/icons-select";
import Rating from "react-rating";
import ChooseImageModal from "../../ChooseImageModal";
import ToggleInputAndText from "../../../toggleInputAndText";
import Nav from "../../Nav";
import OptionsTypeQuestion from "./QuestionTypes/OptionsTypeQuestion";
import MatrixTypeQuestion from "./QuestionTypes/Matrix";
import QuestionFocusAlert from "./QuestionFocusAlert";
import { addStyleSettings } from "../../../../utils/patchFunctions";
import { RiContactsBookLine } from "react-icons/ri";
import SliderTypeQuestion from "./QuestionTypes/Slider";

class ViewQuestion extends React.Component {
  state = {
    selectedOptions: [],
    showAlert: false,
    isCorrect: false,
    correctAnswers: {},
    ratingResult: 0,
    isDisabled: false,
    showOtherOptionInput: false,
    otherInputTextValue: "",
  };

  componentDidMount() {
    //@@@@@ the following part is part of the back button it could lead to unforeseen bugs
    if (
      this.props.isPreview &&
      this.props.data.answers &&
      this.props.data.answers.length
      //  && this.props.data.answers[0].arrayOfResult
    ) {
      const currentAnswer = this.props.data.answers.find(
        (el) => el.questionId === this.props.onDraw.id
      );
      if (currentAnswer && currentAnswer.arrayOfResult) {
        this.setState({
          selectedOptions: currentAnswer.arrayOfResult,
        });
      }
    }

    //@@@@@
    if (
      this.props.onDraw.questionType !== "multiSelect" ||
      !this.props.isPreview
    )
      return;
    const correctAnswers = this.props.onDraw.options.reduce((obj, current) => {
      const { id, correct } = current;
      if (correct) return { ...obj, [id]: id };
      return obj;
    }, {});
    this.setState({ correctAnswers });
  }

  changeOptionImage = (id, index, image, defaultAltText = "") => {
    this.props.changeQuestionsOptionsDetails(
      { target: { name: "src", value: image } },
      index,
      id
    );
    this.props.changeQuestionsOptionsDetails(
      { target: { name: "mediaAltText", value: defaultAltText } },
      index,
      id
    );
  };

  handleSpecialMessage = (data) => {
    const { variables = [], answers = [], contacts = {}, score = 0 } = data;
    let newVariables = variables.map((variable) => {
      return { [variable.name]: variable.value };
    });
    let newAnswers = answers.map((answer) => {
      let { arrayOfResult = [], questionId } = answer;
      let value = arrayOfResult.map((itr) => itr.value).join(", ");
      return { [questionId]: value };
    });

    const newContacts = Object.entries(contacts)
      .filter(([k, v]) => k != "other")
      .map(([k, v]) => {
        const inputKey = v.label || v.type;
        return {
          [inputKey.toLowerCase()]: v.value,
        };
      });

    const dataVariables = Object.assign(
      {},
      ...newVariables,
      ...newAnswers,
      ...newContacts,
      { score }
    );

    return dataVariables;
  };

  handleOptionVarsSwitch = (optionValue, dataWithVariables) => {
    if (!optionValue) return;

    if (optionValue.includes("{{") && optionValue.includes("}}")) {
      const varName = optionValue.substring(
        optionValue.indexOf("{{") + 2,
        optionValue.lastIndexOf("}}#")
      );
      optionValue = dataWithVariables[varName];
    }

    return optionValue;
  };

  toggleErrorMessage = (isDisabled, errorMessage) => {
    this.setState({ isDisabled, errorMessage });
  };

  checkResult = (e, option) => {
    if (this.state.showAlert) return;
    const { optionType } = option;
    if (this.state.showAlert) return;

    if (optionType == "input") {
      return this.toggleButtonOrInput(e, option);
    }
    const { selectedOptions } = this.state;
    let isCorrect = false;
    const dataWithVariables = this.handleSpecialMessage(this.props.data);
    let optionValue = option.title;

    // if (this.props.data.type !== "assessment") this.props.saveAnswer(selectedOptions)
    if (this.props.onDraw.questionType === "singleSelect") {
      selectedOptions.splice(0, selectedOptions.length);
      selectedOptions.push({
        id: option.id,
        value: this.handleOptionVarsSwitch(optionValue, dataWithVariables),
        score: option.score,
      });
      isCorrect = option.correct;
    }
    if (this.props.onDraw.questionType === "multiSelect") {
      const { correctAnswers } = this.state;

      if (e.target.name != "multi-button") {
        if (option.override) {
          const selectedOptionIndex = selectedOptions.findIndex(
            (e) => e.id == option.id
          );
          if (selectedOptionIndex > -1) {
            selectedOptions[selectedOptionIndex].value = optionValue;
          } else {
            selectedOptions.push({
              id: option.id,
              value: this.handleOptionVarsSwitch(
                optionValue,
                dataWithVariables
              ),
              score: option.score,
            });
          }
          return this.setState({ selectedOptions, isCorrect });
        } else {
          const selectedOptionIndex = selectedOptions.findIndex(
            (e) => e.id == option.id
          );
          if (selectedOptionIndex > -1) {
            selectedOptions.splice(selectedOptionIndex, 1);
          } else {
            selectedOptions.push({
              id: option.id,
              value: this.handleOptionVarsSwitch(
                optionValue,
                dataWithVariables
              ),
              score: option.score,
            });
          }
          return this.setState({ selectedOptions, isCorrect });
        }
      }
      if (selectedOptions.length !== Object.values(correctAnswers).length) {
        isCorrect = false;
      } else {
        const result = selectedOptions.filter((option) => {
          if (!correctAnswers[option.id]) return option;
        });
        if (!result.length) {
          isCorrect = true;
        }
      }
    }
    this.setState({ selectedOptions, showAlert: true, isCorrect });
  };

  toggleButtonOrInput = (e, option) => {
    const { optionType, title, placeholder } = option;
    if (optionType !== "input") return;
    const { tagName, style } = e.target;
    switch (tagName) {
      case "BUTTON":
        const input = document.createElement("input");
        input.placeholder = placeholder;
        input.style = { ...style };
        e.target.replaceWith(input);
        break;
      default:
        break;
    }
  };

  changeRating = (ratingResult) => {
    if (this.state.showAlert) return;
    const { withNextButton } = this.props.onDraw;
    this.setState({ ratingResult });
    if (!withNextButton) this.sendRatingResult();
  };

  changeSlider = (sliderResult) => {
    if (this.state.showAlert) return;
    this.setState({ sliderResult });
  };

  sendRatingResult = () => {
    const { isPreview, isPreviewDesignMode } = this.props;
    if (!isPreview || isPreviewDesignMode) return;
    const { selectedOptions, ratingResult } = this.state;
    if (selectedOptions.length) {
      selectedOptions.splice(0, selectedOptions.length);
    }
    selectedOptions.push({ id: null, value: ratingResult });
    this.setState({ selectedOptions, showAlert: true });
  };

  sendSliderResult = () => {
    const { isPreview, isPreviewDesignMode } = this.props;
    if (!isPreview || isPreviewDesignMode) return;
    const { selectedOptions, sliderResult } = this.state;
    if (selectedOptions.length) {
      selectedOptions.splice(0, selectedOptions.length);
    }
    selectedOptions.push({ id: null, value: sliderResult });
    this.setState({ selectedOptions, showAlert: true });
  };

  toggleMediaType = (e) => {
    const { id } = this.props.data.onEdit;
    const { name, checked } = e.target;
    const value = checked ? "image" : "";
    this.props.handleChangeQuestion({ target: { name, value } }, id);
  };

  drawAnswersByQuestionType = () => {
    const { settings, direction } = this.props.data;
    const {
      questionType,
      id,
      style,
      options,
      ratingIcon,
      ratingIconColor,
      ratingIconCount,
      ratingIconSize,
      ratingIconEmptyColor = "grey",
      matrix = {},
      useAnswersCustomStyle,
      buttonText,
      withNextButton = true,
    } = this.props.onDraw;

    let {
      isPreview,
      accessibility = {},
      isMobile,
      isTablet,
      isMobilePreview,
      isPreviewDesignMode,
    } = this.props;
    isMobile = isMobilePreview || isMobile;
    const { ratingResult } = this.state;
    let styleObject = useAnswersCustomStyle ? style : settings;

    //Font size
    let fontSize = "14";
    if (isMobile && styleObject.answers.mobileFontSize)
      fontSize = styleObject.answers.mobileFontSize;
    else if (isTablet && styleObject.answers.tabletFontSize)
      fontSize = styleObject.answers.tabletFontSize;
    else if (styleObject.answers.fontSize)
      fontSize = styleObject.answers.fontSize;

    //Top padding
    let topPadding = 0;
    if (styleObject.answers.padding) {
      if (isMobile && styleObject.answers.padding.mobileTop)
        topPadding = styleObject.answers.padding.mobileTop;
      else if (isTablet && styleObject.answers.padding.tabletTop)
        topPadding = styleObject.answers.padding.tabletTop;
      else if (styleObject.answers.padding.top)
        topPadding = styleObject.answers.padding.top;
    }

    //Right padding
    let rightPadding = 0;
    if (styleObject.answers.padding) {
      if (isMobile && styleObject.answers.padding.mobileRight)
        rightPadding = styleObject.answers.padding.mobileRight;
      else if (isTablet && styleObject.answers.padding.tabletRight)
        rightPadding = styleObject.answers.padding.tabletRight;
      else if (styleObject.answers.padding.right)
        rightPadding = styleObject.answers.padding.right;
    }

    //Left padding
    let leftPadding = 0;
    if (styleObject.answers.padding) {
      if (isMobile && styleObject.answers.padding.mobileLeft)
        leftPadding = styleObject.answers.padding.mobileLeft;
      else if (isTablet && styleObject.answers.padding.tabletLeft)
        leftPadding = styleObject.answers.padding.tabletLeft;
      else if (styleObject.answers.padding.left)
        leftPadding = styleObject.answers.padding.left;
    }

    //Bottom padding
    let bottomPadding = 0;
    if (styleObject.answers.padding) {
      if (isMobile && styleObject.answers.padding.mobileBottom)
        bottomPadding = styleObject.answers.padding.mobileBottom;
      else if (isTablet && styleObject.answers.padding.tabletBottom)
        bottomPadding = styleObject.answers.padding.tabletBottom;
      else if (styleObject.answers.padding.bottom)
        bottomPadding = styleObject.answers.padding.bottom;
    }

    //Pic size
    let picSize = "14";
    if (isMobile && styleObject.answers.mobilePicSize)
      picSize = styleObject.answers.mobilePicSize;
    else if (isTablet && styleObject.answers.tabletPicSize)
      picSize = styleObject.answers.tabletPicSize;
    else if (styleObject.answers.picSize) picSize = styleObject.answers.picSize;

    //Pic size
    let textContainerSize = "14";
    if (isMobile && styleObject.answers.mobileTextContainerSize)
      textContainerSize = styleObject.answers.mobileTextContainerSize;
    else if (isTablet && styleObject.answers.tabletTextContainerSize)
      textContainerSize = styleObject.answers.tabletTextContainerSize;
    else if (styleObject.answers.textContainerSize)
      textContainerSize = styleObject.answers.textContainerSize;

    const answersStyle = {
      color: styleObject.answers.buttonsTextColor,
      background: styleObject.answers.buttonsBackgroundColor,
      border: `${styleObject.answers.buttonsBorderColor} solid ${styleObject.answers.buttonsBorderSize}px`,
      borderRadius: `${styleObject.answers.buttonsBorderRadius}px`,
      padding: styleObject.answers.padding
        ? `${topPadding}px ${rightPadding}px ${bottomPadding}px ${leftPadding}px `
        : "0px 0px 0px 0px",
      fontSize: `${parseInt(fontSize || "14") + (isPreview ? accessibility.fontSize : 0)
        }px`,
      fontStyle: styleObject.answers.fontStyle,
      fontWeight: styleObject.answers.fontWeight,
      margin: 10,
      display: "inline-flex",
      flexDirection: "column",
      fontFamily: styleObject.answers.fontFamily.replace(/\+/g, " "),
      textAlign: styleObject.answers.textAlign,
      picSize,
      textContainerSize,
      alignItems: styleObject.answers.alignItems,
      wordBreak: "break-word",
      // width: `${styleObject.answers.picSize}px`,
      // border: `${styleObject.answers.buttonsBorderSize}px solid ${styleObject.answers.buttonsBorderColor}`,
    };

    let ButtonstyleObj = settings.button ? settings.button : style.button;

    const buttonStyle = this.handleSectionStyle(
      "button",
      "useButtonCustomStyle"
    );
    if (!ButtonstyleObj) ButtonstyleObj = addStyleSettings(settings, "button");

    switch (questionType) {
      case "singleSelect":
      case "multiSelect":
        return (
          <OptionsTypeQuestion
            direction={direction}
            answersStyle={answersStyle}
            onDraw={this.props.onDraw}
            settings={settings}
            isPreview={isPreview}
            handleEditView={this.handleEditView}
            changeOptionImage={this.changeOptionImage}
            changeQuestionsOptionsDetails={
              this.props.changeQuestionsOptionsDetails
            }
            isEdit={this.props.isEdit}
            checkResult={this.checkResult}
            data={this.props.data}
            addOptionToQuestion={this.props.addOptionToQuestion}
            changeOptionsIndexes={this.props.changeOptionsIndexes}
            deleteOptionFromQuestion={this.props.deleteOptionFromQuestion}
            quizType={this.props.selectedDetails.quizDetails.type}
            showAlert={this.state.showAlert}
            selectedOptions={this.state.selectedOptions}
            handleSpecialMessage={this.handleSpecialMessage}
            context={this.props.rightBarView.context}
            isMobile={isMobile}
            isTablet={isTablet}
            toggleErrorMessage={this.toggleErrorMessage}
            isPreviewDesignMode={isPreviewDesignMode}
            ButtonstyleObj={ButtonstyleObj}
          />
        );

      case "rating": {
        return (
          <div
            className={
              this.props.isEdit &&
                this.props.rightBarView.context.replace("Style", "") ===
                "ratingAnswer"
                ? "ratingContainer active-element"
                : "ratingContainer"
            }
            style={{ textAlign: buttonStyle.textAlign }}
            onClick={(e) => this.handleEditView(e, "ratingAnswerStyle")}
          >
            <Rating
              start={0}
              stop={ratingIconCount}
              initialRating={
                !ratingResult && this.state.selectedOptions.length
                  ? this.state.selectedOptions[0].value
                  : ratingResult
              }
              onClick={this.changeRating}
              emptySymbol={
                <IconPickerItem
                  icon={ratingIcon}
                  size={`${parseInt(ratingIconSize) + accessibility.fontSize
                    }px`}
                  color={ratingIconEmptyColor}
                />
              }
              fullSymbol={
                <IconPickerItem
                  icon={ratingIcon}
                  size={`${parseInt(ratingIconSize) + accessibility.fontSize
                    }px`}
                  color={ratingIconColor}
                />
              }
            />
            {withNextButton ? (
              <div
                style={{ textAlign: buttonStyle.textAlign }}
                className={
                  this.props.isEdit &&
                    this.props.rightBarView.context === "buttonStyle"
                    ? "active-element"
                    : "pointer"
                }
                onClick={(e) => this.handleEditView(e, "buttonStyle")}
              >
                <button
                  className="rating-btn"
                  style={buttonStyle}
                  onClick={this.sendRatingResult}
                >
                  <ToggleInputAndText
                    isRichText={true}
                    value={buttonText || "Next"}
                    handleChange={(e) => this.props.handleChange(e, id)}
                    id={id}
                    name="buttonText"
                    data={this.props.data}
                    tag="span"
                    isEdit={this.props.isEdit}
                    placeholder="Write title here"
                    isResult={false}
                    handleEditView={this.handleEditView}
                  />
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
        );
      }
      case "matrix": {
        return (
          <>
            <MatrixTypeQuestion
              onDraw={this.props.onDraw}
              settings={settings}
              isPreview={isPreview}
              handleEditView={this.handleEditView}
              changeOptionImage={this.changeOptionImage}
              addColumnToMatrix={this.props.addColumnToMatrix}
              deleteMatrixItem={this.props.deleteMatrixItem}
              addRowToMatrix={this.props.addRowToMatrix}
              handleChangeMatrixData={this.props.handleChangeMatrixData}
              saveMatrixOption={this.saveMatrixOption}
              data={this.props.data}
              isEdit={this.props.isEdit}
              addOptionToQuestion={this.props.addOptionToQuestion}
              isMobile={isMobile}
              isTablet={isTablet}
              rightBarView={this.props.rightBarView}
            />
          </>
        );
      }
      case "slider": {
        return (
          <>
            <SliderTypeQuestion
              onDraw={this.props.onDraw}
              settings={settings}
              isPreview={isPreview}
              handleEditView={this.handleEditView}
              data={this.props.data}
              isEdit={this.props.isEdit}
              isMobile={isMobile}
              isTablet={isTablet}
              rightBarView={this.props.rightBarView}
              changeSlider={this.changeSlider}
            />
            <div
              style={{ textAlign: buttonStyle.textAlign, marginTop: "50px" }}
              className={
                this.props.isEdit &&
                  this.props.rightBarView.context === "buttonStyle"
                  ? "active-element"
                  : "pointer"
              }
              onClick={(e) => this.handleEditView(e, "buttonStyle")}
            >
              <button
                className="rating-btn"
                style={buttonStyle}
                onClick={this.sendSliderResult}
              >
                <ToggleInputAndText
                  isRichText={true}
                  value={buttonText || "Next"}
                  handleChange={(e) => this.props.handleChange(e, id)}
                  id={id}
                  name="buttonText"
                  data={this.props.data}
                  tag="span"
                  isEdit={this.props.isEdit}
                  placeholder="Write title here"
                  isResult={false}
                  handleEditView={this.handleEditView}
                />
              </button>
            </div>
          </>
        );
      }
      default:
        break;
    }
  };
  toggleContinueButton = () => {
    const {
      questionType,
      id,
      buttonText,
      style,
      isMultiSelectRequired,
      errorMsg,
      matrix,
      required,
      customOtherTextRequired,
      otherOptionFreeTextErrorMessage,
    } = this.props.onDraw;
    const { settings } = this.props.data;
    const { isPreview, isPreviewDesignMode } = this.props;

    let styleObj = settings.button ? settings.button : style.button;
    if (!styleObj) styleObj = addStyleSettings(settings, "button");

    let ButtonstyleObj = settings.button ? settings.button : style.button;

    const buttonStyle = this.handleSectionStyle(
      "button",
      "useButtonCustomStyle"
    );

    if (!ButtonstyleObj) ButtonstyleObj = addStyleSettings(settings, "button");

    if (questionType === "multiSelect" || questionType === "matrix") {
      if (isPreview) {
        return (
          <>
            {/* {this.state.isDisabled ? (
              <div className="w-100 d-flex justify-content-center">
                <span
                  classname="req-error"
                  style={{ color: "red", margin: "auto" }}
                >
                  {" "}
                  {errorMsg || "Please Choose an Option"}
                </span>{" "}
              </div>
            ) : (
              <></>
            )} */}
            <div
              style={{
                width: "100%",
                textAlign: buttonStyle.textAlign,
                marginTop: "20px",
                marginBottom: "30px",
              }}
            >
              <button
                align="center"
                style={{
                  ...buttonStyle,
                  zIndex: 2,

                  marginBottom: "0px !important",
                }}
                name="multi-button"
                className="multi-button"
                onClick={(e) => {
                  if (isPreviewDesignMode) return;
                  const otherOption = this.state.selectedOptions.find(
                    (option) =>  option.value && option.value.includes("other_option::")
                  );

                  if (
                    (questionType === "multiSelect" &&
                      isMultiSelectRequired &&
                      !this.state.selectedOptions.length) ||
                    (questionType === "matrix" &&
                      required &&
                      this.state.selectedOptions.length !== matrix.rows.length)
                  ) {
                    return this.setState({
                      isDisabled: true,
                      errorMessage: errorMsg || "Please Choose an Option",
                    });
                  }
                  if (
                    customOtherTextRequired &&
                    otherOption &&
                    !otherOption.value.replace("other_option::", "")
                  ) {
                    return this.setState({
                      isDisabled: true,
                      errorMessage: otherOptionFreeTextErrorMessage,
                    });
                  }

                  this.setState({ isDisabled: false, errorMessage: "" });
                  this.checkResult(e, this.state.selectedOptions, id);
                }}
              >
                <ToggleInputAndText
                  isRichText={false}
                  value={buttonText || "Continue"}
                  onClick={(e) => {
                    if (isPreviewDesignMode) return;
                    const otherOption = this.state.selectedOptions.find(
                      (option) =>  option.value && option.value.includes("other_option::")
                    );
                    if (
                      (questionType === "multiSelect" &&
                        isMultiSelectRequired &&
                        !this.state.selectedOptions.length) ||
                      (questionType === "matrix" &&
                        required &&
                        this.state.selectedOptions.length !==
                        matrix.rows.length)
                    ) {
                      return this.setState({
                        isDisabled: true,
                        errorMessage: errorMsg || "Please Choose an Option",
                      });
                    }
                    if (
                      customOtherTextRequired &&
                      otherOption &&
                      !otherOption.value.replace("other_option::", "")
                    ) {
                      return this.setState({
                        isDisabled: true,
                        errorMessage: otherOptionFreeTextErrorMessage,
                      });
                    }

                    this.setState({ isDisabled: false, errorMessage: "" });
                    this.checkResult(
                      { ...e, target: { ...e.target, name: "multi-button" } },
                      this.state.selectedOptions,
                      id
                    );
                  }}
                  id={id}
                  name="buttonText"
                  defaultShowInput={isPreview}
                  data={this.props.data}
                  tag="span"
                  isEdit={this.props.isEdit}
                  placeholder="Write title here"
                  isResult={false}
                />
              </button>
            </div>
          </>
        );
      }
      return (
        <div
          className={
            this.props.isEdit &&
              this.props.rightBarView.context === "buttonStyle"
              ? "active-element"
              : "pointer"
          }
          onClick={(e) => this.handleEditView(e, "buttonStyle")}
          style={{
            width: "100%",
            textAlign: buttonStyle.textAlign,
            marginTop: "20px",
            marginBottom: "30px",
          }}
        >
          <button style={{ ...buttonStyle }}>
            <ToggleInputAndText
              isRichText={true}
              value={buttonText || "Continue"}
              handleChange={(e) => this.props.handleChange(e, id)}
              id={id}
              name="buttonText"
              defaultShowInput={isPreview}
              data={this.props.data}
              tag="span"
              isEdit={this.props.isEdit}
              placeholder="Write title here"
              isResult={false}
            />
          </button>
        </div>
      );
    }
  };

  drawErrorMessage = () => {
    const { errorMessage, isDisabled } = this.state;
    const { questionType } = this.props.onDraw;
    const { isPreview } = this.props;
    if (isPreview && isDisabled) {
      return (
        <div className="w-100 d-flex justify-content-center">
          <span classname="req-error" style={{ color: "red", margin: "auto" }}>
            {" "}
            {errorMessage}
          </span>{" "}
        </div>
      );
    }
  };

  saveOption = (e, option) => {
    const { selectedOptions } = this.state;
    if (e.target.checked) {
      selectedOptions.push({ id: option.id, value: option.title });
      this.setState({ selectedOptions });
    } else {
      let indexToDelete = selectedOptions.findIndex(
        (selected) => selected.id === option.id
      );
      if (indexToDelete == -1) return;
      selectedOptions.splice(indexToDelete, 1);
      this.setState({ selectedOptions });
    }
  };
  saveMatrixOption = (e, row, column) => {
    const { selectedOptions } = this.state;
    const { matrix } = this.props.onDraw;
    if (!matrix) return;
    const { columns = [], allowMultipleSelection } = matrix;
    const answer = columns.find((itr) => itr.id == column.id);
    if (e.target.checked) {
      if (!allowMultipleSelection) {
        let indexToDelete = selectedOptions.findIndex(
          (selected) => selected.subQuestionId === row.id
        );
        if (indexToDelete != -1) selectedOptions.splice(indexToDelete, 1);
      }
      selectedOptions.push({
        subQuestionId: row.id,
        id: answer.id,
        value: answer.value,
        subQuestionTitle: row.value,
        score: answer.score,
      });
      this.setState({ selectedOptions });
    } else {
      let indexToDelete = selectedOptions.findIndex(
        (selected) =>
          selected.subQuestionId === row.id && selected.id === answer.id
      );
      if (indexToDelete == -1) return;
      selectedOptions.splice(indexToDelete, 1);
      this.setState({ selectedOptions });
    }
  };

  handleChangeMediaImage = (image) => {
    const { id } = this.props.data.onEdit;
    this.props.handleChangeQuestion(
      { target: { name: "mediaImageSrc", value: image } },
      id
    );
  };

  saveResult = (timeout = 0) => {
    const { selectedOptions, isCorrect } = this.state;

    const { id: questionId, type, title, questionType } = this.props.onDraw;

    const { id: quizId, answers, arrayOfData } = this.props.data;
    let score = 0;
    if (questionType === "singleSelect") {
      score = selectedOptions[selectedOptions.length - 1].score;
    } else if (questionType === "multiSelect" || questionType === "matrix") {
      score = selectedOptions.reduce(
        (acc, curr) => (acc += curr.score || 0),
        0
      );
    }
    setTimeout(() => {
      this.props.saveAnswer(selectedOptions, isCorrect, questionId, score);
      const numberOfQuestions = arrayOfData.filter(
        (itr) => itr.type === "question"
      ).length;
      this.props.actions.saveAnswer({
        quizId,
        questionId,
        title,
        selectedOptions,
        answers,
        numberOfQuestions,
        type,
        visitKey: this.props.visitKey,
      });
    }, timeout);
  };

  handleAlerts = () => {
    const { showAlert, isCorrect } = this.state;
    const { settings } = this.props.data;

    if (!this.props.onDraw.feedback && showAlert) {
      this.saveResult();
      return <></>;
    }
    const { focus, correctMessage, incorrectMessage } =
      this.props.onDraw.feedback;
    const { feedback } = this.props.data;
    const { questionType } = this.props.onDraw;
    let { isPreview, selectedDetails, isMobile, isTablet, isMobilePreview } =
      this.props;
    isMobile = isMobilePreview || isMobile;
    if (!isPreview) {
      if (focus === "correct") {
        return (
          <QuestionFocusAlert
            alertClass={"alert alert-success"}
            msg={correctMessage}
            feedback={feedback.correctMessage}
            quizType={selectedDetails.quizDetails.type}
            customStyle={settings.alert}
            correct={true}
            questionType={questionType}
            isMobile={isMobile}
            isTablet={isTablet}
          />
        );
      }
      if (focus === "incorrect") {
        return (
          <QuestionFocusAlert
            alertClass={"alert alert-danger"}
            msg={incorrectMessage}
            feedback={feedback.incorrectMessage}
            quizType={selectedDetails.quizDetails.type}
            customStyle={settings.alert}
            correct={false}
            questionType={questionType}
            isMobile={isMobile}
            isTablet={isTablet}
          />
        );
      }
    }
    if (showAlert && isPreview) {
      if (questionType == "rating") {
        return <div>{this.saveResult(500)}</div>;
      }
      if (isCorrect) {
        return (
          <QuestionFocusAlert
            alertClass={"alert alert-success"}
            msg={correctMessage}
            feedback={feedback.correctMessage}
            saveResult={this.saveResult}
            quizType={selectedDetails.quizDetails.type}
            customStyle={settings.alert}
            correct={true}
            questionType={questionType}
            isMobile={isMobile}
            isTablet={isTablet}
          />
        );
      }
      return (
        <QuestionFocusAlert
          alertClass={"alert alert-danger"}
          msg={incorrectMessage}
          feedback={feedback.incorrectMessage}
          saveResult={this.saveResult}
          quizType={selectedDetails.quizDetails.type}
          customStyle={settings.alert}
          correct={false}
          questionType={questionType}
          isMobile={isMobile}
          isTablet={isTablet}
        />
      );
    }
  };

  handleEditView = (event, context, subContextId) => {
    const { actions, isPreview, onDraw } = this.props;
    if (!isPreview) actions.changeRightBarView(context, subContextId, "design");

    //  added isedit option
    if (this.props.isEdit) {
      this.props.changeTheOnEditObject(onDraw.id);
    }

    event.stopPropagation();
  };

  handleSectionStyle = (context, keyInObject) => {
    const { style } = this.props.onDraw;
    const { settings } = this.props.data;
    let { accessibility, isPreview, isMobile, isTablet, isMobilePreview } =
      this.props;
    isMobile = isMobilePreview || isMobile;

    if (!keyInObject) return;
    let styleObject = this.props.onDraw[keyInObject] ? style : settings;
    if (!styleObject[context]) return;
    styleObject = styleObject[context];

    //Font size
    let fontSize = "14";
    if (isMobile && styleObject.mobileFontSize)
      fontSize = styleObject.mobileFontSize;
    else if (isTablet && styleObject.tabletFontSize)
      fontSize = styleObject.tabletFontSize;
    else if (styleObject.fontSize) fontSize = styleObject.fontSize;

    //Top padding
    let topPadding = 0;
    if (styleObject.padding) {
      if (isMobile && styleObject.padding.mobileTop)
        topPadding = styleObject.padding.mobileTop;
      else if (isTablet && styleObject.padding.tabletTop)
        topPadding = styleObject.padding.tabletTop;
      else if (styleObject.padding.top) topPadding = styleObject.padding.top;
    }

    //Right padding
    let rightPadding = 0;
    if (styleObject.padding) {
      if (isMobile && styleObject.padding.mobileRight)
        rightPadding = styleObject.padding.mobileRight;
      else if (isTablet && styleObject.padding.tabletRight)
        rightPadding = styleObject.padding.tabletRight;
      else if (styleObject.padding.right)
        rightPadding = styleObject.padding.right;
    }

    //Left padding
    let leftPadding = 0;
    if (styleObject.padding) {
      if (isMobile && styleObject.padding.mobileLeft)
        leftPadding = styleObject.padding.mobileLeft;
      else if (isTablet && styleObject.padding.tabletLeft)
        leftPadding = styleObject.padding.tabletLeft;
      else if (styleObject.padding.left) leftPadding = styleObject.padding.left;
    }

    //Bottom padding
    let bottomPadding = 0;
    if (styleObject.padding) {
      if (isMobile && styleObject.padding.mobileBottom)
        bottomPadding = styleObject.padding.mobileBottom;
      else if (isTablet && styleObject.padding.tabletBottom)
        bottomPadding = styleObject.padding.tabletBottom;
      else if (styleObject.padding.bottom)
        bottomPadding = styleObject.padding.bottom;
    }

    return {
      color: styleObject.color,
      width: "fit-content",
      padding: styleObject.padding
        ? `${topPadding}px ${rightPadding}px ${bottomPadding}px ${leftPadding}px `
        : "0px 0px 0px 0px",
      fontSize: `${parseInt(fontSize || "14") + (isPreview ? accessibility.fontSize : 0)
        }px`,
      fontStyle: styleObject.fontStyle,
      fontWeight: styleObject.fontWeight,
      fontFamily: styleObject.fontFamily.replace(/\+/g, " "),
      textAlign: styleObject.textAlign,
      borderRadius: `${styleObject.borderRadius}px`,
      background: styleObject.background,
      border: `${styleObject.borderSize}px ${styleObject.borderColor} solid`,
    };
  };

  render() {
    let { options } = this.props.onDraw;
    options = options ? options : [];
    return (
      <div>
        {this.handleAlerts()}

        {this.drawAnswersByQuestionType()}

        {this.toggleContinueButton()}

        {this.drawErrorMessage()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      saveAnswer: (answerDetails) => dispatch(saveAnswer(answerDetails)),
      changeRightBarView: (context, subContextId, rightBarViewTab) =>
        dispatch(changeRightBarView(context, subContextId, rightBarViewTab)),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewQuestion);
