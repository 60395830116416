import React from "react";
import StyleSettings from "../ThemeEdit/Settings";
import { connect } from "react-redux";
import { getThemes } from "../../../redux/actions";
import ThemesList from "./themeList";

class Themes extends React.Component {
  componentDidMount() {
    this.props.actions.getThemes();
  }

  render() {
    const { themes } = this.props;
    return <div></div>;
    return (
      <div>
        <div id="mainStyle">
          <ul>
            <li id="myThemes">
              <a
                title="myThemes"
                data-toggle="collapse"
                href="#myThemesTab"
                className="open collapsed gradient"
                aria-expanded="true"
                aria-controls="myThemesTab"
              >
                My themes
              </a>
            </li>
            <li id="templates">
              <a
                title="Design"
                data-toggle="collapse"
                href="#galleryTab"
                className="open collapsed"
                aria-expanded="true"
                aria-controls="galleryTab"
              >
                Gallery
              </a>
            </li>
          </ul>
          <div
            className="collapse"
            id="myThemesTab"
            aria-labelledby="myThemesTab"
            data-parent="#mainStyle"
          >
            <button className="btn btn-primary">+ Add new theme</button>
            {/* <ThemesList changeTheme={this.props.changeTheme} themes={themes.filter(theme => !theme.is_public)} /> */}
            <StyleSettings {...this.props} />
          </div>
          <div
            className="collapse"
            id="galleryTab"
            aria-labelledby="galleryTab"
            data-parent="#mainStyle"
          >
            <ThemesList
              changeTheme={this.props.changeTheme}
              themes={themes.filter((theme) => theme.is_public)}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ themes }) => {
  return { themes };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      getThemes: () => dispatch(getThemes()),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Themes);
