import React, { Component } from "react";

class Logo extends Component {
  render() {
    const { logoImageSrc, size, logoPosition, show } = this.props.logo;
    const {
      logoPaddingTop,
      logoPaddingBottom,
      logoPaddingRight,
      logoPaddingLeft,
      textAlign,
    } = logoPosition;
    const { isPreview } = this.props;
    let logoStyle = {};
    if (logoImageSrc && show) {
      logoStyle = {
        width: `${size}px`,
        height: "auto",
      };
      const logoContainerStyle = {
        zIndex: "100%",
        width: "100%",
        display: "flex",
        justifyContent: textAlign,
        padding: `${logoPaddingTop || 0}px ${logoPaddingRight || 0}px ${
          logoPaddingBottom || 0
        }px ${logoPaddingLeft || 0}px`,
      };

      if (isPreview && logoImageSrc) {
        return (
          <div style={logoContainerStyle}>
            <a href={logoImageSrc}>
              <img style={logoStyle} src={logoImageSrc} alt="logo" />
            </a>
          </div>
        );
      }
      return (
        <div style={logoContainerStyle}>
          <img style={logoStyle} src={logoImageSrc} alt="logo" />
        </div>
      );
    }

    return <></>;
  }
}

export default Logo;
