import React, { useEffect, useState } from "react";
import Fonts from "../../../../../utils/Fonts/Fonts";
import ColorPicker from "../../../ColorPicker";
import SelectSize from "../../../SelectSize";
import { withTranslation } from "react-i18next";
import Justify from "../../../../justify/justify";
import ButtonStyle from "../../../ThemeEdit/buttonStyle";

import { addStyleSettings } from "../../../../../utils/patchFunctions";
// import Avatar from "@material-ui/core/Avatar";
import { FiEdit3 } from "react-icons/fi";
import RightBarTabsContainer from "../../../RightBar/RightBarTabContainer";
import CallToActionSettings from "../../Result/CallToActionSettings";

function ButtonStyleCustom(props) {
  const [hookSettings, setHookSettings] = useState({});
  const [hookStyle, setHookStyle] = useState({});

  useEffect(() => {
    const { settings, style } = props;
    if (!settings.button) {
      const newSettings = addStyleSettings(settings, "button");
      setHookSettings(newSettings);
    } else {
      setHookSettings(settings);
    }

    if (!style.button) {
      const newstyle = addStyleSettings(style, "button");
      setHookStyle(newstyle);
    } else {
      setHookStyle(style);
    }
  }, [props.settings, props.style]);

  const handleColorsToChange = (e, name) => {
    const { r, g, b, a } = e.rgb;
    const value = `rgba(${r}, ${g}, ${b}, ${a})`;
    props.handleQuestionStyle({ target: { name, value } }, props.id, "button");
  };
  const {
    settings,
    style,
    handleQuestionStyle,
    fonts,
    id,
    useButtonCustomStyle,
    handleChangeQuestion,
    t,
    callToActionTarget,
    callToActionLink
  } = props;
  const currentFont = hookStyle.button
    ? hookStyle.button.fontFamily
    : "Open Sans";


  const drawButton = <>

    <div className="addSwitch override">
      <label className="switch">
        <input
          type="checkbox"
          name="withSubtitle"
          checked={useButtonCustomStyle ? true : false}
          onChange={(e) =>
            handleChangeQuestion(
              {
                target: {
                  name: "useButtonCustomStyle",
                  type: "checkbox",
                  checked: useButtonCustomStyle ? false : true,
                },
              },
              id
            )
          }
        />
        <span className="slider round"></span>
      </label>
      {t("rightBar-customize-override")}


    </div>
    {useButtonCustomStyle ? (<>
      <ButtonStyle
        selectedTheme={"override-edit"}
        handleChange={handleQuestionStyle}
        context="button"
        fonts={fonts}
        props={props}
        handleColorsToChange={handleColorsToChange}
      />
    </>
    ) : (
      <>
        <div className="addSwitch-disabled"></div>
        <ButtonStyle
          selectedTheme={"override-edit"}
          handleChange={{}}
          context="button"
          fonts={fonts}
          props={props}
          handleColorsToChange={{}}
        />
      </>
    )}</>

  const callToActionSettings = (<><CallToActionSettings handleChangeQuestion={handleChangeQuestion}
    id={id}
    t={t}
    callToActionTarget={callToActionTarget}
    callToActionLink={callToActionLink}
    settings={props} /></>)

  const formTabsObj = { Settings: callToActionSettings, Design: drawButton };

  return (
    <>

      {props.id.startsWith("R_") ? (<RightBarTabsContainer formTabsObj={formTabsObj} />) : (drawButton)}

    </>
  );
}

export default withTranslation()(ButtonStyleCustom);
