import React, { useState, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ToggleInputAndText from "../../../../toggleInputAndText";
import ChooseImageModal from "../../../ChooseImageModal";
// import { ReactLiquid } from "react-liquid";
import {
  getAlignmentClassNameByAlignmentTypeAndDirection,
  stripTags,
} from "../../../../../utils";
import { IoTrashOutline } from "react-icons/io5";
import { shuffleArray } from "../../../../../utils";

export default function OptionsTypeQuestion(props) {
  const {
    answersStyle,
    padding,
    onDraw,
    isPreview,
    handleEditView,
    deleteOptionFromQuestion,
    checkResult,
    changeOptionImage,
    changeQuestionsOptionsDetails,
    data,
    isEdit,
    addOptionToQuestion,
    changeOptionsIndexes,
    direction,
    quizType,
    showAlert,
    selectedOptions,
    handleSpecialMessage,
    isMobile,
    isTablel,
    toggleErrorMessage,
    isPreviewDesignMode,
    ButtonstyleObj,
  } = props;

  const {
    questionType,
    id,
    options,
    answerWithImages = true,
    verticalAnswers = false,
    isRandom = false,
    isMultiSelectRequired = false,
    errorMsg = "",
    isOtherOption = false,
    customOtherTextRequired,
    otherOptionFreeTextLabel,
    otherOptionFreeTextErrorMessage,
    customOtherBtnText,
  } = onDraw;

  const [alignVertical, setAlignVertical] = useState("align-items-start");
  const [isSelect, setIsSelect] = useState(false);
  const [isElementActiveClass, setIsElementActiveClass] = useState("");
  const [isDisabeld, setIsDisabeld] = useState(false);
  const [drawOptions, setDrawOptions] = useState(options);
  const [drawOtherInput, setDrawOtherInput] = useState(false);
  const [otherInputTextValue, setOtherInputValueText] = useState("");
  const [otherOption, setOtherOption] = useState({});

  useEffect(() => {
    const className = getAlignmentClassNameByAlignmentTypeAndDirection(
      answersStyle.alignItems,
      direction,
      verticalAnswers
    );
    setAlignVertical(className);
    // const alignmentType = verticalAnswers ? "align-items" : "justify-content";
    // if (answersStyle.alignItems == "center") {
    //   setAlignVertical(`${alignmentType}-center`);
    // } else if (answersStyle.alignItems == "right") {
    //   direction == "rtl"
    //     ? setAlignVertical(`${alignmentType}-start`)
    //     : setAlignVertical(`${alignmentType}-end`);
    // } else if (answersStyle.alignItems == "left") {
    //   direction == "rtl"
    //     ? setAlignVertical(`${alignmentType}-end`)
    //     : setAlignVertical(`${alignmentType}-start`);
    // }
  }, [answersStyle.alignItems, verticalAnswers]);

  useEffect(() => {
    if (props.context.replace("Style", "") === "buttonAnswer") {
      setIsElementActiveClass("active-element");
    } else {
      setIsElementActiveClass("");
    }
  }, [props.context]);

  useEffect(() => {
    const otherOption = options.find(
      (option) => option.isOtherOption && !option.hide
    );
    setOtherOption(otherOption);
    if (isRandom) {
      const tempOptions = JSON.parse(JSON.stringify(options));
      let shuffledOptions = [];
      if (isOtherOption) {
        const otherOption = tempOptions.pop();
        shuffledOptions = shuffleArray(tempOptions);
        shuffledOptions.push(otherOption);
      } else {
        shuffledOptions = shuffleArray(tempOptions);
      }
      setDrawOptions(shuffledOptions);
    } else {
      setDrawOptions(options);
    }
  }, [options, isRandom, isOtherOption]);

  const setSelectedTOption = (id) => {
    const selectedIds = selectedOptions.map((option) => option.id);
    if (
      questionType === "multiSelect" &&
      selectedIds.includes(id) &&
      selectedIds.length == 0
    ) {
      setIsSelect(false);
    } else {
      setIsSelect(true);
    }
  };

  const { type } = data;
  const onDragEnd = ({ source, destination }) => {
    if (!source || !destination) return;
    changeOptionsIndexes(source.index, destination.index, id);
  };

  const containerSize = (styleObject) => {
    const { textContainerSize, picSize } = styleObject;
    let biggerSize = picSize;
    if (
      !isNaN(parseInt(textContainerSize)) &&
      parseInt(textContainerSize) > parseInt(picSize)
    ) {
      biggerSize = textContainerSize;
    }
    return {
      width: `${biggerSize}px`,
      height: `${biggerSize}px`,
      margin: "5% 0 5% 0",
      display: "flex",
      // margin: "auto",
      padding: "5%",
    };
  };

  const { background, border, borderRadius, ...restOfAnswersStyle } =
    answersStyle;
  //Need to add the other option;
  if (!isEdit) {
    return (
      <>
        <div
          className={`answersContainer answers  ${
            verticalAnswers ? "verticalAnswers " : ""
          }${alignVertical} `}
        >
          {drawOptions
            .filter((option) => !option.hide)
            .map((option, index) => {
              let borderSelectClass = " ";
              let isDiffrentBorderColor = false;
              const isOptionSelected = selectedOptions.find(
                (e) => e.id == option.id
              );

              if (
                isSelect &&
                isOptionSelected &&
                !option.correct &&
                showAlert &&
                quizType === "assessment"
              ) {
                borderSelectClass = "selected-answer-false";
              } else if (
                // isSelect &&
                option.correct &&
                showAlert &&
                quizType === "assessment"
              ) {
                borderSelectClass = "selected-answer-true";
              } else if (isOptionSelected) {
                borderSelectClass = "selected-answer-multiple";
                if (answersStyle && answersStyle.border) {
                  const borderColor = answersStyle.border.split("solid");
                  isDiffrentBorderColor = `4px 4px 10px ${borderColor[0]}`;
                }
              }
              return (
                <div
                  style={{
                    background,
                    border,
                    borderRadius,
                    boxShadow: isDiffrentBorderColor
                      ? isDiffrentBorderColor
                      : "inherit",
                  }}
                  className={borderSelectClass}
                >
                  <div
                    key={option.id}
                    onClick={(e) => {
                      let optionTitle = option.title;
                      if (option.isOtherOption) {
                        optionTitle = `other_option::${otherInputTextValue}`;
                        setDrawOtherInput(!drawOtherInput);
                      }
                      if (isPreviewDesignMode) return;
                      if (questionType === "singleSelect") {
                        if (isDisabeld) return;
                        if (!option.isOtherOption) setIsDisabeld(true);
                      }
                      setSelectedTOption(option.id, quizType);
                      if (
                        option.isOtherOption &&
                        questionType === "singleSelect"
                      )
                        return;
                      checkResult(
                        e,
                        { ...option, title: stripTags(optionTitle) },
                        id
                      );
                    }}
                    style={{
                      ...restOfAnswersStyle,
                      // border: styleObject.border || "none",
                      height: "auto",
                      display: "inline-flex",
                      flexDirection: "column",
                      opacity: isDisabeld ? 0.5 : 1,
                      cursor: isDisabeld ? "not-allowed" : "inherit",
                    }}
                    className="answerContainer"
                  >
                    {answerWithImages ? (
                      <div style={containerSize(answersStyle)}>
                        <div
                          style={{
                            width: `${answersStyle.picSize}px`,
                            height: `${answersStyle.picSize}px`,
                            display: "flex",
                            margin: "auto",
                          }}
                        >
                          {" "}
                          <img
                            type="button"
                            title=""
                            src={option.src || "/img/picFrame.png"}
                            alt={option.mediaAltText}
                            className="answerPic"
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <ToggleInputAndText
                      isRichText={true}
                      padding={padding}
                      isDisabeld={isDisabeld}
                      value={option.title}
                      id={option.id}
                      name="title"
                      onClick={() => {}}
                      defaultShowInput={isPreview}
                      data={data}
                      onDraw={onDraw}
                      // style={answersStyle}
                      isEdit={isEdit}
                      placeholder="Answer"
                      handleSpecialMessage={handleSpecialMessage}
                      // removed the answers without pics from the exclusion as a fix

                      // className={
                      //   !answerWithImages || questionType == "multiSelect"
                      //     ? ""
                      //     : "answerBTN"
                      // }
                      className={
                        questionType == "multiSelect" ||
                        questionType == "singleSelect"
                          ? "text-center"
                          : "answerBTN text-center"
                      }
                      style={{
                        ...answersStyle,
                        width: `${answersStyle.textContainerSize}px`,
                        border: "none",
                        boxShadow: "none",
                      }}
                      isResult={false}
                    />
                  </div>
                </div>
              );
            })}
        </div>
        {(otherOption && otherOption.isOtherOption && drawOtherInput) ||
        (otherOption &&
          otherOption.isOtherOption &&
          selectedOptions.find((e) => e.id == otherOption.id)) ? (
          <div className="w-100 d-flex justify-content-center">
            <div className="other-input-input row">
              <label>{otherOptionFreeTextLabel}</label>
              <input
                type="text"
                className="form-control"
                value={otherInputTextValue}
                onChange={(e) => {
                  setOtherInputValueText(e.target.value);
                  if (isPreviewDesignMode) return;
                  if (questionType === "multiSelect") {
                    checkResult(
                      e,
                      {
                        ...otherOption,
                        title: stripTags(`other_option::${e.target.value}`),
                        override: true,
                      },
                      id
                    );
                  }
                }}
              />
              {questionType === "singleSelect" ? (
                <button
                  className="btn"
                  style={{
                    ...ButtonstyleObj,
                    width: "fit-content",
                    margin: "auto",
                  }}
                  onClick={(e) => {
                    if (isPreviewDesignMode) return;
                    if (isDisabeld) return;
                    if (customOtherTextRequired && !otherInputTextValue)
                      return toggleErrorMessage(
                        true,
                        otherOptionFreeTextErrorMessage
                      );
                    setIsDisabeld(true);
                    checkResult(
                      e,
                      {
                        ...otherOption,
                        title: stripTags(
                          `other_option::${otherInputTextValue}`
                        ),
                      },
                      id
                    );
                    setSelectedTOption(otherOption.id, quizType);
                  }}
                >
                  {customOtherBtnText || "Next"}
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }

  const drawOption = (option, index, provided) => {
    return (
      <div
        onClick={(e) => handleEditView(e, "buttonAnswerStyle", option.id)}
        className="allowAddAnswers answerContainer"
        // style={{ borderRadius: answersStyle.borderRadius ? `${answersStyle.borderRadius} !important` : "inherit" }}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={{
          background,
          border,
          borderRadius,
        }}
        // key={option.id}
      >
        <div style={{ ...restOfAnswersStyle, margin: "0px" }}>
          <div className="correctContainer">
            {type === "assessment" ? (
              <>
                <input
                  className="editAnswer w-100"
                  type="checkbox"
                  name="correct"
                  title="Mark correct"
                  checked={option.correct}
                  onChange={(e) =>
                    changeQuestionsOptionsDetails(
                      {
                        target: {
                          name: "correct",
                          value: e.target.checked,
                          type: e.target.type,
                        },
                      },
                      index,
                      id
                    )
                  }
                />
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="optionMenuTop">
            <div className="optionMenu">
              <a
                className="editAnswer"
                title="Mark correct"
                onClick={(e) => handleEditView(e, "buttonExtraData", option.id)}
                type="button"
              ></a>

              <a className="properties" onClick={() => null} type="button"></a>
              <a
                className="addAnswer"
                onClick={() => addOptionToQuestion(id, index)}
                type="button"
              ></a>
              {options.length > 1 ? (
                <a
                  className="deleteAnswer"
                  onClick={() => deleteOptionFromQuestion(index, id)}
                  type="button"
                ></a>
              ) : (
                <></>
              )}
            </div>
          </div>
          {answerWithImages ? (
            <>
              <div style={containerSize(answersStyle)}>
                {" "}
                <div
                  style={{
                    maxWidth: `${answersStyle.picSize}px`,
                    maxHeight: `${answersStyle.picSize}px`,
                    display: "flex",
                    margin: "auto",
                  }}
                >
                  <ChooseImageModal
                    index={index}
                    id={id}
                    isEdit={props.isEdit}
                    image={option.src || "/img/picFrame.png"}
                    questionType={questionType}
                    mediaAltText={option.mediaAltText}
                    changeOptionImage={changeOptionImage}
                    style={{
                      width: `${answersStyle.picSize}px`,
                      height: `${answersStyle.picSize}px`,
                    }}
                  />
                </div>
              </div>

              <button
                style={{
                  top: -50,
                  right: "-40%",
                  height: "fit-content",
                }}
                className="btn- btn-link remove-image-icon"
                onClick={() => changeOptionImage(id, index, "")}
              >
                <IoTrashOutline size={20} />{" "}
              </button>
            </>
          ) : (
            <></>
          )}
          <ToggleInputAndText
            isRichText={true}
            value={option.title}
            padding={padding}
            handleChange={(e) => changeQuestionsOptionsDetails(e, index, id)}
            id={option.id}
            name="title"
            defaultShowInput={isPreview}
            data={data}
            // style={answersStyle}
            isEdit={isEdit}
            placeholder="Answer"
            handleSpecialMessage={handleSpecialMessage}
            // removed the answers without pics from the exclusion as a fix

            // className={
            //   !answerWithImages || questionType == "multiSelect"
            //     ? ""
            //     : "answerBTN"
            // }
            style={{
              width: `${answersStyle.textContainerSize}px`,
              margin: "auto",
              wordBreak: "break-word",
              border: "none",
              boxShadow: "none",
            }}
            className={
              questionType == "multiSelect" || questionType == "singleSelect"
                ? "text-center"
                : "answerBTN text-center"
            }
            isResult={false}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId={`question_${id}`}
          direction={`${verticalAnswers ? "vertical" : "horizontal"}`}
        >
          {(provided, snapshot) => {
            return (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={`answersContainer answers ${isElementActiveClass} ${
                  verticalAnswers ? "verticalAnswers " : ""
                } ${alignVertical}`}
              >
                {/* <a className="addAnswer" onClick={() => addOptionToQuestion(id)} type="button"></a> */}

                {options
                  .filter((option) => !option.hide)
                  .map((option, index) => {
                    return (
                      <Draggable
                        key={option.id}
                        draggableId={option.id}
                        index={index}
                        isDragDisabled={option.isOtherOption}
                      >
                        {(provided, snapshot) => {
                          return drawOption(option, index, provided);
                        }}
                      </Draggable>
                    );
                  })}

                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
