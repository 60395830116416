import React, { useEffect, useState } from "react";
import { websiteUrl, configHostUrl } from "../../../config";
import { copyToClip } from "../../../utils";

function FullPage(props) {
  const { slug, guid, path } = props;
  const [embedCode, setEmbedCode] = useState("");

  useEffect(() => {
    // const embed = `<div><div id=${guid} data-url="${path}" data-width='100%'></div><script src="${websiteUrl}/assets/js/hippoloader.js"></script><script>initEmbed("${guid}", "fullpage", ["${websiteUrl}"]);</script></div>`;
    const embed = `<div><div id=${guid} data-knowy-url="${path}" data-knowy-type="fullpage" data-url="${path}" data-width="100%"></div><script src="//app.knowyyy.com/embed.js"></script></div>`;


      setEmbedCode(embed);
  }, []);

  return (
    <div id="instant" className="active innerTabs">
      <div className="banner">
        <h3>Full Page</h3>
        <p>
            Knowy's Full Page Embed feature allows you to seamlessly integrate your forms into your website or landing page. With a single line of code, you can display your form in its entirety, delivering a seamless user experience for your audience.
        </p>
      </div>
      <div className="screens custom">
        <div className="mobail">
          <span></span>
        </div>
        <div className="desktop">
          <span></span>
        </div>
      </div>
      <div className="style full">
        <div>
          <h3>Code:</h3>
          <textarea value={embedCode}></textarea>
        </div>
      </div>

      <a
        title=""
        className="copy pointer"
        onClick={() => copyToClip(embedCode)}
      >
        Copy
      </a>
    </div>
  );
}

export default FullPage;
