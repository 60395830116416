import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import i18n from "../../../translate/i18n";
import Modal from "react-bootstrap/esm/Modal";
import { BiFileBlank } from "react-icons/bi";
import { AiOutlineFileDone, AiOutlineShoppingCart } from "react-icons/ai";

function AddResultModal(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { handleCreate, quizType } = props;

  const onBlur = () => {
    setIsOpen(false);
  };

  const createContent = (type, qt = null) => {
    handleCreate(type, qt);
    onBlur();
  };
  const { t } = props;
  return (
    <div className="addContent-wapper">
      <a
        type="button"
        title=""
        onClick={() =>
          quizType === "ecom" ? setIsOpen(true) : handleCreate("result")
        }
        className="add"
      >
        <span>+</span> {t("leftBar-add-result")}
      </a>
      <Modal
        className="addResultContent-modal"
        size="sm"
        show={isOpen}
        onHide={onBlur}
      >
        <div
          className={
            i18n.language == "he" ? "addContent addContent-rtl" : "addContent"
          }
          tabIndex="1"
        >
          <div className="d-flex flex-column">
            <h4 className="d-flex">
              <AiOutlineFileDone size={20} />
              Result
            </h4>
            <div className="d-flex flex-column" style={{ marginLeft: "30px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <BiFileBlank size={20} />
                <a
                  style={{ padding: "0 0 0 5px" }}
                  type="button"
                  onClick={() => createContent("result")}
                  title="Default"
                >
                  Default
                </a>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <AiOutlineShoppingCart size={20} />
                <a
                  style={{ padding: "0 0 0 5px" }}
                  type="button"
                  onClick={() => createContent("result", "ecom")}
                  title="Ecom"
                >
                  Ecom
                </a>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default withTranslation()(AddResultModal);
