import React, { useEffect, useRef } from "react";
import "./Add.css";
import Pagination from "../Pagination";
import ProgressBar from "../ProgressBar";
import { generateFunctionName } from "../../../utils";
// import { ReactLiquid } from "react-liquid";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  VKShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  VKIcon,
  WhatsappIcon,
} from "react-share";
import ToggleInputAndText from "../../toggleInputAndText";
import ChooseImageModal from "../ChooseImageModal";
import Nav from "../Nav";
import {
  changeRightBarView,
  saveAnswer,
  chnageQuillTollbarElement,
} from "../../../redux/actions";
import { connect } from "react-redux";
import ViewContact from "./ViewContact";
import ViewQuestion from "./Question/ViewQuestion";
import Spinner from "react-bootstrap/Spinner";
import pageLayout from "../Add/Question/Customize/pageLayout";
import { stripTags } from "../../../utils";
import { Helmet } from "react-helmet";
import ViewResult from "./Result/ViewResult";

export const ViewIsImg = (
  mediaImageSrc,
  color = "grey",
  opacity = "0",
  layout,
  type,
  mediaVideoSrc,
  headerOrFooterHeight
) => {
  return mediaImageSrc ? (
    <div
      className="pic"
      role="img"
      aria-label="lala"
      style={{
        zIndex: 2,
        backgroundImage: type == "image" ? `url('${mediaImageSrc}')` : "",
        position: layout != 4 ? "relative" : "",
        display: "flex",
      }}
    >
      {type == "video" ? (
        <video
          style={{
            width: "100",
            height:
              layout == 1
                ? "150px"
                : "calc(100vh - " + headerOrFooterHeight + "px)",
            objectFit: "cover",
          }}
          width="100%"
          height="100%"
          preload="auto"
          playsInline
          poster={`${mediaImageSrc}`}
          muted={true}
          loop={true}
          autoPlay={true}
        >
          <source src={`${mediaVideoSrc}`} type="video/mp4" />
        </video>
      ) : (
        <></>
      )}
      <div
        style={{
          width: "100%",
          backgroundColor: color,
          opacity: `${opacity}%`,
          height: "100%",
          position: "absolute",
        }}
      ></div>
    </div>
  ) : (
    <></>
  );
};

export const handleSectionStyle = (context, keyInObject, props) => {
  let {
    isPreview,
    accessibility = {},
    isMobile,
    isTablet,
    isMobilePreview,
  } = props;
  isMobile = isMobilePreview || isMobile;
  const { style } = props.onDraw;
  const { settings } = props.data;
  // if (context == "title") console.log(style[context]);
  if (!keyInObject) return;
  let styleObject = props.onDraw[keyInObject] ? style : settings;
  if (!styleObject[context]) return;
  styleObject = styleObject[context];

  //Font size
  let fontSize = "14";
  if (isMobile && styleObject.mobileFontSize)
    fontSize = styleObject.mobileFontSize;
  else if (isTablet && styleObject.tabletFontSize)
    fontSize = styleObject.tabletFontSize;
  else if (styleObject.fontSize) fontSize = styleObject.fontSize;

  //Top padding
  let topPadding = 0;
  if (styleObject.padding) {
    if (isMobile && styleObject.padding.mobileTop)
      topPadding = styleObject.padding.mobileTop;
    else if (isTablet && styleObject.padding.tabletTop)
      topPadding = styleObject.padding.tabletTop;
    else if (styleObject.padding.top) topPadding = styleObject.padding.top;
  }

  //Right padding
  let rightPadding = 0;
  if (styleObject.padding) {
    if (isMobile && styleObject.padding.mobileRight)
      rightPadding = styleObject.padding.mobileRight;
    else if (isTablet && styleObject.padding.tabletRight)
      rightPadding = styleObject.padding.tabletRight;
    else if (styleObject.padding.right)
      rightPadding = styleObject.padding.right;
  }

  //Left padding
  let leftPadding = 0;
  if (styleObject.padding) {
    if (isMobile && styleObject.padding.mobileLeft)
      leftPadding = styleObject.padding.mobileLeft;
    else if (isTablet && styleObject.padding.tabletLeft)
      leftPadding = styleObject.padding.tabletLeft;
    else if (styleObject.padding.left) leftPadding = styleObject.padding.left;
  }

  //Bottom padding
  let bottomPadding = 0;
  if (styleObject.padding) {
    if (isMobile && styleObject.padding.mobileBottom)
      bottomPadding = styleObject.padding.mobileBottom;
    else if (isTablet && styleObject.padding.tabletBottom)
      bottomPadding = styleObject.padding.tabletBottom;
    else if (styleObject.padding.bottom)
      bottomPadding = styleObject.padding.bottom;
  }

  return {
    color: styleObject.color,
    padding: styleObject.padding
      ? `${topPadding}px ${rightPadding}px ${bottomPadding}px ${leftPadding}px `
      : "0px 0px 0px 0px",
    fontSize: `${parseInt(fontSize || "14") + (isPreview ? accessibility.fontSize : 0)
      }px`,
    fontStyle: styleObject.fontStyle,
    fontWeight: styleObject.fontWeight,
    fontFamily: styleObject.fontFamily
      ? styleObject.fontFamily.replace(/\+/g, " ")
      : "open sans",
    textAlign: styleObject.textAlign || "center",
    borderRadius: `${styleObject.borderRadius}px`,
    background: styleObject.background,
    border: `${styleObject.borderSize}px ${styleObject.borderColor} solid`,
  };
};

class ViewStep extends React.Component {
  handleEditView = (event, context, RightBarViewTab) => {
    const { actions, isPreview, onDraw, isCalculating } = this.props;
    if (!isPreview) actions.changeRightBarView(context, "", RightBarViewTab);

    //  added isedit option
    if (this.props.isEdit) {
      this.props.changeTheOnEditObject(onDraw.id);
    }
    event.stopPropagation();
  };

  handleSpecialMessage = () => {
    let { arrayOfData, numberOfCorrect, variables } = this.props.data;
    numberOfCorrect = numberOfCorrect ? numberOfCorrect : 0;
    const questionsCount = arrayOfData.filter(
      (step) => step.type === "question"
    ).length;
    if (variables) {
      variables = variables.map((variable) => {
        return { [variable.name]: variable.value };
      });
      variables = Object.assign({}, ...variables);
    } else {
      variables = {};
    }

    const data = { questionsCount, numberOfCorrect, ...variables };
    return data;
  };

  handleOnlyScriptsThatShouldRun = () => {
    return Object.values(this.props.customScripts || {}).filter((script) =>
      this.checkIfRenderScript(script)
    );
  };

  checkIfRenderScript = (scriptData) => {
    const { logicScriptToRun } = this.props.data;
    if (!scriptData.active) return;
    if (![2, 3, 4, 5, 6].includes(scriptData.runtime)) return;
    if (scriptData.runtime == 3 && this.props.onDraw.type !== "result") return; // only result pages
    if (scriptData.runtime == 4 && this.props.onDraw.type === "result") return; // only non result pages
    if (scriptData.runtime == 5 && Array.isArray(scriptData.specificSteps)) {
      //Only specific steps
      if (
        !scriptData.specificSteps.find(
          (step) => step.value == this.props.onDraw.id
        )
      )
        return;
    }
    if (scriptData.runtime == 6 && scriptData.id != logicScriptToRun) return; // script from logics
    return true;
  };

  renderScriptIfValid = (scriptData) => {
    if (
      scriptData.script.includes("<script>") &&
      scriptData.script.includes("</script>")
    ) {
      const newScript = scriptData.script
        .replaceAll("<script>", "")
        .replaceAll("</script>", "");
      return (
        <script>{`(function ${generateFunctionName()}(){${newScript}})();`}</script>
      );
    } else if (
      scriptData.script.includes("<noscript>") &&
      scriptData.script.includes("</noscript>")
    ) {
      const newScript = scriptData.script
        .replaceAll("<noscript>", "")
        .replaceAll("</noscript>", "");
      return <noscript>{newScript}</noscript>;
    }
  };

  render() {
    let {
      id,
      title = "",
      description = "",
      share,
      withHeader,
      withFooter,
      withPagination,
      withProgressBar,
      withSubtitle,
      mediaImageSrc,
      mediaAltText = "",
      mediaType,
      showMedia,

      layout = "1",
      buttonText = "",
      buttonPosition = "center",
      startWithEnterKey, //for start page
    } = this.props.onDraw;

    const {
      settings,
      isEdit,
      header,
      footer,
      transitions = {},
      sliderAnimation = "moveUp",
    } = this.props.data;
    const { transitionDuration = 0.6, useTransitions = true } = transitions;
    const {
      isCalculating,
      customScripts = {},
      calculatedProducts = [],
    } = this.props;

    const titleStyle = handleSectionStyle(
      "title",
      "useTitleCustomStyle",
      this.props
    );

    const subtitleStyle = handleSectionStyle(
      "subtitle",
      "useSubTitleCustomStyle",
      this.props
    );

    const buttonStyle = handleSectionStyle(
      "button",
      "useButtonCustomStyle",
      this.props
    );

    let { isPreview, isMobile, isTablet, isMobilePreview } = this.props;
    isMobile = isMobilePreview || isMobile;

    const data = this.handleSpecialMessage();
    const url = window.location.href;
    const publishClass = !this.props.isEdit ? "publish-quiz-margin" : "";

    const styleProp = { ...this.props.style }; //we copy the style to inject 100% width for overly BG
    styleProp.width = "100%";

    const linksLength =
      footer && footer.links && footer.links.length ? footer.links.length : 0;

    const footerHeight = linksLength * 25 > 50 ? linksLength * 25 : 50;
    //todo: get the header height from theme and use it instead of 150
    let headerOrFooterHeight = withHeader ? header.height || 150 : 0;
    headerOrFooterHeight = withFooter
      ? 100 + headerOrFooterHeight
      : headerOrFooterHeight;
    const onlyScriptsThatShouldRun = this.handleOnlyScriptsThatShouldRun();
    return (
      <>
        {isPreview && onlyScriptsThatShouldRun.length ? (
          <Helmet>
            {onlyScriptsThatShouldRun.map((itr) => {
              return this.renderScriptIfValid(itr);
            })}
          </Helmet>
        ) : (
          <></>
        )}
        <div
          className={`questionContent insideSlideData ${publishClass}`}
          style={{ position: "relative" }}
        >
          {styleProp.backgroundOverlayOpacity > 0 ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                backgroundColor: `${styleProp.backgroundOverlayColor}`,
                opacity: `${styleProp.backgroundOverlayOpacity}%`,
              }}
            ></div>
          ) : (
            ""
          )}

          <div
            className={"quizDiv"}
            style={styleProp}
            onClick={(e) => {
              this.props.actions.chnageQuillTollbar("");
              this.handleEditView(e, "pageLayout", "customize");
            }}
          // className={`questionContent insideSlideData ${publishClass}`}
          >
            {styleProp.backgroundMediaType == "video" ? (
              <video
                key={styleProp.backgroundVideoSrc}
                style={{
                  width: "100",
                  height: "100%",
                  objectFit: "cover",
                  position: "absolute",
                }}
                poster={`${styleProp.backgroundImage}`}
                width="100%"
                height="100%"
                preload="auto"
                playsInline
                muted={true}
                loop={true}
                autoPlay={true}
              >
                <source
                  src={`${styleProp.backgroundVideoSrc}`}
                  type="video/mp4"
                />
              </video>
            ) : (
              <></>
            )}

            {styleProp.backgroundMediaType == "video" &&
              styleProp.backgroundOverlayOpacity > 0 ? (
              <div
                className="pic"
                style={{
                  width: "100%",
                  position: "absolute",
                  top: "0",
                  backgroundColor: `${styleProp.backgroundOverlayColor}`,
                  opacity: `${styleProp.backgroundOverlayOpacity}%`,
                  height: "100%",
                }}
              ></div>
            ) : (
              ""
            )}
            <Nav
              // style={hederStyle}
              data={this.props.data.header}
              position="header"
              show={withHeader}
              rightBarView={this.props.rightBarView}
              handleEditView={this.handleEditView}
              useHeaderCustomStyle={this.props.onDraw.useHeaderCustomStyle}
              customStyle={this.props.onDraw.style.header}
              isEdit={this.props.isEdit}
              isMobile={isMobile}
              isTablet={isTablet}
            />

            <div
              className={`layaou${this.props.onDraw.showMedia ? layout : "4"}`}
              style={{
                minHeight: this.props.isEdit
                  ? ""
                  : "calc(100vh - " + headerOrFooterHeight + "px)",
              }}
            >
              {this.props.onDraw.showMedia && this.props.isEdit ? (
                <div
                  role="img"
                  aria-label={mediaAltText}
                  className={
                    this.props.onDraw.mediaType == "image" ? "pic" : "pic"
                  }
                  style={{
                    alignSelf:
                      this.props.onDraw.mediaType == "image" ? "auto" : "auto",
                    display: "flex",
                    backgroundImage:
                      this.props.onDraw.mediaType == "image"
                        ? `url('${mediaImageSrc}')`
                        : "",
                    zIndex: 2,
                    position: layout != 4 ? "relative" : "",
                  }}
                >
                  {this.props.onDraw.mediaType == "video" ? (
                    <video
                      key={this.props.onDraw.mediaVideoSrc}
                      role="img"
                      aria-label={mediaAltText}
                      style={{
                        width: "100",
                        height:
                          layout == 1
                            ? "150px"
                            : "calc(100vh - " + headerOrFooterHeight + "px)",
                        objectFit: "cover",
                      }}
                      width="100%"
                      height="100%"
                      preload="auto"
                      playsInline
                      poster={`${mediaImageSrc}`}
                      muted={true}
                      loop={true}
                      autoPlay={true}
                    >
                      <source
                        src={`${this.props.onDraw.mediaVideoSrc}`}
                        type="video/mp4"
                      />
                    </video>
                  ) : (
                    <></>
                  )}

                  {this.props.onDraw.mediaImageOverlayOpacity > 0 ? (
                    <div
                      className="pic"
                      style={{
                        width: "100%",
                        position: "absolute",
                        top: "0",
                        backgroundColor: `${this.props.onDraw.mediaImageOverlayColor ||
                          "rgb(128,128,128, 1)"
                          }`,
                        opacity: `${this.props.onDraw.mediaImageOverlayOpacity}%`,
                        height: "100%",
                      }}
                    ></div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <>
                  {this.props.onDraw.showMedia
                    ? ViewIsImg(
                      mediaImageSrc,
                      this.props.onDraw.mediaImageOverlayColor,
                      this.props.onDraw.mediaImageOverlayOpacity,
                      layout,
                      this.props.onDraw.mediaType,
                      this.props.onDraw.mediaVideoSrc,
                      headerOrFooterHeight
                    )
                    : ""}
                </>
              )}
              <div className="qContent">
                <ProgressBar
                  stepId={id}
                  show={withProgressBar}
                  list={this.props.data.arrayOfData}
                  isEdit={this.props.isEdit}
                  data={this.props.data.settings.bar || {}}
                  direction={this.props.direction}
                  isMobile={isMobile}
                  isTablet={isTablet}
                />
                <div
                  key={id}
                  style={
                    useTransitions && !this.props.isEdit
                      ? {
                        animation: `${sliderAnimation} ${transitionDuration}s`,
                      }
                      : {}
                  }

                >

                  {!isCalculating && (
                    <div
                      // style={{ width: "100%", zIndex: 5 }}
                      // className="titleContainer"
                      //
                      className={
                        this.props.isEdit &&
                          this.props.rightBarView.context.replace("Style", "") ===
                          "title"
                          ? "titleContainer active-element"
                          : "titleContainer"
                      }
                      onClick={(e) =>
                        this.handleEditView(e, "titleStyle", "design")
                      }
                    >
                      <ToggleInputAndText
                        isRichText={true}
                        value={title}
                        handleChange={(e) => this.props.handleChange(e, id)}
                        id={`${id}_title`}
                        name="title"
                        defaultShowInput={isPreview}
                        data={this.props.data}
                        style={titleStyle}
                        isEdit={this.props.isEdit}
                        placeholder="Title"
                        isResult={
                          this.props.stepType == "result" ? true : false
                        }
                      />
                    </div>
                  )}
                  {withSubtitle && !isCalculating ? (
                    <div
                      style={{ width: "100%" }}
                      className={
                        this.props.isEdit &&
                          this.props.rightBarView.context.replace("Style", "") ===
                          "subtitle"
                          ? "descriptionContainer active-element"
                          : "descriptionContainer"
                      }
                      onClick={(e) =>
                        this.handleEditView(e, "subtitleStyle", "design")
                      }
                    >
                      <ToggleInputAndText
                        isRichText={true}
                        value={description}
                        style={subtitleStyle}
                        handleChange={(e) => this.props.handleChange(e, id)}
                        id={`${id}_subtitle`}
                        name="description"
                        data={this.props.data}
                        placeholder="Subtitle"
                        isEdit={this.props.isEdit}
                        isResult={
                          this.props.stepType == "result" ? true : false
                        }
                      />
                    </div>
                  ) : (
                    <></>
                  )}

                  {this.props.onDraw.id.startsWith("R_") &&
                    this.props.onDraw.withCallToAction ? (
                    <div
                      // className="contactBTNdiv"
                      className={
                        this.props.isEdit &&
                          this.props.rightBarView.context === "buttonStyle"
                          ? "callToActionBtn active-element"
                          : "callToActionBtn pointer"
                      }
                      style={{
                        display: "flex",
                        justifyContent: buttonStyle.textAlign,
                      }}
                    >
                      {isPreview ? (
                        <a
                          type="button"
                          // onClick={() => }
                          href={this.props.onDraw.callToActionLink}
                          target={
                            this.props.onDraw.callToActiontarget
                              ? "_blank"
                              : "_top"
                          }
                          className="callToActionBtn pointer"
                          name="callToActionBtn"
                          style={buttonStyle}
                        >
                          {stripTags(buttonText || "Press")}
                        </a>
                      ) : (
                        <div
                          onClick={(e) =>
                            this.handleEditView(e, "buttonStyle", "design")
                          }
                        >
                          <ToggleInputAndText
                            isRichText={true}
                            value={buttonText || "Press"}
                            handleChange={(e) => this.props.handleChange(e, id)}
                            id={`buttonText_${id}`}
                            name="buttonText"
                            data={this.props.data}
                            tag="span"
                            isEdit={this.props.isEdit}
                            className="allToActionBtn pointer"
                            classNameForTag="quillTag"
                            placeholder="Press"
                            style={buttonStyle}
                            isResult={
                              this.props.stepType == "result" ? true : false
                            }
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}

                  {this.props.stepType == "question" && !isCalculating ? (
                    <ViewQuestion
                      data={this.props.data}
                      onDraw={this.props.onDraw}
                      direction={this.props.direction}
                      handleChangeContactDetails={
                        this.props.handleChangeContactDetails
                      }
                      addInputToContact={this.props.addInputToContact}
                      deleteInputFromContact={this.props.deleteInputFromContact}
                      deleteFromArray={this.props.deleteFromArray}
                      handleChange={this.props.handleChange}
                      style={this.props.style}
                      changeTheOnEditObject={this.props.changeTheOnEditObject}
                      isEdit={this.props.isEdit}
                      isPreview={this.props.isPreview}
                      saveAnswer={this.props.saveAnswer}
                      changeQuestionsOptionsDetails={
                        this.props.changeQuestionsOptionsDetails
                      }
                      handleChangeQuestion={this.props.handleChangeQuestion}
                      addOptionToQuestion={this.props.addOptionToQuestion}
                      addRowToMatrix={this.props.addRowToMatrix}
                      addColumnToMatrix={this.props.addColumnToMatrix}
                      handleChangeMatrixData={this.props.handleChangeMatrixData}
                      deleteMatrixItem={this.props.deleteMatrixItem}
                      changeOptionsIndexes={this.props.changeOptionsIndexes}
                      deleteOptionFromQuestion={
                        this.props.deleteOptionFromQuestion
                      }
                      stepIndex={this.props.stepIndex}
                    />
                  ) : (
                    <></>
                  )}

                  {this.props.stepType == "contact" && !isCalculating ? (
                    <ViewContact
                      stepIndex={this.props.stepIndex}
                      data={this.props.data}
                      onDraw={this.props.onDraw}
                      direction={this.props.direction}
                      handleChangeContactDetails={
                        this.props.handleChangeContactDetails
                      }
                      addInputToContact={this.props.addInputToContact}
                      deleteInputFromContact={this.props.deleteInputFromContact}
                      deleteFromArray={this.props.deleteFromArray}
                      handleChange={this.props.handleChange}
                      style={this.props.style}
                      changeTheOnEditObject={this.props.changeTheOnEditObject}
                      isEdit={this.props.isEdit}
                      isPreview={this.props.isPreview}
                      saveContact={this.props.saveContact}
                    />
                  ) : (
                    <></>
                  )}
                  {this.props.stepType == "result" && !isCalculating ? (
                    <ViewResult
                      stepIndex={this.props.stepIndex}
                      data={this.props.data}
                      onDraw={this.props.onDraw}
                      isEdit={this.props.isEdit}
                      isPreview={this.props.isPreview}
                      calculatedProducts={calculatedProducts}
                      handleEditView={this.handleEditView}
                    />
                  ) : (
                    <></>
                  )}
                  {(this.props.stepType == "start" ||
                    this.props.stepType == "text") &&
                    !isCalculating ? (
                    <div
                      // className="d-flex answersButton"
                      className={
                        this.props.isEdit &&
                          this.props.rightBarView.context.replace("Style", "") ===
                          "button"
                          ? "d-flex answersButton active-element"
                          : "d-flex answersButton"
                      }
                      style={{
                        textAlign: buttonPosition || "center",
                        justifyContent: buttonStyle
                          ? buttonStyle.textAlign || "center"
                          : "center",
                        zIndex: 5,
                      }}
                      onClick={(e) => {
                        this.handleEditView(e, "buttonStyle", "design");
                      }}
                    >
                      {startWithEnterKey && "press Enter ↵"}
                      <ToggleInputAndText
                        isRichText={true}
                        isButton={true}
                        isDisabled={this.props.isPreviewDesignMode}
                        value={buttonText}
                        handleChange={(e) => this.props.handleChange(e, id)}
                        id={`buttonText_${id}`}
                        name="buttonText"
                        data={this.props.data}
                        tag="span"
                        isEdit={this.props.isEdit}
                        style={{ ...buttonStyle, width: "fit-content" }}
                        className="contactBTN pointer"
                        placeholder="Write text here"
                        classNameForTag="quillTag"
                        onClick={() =>
                          !this.props.isPreviewDesignMode
                            ? this.props.saveAnswer(this.props.stepType)
                            : null
                        }
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {isCalculating ? (
                  <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                    <Spinner
                      style={{
                        width: "400px",
                        height: "400px",
                        border: ".9em solid currentColor",
                        borderRightColor: "transparent",
                      }}
                      animation="border"
                      variant="success"
                    />
                  </div>
                ) : (
                  <> </>
                )}

                <Pagination
                  style={this.props.data.settings.backButton}
                  stepId={id}
                  isMobile={isMobile}
                  isTablet={isTablet}
                  stepBack={this.props.stepBack || (() => { })}
                  show={withPagination}
                  list={this.props.data.arrayOfData}
                  isEdit={this.props.isEdit}
                />
              </div>
            </div>
            <Nav
              show={withFooter}
              data={this.props.data.footer}
              position="footer"
              rightBarView={this.props.rightBarView}
              handleEditView={this.handleEditView}
              useFooterCustomStyle={this.props.onDraw.useFooterCustomStyle}
              customStyle={this.props.onDraw.style.footer}
              isPreview={this.props.isPreview}
              isEdit={this.props.isEdit}
              isMobile={isMobile}
              isTablet={isTablet}
            // style={footer}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      saveAnswer: (answerDetails) => dispatch(saveAnswer(answerDetails)),
      changeRightBarView: (context, subContextId, rightBarViewTab) =>
        dispatch(changeRightBarView(context, subContextId, rightBarViewTab)),
      chnageQuillTollbar: (toolbarId) =>
        dispatch(chnageQuillTollbarElement(toolbarId)),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewStep);
