import React, { useEffect } from "react";
import "./analyze.css";
import { useTable, useSortBy, usePagination } from "react-table";
import OtherContactInfoModal from "./OtherContactInfo";
import { connect } from "react-redux";
import {
  getQuizAnalyzeTable,
  downloadExcellAction,
  getQuizAnalyzeTableSuccess
} from "../../../redux/actions";
import ExcelExport from "./ExcelExport";
import AnalyzeRightTable from "./analyxeRightTable";
import { IoLogoChrome } from "react-icons/io";
import { SiOpera } from "react-icons/si";
import { FaEdge } from "react-icons/fa";
import { SiSafari } from "react-icons/si";
import { SiFirefox } from "react-icons/si";
import { GoDeviceDesktop } from "react-icons/go";
import { GoDeviceMobile } from "react-icons/go";
import { HiOutlineGlobeAlt } from "react-icons/hi";
import { IconContext } from "react-icons";
import { object } from "prop-types";

function Table({
  columns,
  data,
  tableId,
  onRowClick,
  selectedQuiz,
  quizAnalyzeTable,
  fetchData,
  quizAnalyzeTablePageCount,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: quizAnalyzeTablePageCount, // Update pageCount based on the data length
      autoResetPage: false,
    },
    useSortBy,
    usePagination
  );
  useEffect(() => {
    fetchData({ pageIndex });
  }, [pageIndex]);

  return (
    <div className="analyze-tables-wrapper-leftTable">
      <div className="analyze-tables-wrapper-leftTable-stick">
        <table className="table" id={tableId} {...getTableProps()}>
          <thead className="thead-light">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers
                  .filter(
                    (el) =>
                      el.id !== "create_at_placeholder_0" &&
                      el.id !== "date_placeholder_0" &&
                      el.Header !== "Visit Details"
                  )
                  .map((column) => (
                    <th
                      className="analyze-left-table-header"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      {/* <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span> */}
                    </th>
                  ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              const isRowSelected =
                selectedQuiz && selectedQuiz.id == row.original.id
                  ? "picked-table-row"
                  : "";
              return (
                <tr
                  className={`analyze-left-table-row ${isRowSelected}`}
                  onClick={(e) => onRowClick(e, data[i])}
                  key={i}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, index) => {
                    let toShow = "";

                    if (cell.column.id === "other")
                      toShow = (
                        <div>
                          <OtherContactInfoModal
                            id={`other${i}`}
                            data={cell.value}
                          />
                          <button
                            data-toggle="modal"
                            data-target={`#other${i}`}
                            className="btn btn-light"
                          >
                            🔍
                          </button>
                        </div>
                      );
                    else if (cell.column.Header === "Browser & Device") {
                      const device = quizAnalyzeTable[i]["mobile/desktop"];
                      switch (quizAnalyzeTable[i].browser) {
                        case "Chrome":
                          device === "Desktop"
                            ? (toShow = (
                              <span className="browser-device">
                                <IconContext.Provider
                                  value={{
                                    color: "#818488",
                                    // className: "global-class-name",
                                  }}
                                >
                                  <IoLogoChrome />

                                  <GoDeviceDesktop />
                                </IconContext.Provider>
                              </span>
                            ))
                            : (toShow = (
                              <span className="browser-device">
                                <IconContext.Provider
                                  value={{
                                    color: "#818488",
                                    // className: "global-class-name",
                                  }}
                                >
                                  <IoLogoChrome /> <GoDeviceMobile />
                                </IconContext.Provider>
                              </span>
                            ));

                          break;
                        case "Edge":
                          device === "Desktop"
                            ? (toShow = (
                              <span className="browser-device">
                                <IconContext.Provider
                                  value={{
                                    color: "#818488",
                                    // className: "global-class-name",
                                  }}
                                >
                                  <FaEdge /> <GoDeviceDesktop />
                                </IconContext.Provider>
                              </span>
                            ))
                            : (toShow = (
                              <span className="browser-device">
                                <IconContext.Provider
                                  value={{
                                    color: "#818488",
                                    // className: "global-class-name",
                                  }}
                                >
                                  <FaEdge /> <GoDeviceMobile />
                                </IconContext.Provider>
                              </span>
                            ));

                          break;
                        case "Opera":
                          device === "Desktop"
                            ? (toShow = (
                              <span className="browser-device">
                                <IconContext.Provider
                                  value={{
                                    color: "#818488",
                                    // className: "global-class-name",
                                  }}
                                >
                                  <SiOpera /> <GoDeviceDesktop />
                                </IconContext.Provider>
                              </span>
                            ))
                            : (toShow = (
                              <span className="browser-device">
                                <IconContext.Provider
                                  value={{
                                    color: "#818488",
                                    // className: "global-class-name",
                                  }}
                                >
                                  <SiOpera /> <GoDeviceMobile />
                                </IconContext.Provider>
                              </span>
                            ));

                          break;

                        case "Safari":
                          device === "Desktop"
                            ? (toShow = (
                              <span className="browser-device">
                                <IconContext.Provider
                                  value={{
                                    color: "#818488",
                                    // className: "global-class-name",
                                  }}
                                >
                                  <SiSafari /> <GoDeviceDesktop />
                                </IconContext.Provider>
                              </span>
                            ))
                            : (toShow = (
                              <span className="browser-device">
                                <IconContext.Provider
                                  value={{
                                    color: "#818488",
                                    // className: "global-class-name",
                                  }}
                                >
                                  <SiSafari /> <GoDeviceMobile />
                                </IconContext.Provider>
                              </span>
                            ));

                          break;
                        case "Firefox":
                          device === "Desktop"
                            ? (toShow = (
                              <span className="browser-device">
                                <IconContext.Provider
                                  value={{
                                    color: "#818488",
                                    // className: "global-class-name",
                                  }}
                                >
                                  <SiFirefox /> <GoDeviceDesktop />
                                </IconContext.Provider>
                              </span>
                            ))
                            : (toShow = (
                              <span className="browser-device">
                                <IconContext.Provider
                                  value={{
                                    color: "#818488",
                                    // className: "global-class-name",
                                  }}
                                >
                                  <SiFirefox /> <GoDeviceMobile />
                                </IconContext.Provider>
                              </span>
                            ));

                          break;
                        default:
                          device === "Desktop" ? (
                            (toShow = (
                              <span className="browser-device">
                                <IconContext.Provider
                                  value={{
                                    color: "#818488",
                                    // className: "global-class-name",
                                  }}
                                >
                                  <HiOutlineGlobeAlt /> <GoDeviceDesktop />
                                </IconContext.Provider>
                              </span>
                            ))
                          ) : (
                            <span className="browser-device">
                              <IconContext.Provider
                                value={{
                                  color: "#818488",
                                  // className: "global-class-name",
                                }}
                              >
                                <HiOutlineGlobeAlt /> <GoDeviceMobile />
                              </IconContext.Provider>
                            </span>
                          );
                          break;
                      }
                    } else
                      toShow = typeof cell.value !== null ? cell.value : "";
                    return (
                      <td
                        className={`analyze-left-table-td ${isRowSelected}`}
                        key={index}
                        id={`analyze-left-table-td-${i}`}
                      >
                        {toShow}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="summary-table-pagination">
        <div className="summary-table-pagination-page-number">
          Page
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </div>
        <div className="summary-table-pagination-buttons">
          <button
            className="summary-table-pagination-btn"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {"<"}
          </button>
          <button
            className="summary-table-pagination-btn"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {">"}
          </button>
        </div>
      </div>
    </div>
  );
}

class AnalyzeTable extends React.Component {
  state = {
    selectedQuiz: null,
    currentDataOnSelectedPage: [],
  };

  componentDidMount() {
    this.fetchData({ pageIndex: 0, init: true });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.quizAnalyzeTable.length !== prevProps.quizAnalyzeTable.length
    ) {
      this.fetchData({ pageIndex: 0 });
    }
  }

  fetchData = ({ pageIndex = 0, init }) => {
    if (init) {
      const { id } = this.props.data;
      const { filterObj, additionalFilters } = this.props;
      this.props.actions.getQuizAnalyzeTable({
        id,
        fromDate: null,
        toDate: null,
        filters: filterObj,
        offset: pageIndex * 10,
        additionalFilters,
      });
    }
    this.setState({
      currentDataOnSelectedPage: this.props.quizAnalyzeTable.slice(
        pageIndex * 10,
        pageIndex * 10 + 10
      ),
    });
  };

  downloadExcellFile = () => {
    const { id, published, slug } = this.props.data;
    const { limitRows, quizAnalyzeStats, filterObj, additionalFilters } =
      this.props;
    this.props.actions.downloadExcell({
      quizId: id,
      fromDate: null,
      toDate: null,
      filters: filterObj,
      offset: 0,
      additionalFilters,
      slug,
    });
  };

  /* display the data from left side table row (each row is a different quiz) 
to right side table (display data from that quiz) */
  onRowClick = (e, item) => {
    this.setState({ selectedQuiz: item });
  };

  updateTable = (guid) => {

    const { quizAnalyzeTable, tableItems, data = {} } = this.props;
    const tempData = Object.assign([], quizAnalyzeTable).filter(el => el.guid !== guid)
    this.props.actions.updateTable(tempData, this.props.quizAnalyzeTablePageCount);
    this.setState({ selectedQuiz: guid == quizAnalyzeTable[0].guid ? quizAnalyzeTable[1] : quizAnalyzeTable[0] })

  }

  render() {
    let { quizAnalyzeTable, tableItems, data = {} } = this.props;

    const { arrayOfData = [] } = data;

    if (!this.state.currentDataOnSelectedPage.length) return <></>;

    const columns = [
      {
        Header: "Date",
        id: "date",
        accessor: "create_at",
      },
      {
        Header: "Visit Details",
        id: "visit_details",
        columns: [
          {
            Header: "Score",
            id: "score",
            accessor: "score",
          },
          {
            Header: `Questions Answered`,
            id: "number_of_answers",
            accessor: "number_of_answers",
          },

          {
            Header: "Browser & Device",
            id: "browser",
            accessor: "browser",
          },

          {
            Header: "Source",
            id: "source",
            accessor: "source",
          },
        ],
      },
    ];

    return (
      <div className="tab-content-inner">
        <div className="container">
          <div className="row" style={{ marginBottom: "10px" }}>
            <div className="col">
              <h3>
                <strong>List View:</strong>
              </h3>
            </div>

            <div className="col">
              <ExcelExport downloadExcellFile={this.downloadExcellFile} />
            </div>
          </div>
        </div>
        <div className="analyze-tables-wrapper">
          <Table
            quizAnalyzeTable={this.state.currentDataOnSelectedPage}
            tableId={"leftTable"}
            columns={columns}
            data={this.state.currentDataOnSelectedPage}
            onRowClick={this.onRowClick}
            selectedQuiz={
              this.state.selectedQuiz || this.state.currentDataOnSelectedPage[0]
            }
            fetchData={this.fetchData}
            quizAnalyzeTablePageCount={this.props.quizAnalyzeTablePageCount}
          />
          <AnalyzeRightTable
            updateTable={this.updateTable}
            arrayOfData={arrayOfData}
            columns={this.props.quizAnalyzeHeaders}
            selectedQuiz={this.state.selectedQuiz || quizAnalyzeTable[0]}
            tableId={"rightTable"}
            moveToSummery={this.props.moveToSummery}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    quizAnalyzeTable,
    quizAnalyzeStats,
    quizAnalyzeHeaders,
    excelDataPath,
  } = state;
  return {
    quizAnalyzeTable,
    quizAnalyzeStats,
    quizAnalyzeHeaders,
    excelDataPath,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      getQuizAnalyzeTable: (quizDetails) =>
        dispatch(getQuizAnalyzeTable(quizDetails)),
      downloadExcell: (quizDetails) =>
        dispatch(downloadExcellAction(quizDetails)),
      updateTable: (data, pageCount) => dispatch(getQuizAnalyzeTableSuccess(data, pageCount)),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalyzeTable);
