import React from "react";
import { connect } from "react-redux";
import { saveCustomStyle } from "../../../redux/actions";
import ConfigActionButtons from "./ConfigActionButtons";
import { withTranslation } from "react-i18next";
import FeatureUnavailable from "../../Subscriptions/FeatureUnavailable";
class CustomStyle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPermited: true
    };
  }

  render() {
    const { customStyle, id } = this.props.data;
    const { t } = this.props;

    if (!this.state.isPermited) {
      return <><FeatureUnavailable isShow="true" hide={() => this.setState({ isPermited: true })} feature="custom_styles" /></>
    }
    return (
      <div className="container">
        <h2>{t("configuration-custom-style-inner")} {!this.props.permitions.custom_styles ? <i class="bi bi-lock-fill "></i> : <></>}</h2>
        {!this.props.permitions.custom_scripts ? <></> : (
          <textarea
            className="form-control"
            value={customStyle}
            onChange={(e) => this.props.handleChangeStyle(e)}
            name="customStyle"
            cols="30"
            rows="20"
          ></textarea>)}
        {/* <button
          className="btn btn-primary"
          onClick={() => this.props.actions.saveCustomStyle(customStyle, id)}
        >
          Save
        </button> */}
        <ConfigActionButtons
          selectedFunc={() => {
            if (!this.props.permitions.custom_styles) {
              return this.setState({ isPermited: false })
            }
            this.props.actions.saveCustomStyle(customStyle, id)
          }
          }
          selectedArgs={[customStyle, id]}
          toast={!this.props.permitions.custom_styles ? null : this.props.toast}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { permitions: state.permitions };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      saveCustomStyle: (customStyle, quizId) =>
        dispatch(saveCustomStyle(customStyle, quizId)),
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CustomStyle)
);
