import React, { useState, useEffect } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import {
  calculateSliderStepOnPercentage,
  formattingPercentageNumber,
  getValueAsPercentage,
  getValueAsPercentageAndReplaceBasedMinAndMax,
} from "../../../../../utils/sliderFunctions";
import "./slider.css";

export default function SliderTypeQuestion(props) {
  const {
    onDraw,
    isPreview,
    isEdit,
    isMobile = false,
    isTablet = false,
    handleEditView,
    rightBarView,
    changeSlider,
  } = props;
  const { id, slider = {} } = onDraw;

  const {
    min = 2,
    max = 15,
    stepSize = 1,
    defaultValue = 5,
    displayCurrentValue = false,
    isPercentage = false,
    baseColor = "#0046B5",
    selectedColor = "rgb(190, 30, 185)",
  } = slider;

  const sliderStyle = {
    baseColor,
    selectedColor,
  };
  const [value, setValue] = useState(defaultValue);
  const [marks, setMarks] = useState({});
  const [step, setStep] = useState(stepSize);

  useEffect(() => {
    //Save the initial value so when pressing next without moving the slider it saved with value;
    changeSlider(value);
  }, []);

  useEffect(() => {
    const newMarks = {};

    if (!isPercentage && stepSize > 0) {
      for (let i = min; i <= max; i += stepSize) {
        const markStyle = {
          color: i > value ? sliderStyle.selectedColor : sliderStyle.baseColor,
        };
        newMarks[i] = {
          style: markStyle,
          label: i,
        };
      }
      setStep(stepSize);
    } else {
      const minPercentage = ((min - min) / (max - min)) * 100;
      const maxPercentage = ((max - min) / (max - min)) * 100;
      newMarks[minPercentage] = {
        style: { color: sliderStyle.baseColor },
        label: `${minPercentage}%`,
      };
      newMarks[maxPercentage] = {
        style: { color: sliderStyle.selectedColor },
        label: `${maxPercentage}%`,
      };

      setStep(calculateSliderStepOnPercentage(min, max));
    }
    setMarks(newMarks);
  }, [
    value,
    min,
    max,
    isPercentage,
    displayCurrentValue,
    stepSize,
    baseColor,
    selectedColor,
  ]);

  const dotStyle = {
    borderColor: sliderStyle.baseColor, // Adjust color of unselected marks
  };

  const activeDotStyle = {
    borderColor: sliderStyle.selectedColor, // Adjust color of selected mark
  };

  const handleChangeValue = (newValue) => {
    let valueAsNumber = newValue;
    if (isPercentage) {
      valueAsNumber = getValueAsPercentageAndReplaceBasedMinAndMax(
        newValue,
        min,
        max
      );
    }
    setValue(valueAsNumber);
    changeSlider(valueAsNumber);
  };

  const getValueByType = () => {
    if (isPercentage) return getValueAsPercentage(value, min, max);
    return value;
  };

  const formatTooltipPosition = () => {
    const slider = document.querySelector(".rc-slider");
    if (!slider) return {};
    const sliderWidth = slider.clientWidth;
    const handle = document.querySelector(".rc-slider-handle");
    if (!sliderWidth) return {};
    const handlePosition = handle.style.left;
    const handleWidth = handle.clientWidth;
    const tooltip = document.querySelector(".custom-tooltip");
    if (!tooltip) return {};
    const tooltipWidth = tooltip.clientWidth - 150;

    const left =
      (parseFloat(handlePosition) * sliderWidth) / 100 -
      tooltipWidth / 2 +
      handleWidth / 2;

    return { left: `${left}px` };
  };
  return (
    <div
      className={`sliderContainer ${
        isEdit && rightBarView.context === "sliderExtraData"
          ? "active-element"
          : ""
      }`}
      onClick={(e) => handleEditView(e, "sliderExtraData")}
    >
      <Slider
        onChange={(newValue) => handleChangeValue(newValue)}
        min={isPercentage ? 0 : min}
        max={isPercentage ? 100 : max}
        step={step}
        marks={marks}
        value={getValueByType()}
        trackStyle={{ backgroundColor: baseColor }} // Customize track color
        railStyle={{ backgroundColor: selectedColor }} // Customize track color
        dotStyle={dotStyle} // Customize unselected mark style
        activeDotStyle={activeDotStyle} // Customize selected mark style
      />
      {displayCurrentValue && (
        <div className="custom-tooltip" style={formatTooltipPosition()}>
          {formattingPercentageNumber(getValueByType())}
          {isPercentage ? "%" : ""}
        </div>
      )}
    </div>
  );
}
