import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

function CallToActionSettings(props) {

    const { handleChangeQuestion, t, id, target, callToActionTarget, callToActionLink } = props
    return <>
        <div className="addSwitch override">
            <label className="switch">
                <input
                    type="checkbox"
                    name="callToActionTarget"
                    checked={callToActionTarget ? true : false}
                    onChange={(e) =>
                        handleChangeQuestion(
                            {
                                target: {
                                    name: "callToActionTarget",
                                    type: "checkbox",
                                    checked: callToActionTarget ? false : true,
                                },
                            },
                            id
                        )
                    }
                />
                <span className="slider round"></span>
            </label>
            Open in new tab


        </div>

        <div className="fields">
            <label> Result button Link</label>
            <input
                type="text"
                name="callToActionLink"
                placeholder={callToActionLink}
                value={callToActionLink || "#"}
                onChange={(e) =>
                    handleChangeQuestion(
                        {
                            target: {
                                name: "callToActionLink",
                                type: "text",
                                value: e.target.value,

                            },
                        },
                        id
                    )
                }
                className="form-control"
            />
        </div>

    </>
}


export default withTranslation()(CallToActionSettings);