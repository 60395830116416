import React, { useEffect, useState } from "react";
import ProductItem from "./ProductItem";
import { addStyleSettings } from "../../../../utils/patchFunctions";
import { connect } from "react-redux";

function ViewResult(props) {
  let {
    feedProducts = [],
    settings,
    useAnswersCustomStyle,
    calculatedProducts,
    isEdit,
  } = props.data;
  const {
    maxProductsOnPage = 0,
    resultType,
    defaultProductLink,
    linkButtonText,
    showProductPrice = true,
    showProductDescription = true,
    showProductCTA = true,
    showProductCategory = false,
  } = props.onDraw;
  const [productsToDraw, setProductsToDraw] = useState([]);
  const [mainProduct, setMainProduct] = useState(null);

  useEffect(() => {
    console.log(isEdit ? feedProducts : calculatedProducts || feedProducts);
    const tempFeedProducts = JSON.parse(
      JSON.stringify(isEdit ? feedProducts : calculatedProducts || feedProducts)
    );
    if (!tempFeedProducts.length) return;
    if (tempFeedProducts.length > maxProductsOnPage) {
      tempFeedProducts.length = maxProductsOnPage;
    }
    const mainProduct = tempFeedProducts.shift();
    setMainProduct(mainProduct);
    setProductsToDraw(tempFeedProducts);
  }, [maxProductsOnPage, calculatedProducts]);

  if (!settings.products) {
    settings = addStyleSettings(settings, "products");
  }
  let {
    isPreview,
    accessibility = {},
    isMobile,
    isTablet,
    isMobilePreview,
  } = props;
  isMobile = isMobilePreview || isMobile;
  let styleObject = settings;

  //Title font size
  let titleFontSize = "14";
  if (isMobile && styleObject.products.titleMobileFontSize)
    titleFontSize = styleObject.products.titleMobileFontSize;
  else if (isTablet && styleObject.products.titleTabletFontSize)
    titleFontSize = styleObject.products.titleTabletFontSize;
  else if (styleObject.products.titleFontSize)
    titleFontSize = styleObject.products.titleFontSize;

  //Description font size
  let descriptionFontSize = "14";
  if (isMobile && styleObject.products.descriptionMobileFontSize)
    descriptionFontSize = styleObject.products.descriptionMobileFontSize;
  else if (isTablet && styleObject.products.descriptionTabletFontSize)
    descriptionFontSize = styleObject.products.descriptionTabletFontSize;
  else if (styleObject.products.descriptionFontSize)
    descriptionFontSize = styleObject.products.descriptionFontSize;

  //Price font size
  let priceFontSize = "14";
  if (isMobile && styleObject.products.priceMobileFontSize)
    priceFontSize = styleObject.products.priceMobileFontSize;
  else if (isTablet && styleObject.products.priceTabletFontSize)
    priceFontSize = styleObject.products.priceTabletFontSize;
  else if (styleObject.products.priceFontSize)
    priceFontSize = styleObject.products.priceFontSize;

  //Price font size
  let linkButtonFontSize = "14";
  if (isMobile && styleObject.products.linkButtonMobileFontSize)
    linkButtonFontSize = styleObject.products.linkButtonMobileFontSize;
  else if (isTablet && styleObject.products.linkButtonTabletFontSize)
    linkButtonFontSize = styleObject.products.linkButtonTabletFontSize;
  else if (styleObject.products.linkButtonFontSize)
    linkButtonFontSize = styleObject.products.linkButtonFontSize;

  //Top padding
  let topPadding = 0;
  if (styleObject.products.padding) {
    if (isMobile && styleObject.products.padding.mobileTop)
      topPadding = styleObject.products.padding.mobileTop;
    else if (isTablet && styleObject.products.padding.tabletTop)
      topPadding = styleObject.products.padding.tabletTop;
    else if (styleObject.products.padding.top)
      topPadding = styleObject.products.padding.top;
  }

  //Right padding
  let rightPadding = 0;
  if (styleObject.products.padding) {
    if (isMobile && styleObject.products.padding.mobileRight)
      rightPadding = styleObject.products.padding.mobileRight;
    else if (isTablet && styleObject.products.padding.tabletRight)
      rightPadding = styleObject.products.padding.tabletRight;
    else if (styleObject.products.padding.right)
      rightPadding = styleObject.products.padding.right;
  }

  //Left padding
  let leftPadding = 0;
  if (styleObject.products.padding) {
    if (isMobile && styleObject.products.padding.mobileLeft)
      leftPadding = styleObject.products.padding.mobileLeft;
    else if (isTablet && styleObject.products.padding.tabletLeft)
      leftPadding = styleObject.products.padding.tabletLeft;
    else if (styleObject.products.padding.left)
      leftPadding = styleObject.products.padding.left;
  }

  //Bottom padding
  let bottomPadding = 0;
  if (styleObject.products.padding) {
    if (isMobile && styleObject.products.padding.mobileBottom)
      bottomPadding = styleObject.products.padding.mobileBottom;
    else if (isTablet && styleObject.products.padding.tabletBottom)
      bottomPadding = styleObject.products.padding.tabletBottom;
    else if (styleObject.products.padding.bottom)
      bottomPadding = styleObject.products.padding.bottom;
  }

  //Pic size
  let picSize = "14";
  if (isMobile && styleObject.products.mobilePicSize)
    picSize = styleObject.products.mobilePicSize;
  else if (isTablet && styleObject.products.tabletPicSize)
    picSize = styleObject.products.tabletPicSize;
  else if (styleObject.products.picSize) picSize = styleObject.products.picSize;

  //Pic size
  let textContainerSize = "14";
  if (isMobile && styleObject.products.mobileTextContainerSize)
    textContainerSize = styleObject.products.mobileTextContainerSize;
  else if (isTablet && styleObject.products.tabletTextContainerSize)
    textContainerSize = styleObject.products.tabletTextContainerSize;
  else if (styleObject.products.textContainerSize)
    textContainerSize = styleObject.products.textContainerSize;

  const productsStyle = {
    color: styleObject.products.textColor,
    background: styleObject.products.backgroundColor,
    padding: styleObject.products.padding
      ? `${topPadding}px ${rightPadding}px ${bottomPadding}px ${leftPadding}px `
      : "0px 0px 0px 0px",
    titleFontSize: `${parseInt(titleFontSize || "14") + (isPreview ? accessibility.fontSize : 0)
      }px`,
    descriptionFontSize: `${parseInt(descriptionFontSize || "14") +
      (isPreview ? accessibility.fontSize : 0)
      }px`,
    priceFontSize: `${parseInt(priceFontSize || "14") + (isPreview ? accessibility.fontSize : 0)
      }px`,
    fontStyle: styleObject.products.fontStyle,
    fontWeight: styleObject.products.fontWeight,
    margin: 10,
    display: "inline-flex",
    flexDirection: "column",
    fontFamily: styleObject.products.fontFamily.replace(/\+/g, " "),
    textAlign: styleObject.products.textAlign,
    picSize,
    alignItems: "center",
    textContainerSize,
    wordBreak: "break-word",
    // width: `${styleObject.products.picSize}px`,
    // border: `${styleObject.products.buttonsBorderSize}px solid ${styleObject.products.buttonsBorderColor}`,
  };

  if (styleObject.products.alignItems == "left")
    productsStyle.alignItems = "start";
  if (styleObject.products.alignItems == "right")
    productsStyle.alignItems = "end";

  const ctaButtonStyle = {
    color: styleObject.products.buttonsTextColor,
    background: styleObject.products.buttonsBackgroundColor,
    border: `${styleObject.products.buttonsBorderColor} solid ${styleObject.products.buttonsBorderSize}px`,
    borderRadius: `${styleObject.products.buttonsBorderRadius}px`,
    fontSize: `${parseInt(linkButtonFontSize || "14") +
      (isPreview ? accessibility.fontSize : 0)
      }px`,
    fontStyle: styleObject.products.fontStyle,
    fontWeight: styleObject.products.fontWeight,
  };

  if (resultType !== "ecom") return <></>;
  return (
    <div
      className={
        props.isEdit && props.rightBarView.context === "products"
          ? "active-element"
          : ""
      }
      onClick={(e) => props.handleEditView(e, "products", "design")}
    >
      {mainProduct && (
        <div id={"mainProduct"} className={`answersContainer answers`}>
          <ProductItem

            product={mainProduct}
            productsStyle={{
              ...productsStyle,
              textContainerSize: parseInt(textContainerSize) * 1.5,
            }}
            ctaButtonStyle={ctaButtonStyle}
            defaultProductLink={defaultProductLink}
            linkButtonText={linkButtonText}
            showProductPrice={showProductPrice}
            showProductDescription={showProductDescription}
            showProductCTA={showProductCTA}
            showProductCategory={showProductCategory}
            handleEditView={props.handleEditView}
          />
        </div>)}
      {productsToDraw.length ? (
        <>
          <hr />
          <div
            className={`answersContainer answers`}
            style={{ textAlign: productsStyle.textAlign }}
          >
            {productsToDraw.map((product) => (
              <ProductItem
                product={product}
                productsStyle={productsStyle}
                ctaButtonStyle={ctaButtonStyle}
                defaultProductLink={defaultProductLink}
                linkButtonText={linkButtonText}
                showProductPrice={showProductPrice}
                showProductDescription={showProductDescription}
                showProductCTA={showProductCTA}
                showProductCategory={showProductCategory}
                handleEditView={props.handleEditView}
              />
            ))}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  let {
    accessibility = {},
    isMobile,
    isTablet,
    isMobilePreview,
    rightBarView,
  } = state;
  return { accessibility, isMobile, isTablet, isMobilePreview, rightBarView };
};

export default connect(mapStateToProps, null)(ViewResult);
