import React from "react";
import ChooseImage from "../../ChooseImage";
import ColorPicker from "../../ColorPicker";
import { IconPicker } from "../../../../utils/icons-select";
import PageLayout from "./Customize/pageLayout";
import TitleStyle from "./Customize/titleStyle";
import ButtonAnswerStyle from "./Customize/Answers/buttonAnswer";
import RatingAnswerStyle from "./Customize/Answers/ratingAnswer";
import { connect } from "react-redux";
import SubtitleStyle from "./Customize/subtitleStyle";
import ButtonStyleCustom from "./Customize/buttonStyle";
import ButtonExtraData from "./Customize/Answers/buttonExtraData";
import MatrixExtraData from "./Customize/Answers/matrixExtraData";
import HeaderStyle from "./Customize/headerStyle";
import FooterStyleCustomize from "./Customize/footerStyle";
import AltImagesTextArea from "../../AltImagesTextArea";
import MatrixStyle from "./Customize/Answers/matrixStyle";
import SliderExtraData from "./Customize/Answers/sliderExtraData";
import ProductsEdit from "../Result/Products";
class AddQuestion extends React.Component {
  state = {
    editorTitleState: "",
    colorToShow: "",
    tabToShow: "customize",
  };

  toggleColorPicker = (colorToShow) => {
    this.setState({ colorToShow });
  };

  handleUpAndDownButtons = (index) => {
    const { options, id } = this.props.data.onEdit;

    switch (index) {
      case 0:
        return (
          <div className="answer-icons">
            <a
              role="button"
              title=""
              className="arrow-down"
              onClick={() =>
                this.props.changeInputIndexes(index, index + 1, id)
              }
            ></a>
            <a
              role="button"
              title=""
              onClick={() => this.props.deleteOptionFromQuestion(index, id)}
            >
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 14 18"
              >
                <defs></defs>
                <path
                  d="M18.5,12.09h3v.5h1.09V12a1,1,0,0,0-1-1H18.42a1,1,0,0,0-1,1v.57H18.5Z"
                  transform="translate(-13 -11)"
                />
                <path
                  d="M25.1,16.9H14.9a.48.48,0,0,0-.48.51L15.28,28a1.12,1.12,0,0,0,1.12,1h7.2a1.12,1.12,0,0,0,1.12-1l.86-10.55A.48.48,0,0,0,25.1,16.9Zm-7.73,11h0a.56.56,0,0,1-.55-.51l-.53-8.66a.53.53,0,0,1,.51-.57.52.52,0,0,1,.57.5l.54,8.66A.55.55,0,0,1,17.37,27.88Zm3.18-.55a.55.55,0,0,1-.54.55.55.55,0,0,1-.55-.55V18.68a.55.55,0,0,1,.55-.55.55.55,0,0,1,.54.55Zm2.68,0a.55.55,0,0,1-.54.51h0a.55.55,0,0,1-.51-.58l.51-8.65a.54.54,0,1,1,1.08.06Z"
                  transform="translate(-13 -11)"
                />
                <path
                  d="M27,15.22l-.36-1.07a.69.69,0,0,0-.65-.47H14a.69.69,0,0,0-.65.47L13,15.22a.44.44,0,0,0,.19.52.4.4,0,0,0,.24.07h13.1a.4.4,0,0,0,.24-.07A.44.44,0,0,0,27,15.22Z"
                  transform="translate(-13 -11)"
                />
              </svg>
            </a>
          </div>
        );
      case options.length - 1:
        return (
          <div className="answer-icons">
            <a
              role="button"
              onClick={() =>
                this.props.changeInputIndexes(index, index - 1, id)
              }
              title=""
              className="arrow-up"
            ></a>
            <a
              role="button"
              title=""
              onClick={() => this.props.deleteOptionFromQuestion(index, id)}
            >
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 14 18"
              >
                <defs></defs>
                <path
                  d="M18.5,12.09h3v.5h1.09V12a1,1,0,0,0-1-1H18.42a1,1,0,0,0-1,1v.57H18.5Z"
                  transform="translate(-13 -11)"
                />
                <path
                  d="M25.1,16.9H14.9a.48.48,0,0,0-.48.51L15.28,28a1.12,1.12,0,0,0,1.12,1h7.2a1.12,1.12,0,0,0,1.12-1l.86-10.55A.48.48,0,0,0,25.1,16.9Zm-7.73,11h0a.56.56,0,0,1-.55-.51l-.53-8.66a.53.53,0,0,1,.51-.57.52.52,0,0,1,.57.5l.54,8.66A.55.55,0,0,1,17.37,27.88Zm3.18-.55a.55.55,0,0,1-.54.55.55.55,0,0,1-.55-.55V18.68a.55.55,0,0,1,.55-.55.55.55,0,0,1,.54.55Zm2.68,0a.55.55,0,0,1-.54.51h0a.55.55,0,0,1-.51-.58l.51-8.65a.54.54,0,1,1,1.08.06Z"
                  transform="translate(-13 -11)"
                />
                <path
                  d="M27,15.22l-.36-1.07a.69.69,0,0,0-.65-.47H14a.69.69,0,0,0-.65.47L13,15.22a.44.44,0,0,0,.19.52.4.4,0,0,0,.24.07h13.1a.4.4,0,0,0,.24-.07A.44.44,0,0,0,27,15.22Z"
                  transform="translate(-13 -11)"
                />
              </svg>
            </a>
          </div>
        );
      default:
        return (
          <div className="answer-icons">
            <a
              role="button"
              onClick={() =>
                this.props.changeInputIndexes(index, index - 1, id)
              }
              title=""
              className="arrow-up"
            ></a>
            <a
              role="button"
              title=""
              className="arrow-down"
              onClick={() =>
                this.props.changeInputIndexes(index, index + 1, id)
              }
            ></a>
            <a
              role="button"
              title=""
              onClick={() => this.props.deleteOptionFromQuestion(index, id)}
            >
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 14 18"
              >
                <defs></defs>
                <path
                  d="M18.5,12.09h3v.5h1.09V12a1,1,0,0,0-1-1H18.42a1,1,0,0,0-1,1v.57H18.5Z"
                  transform="translate(-13 -11)"
                />
                <path
                  d="M25.1,16.9H14.9a.48.48,0,0,0-.48.51L15.28,28a1.12,1.12,0,0,0,1.12,1h7.2a1.12,1.12,0,0,0,1.12-1l.86-10.55A.48.48,0,0,0,25.1,16.9Zm-7.73,11h0a.56.56,0,0,1-.55-.51l-.53-8.66a.53.53,0,0,1,.51-.57.52.52,0,0,1,.57.5l.54,8.66A.55.55,0,0,1,17.37,27.88Zm3.18-.55a.55.55,0,0,1-.54.55.55.55,0,0,1-.55-.55V18.68a.55.55,0,0,1,.55-.55.55.55,0,0,1,.54.55Zm2.68,0a.55.55,0,0,1-.54.51h0a.55.55,0,0,1-.51-.58l.51-8.65a.54.54,0,1,1,1.08.06Z"
                  transform="translate(-13 -11)"
                />
                <path
                  d="M27,15.22l-.36-1.07a.69.69,0,0,0-.65-.47H14a.69.69,0,0,0-.65.47L13,15.22a.44.44,0,0,0,.19.52.4.4,0,0,0,.24.07h13.1a.4.4,0,0,0,.24-.07A.44.44,0,0,0,27,15.22Z"
                  transform="translate(-13 -11)"
                />
              </svg>
            </a>
          </div>
        );
    }
  };

  toggleSelectImage = (index, optionType) => {
    if (optionType === "image") {
      return (
        <button
          data-toggle="modal"
          data-target={`#modal${index}`}
          className="btn btn-dark"
        >
          Upload
        </button>
      );
    }
  };

  drawOptionFormByQuestionType = () => {
    const {
      questionType,
      id,
      buttonText,
      options,
      ratingIcon,
      ratingIconColor,
      ratingIconCount,
      ratingIconSize,
      matrix,
    } = this.props.data.onEdit;
    switch (questionType) {
      case "singleSelect":
      case "multiSelect": {
        return (
          <>
            <div className="innerBox">
              <div className="form-group ">
                {options.map((option, index) => {
                  const {
                    title,
                    id: optionId,
                    score,
                    redirectTo,
                    optionType,
                    src,
                  } = option;
                  return (
                    <div key={index}>
                      <div>{this.handleUpAndDownButtons(index)}</div>

                      <div>
                        <ChooseImage
                          index={index}
                          id={id}
                          changeOptionImage={this.changeOptionImage}
                        />

                        <input
                          type="text"
                          name="title"
                          onChange={(e) =>
                            this.props.changeQuestionsOptionsDetails(
                              e,
                              index,
                              id
                            )
                          }
                          value={title}
                          className="form-control"
                        />
                        <div>
                          <select
                            name="optionType"
                            onChange={(e) =>
                              this.props.changeQuestionsOptionsDetails(
                                e,
                                index,
                                id
                              )
                            }
                            value={optionType}
                          >
                            <option value="text">Text</option>
                            <option value="image">Image</option>
                            <option value="input">Input</option>
                          </select>
                        </div>
                        <div className="row">
                          {optionType == "image" && (
                            <div className="col-md-5">
                              <label>Image\Icon:</label>
                              <div className="image-box">
                                <div
                                  className="pic-upload"
                                  data-toggle="modal"
                                  data-target={`#modal${index}`}
                                ></div>
                              </div>
                            </div>
                          )}
                          {optionType == "input" && (
                            <div className="col-md-5">
                              <label>Placeholder:</label>
                              <input
                                type="text"
                                name="placeholder"
                                onChange={(e) =>
                                  this.props.changeQuestionsOptionsDetails(
                                    e,
                                    index,
                                    id
                                  )
                                }
                              />
                            </div>
                          )}
                          <div className="col-md-4">
                            {this.drawOptionByQuizType(option, index)}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <a
              onClick={() => this.props.addOptionToQuestion(id)}
              title=""
              className="add-link"
            >
              Add an option
            </a>
            {questionType === "multiSelect" && (
              <div className="innerBox cardBox">
                <div className="card">
                  <div className="card-header" id="mediaBox">
                    <h3 className="mb-0">
                      <a
                        className="btn collapsed"
                        href="#button"
                        type="button"
                        data-toggle="collapse"
                        data-target="#button"
                        aria-expanded="false"
                        aria-controls="button"
                      >
                        Next Button
                      </a>
                    </h3>
                  </div>

                  <div
                    id="button"
                    className="collapse"
                    aria-labelledby="mediaBox"
                  >
                    <div className="card-body">
                      <a role="button" title="" className="edit">
                        <svg
                          id="Capa_1"
                          enableBackground="new 0 0 512.279 512.279"
                          height="512"
                          viewBox="0 0 512.279 512.279"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g>
                            <path d="m230.23 482.566c81.432 0 160.296-43.89 160.296-121.32 0-33.771-20.231-52.689-36.487-67.89-31.828-29.761-31.828-44.459 0-74.22 16.256-15.201 36.487-34.119 36.487-67.89 0-57.859-44.772-97.744-101.344-113.512-89.916-25.064-200.348 8.857-256.154 101.184-43.828 71.666-44.234 162.327-.04 234.587 43.024 71.181 119.202 109.061 197.242 109.061zm-171.568-328.062c48.345-79.982 145.44-109.341 222.465-87.872 49.717 13.858 79.398 45.489 79.398 84.614 0 20.751-12.502 32.442-26.978 45.978-43.525 40.7-45.656 75.352 0 118.044 14.476 13.536 26.978 25.227 26.978 45.978 0 60.864-67.557 91.236-130.038 91.234-62.823-.002-132.162-28.873-171.865-94.559-38.356-62.717-38.356-140.635.04-203.417z" />
                            <path d="m512.213 374.72c.002-12.015-4.673-23.313-13.167-31.814-.349-.349-.71-.682-1.068-1.018 3.436-19.579 14.278-86.015 14.302-144.583.018-45.254-6.213-109.051-10.145-138.633-3.653-27.497-34.036-28.92-34.137-28.959-18.093 0-33.494 13.543-35.824 31.503-4.785 36.884-9.879 97.102-9.895 136.052-.023 58.593 10.783 125.067 14.2 144.619-8.766 8.213-14.262 19.877-14.267 32.807-.013 32.601 28.327 90.256 31.559 96.723 2.543 5.09 7.912 8.518 14.05 8.263 8.117-.339 14.368-7.043 14.368-14.962.011-24.98 30.016-55.512 30.023-89.98 0-.003 0-.005 0-.007 0-.001 0-.005 0-.006.001-.001.001-.003.001-.005zm-50.289-309.644c.396-3.057 3.008-5.363 6.091-5.363.185.055 3.92-.554 4.382 2.911 4.113 30.952 9.899 93.196 9.883 134.669-.006 13.833-.668 28.263-1.708 42.813l-26.616-.001c-1.029-14.551-1.681-28.991-1.675-42.824.014-37.696 4.979-96.25 9.643-132.205zm-5.244 205.021 21.143.009c-2.729 24.79-6.095 46.765-8.23 59.672-1.587-.082-3.08-.088-4.725-.003-2.127-12.908-5.477-34.886-8.188-59.678zm7.917 151.391c-6.823-17.711-12.388-35.884-12.384-46.78.004-8.269 6.732-14.994 15.001-14.994h.006c8.29.004 14.997 6.714 14.993 15.006v.003s0 .002 0 .003c0 13.841-9.584 30.156-17.616 46.762z" />
                            <path d="m104.077 299.714c24.813 0 45-20.187 45-45s-20.187-45-45-45-45 20.187-45 45 20.187 45 45 45zm0-60c8.271 0 15 6.729 15 15s-6.729 15-15 15-15-6.729-15-15 6.729-15 15-15z" />
                            <path d="m186.577 319.666c-21.538-12.434-49.034-5.071-61.471 16.471-12.406 21.489-5.018 49.064 16.471 61.471 21.453 12.385 48.99 5.147 61.471-16.471 12.406-21.489 5.017-49.065-16.471-61.471zm-9.51 46.471c-4.136 7.163-13.325 9.626-20.49 5.49-7.163-4.135-9.626-13.328-5.49-20.49 4.1-7.099 13.231-9.681 20.49-5.491 7.163 4.136 9.626 13.328 5.49 20.491z" />
                            <path d="m261.577 189.762c21.551 12.442 49.041 5.058 61.471-16.471 12.406-21.489 5.018-49.065-16.471-61.471-21.537-12.435-49.034-5.07-61.471 16.471-12.406 21.489-5.017 49.064 16.471 61.471zm9.51-46.471c4.146-7.178 13.307-9.637 20.49-5.49 7.163 4.135 9.626 13.327 5.49 20.49-4.146 7.179-13.308 9.636-20.49 5.49-7.163-4.135-9.626-13.327-5.49-20.49z" />
                            <path d="m306.577 397.608c21.488-12.407 28.877-39.982 16.471-61.471-12.435-21.538-39.93-28.908-61.471-16.471-21.488 12.406-28.877 39.983-16.471 61.471 12.406 21.487 39.872 28.942 61.471 16.471zm-30-51.962c7.18-4.145 16.343-1.69 20.49 5.491 4.136 7.163 1.673 16.355-5.49 20.49-7.165 4.136-16.354 1.672-20.49-5.49-4.136-7.163-1.673-16.355 5.49-20.491z" />
                            <path d="m186.577 189.762c21.488-12.406 28.877-39.982 16.471-61.471-12.434-21.537-39.928-28.908-61.471-16.471-21.488 12.406-28.877 39.982-16.471 61.471 12.431 21.531 39.924 28.911 61.471 16.471zm-30-51.962c7.193-4.153 16.349-1.681 20.49 5.49 4.136 7.163 1.673 16.355-5.49 20.49-7.18 4.145-16.343 1.69-20.49-5.49-4.136-7.162-1.673-16.354 5.49-20.49z" />
                          </g>
                        </svg>
                      </a>
                    </div>
                    <div>
                      <label>Text</label>
                      <input
                        type="text"
                        className="form-control"
                        value={buttonText}
                        name="buttonText"
                        onChange={(e) => this.props.handleChangeQuestion(e, id)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        );
      }
      case "rating":
        return (
          <div className="innerBox">
            <div className="form-group ">
              <label>Icon:</label>
              <IconPicker
                value={ratingIcon}
                buttonIconStyles={{ color: ratingIconColor }}
                pickerIconStyles={{ color: ratingIconColor }}
                onChange={(value) =>
                  this.props.handleChangeQuestion(
                    { target: { name: "ratingIcon", value } },
                    id
                  )
                }
              />
            </div>
            <div>
              <label>Color:</label>
              <ColorPicker
                color={ratingIconColor}
                setRight="inherit"
                handleChange={(e) => {
                  const { r, g, b, a } = e.rgb;
                  const value = `rgba(${r}, ${g}, ${b}, ${a})`;
                  this.props.handleChangeQuestion(
                    { target: { value, name: "ratingIconColor" } },
                    id
                  );
                }}
              />
            </div>
            <div>
              <label>Count:</label>
              <input
                type="range"
                name="ratingIconCount"
                min={2}
                max={10}
                value={ratingIconCount}
                onChange={(e) => {
                  let { name, value } = e.target;
                  value = parseInt(value);
                  this.props.handleChangeQuestion(
                    { target: { value, name } },
                    id
                  );
                }}
              />
            </div>
            <div>
              <label>Size:</label>
              <input
                type="range"
                name="ratingIconSize"
                min={20}
                max={50}
                value={ratingIconSize}
                onChange={(e) => {
                  let { name, value } = e.target;
                  value = parseInt(value);
                  this.props.handleChangeQuestion(
                    { target: { value, name } },
                    id
                  );
                }}
              />
            </div>
          </div>
        );
      case "matrix":
        const { allowMultipleSelection } = matrix;
        return (
          <>
            <div className="innerBox">
              <div className="form-group ">
                <label>Allow multiple selection:</label>
                <input
                  type="checkbox"
                  name="allowMultipleSelection"
                  value={allowMultipleSelection}
                  onChange={(e) => this.props.handleMatrixConfig(e, id)}
                />
              </div>
            </div>
            <div className="innerBox cardBox">
              <div className="card">
                <div className="card-header" id="mediaBox">
                  <h3 className="mb-0">
                    <a
                      className="btn collapsed"
                      href="#button"
                      type="button"
                      data-toggle="collapse"
                      data-target="#button"
                      aria-expanded="false"
                      aria-controls="button"
                    >
                      Next Button
                    </a>
                  </h3>
                </div>

                <div
                  id="button"
                  className="collapse"
                  aria-labelledby="mediaBox"
                >
                  <div className="card-body">
                    <a role="button" title="" className="edit">
                      <svg
                        id="Capa_1"
                        enableBackground="new 0 0 512.279 512.279"
                        height="512"
                        viewBox="0 0 512.279 512.279"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g>
                          <path d="m230.23 482.566c81.432 0 160.296-43.89 160.296-121.32 0-33.771-20.231-52.689-36.487-67.89-31.828-29.761-31.828-44.459 0-74.22 16.256-15.201 36.487-34.119 36.487-67.89 0-57.859-44.772-97.744-101.344-113.512-89.916-25.064-200.348 8.857-256.154 101.184-43.828 71.666-44.234 162.327-.04 234.587 43.024 71.181 119.202 109.061 197.242 109.061zm-171.568-328.062c48.345-79.982 145.44-109.341 222.465-87.872 49.717 13.858 79.398 45.489 79.398 84.614 0 20.751-12.502 32.442-26.978 45.978-43.525 40.7-45.656 75.352 0 118.044 14.476 13.536 26.978 25.227 26.978 45.978 0 60.864-67.557 91.236-130.038 91.234-62.823-.002-132.162-28.873-171.865-94.559-38.356-62.717-38.356-140.635.04-203.417z" />
                          <path d="m512.213 374.72c.002-12.015-4.673-23.313-13.167-31.814-.349-.349-.71-.682-1.068-1.018 3.436-19.579 14.278-86.015 14.302-144.583.018-45.254-6.213-109.051-10.145-138.633-3.653-27.497-34.036-28.92-34.137-28.959-18.093 0-33.494 13.543-35.824 31.503-4.785 36.884-9.879 97.102-9.895 136.052-.023 58.593 10.783 125.067 14.2 144.619-8.766 8.213-14.262 19.877-14.267 32.807-.013 32.601 28.327 90.256 31.559 96.723 2.543 5.09 7.912 8.518 14.05 8.263 8.117-.339 14.368-7.043 14.368-14.962.011-24.98 30.016-55.512 30.023-89.98 0-.003 0-.005 0-.007 0-.001 0-.005 0-.006.001-.001.001-.003.001-.005zm-50.289-309.644c.396-3.057 3.008-5.363 6.091-5.363.185.055 3.92-.554 4.382 2.911 4.113 30.952 9.899 93.196 9.883 134.669-.006 13.833-.668 28.263-1.708 42.813l-26.616-.001c-1.029-14.551-1.681-28.991-1.675-42.824.014-37.696 4.979-96.25 9.643-132.205zm-5.244 205.021 21.143.009c-2.729 24.79-6.095 46.765-8.23 59.672-1.587-.082-3.08-.088-4.725-.003-2.127-12.908-5.477-34.886-8.188-59.678zm7.917 151.391c-6.823-17.711-12.388-35.884-12.384-46.78.004-8.269 6.732-14.994 15.001-14.994h.006c8.29.004 14.997 6.714 14.993 15.006v.003s0 .002 0 .003c0 13.841-9.584 30.156-17.616 46.762z" />
                          <path d="m104.077 299.714c24.813 0 45-20.187 45-45s-20.187-45-45-45-45 20.187-45 45 20.187 45 45 45zm0-60c8.271 0 15 6.729 15 15s-6.729 15-15 15-15-6.729-15-15 6.729-15 15-15z" />
                          <path d="m186.577 319.666c-21.538-12.434-49.034-5.071-61.471 16.471-12.406 21.489-5.018 49.064 16.471 61.471 21.453 12.385 48.99 5.147 61.471-16.471 12.406-21.489 5.017-49.065-16.471-61.471zm-9.51 46.471c-4.136 7.163-13.325 9.626-20.49 5.49-7.163-4.135-9.626-13.328-5.49-20.49 4.1-7.099 13.231-9.681 20.49-5.491 7.163 4.136 9.626 13.328 5.49 20.491z" />
                          <path d="m261.577 189.762c21.551 12.442 49.041 5.058 61.471-16.471 12.406-21.489 5.018-49.065-16.471-61.471-21.537-12.435-49.034-5.07-61.471 16.471-12.406 21.489-5.017 49.064 16.471 61.471zm9.51-46.471c4.146-7.178 13.307-9.637 20.49-5.49 7.163 4.135 9.626 13.327 5.49 20.49-4.146 7.179-13.308 9.636-20.49 5.49-7.163-4.135-9.626-13.327-5.49-20.49z" />
                          <path d="m306.577 397.608c21.488-12.407 28.877-39.982 16.471-61.471-12.435-21.538-39.93-28.908-61.471-16.471-21.488 12.406-28.877 39.983-16.471 61.471 12.406 21.487 39.872 28.942 61.471 16.471zm-30-51.962c7.18-4.145 16.343-1.69 20.49 5.491 4.136 7.163 1.673 16.355-5.49 20.49-7.165 4.136-16.354 1.672-20.49-5.49-4.136-7.163-1.673-16.355 5.49-20.491z" />
                          <path d="m186.577 189.762c21.488-12.406 28.877-39.982 16.471-61.471-12.434-21.537-39.928-28.908-61.471-16.471-21.488 12.406-28.877 39.982-16.471 61.471 12.431 21.531 39.924 28.911 61.471 16.471zm-30-51.962c7.193-4.153 16.349-1.681 20.49 5.49 4.136 7.163 1.673 16.355-5.49 20.49-7.18 4.145-16.343 1.69-20.49-5.49-4.136-7.162-1.673-16.354 5.49-20.49z" />
                        </g>
                      </svg>
                    </a>
                  </div>
                  <div>
                    <label>Text</label>
                    <input
                      type="text"
                      className="form-control"
                      value={buttonText}
                      name="buttonText"
                      onChange={(e) => this.props.handleChangeQuestion(e, id)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      default:
        break;
    }
  };

  changeOptionImage = (id, index, image) => {
    this.props.changeQuestionsOptionsDetails(
      { target: { name: "src", value: image } },
      index,
      id
    );
  };

  onEditorStateChange = (e, id) => {
    if (e.blocks[0].text == this.props.data.onEdit.title) return;
    // let editText = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    let titleObject = {
      target: {
        name: "title",
        type: "text",
        value: e.blocks[0].text,
      },
    };
    this.props.handleChangeQuestion(titleObject, id);
  };

  handleColorsToChange = (e, questionId, name, toChange) => {
    const { r, g, b, a } = e.rgb;
    const value = `rgba(${r}, ${g}, ${b}, ${a})`;
    this.props.handleQuestionStyle(
      { target: { name, value } },
      questionId,
      toChange
    );
  };

  drawOptionByQuizType = (option, index) => {
    const { type } = this.props.data;
    const { id, questionType } = this.props.data.onEdit;
    const { correct, score } = option;
    switch (type) {
      case "scored":
        return (
          <div className="form-group ">
            <label>Score:</label>
            <input
              type="number"
              name="score"
              className="form-control"
              onChange={(e) =>
                this.props.changeQuestionsOptionsDetails(
                  {
                    target: { name: "score", value: parseInt(e.target.value) },
                  },
                  index,
                  id
                )
              }
              value={score}
            />
          </div>
        );
      case "personality":
        return <></>;
      case "assessment":
        return (
          <div>
            <label>Correct</label>
            <input
              type="checkbox"
              onChange={(e) =>
                this.props.changeQuestionsOptionsDetails(
                  {
                    target: {
                      name: "correct",
                      value: e.target.checked,
                      type: e.target.type,
                    },
                  },
                  index,
                  id
                )
              }
              name="correct"
              checked={correct}
              className="form-control"
            />
          </div>
        );
    }
  };

  handleMediaType = (selectedMedia) => {
    const { id } = this.props.data.onEdit;
    this.props.handleChangeQuestion(
      { target: { name: "mediaType", value: selectedMedia } },
      id
    );
  };

  handleChangeMediaImage = (image) => {
    const { id } = this.props.data.onEdit;
    this.props.handleChangeQuestion(
      { target: { name: "mediaImageSrc", value: image } },
      id
    );
  };

  render() {
    const { context, selectedTheme, changeTheme, subContextId } = this.props;
    const { settings, fonts } = this.props.data;
    const { tabToShow } = this.state;

    let {
      id,
      mediaType,
      options,
      feedback,
      questionType,
      verticalAnswers,
      style,
      withNextButton,
      ratingIcon,
      ratingIconColor,
      ratingIconEmptyColor = "grey",
      ratingIconCount,
      ratingIconSize,
      answerWithImages,
      useTitleCustomStyle,
      useSubTitleCustomStyle,
      useButtonCustomStyle,
      useAnswersCustomStyle,
      useHeaderCustomStyle,
      useFooterCustomStyle,
      isRandom,
      isMultiSelectRequired,
      errorMsg,
      isOtherOption,
      callToActionTarget,
      withOtherOption,
      callToActionLink,
      customOtherTextRequired,
      otherOptionFreeTextLabel,
      otherOptionFreeTextErrorMessage,
    } = this.props.data.onEdit;
    options = options ? options : [];
    const { correctMessage, incorrectMessage } = this.props.data.feedback;
    const isVideo = mediaType === "video" ? "red" : "";
    const isImage = mediaType === "image" ? "red" : "";
    return (
      <div
        id="customize"
        className="tabContent tabActive"
        // style={{ display: this.props.toolbar ? "flex" : "block" }}
        // style={{ display: "block", height:"100%",overflow:"auto" }}
      >
        {/*when the user click on elemnt that can be edit on the top toolbar - we want to continue to show the PageLayout on the right bar - this should be change to flag or more elegant/easy to read code */}
        {(context == "pageLayout" && !this.props.toolbar) ||
        ((context == "titleStyle" || context == "subtitleStyle") &&
          !this.props.toolbar) ||
        (context == "buttonStyle" && !this.props.toolbar) ||
        (context == "ratingAnswerStyle" && !this.props.toolbar) ||
        (context == "buttonAnswerStyle" && !this.props.toolbar) ||
        (context == "buttonExtraData" && !this.props.toolbar) ||
        (context == "buttonStyle" && !this.props.toolbar) ||
        (context == "ratingAnswerStyle" && !this.props.toolbar) ||
        (context == "headerStyle" && !this.props.toolbar) ||
        (context == "buttonStyle" && !this.props.toolbar) ||
        (context == "matrixStyle" && !this.props.toolbar) ||
        (context == "ratingAnswerStyle" && !this.props.toolbar) ||
        (context == "matrixExtraData" && !this.props.toolbar) ||
        (context == "sliderExtraData" && !this.props.toolbar) ||
        (context == "products" && !this.props.toolbar) ||
        (context == "footerStyle" && !this.props.toolbar) ? (
          <PageLayout
            data={this.props.data}
            questionType={questionType}
            id={id}
            handleChangeQuestion={this.props.handleChangeQuestion}
          />
        ) : (
          <></>
        )}
        {context == "pageLayout" && this.props.toolbar ? (
          <div>Click on any element in the editor screen to edit Design</div>
        ) : (
          <></>
        )}
        {context == "titleStyle" && this.props.toolbar ? (
          <TitleStyle
            style={style}
            settings={settings}
            handleQuestionStyle={this.props.handleQuestionStyle}
            handleChangeQuestion={this.props.handleChangeQuestion}
            fonts={fonts}
            useTitleCustomStyle={useTitleCustomStyle}
            id={id}
          />
        ) : (
          <></>
        )}

        {context == "subtitleStyle" && this.props.toolbar ? (
          <SubtitleStyle
            style={style}
            settings={settings}
            handleQuestionStyle={this.props.handleQuestionStyle}
            handleChangeQuestion={this.props.handleChangeQuestion}
            useSubTitleCustomStyle={useSubTitleCustomStyle}
            fonts={fonts}
            id={id}
          />
        ) : (
          <></>
        )}
        {context == "buttonStyle" && this.props.toolbar ? (
          <ButtonStyleCustom
            style={style}
            settings={settings}
            handleQuestionStyle={this.props.handleQuestionStyle}
            handleChangeQuestion={this.props.handleChangeQuestion}
            useButtonCustomStyle={useButtonCustomStyle}
            fonts={fonts}
            id={id}
            callToActionTarget={callToActionTarget}
            callToActionLink={callToActionLink}
          />
        ) : (
          <></>
        )}
        {context == "buttonAnswerStyle" ? (
          <ButtonAnswerStyle
            style={style}
            settings={settings}
            handleQuestionStyle={this.props.handleQuestionStyle}
            fonts={fonts}
            id={id}
            questionType={questionType}
            handleChangeQuestion={this.props.handleChangeQuestion}
            answerWithImages={answerWithImages}
            isRandom={isRandom}
            isOtherOption={isOtherOption}
            isMultiSelectRequired={isMultiSelectRequired}
            errorMsg={errorMsg}
            verticalAnswers={verticalAnswers}
            feedback={feedback}
            showFeedback={questionType == "Assessment"}
            correctMessage={correctMessage}
            incorrectMessage={incorrectMessage}
            optionId={subContextId}
            useAnswersCustomStyle={useAnswersCustomStyle}
            quizType={this.props.data.type}
            toolbar={this.props.toolbar}
            withOtherOption={withOtherOption}
            customOtherTextRequired={customOtherTextRequired}
            otherOptionFreeTextLabel={otherOptionFreeTextLabel}
            otherOptionFreeTextErrorMessage={otherOptionFreeTextErrorMessage}
          />
        ) : (
          <></>
        )}
        {context == "ratingAnswerStyle" && this.props.toolbar ? (
          <RatingAnswerStyle
            ratingIcon={ratingIcon}
            ratingIconColor={ratingIconColor}
            ratingIconEmptyColor={ratingIconEmptyColor}
            ratingIconCount={ratingIconCount}
            ratingIconSize={ratingIconSize}
            handleQuestionStyle={this.props.handleQuestionStyle}
            handleChangeQuestion={this.props.handleChangeQuestion}
            qId={id}
            withNextButton={withNextButton}
          />
        ) : (
          <></>
        )}
        {context == "matrixStyle" && this.props.toolbar ? (
          <MatrixStyle
            id={id}
            data={this.props.data}
            handleChangeMatrixData={this.props.handleChangeMatrixData}
            matrixData={this.props.data.onEdit.matrix}
            stepData={this.props.data.onEdit}
            handleChange={this.props.handleQuestionStyle}
            handleChangeQuestion={this.props.handleChangeQuestion}
          />
        ) : (
          <></>
        )}
        {context == "headerStyle" && this.props.toolbar ? (
          <HeaderStyle
            mainSettings={this.props.data.header}
            style={style}
            settings={settings}
            handleQuestionStyle={this.props.handleQuestionStyle}
            handleChangeQuestion={this.props.handleChangeQuestion}
            fonts={fonts}
            useHeaderCustomStyle={useHeaderCustomStyle}
            changeNavs={this.props.changeNavs}
            id={id}
          />
        ) : (
          <></>
        )}

        {context == "footerStyle" && this.props.toolbar ? (
          <FooterStyleCustomize
            mainSettings={this.props.data.footer}
            style={style}
            settings={settings}
            handleQuestionStyle={this.props.handleQuestionStyle}
            handleChangeQuestion={this.props.handleChangeQuestion}
            fonts={fonts}
            useFooterCustomStyle={useFooterCustomStyle}
            id={id}
          />
        ) : (
          <></>
        )}
        {context == "buttonExtraData" && subContextId ? (
          <ButtonExtraData
            id={id}
            optionId={subContextId}
            data={this.props.data}
            changeQuestionsOptionsDetails={
              this.props.changeQuestionsOptionsDetails
            }
            options={options}
          />
        ) : (
          <></>
        )}
        {context == "matrixExtraData" && this.props.toolbar ? (
          <MatrixExtraData
            id={id}
            data={this.props.data}
            handleChangeMatrixData={this.props.handleChangeMatrixData}
            matrixData={this.props.data.onEdit.matrix}
            stepData={this.props.data.onEdit}
            handleChange={this.props.handleQuestionStyle}
            handleChangeQuestion={this.props.handleChangeQuestion}
          />
        ) : (
          <></>
        )}
        {context == "sliderExtraData" && this.props.toolbar ? (
          <SliderExtraData
            id={id}
            data={this.props.data}
            stepData={this.props.data.onEdit}
            handleChangeSliderData={this.props.handleChangeSliderData}
          />
        ) : (
          <></>
        )}
        {context == "products" && this.props.toolbar ? (
          <ProductsEdit
            data={this.props.data}
            questionType={questionType}
            id={id}
            handleChangeQuestion={this.props.handleChangeQuestion}
          />
        ) : (
          <></>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { rightBarView } = state;
  const { context = "", subContextId = "" } = rightBarView;
  return { context, subContextId };
};

export default connect(mapStateToProps, null)(AddQuestion);
