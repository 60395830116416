import React from "react";
import Graph from "./Graph";
import { getQuizAnalyzeGraph } from "../../../redux/actions";
import { connect } from "react-redux";
import moment from "moment";
import { Container, Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import LineChart from "./LineChartStats";
class GraphList extends React.Component {
  componentDidMount() {
    const { published, id } = this.props.data;
    if (!published || published == 0) return;
    else {
      const fromDate = moment().format("YYYY-MM-DD");
      let toDate = moment().subtract(30, "days");
      toDate = moment(toDate).format("YYYY-MM-DD");

      this.props.actions.getQuizAnalyzeGraph({
        id,
        fromDate,
        toDate,
        offset: 0,
        filters: this.props.filtersObj,
        additionalFilters: this.props.additionalFilters,
      });
    }
  }

  render() {
    let sumOfQuestions = 0;
    let sumOfCorrect = 0;
    const { total_leads, total_start, total_finish, average, total_visits } =
      this.props.quizAnalyzeStats;
    this.props.quizAnalyzeGraph.map((itr) => {
      if (itr.result) sumOfCorrect += parseInt(itr.result);
      if (itr.number_of_questions)
        sumOfQuestions += parseInt(itr.number_of_questions);
    });
    let avgCorrect = (sumOfCorrect / sumOfQuestions) * 100;
    avgCorrect = isNaN(avgCorrect) ? 0 : avgCorrect;
    const { t, quizAnalyzeLineChart } = this.props;
    return (
      // <div className="margin-analyse">
      <div className="tab-content-inner" id="analyzeTabContent">
        <div className="">
          {/*<h3>*/}
          {/*  <strong>{t("analyze-tab-title")} </strong>*/}
          {/*</h3>*/}
          <div className="visit-details d-flex w-50 justify-content-between">
            <span className="d-flex flex-column align-items-center">
              <span> {t("analyze-total-visits")}</span>
              <h4 style={{ color: "#03A6CA" }}>{total_visits || 0}</h4>
            </span>
            <span className="d-flex flex-column align-items-center">
              <span> {t("analyze-total-starts")}</span>
              <h4 style={{ color: "#03A6CA" }}>{total_start || 0}</h4>
            </span>
            <span className="d-flex flex-column align-items-center">
              <span>{t("analyze-total-finish")}</span>
              <h4 style={{ color: "#03A6CA" }}>{total_finish || 0}</h4>
            </span>
            <span className="d-flex flex-column align-items-center">
              <span> {t("analyze-total-leads")}</span>
              <h4 style={{ color: "#03A6CA" }}>{total_leads || 0}</h4>
            </span>
            <span className="d-flex flex-column align-items-center">
              <span>Avg Score</span>
              <h4 style={{ color: "#03A6CA" }}>
                {average ? `${average}%` : 0}
              </h4>
            </span>
          </div>
        </div>
        <div
          className="tab-pane fade show active "
          id="graph"
          role="tabpanel"
          aria-labelledby="graph-tab"
          style={{ paddingTop: "50px", height: "500px" }}
        >
          <Container>
            <Row>
              <Col>
                <Graph
                  data={this.props.quizAnalyzeGraph}
                  toCalculate={"mobile/desktop"}
                  label="Mobile / Desktop"
                />
              </Col>
              <Col>
                <Graph
                  data={this.props.quizAnalyzeGraph}
                  toCalculate={"browser"}
                  label="Browser"
                />
              </Col>
            </Row>
          </Container>
          <br />
          <br />
          <LineChart
            quizAnalyzeLineChart={quizAnalyzeLineChart}
            nameOfXKey="date"
            nameOfYKey={["total_visits", "total_start", "total_finish"]}
            label="Visit Graph"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { quizAnalyzeGraph, quizAnalyzeStats, quizAnalyzeLineChart } = state;
  return { quizAnalyzeGraph, quizAnalyzeStats, quizAnalyzeLineChart };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      getQuizAnalyzeGraph: (analyzeSearchDetails) =>
        dispatch(getQuizAnalyzeGraph(analyzeSearchDetails)),
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(GraphList)
);
