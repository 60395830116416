import React, { useState, useEffect } from "react";
import { ReactLiquid, liquidEngine } from "react-liquid";
import { chnageQuillTollbarElement } from "../../redux/actions";
import EditorQuill from "../DraftJsRichEditor/quill";
import { connect } from "react-redux";

function ToggleInputAndText(props) {
  let {
    isRichText,
    value,
    handleChange,
    id,
    name,
    data,
    style = {},
    tag = "span",
    placeholder = "",
    className,
    isEdit,
    classNameForTag,
    isButton,
    onClick,
    padding,
    isResult,
    isPreviewDesignMode,

    // handleSpecialMessage,
  } = props;

  const [showInput, setShowInput] = useState(isEdit);
  const [newData, setNewData] = useState(null);

  // useEffect(() => {
  if (isResult && !newData) {
    const newDataTemp = Object.assign({}, data);
    newDataTemp.variables = [...data.variables];
    newDataTemp.variables.push({
      name: "number_of_qusetions",
      type: "result",
      value: data.numberOfQuestions,
    });
    if (data.type == "assessment") {
      newDataTemp.variables.push({
        name: "correct_answers",
        type: "result",
        value: data.numberOfCorrect,
      });
    } else if (data.type == "scored") {
      newDataTemp.variables.push({
        name: "score",
        type: "result",
        value: data.score,
      });
    }
    setNewData(newDataTemp);
  }
  // }, [isResult]);

  // fix duplication of this function, chnage to a higer scope

  const handleSpecialMessage = (data) => {
    let { variables = [], answers = [], contacts = {}, score = 0 } = data;
    variables = variables.map((variable) => {
      return { [variable.name]: variable.value };
    });
    answers = answers.map((answer) => {
      let { arrayOfResult = [], questionId } = answer;
      let value = arrayOfResult.map((itr) => itr.value).join(", ");

      return { [questionId.replaceAll(".", "__")]: value };
    });

    contacts = Object.entries(contacts)
      .filter(([k, v]) => k != "other")
      .map(([k, v]) => {
        const inputKey = v.label;
        return { [inputKey.toLowerCase().replaceAll(" ", "")]: v.value };
      });
    variables = Object.assign({}, ...variables, ...answers, ...contacts, {
      score,
    });
    return variables;
  };
  const dataWithVariables = handleSpecialMessage(newData || data);
  if (!showInput) {
    // if (isButton) {
    //   return (
    //     <div style={style} className="reactLiquidEditor" onClick={onClick}>
    //       <ReactLiquid template={value} data={dataWithVariables} html />
    //     </div>
    //   );
    // }

    //todo: this is hot fix to handle text inside buttons with image
    // - so they don't get extra padding (the external div with the image and another one for the text)
    // need to handle this better
    if (props && props.placeholder == "Answer") {
      style.padding = 0;
      style.margin = "auto";
      style.backgroundColor = "transparent";
      //style.maxWith = "100vw"
      // style.cursor = "pointer";
    }

    return (
      <div
        style={{ ...style }}
        className={`${className} reactLiquidEditor `}
        onClick={onClick}
      >
        {/* Check if its on preview on design mode so replace the curly braces to their code mode, and if not so replace with variables */}
        <ReactLiquid
          template={
            isPreviewDesignMode
              ? value
                .replaceAll("{{", "&#123;&#123;")
                .replaceAll("}}", "&#125;&#125;")
              : value.replace(/\{\{[^\{\}]*\}\}/g, function (match) {
                return /[\u0590-\u05FF]/.test(match)
                  ? dataWithVariables[match.replace(/\{\{|\}\}/g, "")] ||
                  match
                  : match.replace(/\./g, "__");
              })
          }
          data={dataWithVariables}
          html
        />
      </div>
    );
  }

  return (
    <div style={{ ...style }} className={isEdit ? "editorQuill" : ""}>
      <EditorQuill
        text={value}
        id={id}
        handleChange={handleChange}
        name={name}
        data={isResult ? newData : data}
        tag={tag}
        style={style}
        placeholder={placeholder}
        className={className}
        classNameForTag={classNameForTag}
        lastQuillElementId={props.lastQuillElementId}
        chnageQuillTollbar={props.actions.chnageQuillTollbar}

      // variables={Object.entries(dataWithVariables)}
      />
    </div>
  );
  return (
    <input
      type="text"
      value={value}
      onChange={(e) => handleChange(e, id)}
      onBlur={() => setShowInput(false)}
      name={name}
      autoFocus
    />
  );
}

const mapStateToProps = (state) => {
  const { isPreviewDesignMode, lastQuillElementId } = state;
  return { isPreviewDesignMode, lastQuillElementId };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      chnageQuillTollbar: (toolbarId) => dispatch(chnageQuillTollbarElement(toolbarId)),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ToggleInputAndText);
