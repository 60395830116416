import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  searchForImage,
  resetImages,
  sendAnalitixAction,
  deleteImage,
  uploadImage,
} from "../../redux/actions";
import InfiniteScroll from "../InfiniteScroll";
import { apiUrl } from "../../config";
import { mainAxios } from "../../Axios/mainAxios";
import Modal from "react-bootstrap/esm/Modal";
import "./popups.css";
import { withTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";
import ConfirmDeleteImageModal from "./View/ConfirmDeleteImageModal";

function ChooseImage(props) {
  const {
    searchImages,
    hasImages,
    index,
    image = "",
    mediaAltText = "",
    size,
    t,
    rigtBar,
  } = props;

  const [searchValue, setSearchValue] = useState("");
  const [searchTarget, setSearchTarget] = useState("upload");
  const [offset, setOffset] = useState(0);
  const [toResetImages, setToResetImage] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stateImgs, setStateImgs] = useState([]);
  const [selectedMediaProps, setSelectedMediaProps] = useState({});
  const [isConfrimDeleteModalOpen, setIsConfrimDeleteModalOpen] =
    useState(false);

  useEffect(() => {
    if (searchTarget === "upload") {
      const target = "myphotos";
      props.actions.resetImages();
      props.actions.searchForImage({
        searchValue,
        searchTarget: target,
        offset,
        toResetImages,
      });
    }
  }, [isModalOpen]);

  useEffect(() => {
    setStateImgs(searchImages);
    // setOffset(0)
  }, [searchImages]);

  const search = (e, scrolling) => {
    if (!e || e.target.id === "scrollMore" || e.charCode === 13) {
      setToResetImage(false);
      const target = searchTarget === "upload" ? "myphotos" : searchTarget;
      props.actions.searchForImage({
        searchValue,
        searchTarget: target,
        offset: scrolling ? offset + 24 : 0,
        toResetImages,
      });

      setOffset(scrolling ? offset + 24 : 0);
    }
  };

  const resetAllParams = () => {
    setOffset(0);
    setSearchValue("");
    setSearchTarget("upload");
    setIsModalOpen(false);
  };

  const changeSearchTarget = (target) => {
    props.actions.resetImages();
    setSelectedMediaProps({});
    setSearchTarget(target);
    setOffset(0);
    const myPhotos = "myphotos";
    if (target === "upload") {
      props.actions.searchForImage({
        searchValue,
        searchTarget: myPhotos,
        offset,
        toResetImages,
      });
    } else {
      props.actions.searchForImage({
        searchValue,
        searchTarget: target,
        offset,
        toResetImages,
      });
    }
  };

  const chooseVideo = (video, thumbnail) => {
    setSelectedMediaProps({ video, thumbnail });
  };
  const saveVideo = () => {
    const { video, thumbnail } = selectedMediaProps;
    if (!video) return;
    if (props.changeVideo) {
      props.changeVideo(video, thumbnail);
    }

    if (props.changeBackgroundVideo) {
      props.changeBackgroundVideo(video, thumbnail);
    }

    resetAllParams();
  };

  const ChooseImage = (image, defaultAltText, analytix = null) => {
    setSelectedMediaProps({ image, defaultAltText, analytix });
  };

  const saveImage = () => {
    const { image, defaultAltText, analytix } = selectedMediaProps;
    if (!image) return;
    if (image && analytix && searchTarget === "unsplash") {
      props.actions.sendAnalitixEvent(analytix);
    }

    if (props.changeImage) {
      props.changeImage(image, defaultAltText);
    }
    if (props.changeOptionImage) {
      const { id, index } = props;
      props.changeOptionImage(id, index, image, defaultAltText);
    }
    if (props.changeBackgroundImage) {
      props.changeBackgroundImage(image);
    }
    if (props.changeLogo) {
      props.changeLogo(image, props.toChange);
    }
    if (props.handleChangeFavicon) {
      props.handleChangeFavicon(image);
    }
    if (props.handleChangeMediaImage) {
      props.handleChangeMediaImage(image);
    }
    if (props.handleShareImage) {
      props.handleShareImage(image);
    }
    resetAllParams();
  };

  const removeImage = () => {
    setSelectedMediaProps({});
    resetAllParams();
  };

  const chooseFile = async (e) => {
    const file = e.target.files[0] || null;
    if (!file) return;
    const formData = new FormData();
    formData.append("image", file, file.name);
    props.actions.uploadImage(formData);
  };

  const isActiveClass = (e) => {
    return searchTarget === e ? "active" : "";
  };

  const renderCreditSwitch = () => {
    switch (searchTarget) {
      case "pexels":
        return "Photos provided by Pexels";
      case "pexels-video":
        return "Videos provided by Pexels";
      case "giphy":
        return "Photos provided by Giphy";
      case "unsplash":
        return "Photos provided by Unsplash";
      default:
        return "";
    }
  };

  const deleteImageFromServer = () => {
    const { image } = selectedMediaProps;
    if (!image) return;
    props.actions.deleteImage(image, {
      searchValue,
      searchTarget: "myphotos",
      offset,
      toResetImages,
    });
    setIsConfrimDeleteModalOpen(false);
  };

  return (
    <div style={props.borderStyle}>
      <ConfirmDeleteImageModal
        deleteImage={deleteImageFromServer}
        isOpen={isConfrimDeleteModalOpen}
        closeModal={() => setIsConfrimDeleteModalOpen(false)}
      />
      {/*<img*/}
      {/*  type="button"*/}
      {/*  title=""*/}
      {/*  style={{*/}
      {/*    width: props.style ? `${props.style.width}` : "100%",*/}
      {/*    height: props.style ? `${props.style.height}` : "100%",*/}
      {/*    opacity: "0",*/}
      {/*    position: rigtBar ? "inherit" : "absolute",*/}
      {/*  }}*/}
      {/*  onClick={() => setIsModalOpen(true)}*/}
      {/*/>*/}
      {image !== "noFavicon" ? (
        <>
          <img
            type="button"
            title=""
            src={image}
            alt={mediaAltText}
            className="answerPic"
            style={{ ...size }}
            onClick={() => setIsModalOpen(true)}
          />
        </>
      ) : (
        <>
          <FaPlus
            style={{ ...size }}
            color={"grey"}
            onClick={() => setIsModalOpen(true)}
          />
        </>
      )}

      <Modal
        className="gallery-modal"
        size="xl"
        show={isModalOpen}
        onHide={() => {
          resetAllParams();
        }}
        centered
      >
        <div className="chooseImage" id="infinite_scroll_modal">
          <div className="chooseImage-header">
            <h2>{t("answers-modal-choose-image")} </h2>
            {/*<div className="sort">*/}
            {/*  <label>{t("answers-modal-sort")} </label>*/}
            {/*  <select>*/}
            {/*    <option>{t("answers-modal-date")} </option>*/}
            {/*  </select>*/}
            {/*</div>*/}
            <div className="search">
              <input
                type="search"
                onChange={(e) => {
                  setOffset(0);
                  props.actions.resetImages();
                  setSearchValue(e.target.value);
                }}
                onKeyPress={search}
                placeholder={t("answers-modal-search-by-name")}
              />
            </div>
          </div>
          <div className="gallery-tabs">
            <a
              title=""
              value="unsplash"
              className={isActiveClass("unsplash")}
              onClick={(e) => changeSearchTarget("unsplash")}
            >
              Unsplash
            </a>
            <a
              title=""
              value="pexels"
              className={isActiveClass("pexels")}
              onClick={(e) => changeSearchTarget("pexels")}
            >
              Pexels
            </a>

            {props.changeBackgroundVideo || props.changeVideo ? (
              <a
                title=""
                value="pexels-video"
                className={isActiveClass("pexels-video")}
                onClick={(e) => changeSearchTarget("pexels-video")}
              >
                Video
              </a>
            ) : (
              <></>
            )}

            <a
              title=""
              value="giphy"
              className={isActiveClass("giphy")}
              onClick={(e) => changeSearchTarget("giphy")}
            >
              Giphy
            </a>
            {props.changeOptionImage ? (
              <a
                title=""
                value="knowyGallery"
                className={isActiveClass("knowyGallery")}
                onClick={(e) => changeSearchTarget("knowyGallery")}
              >
                Knowy Gallery
              </a>
            ) : (
              <></>
            )}
            <a
              title=""
              className={isActiveClass("upload")}
              value="upload"
              onClick={(e) => changeSearchTarget("upload")}
            >
              {t("answers-modal-photos")}
            </a>
          </div>
          {searchTarget == "upload" ? (
            <div className="gallery-tabContnet">
              {/* hidden input activated by the next input for design reasons */}
              <input
                type="file"
                style={{ display: "none", width: "0px" }}
                name="selectedFile"
                onChange={chooseFile}
                id="selectedFile"
              />

              <></>
            </div>
          ) : (
            <></>
          )}
          <InfiniteScroll
            data={stateImgs}
            hasImages={hasImages}
            searchTarget={searchTarget}
            handleNext={search}
            saveImage={ChooseImage}
            saveVideo={chooseVideo}
            id="scrollMore"
            selectedImage={
              selectedMediaProps.image || selectedMediaProps.video || image
            }
          />
        </div>
        <div style={{ textAlign: "left", marginLeft: "13px" }}>
          {renderCreditSwitch()}
        </div>
        <div className="popupFooter">
          <input
            type="button"
            style={{
              marginLeft: "20px",
              left: "0",
              position: "absolute",
              backgroundColor: "#BE1EB9",
            }}
            className="btn-popup-file"
            value={"Upload File"}
            onClick={() => document.getElementById("selectedFile").click()}
          />
          <span
            className="close close_multi"
            onClick={() => {
              resetAllParams();
            }}
          >
            {t("general-cancel")}
          </span>

          <input
            type="button"
            value={t("answers-modal-btn-delete")}
            className="btn-popup-file"
            style={{
              backgroundColor: selectedMediaProps.image
                ? "rgb(203, 42, 39)"
                : "grey",
            }}
            disabled={!selectedMediaProps.image}
            onClick={() => {
              setIsConfrimDeleteModalOpen(true);
            }}
          />

          <input
            type="button"
            value={t("answers-modal-btn-choose")}
            className="btn-popup-file"
            onClick={() => {
              if (selectedMediaProps.image) saveImage();
              else if (selectedMediaProps.video) saveVideo();
            }}
          />

          <input
            type="button"
            value={t("answers-modal-btn-remove-image")}
            className="btn-popup-delete d-none"
            onClick={removeImage}
          />
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { searchImages, hasImages } = state;
  return { searchImages, hasImages };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      searchForImage: (searchDetails) =>
        dispatch(searchForImage(searchDetails)),
      resetImages: () => dispatch(resetImages()),
      sendAnalitixEvent: (data) => dispatch(sendAnalitixAction(data)),
      deleteImage: (fileName, searchDetails) =>
        dispatch(deleteImage(fileName, searchDetails)),
      uploadImage: (formData) => dispatch(uploadImage(formData)),
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ChooseImage)
);
