import React from "react";

const handleStyle = (
  actionFunction,
  functionName,
  targetValue,
  targetName,
  id,
  value
) => {
  switch (functionName) {
    case "changeMainStyle":
      // code block
      actionFunction(
        { target: { value: targetValue, name: targetName } },
        value
      );

      break;
    case "handleQuestionStyle":
      return actionFunction(
        { target: { value: targetValue, name: targetName } },
        id,
        value
      );
      break;
    default:
    // code block
  }
};

const Justify = ({
  actionFunction,
  handleStyleFunctionName,
  targetName,
  value,
  id,
  selectedJustify,
  JustifyText,
}) => {
  selectedJustify = selectedJustify || "center";
  return (
    <div className="align">
      {/*<h3>{JustifyText} </h3>*/}
      <div style={{ display: "flex" }}>
        <a
          className={selectedJustify == "left" ? "selected-justify" : ""}
          id="btn-left"
          type="button"
          onClick={() =>
            handleStyle(
              actionFunction,
              handleStyleFunctionName,
              "left",
              targetName,
              id,
              value
            )
          }
          title=""
        >
          <i
            className="bi bi-justify-left"
            style={{ fontSize: "25px", color: "lightslategray" }}
          ></i>
        </a>
        <a
          className={selectedJustify == "center" ? "selected-justify" : ""}
          id="btn-center"
          type="button"
          onClick={() =>
            handleStyle(
              actionFunction,
              handleStyleFunctionName,
              "center",
              targetName,
              id,
              value
            )
          }
          title=""
        >
          <i
            className="bi bi-justify"
            style={{ fontSize: "25px", color: "lightslategray" }}
          ></i>
        </a>
        <a
          className={selectedJustify == "right" ? "selected-justify" : ""}
          id="btn-right"
          type="button"
          onClick={() =>
            handleStyle(
              actionFunction,
              handleStyleFunctionName,
              "right",
              targetName,
              id,
              value
            )
          }
          title=""
        >
          <i
            className="bi bi-justify-right"
            style={{ fontSize: "25px", color: "lightslategray" }}
          ></i>
        </a>
      </div>
    </div>
  );
};

export default Justify;
