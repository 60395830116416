import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { withTranslation } from "react-i18next";
function ConfirmDeleteImageModal(props) {
  const { isOpen, closeModal, deleteImage } = props;
  return (
    <>
      <Modal show={isOpen} centered>
        <div className="rename-slide-modal">
          <h2>Are you sure you want to delete that image?</h2>
          <p>The image will removed from all your quizes.</p>
          <button
            style={{ marginRight: "20px" }}
            className="btn-popup"
            onClick={() => deleteImage()}
          >
            Delete
          </button>
          <a type="button" onClick={() => closeModal()}>
            Cancel
          </a>
        </div>
      </Modal>
    </>
  );
}

export default withTranslation()(ConfirmDeleteImageModal);
