import React, { useEffect, useState } from "react";
import FoldersModal from "./Modals/FoldersModal";

function ChangeFolderModal(props) {
    const { folders, changeFolder, selectedQuizId, quizes, hide, t } = props;

    const [checkedFolder, setChecketFolder] = useState(null);

    useEffect(() => {
        const currQuiz = quizes.find((el) => el.id === selectedQuizId);
        if (currQuiz && currQuiz.ws_id) {
            setChecketFolder(currQuiz.ws_id);
        }
    }, []);

    const setSelectedFolder = (val) => {
        setChecketFolder(val);
    };

    const saveChangeFolder = () => {
        const currQuiz = quizes.find((el) => el.id === selectedQuizId);
        if (currQuiz !== checkedFolder) {
            changeFolder(selectedQuizId, checkedFolder);
        }
        hide();
    };

    return (
        <>
            {/*<div className="d-flex flex-column" style={{ marginTop: "20px" }}>*/}
            <h4 style={{ fontSize: "27px", marginTop: "40px" }}>Move To Workspace</h4>
            <select
                name=""
                id=""
                className=""
                onChange={(e) => setSelectedFolder(e.target.value)}
                style={{ width: "60%", height: "60px" }}
            >
                <option value="">Choose Workspace</option>
                {folders.map((el) => (
                    <option value={el.workspace_id}>{el.workspace_name}</option>
                ))}
            </select>
            {/*{folders.map((el) => (*/}
            {/*  <div className="form-check form-check-inline" key={el.id}>*/}
            {/*    <input*/}
            {/*      className="form-check-input"*/}
            {/*      type="radio"*/}
            {/*      name="folders-radio"*/}
            {/*      id={`input-folder-${el.id}`}*/}
            {/*      value={el.id}*/}
            {/*      checked={el.id == checkedFolder}*/}
            {/*      onClick={(e) => setSelectedFolder(e.target.value)}*/}
            {/*    />*/}

            {/*    <label className="form-check-label" for={`input-folder-${el.id}`}>*/}
            {/*      {el.name}*/}
            {/*    </label>*/}
            {/*  </div>*/}
            {/*))}*/}
            {/*<div className="footerBtns">*/}
            <a
                title=""
                className="saveBtn pointer"
                style={{ width: "60%", margin: "0px auto" }}
                onClick={() => saveChangeFolder()}
            >
                {t("publish-popup-btn-move-to-folder")}
            </a>
            {/* <h3 style={{ padding: "20px 0 10px 0", whiteSpace: "break-spaces" }}>
                {t("publish-popup-folder-move-to-folder-create-new")}
            </h3>
            <FoldersModal withoutModal={true} hide={hide} quizId={quizes.find((el) => el.id === selectedQuizId)} />
            </div> */}
            {/*</div>*/}
        </>
    );
}

export default ChangeFolderModal;
