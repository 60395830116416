import React, { useState, useEffect } from "react";

import { mainAxios } from "../Axios/mainAxios";
import { Redirect } from "react-router-dom";

//check relevant routes if the user can enter
export const withAuth = (WrappedComponent) => {
  return function (props) {
    const [verified, setVerified] = useState({ status: "loading" });
    useEffect(() => {
      const verify = async () => {
        try {
          const token = localStorage.getItem("token") || "";
          if (!token) setVerified({ status: false });
          const { data } = await mainAxios.get("/auth/verify");
          let { status } = data;
          setVerified({ status });
        } catch (err) {
          setVerified({ status: false });
        }
      };
      verify();
    }, []);
    const { status } = verified;
    if (status === "loading") return <div className="loader"></div>;
    if (!status) {
      localStorage.setItem("token", "");
      return <Redirect to="/" />;
    } else {
      return <WrappedComponent  {...props} />;
    }
  };
};
