const initialFilter = {
  configType: "text",
  filterField: null,
  filterType: "is",
  filterValue: null,
  filterOrOperator: false,
  filterDbColumnType: null,
};

export default initialFilter;
