import React from "react";
import AddQuestion from "../Add/Question/AddQuestion";
import AddContact from "../Add/Contact/AddContact";
import AddResult from "../Add/Result/AddResult";
import StyleSettings from "../ThemeEdit/Settings";
import "./style.css";
import Result from "../Add/Result";
import Themes from "../Themes";
import ThemesTab from "../Add/Question/Themes";
import { withTranslation } from "react-i18next";

class ToolBar extends React.Component {
  state = {
    tabToShow: "design",
  };
  componentDidUpdate() {
    const { onEdit } = this.props.data;
    // document.getElementById("mainContent").scrollTop =
    //   document.getElementById(onEdit.id).offsetTop - 50;
  }

  handleRender = () => {
    const type = this.props.data.rightBarActive;
    const {
      changeQuestionsOptionsDetails,
      handleChange,
      addOptionToQuestion,
      deleteFromArray,
      handleChangeContactInput,
      addInputToContact,
      deleteInputFromContact,
      data,
      handleContactInputs,
      updateMainSettings,
      changeLogoSettings,
      editBackgroundColors,
      addLinkToNav,
      changeNavs,
      removeLinkFromNav,
      deleteOptionFromQuestion,
      changeInputIndexes,
      handleQuestionFeedback,
      updateMainFeedbacks,
      handleChangeResultShare,
      handleShareDetails,
      handleQuestionStyle,
      handleGradientType,
      changeBackgroudnColorType,
      changeMainStyle,
      handleMatrixConfig,
      changeTheme,
      selectedTheme,
      addLocationsContainerToContact,
      deleteLocationContainerFromContact,
      handleContactLocationInputs,
      handleChangeMatrixData,
      handleChangeSliderData,
    } = this.props;
    switch (type) {
      case "start":
      case "text":
      case "result":
      case "question": {
        return (
          <AddQuestion
            toolbar={true}
            data={data}
            changeQuestionsOptionsDetails={changeQuestionsOptionsDetails}
            handleChangeQuestion={handleChange}
            addOptionToQuestion={addOptionToQuestion}
            deleteFromArray={deleteFromArray}
            deleteOptionFromQuestion={deleteOptionFromQuestion}
            changeInputIndexes={changeInputIndexes}
            handleQuestionFeedback={handleQuestionFeedback}
            handleQuestionStyle={handleQuestionStyle}
            handleMatrixConfig={handleMatrixConfig}
            changeTheme={changeTheme}
            changeNavs={changeNavs}
            selectedTheme={selectedTheme}
            handleChangeMatrixData={handleChangeMatrixData}
            handleChangeSliderData={handleChangeSliderData}
          />
        );
      }
      case "result": {
        // return (
        //   <Result
        //     data={data}
        //     handleChangeResult={handleChange}
        //     handleChangeResultShare={handleChangeResultShare}
        //     handleShareDetails={handleShareDetails}
        //   />
        // );
      }
      case "contact": {
        return (
          <AddContact
            toolbar={true}
            data={data}
            handleChangeContactDetails={handleChange}
            handleQuestionStyle={handleQuestionStyle}
            handleChangeContactInput={handleChangeContactInput}
            addInputToContact={addInputToContact}
            deleteInputFromContact={deleteInputFromContact}
            deleteFromArray={deleteFromArray}
            handleContactInputs={handleContactInputs}
            changeInputIndexes={changeInputIndexes}
            selectedTheme={selectedTheme}
            addLocationsContainerToContact={addLocationsContainerToContact}
            deleteLocationContainerFromContact={
              deleteLocationContainerFromContact
            }
            handleContactLocationInputs={handleContactLocationInputs}
          />
        );
      }
      case "styleSettings": {
        return (
          <Themes
            data={data}
            handleChange={handleChange}
            updateMainSettings={updateMainSettings}
            changeLogoSettings={changeLogoSettings}
            editBackgroundColors={editBackgroundColors}
            addLinkToNav={addLinkToNav}
            changeNavs={changeNavs}
            removeLinkFromNav={removeLinkFromNav}
            updateMainFeedbacks={updateMainFeedbacks}
            handleGradientType={handleGradientType}
            changeBackgroudnColorType={changeBackgroudnColorType}
            changeMainStyle={changeMainStyle}
            changeTheme={changeTheme}
          />
        );
      }
      default: {
        return (
          // <Start
          //   data={data}
          //   handleChange={handleChange}
          //   updateMainSettings={updateMainSettings}
          // />
          <></>
        );
      }
    }
  };

  render() {
    const { tabToShow } = this.state;
    const { t } = this.props;
    return (
      <div className="toolbar-design" style={{ width: "100%" }}>
        {tabToShow == "design" ? this.handleRender() : <></>}
      </div>
    );
  }
}

export default withTranslation()(ToolBar);
