// Gan 09-05-22 - i have changes the architecture, instead of two options:
// published and nun published i added third option,
//  non published and no subdomain _name' tha opens a form to enter your own subdomain
// so i have moved a lot of the code to small functions/components
// in the future i advise to move all the components to a diffrent file

import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { connect } from "react-redux";
import { websiteUrl, configHostUrl } from "../../config";
import { addStr } from "../../utils";
import { Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Modal from "react-bootstrap/esm/Modal";
// import Collapse from "react-bootstrap/Collapse";
class PublishModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
      newSubdomain: "",
      subdomainError: props.errors.subdomain,
      subdomain_name: props.subdomain_name,
      skippedSubdomain: false,
      redirectEmbedd: false,
      showModal: props.showModal,
      isUnpublished: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.subdomain_name !== this.props.subdomain_name) {
      this.setState({
        ...this.state,
        subdomain_name: this.props.subdomain_name,
      });
    }
    if (prevProps.showModal !== this.props.showModal) {
      this.setState({
        ...this.state,
        showModal: this.props.showModal,
      });
    }
  }

  render() {
    const {
      userId,
      orgId,
      slug,
      guid,
      published,
      is_redirected_domain,
      createSubdomain,
      quizId,
      domain_protocol,
      changeEmbeddObject,
      t,
    } = this.props;
    const { subdomain_name, skippedSubdomain, isUnpublished } = this.state;
    let subdomainPath;
    if (subdomain_name && !is_redirected_domain) {
      if (
        configHostUrl === "knowyyy.com" ||
        configHostUrl === "smarthippo.io"
      ) {
        subdomainPath = `https://${subdomain_name}.${configHostUrl}`;
      } else {
        subdomainPath = websiteUrl.includes("https://")
          ? addStr(websiteUrl, 8, `${subdomain_name}.`)
          : addStr(websiteUrl, 7, `${subdomain_name}.`);
      }
    } else if (subdomain_name) {
      subdomainPath = domain_protocol + "://" + subdomain_name;
    }
    const quizPath = subdomain_name
      ? `${subdomainPath}/page/${slug}`
      : `${websiteUrl}/quiz/${orgId}/${slug}`;
    // const embed = `<div>
    //     <div id=${guid}
    //         data-url="${websiteUrl}/quiz/1/g/${guid}" data-width='100%'></div>
    //     <script src="${websiteUrl}/assets/js/hippoloader.js"></script>
    //     <script>initEmbed(${guid});</script>
    // </div>`;

    const redirectEmbedd = () => {
      this.props.changeMode("share", true);
      this.props.changeShowModal(false);
      // changeEmbeddObject({ guid, slug, path: quizPath, quizId });
      // this.setState({ redirectEmbedd: true });
    };

    const handleCreateSubdomain = () => {
      const subDoVal = this.state.newSubdomain;
      const regex = /^[a-zA-Z0-9]*$/;
      if (!subDoVal || !regex.test(subDoVal)) {
        this.setState({
          ...this.state,
          subdomainError: "Not a valid subdomain",
        });
        return;
      }
      createSubdomain(subDoVal, quizId);
    };

    // const modelTitle = () => {
    //   if (!published && !subdomain_name && !skippedSubdomain) {
    //     return "Choose a Subdomain name";
    //   } else if (!published) {
    //     return "Publish";
    //   } else {
    //     return "Completly Published!";
    //   }
    // };

    const modelBody = () => {
      if (published != 1 && !subdomain_name && !skippedSubdomain) {
        return (
          <div className="fields">
            <label>{t("publish-popup-choose-subdomain")}</label>
            <input
              type="text"
              className="fileds-input-subdomain"
              id="subdomain-name"
              name="subdomain-name"
              aria-describedby="subdomain-error"
              placeholder={t("publish-popup-subdomain-name")}
              onChange={(e) =>
                this.setState({
                  newSubdomain: e.target.value,
                  subdomainError: "",
                })
              }
            />
            <small id="subdomain-error" className="text-danger form-text">
              {this.state.subdomainError}
            </small>
          </div>
        );
      } else if (published != 1) {
        return (
          <div className="fields">
            <label> {t("publish-popup-publish-now?")}</label>
          </div>
        );
      } else if (isUnpublished) {
        return (
          <div>
            <h3>Are you sure you want to do this?</h3>
          </div>
        );
      } else {
        return (
          <>
            <div className="fields">
              <label className="float-left">Link:</label>
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "100%" }}>
                  <input
                    type="text"
                    className="fileds-input-link"
                    defaultValue={quizPath}
                    tabIndex="1"
                  />
                </div>
                <div
                  style={{
                    width: "0",
                    position: "relative",
                    left: "-30px",
                    top: "10px",
                  }}
                >
                  <CopyToClipboard
                    text={quizPath}
                    onCopy={() => this.setState({ copied: true })}
                  >
                    <a title="copy-path" className="copy-path">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.5 0H5.5C4.83696 0 4.20108 0.263398 3.73224 0.732239C3.2634 1.20108 3 1.83696 3 2.5V3H2.5C1.83696 3 1.20108 3.2634 0.732239 3.73224C0.263398 4.20108 0 4.83696 0 5.5V13.5C0 14.163 0.263398 14.7989 0.732239 15.2678C1.20108 15.7366 1.83696 16 2.5 16H10.5C11.163 16 11.7989 15.7366 12.2678 15.2678C12.7366 14.7989 13 14.163 13 13.5V13H13.5C14.163 13 14.7989 12.7366 15.2678 12.2678C15.7366 11.7989 16 11.163 16 10.5V2.5C16 1.83696 15.7366 1.20108 15.2678 0.732239C14.7989 0.263398 14.163 0 13.5 0ZM12 13.5C12 13.8978 11.842 14.2794 11.5607 14.5607C11.2794 14.842 10.8978 15 10.5 15H2.5C2.10218 15 1.72064 14.842 1.43933 14.5607C1.15803 14.2794 1 13.8978 1 13.5V5.5C1 5.10218 1.15803 4.72064 1.43933 4.43933C1.72064 4.15803 2.10218 4 2.5 4H3V10.5C3 11.163 3.2634 11.7989 3.73224 12.2678C4.20108 12.7366 4.83696 13 5.5 13H12V13.5ZM15 10.5C15 10.8978 14.842 11.2794 14.5607 11.5607C14.2794 11.842 13.8978 12 13.5 12H5.5C5.10218 12 4.72064 11.842 4.43933 11.5607C4.15803 11.2794 4 10.8978 4 10.5V2.5C4 2.10218 4.15803 1.72064 4.43933 1.43933C4.72064 1.15803 5.10218 1 5.5 1H13.5C13.8978 1 14.2794 1.15803 14.5607 1.43933C14.842 1.72064 15 2.10218 15 2.5V10.5Z"
                          fill="#78889F"
                        />
                      </svg>
                    </a>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
            <div style={{ paddingBottom: "20px" }}>
              {this.state.copied ? (
                <span style={{ color: "#BE1EB9" }}>Copied.</span>
              ) : null}
            </div>
            {/* <div>
              <h3>Embed</h3>
              <div style={{ background: "lightgrey" }}>
                <br />
                <code style={{ color: "black" }}>{embed}</code>
                <div
                  style={{
                    position: "relative",
                    float: "right",
                    top: "0",
                  }}
                >
                  <CopyToClipboard text={embed}>
                    <button className="btn btn-light">Copy</button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>{" "} */}
          </>
        );
      }
    };

    const modelFooter = () => {
      if (published != 1 && !subdomain_name && !skippedSubdomain) {
        return (
          <>
            <input
              type="button"
              value={t("publish-popup-btn-create")}
              className="btn-popup"
              onClick={handleCreateSubdomain}
            />
            <input
              type="button"
              value={t("publish-popup-btn-skip")}
              className="btn-popup btn-skip"
              onClick={() =>
                this.setState({ ...this.state, skippedSubdomain: true })
              }
            />
          </>
        );
      } else if (published != 1) {
        return (
          <>
            <input
              type="button"
              value={t("nav-publish")}
              className="btn-popup"
              onClick={() => {
                if (published == 0) {
                  this.props.publishQuiz(1);
                  return;
                }
                if (
                  window.confirm(
                    "If You have un-publish conditions we recommend that you turn them off before republishing. Do you want to continue?"
                  )
                ) {
                  this.props.publishQuiz(1);
                }
              }}
            />
            <input
              type="button"
              value={t("general-cancel")}
              data-dismiss="modal"
              className="btn-popup btn-skip"
              onClick={() => this.props.changeShowModal(false)}
            />
          </>
        );
      } else if (isUnpublished) {
        return (
          <>
            {" "}
            <br />
            <div className="flex w-100 align-items-center unpublish-section">
              <button
                className="btn-popup btn-unpublish"
                style={{
                  backgroundColor: "#dc3545",
                  marginRight: "20px",
                  width: "47% !important",
                }}
                onClick={() => {
                  this.props.publishQuiz(2);
                  this.props.changeShowModal(false);
                  this.setState({ ...this.state, isUnpublished: false });
                }}
              >
                Unpublish
              </button>
              <button
                style={{ width: "47% !important" }}
                className="btn-popup btn-skip"
                onClick={() => {
                  this.props.changeShowModal(false);
                  this.setState({ ...this.state, isUnpublished: false });
                }}
              >
                Cancel
              </button>
            </div>
          </>
        );
      } else {
        return (
          <>
            {" "}
            <div className="d-flex justify-content-around align-baseline pointer">
              <a
                className="embed-btn"
                data-dismiss="modal"
                onClick={() => redirectEmbedd()}
              >
                {t("publish-popup-embedding-preferences")}
              </a>
              <button
                type="button"
                className="btn btn-secondary rounded rounded-pill w-50"
                data-dismiss="modal"
                onClick={() => {
                  this.setState({ ...this.state, isUnpublished: true });
                }}
              >
                {t("publish-popup-unpublish")}
              </button>
            </div>
          </>
        );
      }
    };

    return (
      <div>
        {this.state.redirectEmbedd ? (
          <Redirect to="/embedd" />
        ) : (
          // <div
          //   className={
          //     this.state.showModal
          //       ? "modal showModal publishModal"
          //       : "modal fade publishModal"
          //   }
          //   id="publish"
          //   tabIndex="-1"
          //   role="dialog"
          //   aria-labelledby="exampleModalLabel"
          //   aria-hidden="true"
          // >
          //   <div className="modal-dialog" role="document">
          //     <div className="modal-content">
          //       <div className="modal-body">{modelBody()}</div>
          //       <div className="modal-footer">{modelFooter()}</div>
          //     </div>
          //   </div>
          // </div>
          <Modal
            className="publishModal"
            size="xs"
            show={this.state.showModal}
            onHide={() => this.props.changeShowModal(false)}
            centered
          >
            <div className="modal-body">{modelBody()}</div>
            <div className="modal-footer">{modelFooter()}</div>
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { subdomain_name, is_redirected_domain, domain_protocol } =
    state.selectedDetails.quizDetails;
  return { subdomain_name, is_redirected_domain, domain_protocol };
};
export default withTranslation()(connect(mapStateToProps, null)(PublishModal));
