import { style } from "glamor";
import React from "react";
import { addStyleSettings } from "../../utils/patchFunctions";

class Pagination extends React.Component {
  handlePaginationView = () => {
    const { list, stepId } = this.props;
    const listLength = list.length - 1;
    const currentStepIndex = list.findIndex((step) => step.id === stepId);
    return (
      <span style={{ marginLeft: "20px", marginRight: "20px" }}>
        {currentStepIndex}/{listLength}
      </span>
    );
  };

  render() {
    let { show, isMobile, isTablet, isMobilePreview } = this.props;
    isMobile = isMobilePreview || isMobile;
    const backButtonStyle = this.props.style
      ? this.props.style
      : addStyleSettings({}, "backButton").backButton;

    //Font size
    let fontSize = backButtonStyle.fontSize;
    if (isMobile && backButtonStyle.mobileFontSize)
      fontSize = backButtonStyle.mobileFontSize;
    else if (isTablet && backButtonStyle.tabletFontSize)
      fontSize = backButtonStyle.tabletFontSize;
    else if (backButtonStyle.fontSize) fontSize = backButtonStyle.fontSize;

    //Top padding
    let topPadding = 0;
    if (backButtonStyle.padding) {
      if (isMobile && backButtonStyle.padding.mobileTop)
        topPadding = backButtonStyle.padding.mobileTop;
      else if (isTablet && backButtonStyle.padding.tabletTop)
        topPadding = backButtonStyle.padding.tabletTop;
      else if (backButtonStyle.padding.top)
        topPadding = backButtonStyle.padding.top;
    }

    //Right padding
    let rightPadding = 0;
    if (backButtonStyle.padding) {
      if (isMobile && backButtonStyle.padding.mobileRight)
        rightPadding = backButtonStyle.padding.mobileRight;
      else if (isTablet && backButtonStyle.padding.tabletRight)
        rightPadding = backButtonStyle.padding.tabletRight;
      else if (backButtonStyle.padding.right)
        rightPadding = backButtonStyle.padding.right;
    }

    //Left padding
    let leftPadding = 0;
    if (backButtonStyle.padding) {
      if (isMobile && backButtonStyle.padding.mobileLeft)
        leftPadding = backButtonStyle.padding.mobileLeft;
      else if (isTablet && backButtonStyle.padding.tabletLeft)
        leftPadding = backButtonStyle.padding.tabletLeft;
      else if (backButtonStyle.padding.left)
        leftPadding = backButtonStyle.padding.left;
    }

    //Bottom padding
    let bottomPadding = 0;
    if (backButtonStyle.padding) {
      if (isMobile && backButtonStyle.padding.mobileBottom)
        bottomPadding = backButtonStyle.padding.mobileBottom;
      else if (isTablet && backButtonStyle.padding.tabletBottom)
        bottomPadding = backButtonStyle.padding.tabletBottom;
      else if (backButtonStyle.padding.bottom)
        bottomPadding = backButtonStyle.padding.bottom;
    }

    const buttonStyle = {
      ...backButtonStyle,
      height: "fit-content",
      width: "fit-content",
      fontSize: `${fontSize}px`,
      padding: `${topPadding}px ${rightPadding}px ${bottomPadding}px ${leftPadding}px `,
      border: `${backButtonStyle.borderSize}px solid ${backButtonStyle.borderColor}`,
      borderRadius: `${backButtonStyle.borderRadius}px`,
    };

    if (!show) return <></>;
    return (
      <div
        className="d-flex"
        style={{ justifyContent: backButtonStyle.textAlign }}
      >
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <button
                style={buttonStyle}
                onClick={(e) => this.props.stepBack(this.props.stepId)}
                className="page-link"
                aria-label="Previous"
              >
                {backButtonStyle.buttonText}
              </button>
            </li>
            {/* <div>{this.handlePaginationView()}</div>  */}
            {/* <li className="page-item">
              <button className="page-link" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
              </button>
            </li> */}
          </ul>
        </nav>
      </div>
    );
  }
}

export default Pagination;
