import React from 'react';
import * as FontIcon from 'react-icons/fa';
import PropTypes from 'prop-types';
import { iconList } from '.';

const IconPickerItem = ({ icon, size, color, onClick, containerStyles }) => {
    const iconDiv = !!icon ? React.createElement(FontIcon[icon]) : <div />;
    return (
        <div
            onClick={() => !!onClick && onClick(icon)}
            style={{ fontSize: size, color: color, padding: 2, ...containerStyles }}
        >
            {iconDiv}
        </div>
    );
};

IconPickerItem.defaultProps = {
    color: '#000',
    size: 24,
    onClick: (_) => {},
};

IconPickerItem.propTypes = {
    // icon: PropTypes.oneOf(iconList).isRequired,
    size: PropTypes.number,
    color: PropTypes.string,
    onClick: PropTypes.func,
    containerStyles: PropTypes.object,
};

export { IconPickerItem };