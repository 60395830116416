export function getValueAsPercentageAndReplaceBasedMinAndMax(value, min, max) {
  return (value / 100) * (max - min) + min;
}

export function calculateSliderStepOnPercentage(min, max) {
  const range = max - min;
  return 100 / range;
}

export function formattingPercentageNumber(number) {
  return number.toFixed(1);
}

export function getValueAsPercentage(value, min, max) {
  const range = max - min;
  return ((value - min) / range) * 100;
}

