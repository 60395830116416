import React, { useEffect, useState } from "react";
import { websiteUrl, configHostUrl } from "../../../config";
import ColorPicker from "../../CreateQuiz/ColorPicker";
import { copyToClip } from "../../../utils";

function Banner(props) {
  const { slug, guid, path } = props;
  const [embedCode, setEmbedCode] = useState("");
  const [btnSize, setbtnSize] = useState(25);
  const [bannerSize, setBannerSize] = useState(25);
  const [btnText, setBtnText] = useState("Start");
  const [bannerText, setBannerText] = useState("Hello world");
  const [textColor, setTextColor] = useState("black");
  const [btnColor, setBtnColor] = useState("white");
  const [bgColor, setBgColor] = useState("#FBBB00");
  const [btnPosition, setBtnPosition] = useState("right");

  useEffect(() => {
    // const embed = `<div><div id="${guid}" data-url="${path}" data-width='100%'></div><script src="${websiteUrl}/assets/js/hippoloader.js"></script><script>initEmbed("${guid}", "banner", ["${websiteUrl}", , , , , ,, , ]);</script></div>`;
    const embed = `<div><div id="${guid}" data-knowy-url="${path}" data-knowy-type="banner" data-knowy-btnSize="${btnSize}" data-knowy-btnText="${btnText}" data-knowy-bannerText="${bannerText}" data-knowy-bannerSize="${bannerSize}" data-knowy-textColor="${textColor}" data-knowy-btnColor="${btnColor}" data-knowy-bgColor="${bgColor}" data-knowy-btnPosition="${btnPosition}"></div><script src="//app.knowyyy.com/embed.js"></script></div>`;

    setEmbedCode(embed);
  }, [
    btnSize,
    btnText,
    bannerText,
    bannerSize,
    textColor,
    bgColor,
    btnColor,
    btnPosition,
  ]);

  const editBackgroundColors = (e, funcName) => {
    const value = e.hex;
    funcName(value);
  };

  const layoutBtn = (layout, imgSrc) => {
    let classes = layout + " pointer";
    if (layout === btnPosition) {
      classes += " active";
    }
    return (
      <a title="" className={classes} onClick={() => setBtnPosition(layout)}>
        <img src={imgSrc} />
      </a>
    );
  };
  return (
    <div id="instant" className="active innerTabs">
      <div className="banner">
        <h3>Banner</h3>
        <p>
          The Banner Embed feature in Knowy lets you display your forms as a banner on your website. This provides a discreet yet noticeable way to collect information from your users. With the Banner Embed, you can choose the banner's location, design, and size to match your website's look and feel. The form can be opened and closed with ease, allowing users to provide their information without disrupting their experience. Use Knowy's Banner Embed to capture leads, gather feedback, or run surveys in a non-intrusive way.
        </p>
      </div>
      <div className="screens bannerScreen left">
        <div className="mobail">
          <span style={{ "background-color": "#FBBB00" }}></span>
        </div>
        <div className="desktop">
          <span style={{ "background-color": "#FBBB00" }}></span>
        </div>
      </div>
      <div className="style">
        <div className="sideStayle bannerStyle">
          <h3>Style:</h3>
          <div className="field">
            <label>Title text:</label>
            <input
              type="text"
              value={bannerText}
              placeholder="Hello world"
              onChange={(e) => setBannerText(e.target.value)}
            />
            <label>Button text:</label>
            <input
              type="text"
              value={btnText}
              placeholder="Start"
              onChange={(e) => setBtnText(e.target.value)}
            />
          </div>
          <div className="field">
            <label>Size:</label>
            <input
              type="number"
              placeholder={bannerSize || 0 + "%"}
              value={bannerSize}
              min={1}
              max={100}
              onChange={(e) => setBannerSize(e.target.value)}
            />
            <label>Size:</label>
            <input
              type="number"
              placeholder={btnSize || 0 + "%"}
              value={btnSize}
              min={1}
              max={100}
              onChange={(e) => setbtnSize(e.target.value)}
            />
          </div>
          <div>
            <div className="field">
              <label>Background color:</label>
              <ColorPicker
                setRight="inherit"
                color={bgColor}
                className="colorPiker"
                handleChange={(e) => editBackgroundColors(e, setBgColor)}
              />
            </div>
            <div className="field mt-4">
              <label>Text color:</label>
              <ColorPicker
                setRight="inherit"
                color={textColor}
                className="colorPiker"
                handleChange={(e) => editBackgroundColors(e, setTextColor)}
              />
            </div>
            <div className="field w-100">
              <label>Button color:</label>
              <ColorPicker
                setRight="inherit"
                color={btnColor}
                className="colorPiker"
                handleChange={(e) => editBackgroundColors(e, setBtnColor)}
              />
            </div>
          </div>
        </div>
        <div className="layout">
          <h3>Layout:</h3>

          {layoutBtn("right", "/img/layout-right.png")}

          {layoutBtn("left", "/img/layout-left.png")}

          {layoutBtn("bottom", "/img/layout-bottom.png")}

          {layoutBtn("top", "/img/layout-top.png")}
        </div>
        <div>
          <h3>Code:</h3>
          <textarea disabled value={embedCode}></textarea>
        </div>
      </div>
      <a
        onClick={() => copyToClip(embedCode)}
        title=""
        className="copy pointer"
      >
        Copy
      </a>
    </div>
  );
}

export default Banner;
