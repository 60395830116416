import React, { useState } from "react";
import Fonts from "../../../utils/Fonts/Fonts";
import ColorPicker from "../ColorPicker";
import SelectSize from "../SelectSize";
import { withTranslation } from "react-i18next";
import Justify from "../../justify/justify";
import PaddingEdit from "./PaddingEdit";
import { useMediaQuery } from "react-responsive";
import { ImFont, ImFontSize, ImParagraphJustify } from "react-icons/im";
import { BiFontColor } from "react-icons/bi";
import {
  RiTextSpacing,
  RiArrowUpSLine,
  RiArrowDownSLine,
} from "react-icons/ri";

import Modal from "react-bootstrap/esm/Modal";
import PlatformSelect from "../../PlatformSelect";

function ButtonStyle({
  selectedTheme,
  context,
  handleColorsToChange,
  handleChange,
  props,
  fonts,
  fromTheme,
}) {
  const { t, id, style, setings } = props;

  const [modalName, setModalName] = useState("");
  const [mode, setMode] = useState({});

  //@@ function that return diffrent block of padding depanding if theme or customize (modal)
  //being called from inside the component returns HTML
  const drawPaddingEditSection = () => {
    // if (selectedTheme !== "override-edit") {
    return (
      <div className="fontStyle space line">
        <div className="row platform-selection-container">
          <h3 className="col-6"> {t("theme-accordion-space")}</h3>
          <PlatformSelect showPlatformSelect={true} setMode={setMode} />
        </div>{" "}
        <div style={{ marginTop: "5px" }}>
          <PaddingEdit
            paddingObject={styleObject.padding}
            selectedTheme={selectedTheme}
            context={context}
            id={id}
            mode={mode}
            handleChange={handleChange}
            changeMainStyle={props.changeMainStyle}
            t={t}
          />
        </div>
      </div>
    );
  };

  //@@ function that return diffrent block of font size depanding if theme or customize (modal)
  //being called from inside the component returns HTML
  const drawFontSizeEditSection = () => {
    if (selectedTheme !== "override-edit") {
      return (
        <div className="fontStyle">
          <SelectSize
            fromTheme={fromTheme}
            name="fontSize"
            label={t("general-font-size")}
            value={{
              desktop: styleObject.fontSize,
              mobile: styleObject.mobileFontSize || styleObject.fontSize,
              tablet: styleObject.tabletFontSize || styleObject.fontSize,
            }}
            handleChange={(size, name = "fontSize") => {
              selectedTheme == "override-edit"
                ? (function () {
                    handleChange(
                      { target: { name, value: size } },
                      id,
                      context
                    );
                  })()
                : (function () {
                    props.changeMainStyle(
                      { target: { name, value: size } },
                      context
                    );
                    handleChange({ target: { name, value: size } }, context);
                  })();
            }}
            changedDetails={{ toChange: context }}
          />
        </div>
      );
    } else {
      return (
        <>
          <div className="fontSizeDiv">
            <SelectSize
              fromTheme={true}
              name="fontSize"
              label={t("general-font-size")}
              value={{
                desktop: styleObject.fontSize,
                mobile: styleObject.mobileFontSize || styleObject.fontSize,
                tablet: styleObject.tabletFontSize || styleObject.fontSize,
              }}
              handleChange={(size, name = "fontSize") => {
                selectedTheme == "override-edit"
                  ? (function () {
                      handleChange(
                        { target: { name, value: size } },
                        id,
                        context
                      );
                    })()
                  : (function () {
                      props.changeMainStyle(
                        { target: { name, value: size } },
                        context
                      );
                      handleChange({ target: { name, value: size } }, context);
                    })();
              }}
              changedDetails={{ toChange: context }}
            />
          </div>
        </>
      );
    }
  };

  //@@ function that return diffrent block of Border Attribures depanding if theme or customize (modal)
  //being called from inside the component returns HTML
  const drawBorderEditSection = () => {
    // if (selectedTheme !== "override-edit") {
    return (
      <>
        {" "}
        <div className="space line" style={{ width: "25%" }}>
          <h3>{t("theme-accordion-border-size")} </h3>
          <label style={{ width: "100%" }}>
            <input
              type="number"
              name="borderSize"
              defaultValue={
                styleObject.borderSize ? styleObject.borderSize : "0"
              }
              onChange={(e) => {
                selectedTheme == "override-edit"
                  ? handleChange(
                      { target: { name: "borderSize", value: e.target.value } },
                      id,
                      context
                    )
                  : (function (e) {
                      props.changeMainStyle(e, context);
                      handleChange(e, context);
                    })(e);
              }}
              border="1"
            />
            px
          </label>
        </div>
        <div className="space line" style={{ width: "25%" }}>
          <h3>{t("theme-accordion-border-radius")} </h3>

          <label style={{ width: "100%" }}>
            <input
              type="number"
              name="borderRadius"
              defaultValue={
                styleObject.borderRadius ? styleObject.borderRadius : "0"
              }
              onChange={(e) => {
                selectedTheme == "override-edit"
                  ? handleChange(
                      {
                        target: { name: "borderRadius", value: e.target.value },
                      },
                      id,
                      context
                    )
                  : (function (e) {
                      props.changeMainStyle(e, context);
                      handleChange(e, context);
                    })(e);
              }}
              border="1"
            />
            px
          </label>
        </div>
      </>
    );
  };

  //@@ function that return diffrent block of colors Attribures depanding if theme or customize (modal)
  //being called from inside the component returns HTML
  const drawColorsEditSection = () => {
    // if (selectedTheme !== "override-edit") {
    return (
      <>
        {" "}
        <div className="fontStyle" style={{ paddingBottom: "5px" }}>
          <h3>{t("theme-accordion-color-section")} </h3>
        </div>
        <div
          style={{
            display: "flex",
            columnGap: "16px",
            // margin: "0px 0 10px 0",
            justifyContent: "space-between",
          }}
        >
          <div className="fontStyle" style={{ textAlign: "center" }}>
            <div className="buttonColor">
              <ColorPicker
                isButtonStyle={false}
                color={styleObject.color ? styleObject.color : "black"}
                handleChange={(e) => {
                  handleColorsToChange(e, "color", context);
                }}
              />
            </div>
            <div className="noPadding">
              {" "}
              <h3>{t("theme-accordion-text-color")} </h3>
            </div>
          </div>

          <div className="fontStyle" style={{ textAlign: "center" }}>
            <div className="buttonColor">
              <ColorPicker
                isButtonStyle={false}
                setRight="30px"
                color={styleObject.background}
                handleChange={(e) =>
                  handleColorsToChange(e, "background", context)
                }
              />
            </div>

            <div className="noPadding">
              {" "}
              <h3>{t("theme-accordion-background-color")} </h3>
            </div>
          </div>

          <div className="fontStyle" style={{ textAlign: "center" }}>
            <div className="buttonColor">
              <ColorPicker
                isButtonStyle={false}
                setRight="0px"
                color={styleObject.borderColor}
                name="borderColor"
                handleChange={(e) =>
                  handleColorsToChange(e, "borderColor", context)
                }
              />
            </div>
            <div className="noPadding">
              {" "}
              <h3>{t("theme-accordion-border-color")} </h3>
            </div>
          </div>
        </div>
      </>
    );
  };

  if (!selectedTheme) return <></>;

  const defaultButtonStyle = {
    color: "#000",
    background: "#fff",
    borderRadius: "0",
    hoverColor: "",
    hoverBackground: "",
    hoverBorderSize: "0",
    hoverBorderColor: "",
    hoverBorderRadius: "0",
    fontWeight: "",
    fontSize: "16",
    fontType: "",
    fontFamily: "Open Sans",
    fontStyle: "",
    padding: { top: "0", right: "0", bottom: "0", left: "0" },
    textAlign: "center",
    borderColor: "#000",
    borderSize: "0",
  };

  //this is patches because of bad programinig - will need to be fixed in the future
  let styleObject =
    selectedTheme == "override-edit"
      ? props.style[context] || defaultButtonStyle
      : selectedTheme[context] || defaultButtonStyle;

  if (!styleObject.fontFamily) {
    styleObject = defaultButtonStyle;
  }
  if (!styleObject.padding) {
    styleObject.padding = { top: "0", right: "0", bottom: "0", left: "0" };
  }
  // ------------------------------

  if (
    selectedTheme == "override-edit" &&
    !styleObject.isOverride &&
    typeof handleChange === "function"
  ) {
    // props.style[context] = Object.assign({}, settings[context]);
    Object.entries(props.settings[context]).forEach(([key, value]) => {
      handleChange({ target: { name: key, value } }, id, context);
    });
    handleChange({ target: { name: "isOverride", value: true } }, id, context);
  }
  return (
    <div className="toolbar">
      {context === "backButton" ? (
        <div className="selectFont" style={{ padding: "0 0 10px 0" }}>
          <h3>Button Text</h3>
          <div className={"selectFont"}>
            <label style={{ width: "100%" }}>
              <input
                type="text"
                name="buttonText"
                defaultValue={
                  styleObject.buttonText ? styleObject.buttonText : "<<"
                }
                onChange={(e) => {
                  selectedTheme == "override-edit"
                    ? handleChange(
                        {
                          target: { name: "buttonText", value: e.target.value },
                        },
                        id,
                        context
                      )
                    : (function (e) {
                        props.changeMainStyle(e, context);
                        handleChange(e, context);
                      })(e);
                }}
                border="1"
              />
            </label>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="fontStyle">
        {/*{fromTheme ? <h3>{t("theme-accordion-font-style")} </h3> : ""}*/}
        <h3>{t("theme-accordion-font-style")} </h3>
        <div className="selectFont">
          <Fonts
            templatesFonts={fonts}
            selected={styleObject.fontFamily.replace(/\+/g, " ")}
            saveFont={(font) => {
              selectedTheme == "override-edit"
                ? (function () {
                    handleChange(
                      { target: { name: "fontFamily", value: font } },
                      id,
                      context
                    );
                  })()
                : (function () {
                    props.changeMainStyle(
                      { target: { name: "fontFamily", value: font } },
                      context
                    );
                    handleChange(
                      { target: { name: "fontFamily", value: font } },
                      context
                    );
                  })();
            }}
          />
        </div>
      </div>

      {drawFontSizeEditSection()}

      <div
        // className="fotStyle"
        style={{
          display: "flex",
          columnGap: "16px",
          // margin: "15px 0px 0px 0px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Justify
          // JustifyText={t("theme-accordion-text-alignment")}
          handleStyleFunctionName={
            selectedTheme == "override-edit"
              ? "handleQuestionStyle"
              : "changeMainStyle"
          }
          targetName={"textAlign"}
          value={context}
          id={selectedTheme == "override-edit" ? id : null}
          actionFunction={
            selectedTheme == "override-edit"
              ? handleChange
              : props.changeMainStyle
          }
          selectedJustify={
            selectedTheme === "override-edit"
              ? styleObject.textAlign
              : props.settings[context].textAlign
          }
        />

        {drawBorderEditSection()}
      </div>

      {drawColorsEditSection()}
      {drawPaddingEditSection()}
    </div>
  );
}

export default ButtonStyle;
