import React from "react";
import { connect } from "react-redux";
import { parseInviteAction, register, setGtmStatusAction } from "../../redux/actions";
import "../Login/login.css";
import { withTranslation } from "react-i18next";
import validator from "validator";
import {

  useSearchParams,
} from "react-router-dom";

class Register extends React.Component {
  state = {
    email: "",
    password: "",
    name: "",
    valid: {
      name: true,
      email: true,
      password: true,
    },
  };

  componentDidMount() {
    // document.title = "Register";
    this.props.actions.setGtmStatus({ page: "register", events: null });
  }

  handleChange = (e) => {
    const { value, name } = e.currentTarget;
    this.setState({ [name]: value });
  };
  handleRegister = () => {
    this.onEmailBlur();
    this.onPasswordBlur();
    this.onNameBlur();
    const { valid, email, password, name } = this.state;
    const { register } = this.props.actions;
    if (!valid.email || !valid.password || !valid.name) return;
    register({ email, password, name });
    // this.props.history.push("/login");
  };

  onEmailBlur = () => {
    const { valid, email } = this.state;
    valid.email = validator.isEmail(email);
    this.setState({ valid });
  };
  onPasswordBlur = () => {
    const { valid, password } = this.state;

    let passwordRegex = /^(?=.*[A-Z])(?=.*[a-zA-Z])[a-zA-Z\d!@#$%^&*()_+]{8,20}$/;
    valid.password = passwordRegex.test(password);

    this.setState({ valid });
  };

  onNameBlur = () => {
    const { valid, name } = this.state;
    valid.name = name ? true : false;
    this.setState({ valid });
  };

  checkIfLoginSuccess = () => {
    const params = new URLSearchParams(window.location.search);
    const term = params.get("invite");
    if (term) {
      this.props.actions.parseInvite(term);
    }
    const { user_id } = this.props;
    if (user_id) {
      this.props.history.push("/dashboard")
    } else if (term) {
      this.props.history.push(`/login`);
    }
  };

  render() {
    const { t } = this.props;
    const { name, password, email, valid } = this.state;
    this.checkIfLoginSuccess();
    return (
      <>
        <h1>{t("register-title")}</h1>

        <form name="login" style={{ width: "80%", margin: "auto" }}>
          <div className={!valid.name ? "error" : ""}>
            <input
              type="text"
              value={name}
              required
              name="name"
              onBlur={this.onNameBlur}
              onChange={this.handleChange}
              placeholder="Name"
            />
            <span className="errorMsg">{t("register-name-error-message")}</span>
          </div>
          <div className={!valid.email ? "error" : ""}>
            <input
              type="email"
              name="email"
              value={email}
              onBlur={this.onEmailBlur}
              onChange={this.handleChange}
              placeholder={t("login-email-placeholder")}
              required
            />
            <span className="errorMsg">{t("login-email-error-message")}</span>
          </div>
          <div className={!valid.password ? "error" : ""}>
            <input
              type="password"
              name="password"
              value={password}
              onBlur={this.onPasswordBlur}
              onChange={this.handleChange}
              placeholder={t("login-password-placeholder")}
              required
            />
            <span className="errorMsg">
              {t("login-password-error-message")}
            </span>
          </div>
          <input
            type="button"
            onClick={this.handleRegister}
            value="Register"
            className="login-btn"
          />
          {/* <span>OR</span> */}
          {/* <a href="#" title="Register with Google" className="google-btn"><img src="img/google.png" alt="Google" />{t('register-google')}</a> */}
        </form>

        <div className="px-5 ms-xl-4 mt-5">
          <p>
            Already a user? <a href="/login">Login here</a>
          </p>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { user_id } = state.loginDetails;
  return { user_id };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      register: (userDetails) => dispatch(register(userDetails)),
      setGtmStatus: (status) => dispatch(setGtmStatusAction(status)),
      parseInvite: (invite) => dispatch(parseInviteAction(invite)),
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Register)
);
