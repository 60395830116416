import React, { useState } from "react";
import Fonts from "../../../utils/Fonts/Fonts";
import ColorPicker from "../ColorPicker";
import SelectSize from "../SelectSize";
import { withTranslation } from "react-i18next";
import Justify from "../../justify/justify";
import { useMediaQuery } from "react-responsive";
import { ImFont, ImFontSize, ImParagraphJustify } from "react-icons/im";
import { BiFontColor } from "react-icons/bi";
import { RiTextSpacing } from "react-icons/ri";

import PaddingEdit from "./PaddingEdit";
import PlatformSelect from "../../PlatformSelect";

function ContactFormDesign({
  selectedTheme,
  context,
  handleChange,
  props,
  handleColorsToChange,
  fromTheme,
}) {
  const { settings, fonts, t, id } = props;
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const [mode, setMode] = useState({});

  if (!selectedTheme) return <></>;

  let styleObject = selectedTheme[context];
  if (!styleObject && props.style && props.style[context]) {
    styleObject = props.style[context];
  } else if (!styleObject) {
    styleObject = {
      color: "rgba(0, 0, 0, 1)",
      fontWeight: "",
      fontSize: "",
      fontType: "",
      fontFamily: "Open Sans",
      fontStyle: "",
      padding: { top: "0", right: "0", bottom: "0", left: "0" },
    };
    props.data.settings[context] = styleObject;
  }

  if (
    selectedTheme == "override-edit" &&
    !styleObject.isOverride &&
    typeof handleChange === "function"
  ) {
    // props.style[context] = Object.assign({}, settings[context]);
    Object.entries(props.data.settings[context]).forEach(([key, value]) => {
      handleChange({ target: { name: key, value } }, id, context);
    });
    handleChange({ target: { name: "isOverride", value: true } }, id, context);
  }
  return (
    <>
      <div className="fontStyle">
        <h3>{t("theme-accordion-font-style")} </h3>
        <div>
          <Fonts
            templatesFonts={fonts}
            selected={
              styleObject.fontFamily.replace(/\+/g, " ")
                ? styleObject.fontFamily.replace(/\+/g, " ")
                : props.data.settings.subtitle.fontFamily.replace(/\+/g, " ") ||
                  "open sans"
            }
            saveFont={(font) => {
              selectedTheme == "override-edit"
                ? (function () {
                    handleChange(
                      { target: { name: "fontFamily", value: font } },
                      id,
                      context
                    );
                  })()
                : props.changeMainStyle(
                    { target: { name: "fontFamily", value: font } },
                    context
                  );
              handleChange(
                { target: { name: "fontFamily", value: font } },
                context
              );
            }}
          />
        </div>
        <SelectSize
          name="fontSize"
          fromTheme={true}
          label={t("general-font-size")}
          value={{
            desktop: styleObject.fontSize,
            mobile: styleObject.mobileFontSize || styleObject.fontSize,
            tablet: styleObject.tabletFontSize || styleObject.fontSize,
          }}
          handleChange={(size, name = "fontSize") => {
            selectedTheme == "override-edit"
              ? (function () {
                  handleChange({ target: { name, value: size } }, id, context);
                })()
              : (function () {
                  props.changeMainStyle(
                    { target: { name, value: size } },
                    context
                  );
                  handleChange({ target: { name, value: size } }, context);
                })();
          }}
          changedDetails={{ toChange: context }}
        />
      </div>

      <div
        style={{
          display: "flex",
          columnGap: "16px",
          margin: "10px 0 10px 0",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <h3>{t("rightBar-theme-forms-label-align")}</h3>
          <Justify
            JustifyText={t("theme-accordion-text-alignment")}
            handleStyleFunctionName={
              selectedTheme == "override-edit"
                ? "handleQuestionStyle"
                : "changeMainStyle"
            }
            targetName={"textAlign"}
            value={context}
            id={selectedTheme == "override-edit" ? id : null}
            actionFunction={
              selectedTheme == "override-edit"
                ? handleChange
                : props.changeMainStyle
            }
            selectedJustify={
              selectedTheme === "override-edit"
                ? styleObject.textAlign
                : props.settings[context].textAlign
            }
          />
        </div>

        <div
          style={{
            display: "flex",
            width: "20%",
            columnGap: "16px",
            // margin: "0px 0 10px 0",
            justifyContent: "space-between",
          }}
        >
          <div className="fontStyle">
            <h3>{t("theme-accordion-text-color")}</h3>
            <ColorPicker
              setRight="0px"
              isButtonStyle={true}
              color={styleObject.color}
              handleChange={(e) => {
                handleColorsToChange(e, "color", context);
              }}
            />
          </div>
        </div>
      </div>

      <div className="space line">
        <div className="row platform-selection-container">
          <h3 className="col-6"> {t("theme-accordion-space")}</h3>
          <PlatformSelect showPlatformSelect={true} setMode={setMode} />
        </div>{" "}
        <div>
          <PaddingEdit
            paddingObject={styleObject.padding}
            selectedTheme={selectedTheme}
            context={context}
            id={id}
            mode={mode}
            handleChange={handleChange}
            changeMainStyle={props.changeMainStyle}
            t={t}
          />
        </div>
      </div>

      <div>
        {/*<h3>{t("rightBar-theme-forms-main")}</h3>*/}
        <div className="fontStyle">
          <SelectSize
            fromTheme={true}
            name="width"
            label={t("rightBar-theme-from-width")}
            value={{
              desktop: styleObject.width,
              mobile: styleObject.mobileWidth || styleObject.width,
              tablet: styleObject.tabletWidth || styleObject.width,
            }}
            handleChange={(size, name = "width") => {
              selectedTheme == "override-edit"
                ? (function () {
                    handleChange(
                      { target: { name, value: size } },
                      id,
                      context
                    );
                  })()
                : (function () {
                    props.changeMainStyle(
                      { target: { name, value: size } },
                      context
                    );
                    handleChange({ target: { name, value: size } }, context);
                  })();
            }}
            changedDetails={{ toChange: context }}
          />
        </div>
        <h3>{t("rightBar-theme-forms-align")}</h3>
        <Justify
          handleStyleFunctionName={
            selectedTheme == "override-edit"
              ? "handleQuestionStyle"
              : "changeMainStyle"
          }
          targetName={"formTextAlign"}
          value={context}
          id={selectedTheme == "override-edit" ? id : null}
          actionFunction={
            selectedTheme == "override-edit"
              ? handleChange
              : props.changeMainStyle
          }
          selectedJustify={
            selectedTheme === "override-edit"
              ? styleObject.textAlign
              : props.settings[context].textAlign
          }
        />
      </div>

      <div className="addSwitch" style={{ paddingTop: "15px" }}>
        <label className="switch">
          <input
            type="checkbox"
            name="minimalStyle"
            checked={styleObject.minimalStyle}
            onChange={(e) => {
              selectedTheme == "override-edit"
                ? (function () {
                    handleChange(
                      {
                        target: {
                          name: "minimalStyle",
                          value: e.target.checked,
                        },
                      },
                      id,
                      context
                    );
                  })()
                : (function () {
                    props.changeMainStyle(
                      {
                        target: {
                          name: "minimalStyle",
                          value: e.target.checked,
                        },
                      },
                      context
                    );
                    handleChange(
                      {
                        target: {
                          name: "minimalStyle",
                          value: e.target.checked,
                        },
                      },
                      context
                    );
                  })();
            }}
          />{" "}
          <span className="slider round"></span>
        </label>
        {t("rightBar-theme-from-input-style")}
      </div>
    </>
  );
}

export default withTranslation()(ContactFormDesign);
