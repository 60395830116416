import React from "react";
import "./createQuiz.css";
import Logo from "./Logo";

class Nav extends React.Component {
  render() {
    let {
      position,
      useHeaderCustomStyle = null,
      useFooterCustomStyle = null,
      customStyle,
      isMobile,
      isTablet,
      isMobilePreview,
    } = this.props;
    isMobile = isMobilePreview || isMobile;

    let {
      links,
      background,
      logoImageSrc,
      size,
      mobileSize,
      tabletSize,
      logoPosition,
      fontFamily,
      fontSize,
      mobileFontSize,
      tabletFontSize,
      fontStyle,
      fontWeight,
      fontUnderline,
      backgroundImage,
      logoPaddingTop,
      logoPaddingBottom,
      logoPaddingRight,
      logoPaddingLeft,
      textAlign,
      linkColor,
      height,
      mobileHeight,
      tabletHeight,
    } = this.props.data;
    const overideCondition =
      position === "header" ? useHeaderCustomStyle : useFooterCustomStyle;

    const { isPreview, show, handleEditView, isEdit } = this.props;
    links = Object.values(links) ? Object.values(links) : [];

    if (customStyle && overideCondition) {
      //Height
      if (isMobile && customStyle.mobileHeight)
        height = customStyle.mobileHeight;
      else if (isTablet && customStyle.tabletHeight)
        height = customStyle.tabletHeight;
      else if (customStyle.height) height = customStyle.height;
      //Size
      if (isMobile && customStyle.mobileSize) size = customStyle.mobileSize;
      else if (isTablet && customStyle.tabletSize)
        size = customStyle.tabletSize;
      else if (customStyle.size) size = customStyle.size;
      //Font size
      if (isMobile && customStyle.mobileFontSize)
        fontSize = customStyle.mobileFontSize;
      else if (isTablet && customStyle.tabletFontSize)
        fontSize = customStyle.tabletFontSize;
      else if (customStyle.fontSize) fontSize = customStyle.fontSize;
    } else {
      if (isMobile && mobileHeight) height = mobileHeight;
      else if (isTablet && tabletHeight) height = tabletHeight;
      else if (height) height = height;

      if (isMobile && mobileFontSize) fontSize = mobileFontSize;
      else if (isTablet && tabletFontSize) fontSize = tabletFontSize;
      else if (fontSize) fontSize = fontSize;

      if (isMobile && mobileSize) size = mobileSize;
      else if (isTablet && tabletSize) size = tabletSize;
      else if (size) size = size;
    }
    const style = {
      width: "100%",
      backgroundImage:
        overideCondition && customStyle
          ? `url('${customStyle.backgroundImage}')`
          : `url('${backgroundImage}')`,
      backgroundRepeat: "no-repeat",
      height: `${height}px`,
    };

    // if (!backgroundImage) style.background = style.background || "grey";
    style.backgroundColor =
      overideCondition && customStyle ? customStyle.background : background;
    if (position === "footer") {
      style.bottom = 0;
      style.textAlign = textAlign;
    } else {
      style.top = 0;
      style.textAlign = textAlign;
    }
    if (!show) return <></>;

    const nameOfClass = position === "footer" ? "footer" : "header";

    const linksStyle = {
      fontFamily:
        overideCondition && customStyle ? customStyle.fontFamily : fontFamily,
      fontSize:
        overideCondition && customStyle ? `${fontSize}px` : `${fontSize}px`,
      fontStyle:
        overideCondition && customStyle ? customStyle.fontStyle : fontStyle,
      fontWeight:
        overideCondition && customStyle ? customStyle.fontWeight : fontWeight,
      textDecoration:
        overideCondition && customStyle
          ? customStyle.fontUnderline
          : fontUnderline,
      color:
        overideCondition && customStyle
          ? customStyle.linkColor || "black"
          : linkColor || "black",
    };
    return (
      <div
        className={
          isEdit &&
          this.props.rightBarView.context.replace("Style", "") === nameOfClass
            ? `${nameOfClass} active-element`
            : nameOfClass
        }
        style={style}
        onClick={(e) => {
          handleEditView(e, `${position}Style`, "design");
        }}
      >
        <Logo
          logo={{
            logoImageSrc:
              overideCondition && customStyle
                ? customStyle.logoImageSrc
                : logoImageSrc,
            size,
            logoPosition: {
              logoPaddingTop:
                overideCondition && customStyle
                  ? customStyle.logoPaddingTop
                  : logoPaddingTop,
              logoPaddingBottom:
                overideCondition && customStyle
                  ? customStyle.logoPaddingBottom
                  : logoPaddingBottom,
              logoPaddingRight:
                overideCondition && customStyle
                  ? customStyle.logoPaddingRight
                  : logoPaddingRight,
              logoPaddingLeft:
                overideCondition && customStyle
                  ? customStyle.logoPaddingLeft
                  : logoPaddingLeft,
              textAlign:
                overideCondition && customStyle
                  ? customStyle.textAlign
                  : textAlign,
            },
            show,
          }}
        />
        <nav>
          <ul>
            {overideCondition && customStyle && customStyle.hideLinks ? (
              <></>
            ) : (
              links.map((linkItem) => {
                const { id, title, link, icon } = linkItem;
                if (isPreview) {
                  return (
                    <li
                      key={id}
                      className="d-flex"
                      style={{
                        justifyContent:
                          overideCondition &&
                          customStyle &&
                          position === "footer"
                            ? customStyle.textAlign
                            : textAlign,
                      }}
                    >
                      {icon ? <a href={link} className={icon}></a> : <></>}
                      <a style={linksStyle} href={link} title="">
                        {title}
                      </a>
                    </li>
                  );
                } else {
                  return (
                    <li
                      key={id}
                      className="d-flex"
                      style={{
                        justifyContent:
                          overideCondition &&
                          customStyle &&
                          position === "footer"
                            ? customStyle.textAlign
                            : textAlign,
                      }}
                    >
                      {icon ? <div className={icon}></div> : <></>}
                      <a style={linksStyle} type="button" title="">
                        {title}
                      </a>
                    </li>
                  );
                }
              })
            )}
          </ul>
        </nav>
      </div>
    );
  }
}

export default Nav;
