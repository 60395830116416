import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { getOrgsUsers, getOrgsUsersSuccess, sendWSInviteAction } from "../../../redux/actions";
import Modal from "react-bootstrap/Modal";
import { withTranslation } from "react-i18next";

import ReactSelect from "react-select";


function InviteUsersToWs(props) {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [inviteArray, setInviteArray] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [filterUsers, setFilterUsers] = useState([]);
    const { orgUsers, wsName, wsId, userId } = props;

    useEffect(() => {
        if (modalIsOpen) {
            props.actions.getUsers();
        } else {
            props.actions.resetUsers();
        }
    }, [modalIsOpen]);

    useEffect(() => {
        if (orgUsers && orgUsers.length > 0) {
            setFilterUsers(orgUsers.filter((el) => {
                const userWs = el.user_workspaces ? el.user_workspaces.split(",") : [];
                console.log("userWs", userWs, wsId)
                return userId != el.id && !userWs.includes(wsId.toString());
            }).map((el) => ({ value: el.email, label: el.email })));
        }
    }, [orgUsers, inviteArray]);

    const sendInvitations = () => {
        const tempInviteArr = Object.assign([], inviteArray);

        props.actions.sendInvite(tempInviteArr.map((el) => { return { email: el.value, role: 3 } }), wsId);
        setIsOpen(false);
    }

    return (
        <>
            <button className="btn btn-primary"
                style={{ height: "36px", marginRight: "10px", verticalAlign: "bottom", backgroundColor: "#BE1EB9", border: "none" }}
                onClick={() => setIsOpen(true)}>Add members to workspace

            </button>

            <Modal show={modalIsOpen} onHide={() => setIsOpen(false)} centered>
                <h2> Add Users To {wsName} workspace </h2>

                <ReactSelect
                    placeholder=""
                    defaultValue={filterUsers}
                    value={inviteArray}
                    style={{ width: "44px" }}
                    className={"react-select-container"}
                    options={filterUsers}
                    isMulti={true}
                    onChange={(users) => setInviteArray(users)}
                    components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                    }}
                />
                <div className="">
                    <button className="btn btn-primary" onClick={() => {
                        sendInvitations();
                        setIsOpen(false);
                    }}>Send Invite</button>
                </div>

            </Modal>
        </>
    )

}
const mapStateToProps = (state) => {
    const { loginDetails, orgUsers } = state;
    let { name, orgArray, orgId, user_id: userId } = loginDetails;
    return { name, orgArray, orgId, permitions: state.permitions, orgUsers, userId };
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            sendInvite: (emails, id) => dispatch(sendWSInviteAction(emails, id)),
            getUsers: () => dispatch(getOrgsUsers()),
            resetUsers: () => dispatch(getOrgsUsersSuccess([])),
        },
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(InviteUsersToWs));