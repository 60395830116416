import React from "react";
import Fonts from "../../../../../utils/Fonts/Fonts";
import ColorPicker from "../../../ColorPicker";
import SelectSize from "../../../SelectSize";
import { withTranslation } from "react-i18next";
import Justify from "../../../../justify/justify";
import TitleAndSubTitle from "../../../ThemeEdit/titleAndSubtitle";
// import Avatar from "@material-ui/core/Avatar";
// import { FiEdit3 } from "react-icons/fi";

function SubtitleStyle(props) {
  const handleColorsToChange = (e, name) => {
    const { r, g, b, a } = e.rgb;
    const value = `rgba(${r}, ${g}, ${b}, ${a})`;
    props.handleQuestionStyle(
      { target: { name, value } },
      props.id,
      "subtitle"
    );
  };
  const {
    settings,
    style,
    handleQuestionStyle,
    fonts,
    id,
    useSubTitleCustomStyle,
    handleChangeQuestion,
    t,
  } = props;

  return (
    <>
      <div className="addSwitch override">

        <label className="switch">
          <input
            type="checkbox"
            name="withSubtitle"
            checked={useSubTitleCustomStyle ? true : false}
            onChange={(e) =>
              handleChangeQuestion(
                {
                  target: {
                    name: "useSubTitleCustomStyle",
                    type: "checkbox",
                    checked: useSubTitleCustomStyle ? false : true,
                  },
                },
                id
              )
            }
          />
          <span className="slider round"></span>
        </label>
        {t("rightBar-customize-override")}

      </div>
      {useSubTitleCustomStyle ? (
        <>
          <TitleAndSubTitle
            selectedTheme={"override-edit"}
            handleChange={handleQuestionStyle}
            context="subtitle"
            // fonts={fonts}
            props={props}
            handleColorsToChange={handleColorsToChange}
          />
        </>
      ) : (
        <>
          <div className="addSwitch-disabled"></div>
          <TitleAndSubTitle
            selectedTheme={"override-edit"}
            handleChange={{}}
            context="subtitle"
            // fonts={fonts}
            props={props}
            handleColorsToChange={{}}
          />
        </>
      )}
    </>
  );
}

export default withTranslation()(SubtitleStyle);
