import React, { useEffect, useState } from "react";

export default function PackageItem({
  packageItem,
  getPackageCheckout,
  setIsModalOpen,
}) {
  // let btnClass = "btn btn-primary";
  let btnClass = "btn btn-warning";
  if (packageItem.callToAction === "Current Package") {
    btnClass = "btn btn-secondary disabled";
  } else if (packageItem.callToAction === "Upgrade") {
    btnClass = "btn btn-warning";
  }
  return (
    <div className="card">
      <div
        className="card-body d-flex flex-column align-items-center justify-content-around"
        style={{ height: "600px" }}
      >
        <img src={packageItem.product.images[0]} alt="" />
        <h3 className="card-title">{packageItem.product.name}</h3>
        {/*<p className="card-text">{packageItem.product.description}</p>*/}
        <p className="card-text" style={{fontSize:"14px", height:"250px"}}>
<ul>
        {packageItem.product.metadata.features ?
            packageItem.product.metadata.features.split(",").map((item,index) => {
              return (
              <li>{item}</li>
              )
            }):
            <span></span>
        }
</ul>
        </p>
        {/*<p className="card-text">{packageItem.nickname}</p>*/}
        <a
            style={{fontSize: "18px",
                fontWeight: "700"
            }}
          className={btnClass}
          onClick={() => {
            if (packageItem.callToAction === "Current Package") return false;
            // window.preventDefault();
            // setIsModalOpen(true);
            getPackageCheckout(
              packageItem.id,
              window.location.href,
              packageItem.callToAction
            );
          }}
        >
          {packageItem.callToAction === "Upgrade" || packageItem.callToAction === "Pay" ? "Upgrade to " + packageItem.product.name : packageItem.callToAction}
        </a>
        <div className="card-text">
          <span style={{fontSize:"45px"}}>{packageItem.recurring.interval ==="year" ? packageItem.unit_amount / 100 /12 : packageItem.unit_amount / 100}
           { " " + packageItem.currency.toUpperCase()} </span><span style={{fontSize:"25px"}}> /mo</span>
          <p>Billed {packageItem.recurring.interval ==="year" ? "yearly": "monthly"}</p>
        </div>
      </div>
    </div>
  );
}
