import React, { Component } from "react";
// import ReactExport from "react-export-excel";
import "./analyze.css";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class Download extends Component {
  render() {
    let { columns, data, downloadExcellFile } = this.props;
    // if (!data || !data.length) return <></>;
    if (!downloadExcellFile) return <div></div>

    // let headers = Object.keys(data[data.length - 1]);
    // const headers1 = columns[0].columns.map((item) => item.accessor);
    // const headers2 = columns[1].columns.map((item) => item.accessor);

    // const headers = [...headers2, "create_at"];
    const multiDataSet = [
      {
        // // columns: [...columns[1].columns],
        // columns: [...headers],

        // data: data.map((itr) => {
        //   headers.forEach((element) => {
        //     if (!itr[element]) {
        //       itr[element] = itr.meta[element] ? itr.meta[element] : "";
        //     }
        //   });

        //   return Object.entries(itr)
        //     .filter(([k, v], i) => {
        //       if (headers.includes(k)) {
        //         return true;
        //       }
        //     })
        //     .sort(([k1], [k2]) => {
        //       const k1Index = headers.indexOf(k1);
        //       const k2Index = headers.indexOf(k2);
        //       return k1Index - k2Index;
        //     })
        //     .map(([k, v], i) => v || "");
        // }),
      },
    ];

    return (
      <div>
        <button
          onClick={() => {
            downloadExcellFile()

            alert("We will send You the data Via your email shortly ")

          }} className="btn quiz-download-excel">Download</button>
        {/* <ExcelFile
          element={
            <button className="btn quiz-download-excel">Download</button>
          }
        >
          <ExcelSheet dataSet={multiDataSet} name="Organization" />
        </ExcelFile> */}
      </div>
    );
  }
}

export default Download;
