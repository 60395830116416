import React from 'react'
import { Link, Redirect, useHistory } from "react-router-dom";
import { sendResetPasswordEmail, validateMailToken, resetPassword } from './services'
import { connect } from 'react-redux';
import { login } from "../../redux/actions"
import "../Login/login.css"
import { withTranslation } from 'react-i18next';
import validator from 'validator';


class ResetPassword extends React.Component {
    state = {
        step: 1,
        sendToEmail: '',
        newPassword: '',
        confirmPassword: '',
        email: '',
        mailToken: '',
        password: '',
        valid: {
            sendToEmail: true,
            newPassword: true,
            confirmPassword: true,
            email: true,
            password: true
        },
        mailTokenVerified: true,
        errorMessage: ''
    }

    async componentDidMount() {
        const mailToken = new URL(window.location).searchParams.get("t")
        if (!mailToken) return
        const { success } = await validateMailToken(mailToken)
        if (success) return this.setState({ mailTokenVerified: true, step: 3 })
        this.setState({ mailTokenVerified: false })
    }

    validateEmail = (email = '') => {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailRegex.test(String(email).toLowerCase());
    }
    resetPassword = async () => {
        const mailToken = new URL(window.location).searchParams.get("t")
        const { newPassword, confirmPassword, valid } = this.state
        let passwordRegex = /^(?=.*[A-Z])(?=.*[a-zA-Z])[a-zA-Z\d!@#$%^&*()_+]{8,20}$/;
        valid.newPassword = passwordRegex.test(newPassword);
        valid.confirmPassword = passwordRegex.test(confirmPassword);
        if (!valid.newPassword || !valid.confirmPassword) {
            this.setState({ valid })
            return
        }
        if (!newPassword) {
            valid.newPassword = false
            valid.confirmPassword = true
            return this.setState({ valid })
        }
        if (!confirmPassword || newPassword !== confirmPassword) {
            valid.confirmPassword = false
            valid.newPassword = true
            return this.setState({ valid })
        }
        const { success } = await resetPassword(newPassword, mailToken)
        if (success) {
            valid.confirmPassword = true
            valid.newPassword = true
            this.setState({ valid, step: 4 })
        }
    }

    login = async () => {
        const { email, password, valid } = this.state
        if (!email) {
            valid.email = false
            valid.password = true
            return this.setState({ valid })
        }
        if (!password) {
            valid.password = false
            valid.email = true
            return this.setState({ valid })
        }
        await this.props.actions.login({ email, password })
    }


    handleChange = (e) => {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    onEmailBlur = () => {
        const { valid, email } = this.state;
        valid.email = validator.isEmail(email);
        this.setState({ valid });
    };

    handleSendEmail = async () => {
        const { valid, sendToEmail } = this.state
        const isValidEmail = this.validateEmail(sendToEmail)
        valid.sendToEmail = isValidEmail
        if (!isValidEmail) return this.setState({ valid })
        else {
            const { success } = await sendResetPasswordEmail(sendToEmail)
            if (success) return this.setState({ valid, step: 2 })
        }
    }

    handleView = () => {
        const { step, sendToEmail, errorMessage, newPassword, password, confirmPassword, email, valid } = this.state
        switch (step) {
            case 1:
                return (
                    <form name="login">
                        <h3>Forgot your password?</h3>
                        <h2>Recover your account step 1:</h2>
                        {/*<div className="form-group">*/}
                        {/*    <input type="email" style={{ border: !valid.sendToEmail ? 'solid red 1px' : '' }} name="sendToEmail" value={sendToEmail} onChange={this.handleChange} className="form-control" placeholder="Email address..." />*/}
                        {/*</div>*/}
                        <div className={!valid.email ? "error" : ""}>
                            <input
                                type="email"
                                name="sendToEmail"
                                value={sendToEmail}
                                onChange={this.handleChange}
                                placeholder="Email address..."
                                onBlur={this.onEmailBlur}
                                required
                            />
                            <span className="errorMsg">error</span>
                        </div>
                        <input
                            type="button"
                            value="Reset Password"
                            onClick={this.handleSendEmail}
                            className="login-btn"
                        />
                        {/*<a href="#" onClick={this.handleSendEmail} title="" className="btn">Reset password</a>*/}
                    </form>
                )
            case 2:
                return (
                    <>
                        <h3>Fotgot your password?</h3>
                        <h2>Recover your account step 2:</h2>
                        <p>Check your email inbox</p>
                    </>
                )
            case 3:
                return (
                    <>
                        <h2>Recover your account step 3:</h2>
                        <div className="form-group">
                            <input type="password" style={{ border: !valid.newPassword ? 'solid red 1px' : '' }} className="form-control" name="newPassword" value={newPassword} onChange={this.handleChange} placeholder="New Passowrd" />
                        </div>
                        <div className="form-group">
                            <input type="password" style={{ border: !valid.confirmPassword ? 'solid red 1px' : '' }} className="form-control" name="confirmPassword" value={confirmPassword} onChange={this.handleChange} placeholder="Confirm Password" />
                        </div>
                        <a href="#" title="" onClick={this.resetPassword} className="btn">Reset</a>
                    </>
                )
            case 4:
                return <Redirect to="/login" />

            default:
                return <></>
        }
    }
    render() {
        const { mailTokenVerified } = this.state
        if (!mailTokenVerified) return <></>
        return (
            <main className="register">
                <header>
                    <a href="https://knowyyy.com/"><img src="img/logo.png" alt="KNOWY - KNOW YOUR CLIENT" style={{ height: "40px" }} /></a>
                </header>
                <section className="registerContent">
                    {this.handleView()}
                </section>
            </main>
        )
    }
}

const mapStateToProps = (state) => {
    const { user_id } = state.loginDetails
    return { user_id }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            login: (userDetails) => dispatch(login(userDetails))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
