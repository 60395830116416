import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { withTranslation } from "react-i18next";
import ReactSelect from "react-select";
function NewScriptModal(props) {
  const {
    selectedScriptId,
    selectedScriptObj,
    modalOpen,
    modalTogle,
    scriptType,
    updateScript,
    handleSaveScripts,
    t,
    allSteps,
  } = props;
  const [scriptBody, setScriptBody] = useState("");
  const [scriptTitle, setScriptTitle] = useState("");
  const [scriptId, setScriptId] = useState(null);
  const [scriptActive, setScriptActive] = useState(false);
  const [scriptRunTime, setScriptRunTime] = useState("");
  const [allStepsAsOptions, setAllStepsAsOptions] = useState([]);
  const [specificSteps, setSpecificSteps] = useState([]);
  const [errorInScript, setErrorInScript] = useState("");
  const [showSpecificStepsIsEmptyMessage, setShowSpecificStepsIsEmptyMessage] =
    useState(false);

  useEffect(() => {
    setAllStepsAsOptions(
      allSteps.map((step) => {
        return { value: step.id, label: step.title.replace(/<[^>]+>/g, "") };
      })
    );
    if (selectedScriptObj) {
      setScriptBody(selectedScriptObj.script);
      setScriptTitle(selectedScriptObj.title);
      setScriptActive(selectedScriptObj.active);
      setScriptRunTime(selectedScriptObj.runtime || 1);
      setScriptId(selectedScriptId);
      setSpecificSteps(selectedScriptObj.specificSteps || []);
    }
  }, [selectedScriptId, selectedScriptObj]);

  useEffect(() => {
    if (
      !scriptBody.startsWith("<script>") &&
      !scriptBody.startsWith("<noscript>")
    ) {
      return setErrorInScript(
        `Script Must start with "<script> or <noscript> tags."`
      );
    }
    if (
      scriptBody.startsWith("<script>") &&
      !scriptBody.endsWith("</script>")
    ) {
      return setErrorInScript(`Script Must end with "</script>."`);
    }
    if (
      scriptBody.startsWith("<noscript>") &&
      !scriptBody.endsWith("</noscript>")
    ) {
      return setErrorInScript(`Script Must end with "</noscript>."`);
    }
    const scriptWithoutTags = scriptBody
      .replaceAll("<script>", "")
      .replaceAll("</script>", "")
      .replaceAll("<noscript>", "")
      .replaceAll("</noscript>", "");
    try {
      new Function(scriptWithoutTags);
      setErrorInScript("");
    } catch (e) {
      setErrorInScript(e.message);
    }
  }, [scriptBody]);

  const handleSpecificSteps = (newSpecificSteps) => {
    setSpecificSteps(newSpecificSteps);
    setShowSpecificStepsIsEmptyMessage(!newSpecificSteps.length);
  };

  const handleUpdateScript = (isActive) => {
    if (errorInScript || (scriptRunTime == 5 && !specificSteps.length)) return;
    const scriptObj = {
      ...selectedScriptObj,
      title: scriptTitle,
      script: scriptBody,
      active: scriptActive,
      runtime: scriptRunTime,
      specificSteps,
    };

    updateScript({ target: { name: "title", value: scriptTitle } }, scriptId);
    updateScript({ target: { name: "script", value: scriptBody } }, scriptId);
    updateScript(
      { target: { name: "active", checked: scriptActive } },
      scriptId
    );
    handleSaveScripts(scriptObj, scriptId);
    modalTogle(false);
  };

  return (
    <div>
      <Modal
        className="configure-modal"
        size="xl"
        show={modalOpen}
        onHide={() => modalTogle(false)}
      >
        <h2 className="modal-title" id="exampleModalLabel">
          {scriptType}
        </h2>

        <div className="modal-body modal-big">
          <div>
            <input
              type="text"
              value={scriptTitle}
              name="title"
              //   onChange={(e) => this.props.updateScript(e, selectedScriptId)}
              onChange={(e) => setScriptTitle(e.target.value)}
              className="form-control"
            />
          </div>
          <div>
            <textarea
              className="form-control"
              value={scriptBody}
              //   onChange={(e) => this.props.updateScript(e, selectedScriptId)}
              onChange={(e) => setScriptBody(e.target.value)}
              name="script"
              cols="30"
              rows="20"
            />
          </div>
          <span style={{ color: "red" }}>{errorInScript}</span>
        </div>
        <div className="modal-footer modal-big">
          <div className="d-block modal-active ">
            <label>Run script at</label>
            <select
              value={scriptRunTime}
              onChange={(e) => setScriptRunTime(e.target.value)}
            >
              <option value="1">On load</option>
              <option value="2">On each step and result</option>
              <option value="3">Results pages</option>
              <option value="4">Non results pages</option>
              <option value="5">On Specific step</option>
              <option value="6">Manual (from logic)</option>
            </select>
          </div>
          {scriptRunTime == 5 ? (
            <div className="d-block modal-active ">
              <label>Step</label>
              <ReactSelect
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: showSpecificStepsIsEmptyMessage
                      ? "red"
                      : "grey",
                  }),
                }}
                defaultValue={specificSteps}
                onChange={handleSpecificSteps}
                isMulti
                options={allStepsAsOptions}
                isSearchable
              />
              <span style={{ color: "red" }}>
                {showSpecificStepsIsEmptyMessage
                  ? "You must enter at least one spesific step."
                  : ""}
              </span>
            </div>
          ) : (
            <></>
          )}
          <div className="d-block modal-active ">
            <label className="checkboxContainer ">
              <input
                type="checkbox"
                name="active"
                checked={scriptActive}
                onChange={(e) => {
                  setScriptActive(!scriptActive);
                }}
              />
              <span className="checkmark"></span>
              <span> {t("configuration-custom-scripts-active")} </span>
            </label>
          </div>
          <div className="float-right  w-50">
            <a
              title=""
              className=" pointer mr-2 "
              onClick={() => modalTogle(false)}
            >
              {t("general-cancel")}
            </a>
            <a
              title=""
              className="saveBtn-script w-50 pointer ml-2 "
              onClick={() => handleUpdateScript(scriptActive)}
            >
              {t("configuration-custom-scripts-save")}
            </a>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { subdomain_name, is_redirected_domain, domain_protocol } =
    state.selectedDetails.quizDetails;

  return {};
};
export default withTranslation()(
  connect(mapStateToProps, null)(NewScriptModal)
);
