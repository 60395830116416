import Actions from "./actions.config";
const initialState = {
  loginDetails: {
    message: "",
    name: "",
    userName: "",
    user_id: "",
    orgId: "",
    orgArray: [],
    roleId: "",
  },
  searchImages: [],
  saveQuizMessage: "",
  templates: [],
  selectedDetails: {},
  userQuizes: { quizes: [], folders: [] },
  quizAnalyzeGraph: [],
  quizAnalyzeTable: [],
  quizAnalyzeTablePageCount: 1,
  quizAnalyzeHeaders: [],
  quizAnalyzeStats: {},
  quizAnalyzeQuestions: [],
  hasImages: true,
  themes: [],
  language: "en",
  editorToolbarRef: null,
  apikeys: [],
  errors: { subdomain: "" },
  rightBarView: {
    context: "pageLayout",
    subContextId: "",
    rightBarViewTab: "customize",
  },
  rightBarViewTab: "customize",
  embedd: { path: null, guid: null, slug: null, quizId: null },
  profile: {},
  updatedPassword: {},
  logicStepObj: {},
  logicResult: null,
  isLogic: {},

  isUserIntegrations: false,
  integrationApps: [],
  integrationsModules: [],
  integrationsModulesConnections: [],
  moduleConnectionsOptions: {},
  integrationTriggers: [],
  moduleFieldsOptions: [],
  moduleAdditionalptions: {},
  connectEditObject: {},

  accessibilityConfiguration: {
    hideAccessibility: false,
    accessibilityIcon: "MdOutlineAccessibilityNew",
    iconColor: "blue",
    iconBackgroundColor: "#ff6647",
    position: "ImArrowDownLeft2",
    statement: "Accessibility Statement",
  },
  accessibility: {
    fontSize: 0,
    color: "",
    backgroundColor: "",
    lineHeight: 0,
    lowSaturation: false,
    highSaturation: false,
    monochrome: false,
    highContrast: false,
    fontFamilyAriel: false,
  },

  packages: [],
  packageSessions: "",
  paymentDetails: {},
  unpublishConditions: {},
  permitions: {},
  gtmStatus: { page: "restart" },
  isMobile: false,
  isTablet: false,
  isMobilePreview: false,

  visitKey: "",

  orgUsers: [],
  orgFeeds: [],
  workspaces: [],
  isPreviewDesignMode: false,

  productsLogic: {},

  lastQuillElementId: "",
};
export default function root(state = initialState, action) {
  switch (action.type) {
    case Actions.LOGIN: {
      const {
        message,
        name,
        token,
        user_name: userName,
        id,
        err,
        orgId,
        roleId,

        orgArray,
      } = action.payload;
      localStorage.setItem("token", token);
      return {
        ...state,
        loginDetails: {
          message,
          name,
          userName,
          user_id: id,
          orgId,
          roleId,
          orgArray,
        },
        userQuizes: { quizes: [], folders: [] },
      };
    }
    case Actions.LOGOUT: {
      localStorage.setItem("token", "");
      return {
        ...state,
        loginDetails: { message: "", name: "", userName: "", user_id: "" },
      };
    }
    case Actions.INIT: {
      const { loginDetails } = state;
      const { name, user_name: userName, id, orgId, orgArray } = action.payload;
      return {
        ...state,
        loginDetails: {
          ...loginDetails,
          name,
          userName,
          user_id: id,
          orgId,
          orgArray,
        },
      };
    }
    case Actions.SEARCH_FOR_IMAGE: {
      const { images, hasImages, replace } = action.payload;
      return {
        ...state,
        searchImages: replace ? images : [...state.searchImages, ...images],
        hasImages,
      };
    }
    case Actions.RESET_IMAGES: {
      return {
        ...state,
        searchImages: [],
      };
    }

    case Actions.EDIT_QUIZ: {
      const { message } = action.payload;
      return {
        ...state,
        saveQuizMessage: message,
      };
    }

    case Actions.CHANGE_LANGUAGE: {
      return {
        ...state,
        language: action.payload,
      };
    }

    case Actions.GET_TEMPLATES: {
      return {
        ...state,
        templates: action.payload,
      };
    }
    case Actions.GET_THEMES: {
      return {
        ...state,
        themes: action.payload,
      };
    }

    case Actions.GET_QUIZ_DETAILS_BY_QUIZ_ID: {
      const {
        quizDetails,
        isLogic,
        accessibilityData = {},
        permitions = {},
      } = action.payload;
      /* 111 */
      return {
        ...state,
        selectedDetails: quizDetails,
        isLogic: isLogic,
        accessibilityConfiguration: accessibilityData,
        permitions: permitions,
      };
    }

    case Actions.GET_USER_QUIZES: {
      const { folders, quizes } = action.payload;
      if (!folders || !quizes) return state;
      return {
        ...state,
        userQuizes: action.payload,
      };
    }

    case Actions.DELETE_QUIZ: {
      const { data, quizId } = action.payload;
      let { userQuizes } = state;
      if (data.status) {
        const index = userQuizes.quizes.findIndex((step) => step.id === quizId);
        userQuizes.quizes.splice(index, 1);
      }
      return {
        ...state,
        userQuizes,
      };
    }

    case Actions.GET_QUIZ_DETAILS_BY_USER_ID_AND_SLUG: {
      const { key, quizDetails, isLogic } = action.payload;
      localStorage.setItem("key", key);
      sessionStorage.setItem("visitKey", quizDetails.visitGuid);
      return {
        ...state,
        selectedDetails: quizDetails,
        isLogic: isLogic,
        visitKey: quizDetails.visitGuid,
      };
    }

    case Actions.GET_QUIZ_ANALYZE_GRAPH: {
      return {
        ...state,
        quizAnalyzeGraph: action.payload,
      };
    }
    case Actions.GET_QUIZ_ANALYZE_STATS: {
      return {
        ...state,
        quizAnalyzeStats: action.payload,
      };
    }
    case Actions.GET_QUIZ_ANALYZE_LINE_CHART: {
      return {
        ...state,
        quizAnalyzeLineChart: action.payload,
      };
    }

    case Actions.GET_QUIZ_ANALYZE_QUESTIONS: {
      return {
        ...state,
        quizAnalyzeQuestions: action.payload,
      };
    }

    case Actions.GET_QUIZ_ANALYZE_TABLE: {
      return {
        ...state,
        quizAnalyzeTable: action.payload.analyzeData,
        quizAnalyzeTablePageCount: action.payload.pageCount,
      };
    }

    case Actions.GET_QUIZ_ANALYZE_HEADERS: {
      return {
        ...state,
        quizAnalyzeHeaders: action.payload,
      };
    }

    case Actions.SET_EDITOR_TOOLBAR_REF: {
      return { ...state, editorToolbarRef: action.payload };
    }

    case Actions.GET_API_KEYS_SUCCESS: {
      return { ...state, apikeys: action.payload };
    }

    // template for errors-text handeling directly from reducer
    case Actions.SET_ERROR: {
      const { type, value } = action.payload;
      return {
        ...state,
        errors: { ...state.errors, [type]: value },
      };
    }

    case Actions.CHANGE_RIGHT_BAR_VIEW: {
      const { context, subContextId = "", rightBarViewTab } = action.payload;
      return {
        ...state,
        rightBarView: { ...state.rightBarView, context, subContextId },
        rightBarViewActive: { ...state.rightBarView, rightBarViewTab },
      };
    }

    case Actions.SAVE_SCRIPTS_STYLES_SUCCESS: {
      const { scriptsAndStyles } = action.payload;
      return {
        ...state,
        selectedDetails: {
          ...state.selectedDetails,
          quizDetails: {
            ...state.selectedDetails.quizDetails,
            ...scriptsAndStyles,
          },
        },
      };
    }

    case Actions.SAVE_EMBEDD_OBJECT: {
      const { embeddObj } = action.payload;
      return {
        ...state,
        embedd: embeddObj,
      };
    }

    case Actions.GET_USER_INFO: {
      const { user } = action.payload;
      return {
        ...state,
        profile: user,
      };
    }
    case Actions.UPDATE_USER_INFO: {
      const { user } = action.payload;
      return {
        ...state,
        profile: user,
      };
    }

    case Actions.PASSWORD_UPDATE: {
      const { user } = action.payload;
      return {
        ...state,
        updatedPassword: user,
      };
    }

    case Actions.LOGIC_STEP_UPDATE: {
      return {
        ...state,
        logicStepObj: action.payload,
      };
    }
    /*  ACCESSIBILITY*/
    case Actions.ACCESSIBILITY: {
      return {
        ...state,
        accessibility: { ...state.accessibility, ...action.payload },
      };
    }

    case Actions.ACCESSIBILITY_CONFIGURATION: {
      return {
        ...state,
        accessibilityConfiguration: action.payload,
      };
    }

    case Actions.LOGIC_RESULT_UPDATE: {
      return {
        ...state,
        logicResult: action.payload,
      };
    }

    case Actions.GET_INTEGRATION_APPS: {
      return {
        ...state,
        integrationApps: action.payload,
      };
    }

    case Actions.GET_INTEGRATION_MODULES: {
      return {
        ...state,
        integrationsModules: action.payload,
      };
    }

    case Actions.GET_INTEGRATION_CONNECTION_MODULES: {
      return {
        ...state,
        integrationsModulesConnections: action.payload,
      };
    }

    case Actions.GET_MDULES_CONNECTIONS_OPTIONS: {
      return {
        ...state,
        moduleConnectionsOptions: action.payload,
      };
    }

    case Actions.GET_INTEGRATION_TRIGGERS: {
      return {
        ...state,
        integrationTriggers: action.payload,
      };
    }

    case Actions.GET_INTEGRATION_TRIGGERS_ACTIVE: {
      const { value, triggerId } = action.payload;
      const selecteTriggerIndex = state.integrationTriggers.findIndex(
        (el) => el.id == triggerId
      );
      const tempArry = Object.assign([], state.integrationTriggers);
      tempArry[selecteTriggerIndex].is_active = value;
      return {
        ...state,
        integrationTriggers: tempArry,
      };
    }

    case Actions.GET_IS_USER_INTEGRATED: {
      return {
        ...state,
        isUserIntegrations: action.payload,
      };
    }

    case Actions.GET_MDULES_FIELDS_OPTIONS: {
      return {
        ...state,
        moduleFieldsOptions: action.payload,
      };
    }

    case Actions.GET_MDULES_CONNECTIONS_MORE_OPTIONS: {
      return {
        ...state,
        moduleAdditionalptions: {
          ...state.moduleAdditionalptions,
          ...action.payload,
        },
      };
    }

    case Actions.GET_PACKGES: {
      return {
        ...state,
        packages: action.payload,
      };
    }

    case Actions.SET_SESSION_URL: {
      return {
        ...state,
        packageSession: action.payload,
      };
    }

    case Actions.SET_PAYMENT_DETAILS: {
      return {
        ...state,
        paymentDetails: action.payload,
      };
    }

    case Actions.SET_CONNECT_EDIT_OBJECT: {
      return {
        ...state,
        connectEditObject: action.payload,
      };
    }

    case Actions.GET_UNPUBLISH_CONDITION: {
      return {
        ...state,
        unpublishCondition: action.payload,
      };
    }

    case Actions.SET_GTM_STATUS: {
      return {
        ...state,
        gtmStatus: action.payload,
      };
    }
    case Actions.SET_MEDIA_QUERY: {
      return {
        ...state,
        isMobile: action.payload.isMobile || false,
        isTablet: action.payload.isTablet || false,
      };
    }

    case Actions.SET_IS_MOBILE_PREVIEW: {
      return {
        ...state,
        isMobilePreview: action.payload,
      };
    }
    case Actions.CHANGE_PERMITIONS: {
      return {
        ...state,
        permitions: action.payload,
      };
    }
    case Actions.GET_ORGS_USERS: {
      return {
        ...state,
        orgUsers: action.payload,
      };
    }

    case Actions.SAVE_VISIT_KEY: {
      return {
        ...state,
        visitKey: action.payload,
      };
    }

    case Actions.GET_WORKSPACES: {
      return {
        ...state,
        workspaces: action.payload,
      };
    }

    case Actions.GET_WORKSPACE_QUIZES: {
      return {
        ...state,
        workspaceQuizes: action.payload,
      };
    }

    case Actions.TOGGLE_PREVIEW_DESIGN_MODE: {
      return {
        ...state,
        isPreviewDesignMode: action.payload,
      };
    }
    case Actions.GET_ORGS_FEEDS: {
      return {
        ...state,
        orgFeeds: action.payload,
      };
    }

    case Actions.PRODUCTS_LOGIC_UPDATE: {
      return {
        ...state,
        productsLogic: action.payload,
      };
    }
    case Actions.CHANGE_QUILL_TOOLBAR: {
      return {
        ...state,
        lastQuillElementId: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
