import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { withTranslation } from "react-i18next";

function DeleteFeedModal(props) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const { feedId, deleteFeedAction, t } = props;

  const handleClick = () => {
    deleteFeedAction(feedId);
    setIsOpen(false);
  };
  return (
    <>
      <button
        className="btn btn-danger"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Delete feed
      </button>

      <Modal show={modalIsOpen} onHide={() => setIsOpen(false)} centered>
        <h2>Are you sure you want to delete this feed?</h2>
        <div style={{ paddingBottom: "15px" }}>
          <span>{t("leftBar-delete-slide-desc-undo")} </span>
        </div>
        <button
          type="button"
          value={t("general-delete")}
          onClick={handleClick}
          className="btn btn-danger"
        >
          {t("general-delete")}
        </button>
        <span
          className="close close_multi"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          {t("leftBar-delete-wait-keep-it")}
        </span>
      </Modal>
    </>
  );
}
export default withTranslation()(DeleteFeedModal);
