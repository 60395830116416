import React, { useEffect, useState } from "react";
import { websiteUrl, configHostUrl } from "../../../config";
import ColorPicker from "../../CreateQuiz/ColorPicker";
import { copyToClip } from "../../../utils";

function PopUp(props) {
  const { slug, guid, path } = props;
  const [embedCode, setEmbedCode] = useState("");
  const [btnSize, setbtnSize] = useState(25);
  const [btnText, setBtnText] = useState("Start");
  const [btnColor, setBtnColor] = useState("black");
  const [btnBgColor, setBtnBgColor] = useState("#fc7d73");

  useEffect(() => {
    // const embed = `<div><div id="${guid}" data-url="${path}" data-width='100%'></div><script src="${websiteUrl}/assets/js/hippoloader.js"></script><script>initEmbed("${guid}", "popup", ["${websiteUrl}", , , , ]);</script></div>`;
    const embed = `<div><div id="${guid}" data-knowy-url="${path}" data-knowy-type="popup" data-knowy-textSize="${btnSize}" data-knowy-textContent="${btnText}" data-knowy-btnColor="${btnColor}" data-knowy-btnBgColor="${btnBgColor}"></div><script src="//app.knowyyy.com/embed.js"></script></div>`;
    setEmbedCode(embed);
  }, [btnSize, btnColor, btnText, btnBgColor]);

  const editBackgroundColors = (e, funcName) => {
    const value = e.hex;
    funcName(value);
  };
  return (
    <div id="instant" className="active innerTabs">
      <div className="banner">
        <h3>PopUp</h3>
        <p>
          The Popup Embed feature in Knowy makes it easy to display your form as a pop-up on your website. With just a few clicks, you can add a stylish and interactive pop-up form to any page, attracting user attention and boosting conversions. The form appears in a pop-up window, allowing visitors to interact with it without leaving the page they're on. This feature is perfect for capturing leads, collecting feedback, or running surveys, all while providing a great user experience.
        </p>
      </div>
      <div className="screens popup">
        <div className="mobail">
          <span>
            <span className="btn" style={{ "background-color": "#BE1EB9" }}>
              Start
            </span>
          </span>
        </div>
        <div className="desktop">
          <span>
            <span className="btn" style={{ "background-color": "#BE1EB9" }}>
              Start
            </span>
          </span>
        </div>
      </div>
      <div className="style">
        <div className="sideStayle bannerStyle d-flex align-items-start flex-column">
          <h3>Style:</h3>
          <div className="field">
            <label>Button text:</label>
            <input
              type="text"
              value={btnText}
              placeholder="Start"
              onChange={(e) => setBtnText(e.target.value)}
            />
          </div>
          <div className="field">
            <label>Size:</label>
            <input
              type="number"
              placeholder={btnSize || 0 + "%"}
              value={btnSize}
              min={1}
              max={100}
              onChange={(e) => setbtnSize(e.target.value)}
            />
          </div>
          <div className="field" style={{ width: "100%" }}>
            <label>Button color:</label>
            <ColorPicker
              setRight="inherit"
              color={btnBgColor}
              className="colorPiker"
              handleChange={(e) => editBackgroundColors(e, setBtnBgColor)}
            />

            <label>Text color:</label>
            <ColorPicker
              setRight="inherit"
              color={btnColor}
              className="colorPiker"
              handleChange={(e) => editBackgroundColors(e, setBtnColor)}
            />
          </div>
        </div>
        <div>
          <h3>Code:</h3>
          <textarea disabled value={embedCode}></textarea>
        </div>
      </div>
      <a
        title=""
        className="copy pointer"
        onClick={() => copyToClip(embedCode)}
      >
        Copy
      </a>
    </div>
  );
}

export default PopUp;
