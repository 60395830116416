import React, { useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import OtherContactInfoModal from "./OtherContactInfo";
import { connect } from "react-redux";
import { getQuizAnalyzeTable } from "../../../redux/actions";
import ExcelExport from "./ExcelExport";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination
  );

  return (
    <div>
      <table
        border="1px"
        className="table  table-striped table-borderless border-0"
        style={{ width: "100%" }}
        {...getTableProps()}
      >
        <thead className="thead-light">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers
                .filter(
                  (el) =>
                    el.id !== "create_at_placeholder_0" &&
                    el.Header !== "Visit Details"
                )
                .map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={i} {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  let toShow = "";
                  if (cell.column.id === "other")
                    toShow = (
                      <div>
                        <OtherContactInfoModal
                          id={`other${i}`}
                          data={cell.value}
                        />
                        <button
                          data-toggle="modal"
                          data-target={`#other${i}`}
                          className="btn btn-light"
                        >
                          🔍
                        </button>
                      </div>
                    );
                  else toShow = typeof cell.value !== null ? cell.value : "";
                  return <td key={index}>{toShow}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />
      <div className="pagination d-flex justify-content-center">
        <button
          className="btn btn-secondary"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          {"<<"}
        </button>{" "}
        <button
          className="btn btn-secondary"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {"<"}
        </button>{" "}
        <button
          className="btn btn-secondary"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {">"}
        </button>{" "}
        <button
          className="btn btn-secondary"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          {">>"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            className="form-control d-inline"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
          className="custom-select w-25"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>{" "}
    </div>
  );
}

class VisitsTable extends React.Component {
  componentDidMount() {
    const { id, published } = this.props.data;
    const {
      limitRows,
      quizAnalyzeStats,
      quizAnalyze,
      additionalFilters,
    } = this.props;

    // this.props.actions.getQuizAnalyzeTable({ id });
  }

  render() {
    let { quizAnalyze, tableItems } = this.props;

    if (!quizAnalyze.length) return <></>;

    // if (!tableItems) tableItems = [];
    // else tableItems = Object.values(tableItems);
    const columns = [
      {
        Header: "Date",
        accessor: "create_at",
      },
      {
        Header: "Visit Details",
        columns: [
          {
            Header: "Questions answered",
            accessor: "number_of_answers",
          },
          {
            Header: "Result",
            accessor: "result",
          },
          {
            Header: "Score",
            accessor: "score",
          },
          {
            Header: "Browser",
            accessor: "browser",
          },
          {
            Header: "Email",
            accessor: "email",
          },
          {
            Header: "First Name",
            accessor: "firstName",
          },
          {
            Header: "Last Name",
            accessor: "lastName",
          },
          {
            Header: "Phone Number",
            accessor: "phoneNumber",
          },
        ],
      },
    ];
    // const exportColumns = JSON.parse(JSON.stringify(columns));

    // Object.keys(this.props.quizAnalyze[0].meta).forEach((el) => {
    //   exportColumns[1].columns.push({
    //     Header: el,
    //     accessor: el,
    //   });
    // });

    // if (tableItems.length)
    //   columns.push({
    //     Header: "Contact Details",
    //     columns: [
    //       ...tableItems
    //         .filter((itr) => itr)
    //         .map((itr) => {
    //           return {
    //             Header: itr,
    //             accessor: itr,
    //           };
    //         }),
    //     ],
    //   });

    return (
      <div className="tab-content">
        <div className="d-flex justify-content-between">
          <h3>
            {" "}
            <strong>Visit detailes:</strong>
          </h3>
          <div className="">
            <ExcelExport
            // columns={exportColumns}
            // data={this.props.quizAnalyze}
            />
          </div>
        </div>
        <Table columns={columns} data={this.props.quizAnalyze} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { quizAnalyze, excelDataPath } = state;
  return { quizAnalyze, excelDataPath };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      getQuizAnalyzeTable: (quizDetails) =>
        dispatch(getQuizAnalyzeTable(quizDetails)),
      // downloadExcell: (quizDetails) => dispatch(downloadExcellAction(quizDetails)),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VisitsTable);
