import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Dropdown } from "react-bootstrap";
import { withTranslation } from "react-i18next";
function RenameStep(props) {
  const { id, t, slideName = "", changeSlideName, isOpen, closeModal } = props;
  const [tempSlideName, setTempSlideName] = useState(slideName);

  useEffect(() => {
    setTempSlideName(slideName);
  }, [id]);

  const handleClick = () => {
    if (!tempSlideName) return;
    changeSlideName(
      { target: { value: tempSlideName, name: "slideName" } },
      id
    );
    closeModal();
  };

  return (
    <>
      <Modal show={isOpen} onHide={() => closeModal()} centered>
        <div className="rename-slide-modal">
          <h2>Add new page</h2>
          <input
            type="text"
            className="form-control"
            value={tempSlideName}
            onChange={(e) => setTempSlideName(e.target.value)}
          />
          <button
            disabled={!tempSlideName}
            className=" btn confirm-rename-btn"
            onClick={handleClick}
          >
            Continue
          </button>
        </div>
      </Modal>
    </>
  );
}

export default withTranslation()(RenameStep);
