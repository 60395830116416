import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ReactSelect from "react-select";
import { handleLiquid } from "../../../utils";
import { getDefaultCountriesOptions } from "../../..//utils/CountriesOptions";

function SelectInput(props) {
  let {
    color = "#000000",
    minimalStyle,
    defaultValue,
    isPreview,
    placeholder,
    name,
    isAutoComplete,
    selectedOptionsMapped,
    isRtl,
    fontSize,
    height,
    inputId,
    isMultiSelect,
    inputStyle,
    errorMessage,
    inputValid,
    isValid,
    minimalDateClass,
    data,
    required,
    handlevalidationInputChanges,
    dataWithVariables,
    countriesOptions,
    isCountriesSelect,
    countriesValueType,
    statesInputId,
  } = props;
  const [ColorComp, setColorComp] = useState(styled.div``);
  const [isSelected, setIsSelected] = useState("");
  const [defaultVal, setDefaultVal] = useState(defaultValue);
  const [parsedOptions, setParsedOptions] = useState([]);

  useEffect(() => {
    if (defaultValue && dataWithVariables) {
      handlevalidationInputChanges(
        handleLiquid(defaultValue, dataWithVariables),
        inputId
      );
    }
    setDefaultVal(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    const StyledDateInput = minimalStyle
      ? styled.div`
          > div {
            color: ${color.slice(0, color.lastIndexOf(",")).replace(/,/g, "") +
            ") "};
            box-shadow: ${color
              .slice(0, color.lastIndexOf(","))
              .replace(/,/g, "") + " /30%) 0px 1px !important"};
          }
          > div div div {
            color: ${color.slice(0, color.lastIndexOf(",")).replace(/,/g, "") +
            ") "};
          }
          > div .css-11l0ves-control div {
            color: ${color.slice(0, color.lastIndexOf(",")).replace(/,/g, "") +
            ") "};
            opacity: 0.5;
          }
          > div .css-13cymwt-control div {
            color: ${color.slice(0, color.lastIndexOf(",")).replace(/,/g, "") +
            ") "};
            opacity: 0.5;
          }

          > div .css-17nyuw-control div {
            color: ${color.slice(0, color.lastIndexOf(",")).replace(/,/g, "") +
            ") "};
            opacity: 0.5;
          }
        `
      : styled.div``;
    setColorComp(StyledDateInput);
  }, [color, minimalStyle]);

  useEffect(() => {
    if (isCountriesSelect) {
      countriesOptions = getDefaultCountriesOptions(
        countriesValueType,
        countriesOptions
      );
      //add default country for case when back button in view;
      if (
        data.contacts &&
        data.contacts.other &&
        data.contacts.other[inputId]
      ) {
        const savedValue = data.contacts.other[inputId].value;
        if (savedValue) {
          setDefaultVal(
            countriesOptions.find((itr) => itr.value == savedValue)
          );
        }
      }
      return setParsedOptions(countriesOptions);
    }
    if (data.contacts && data.contacts.other && data.contacts.other[inputId]) {
      const value = selectedOptionsMapped
        .map((el) => {
          return { label: el.name, value: el.name };
        })
        .find((el) => el.value === data.contacts.other[inputId].value);
      setDefaultVal(value || null);
    }
    if (isPreview) {
      const tempValue = [];
      if (selectedOptionsMapped && selectedOptionsMapped.length > 0) {
        selectedOptionsMapped.forEach((el) => {
          if (
            el.name.includes("{{") &&
            el.name.includes("}}") &&
            el.name.includes(".")
          ) {
            const elArray = el.name
              .replace("{{", "")
              .replace("}}", "")
              .split(".");
            const jsonName = elArray.shift();
            const foundValue = data.variables.find(
              (el) => el.name === jsonName
            );
            if (foundValue && foundValue.type === "json") {
              const varBody = JSON.parse(foundValue.value);
              const jsonItirate = eval(`varBody.${elArray.join(".")}`);
              if (jsonItirate) {
                jsonItirate.forEach((l) => {
                  tempValue.push({ name: l });
                });
              } else {
                tempValue.push({ name: el.name });
              }
            } else {
              tempValue.push({ name: el.name });
            }
          } else {
            tempValue.push({ name: el.name });
          }
        });
      }
      setParsedOptions(tempValue);
    }
  }, [data, inputId]);

  return (
    <ColorComp>
      {isPreview ? (
        <>
          <ReactSelect
            isMulti={isMultiSelect}
            placeholder={handleLiquid(placeholder, dataWithVariables)}
            name={name}
            isSearchable={isAutoComplete}
            isRtl={isRtl}
            options={
              isCountriesSelect
                ? parsedOptions
                : parsedOptions.map((el) => {
                    return {
                      label: handleLiquid(el.name, dataWithVariables),
                      value: handleLiquid(el.name, dataWithVariables),
                    };
                  })
            }
            // {...inputStyle}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                margin: "auto",
                maxWidth: "100%",
                fontSize: fontSize,
                height: height,
                border: !minimalStyle ? "1px solid #78889f" : "",
                borderRadius: !minimalStyle ? "7px" : "",
              }),
              option: (styles, { isFocused, isSelected }) => ({
                ...styles,

                color: "#000 !important",
              }),

              multiValueLabel: (styles, { data }) => ({
                ...styles,
                backgroundColor: minimalStyle ? "#fff !important" : "inherit",
                color: "#000 !important",
                opacity: "0.8 !important",
              }),
              multiValueRemove: (styles, { data }) => ({
                ...styles,
                backgroundColor: minimalStyle ? "#fff !important" : "inherit",
                color: "#000 !important",
                opacity: "0.8 !important",
              }),
              multiValue: (styles, { data }) => ({
                ...styles,
                backgroundColor: minimalStyle ? "#fff !important" : "inherit",
                color: "#000 !important",
                opacity: "0.8 !important",
              }),
            }}
            className={
              !isRtl
                ? `react-select-form ${minimalDateClass + " " + isSelected}`
                : `${minimalDateClass} ${isSelected}`
            }
            defaultValue={defaultVal}
            onInvalid={(e) => e.target.setCustomValidity(errorMessage)}
            onChange={(value) => {
              if (value && value.value) setIsSelected("minimalselected");
              handlevalidationInputChanges(
                isMultiSelect
                  ? {
                      label: handleLiquid(value, dataWithVariables),
                      value: handleLiquid(value, dataWithVariables),
                    }
                  : handleLiquid(value.value, dataWithVariables),
                inputId
              );
              if (statesInputId) {
                handlevalidationInputChanges(
                  isMultiSelect
                    ? {
                        label: "",
                        value: "",
                      }
                    : "",
                  statesInputId
                );
              }
            }}
          />
          {inputValid || isValid ? (
            <></>
          ) : (
            <span style={{ color: "red" }} className="req-error">
              {errorMessage}
            </span>
          )}
        </>
      ) : (
        <>
          <ReactSelect
            isMulti={isMultiSelect}
            name={name}
            value={defaultVal}
            placeholder={placeholder}
            defaultValue={defaultVal}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                margin: "auto",
                maxWidth: "100%",
                fontSize: fontSize,
                height: height,
                border: !minimalStyle ? "1px solid #78889f" : "none",
                borderRadius: !minimalStyle ? "7px" : "none",
              }),
              option: (styles, { isFocused, isSelected }) => ({
                ...styles,
                color: isSelected ? "#000 !important" : "#000 !important",
              }),

              multiValueLabel: (styles, { data }) => ({
                ...styles,
                backgroundColor: minimalStyle ? "#fff !important" : "inherit",
                color: "#000 !important",
                opacity: "0.8 !important",
              }),
              multiValueRemove: (styles, { data }) => ({
                ...styles,
                backgroundColor: minimalStyle ? "#fff !important" : "inherit",
                color: "#000 !important",
                opacity: "0.8 !important",
              }),
              multiValue: (styles, { data }) => ({
                ...styles,
                backgroundColor: minimalStyle ? "#fff !important" : "inherit",
                color: "#000 !important",
                opacity: "0.8 !important",
              }),
            }}
            className={
              !isRtl
                ? `react-select-form ${minimalDateClass + " " + isSelected}`
                : `${minimalDateClass} ${isSelected}`
            }
            isSearchable={isAutoComplete}
            isRtl={isRtl}
            id={inputId}
            onChange={(value) => {
              if (value && value.value) setIsSelected("minimalselected");
            }}
            options={
              isCountriesSelect
                ? parsedOptions
                : selectedOptionsMapped.map((el) => {
                    return { label: el.name, value: el.name };
                  })
            }
            required={required}
          />
        </>
      )}
    </ColorComp>
  );
}

export default SelectInput;
