import React from "react";
import Pagination from "../Pagination";
import validator from "validator";
import { connect } from "react-redux";
import { changeRightBarView, saveAnswer } from "../../../redux/actions";
import ToggleInputAndText from "../../toggleInputAndText";
import ChooseImageModal from "../ChooseImageModal";
import "react-phone-number-input/style.css";
import PhoneInput, {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import PhoneNumberInput from "./PhoneNumberInput";
import { handleLiquid, stripTags } from "../../../utils";
import { css } from "glamor";
import { IoTrashOutline } from "react-icons/io5";
import DateInput from "./DateInput";
import InputValidationConstans from "../../../utils/json/inputValidationConstans.json";
import { countDecimals } from "../../../utils";
import SelectInput from "./SelectInput";

import AsyncSelectInput from "./AsyncSelectInput";

import SignatureInput from "./SignatureInput";
import InputAndTextArea from "./InputAndTextArea";

import FileInput from "./FileInput";

import SliderInput from "./SliderInput";

class ViewContact extends React.Component {
  state = {
    type: "text",
    name: "",
    value: "",
    placeholder: "",
    label: "",
    required: false,
    isValid: true,
    styleObjct: {},
    dataWithVariables: {},
    inputs: [],
  };

  componentDidMount() {
    let { inputs } = this.props.onDraw;
    inputs = inputs.reduce((allInputs, current) => {
      const { id, type, inputs = [] } = current;
      if (type !== "location") return [...allInputs, current];
      inputs.forEach((itr) => {
        if (!itr.show) return;
        itr.parentLabel = current.label;
        itr.validationType = "other";
        if (itr.type == "Country" && itr.defaultCountry)
          itr.value = itr.defaultCountry.value;
        allInputs.push(itr);
      });
      return allInputs;
    }, []);
    if (!this.props.isPreview) return this.setState({ inputs });
    window.addEventListener("keypress", this.enterContinue);
    const { contacts } = this.props.data;
    const other = {};
    const validateInputs = inputs.reduce((obj, current, index) => {
      const {
        id,
        validationType,
        required,
        type,
        defaultChecked = false,
        defaultCountry,
        useDefaultValue = false,
        defaultValue = "",
        withValidation,
      } = current;
      let value = "";
      if (useDefaultValue && defaultValue) {
        if (
          validationType === "email" ||
          validationType === "phoneNumber" ||
          (validationType == "other" && type == "email") ||
          (validationType == "other" && type == "url") ||
          (validationType == "other" && withValidation)
        ) {
          const parsedValue = this.handleInputLabel(
            defaultValue,
            inputs,
            index,
            this.handleSpecialMessage(this.props.data, false)
          );
          if (this.checkValidation({ ...current, value: parsedValue })) {
            value = parsedValue;
          }
        } else {
          value = defaultValue;
        }
      }
      if (validationType === "other") {
        if (type == "checkbox") value = defaultChecked;
        if (type == "Country" && defaultCountry) value = defaultCountry.value;
        other[id] = {
          ...current,
          isValid: this.checkValidation(current),
          value,
        };
        return obj;
      }

      return (obj = {
        ...obj,
        [id]: {
          ...current,
          type: validationType || type,
          isValid: this.checkValidation(current),
          value,
        },
      });
    }, {});
    validateInputs.other = other;
    Object.entries(validateInputs).forEach(([k, v]) => {
      if (k === "other") {
        Object.entries(v).forEach(([ok, ov]) => {
          if (!ov.value && contacts.other && contacts.other[ov.id])
            ov.value = contacts.other[ov.id].value;
        });
      } else {
        if (!v.value && contacts[v.id]) v.value = contacts[v.id].value;
      }
    });
    this.setState({
      validateInputs,
      dataWithVariables: this.handleSpecialMessage(this.props.data),
      inputs,
    });
  }

  componentWillUnmount() {
    window.removeEventListener("keypress", this.enterContinue);
  }

  handleSpecialMessage = (data, toLowerCase = true) => {
    let { variables = [], answers = [], contacts = {}, score = 0 } = data;
    variables = variables.map((variable) => {
      return { [variable.name]: variable.value };
    });
    answers = answers.map((answer) => {
      let { arrayOfResult = [], questionId } = answer;
      let value = arrayOfResult.map((itr) => itr.value).join(", ");

      return { [questionId.replaceAll(".", "__")]: value };
    });

    contacts = Object.entries(contacts)
      .filter(([k, v]) => k != "other")
      .map(([k, v]) => {
        let inputKey = v.label;
        if (toLowerCase) inputKey.toLowerCase();
        return { [inputKey.replaceAll(" ", "")]: v.value };
      });

    variables = Object.assign({}, ...variables, ...answers, ...contacts, {
      score,
    });
    return variables;
  };

  handleInputLabel = (
    label,
    inputs,
    index,
    dataWithVariablesFromComponentDidMount
  ) => {
    inputs = Object.values(inputs) ? Object.values(inputs) : [];
    if (!label || !label.trim())
      return `Item-${inputs[index].type}-${index + 1}`;
    return handleLiquid(
      label,
      dataWithVariablesFromComponentDidMount || this.state.dataWithVariables
    );
  };

  handlevalidationInputChanges = (value, id, isFileError = false) => {
    let { validateInputs } = this.state;
    if (!validateInputs) return;
    if (!this.props.isPreview) return;
    if (!validateInputs[id] && validateInputs.other[id]) {
      validateInputs.other[id].value =
        validateInputs.other[id].required && typeof value === "string"
          ? value.trim()
          : value;
      validateInputs.other[id].isValid = this.checkValidation(
        validateInputs.other[id],
        isFileError
      );
    } else if (validateInputs[id] && validateInputs[id].type == "text") {
      validateInputs.other[id] = validateInputs[id];
      validateInputs.other[id].value =
        validateInputs.other[id].required && typeof value === "string"
          ? value.trim()
          : value;
      validateInputs.other[id].isValid = this.checkValidation(
        validateInputs.other[id]
      );
      delete validateInputs[id];
    } else {
      validateInputs[id].value =
        validateInputs[id].required && typeof value === "string"
          ? value.trim()
          : value;
      validateInputs[id].isValid = this.checkValidation(validateInputs[id]);
    }
    return this.setState({ validateInputs });
  };

  handleFormAlignment = (formAlign) => {
    const { direction } = this.props;
    if (formAlign == "center") {
      return formAlign;
    } else if (formAlign == "right") {
      return direction == "rtl" ? "start" : "end";
    } else if (formAlign == "left") {
      return direction == "rtl" ? "end" : "start";
    }
  };

  send = (e) => {
    const { isPreview, isPreviewDesignMode } = this.props;
    // e.preventDefault()
    if (!isPreview || isPreviewDesignMode) return;
    const { validateInputs } = this.state;
    const { contacts } = this.props.data;
    const { stepIndex } = this.props;
    let { id, type, label } = this.props.onDraw;

    const checkValidation = Object.values(validateInputs)
      .map((el) => el.isValid)
      .find((input) => !input);
    // handle exeption of checkbox inputs with required and default checked
    Object.values(validateInputs.other).forEach((el) => {
      if (el.type == "checkbox" && el.required && el.defaultChecked) {
        el.isValid = el.value ? true : false;
      }
    });
    //---------------------------------------------
    const checkValidationOther = Object.values(validateInputs.other)
      .map((el) => el.isValid)
      .find((input) => !input);
    const isValid =
      checkValidation === false || checkValidationOther === false
        ? false
        : true;
    this.setState({ isValid });
    if (isValid) {
      this.props.saveContact(id, validateInputs);
      const formDetails = Object.assign([], Object.values(validateInputs))
        .map((input) => {
          if (input.type && input.type !== "select" && input.type !== "date")
            return { type: input.type, value: input.value, label: input.label };
          if (input.type === "select" || input.type === "date") {
            validateInputs.other[input.id] = input;
            return null;
          }
        })
        .filter((input) => input);
      if (validateInputs.other) {
        formDetails.push({
          type: "other",
          value: JSON.stringify(
            Object.values(Object.assign({}, validateInputs.other)).map(
              (other) => {
                if (other.parentLabel) {
                  other.label = `${other.parentLabel}_${other.label}`;
                }
                // if (other.type == "file" && other.value) {
                //   const tempValue = Object.assign([], other.value);
                //   console.log(tempValue)
                //   other.value = tempValue.map(file => file.savedPath)
                //   console.log(validateInputs.other)
                // }
                return {
                  type: other.label,
                  value:
                    other.type == "file"
                      ? Object.assign([], other.value).map(
                          (file) => file.savedPath
                        )
                      : other.value,
                  input_id: `${other.id}__${stepIndex}`,
                  isMultiSelect: other.isMultiSelect,
                  isSignature: other.isSignature,
                  isFile: other.isFile,
                };
              }
            )
          ),
        });
      }

      this.props.actions.saveContact({
        questionId: id,
        quizId: this.props.data.id,
        formDetails,
        type,
      });
    }
  };

  enterContinue = (e) => {
    const key = e.which;
    if (e.target && e.target.tagName === "TEXTAREA") return;
    if (key === 13) {
      this.send({});
    }
  };

  checkValidation = (input, fileError = false) => {
    let {
      value,
      type,
      required,
      withValidation = false,
      minimum,
      minimumCharacters,
      maximum,
      maximumCharacters,
      maximumDecimals,
      isValidationError = false,
    } = input;

    if (!value && !required) return true;
    if (!value && required) return false;
    if (type === "email") {
      if (!validator.isEmail(value)) return false;
    } else if (type === "phoneNumber") {
      if (!isPossiblePhoneNumber(value)) return false;
    } else if (type === "url") {
      if (!/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(value)) return false;
    } else if (type === "Postal/Zip Code") {
      if (!/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(value)) return false;
    }
    // switch (type) {
    //   case "email":
    //     if (!validator.isEmail(value)) return false;
    //   // return true
    //   case "phoneNumber":
    //     if (!isPossiblePhoneNumber(value)) return false;

    //   case "url":
    //     if (!/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(value)) return false;
    //   case "Postal/Zip Code":
    //     if (!/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(value)) return false;
    //   // }
    //   // default:
    // }

    if (withValidation && !isValidationError) {
      if (!maximumCharacters) {
        maximumCharacters =
          InputValidationConstans.maximumCharacters[type] || 0;
      }
      if (
        minimumCharacters &&
        value.length < parseInt(minimumCharacters || "0")
      )
        return false;
      if (
        maximumCharacters &&
        value.length > parseInt(maximumCharacters || "0")
      )
        return false;
      if (
        minimum &&
        type == "number" &&
        parseInt(value || "0") < parseInt(minimum || "0")
      ) {
        return false;
      }
      if (
        maximum &&
        type == "number" &&
        parseInt(value || "0") > parseInt(maximum || "0")
      )
        return false;
      if (
        maximumDecimals &&
        type == "number" &&
        countDecimals(value) > parseInt(maximumDecimals || "0")
      )
        return false;
    }

    if (type === "file") {
      if (fileError) return false;
      if (required && (!value || !value.length)) return false;
    }
    return true;
  };

  changeFieldRequired = (inputId, isRequired) => {
    // This function handle the situation when we search states for country that dosent have any states and we get an empty array as result
    // is that case we turn the states input to an unrequired input and reset the isValid prop.
    const { validateInputs } = this.state;
    validateInputs.other[inputId].required = isRequired;
    validateInputs.other[inputId].isValid = isRequired ? false : true;
    this.setState({ validateInputs });
  };

  checkIfPreviewAndRenderInput = (
    input,
    inputStyle,
    selectStyle,
    styleObject,
    allInputs
  ) => {
    const { isValid, validateInputs } = this.state;
    const { minimalStyle, color } = styleObject;
    const {
      id: inputId,
      name,
      type,
      placeholder,
      label,
      required,
      errorMessage,
      defaultCountry,
      isTextarea,
      validationType,
      defaultChecked,
      isRtl, //Select type
      isMultiSelect, //Select type
      isAutoComplete, //Select type
      hideCountryList,
      width = "100%",
      textareaNumberOfRows = 2,
    } = input;
    let inputValid = false;
    if (validateInputs) {
      inputValid = validateInputs.other[inputId]
        ? validateInputs.other[inputId].isValid
        : validateInputs[inputId].isValid;
    }
    const defaultHeight = 44;
    const defaultFontSize = 16;
    const { accessibility } = this.props;

    const fontSize = `${defaultFontSize + accessibility.fontSize}px`;
    const height = `${defaultHeight + accessibility.fontSize}px`;

    const minimalDateClass = minimalStyle ? "minimaldate" : "";
    if (type === "phoneNumber") {
      if (this.props.isPreview) {
        let defaultValue = "";

        if (this.props.data.contacts && this.props.data.contacts[inputId]) {
          defaultValue = this.props.data.contacts[inputId].value;
        } else if (
          this.props.data.contacts.other &&
          this.props.data.contacts.other[inputId]
        ) {
          defaultValue = this.props.data.contacts.other[inputId].value;
        } else if (validateInputs && validateInputs[inputId]) {
          defaultValue = validateInputs[inputId].value || "";
        } else if (
          validateInputs &&
          validateInputs.other &&
          validateInputs.other[inputId]
        ) {
          defaultValue = validateInputs.other[inputId].value || "";
        }
        return (
          <>
            <PhoneNumberInput
              placeholder={handleLiquid(
                placeholder,
                this.state.dataWithVariables
              )}
              hideCountryList={hideCountryList}
              defaultCountry={defaultCountry}
              minimalStyle={minimalStyle}
              change={this.handlevalidationInputChanges}
              defaultValue={defaultValue}
              inputStyle={inputStyle}
              currInput={inputId}
              isPreview={this.props.isPreview}
              dataWithVariables={this.state.dataWithVariables}
            />{" "}
            {inputValid || isValid ? (
              <></>
            ) : (
              <span style={{ color: "red" }} className="req-error">
                {errorMessage || "Invalid phone number"}
              </span>
            )}
          </>
        );
      } else {
        return (
          <PhoneNumberInput
            minimalStyle={minimalStyle}
            hideCountryList={hideCountryList}
            placeholder={handleLiquid(
              placeholder,
              this.state.dataWithVariables
            )}
            defaultCountry={defaultCountry}
            change={this.handlevalidationInputChanges}
            currInput={inputId}
            inputStyle={inputStyle}
            isPreview={this.props.isPreview}
          />
        );
      }
    }

    if (type === "select") {
      const defaultValue = "";
      const defaultOption = input.placeholder || "Select";
      const selectedOptionsMapped = input.selectOptions
        ? input.selectOptions
        : [];

      return (
        <>
          <SelectInput
            isPreview={this.props.isPreview}
            color={color}
            minimalStyle={minimalStyle}
            defaultValue={defaultValue}
            placeholder={placeholder}
            name={name}
            isAutoComplete={isAutoComplete}
            selectedOptionsMapped={selectedOptionsMapped}
            isRtl={isRtl}
            fontSize={fontSize}
            height={height}
            inputId={inputId}
            isMultiSelect={isMultiSelect}
            inputStyle={inputStyle}
            errorMessage={errorMessage}
            inputValid={inputValid}
            isValid={isValid}
            minimalDateClass={minimalDateClass}
            required={required}
            data={this.props.data}
            handlevalidationInputChanges={this.handlevalidationInputChanges}
            dataWithVariables={this.state.dataWithVariables}
          />
        </>
      );
    }
    if (type === "Country") {
      const defaultOption = input.placeholder || "Select";
      const selectedOptionsMapped = input.selectOptions
        ? input.selectOptions
        : [];
      let statesInputId = "";
      if (this.props.isPreview && validateInputs) {
        const stateInput = Object.values(validateInputs.other).find(
          (itr) =>
            itr.parentLabel == input.parentLabel &&
            itr.type == "State/Region" &&
            itr.show &&
            itr.useApiCalls
        );
        if (stateInput) statesInputId = stateInput.id;
      }
      return (
        <>
          <SelectInput
            isPreview={this.props.isPreview}
            color={color}
            minimalStyle={minimalStyle}
            defaultValue={defaultCountry}
            placeholder={placeholder}
            name={name}
            isAutoComplete={isAutoComplete}
            selectedOptionsMapped={selectedOptionsMapped}
            isRtl={isRtl}
            fontSize={fontSize}
            height={height}
            inputId={inputId}
            isMultiSelect={isMultiSelect}
            inputStyle={inputStyle}
            errorMessage={errorMessage}
            inputValid={inputValid}
            isValid={isValid}
            minimalDateClass={minimalDateClass}
            required={required}
            data={this.props.data}
            handlevalidationInputChanges={this.handlevalidationInputChanges}
            countriesOptions={
              input.limitCountries ? input.countriesList : undefined
            }
            isCountriesSelect={true}
            countriesValueType={input.valueType}
            statesInputId={statesInputId}
          />
        </>
      );
    }
    if (type === "State/Region" && input.useApiCalls) {
      const defaultValue = "";
      let value = "";
      const selectedOptionsMapped = input.selectOptions
        ? input.selectOptions
        : [];
      let countryCode = "";

      if (this.props.isPreview && validateInputs) {
        const countryInput = Object.values(validateInputs.other).find(
          (itr) => itr.parentLabel == input.parentLabel && itr.type == "Country"
        );
        countryCode = countryInput.value;
        if (!countryCode)
          countryCode = countryInput.defaultCountry
            ? countryInput.defaultCountry.value
            : "";
        const statesInput = Object.values(validateInputs.other).find(
          (itr) => itr.parentLabel == input.parentLabel && itr.type == type
        );
        if (statesInput)
          value = { label: statesInput.value, value: statesInput.value };
      } else if (!this.props.isPreview) {
        const countryInput = allInputs.find(
          (itr) => itr.parentLabel == input.parentLabel && itr.type == "Country"
        );
        if (countryInput) {
          countryCode = countryInput.defaultCountry
            ? countryInput.defaultCountry.value
            : "";
        }
      }
      return (
        <AsyncSelectInput
          isPreview={this.props.isPreview}
          color={color}
          minimalStyle={minimalStyle}
          defaultValue={defaultValue}
          value={value}
          placeholder={placeholder}
          name={name}
          isAutoComplete={isAutoComplete}
          selectedOptionsMapped={selectedOptionsMapped}
          isRtl={isRtl}
          fontSize={fontSize}
          height={height}
          inputId={inputId}
          isMultiSelect={isMultiSelect}
          inputStyle={inputStyle}
          errorMessage={errorMessage}
          inputValid={inputValid}
          isValid={isValid}
          minimalDateClass={minimalDateClass}
          required={required}
          data={this.props.data}
          handlevalidationInputChanges={this.handlevalidationInputChanges}
          dataWithVariables={this.state.dataWithVariables}
          countryCode={countryCode}
          changeFieldRequired={this.changeFieldRequired}
        />
      );
    }

    if (type === "date") {
      if (this.props.isPreview) {
        return (
          <>
            <DateInput
              change={this.handlevalidationInputChanges}
              currInput={inputId}
              isPreview={this.props.isPreview}
              input={input}
              minimalStyle={minimalStyle}
              inputStyle={inputStyle}
              errorMessage={errorMessage}
              inputValid={inputValid}
              isValid={isValid}
              color={color}
              defaultValue={
                this.props.data.contacts.other &&
                this.props.data.contacts.other[inputId] &&
                this.props.data.contacts.other[inputId].value
                  ? this.props.data.contacts.other[inputId].value
                  : ""
              }
            />
          </>
        );
      } else {
        return (
          <>
            <DateInput
              change={this.handlevalidationInputChanges}
              currInput={inputId}
              color={color}
              minimalStyle={minimalStyle}
              isPreview={this.props.isPreview}
              input={input}
              inputStyle={inputStyle}
            />
          </>
        );
      }
    }

    if (type === "slider") {
      if (this.props.isPreview) {
        return (
          <>
            <SliderInput
              changeSlider={this.handlevalidationInputChanges}
              currInput={inputId}
              isPreview={this.props.isPreview}
              input={input}
              errorMessage={errorMessage}
              defaultValue={
                this.props.data.contacts.other &&
                this.props.data.contacts.other[inputId] &&
                this.props.data.contacts.other[inputId].value
                  ? this.props.data.contacts.other[inputId].value
                  : ""
              }
            />
          </>
        );
      } else {
        return (
          <>
            <SliderInput
              changeSlider={this.handlevalidationInputChanges}
              currInput={inputId}
              isPreview={this.props.isPreview}
              input={input}
            />
          </>
        );
      }
    }

    if (type === "checkbox") {
      if (this.props.isPreview) {
        const checkBoxValue =
          this.props.data.contacts.other &&
          this.props.data.contacts.other[inputId]
            ? this.props.data.contacts.other[inputId].value
            : defaultChecked;
        return (
          <>
            <div className="d-flex ">
              <input
                id={inputId}
                type={type}
                name={name}
                // hide default value until we fix duplication contact ID issue
                style={{
                  fontSize,
                  height,
                  width: "1.2em",
                  marginRight: "10px",
                }}
                // defaultValue={defaultValue}
                defaultChecked={checkBoxValue}
                required={required}
                onInvalid={(e) => e.target.setCustomValidity(errorMessage)}
                onChange={(e) =>
                  this.handlevalidationInputChanges(
                    e.target.checked ? true : null,
                    inputId
                  )
                }
              />
              {inputValid || isValid ? (
                <></>
              ) : (
                <span style={{ color: "red" }} className="req-error">
                  {errorMessage}
                </span>
              )}

              <label className="align-self-center checbox-description">
                {handleLiquid(placeholder, this.state.dataWithVariables)}
              </label>
            </div>
          </>
        );
      } else {
        return (
          <div className="d-flex ">
            <input
              style={{ width: "1.2em", marginRight: "10px" }}
              id={inputId}
              type={type}
              name={name}
              checked={defaultChecked}
              placeholder={placeholder}
              className=""
              required={required}
              width={width}
            />
            <label className="align-self-center checbox-description">
              {placeholder}
            </label>
          </div>
        );
      }
    }

    if (type === "signature") {
      let defaultValue = "";
      if (this.props.isPreview) {
        if (this.props.data.contacts && this.props.data.contacts[inputId]) {
          defaultValue = this.props.data.contacts[inputId].value;
        } else if (
          this.props.data.contacts.other &&
          this.props.data.contacts.other[inputId]
        ) {
          defaultValue = this.props.data.contacts.other[inputId].value;
        }
      }
      return (
        <>
          <SignatureInput
            isPreview={this.props.isPreview}
            color={color}
            minimalStyle={minimalStyle}
            defaultValue={defaultValue}
            placeholder={placeholder}
            name={name}
            isRtl={isRtl}
            fontSize={fontSize}
            height={height}
            inputId={inputId}
            isMultiSelect={isMultiSelect}
            inputStyle={inputStyle}
            errorMessage={errorMessage}
            inputValid={inputValid}
            isValid={isValid}
            required={required}
            data={this.props.data}
            handlevalidationInputChanges={this.handlevalidationInputChanges}
            width={width}
            isMobile={this.props.isMobile}
            isMobilePreview={this.props.isMobilePreview}
          />
        </>
      );
    }

    if (type === "file") {
      let defaultValue = "";

      if (this.props.isPreview) {
        if (this.props.data.contacts && this.props.data.contacts[inputId]) {
          defaultValue = this.props.data.contacts[inputId].value;
        } else if (
          this.props.data.contacts.other &&
          this.props.data.contacts.other[inputId]
        ) {
          defaultValue = this.props.data.contacts.other[inputId].value;
        }
      }
      return (
        <>
          <FileInput
            isPreview={this.props.isPreview}
            color={color}
            minimalStyle={minimalStyle}
            defaultValue={defaultValue}
            placeholder={placeholder}
            name={name}
            isRtl={isRtl}
            inputId={inputId}
            inputStyle={inputStyle}
            errorMessage={errorMessage}
            inputValid={inputValid}
            isValid={isValid}
            required={required}
            handlevalidationInputChanges={this.handlevalidationInputChanges}
            width={width}
            isMobile={this.props.isMobile}
            isMobilePreview={this.props.isMobilePreview}
            maxNumberOfFiles={input.maxNumberOfFiles}
            maxFileErrorMessage={input.maxFileErrorMessage}
            maxFileSize={input.maxFileSize}
            minFileSize={input.minFileSize}
            customFileSize={input.customFileSize}
            customFileTypes={input.customFileTypes}
            fileTypeErrorMessage={input.fileTypeErrorMessage}
            fileType={input.fileType}
            predifinedFileType={input.predifinedFileType}
            dataWithVariables={this.state.dataWithVariables}
            label={input.label}
            quizId={this.props.data.id}
            isPreviewDesignMode={this.props.isPreviewDesignMode}
          />
        </>
      );
    }

    if (this.props.isPreview) {
      let defaultValue = "";

      if (this.props.data.contacts && this.props.data.contacts[inputId]) {
        defaultValue = this.props.data.contacts[inputId].value;
      } else if (
        this.props.data.contacts.other &&
        this.props.data.contacts.other[inputId]
      ) {
        defaultValue = this.props.data.contacts.other[inputId].value;
      } else if (validateInputs && validateInputs[inputId]) {
        defaultValue = validateInputs[inputId].value || "";
      } else if (
        validateInputs &&
        validateInputs.other &&
        validateInputs.other[inputId]
      ) {
        defaultValue = validateInputs.other[inputId].value || "";
      }
      return (
        <>
          <InputAndTextArea
            defaultValue={defaultValue}
            validationType={validationType}
            isTextarea={isTextarea}
            handleLiquid={handleLiquid}
            errorMessage={errorMessage}
            inputValid={inputValid}
            inputStyle={inputStyle}
            height={height}
            inputId={inputId}
            type={type}
            rows={textareaNumberOfRows || 2}
            handlevalidationInputChanges={this.handlevalidationInputChanges}
            dataWithVariables={this.state.dataWithVariables}
            placeholder={placeholder}
            name={name}
            fontSize={fontSize}
            required={required}
            isValid={isValid}
          />{" "}
        </>
      );
    } else if (validationType == "other" && isTextarea) {
      return (
        <>
          <textarea
            {...inputStyle}
            id={inputId}
            type={type}
            name={name}
            rows={textareaNumberOfRows || 2}
            placeholder={placeholder}
            className="form-control"
            // style={{ width: "200px" }}
            required={required}
          />
        </>
      );
    } else {
      return (
        <>
          <input
            {...inputStyle}
            id={inputId}
            type={type}
            name={name}
            placeholder={placeholder}
            className={`form-control ${type === "email" ? "always-ltr" : ""}`}
            // style={{ width: "200px" }}
            required={required}
          />
        </>
      );
    }
  };

  handleEditView = (event, context) => {
    const { actions, isPreview, onDraw } = this.props;
    if (!isPreview) actions.changeRightBarView(context, "", "design");

    //  added isedit option
    if (this.props.isEdit) {
      this.props.changeTheOnEditObject(onDraw.id);
    }
    event.stopPropagation();
  };

  // probobly foundation for uploading files. as of now its unactive; if activate please remove comment
  handleMediaType = () => {
    const { showMedia, mediaType, mediaVideoSrc, mediaImageSrc, id, style } =
      this.props.onDraw;
    const mediaStyle = {};
    // const mediaStyle = {
    //   border: `solid ${style.media.borderColor} ${style.media.borderSize}px`,
    //   borderRadius: `${style.media.radius}px`,
    //   padding: `${style.media.padding.top}px ${style.media.padding.right}px ${style.media.padding.bottom}px ${style.media.padding.left}px`,
    //   height: "auto",
    // };
    if (!showMedia) return <></>;
    return (
      //   {this.props.isEdit ? (

      <>
        {this.props.isEdit ? (
          <div
            className="pic"
            style={{ backgroundImage: `url('${mediaImageSrc}')`, zIndex: 2 }}
          >
            <ChooseImageModal
              index="mediaImageSrc"
              id={id}
              image={mediaImageSrc || "/img/picFrame.png"}
              handleChangeMediaImage={this.handleChangeMediaImage}
              size={{ height: "0px", width: "0px", opacity: 0 }}
            />

            <button
              className="btn- btn-link remove-image-icon"
              onClick={() => this.handleChangeMediaImage("")}
            >
              <IoTrashOutline size={20} />{" "}
            </button>
          </div>
        ) : (
          <>{this.viewIsImg()}</>
        )}
      </>
    );
  };

  handleFormStyle = (context, keyInObject) => {
    const { style } = this.props.onDraw;
    const { settings } = this.props.data;
    let { accessibility, isPreview, isMobile, isTablet, isMobilePreview } =
      this.props;
    isMobile = isMobilePreview || isMobile;

    if (!keyInObject)
      return settings[context] ? settings[context] : style[context];
    let styleObject = this.props.onDraw[keyInObject] ? style : settings;
    if (!styleObject[context]) return style[context];
    styleObject = styleObject[context];

    //Font size
    let fontSize = "14";
    if (isMobile && styleObject.mobileFontSize)
      fontSize = styleObject.mobileFontSize;
    else if (isTablet && styleObject.tabletFontSize)
      fontSize = styleObject.tabletFontSize;
    else if (styleObject.fontSize) fontSize = styleObject.fontSize;

    //Width
    let width = styleObject.width;
    if (isMobile && styleObject.mobileWidth) width = styleObject.mobileWidth;
    else if (isTablet && styleObject.tabletWidth)
      width = styleObject.tabletWidth;
    else if (styleObject.width) width = styleObject.width;

    //Top padding
    let topPadding = 0;
    if (styleObject.padding) {
      if (isMobile && styleObject.padding.mobileTop)
        topPadding = styleObject.padding.mobileTop;
      else if (isTablet && styleObject.padding.tabletTop)
        topPadding = styleObject.padding.tabletTop;
      else if (styleObject.padding.top) topPadding = styleObject.padding.top;
    }

    //Right padding
    let rightPadding = 0;
    if (styleObject.padding) {
      if (isMobile && styleObject.padding.mobileRight)
        rightPadding = styleObject.padding.mobileRight;
      else if (isTablet && styleObject.padding.tabletRight)
        rightPadding = styleObject.padding.tabletRight;
      else if (styleObject.padding.right)
        rightPadding = styleObject.padding.right;
    }

    //Left padding
    let leftPadding = 0;
    if (styleObject.padding) {
      if (isMobile && styleObject.padding.mobileLeft)
        leftPadding = styleObject.padding.mobileLeft;
      else if (isTablet && styleObject.padding.tabletLeft)
        leftPadding = styleObject.padding.tabletLeft;
      else if (styleObject.padding.left) leftPadding = styleObject.padding.left;
    }

    //Bottom padding
    let bottomPadding = 0;
    if (styleObject.padding) {
      if (isMobile && styleObject.padding.mobileBottom)
        bottomPadding = styleObject.padding.mobileBottom;
      else if (isTablet && styleObject.padding.tabletBottom)
        bottomPadding = styleObject.padding.tabletBottom;
      else if (styleObject.padding.bottom)
        bottomPadding = styleObject.padding.bottom;
    }

    return {
      ...styleObject,
      fontSize,
      topPadding,
      rightPadding,
      leftPadding,
      bottomPadding,
      width,
    };
  };

  handleSectionStyle = (context, keyInObject) => {
    const { style } = this.props.onDraw;
    const { settings } = this.props.data;
    let { accessibility, isPreview, isMobile, isTablet, isMobilePreview } =
      this.props;
    isMobile = isMobilePreview || isMobile;

    if (!keyInObject) return;
    let styleObject = this.props.onDraw[keyInObject] ? style : settings;
    if (!styleObject[context]) return;
    styleObject = styleObject[context];

    //Font size
    let fontSize = "14";
    if (isMobile && styleObject.mobileFontSize)
      fontSize = styleObject.mobileFontSize;
    else if (isTablet && styleObject.tabletFontSize)
      fontSize = styleObject.tabletFontSize;
    else if (styleObject.fontSize) fontSize = styleObject.fontSize;

    //Top padding
    let topPadding = 0;
    if (styleObject.padding) {
      if (isMobile && styleObject.padding.mobileTop)
        topPadding = styleObject.padding.mobileTop;
      else if (isTablet && styleObject.padding.tabletTop)
        topPadding = styleObject.padding.tabletTop;
      else if (styleObject.padding.top) topPadding = styleObject.padding.top;
    }

    //Right padding
    let rightPadding = 0;
    if (styleObject.padding) {
      if (isMobile && styleObject.padding.mobileRight)
        rightPadding = styleObject.padding.mobileRight;
      else if (isTablet && styleObject.padding.tabletRight)
        rightPadding = styleObject.padding.tabletRight;
      else if (styleObject.padding.right)
        rightPadding = styleObject.padding.right;
    }

    //Left padding
    let leftPadding = 0;
    if (styleObject.padding) {
      if (isMobile && styleObject.padding.mobileLeft)
        leftPadding = styleObject.padding.mobileLeft;
      else if (isTablet && styleObject.padding.tabletLeft)
        leftPadding = styleObject.padding.tabletLeft;
      else if (styleObject.padding.left) leftPadding = styleObject.padding.left;
    }

    //Bottom padding
    let bottomPadding = 0;
    if (styleObject.padding) {
      if (isMobile && styleObject.padding.mobileBottom)
        bottomPadding = styleObject.padding.mobileBottom;
      else if (isTablet && styleObject.padding.tabletBottom)
        bottomPadding = styleObject.padding.tabletBottom;
      else if (styleObject.padding.bottom)
        bottomPadding = styleObject.padding.bottom;
    }

    return {
      color: styleObject.color,
      padding: styleObject.padding
        ? `${topPadding}px ${rightPadding}px ${bottomPadding}px ${leftPadding}px `
        : "0px 0px 0px 0px",
      width: "fit-content",
      fontSize: `${
        parseInt(styleObject.fontSize || "14") +
        (isPreview ? accessibility.fontSize : 0)
      }px`,
      fontStyle: styleObject.fontStyle,
      fontWeight: styleObject.fontWeight,
      fontFamily: styleObject.fontFamily.replace(/\+/g, " "),
      textAlign: styleObject.textAlign,
      borderRadius: `${styleObject.borderRadius}px`,
      background: styleObject.background,

      border: `${styleObject.borderSize}px ${styleObject.borderColor} solid`,
    };
  };

  viewIsImg() {
    const { mediaImageSrc } = this.props.onDraw;
    return mediaImageSrc ? (
      <div
        className="pic"
        style={{ backgroundImage: `url('${mediaImageSrc}')` }}
      ></div>
    ) : (
      <></>
    );
  }

  render() {
    let { id, buttonText, withPagination, inputs } = this.props.onDraw;
    // const { inputs } = this.state;
    inputs = inputs.reduce((allInputs, current) => {
      const { id, type, inputs = [] } = current;
      if (type !== "location") return [...allInputs, current];
      inputs.forEach((itr) => {
        if (!itr.show) return;
        itr.parentLabel = current.label;
        itr.validationType = "other";
        allInputs.push(itr);
      });
      return allInputs;
    }, []);
    const { isPreview, accessibility, isMobile, isTablet } = this.props;
    const buttonStyle = this.handleSectionStyle(
      "button",
      "useButtonCustomStyle"
    );

    const formStyle = this.handleFormStyle("forms", "useFormCustomStyle");

    const styleObject = {
      ...formStyle,
      fontSize: `${
        parseInt(formStyle.fontSize || "14") +
        (isPreview ? accessibility.fontSize : 0)
      }px`,
      padding: `${formStyle.topPadding || 0}px ${
        formStyle.rightPadding || 0
      }px ${formStyle.bottomPadding || 0}px ${formStyle.leftPadding || 0}px `,
      formWidth: `${formStyle.width || 50}%`,
      formTextAlign: this.handleFormAlignment(
        formStyle.formTextAlign || "center"
      ),
    };

    let selectStyle = styleObject.minimalStyle
      ? css({
          borderRadius: "0px !important",
          border: "none !important",
          borderBottom: "1px solid #78889f !important",
        })
      : {};

    // make css rules
    let inputStyle = styleObject.minimalStyle
      ? css({
          padding: "0px 0px 8px !important",
          border: "none !important",
          borderRadius: "0 !important",
          lineHeight: "unset !important",
          color:
            styleObject.color
              .slice(0, styleObject.color.lastIndexOf(","))
              .replace(/,/g, "") + ") !important",
          boxShadow:
            styleObject.color
              .slice(0, styleObject.color.lastIndexOf(","))
              .replace(/,/g, "") + " /30%) 0px 1px !important",
          backgroundColor: "transparent !important",
          // WebkitTextFillColor: styleObject.color,
          WebkitFontSmoothing: "antialiased !important",
          ":focus": {
            boxShadow:
              styleObject.color
                .slice(0, styleObject.color.lastIndexOf(","))
                .replace(/,/g, "") + ") 0px 2px !important",
          },
          "::placeholder": {
            color:
              styleObject.color
                .slice(0, styleObject.color.lastIndexOf(","))
                .replace(/,/g, "") + " /30%) !important",
          },
        })
      : {};
    return (
      <>
        <div
          // className="d-flex formContainer"
          className={
            this.props.isEdit &&
            this.props.rightBarView.context === "contactForm"
              ? "d-flex formContainer active-element"
              : "d-flex formContainer"
          }
          style={{
            justifyContent: styleObject.formTextAlign,
            width: "100%",
          }}
        >
          <form
            action=""
            onClick={(e) => this.handleEditView(e, "contactForm")}
            className="contanctForm"
            style={{ width: styleObject.formWidth, zIndex: 3 }}
          >
            {inputs.map((input, index) => {
              const { id: inputId, label, name, hideLabel } = input;
              return (
                <div key={inputId} className="field">
                  {hideLabel ? (
                    <></>
                  ) : (
                    <label htmlFor={inputId} style={styleObject}>
                      {this.handleInputLabel(label, inputs, index)}
                    </label>
                  )}
                  {this.checkIfPreviewAndRenderInput(
                    input,
                    inputStyle,
                    selectStyle,
                    styleObject,
                    inputs
                  )}
                </div>
              );
            })}
            <input
              style={{
                visibility: "hidden",
                height: "0px",
                width: "0px",
                display: "none",
              }}
            />
            <div
              // className="contactBTNdiv"
              className={
                this.props.isEdit &&
                this.props.rightBarView.context === "buttonStyle"
                  ? "contactBTNdiv active-element"
                  : "contactBTNdiv pointer"
              }
              style={{ display: "flex", justifyContent: buttonStyle.textAlign }}
            >
              {isPreview ? (
                <a
                  type="button"
                  onClick={this.send}
                  className="contactBTN pointer"
                  style={buttonStyle}
                >
                  {stripTags(buttonText)}
                </a>
              ) : (
                <div onClick={(e) => this.handleEditView(e, "buttonStyle")}>
                  <ToggleInputAndText
                    isRichText={true}
                    value={buttonText}
                    handleChange={(e) => this.props.handleChange(e, id)}
                    id={`buttonText_${id}`}
                    name="buttonText"
                    data={this.props.data}
                    tag="span"
                    isEdit={this.props.isEdit}
                    className="contactBTN pointer"
                    classNameForTag="quillTag"
                    placeholder="Submit Form"
                    style={buttonStyle}
                  />
                </div>
              )}
            </div>
          </form>
        </div>

        {withPagination ? (
          <Pagination
            style={this.props.data.settings.backButton}
            stepId={id}
            isMobile={isMobile}
            isTablet={isTablet}
            stepBack={this.props.stepBack || (() => {})}
            show={this.props.data.settings.pagination}
            list={this.props.data.arrayOfData}
            isEdit={this.props.isEdit}
          />
        ) : (
          <></>
        )}
      </>
      // </div>
      // <Nav
      //   show={withFooter}
      //   data={this.props.data.footer}
      //   position="footer"
      // />
      // </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      saveContact: (answerDetails) => dispatch(saveAnswer(answerDetails)),
      changeRightBarView: (context, subContextId, rightBarViewTab) =>
        dispatch(changeRightBarView(context, "", rightBarViewTab)),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewContact);
