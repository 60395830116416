import React, { useState, useEffect } from "react";
import ChooseImageModal from "../ChooseImageModal";
import ColorPicker from "../ColorPicker";
import { withTranslation } from "react-i18next";
import Collapse from "react-bootstrap/Collapse";
import SelectSize from "../SelectSize";
import { IoTrashOutline } from "react-icons/io5";
import {
  BsFillArrowDownLeftSquareFill,
  BsFillArrowDownRightSquareFill,
  BsFillArrowDownSquareFill,
  BsFillArrowLeftSquareFill,
  BsFillArrowRightSquareFill,
  BsFillArrowUpLeftSquareFill,
  BsFillArrowUpRightSquareFill,
  BsFillArrowUpSquareFill,
} from "react-icons/bs";
import { GoPrimitiveSquare } from "react-icons/go";

function Background({
  selectedTheme,
  props,
  onSwatchHover,
  changeBackgroundImage,
  changeBackgroundVideo,
  handleChange,
  fromTheme,
}) {
  const [isCover, setIsCover] = useState(false);
  useEffect(() => {
    if (selectedTheme.backgroundSize === "cover") {
      setIsCover(true);
    } else {
      setIsCover(false);
    }
  }, [selectedTheme]);

  if (!selectedTheme) return <></>;
  const {
    backgroundColor,
    backgroundImage,
    backgroundOverlayOpacity,
    backgroundOverlayColor,
    backgroundPosition,
  } = selectedTheme;
  const { t, currentOnEdit } = props;

  const handleCover = (cover) => {
    setIsCover(cover);
    const value = cover ? "cover" : "auto";
    props.updateMainSettings({ target: { name: "backgroundSize", value } });
    handleChange({ target: { name: "backgroundSize", value } });
  };

  const handlePosition = (value) => {
    props.updateMainSettings({ target: { name: "backgroundPosition", value } });
    handleChange({ target: { name: "backgroundPosition", value } });
  };

  const handleBgOpacity = (value) => {
    props.updateMainSettings({
      target: { name: "backgroundOverlayOpacity", value },
    });
    handleChange({ target: { name: "backgroundOverlayOpacity", value } });
  };
  return (
    <div className="background">
      {/*<h3>{t("theme-accordion-background")} </h3>*/}
      {/*<select>*/}
      {/*  <option>{t("theme-accordion-solid-color")} </option>*/}
      {/*</select>*/}
      <div className="answerColor">
        <h3>{t("theme-accordion-background-color")} </h3>
      </div>
      <div className="answerColor">
        <ColorPicker
          setRight="inherit"
          color={backgroundColor}
          name="background"
          handleChange={(e) => onSwatchHover(e, "background")}
        />
      </div>
      <div className="answerColor">
        <h3>{t("theme-accordion-background-image")} </h3>
      </div>

      <div className="chooseImage">
        <div className="imageBox">
          <ChooseImageModal
            index={"mainSettings"}
            changeBackgroundImage={changeBackgroundImage}
            changeBackgroundVideo={changeBackgroundVideo}
            image={
              selectedTheme === "override-edit"
                ? backgroundImage || "/img/picFrame.png"
                : props.settings.backgroundImage || "/img/picFrame.png"
            }
            rigtBar={true}
            size={{
              height: "auto",
              maxWidth: "100%",
              width: "auto",
              maxHeight: "100%",
              margin: "auto",
            }}
            borderStyle={{
              border: "2px solid #DEDCF4",
              width: "100%",
              height: "120px",
              margin: "10px 0px",
              position: "relative",
              alignItems: "center",
              display: "flex",
              backgroundColor: "#F2F1FF",
              borderRadius: "2px",
            }}
          />
        </div>
        <div className="imageBoxRemove">
          {/*{mediaImageSrc ? (  <button className="btn- btn-link remove-image-icon" onClick={() => this.changeImage("")}><IoTrashOutline size={20} /> </button> ) : <></>}*/}
          <button
            className="btn- btn-link remove-image-icon"
            onClick={() => changeBackgroundImage("")}
          >
            <IoTrashOutline size={20} />{" "}
          </button>
        </div>
      </div>

      {/*<div className="answerColor">*/}
      {/*  <ChooseImageModal*/}
      {/*    index={"mainSettings"}*/}
      {/*    changeBackgroundImage={changeBackgroundImage}*/}
      {/*    changeBackgroundVideo={changeBackgroundVideo}*/}
      {/*    image={selectedTheme === 'override-edit' ? backgroundImage || "/img/picFrame.png" : props.settings.backgroundImage || "/img/picFrame.png"}*/}
      {/*    rigtBar={true}*/}
      {/*  /> <button style={{ right: 0, top: 0 }} className="btn- btn-link remove-image-icon" onClick={() => changeBackgroundImage("")}><IoTrashOutline size={20} /> </button>*/}
      {/*</div>*/}

      <div className="addSwitch">
        <label className="switch">
          <input
            type="checkbox"
            name="backgroundSize"
            checked={isCover}
            onChange={(e) => {
              handleCover(!isCover);
            }}
          />
          <span className="slider round"></span>
        </label>
        {t("rightBar-theme-background-cover")}
      </div>
      <Collapse in={!isCover}>
        <div>
          <div className="d-flex w-100 justify-content-around">
            <button
              name="backgroundPosition"
              className={`btn btn-light ${
                backgroundPosition == "top left" ? "active" : ""
              }`}
              onClick={(e) => handlePosition("top left")}
              value="top left"
            >
              {" "}
              <BsFillArrowUpLeftSquareFill size={30} />
            </button>
            <button
              name="backgroundPosition"
              className={`btn btn-light ${
                backgroundPosition == "top center" ? "active" : ""
              }`}
              onClick={(e) => handlePosition("top center")}
              value="top center"
            >
              {" "}
              <BsFillArrowUpSquareFill size={30} />
            </button>
            <button
              name="backgroundPosition"
              className={`btn btn-light ${
                backgroundPosition == "top right" ? "active" : ""
              }`}
              onClick={(e) => handlePosition("top right")}
              value="top right"
            >
              {" "}
              <BsFillArrowUpRightSquareFill size={30} />
            </button>
          </div>
          <div className="d-flex w-100 justify-content-around">
            <button
              name="backgroundPosition"
              className={`btn btn-light ${
                backgroundPosition == "center left" ? "active" : ""
              }`}
              onClick={(e) => handlePosition("center left")}
              value="center left"
            >
              {" "}
              <BsFillArrowLeftSquareFill size={30} />
            </button>
            <button
              name="backgroundPosition"
              className={`btn btn-light ${
                backgroundPosition == "center center" ? "active" : ""
              }`}
              onClick={(e) => handlePosition("center center")}
              value="center center"
            >
              <GoPrimitiveSquare size={30} />
            </button>
            <button
              name="backgroundPosition"
              className={`btn btn-light ${
                backgroundPosition == "center right" ? "active" : ""
              }`}
              onClick={(e) => handlePosition("center right")}
              value="center right"
            >
              {" "}
              <BsFillArrowRightSquareFill size={30} />
            </button>
          </div>
          <div className="d-flex w-100 justify-content-around">
            <button
              className={`btn btn-light ${
                backgroundPosition == "bottom left" ? "active" : ""
              }`}
              onClick={(e) => handlePosition("bottom left")}
              value="buttom left"
            >
              <BsFillArrowDownLeftSquareFill size={30} />
            </button>
            <button
              className={`btn btn-light ${
                backgroundPosition == "bottom center" ? "active" : ""
              }`}
              onClick={(e) => handlePosition("bottom center")}
              value="buttom center"
            >
              {" "}
              <BsFillArrowDownSquareFill size={30} />{" "}
            </button>
            <button
              className={`btn btn-light ${
                backgroundPosition == "bottom right" ? "active" : ""
              }`}
              onClick={(e) => handlePosition("bottom right")}
              value="buttom right"
            >
              <BsFillArrowDownRightSquareFill size={30} />
            </button>
          </div>
        </div>
      </Collapse>
      <div className="fontStyle">
        <SelectSize
          showPlatformSelect={false}
          fromTheme={fromTheme}
          name="backgroundOverlayOpacity"
          label={t("theme-image-overlay-opacity")}
          value={backgroundOverlayOpacity || `0`}
          handleChange={(size) => handleBgOpacity(size)}
          // handleChange={(size) => {
          //   props.changeMainStyle(
          //     { target: { name: "backgroundOverlayOpacity", value: size } },
          //     ""
          //   );
          //   handleChange(
          //     { target: { name: "backgroundOverlayOpacity", value: size } },
          //     ""
          //   );
          // }}
          changedDetails={{ toChange: "background" }}
        />
      </div>
      <h3>{t("theme-image-overlay-color")}</h3>
      <div className="answerColor m-2">
        <ColorPicker
          setRight="inherit"
          color={backgroundOverlayColor}
          name="backgroundOverlayColor"
          handleChange={(e) => onSwatchHover(e, "backgroundOverlayColor")}
        />
      </div>
    </div>
  );
}
export default withTranslation()(Background);
