import React from "react";
import { connect } from "react-redux";
import {
  getQuizAnalyzeGraph,
  getQuizAnalyzeStats,
  getQuizAnalyzeLineChart,
  getQuizAnalyzeTable,
  getQuizAnalyzeQuestions,
  purgeAllDataAction,
} from "../../../redux/actions";
import VisitsTable from "./VisitsTable";
// import SimpleReactCalendar from "simple-react-calendar";
import GraphList from "./GraphList";
import "./analyze.css";
import AnalyzaNotPublish from "./AnalyzeNotPublish";
import Collapse from "react-bootstrap/Collapse";
import AnalyseFilters from "./AnalyseFilters";
import moment from "moment";
import AnalyzeTable from "./analyzeTable";
import DetailedTable from "./summeryTable";

import initialFilter from "./FiltersConstants";

import { CgCloseO, CgToggleSquare, CgToggleSquareOff } from "react-icons/cg";
import { VscDebugRestart } from "react-icons/vsc";
import { BiFilterAlt, BiBookmarkPlus } from "react-icons/bi";
import { BsFillTrashFill } from "react-icons/bs";
import AnalyseQuestion from "./AnalyseQuestion";
import { withTranslation } from "react-i18next";
import i18n from "../../../translate/i18n";
import FeatureUnavailable from "../../Subscriptions/FeatureUnavailable";

class Analyze extends React.Component {
  state = {
    toShow: "graph",
    // quizAnalyzeTable: [],
    // quizAnalyzeHeaders: [],
    isFilterSection: false,
    filterObj: [
      {
        ...initialFilter,
        id: `F_${Math.round(Math.random() * 999999)}`,
      },
    ],
    additionalFilters: [],
    isPermited: true,
    offset: 0,
  };

  componentDidMount() {
    this.props.actions.getQuizAnalyzeStats(
      this.props.data.id,
      this.state.filterObj,
      this.state.additionalFilters
    );

    this.props.actions.getQuizAnalyzeLineChart(
      this.props.data.id,
      this.state.filterObj,
      this.state.additionalFilters
    );
  }

  getDataWithFilter = (reset) => {
    const { filterObj, toShow, offset } = this.state;
    const filterToServer = reset
      ? [
        {
          ...initialFilter,
          id: `F_${Math.round(Math.random() * 999999)}`,
        },
      ]
      : filterObj;

    const fromDate = moment().format("YYYY-MM-DD");
    let toDate = moment().subtract(30, "days");
    toDate = moment(toDate).format("YYYY-MM-DD");
    if (toShow === "graph") {
      this.props.actions.getQuizAnalyzeGraph({
        id: this.props.data.id,
        fromDate,
        toDate,
        filters: filterToServer,
        offset: 0,
        additionalFilters: this.state.additionalFilters,
      });
      this.props.actions.getQuizAnalyzeLineChart(
        this.props.data.id,
        filterToServer,
        this.state.additionalFilters
      );
    } else if (toShow === "table" || toShow === "summary") {
      this.props.actions.getQuizAnalyzeTable({
        id: this.props.data.id,
        fromDate,
        toDate,
        filters: filterToServer,
        offset,
        additionalFilters: this.state.additionalFilters,
      });
    } else if (toShow === "anaylze-question") {
      this.props.actions.getQuizAnalyzeQuestions({
        id: this.props.data.id,
        fromDate,
        toDate,
        filters: filterToServer,
        additionalFilters: this.state.additionalFilters,
      });
    }
    this.props.actions.getQuizAnalyzeStats(
      this.props.data.id,
      filterToServer,
      this.state.additionalFilters
    );
    if (reset) {
      this.setState({
        filterObj: filterToServer,
        isFilterSection: false,
        additionalFilters: [],
      });
    }
  };

  addRemoveFilterBlock = (op, index) => {
    const { additionalFilters } = this.state;
    if (op === "add") {
      const newFilter = {
        filter: [
          {
            ...initialFilter,
            id: `F_${Math.round(Math.random() * 999999)}`,
          },
        ],
        blockIsOr: false,
      };
      additionalFilters.push(newFilter);
      this.setState({ additionalFilters });
    } else {
      additionalFilters.splice(index, 1);
      this.setState({ additionalFilters });
    }
  };

  renderAndOrToggle = (index) => {
    const { additionalFilters } = this.state;
    const orToggle = additionalFilters[index].blockIsOr;
    if (orToggle) {
      return (
        <>
          {" "}
          <span className="text-success fw-bold">OR</span>
          <button className=" btn">
            <CgToggleSquare
              size={20}
              onClick={() => {
                additionalFilters[index].blockIsOr = !orToggle;
                this.setState({ additionalFilters });
              }}
            />
          </button>{" "}
          <span className="text-muted fw-light">AND</span>
        </>
      );
    } else {
      return (
        <>
          {" "}
          <span className="text-muted fw-light">OR</span>
          <button className=" btn">
            <CgToggleSquareOff
              size={20}
              onClick={() => {
                additionalFilters[index].blockIsOr = !orToggle;
                this.setState({ additionalFilters });
              }}
            />
          </button>{" "}
          <span className="text-success fw-bold">AND</span>
        </>
      );
    }
  };

  render() {
    const { toShow } = this.state;
    const { t } = this.props;
    if (!this.props.data.published || this.props.data.published == 0) {
      return (
        <>
          <AnalyzaNotPublish />
        </>
      );
    } else {
      return (
        <div className="" style={{ padding: 0, height: "100%", width: "100%" }}>
          {!this.state.isPermited ? (
            <>
              <FeatureUnavailable
                isShow="true"
                hide={() => this.setState({ isPermited: true })}
                feature="analyze-filters"
              />
            </>
          ) : (
            <></>
          )}
          {/*    <div style={{ width: "auto" }}>
                  <div className="col-3">
                    <ul className="nav nav-tabs" id="analyzeTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <a
                          className={`nav-link ${
                            toShow == "graph" ? "active" : ""
                          }`}
                          id="graph-tab"
                          style={{ width: "100px" }}
                          data-toggle="tab"
                          type="button"
                          onClick={() => this.setState({ toShow: "graph" })}
                          role="tab"
                          aria-controls="graph"
                          aria-selected="true"
                        >
                          Graph
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className={`nav-link ${
                            toShow == "table" ? "active" : ""
                          }`}
                          id="table-tab"
                          style={{ width: "100px" }}
                          data-toggle="tab"
                          type="button"
                          onClick={() => this.setState({ toShow: "table" })}
                          role="tab"
                          aria-controls="table"
                          aria-selected="false"
                        >
                          Table
                        </a>
                      </li>
                    </ul>
                  </div>
                </div> */}
          {/*</div>*/}
          {/*<div className="row">*/}
          <section className="dashboardContent config">
            <div
              className={
                i18n.language == "he"
                  ? "config-tabs config-tabs-rtl"
                  : "config-tabs"
              }
            >
              <h2>{t("nav-analyze")}</h2>
              {/*<div className="viewPick">*/}
              <a
                onClick={() => this.setState({ toShow: "graph" })}
                className={`summary ${toShow === "graph" ? "active" : ""}`}
              >
                {t("analyze-menu-summary")}
              </a>
              <a
                onClick={() => this.setState({ toShow: "table" })}
                className={` ${toShow === "table" ? "table-data active" : "table-data"
                  }`}
              >
                {t("analyze-menu-users")}
              </a>
              <a
                // className="responses"
                onClick={() => this.setState({ toShow: "summary" })}
                className={`${toShow === "summary" ? "active" : ""}`}
              >
                {t("analyze-menu-responses")}
              </a>
              <a
                className={`${toShow === "anaylze-question" ? "active" : ""}`}
                onClick={() => this.setState({ toShow: "anaylze-question" })}
              >
                {t("analyze-menu-analyze-question")}
              </a>
              {/*</div>*/}
            </div>
            <div className={"tab-content"}>
              {toShow === "graph" ? (
                <div className="h-100">
                  <GraphList
                    data={this.props.data}
                    filtersObj={this.state.filterObj}
                    additionalFilters={this.state.additionalFilters}
                  />
                </div>
              ) : (
                <></>
              )}
              {toShow === "table" ? (
                <div className="margin-analyse">
                  <AnalyzeTable
                    data={this.props.data}
                    tableItems={this.props.data.form_details}
                    filterObj={this.state.filterObj}
                    additionalFilters={this.state.additionalFilters}
                    quizAnalyzeTablePageCount={
                      this.props.quizAnalyzeTablePageCount
                    }
                    moveToSummery={() => {
                      this.getDataWithFilter();
                      this.setState({ toShow: "graph" })

                    }}
                  />
                </div>
              ) : (
                <></>
              )}
              {toShow === "summary" ? (
                <div className="tab-content-inner summary-table-wrapper">
                  <DetailedTable
                    data={this.props.quizAnalyzeTable}
                    columns={this.props.quizAnalyzeHeaders}
                    filterObj={this.state.filterObj}
                    getQuizAnalyzeTable={this.props.actions.getQuizAnalyzeTable}
                    quizId={this.props.data.id}
                    additionalFilters={this.state.additionalFilters}
                    quizAnalyzeTablePageCount={
                      this.props.quizAnalyzeTablePageCount
                    }
                  />
                </div>
              ) : (
                <></>
              )}

              {toShow === "anaylze-question" ? (
                <>
                  <AnalyseQuestion
                    label="question"
                    // toCalculate={this.props.quizAnalyzeHeaders}
                    // data={this.props.quizAnalyzeTable}
                    filterObj={this.state.filterObj}
                    additionalFilters={this.state.additionalFilters}
                    quizId={this.props.data.id}
                    getQuizAnalyzeQuestions={
                      this.props.actions.getQuizAnalyzeQuestions
                    }
                    quizAnalyzeQuestions={this.props.quizAnalyzeQuestions}
                    arrayOfData={this.props.data.arrayOfData}
                  />
                </>
              ) : (
                <></>
              )}
            </div>

            <div
              className="filter row"
              style={{
                display: "block",
                width: this.state.isFilterSection ? "100%" : "5%",
              }}
            >
              <div
                className="col-11 m-auto"
                style={{ height: "100%", overflowY: "auto" }}
              >
                <Collapse in={!this.state.isFilterSection}>
                  <button
                    className=" btn float-end"
                    onClick={() => {
                      if (!this.props.permitions.analyze_filters)
                        return this.setState({ isPermited: false });
                      this.setState({ isFilterSection: true });
                    }}
                    style={{
                      backgroundColor: "#0046B5",
                      color: "#ffffff",
                      marginBottom: "1px",
                    }}
                  >
                    <BiFilterAlt size={20} />
                    {!this.props.permitions.analyze_filters ? (
                      <i class="bi bi-lock-fill m-2"></i>
                    ) : (
                      <></>
                    )}
                  </button>
                </Collapse>
                {!this.state.isFilterSection ? (
                  <button
                    className=" btn btn-danger float-end"
                    onClick={() => {
                      // if (!this.props.permitions.purge_data) return this.setState({ isPermited: false });
                      const sure = window.confirm(
                        "Are you sure you want to purge all data?"
                      );
                      if (!sure) return;
                      this.props.actions.purgeData(this.props.data.id);
                      this.setState({
                        toShow: "graph",
                      });
                    }}
                  >
                    <BsFillTrashFill size={20} />
                  </button>
                ) : (
                  <></>
                )}
                <Collapse in={this.state.isFilterSection}>
                  <div
                    className="p-5  w-100"
                    style={{ backgroundColor: "#F2F1FF" }}
                  >
                    <div
                      className="p-3 mb-5 rounded"
                      style={{ backgroundColor: "#ffffff" }}
                    >
                      <AnalyseFilters
                        variables={this.props.data.variables}
                        arrayOfData={this.props.data.arrayOfData}
                        setFiltersObject={(filterObj) =>
                          this.setState({ filterObj })
                        }
                        filtersObject={this.state.filterObj}
                        resetFlag={this.state.isFilterSection}
                        additionalFilters={false}
                      />
                    </div>
                    <div>
                      {this.state.additionalFilters.map((filter, index) => (
                        <>
                          {" "}
                          <div className="d-flex justify-content-center align-items-center">
                            {this.renderAndOrToggle(index)}
                          </div>
                          <button
                            className=" btn btn-light float-end m-1 "
                            onClick={() =>
                              this.addRemoveFilterBlock("remove", index)
                            }
                          >
                            <CgCloseO size={20} />
                          </button>
                          <div
                            className="p-3 mb-5 rounded"
                            style={{ backgroundColor: "#ffffff" }}
                          >
                            <AnalyseFilters
                              variables={this.props.data.variables}
                              arrayOfData={this.props.data.arrayOfData}
                              setFiltersObject={(filterObj) => {
                                const tempObject = Object.assign(
                                  this.state.additionalFilters
                                );
                                tempObject[index].filter = filterObj;
                                this.setState({
                                  additionalFilters: tempObject,
                                });
                              }}
                              filtersObject={filter.filter}
                              resetFlag={this.state.isFilterSection}
                              additionalFilters={this.state.additionalFilters}
                              additionalFilterIndex={index}
                            />
                          </div>
                        </>
                      ))}
                      <div className="d-flex">
                        <button
                          className=" btn float-end m-1 "
                          style={{
                            backgroundColor: "#F2F1FF",
                            border: "1px solid #0046B5",
                            boxShadow: "0px 0px 13px rgba(151, 71, 255, 0.4)",
                            borderRadius: "6px",
                          }}
                          onClick={() => this.addRemoveFilterBlock("add")}
                        >
                          Add Filter Group
                          {/*<BiBookmarkPlus size={30} />*/}
                        </button>
                      </div>

                      <button
                        className=" btn float-end m-1"
                        style={{
                          backgroundColor: "#0046B5",
                          boxShadow: "0px 0px 13px rgba(151, 71, 255, 0.4)",
                          borderRadius: "6px",
                          color: "#F2F1FF",
                        }}
                        onClick={() => this.getDataWithFilter()}
                      >
                        Save Filter
                      </button>

                      <button
                        className=" btn btn-success float-end m-1 "
                        onClick={() =>
                          this.setState({ isFilterSection: false })
                        }
                      >
                        Close
                      </button>

                      <span
                        className="m-2 float-end"
                        style={{ color: "#0046B5" }}
                        onClick={() => {
                          this.getDataWithFilter(true);
                        }}
                      >
                        {" "}
                        <VscDebugRestart size={20} /> Clear all filters
                      </span>
                    </div>
                  </div>
                </Collapse>
              </div>

              {/* <SimpleReactCalendar
                                mode='range'
                                blockClassName='date_picker'
                                onSelect={({ start, end }) => {
                                    console.log('>>>>>', { end, start })
                                }}
                            /> */}
            </div>
          </section>
          {/* <aside className="col-3 analyze-said-items">
                            <ul>
                                <li>
                                    <h4>Total Visits</h4>
                                    <span>{this.state.quizAnalyze.length || 0}</span>
                                </li>
                                <li >
                                    <h4>Total Starts</h4>
                                    <span>{total_start || 0}</span>
                                </li>
                                <li>
                                    <h4>Total Finish</h4>
                                    <span>{total_finish || 0}</span>
                                </li>
                                <li>
                                    <h4>Total Leads</h4>
                                    <span>{total_leads || 0}</span>
                                </li>
                                <li>
                                    <h4>Avg Score</h4>
                                    <span>{average ? `${average}%` : 0}</span>
                                </li>
                            </ul>
                        </aside> */}
          {/*</div>*/}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  const {
    quizAnalyzeGraph,
    quizAnalyzeStats,
    quizAnalyzeTable,
    quizAnalyzeHeaders,
    quizAnalyzeQuestions,
    permitions,
    quizAnalyzeTablePageCount,
  } = state;

  return {
    quizAnalyzeGraph,
    quizAnalyzeStats,
    quizAnalyzeTable,
    quizAnalyzeHeaders,
    quizAnalyzeQuestions,
    permitions,
    quizAnalyzeTablePageCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      getQuizAnalyzeGraph: (analyzeSearchDetails) =>
        dispatch(getQuizAnalyzeGraph(analyzeSearchDetails)),
      getQuizAnalyzeStats: (quizId, filterObj, additionalFilters) =>
        dispatch(getQuizAnalyzeStats(quizId, filterObj, additionalFilters)),
      getQuizAnalyzeLineChart: (quizId, filterObj, additionalFilters) =>
        dispatch(getQuizAnalyzeLineChart(quizId, filterObj, additionalFilters)),
      getQuizAnalyzeTable: (quizDetails) =>
        dispatch(getQuizAnalyzeTable(quizDetails)),
      getQuizAnalyzeQuestions: (analyzeSearchDetails) =>
        dispatch(getQuizAnalyzeQuestions(analyzeSearchDetails)),
      purgeData: (quizId) => dispatch(purgeAllDataAction(quizId)),
    },
  };
};

// export default connect(mapStateToProps, mapDispatchToProps)(Analyze);
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Analyze)
);
