import React, { useState, useEffect } from "react";
import Settings from "../../ThemeEdit/Settings";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getThemes } from "../../../../redux/actions";
import { addStyleSettings } from "../../../../utils/patchFunctions";

function MyStyle(props) {
  const [currentOnEdit, setCurrentOnEdit] = useState({
    theme_data: { ...props.settings },
    theme_title: "KnowyyyDefaultStyle",
    theme_id: null,
  });

  const [styleMode, setStyleMode] = useState("myStyle");
  const [toThemeModal, setToThemeModal] = useState(false);

  useEffect(() => {
    if (props.settings && !props.settings.backButton) {
      const newSettings = addStyleSettings(props.settings, "backButton");
      setCurrentOnEdit({
        theme_data: { ...newSettings },
        theme_title: "KnowyyyDefaultStyle",
        theme_id: null,
      });
    }
    props.actions.getThemes();
  }, []);

  return (
    <>
      {styleMode === "myStyle" ? (
        <>
          <div style={{ position: "position" }}>
            {currentOnEdit && (
              <>
                <Settings
                  currentOnEdit={currentOnEdit}
                  // handleCurrentTheme={() => alert("gg1")}
                  // setIsEditMode={() => alert("gg2")}
                  setStyleMode={setStyleMode}
                  setToThemeModal={setToThemeModal}
                  toThemeModal={toThemeModal}
                  {...props}
                />
              </>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

const mapStateToProps = ({ themes }) => {
  return { themes };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      getThemes: () => dispatch(getThemes()),
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(MyStyle)
);
