import React from "react";
import ChooseImage from "../../ChooseImage";


class ShareSettings extends React.Component {

    handleShareImage = (image) => {
        this.props.handleShareDetails({ target: { name: "imageUrl", value: image } })
    }

    render() {
        const { title, description } = this.props.data.shareDetails
        return (
            <div>
                <ChooseImage
                    index="shareImageUrl"
                    id="shareImageUrl"
                    handleShareImage={this.handleShareImage}
                />
                <div>
                    <label>Share Title</label>
                    <input type="text" name="title" value={title} onChange={(e) => this.props.handleShareDetails(e)} className="form-control" />
                </div>
                <div>
                    <label>Share Description</label>
                    <input type="text" name="description" value={description} onChange={(e) => this.props.handleShareDetails(e)} className="form-control" />
                </div>
                <div>
                    <label >Share Image</label>
                    <div className="custom-file" data-toggle="modal" data-target="#modalshareImageUrl">
                        <input type="button" className="custom-file-input" required />
                        <label className="custom-file-label" for="validatedCustomFile">Choose file...</label>
                    </div>
                </div>
            </div>
        )
    }
}

export default ShareSettings