import React from "react";

class FolderItem extends React.Component {
    render() {
        const { name, id} = this.props.folder
        return (

            <li onClick={() => this.props.changeFolderFilter(id, name)}>
                {this.props.selectedFolderId==id ?<a role="button" className={"active"}>{name}</a> : <a role="button">{name}</a>}

            </li>
        )
    }
}

export default FolderItem