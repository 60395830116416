import React from "react";
import Infinite from "react-infinite-scroll-component";
import background from "../CreateQuiz/ThemeEdit/background";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Tooltip from "react-bootstrap/Tooltip";

class InfinteScroll extends React.Component {
  render() {
    const {
      data,
      handleNext,
      saveVideo,
      saveImage,
      hasImages,
      selectedImage,
      searchTarget,
    } = this.props;
    const renderTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        {handleRenderPicInfo(props) || props.url}
      </Tooltip>
    );

    const handleRenderPicInfo = (props) => {
      if (searchTarget === "pexels" || searchTarget === "pexels-video") {
        return (
          <>
            {" "}
            <a
              href={props.url}
              target="_blank"
              style={{ color: "#fff", textDecoration: "underline" }}
            >
              Photo by {props.photographer}{" "}
            </a>{" "}
            On Pexels
          </>
        );
      }
      if (searchTarget === "unsplash") {
        return (
          <>
            <a
              href={
                props.photographer_url + "?utm_source=knowy&utm_medium=referral"
              }
              target="_blank"
              style={{ color: "#fff", textDecoration: "underline" }}
            >
              Photo by {props.photographer}{" "}
            </a>{" "}
            on Unsplash
          </>
        );
      }
    };

    return (
      <div
        id="scrollableDiv"
        style={{
          height: 400,
          overflow: "auto",
        }}
      >
        <Infinite
          dataLength={data.length}
          next={(e) => handleNext(e, true)}
          hasMore={hasImages}
          style={{
            overflow: "hidden",
            display: "flex",
            flexDirection: "column-reverse",
          }}
          scrollableTarget="scrollableDiv"
          endMessage={
            <p style={{ textAlign: "center" }}>
              {data.length ? (
                <b>Yay! You have seen it all</b>
              ) : (
                <b>No results</b>
              )}
            </p>
          }
        >
          <div className="row" style={{ margin: "0 4px 0 4px" }}>
            {data.length ? (
              data.map((item, index) => {
                return (
                  <div
                    style={{
                      marginBottom: "15px",
                      height: "120px",
                      border:
                        selectedImage == item.url ? "1px solid black" : "",
                    }}
                    className="col-2"
                    onClick={() =>
                      item.video
                        ? saveVideo(item.video, item.url)
                        : saveImage(
                            item.url,
                            item.description,
                            item.downloadLocation
                          )
                    }
                    key={`${index}-${item.id}`}
                  >
                    <div
                      style={{
                        backgroundColor: "#d3d3d345",
                        height: "100%",
                        borderRadius: "12px",
                        backgroundPosition: "center",
                        backgroundImage: "url(" + item.url + ")",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        alignContent: "flex-end",
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "flex-end",
                      }}
                    >
                      {searchTarget == "unsplash" ||
                      searchTarget == "pexels" ||
                      searchTarget == "pexels-video" ||
                      searchTarget == "giphy" ? (
                        <div>
                          <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 1000 }}
                            overlay={renderTooltip(item)}
                          >
                            <div>
                              <AiOutlineInfoCircle color={"#0046B5"} />
                            </div>
                          </OverlayTrigger>
                        </div>
                      ) : (
                        <></>
                      )}
                      {/*<img*/}
                      {/*  src={item.url}*/}
                      {/*  // width={100}*/}
                      {/*  style={{ maxHeight: "100%", margin: "auto", width: "auto" }}*/}
                      {/*  alt=""*/}
                      {/*  data-dismiss="modal"*/}
                      {/*/>*/}
                    </div>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </Infinite>
      </div>
    );
  }
}

export default InfinteScroll;
