import React from "react";
import ChooseImage from "../ChooseImage";
import ChooseImageModal from "../ChooseImageModal";
import { changeLanguage } from "../../../redux/actions";
import { connect } from "react-redux";
import ConfigActionButtons from "./ConfigActionButtons";
import { withTranslation } from "react-i18next";
import background from "../ThemeEdit/background";
import { IoTrashOutline } from 'react-icons/io5'
import FeatureUnavailable from "../../Subscriptions/FeatureUnavailable";

class Configure extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    valError: "",
    isPermited: true,
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  handleChangeFavicon = (image) => {
    this.props.changeConfigureDetails({
      target: { name: "favicon", value: image },
    });
  };

  handleConfigElements(e) {

    const { title, slug, favicon, direction } = this.props.data;
    this.props.changeConfigureDetails(e);
    if (!e.target.value || !title || !slug) {
      this.setState({
        valError: "*One of the fields is empty, this can cause errors",
      });
    } else {
      this.setState({ valError: "" });
      this.props.changeConfigureDetails(e);
    }
  }

  render() {
    const { title, slug, favicon, direction, removeBranding } = this.props.data;
    const styles = {
      editor: {
        border: "1px solid gray",
        minHeight: "6em",
      },
    };
    const { t, permitions } = this.props;

    if (!this.state.isPermited) {
      return <><FeatureUnavailable isShow="true" hide={() => this.setState({ isPermited: true })} feature="remove_branding" /></>
    }
    return (
      <div>
        <h2>{t("configuration-configure-general")}</h2>
        <div className="fields">
          <label> {t("configuration-quiz-title")}</label>
          <input
            type="text"
            name="title"
            placeholder={title}
            onChange={(e) => this.handleConfigElements(e)}
            className="form-control"
          />
        </div>
        <div className="fields">
          <label>{t("configuration-quiz-slug")}</label>
          <input
            type="text"
            name="slug"
            placeholder={slug}
            onChange={(e) => this.handleConfigElements(e)}
            className="form-control"
          />
        </div>

        <div className="fields">
          <label>{t("configuration-quiz-direction")}</label>
          <select
            name="direction"
            value={direction}
            onChange={(e) => this.handleConfigElements(e)}
            className="form-control"
          >
            <option value="ltr">{t("configuration-quiz-direction-ltr")}</option>
            <option value="rtl">{t("configuration-quiz-direction-rtl")}</option>
          </select>

        </div>
        <div className="fields">
          <label> {t("configuration-quiz-favicon")} </label>
          <button
            className={favicon ? "abbIcon border-0 noAdd" : "abbIcon border-0"}
            name="favicon"
            onChange={(e) => this.handleConfigElements(e)}
            data-toggle="modal"
            data-target={`#modalfavicon`}
          >
            {/*<img*/}
            {/*    style={{ marginLeft: "15px" }}*/}
            {/*    src={favicon}*/}
            {/*    width="100px"*/}
            {/*    alt=""*/}
            {/*/>*/}
            <ChooseImageModal
              index="favicon"
              size={{
                width: "100%",
                height: "100%",
                backgroundColor: "transParent",
              }}
              id="favicon"
              image={favicon ? favicon : "noFavicon"}
              handleChangeFavicon={this.handleChangeFavicon}
            />
          </button>
          <button style={{ right: 0, top: 0 }} className="btn- btn-link remove-image-icon" onClick={() => this.props.changeConfigureDetails({ target: { name: "favicon", value: "" } })}><IoTrashOutline size={20} /> </button>



        </div>


        <div className="addSwitch fields">
          <label htmlFor=""> Remove Branding: {!permitions.remove_branding ? <i class="bi bi-lock-fill"></i> : <></>}</label>

          <label className="switch">

            <input
              type="checkbox"
              name="removeBranding"
              checked={removeBranding ? true : false}
              onChange={(e) => {
                if (!permitions.remove_branding) return this.setState({ isPermited: false })
                this.props.changeConfigureDetails({ target: { name: "removeBranding", value: removeBranding ? false : true } })
                // setShowMsgUnpublished(false)
                // setShowLinkUnpublished(!showLinkUnpublished)
              }}
            />
            <span className="slider round"></span>

          </label>


        </div>


        <div>
          <span className="red">{this.state.valError}</span>
        </div>





        <ConfigActionButtons
          // handleAccessibilityChanges={this.props.handleAccessibilityChanges}
          // accessibilitySettings={this.props.accessibilitySettings}
          selectedFunc={
            this.props.saveEditedQuiz
          }
          toast={this.props.toast}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

export default withTranslation()(connect(mapStateToProps, null)(Configure));
