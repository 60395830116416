import "./account.css";
import React, { useState, useEffect } from "react";
import avatar from "../../images/avatar.png";
import { withTranslation } from "react-i18next";
import {
  changeLanguage,
  updateUserInfoActions,
  getUserInfoActions,
  updateUserPasswordActions,
} from "../../redux/actions";
import i18n from "../../translate/i18n";
import { connect } from "react-redux";

const PersonalDetails = (props) => {
  const {
    t,
    updateUserInfo,
    details,
    updateUserPassword,
    getUserinfo,
    passwordUpdate,
  } = props;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [languagePreference, setLanguagePreference] = useState(
    // localStorage.getItem("language")
    ""
  );
  let currentPassword = document.getElementById("oldPass");
  let newPassword = document.getElementById("newPass");
  let verifyNewPassword = document.getElementById("verifyNewPass");

  useEffect(() => {
    getUserinfo();
  }, []);

  useEffect(() => {
    if (!details.first_name) return;
    setFirstName(details.first_name);
    setLastName(details.last_name);
    setEmail(details.email);
    setLanguagePreference(details.language);
  }, [details]);

  useEffect(() => {
    const success = document.getElementById("profile-success");
    success.textContent = passwordUpdate.message;
    success.classList.remove("hide");
    success.style.fontSize = "2rem";
    if (
      passwordUpdate.message === "Something Went Wrong" ||
      passwordUpdate.message === "Password don't match DataBase"
    ) {
      success.style.color = "red";
      return;
    } else {
      success.style.color = "green";
    }
  }, [passwordUpdate]);

  const handleLanguage = (value) => {
    changeLanguage(value);
    i18n.changeLanguage(value);
    const tab = document.querySelector(
      ".profile-content.profile-wrapper > .tabs-wrapper > ul"
    );
    if (value === "he") {
      tab.classList.add("hebrew");
    } else {
      tab.classList.remove("hebrew");
    }
  };

  const handleUpdateUserInfo = () => {
    handleLanguage(languagePreference);
    localStorage.setItem("language", languagePreference);
    updateUserInfo(firstName, lastName, languagePreference);
  };

  const handlePasswordVisibility = (e) => {
    const elementBefore = e.target.previousSibling;
    elementBefore.type === "password"
      ? (elementBefore.type = "text")
      : (elementBefore.type = "password");
  };

  const handleBorder = (e) => {
    document.getElementById("profile-success").textContent = "";

    e.target.style.border = "1px solid lightgrey";
    if (e.target.nextSibling) {
      if (e.target.nextSibling.classList.contains("eye")) {
        if (e.target.nextSibling.nextSibling) {
          const parent = e.target.parentElement;
          parent.removeChild(parent.lastChild);
        }

        return;
      }
      const parent = e.target.parentElement;
      parent.removeChild(parent.lastChild);
    }
  };

  const handlePasswordUpdate = () => {
    const oldPass = currentPassword.value;
    const newPass = newPassword.value;
    const newPassCheck = verifyNewPassword.value;
    currentPassword.value = "";
    newPassword.value = "";
    verifyNewPassword.value = "";

    const elem = document.createElement("span");
    elem.classList.add("profile-error");

    if (oldPass === "") {
      if (currentPassword.nextSibling) {
        return;
      }
      currentPassword.style.border = "1px solid red";
      elem.innerText = `${t("error-message-empty-field")}`;
      currentPassword.insertAdjacentElement("afterend", elem);
      return;
    }

    if (oldPass.search(/[A-Z]/) < 0 || oldPass.length < 8) {
      currentPassword.style.border = "1px solid red";
      elem.innerText = `${t("error-message-capital-letter-characters")}`;
      currentPassword.insertAdjacentElement("afterend", elem);
      return;
    }

    if (newPass === "") {
      if (newPassword.nextSibling.classList.contains("eye")) {
        newPassword.style.border = "1px solid red";
        elem.innerText = `${t("error-message-empty-field")}`;
        newPassword.nextSibling.insertAdjacentElement("afterend", elem);
      }
      return;
    }

    if (newPass.length < 8 || newPass.search(/[A-Z]/) < 0) {
      newPassword.style.border = "1px solid red";
      elem.innerText = `${t("error-message-capital-letter-characters")}`;
      newPassword.nextSibling.insertAdjacentElement("afterend", elem);
      return;
    }

    if (newPassCheck === "") {
      if (verifyNewPassword.nextSibling.classList.contains("eye")) {
        verifyNewPassword.style.border = "1px solid red";
        elem.innerText = `${t("error-message-empty-field")}`;
        verifyNewPassword.nextSibling.insertAdjacentElement("afterend", elem);
      }
      return;
    }

    if (newPassCheck.length < 8 || newPassCheck.search(/[A-Z]/) < 0) {
      verifyNewPassword.style.border = "1px solid red";
      elem.innerText = `${t("error-message-capital-letter-characters")}`;
      verifyNewPassword.nextSibling.insertAdjacentElement("afterend", elem);
      return;
    }

    if (oldPass === newPass) {
      newPassword.style.border = "1px solid red";
      currentPassword.style.border = "1px solid red";
      const secondElement = document.createElement("span");
      secondElement.classList.add("profile-error");
      secondElement.innerText = `${t(
        "error-message-oldPassword-match-newPasswod"
      )}`;
      elem.innerText = `${t("error-message-oldPassword-match-newPasswod")}`;
      currentPassword.insertAdjacentElement("afterend", elem);
      newPassword.nextSibling.insertAdjacentElement("afterend", secondElement);

      return;
    }

    if (newPass !== newPassCheck) {
      newPassword.style.border = "1px solid red";
      verifyNewPassword.style.border = "1px solid red";
      const secondElement = document.createElement("span");
      secondElement.classList.add("profile-error");
      secondElement.innerText = `${t(
        "error-message-password-dont-match-both-fields"
      )}`;
      elem.innerText = `${t("error-message-password-dont-match-both-fields")}`;
      verifyNewPassword.nextSibling.insertAdjacentElement("afterend", elem);
      newPassword.nextSibling.insertAdjacentElement("afterend", secondElement);
      return;
    }

    updateUserPassword(oldPass, newPass);
  };

  return (
    <>
      <h2 className="ms-5"> {t("account-personal")} </h2>
      <div className="personal-wrapper">
        {/* left column */}
        <div className="personal-image">
          {/* <input type="file" hidden accept="image/jpeg, image/png, image/jpg" />
          <img
            src={avatar}
            style={{ width: "100px", height: "100px" }}
            alt="profile picture"
          /> */}
        </div>
        {/* middle column */}

        <div className="personal-details-center">
          <div className="control">
            <label htmlFor="firstName"> {t("account-first-name")}</label>
            <input
              type="text"
              id="firstName"
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
              // placeholder={t("account-placeholder-first-name")}
            />
          </div>
          <div className="control">
            <label htmlFor="company">{t("account-company-name")} </label>
            <input
              type="text"
              id="company"
              value={companyName}
              // placeholder={t("account-placeholder-company-name")}
            />
          </div>
          <div className="control">
            <label htmlFor="email">{t("account-email")} </label>
            {/*<input type="text" id="email" placeholder="john@gmail.com" />*/}
            {details.email}
          </div>
        </div>
        {/* right column */}

        <div className="personal-details-right">
          <div className="control">
            <label htmlFor="lastName"> {t("account-last-name")}</label>
            <input
              type="text"
              id="lastName"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              // placeholder={t("account-placeholder-last-name")}
            />
          </div>
          <div className="control">
            <label htmlFor="language">{t("account-pick-language")}</label>

            <select
              id="language"
              defaultValue={details.language}
              onChange={(e) => setLanguagePreference(e.target.value)}
            >
              {/*<option value={details.language} disabled>*/}
              {/*  {t("account-language-select")}*/}
              {/*</option>*/}
              <option value="en" selected={details.language === "en"}>
                {t("account-language-english")}
              </option>
              <option value="he" selected={details.language === "he"}>
                {t("account-language-hebrew")}
              </option>
            </select>
          </div>
          <div className="profile-save">
            <input
              type="button"
              value={t("general-save")}
              onClick={handleUpdateUserInfo}
            />
          </div>
        </div>
      </div>
      <div className="divider"></div>

      <h2 className="ms-5 mt-5">{t("account-change-password")} </h2>

      <div className="personal-wrapper ">
        <div className="left-empty"></div>

        <div className="personal-details-center">
          <div className="control">
            <label htmlFor="oldPass">{t("account-current-password")} </label>
            <input
              onFocus={(e) => handleBorder(e)}
              type="password"
              id="oldPass"
              placeholder={t("account-enter-current-password")}
            />
          </div>
          <div className="control">
            <label htmlFor="pass">{t("account-new-password")} </label>
            <input
              onFocus={(e) => handleBorder(e)}
              type="password"
              id="newPass"
              placeholder={t("account-placeholder-password")}
            />

            <div
              onClick={(e) => handlePasswordVisibility(e)}
              className={i18n.language == "he" ? "eye eye-rtl" : "eye"}
              id="leftEye"
            ></div>
          </div>
        </div>
        <div className="personal-details-right last-input">
          <div className="control  ">
            <label htmlFor="newPass">{t("account-confirm-password")}</label>

            <input
              onFocus={(e) => handleBorder(e)}
              type="password"
              id="verifyNewPass"
              placeholder={t("account-confirm-placeholder-password")}
            />

            <div
              id="rightEye"
              onClick={(e) => handlePasswordVisibility(e)}
              className={i18n.language == "he" ? "eye eye-rtl" : "eye"}
            ></div>
          </div>
          <div className="profile-save">
            <input
              type="button"
              value={t("general-save")}
              onClick={handlePasswordUpdate}
            />
          </div>
        </div>
      </div>
      <div className="profile-error-wrapper d-flex justify-content-center align-items-center">
        <span id="profile-success" className="profile-error hide">
          {t("account-success-message")}
        </span>
      </div>
    </>
  );
};

const mapDispatch = (dispatch) => {
  return {
    getUserInfo: () => {
      dispatch(getUserInfoActions());
    },
    updateUserInfo: (firstName, lastName, language) => {
      dispatch(updateUserInfoActions(firstName, lastName, language));
    },
    updateUserPassword: (oldPass, newPass) => {
      dispatch(updateUserPasswordActions(oldPass, newPass));
    },
    changeLanguage: (language) => dispatch(changeLanguage(language)),
  };
};

export default withTranslation()(connect(null, mapDispatch)(PersonalDetails));
