import React, { useState, useEffect } from "react";
import TriggerItem from "./TriggerItem";
import { FaQuestion, FaWindowClose } from "react-icons/fa";
import Collapse from "react-bootstrap/Collapse";
import Parameters from "./Modules/Parameters/Index.jsx";

export default function TriggerList(props) {
  const {
    currentTriggers,
    setExistingTrigger,
    updateTriggerActivity,
    apps,
    selectedConnection,
    selectedModule,
    params,
    actions,
    selectedApp,
    isValidated,
    setParams,
    moduleConnectionsOptions,
    dataWithVariables,
    moduleFieldsOptions,
    moduleAdditionalptions,
    validatePreSend,
    connectEditObject,
    updateTriggerName,
    deleteTrigger,
  } = props;

  const [isEdit, setIsEdit] = useState(false);
  const [selectedTrigger, setSelectedTrigger] = useState(null);
  const [editObject, setEditObject] = useState({});

  useEffect(() => {
    if (connectEditObject && connectEditObject.module) {
      setEditObject(connectEditObject);
    }
  }, [connectEditObject]);

  const closeEdit = () => {
    setIsEdit(false);
    setSelectedTrigger(null);
    setEditObject({});
    actions.resetEditObject();
  };

  const drawTriggerList = () => {
    return currentTriggers.map((original) => {
      const itr = Object.assign({}, original);

      const app = apps.find((app) => app.id === itr.app_id);
      if (!app) {
        itr.img = <FaQuestion size={40} />;
        itr.appName = "Unknown";
      } else {
        itr.img = <img src={app.image} alt={app.name} />;
        itr.appName = app.name;
      }

      return (
        <TriggerItem
          setExistingTrigger={setExistingTrigger}
          updateTriggerActivity={updateTriggerActivity}
          itr={itr}
          setIsEdit={setIsEdit}
          setSelectedTrigger={setSelectedTrigger}
          getTriggerFullDetails={actions.getTriggerFullDetails}
          closeEdit={closeEdit}
          isEdit={isEdit}
          updateTriggerName={updateTriggerName}
          deleteTrigger={deleteTrigger}
        />
      );
    });
  };

  return (
    <div className="d-flex row bg-white p-4" style={{ height: "100%" }}>
      <div className="col mt-3">{drawTriggerList()}</div>

      <Collapse in={isEdit} dimension="width">
        <div className="w-50 mt-3">
          {" "}
          <button
            className="edit-trigger-close-btn"
            onClick={() => closeEdit()}
          >
            <FaWindowClose size="15" />
          </button>
          {selectedTrigger && selectedTrigger.id ? (
            <div
              className="w-100 connect-parameters connectApp"
              style={{
                background: "#F2F1FF",
                borderRadius: "7px",
                padding: "35px",
              }}
            >
              <h4>{selectedTrigger.label} </h4>
              <br />
              {editObject &&
              editObject.module &&
              editObject.module.module_parameters ? (
                <>
                  <Parameters
                    moduleParameters={editObject.module.module_parameters}
                    moduleName={editObject.module.name}
                    selectedConnection={editObject.connection}
                    setParams={setParams}
                    getModulesConnectionsOptions={
                      actions.getModulesConnectionsOptions
                    }
                    selectedTrigger={selectedTrigger}
                    setSelectedTrigger={setSelectedTrigger}
                    dataWithVariables={dataWithVariables}
                    triggerRpc={actions.triggerRpc}
                    params={params}
                    selectedIntegrationAppId={selectedTrigger.app_id}
                    moduleAdditionalptions={moduleAdditionalptions}
                    moduleConnectionsOptions={moduleConnectionsOptions}
                    moduleFieldsOptions={moduleFieldsOptions}
                  />

                  <div className="mt-3">
                    <button
                      disabled={!isValidated}
                      className={
                        isValidated
                          ? "btn btn-lg purple-btn"
                          : "btn btn-success"
                      }
                      onClick={() => {
                        validatePreSend();
                        closeEdit();
                      }}
                    >
                      Update
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </Collapse>
    </div>
  );
}
