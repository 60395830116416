import React, { useState, useEffect } from "react";
import Fonts from "../../../utils/Fonts/Fonts";
import ChooseImageModal from "../ChooseImageModal";
import ColorPicker from "../ColorPicker";
import SelectSize from "../SelectSize";
import "./theme.css";
import Justify from "../../justify/justify";
import { withTranslation } from "react-i18next";

const FooterStyle = (props) => {
  const {
    t,
    id,
    context,
    selectedTheme,
    footerObj,
    fonts,
    addLinkToNav,
    onSwatchHover,
    changeNavs,
    removeLinkFromNav,
    handleChange,
    props: mainProps
  } = props;



  if (!selectedTheme) return <></>;
  if (selectedTheme == "override-edit" && !footerObj.isOverride && typeof handleChange === 'function' && mainProps.mainSettings) {
    // props.style[context] = Object.assign({}, settings[context]);
    Object.entries(mainProps.mainSettings).forEach(([key, value]) => {
      handleChange(
        { target: { name: key, value } },
        id,
        context
      )
    })
    handleChange(
      { target: { name: "isOverride", value: true } },
      id,
      context
    )


  }
  const footerLinks = Object.values(footerObj.links) || {};
  return (
    <div>
      <div className="form-group d-flex justify-content-center fontStyle">
        <div>
        <label>{t("theme-accordion-background-color")}</label>
        <br />
        <div className="colorBox">
          <ColorPicker
              color={footerObj.background}
              setRight="inherit"
              handleChange={(e) => {
                if (selectedTheme == "override-edit") {
                  props.handleColorsToChange(e, "background", "footer");
                } else {
                  onSwatchHover(e, context)
                }
              }}
          />
        </div>
        </div>
        <Justify
            handleStyleFunctionName={
              selectedTheme == "override-edit"
                  ? "handleQuestionStyle"
                  : "changeMainStyle"
            }
            targetName={"textAlign"}
            value={footerObj.textAlign || "center"}
            actionFunction={(e) => {

              if (selectedTheme == "override-edit") {
                handleChange(e, props.id, "footer");
              } else {

                changeNavs(e, context);
                handleChange(e, context);
              }
            }}
            selectedJustify={footerObj.textAlign || "center"}
        />
      </div>
      <br />
      {selectedTheme === "override-edit" ? <>
        <div className="addSwitch">
          <label className="switch">
            <input
              type="checkbox"
              name="hideLinks"
              checked={footerObj.hidelinks}
              onChange={(e) => handleChange({ target: { value: e.target.checked, name: "hideLinks" } }, props.id, "footer")}
            />
            <span className="slider round"></span>
          </label>
          Hide links
        </div></> : <>
        {footerLinks.map((footerLink) => {
          const { id, title, link, icon } = footerLink;

          return (
            <div key={id} className="row">
              <div style={{ width: "55%" }}>
                <label> {t("theme-accordion-title")} </label>
                <input
                  type="text"
                  name="title"
                  placeholder={t("theme-accordion-text")}
                  value={title}
                  onChange={(e) => changeNavs(e, context, id)}
                  className="form-control"
                />
              </div>

              <div style={{ width: "35%" }}>
                <label> {t("theme-accordion-icon")} </label>
                <select
                  name="icon"
                  placeholder={t("theme-accordion-icon")}
                  value={icon || ""}
                  onChange={(e) => changeNavs(e, context, id)}
                  className="form-control"
                >
                  <option value="" title="" className="">
                    None
                  </option>
                  <option value="email on" title="" className="email on">
                    Email
                  </option>
                  <option value="twitter" title="" className="twitter">
                    Twitter
                  </option>
                  <option value="facebook" title="" className="facebook">
                    Facebook
                  </option>
                  <option value="whatsapp" title="" className="whatsapp">
                    Whatsapp
                  </option>
                  <option value="vk" title="" className="vk">
                    Vk
                  </option>
                  <option value="linkedin" title="" className="linkedin">
                    Linkedin
                  </option>
                  <option value="link" title="" className="link">
                    Link
                  </option>
                </select>
              </div>
              <div style={{ width: "90%" }}>
                <label> {t("theme-accordion-link")}</label>
                <input
                  type="text"
                  name="link"
                  placeholder={t("theme-accordion-url")}
                  value={link}
                  onChange={(e) => changeNavs(e, context, id)}
                  className="form-control"
                />
              </div>
              <div style={{ width: "10%" }} className="answer-icons">
                <a
                  role="button"
                  title=""
                  onClick={(e) => removeLinkFromNav(e, context, id)}
                >
                  <svg
                    id="Layer_1"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 14 18"
                  >
                    <defs></defs>
                    <path
                      d="M18.5,12.09h3v.5h1.09V12a1,1,0,0,0-1-1H18.42a1,1,0,0,0-1,1v.57H18.5Z"
                      transform="translate(-13 -11)"
                    />
                    <path
                      d="M25.1,16.9H14.9a.48.48,0,0,0-.48.51L15.28,28a1.12,1.12,0,0,0,1.12,1h7.2a1.12,1.12,0,0,0,1.12-1l.86-10.55A.48.48,0,0,0,25.1,16.9Zm-7.73,11h0a.56.56,0,0,1-.55-.51l-.53-8.66a.53.53,0,0,1,.51-.57.52.52,0,0,1,.57.5l.54,8.66A.55.55,0,0,1,17.37,27.88Zm3.18-.55a.55.55,0,0,1-.54.55.55.55,0,0,1-.55-.55V18.68a.55.55,0,0,1,.55-.55.55.55,0,0,1,.54.55Zm2.68,0a.55.55,0,0,1-.54.51h0a.55.55,0,0,1-.51-.58l.51-8.65a.54.54,0,1,1,1.08.06Z"
                      transform="translate(-13 -11)"
                    />
                    <path
                      d="M27,15.22l-.36-1.07a.69.69,0,0,0-.65-.47H14a.69.69,0,0,0-.65.47L13,15.22a.44.44,0,0,0,.19.52.4.4,0,0,0,.24.07h13.1a.4.4,0,0,0,.24-.07A.44.44,0,0,0,27,15.22Z"
                      transform="translate(-13 -11)"
                    />
                  </svg>
                </a>
              </div>
            </div>
          );
        })}
        <a
          onClick={(e) => addLinkToNav(e, context)}
          title=""
          className="add-link"
        >
          {t("theme-accordion-add-link")}
        </a> </>}

    </div>
  );
};

export default withTranslation()(FooterStyle);
