import React, { useEffect, useState } from "react";
import { CgToggleSquare, CgToggleSquareOff } from "react-icons/cg";
import { connect } from "react-redux";
import {
  updateUnpublishConditionsAction,
  getUnpublishConditionsAction,
} from "../../../redux/actions";
import { withTranslation } from "react-i18next";
import DateTimePicker from "react-datetime-picker";
import ConfigActionButtons from "./ConfigActionButtons";
import moment from "moment";
import FeatureUnavailable from "../../Subscriptions/FeatureUnavailable";

function Unpublished(props) {
  const {
    t,
    data,
    publishQuiz,
    unpublishCondition,
    permitions,
    saveEditedQuiz,
    changeDuplicationsDetiles,
  } = props;
  const [showDateUnpublished, setShowDateUnpublished] = useState(false);
  const [showCountUnpublished, setShowCountUnpublished] = useState(false);
  const [showMsgUnpublished, setShowMsgUnpublished] = useState(false);
  const [showLinkUnpublished, setShowLinkUnpublished] = useState(false);
  const [condition, setCondition] = useState("||");
  const [conditionDate, setConditionDate] = useState(null);
  const [conditionCount, setConditionCount] = useState(null);
  const [unpublishMsg, setUnpublishMsg] = useState("");
  const [unpublishRedirectLink, setUnpublishRedirectLink] = useState("");
  const [unpublishDesc, setUnpublishDesc] = useState("");
  const [isPermited, setIsPermited] = useState(true);

  const [noDuplicates, setNoDuplicates] = useState(false);
  const [noDuplicatesTitle, setNoDuplicatesTitle] = useState("");
  const [noDuplicatesBody, setNoDuplicatesBody] = useState("");

  useEffect(() => {
    // send to server and get conditions data
    if (data) {
      props.actions.getUnpublishConditions(data.id);
    }
  }, []);

  useEffect(() => {
    if (data.duplications) {
      setNoDuplicates(
        data.duplications.noDuplicates ? data.duplications.noDuplicates : false
      );
      setNoDuplicatesTitle(
        data.duplications.duplicationsTitle
          ? data.duplications.duplicationsTitle
          : ""
      );
      setNoDuplicatesBody(
        data.duplications.duplicationsBody
          ? data.duplications.duplicationsBody
          : ""
      );
    }
  }, [data]);

  useEffect(() => {
    if (unpublishCondition) {
      if (unpublishCondition.unpublish_condition) {
        setCondition(unpublishCondition.unpublish_condition);
      }
      if (unpublishCondition.unpublish_date) {
        setConditionDate(unpublishCondition.unpublish_date);
      }
      if (unpublishCondition.unpublish_count) {
        setConditionCount(unpublishCondition.unpublish_count);
      }
      if (unpublishCondition.is_date) {
        setShowDateUnpublished(unpublishCondition.is_date);
      }
      if (unpublishCondition.is_count) {
        setShowCountUnpublished(unpublishCondition.is_count);
      }
      if (unpublishCondition.unpublish_msg) {
        setUnpublishMsg(unpublishCondition.unpublish_msg);
      }
      if (unpublishCondition.is_msg) {
        setShowMsgUnpublished(unpublishCondition.is_msg);
      }
      if (unpublishCondition.unpublish_desc) {
        setUnpublishDesc(unpublishCondition.unpublish_desc);
      }

      if (unpublishCondition.is_link) {
        setShowLinkUnpublished(unpublishCondition.is_link);
      }
      if (unpublishCondition.unpublish_redirect_link) {
        setUnpublishRedirectLink(unpublishCondition.unpublish_redirect_link);
      }
    }
  }, [unpublishCondition]);

  const sendUnpublishedConditions = () => {
    saveEditedQuiz();
    if (showMsgUnpublished && !unpublishMsg)
      return alert("can't save empty message");
    const conditions = {
      conditionType: condition,
      conditionDate,
      conditionCount,
      isDate: showDateUnpublished,
      isCount: showCountUnpublished,
      quizId: data.id,
      unpublishMsg,
      isMsg: showMsgUnpublished,
      unpublishDesc,
      unpublishRedirectLink,
      isLink: showLinkUnpublished,
    };
    // send to server
    props.actions.updateUnpublishConditions(conditions);
  };

  const handleDuplicatesChange = (type, value) => {
    if (type == "noDuplicates") {
      // setNoDuplicates(value)
      changeDuplicationsDetiles({ target: { name: "noDuplicates", value } });
    }
    if (type == "noDuplicatesTitle") {
      // setNoDuplicatesTitle(value)
      changeDuplicationsDetiles({
        target: { name: "duplicationsTitle", value },
      });
    }
    if (type == "noDuplicatesBody") {
      // setNoDuplicatesBody(value)
      changeDuplicationsDetiles({
        target: { name: "duplicationsBody", value },
      });
    }
  };

  const renderAndOrToggle = (index) => {
    if (condition === "||") {
      return (
        <>
          {" "}
          <span className="text-success fw-bold">OR</span>
          <button className=" btn">
            <CgToggleSquare
              size={20}
              onClick={() => {
                setCondition(condition == "||" ? "&&" : "||");
              }}
            />
          </button>{" "}
          <span className="text-muted fw-light">AND</span>
        </>
      );
    } else {
      return (
        <>
          {" "}
          <span className="text-muted fw-light">OR</span>
          <button className=" btn">
            <CgToggleSquareOff
              size={20}
              onClick={() => {
                setCondition(condition == "||" ? "&&" : "||");
              }}
            />
          </button>{" "}
          <span className="text-success fw-bold">AND</span>
        </>
      );
    }
  };

  if (data && data.published != 1) {
    return (
      <div>
        <h2>This quiz is currently unpublished</h2>
      </div>
    );
  }
  if (!isPermited) {
    return (
      <>
        <FeatureUnavailable
          isShow="true"
          hide={() => setIsPermited(true)}
          feature="unpublish_conditions"
        />
      </>
    );
  }

  return (
    <div>
      <h2 style={{ padding: "0 0 20px 0" }}>Limit and Scheduling</h2>
      {/*<h2>Unpublish this Quiz</h2>*/}
      {/*<div className="d-flex w-100">*/}
      {/*    <h3 className="m-5">Unpublish now:</h3>*/}
      {/*    <button className="m-5 btn btn-danger" onClick={() => {*/}
      {/*        if (window.confirm("Are you sure you want to unpublish this quiz?")) {*/}
      {/*            publishQuiz(2)*/}
      {/*        }*/}
      {/*    }}> unpublish</button>*/}
      {/*</div>*/}
      <div className="d-flex unpublish-div">
        <div className="addSwitch override w-25">
          <label className="switch">
            <input
              type="checkbox"
              name="withSubtitle"
              checked={showDateUnpublished}
              onChange={(e) => {
                if (!permitions.unpublish_date) return setIsPermited(false);
                setShowDateUnpublished(!showDateUnpublished);
              }}
            />
            <span className="slider round "></span>
          </label>
          Unpublish on date:{" "}
          {!permitions.unpublish_date ? (
            <i class="bi bi-lock-fill "></i>
          ) : (
            <></>
          )}
        </div>
        {showDateUnpublished && (
          <div className="w-50">
            <DateTimePicker
              onChange={(e) => {
                if (!e) {
                  setConditionDate(null);
                  return;
                }
                setConditionDate(moment(e).format("YYYY-MM-DD hh:mm:ss"));
              }}
              maxDate={new Date(9999, 12, 31)}
              value={conditionDate ? new Date(conditionDate) : null}
              disableClock={true}
            />
          </div>
        )}
      </div>
      {showDateUnpublished && showCountUnpublished ? (
        <div className="d-flex w-75 m-3" style={{ alignItems: "center" }}>
          {renderAndOrToggle()}
        </div>
      ) : (
        <></>
      )}

      <div className="d-flex  unpublish-div">
        <div className="addSwitch override w-25">
          <label className="switch">
            <input
              type="checkbox"
              name="withSubtitle"
              checked={showCountUnpublished}
              onChange={(e) => {
                if (!permitions.unpublish_count) return setIsPermited(false);
                setShowCountUnpublished(!showCountUnpublished);
              }}
            />
            <span className="slider round"></span>
          </label>
          Unpublish on Count:{" "}
          {!permitions.unpublish_count ? (
            <i class="bi bi-lock-fill "></i>
          ) : (
            <></>
          )}
        </div>
        {showCountUnpublished && (
          <div className="w-10">
            <input
              type="number"
              name={"count"}
              value={conditionCount || 0}
              className="form-control"
              onChange={(e) => setConditionCount(e.target.value)}
            />
          </div>
        )}
      </div>

      {/* copy of msg for link */}

      <div className="d-flex unpublish-div">
        <div className="addSwitch override w-25">
          <label className="switch">
            <input
              type="checkbox"
              name="withSubtitle"
              checked={showLinkUnpublished ? true : false}
              onChange={(e) => {
                if (!permitions.unpublish_redirect_link)
                  return setIsPermited(false);
                setShowMsgUnpublished(false);
                setShowLinkUnpublished(!showLinkUnpublished);
              }}
            />
            <span className="slider round"></span>
          </label>
          Unpublish Redirect Link:{" "}
          {!permitions.unpublish_redirect_link ? (
            <i class="bi bi-lock-fill"></i>
          ) : (
            <></>
          )}
        </div>

        {showLinkUnpublished && (
          <div className="w-50">
            <input
              type="text"
              name={"count"}
              value={unpublishRedirectLink || ""}
              className="form-control"
              placeholder="https://"
              onChange={(e) => setUnpublishRedirectLink(e.target.value)}
            />
          </div>
        )}
      </div>

      {/* real one */}
      <div className="d-flex unpublish-div">
        <div className="addSwitch override w-25">
          <label className="switch">
            <input
              type="checkbox"
              name="withSubtitle"
              checked={showMsgUnpublished ? true : false}
              onChange={(e) => {
                if (!permitions.unpublish_message) return setIsPermited(false);
                setShowLinkUnpublished(false);
                setShowMsgUnpublished(!showMsgUnpublished);
              }}
            />
            <span className="slider round "></span>
          </label>
          Custom Unpublish Message:{" "}
          {!permitions.unpublish_message ? (
            <i class="bi bi-lock-fill "></i>
          ) : (
            <></>
          )}
        </div>

        {showMsgUnpublished && (
          <>
            <div className="w-50">
              <div style={{ marginBottom: "10px", marginTop: "10px" }}>
                <input
                  type="text"
                  name={"unpublishMsg"}
                  value={unpublishMsg}
                  className="form-control"
                  placeholder="Header"
                  onChange={(e) => setUnpublishMsg(e.target.value)}
                />
              </div>
              <div style={{ marginBottom: "10px" }}>
                <textarea
                  name={"unpublishMsg"}
                  value={unpublishDesc}
                  className="form-control"
                  placeholder="Description"
                  onChange={(e) => setUnpublishDesc(e.target.value)}
                />
              </div>
            </div>
          </>
        )}
      </div>

      <div className="d-flex unpublish-div">
        <div className="addSwitch override w-25">
          <label className="switch">
            <input
              type="checkbox"
              name="noDuplicates"
              checked={noDuplicates ? noDuplicates : false}
              onChange={(e) => {
                handleDuplicatesChange(e.target.name, e.target.checked);
              }}
            />
            <span className="slider round "></span>
          </label>
          {"   "} Prevent duplicate submitions
        </div>
        {noDuplicates && (
          <>
            <div className="w-50">
              <div style={{ marginBottom: "10px", marginTop: "10px" }}>
                <input
                  type="text"
                  name={"noDuplicatesTitle"}
                  value={noDuplicatesTitle}
                  className="form-control"
                  placeholder="Header"
                  onChange={(e) =>
                    handleDuplicatesChange(e.target.name, e.target.value)
                  }
                />
              </div>
              <div style={{ marginBottom: "10px" }}>
                <textarea
                  name={"noDuplicatesBody"}
                  value={noDuplicatesBody}
                  className="form-control"
                  placeholder="Description"
                  onChange={(e) =>
                    handleDuplicatesChange(e.target.name, e.target.value)
                  }
                />
              </div>
            </div>
          </>
        )}
      </div>

      <div>
        <ConfigActionButtons
          selectedFunc={sendUnpublishedConditions}
          toast={showMsgUnpublished && !unpublishMsg ? null : props.toast}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { unpublishCondition, permitions } = state;
  return { unpublishCondition, permitions };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      updateUnpublishConditions: (condition) =>
        dispatch(updateUnpublishConditionsAction(condition)),

      getUnpublishConditions: (quizId) =>
        dispatch(getUnpublishConditionsAction(quizId)),
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Unpublished)
);
