import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Navbar from "../Navbar";
import Collapse from "react-bootstrap/Collapse";
import Spinner from "react-bootstrap/Spinner";
import {
  sendInviteAction,
  getOrgsUsers,
  getOrgsUsersSuccess,
  getOrgFeeds,
  createNewFeed,
  updateFeedData,
  uploadCSVProductsFile,
  deleteFeedError,
  importProductsAction,
  deleteFeedAction,
} from "../../redux/actions";

import Modal from "react-bootstrap/esm/Modal";
import { Accordion } from "react-bootstrap";
import FeedAccordion from "./FeedAccordion";

function Organizations(props) {
  const {
    name = "",
    active,
    t,
    orgArray,
    orgId,
    permitions,
    orgUsers,
    orgFeeds,
  } = props;
  const [selectedOrg, setSelectedrg] = useState(null);
  const [usersModal, setUsersModal] = useState(false);
  const [createFeedModal, setCreateFeedModal] = useState(false);
  const [permitrd, setPermitrd] = useState(true);
  const [isErorr, setIsErorr] = useState(false);
  const [orgUsersArray, setOrgUsersArray] = useState([]);
  const [newFeedName, setNewFeedName] = useState("");
  const [collapsedFeedId, setCollapsedFeedId] = useState(null);

  useEffect(() => {
    if (orgId && orgArray) {
      const org = orgArray.find((org) => org.id === orgId);
      const orgObj = orgArray ? orgArray.find((el) => el.id === orgId) : null;
      const orgKeys = orgObj && orgObj.keys ? orgObj.keys.split(",") : [];
      if (
        !permitions ||
        !permitions.add_users ||
        !orgKeys.includes("add_users")
      ) {
        setPermitrd(false);
      }
      setSelectedrg(org);
    }
  }, [orgArray, orgId]);

  useEffect(() => {
    props.actions.getUsers();
    props.actions.getOrgFeeds();
    return () => {
      props.actions.resetUsers();
    };
  }, []);

  useEffect(() => {
    if (orgUsers) {
      setOrgUsersArray(orgUsers);
    }
  }, [orgUsers]);

  const validateEmail = (email = "") => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(email).toLowerCase());
  };

  const handleCreateNewFeed = () => {
    if (!newFeedName) return;
    setCreateFeedModal(false);
    //create;
    props.actions.createNewFeed(newFeedName);
    setNewFeedName("");
  };

  // const { selectedDetails } = props;
  // const [showCustomMsg, setShowCustomMsg] = useState(false);
  if (!selectedOrg)
    return (
      <div className="w-100 d-flex ">
        <Spinner
          style={{
            width: "400px",
            height: "400px",
            border: ".9em solid currentColor",
            borderRightColor: "transparent",
            margin: "auto",
          }}
          animation="border"
          variant="success"
        />
      </div>
    );

  if (!permitrd) return <Redirect to="/dashboard" />;

  return (
    <div className="integration-page">
      <Navbar active="Organization" helpLabel={t("help-center")} />
      <section className="question-content p-1">
        <div className="integrationInner">
          <h1 className="mb-5">{selectedOrg.name}</h1>
          <h5>Users:</h5>
          <div className="d-flex w-100">
            <div className="w-75 ">
              {orgUsersArray.map((user, index) => (
                <div className="d-flex justify-content-between w-50 m-3">
                  <span>{user.email}</span>
                  <span>{user.role_name.replace("_", " ")}</span>
                </div>
              ))}
            </div>
            <div className="w-25 ">
              <button
                className="btn btn-secondary"
                onClick={() => {
                  setUsersModal(true);
                }}
              >
                Add user
              </button>
            </div>
          </div>
        </div>
        <div className="integrationInner">
          <h5>Feeds:</h5>
          <div className="d-flex w-100">
            <div className="w-75 ">
              <Accordion style={{ width: "800px" }}>
                {orgFeeds.map((feed) => {
                  return (
                    <FeedAccordion
                      feed={feed}
                      updateFeedData={props.actions.updateFeedData}
                      uploadCSVProductsFile={
                        props.actions.uploadCSVProductsFile
                      }
                      deleteFeedError={props.actions.deleteFeedError}
                      importProductsManualy={
                        props.actions.importProductsManualy
                      }
                      deleteFeedAction={props.actions.deleteFeedAction}
                    />
                  );
                })}
              </Accordion>
            </div>
            <div className="w-25 ">
              <button
                onClick={() => setCreateFeedModal(true)}
                className="btn btn-secondary"
              >
                Add feed
              </button>
            </div>
          </div>
        </div>
      </section>

      <Modal
        className="package-modal"
        size="m"
        show={usersModal}
        onHide={() => {
          setUsersModal(false);
        }}
        centered
      >
        <div>
          <h2>Invite to {selectedOrg.name} team</h2>
          <span> Enter Emails of users you want to invite</span>
          <textarea
            className="form-control"
            style={{ resize: "none" }}
            name=""
            id=""
            cols="30"
            rows="5"
          ></textarea>
          <div className="d-flex flex-column justify-content-center">
            <span>You Have 0 licences remaining vacent</span>
            <button
              className=" btn btn-primary w-25 m-auto"
              onClick={(e) => {
                const emailsArray = e.target.parentElement.parentElement
                  .querySelector("textarea")
                  .value.split("\n");

                let isErorr = false;
                emailsArray.forEach((email) => {
                  if (!validateEmail(email)) {
                    setIsErorr(true);
                    isErorr = true;
                  }
                });
                if (!isErorr) {
                  setIsErorr(false);
                  setUsersModal(false);
                  props.actions.sendInvite(emailsArray);
                }
              }}
            >
              Send invitations
            </button>
          </div>
        </div>
        {isErorr && (
          <div className="alert alert-danger mt-4">
            There seems to be a problem.
            <br />
            Please enter valid emails
          </div>
        )}
      </Modal>
      <Modal
        className="package-modal"
        size="m"
        show={createFeedModal}
        onHide={() => {
          setCreateFeedModal(false);
        }}
        centered
      >
        <div>
          <h2>Create new feed</h2>
          <input
            type="text"
            placeholder="Enter feed name"
            value={newFeedName}
            onChange={(e) => setNewFeedName(e.target.value)}
            className="form-control"
          />
        </div>
        <button
          className=" btn btn-primary w-25 m-auto"
          onClick={handleCreateNewFeed}
          disabled={!newFeedName}
        >
          Create
        </button>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { loginDetails, orgUsers, orgFeeds } = state;
  let { name, orgArray, orgId } = loginDetails;
  return {
    name,
    orgArray,
    orgId,
    permitions: state.permitions,
    orgUsers,
    orgFeeds: Array.isArray(orgFeeds) ? orgFeeds : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      sendInvite: (emails) => dispatch(sendInviteAction(emails)),
      getUsers: () => dispatch(getOrgsUsers()),
      getOrgFeeds: () => dispatch(getOrgFeeds()),
      createNewFeed: (feedName) => dispatch(createNewFeed(feedName)),
      updateFeedData: (feedData) => dispatch(updateFeedData(feedData)),
      deleteFeedError: (feedErrorId) => dispatch(deleteFeedError(feedErrorId)),
      uploadCSVProductsFile: (formData, feedId) =>
        dispatch(uploadCSVProductsFile(formData, feedId)),
      resetUsers: () => dispatch(getOrgsUsersSuccess([])),
      importProductsManualy: (feedId) => dispatch(importProductsAction(feedId)),
      deleteFeedAction: (feedId) => dispatch(deleteFeedAction(feedId)),
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Organizations)
);
