import React from "react";
import "../Add.css";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  VKIcon,
  WhatsappIcon,
} from "react-share";

class AddResult extends React.Component {
  constructor() {
    super();

    this.state = {
      editorTitleState: "",
      editorDescriptionState: "",
    };
  }

  render() {
    const { id, title, description, redirectTo, minScore, maxScore, share, resultType } =
      this.props.data.onEdit;
    const { facebook, whatsapp, linkedin, telegram, twitter, vk, email } =
      share;
    return (
      <div className="question-section container">
        <div className="form-group">
          <label>title: </label>
          {/* <Editor
                        defaultEditorState={this.state.editorTitleState}
                        onEditorStateChange={(e) => this.onEditorStateChange(e, id, "title")}
                        toolbar={toolbarConfig}
                    /> */}
          <input
            text={this.props.data.onEdit.title}
            id={id}
            onChange={this.props.handleChangeResult}
            name="title"
          />
        </div>
        <div className="form-group">
          <label>Description: </label>

          <input
            text={this.props.data.onEdit.description}
            id={id}
            onChange={this.props.handleChangeResult}
            name="description"
          />
        </div>
        <div>
          <label>Score:</label>
          <div className="result-input-div">
            <label>Min: </label>
            <input
              type="number"
              value={minScore}
              max={3}
              className="form-control"
              onChange={(e) => this.props.handleChangeResult(e, id)}
              name="minScore"
            />
          </div>
          <div className="result-input-div">
            <label>Max: </label>
            <input
              type="number"
              value={maxScore}
              className="form-control"
              onChange={(e) => this.props.handleChangeResult(e, id)}
              name="maxScore"
            />
          </div>
        </div>
        <div className="result-input-div">
          <label> Redirect To (optional): </label>
          <input
            type="text"
            name="redirectTo"
            onChange={(e) => this.props.handleChangeResult(e, id)}
            className="form-control"
            value={redirectTo}
            placeholder="Enter url..."
          />
        </div>
        <div className="innerBox cardBox">
          <div className="card">
            <div className="card-header" id="mediaBox">
              <h3 className="mb-0">
                <a
                  className="btn collapsed"
                  href="#button"
                  type="button"
                  data-toggle="collapse"
                  data-target="#button"
                  aria-expanded="false"
                  aria-controls="button"
                >
                  Share On Social Media
                </a>
              </h3>
            </div>

            <div id="button" className="collapse" aria-labelledby="mediaBox">
              <div className="card-body">
                <a role="button" title="" className="edit">
                  <svg
                    id="Capa_1"
                    enableBackground="new 0 0 512.279 512.279"
                    height="512"
                    viewBox="0 0 512.279 512.279"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <path d="m230.23 482.566c81.432 0 160.296-43.89 160.296-121.32 0-33.771-20.231-52.689-36.487-67.89-31.828-29.761-31.828-44.459 0-74.22 16.256-15.201 36.487-34.119 36.487-67.89 0-57.859-44.772-97.744-101.344-113.512-89.916-25.064-200.348 8.857-256.154 101.184-43.828 71.666-44.234 162.327-.04 234.587 43.024 71.181 119.202 109.061 197.242 109.061zm-171.568-328.062c48.345-79.982 145.44-109.341 222.465-87.872 49.717 13.858 79.398 45.489 79.398 84.614 0 20.751-12.502 32.442-26.978 45.978-43.525 40.7-45.656 75.352 0 118.044 14.476 13.536 26.978 25.227 26.978 45.978 0 60.864-67.557 91.236-130.038 91.234-62.823-.002-132.162-28.873-171.865-94.559-38.356-62.717-38.356-140.635.04-203.417z" />
                      <path d="m512.213 374.72c.002-12.015-4.673-23.313-13.167-31.814-.349-.349-.71-.682-1.068-1.018 3.436-19.579 14.278-86.015 14.302-144.583.018-45.254-6.213-109.051-10.145-138.633-3.653-27.497-34.036-28.92-34.137-28.959-18.093 0-33.494 13.543-35.824 31.503-4.785 36.884-9.879 97.102-9.895 136.052-.023 58.593 10.783 125.067 14.2 144.619-8.766 8.213-14.262 19.877-14.267 32.807-.013 32.601 28.327 90.256 31.559 96.723 2.543 5.09 7.912 8.518 14.05 8.263 8.117-.339 14.368-7.043 14.368-14.962.011-24.98 30.016-55.512 30.023-89.98 0-.003 0-.005 0-.007 0-.001 0-.005 0-.006.001-.001.001-.003.001-.005zm-50.289-309.644c.396-3.057 3.008-5.363 6.091-5.363.185.055 3.92-.554 4.382 2.911 4.113 30.952 9.899 93.196 9.883 134.669-.006 13.833-.668 28.263-1.708 42.813l-26.616-.001c-1.029-14.551-1.681-28.991-1.675-42.824.014-37.696 4.979-96.25 9.643-132.205zm-5.244 205.021 21.143.009c-2.729 24.79-6.095 46.765-8.23 59.672-1.587-.082-3.08-.088-4.725-.003-2.127-12.908-5.477-34.886-8.188-59.678zm7.917 151.391c-6.823-17.711-12.388-35.884-12.384-46.78.004-8.269 6.732-14.994 15.001-14.994h.006c8.29.004 14.997 6.714 14.993 15.006v.003s0 .002 0 .003c0 13.841-9.584 30.156-17.616 46.762z" />
                      <path d="m104.077 299.714c24.813 0 45-20.187 45-45s-20.187-45-45-45-45 20.187-45 45 20.187 45 45 45zm0-60c8.271 0 15 6.729 15 15s-6.729 15-15 15-15-6.729-15-15 6.729-15 15-15z" />
                      <path d="m186.577 319.666c-21.538-12.434-49.034-5.071-61.471 16.471-12.406 21.489-5.018 49.064 16.471 61.471 21.453 12.385 48.99 5.147 61.471-16.471 12.406-21.489 5.017-49.065-16.471-61.471zm-9.51 46.471c-4.136 7.163-13.325 9.626-20.49 5.49-7.163-4.135-9.626-13.328-5.49-20.49 4.1-7.099 13.231-9.681 20.49-5.491 7.163 4.136 9.626 13.328 5.49 20.491z" />
                      <path d="m261.577 189.762c21.551 12.442 49.041 5.058 61.471-16.471 12.406-21.489 5.018-49.065-16.471-61.471-21.537-12.435-49.034-5.07-61.471 16.471-12.406 21.489-5.017 49.064 16.471 61.471zm9.51-46.471c4.146-7.178 13.307-9.637 20.49-5.49 7.163 4.135 9.626 13.327 5.49 20.49-4.146 7.179-13.308 9.636-20.49 5.49-7.163-4.135-9.626-13.327-5.49-20.49z" />
                      <path d="m306.577 397.608c21.488-12.407 28.877-39.982 16.471-61.471-12.435-21.538-39.93-28.908-61.471-16.471-21.488 12.406-28.877 39.983-16.471 61.471 12.406 21.487 39.872 28.942 61.471 16.471zm-30-51.962c7.18-4.145 16.343-1.69 20.49 5.491 4.136 7.163 1.673 16.355-5.49 20.49-7.165 4.136-16.354 1.672-20.49-5.49-4.136-7.163-1.673-16.355 5.49-20.491z" />
                      <path d="m186.577 189.762c21.488-12.406 28.877-39.982 16.471-61.471-12.434-21.537-39.928-28.908-61.471-16.471-21.488 12.406-28.877 39.982-16.471 61.471 12.431 21.531 39.924 28.911 61.471 16.471zm-30-51.962c7.193-4.153 16.349-1.681 20.49 5.49 4.136 7.163 1.673 16.355-5.49 20.49-7.18 4.145-16.343 1.69-20.49-5.49-4.136-7.162-1.673-16.354 5.49-20.49z" />
                    </g>
                  </svg>
                </a>
              </div>
              <div className="row">
                <div>
                  <label>
                    <FacebookIcon size={32} />
                  </label>
                  <input
                    type="checkbox"
                    className="form-control"
                    checked={facebook}
                    name="facebook"
                    onChange={(e) => this.props.handleChangeResultShare(e, id)}
                  />
                </div>
                <div>
                  <label>
                    <EmailIcon size={32} />
                  </label>
                  <input
                    type="checkbox"
                    className="form-control"
                    checked={email}
                    name="email"
                    onChange={(e) => this.props.handleChangeResultShare(e, id)}
                  />
                </div>
                <div>
                  <label>
                    <TwitterIcon size={32} />
                  </label>
                  <input
                    type="checkbox"
                    className="form-control"
                    checked={twitter}
                    name="twitter"
                    onChange={(e) => this.props.handleChangeResultShare(e, id)}
                  />
                </div>
                <div>
                  <label>
                    <WhatsappIcon size={32} />
                  </label>
                  <input
                    type="checkbox"
                    className="form-control"
                    checked={whatsapp}
                    name="whatsapp"
                    onChange={(e) => this.props.handleChangeResultShare(e, id)}
                  />
                </div>
                <div>
                  <label>
                    <LinkedinIcon size={32} />
                  </label>
                  <input
                    type="checkbox"
                    className="form-control"
                    checked={linkedin}
                    name="linkedin"
                    onChange={(e) => this.props.handleChangeResultShare(e, id)}
                  />
                </div>
                <div>
                  <label>
                    <VKIcon size={32} />
                  </label>
                  <input
                    type="checkbox"
                    className="form-control"
                    checked={vk}
                    name="vk"
                    onChange={(e) => this.props.handleChangeResultShare(e, id)}
                  />
                </div>
                <div>
                  <label>
                    <TelegramIcon size={32} />
                  </label>
                  <input
                    type="checkbox"
                    className="form-control"
                    checked={telegram}
                    name="telegram"
                    onChange={(e) => this.props.handleChangeResultShare(e, id)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddResult;
