import React from "react";
import { Redirect } from "react-router-dom";
import QuizItem from "./QuizItem";
import { ReactComponent as PlusSign } from "./../../../img/plus.svg";
import { withTranslation } from "react-i18next";
import { ReactComponent as KnowyLogo } from "../../../images/knowy-logo-animation.svg";
class QuizesGridContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isNew: false };
  }
  render() {
    const { t, folder, wsId } = this.props;

    if (this.state.isNew)
      return (
        <Redirect
          to={{
            pathname: "/templates",
            state: { folder },
          }}
        />
      );
    return (
      <div className="dashboard-items">
        <button
          className="new createQuizFromScratch"
          onClick={() =>
            this.props.handleCreateNewBtnClick()
          }
        >
          <PlusSign style={{ marginBottom: "20px" }} />
          {t("dashboard-create-new")}
        </button>

        {this.props.quizes.map((quiz) => {
          return (
            <QuizItem
              key={quiz.id}
              render={this.render}
              data={quiz}
              handleToggelModal={this.props.handleToggelModal}
              wsId={wsId}
            />
          );
        })}
      </div>
    );
  }
}

export default withTranslation()(QuizesGridContainer);
