import React, { useState, useEffect } from "react";

function InputAndTextArea(props) {
  const {
    defaultValue,
    validationType,
    isTextarea,
    handleLiquid,
    errorMessage,
    inputStyle,
    inputValid,
    height,
    type,
    inputId,
    handlevalidationInputChanges,
    dataWithVariables,
    placeholder,
    name,
    fontSize,
    isValid,
    required,
    rows,
  } = props;
  useEffect(() => {
    if (defaultValue && defaultValue != "" && required && dataWithVariables) {
      handlevalidationInputChanges(defaultValue, inputId);
    }
  }, [defaultValue, dataWithVariables]);

  useEffect(() => {}, [inputValid, isValid]);
  return (
    <div>
      {validationType == "other" && isTextarea ? (
        <>
          <textarea
            {...inputStyle}
            id={inputId}
            type={type}
            name={name}
            placeholder={handleLiquid(placeholder, dataWithVariables)}
            className="form-control"
            // hide default value until we fix duplication contact ID issue
            style={{ fontSize }}
            // defaultValue={defaultValue}
            defaultValue={defaultValue}
            rows={rows}
            required={required}
            onInvalid={(e) => e.target.setCustomValidity(errorMessage)}
            onChange={(e) =>
              handlevalidationInputChanges(e.target.value, inputId)
            }
          />
        </>
      ) : (
        <>
          <input
            {...inputStyle}
            id={inputId}
            type={type}
            name={name}
            placeholder={handleLiquid(placeholder, dataWithVariables)}
            className={`form-control ${type === "email" ? "always-ltr" : ""}`}
            // hide default value until we fix duplication contact ID issue
            style={{ fontSize, height }}
            // defaultValue={defaultValue}
            defaultValue={defaultValue}
            required={required}
            onInvalid={(e) => e.target.setCustomValidity(errorMessage)}
            onChange={(e) =>
              handlevalidationInputChanges(e.target.value, inputId)
            }
          />
        </>
      )}
      {inputValid || isValid ? (
        <></>
      ) : (
        <span style={{ color: "red" }} className="req-error">
          {errorMessage}
        </span>
      )}
    </div>
  );
}

export default InputAndTextArea;
