import React from "react";
import { Accordion } from "react-bootstrap";
import Fonts from "../../../utils/Fonts/Fonts";
import ChooseImageModal from "../ChooseImageModal";
import ColorPicker from "../ColorPicker";
import SelectSize from "../SelectSize";
import "./theme.css";
import Justify from "../../justify/justify";
import { withTranslation } from "react-i18next";
import { IoTrashOutline } from "react-icons/io5";
import { addStyleSettings } from "../../../utils/patchFunctions";
import { BiBold, BiItalic, BiUnderline } from "react-icons/bi";

function Header({
  selectedTheme,
  handleChange,
  props,
  onSwatchHover,
  changeHeaderImage,
  changeLogo,
  fromTheme,
  handleColorsToChange,
}) {
  if (!selectedTheme) return <></>;
  let { header, fonts } = props.data;
  const { t, settings, id } = props;
  let styleObject = header;

  if (styleObject === "override-edit" || !styleObject) {
    styleObject = props.data.header;
  }
  if (!styleObject || !styleObject.fontFamily) {
    const settings = addStyleSettings({}, "header");
    styleObject = settings.header;
  }

  if (
    selectedTheme == "override-edit" &&
    !styleObject.isOverride &&
    typeof handleChange === "function"
  ) {
    // props.style[context] = Object.assign({}, settings[context]);
    Object.entries(props.mainSettings).forEach(([key, value]) => {
      handleChange({ target: { name: key, value } }, id, "header");
    });
    handleChange({ target: { name: "isOverride", value: true } }, id, "header");
  }

  if (!styleObject.textAlign) styleObject.textAlign = "center";
  const headerLinks =
    header && header.links ? Object.values(header.links) || [] : [];

  const fontWeight =
    selectedTheme == "override-edit"
      ? styleObject.fontWeight
      : header.fontWeight;
  const fontStyle =
    selectedTheme == "override-edit" ? styleObject.fontStyle : header.fontStyle;
  const fontUnderline =
    selectedTheme == "override-edit"
      ? styleObject.fontUnderline
      : header.fontUnderline;
  return (
    <div id="Header">
      <div className="card-body">
        <div className="background" id="accordionHeader">
          <div className="box-line">
            <div className="form-group">
              <label>{t("theme-accordion-background-color")}</label>
              <br />
              <div className="colorBox">
                <ColorPicker
                  setRight="inherit"
                  color={
                    selectedTheme === "override-edit"
                      ? styleObject.background
                      : header.background
                  }
                  handleChange={(e) => {
                    if (selectedTheme === "override-edit") {
                      handleColorsToChange(e, "background", "header");
                    } else {
                      onSwatchHover(e, "header");
                    }
                  }}
                />
              </div>
            </div>
            <div className="answerColor">
              <h3>{t("theme-accordion-background-image")}</h3>
            </div>

            <div className="chooseImage">
              <div className="imageBox">
                {selectedTheme === "override-edit" ? (
                  <>
                    <ChooseImageModal
                      index={"headerImage"}
                      changeBackgroundImage={(e) => {
                        handleChange(
                          { target: { value: e, name: "backgroundImage" } },
                          props.id,
                          "header"
                        );
                      }}
                      image={styleObject.backgroundImage || "/img/picFrame.png"}
                      size={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "auto",
                        maxHeight: "100%",
                        margin: "auto",
                      }}
                      borderStyle={{
                        border: "2px solid #DEDCF4",
                        width: "100%",
                        height: "120px",
                        margin: "10px 0px",
                        position: "relative",
                        alignItems: "center",
                        display: "flex",
                        backgroundColor: "#F2F1FF",
                        borderRadius: "2px",
                      }}
                    />
                  </>
                ) : (
                  <>
                    <ChooseImageModal
                      index={"headerImage"}
                      changeBackgroundImage={changeHeaderImage}
                      image={header.backgroundImage || "/img/picFrame.png"}
                      size={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "auto",
                        maxHeight: "100%",
                        margin: "auto",
                      }}
                      borderStyle={{
                        border: "2px solid #DEDCF4",
                        width: "100%",
                        height: "120px",
                        margin: "10px 0px",
                        position: "relative",
                        alignItems: "center",
                        display: "flex",
                        backgroundColor: "#F2F1FF",
                        borderRadius: "2px",
                      }}
                    />
                  </>
                )}
              </div>
              {selectedTheme === "override-edit" ? (
                <button
                  className="btn- btn-link remove-image-icon"
                  onClick={() =>
                    handleChange(
                      {
                        target: {
                          value: "",
                          name: "backgroundImage",
                        },
                      },
                      props.id,
                      "header"
                    )
                  }
                >
                  <IoTrashOutline size={20} />
                </button>
              ) : (
                <button
                  className="btn- btn-link remove-image-icon"
                  onClick={() => changeHeaderImage("")}
                >
                  <IoTrashOutline size={20} />
                </button>
              )}
            </div>

            <div className="form-group">
              <SelectSize
                max={400}
                min={10}
                step={25}
                name="height"
                fromTheme={true}
                label={t("theme-header-height")}
                placeholder={styleObject.fontSize}
                value={
                  selectedTheme == "override-edit"
                    ? {
                        desktop: `${styleObject.height}`,
                        mobile:
                          `${styleObject.mobileHeight}` || styleObject.height,
                        tablet:
                          `${styleObject.tabletHeight}` || styleObject.height,
                      }
                    : {
                        desktop: `${header.height}`,
                        mobile: `${header.mobileHeight}` || header.height,
                        tablet: `${header.tabletHeight}` || header.height,
                      }
                }
                handleChange={(size, name = "height") => {
                  if (selectedTheme == "override-edit") {
                    handleChange(
                      { target: { name, value: size * 4 } },
                      props.id,
                      "header"
                    );
                  } else {
                    props.changeNavs(
                      { target: { name, value: size * 4 } },
                      "header"
                    );
                  }
                }}
                changedDetails={{ toChange: "header" }}
                isTimesFour={true}
              />
            </div>

            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="10">
                <Accordion.Header>{t("theme-accordion-logo")}</Accordion.Header>
                <Accordion.Body>
                  <div id="headerLogo">
                    <div className="form-group">
                      <label>{t("theme-accordion-logo-image")}</label>
                    </div>

                    <div className="chooseImage">
                      <div className="imageBox">
                        {selectedTheme === "override-edit" ? (
                          <>
                            <ChooseImageModal
                              index={"HeaderLogo"}
                              changeLogo={(e) => {
                                handleChange(
                                  {
                                    target: { value: e, name: "logoImageSrc" },
                                  },
                                  props.id,
                                  "header"
                                );
                              }}
                              image={
                                styleObject.logoImageSrc || "/img/picFrame.png"
                              }
                              toChange="header"
                              size={{
                                height: "auto",
                                maxWidth: "100%",
                                width: "auto",
                                maxHeight: "100%",
                                margin: "auto",
                              }}
                              borderStyle={{
                                border: "2px solid #DEDCF4",
                                width: "100%",
                                height: "120px",
                                margin: "10px 0px",
                                position: "relative",
                                alignItems: "center",
                                display: "flex",
                                backgroundColor: "#F2F1FF",
                                borderRadius: "2px",
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <ChooseImageModal
                              changeLogo={changeLogo}
                              image={header.logoImageSrc || "/img/picFrame.png"}
                              index={"HeaderLogo"}
                              toChange="header"
                              size={{
                                height: "auto",
                                maxWidth: "100%",
                                width: "auto",
                                maxHeight: "100%",
                                margin: "auto",
                              }}
                              borderStyle={{
                                border: "2px solid #DEDCF4",
                                width: "100%",
                                height: "120px",
                                margin: "10px 0px",
                                position: "relative",
                                alignItems: "center",
                                display: "flex",
                                backgroundColor: "#F2F1FF",
                                borderRadius: "2px",
                              }}
                            />
                          </>
                        )}
                      </div>

                      {selectedTheme === "override-edit" ? (
                        <button
                          className="btn- btn-link remove-image-icon"
                          onClick={() =>
                            handleChange(
                              {
                                target: {
                                  value: "",
                                  name: "logoImageSrc",
                                },
                              },
                              props.id,
                              "header"
                            )
                          }
                        >
                          <IoTrashOutline size={20} />
                        </button>
                      ) : (
                        <button
                          className="btn- btn-link remove-image-icon"
                          onClick={() => changeLogo("", "header")}
                        >
                          <IoTrashOutline size={20} />
                        </button>
                      )}
                    </div>

                    <div className="space line">
                      <h3>{t("theme-accordion-space")} </h3>
                      <div>
                        <label>
                          {selectedTheme === "override-edit" ? (
                            <input
                              type="number"
                              name="logoPaddingTop"
                              value={styleObject.logoPaddingTop}
                              placeholder="0"
                              onChange={(e) => {
                                handleChange(
                                  {
                                    target: {
                                      value: e.target.value,
                                      name: "logoPaddingTop",
                                    },
                                  },
                                  props.id,
                                  "header"
                                );
                              }}
                              border="1"
                            />
                          ) : (
                            <input
                              type="number"
                              name="logoPaddingTop"
                              value={header.logoPaddingTop}
                              placeholder="0"
                              onChange={(e) => {
                                props.changeNavs(e, "header");
                                // this.handleChange(e, "header")
                              }}
                              border="1"
                            />
                          )}
                          {t("theme-accordion-top")}
                        </label>
                        <label>
                          {selectedTheme === "override-edit" ? (
                            <input
                              type="number"
                              name="logoPaddingRight"
                              value={styleObject.logoPaddingRight}
                              placeholder="0"
                              onChange={(e) => {
                                handleChange(
                                  {
                                    target: {
                                      value: e.target.value,
                                      name: "logoPaddingRight",
                                    },
                                  },
                                  props.id,
                                  "header"
                                );
                                // this.handleChange(e, "header")
                              }}
                              border="2"
                            />
                          ) : (
                            <input
                              type="number"
                              name="logoPaddingRight"
                              value={header.logoPaddingRight}
                              placeholder="0"
                              onChange={(e) => {
                                props.changeNavs(e, "header");
                                // this.handleChange(e, "header")
                              }}
                              border="2"
                            />
                          )}
                          {t("theme-accordion-right")}
                        </label>
                        <label>
                          {selectedTheme === "override-edit" ? (
                            <input
                              type="number"
                              name="logoPaddingBottom"
                              value={styleObject.logoPaddingBottom}
                              placeholder="0"
                              onChange={(e) => {
                                handleChange(
                                  {
                                    target: {
                                      value: e.target.value,
                                      name: "logoPaddingBottom",
                                    },
                                  },
                                  props.id,
                                  "header"
                                );
                              }}
                              border="3"
                            />
                          ) : (
                            <input
                              type="number"
                              name="logoPaddingBottom"
                              value={header.logoPaddingBottom}
                              placeholder="0"
                              onChange={(e) => {
                                props.changeNavs(e, "header");
                                // this.handleChange(e, "header")
                              }}
                              border="3"
                            />
                          )}
                          {t("theme-accordion-bottom")}
                        </label>
                        <label>
                          {selectedTheme === "override-edit" ? (
                            <input
                              type="number"
                              name="logoPaddingLeft"
                              value={styleObject.logoPaddingLeft}
                              placeholder="0"
                              onChange={(e) => {
                                handleChange(
                                  {
                                    target: {
                                      value: e.target.value,
                                      name: "logoPaddingLeft",
                                    },
                                  },
                                  props.id,
                                  "header"
                                );
                              }}
                              border="4"
                            />
                          ) : (
                            <input
                              type="number"
                              name="logoPaddingLeft"
                              value={styleObject.logoPaddingLeft}
                              placeholder="0"
                              onChange={(e) => {
                                props.changeNavs(e, "header");
                                // this.handleChange(e, "header")
                              }}
                              border="4"
                            />
                          )}
                          {t("theme-accordion-left")}
                        </label>
                      </div>
                    </div>
                    <div className="form-group ">
                      <SelectSize
                        fromTheme={true}
                        name="size"
                        label={t("theme-accordion-logo-width")}
                        value={{
                          desktop: styleObject.size,
                          mobile: styleObject.mobileSize || styleObject.size,
                          tablet: styleObject.tabletSize || styleObject.size,
                        }}
                        handleChange={(e, name = "size") => {
                          if (selectedTheme == "override-edit") {
                            handleChange(
                              { target: { value: e, name } },
                              props.id,
                              "header"
                            );
                          } else {
                            props.changeNavs(
                              { target: { value: e, name } },
                              "header"
                            );
                          }
                        }}
                        changedDetails={{ toChange: "header" }}
                      />
                    </div>
                    <div className="justify-content-center fontStyle">
                      <h3>{t("theme-accordion-logo-alignment")}</h3>

                      <Justify
                        handleStyleFunctionName={
                          selectedTheme == "override-edit"
                            ? "handleQuestionStyle"
                            : "changeMainStyle"
                        }
                        targetName={"textAlign"}
                        value={styleObject.textAlign}
                        actionFunction={(e) => {
                          if (selectedTheme == "override-edit") {
                            handleChange(e, props.id, "header");
                          } else {
                            props.changeNavs(e, "header");
                            // handleChange(e, "header");
                          }
                        }}
                        selectedJustify={
                          selectedTheme == "override-edit"
                            ? styleObject.textAlign || "center"
                            : header.textAlign || "center"
                        }
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  {t("theme-accordion-advanced")}
                </Accordion.Header>
                <Accordion.Body>
                  <div id="Advanced">
                    <div className="fontStyle">
                      <Fonts
                        templatesFonts={fonts}
                        selected={
                          selectedTheme == "override-edit"
                            ? styleObject.fontFamily.replace(/\+/g, " ")
                            : header.fontFamily.replace(/\+/g, " ")
                        }
                        saveFont={(font) => {
                          if (selectedTheme == "override-edit") {
                            handleChange(
                              { target: { name: "fontFamily", value: font } },
                              props.id,
                              "header"
                            );
                          } else {
                            props.changeNavs(
                              {
                                target: { name: "fontFamily", value: font },
                              },
                              "header"
                            );
                          }
                        }}
                      />
                    </div>
                    <br />
                    <div className="form-group row">
                      <a className="col-2" role="button">
                        <BiBold
                          size={24}
                          color={fontWeight == "bold" ? "black" : "grey"}
                          onClick={(e) => {
                            if (selectedTheme == "override-edit") {
                              handleChange(
                                {
                                  target: {
                                    name: "fontWeight",
                                    value:
                                      fontWeight === "bold" ? "normal" : "bold",
                                  },
                                },
                                props.id,
                                "header"
                              );
                            } else {
                              props.changeNavs(
                                {
                                  target: {
                                    name: "fontWeight",
                                    value:
                                      fontWeight === "bold" ? "normal" : "bold",
                                  },
                                },
                                "header"
                              );
                            }
                          }}
                        />{" "}
                      </a>
                      <a className="col-2" role="button">
                        <BiItalic
                          size={24}
                          color={fontStyle == "italic" ? "black" : "grey"}
                          onClick={(e) => {
                            if (selectedTheme == "override-edit") {
                              handleChange(
                                {
                                  target: {
                                    name: "fontStyle",
                                    value:
                                      fontStyle === "italic"
                                        ? "normal"
                                        : "italic",
                                  },
                                },
                                props.id,
                                "header"
                              );
                            } else {
                              props.changeNavs(
                                {
                                  target: {
                                    name: "fontStyle",
                                    value:
                                      fontStyle === "italic"
                                        ? "normal"
                                        : "italic",
                                  },
                                },
                                "header"
                              );
                            }
                          }}
                        />{" "}
                      </a>
                      <a className="col-2" role="button">
                        <BiUnderline
                          size={24}
                          color={
                            fontUnderline == "underline" ? "black" : "grey"
                          }
                          onClick={(e) => {
                            if (selectedTheme == "override-edit") {
                              handleChange(
                                {
                                  target: {
                                    name: "fontUnderline",
                                    value:
                                      fontUnderline === "underline"
                                        ? ""
                                        : "underline",
                                  },
                                },
                                props.id,
                                "header"
                              );
                            } else {
                              props.changeNavs(
                                {
                                  target: {
                                    name: "fontUnderline",
                                    value:
                                      fontUnderline === "underline"
                                        ? ""
                                        : "underline",
                                  },
                                },
                                "header"
                              );
                            }
                          }}
                        />{" "}
                      </a>
                    </div>
                    {/* <div className="form-group row">
                      <label className="col-md-3">
                        {t("theme-accordion-weight")}
                      </label>
                      <select
                        className="form-control col-md-3"
                        name="fontWeight"
                        value={
                          selectedTheme == "override-edit"
                            ? styleObject.fontWeight
                            : header.fontWeight
                        }
                        onChange={(e) => {
                          if (selectedTheme == "override-edit") {
                            handleChange(
                              {
                                target: {
                                  name: "fontWeight",
                                  value: e.target.value,
                                },
                              },
                              props.id,
                              "header"
                            );
                          } else {
                            props.changeNavs(e, "header");
                          }
                        }}
                      >
                        <option> {t("theme-accordion-normal")}</option>
                        <option> {t("theme-accordion-bold")}</option>
                      </select>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3">
                        {t("theme-accordion-style")}
                      </label>
                      <select
                        className="form-control col-md-3"
                        name="fontStyle"
                        value={
                          selectedTheme == "override-edit"
                            ? styleObject.fontStyle
                            : header.fontStyle
                        }
                        onChange={(e) => {
                          if (selectedTheme == "override-edit") {
                            handleChange(
                              {
                                target: {
                                  name: "fontStyle",
                                  value: e.target.value,
                                },
                              },
                              props.id,
                              "header"
                            );
                          } else {
                            props.changeNavs(e, "header");
                          }
                        }}
                      >
                        <option> {t("theme-accordion-normal")} </option>
                        <option>{t("theme-accordion-italic")} </option>
                      </select>
                    </div> */}
                    <SelectSize
                      max={100}
                      min={10}
                      step={1}
                      fromTheme={true}
                      name="fontSize"
                      label={t("general-font-size")}
                      placeholder={styleObject.fontSize}
                      value={
                        selectedTheme == "override-edit"
                          ? {
                              desktop: styleObject.fontSize,
                              mobile:
                                styleObject.mobileFontSize ||
                                styleObject.fontSize,
                              tablet:
                                styleObject.tabletFontSize ||
                                styleObject.fontSize,
                            }
                          : {
                              desktop: header.fontSize,
                              mobile: header.mobileFontSize || header.fontSize,
                              tablet: header.tabletFontSize || header.fontSize,
                            }
                      }
                      handleChange={(size, name = "fontSize") => {
                        if (selectedTheme == "override-edit") {
                          handleChange(
                            { target: { name, value: size } },
                            props.id,
                            "header"
                          );
                        } else {
                          props.changeNavs(
                            { target: { name, value: size } },
                            "header"
                          );
                        }
                      }}
                      changedDetails={{ toChange: "header" }}
                    />
                  </div>

                  <div className="form-group">
                    <label>{t("theme-accordion-header-link-color")}</label>
                    <br />
                    <div className="colorBox">
                      <ColorPicker
                        setRight="inherit"
                        color={
                          selectedTheme === "override-edit"
                            ? styleObject.linkColor || "#000000"
                            : header.linkColor
                        }
                        handleChange={(e) => {
                          if (selectedTheme === "override-edit") {
                            handleColorsToChange(e, "linkColor", "header");
                          } else onSwatchHover(e, "headerLink");
                        }}
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <br />
            {selectedTheme === "override-edit" ? (
              <>
                <div className="addSwitch">
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="hideLinks"
                      checked={styleObject.hidelinks}
                      onChange={(e) =>
                        handleChange(
                          {
                            target: {
                              value: e.target.checked,
                              name: "hideLinks",
                            },
                          },
                          props.id,
                          "header"
                        )
                      }
                    />
                    <span className="slider round"></span>
                  </label>
                  Hide links
                </div>
              </>
            ) : (
              <>
                {headerLinks.map((headerLink) => {
                  const { id, title, link } = headerLink;

                  return (
                    <div
                      key={id}
                      className="row"
                      style={{ marginBottom: "20px" }}
                    >
                      <div style={{ width: "100%" }}>
                        <label> {t("theme-accordion-title")} </label>
                        <input
                          type="text"
                          name="title"
                          placeholder={t("theme-accordion-text")}
                          value={title}
                          onChange={(e) => props.changeNavs(e, "header", id)}
                          className="form-control"
                        />
                      </div>
                      <div style={{ width: "90%" }}>
                        <label> {t("theme-accordion-link")}</label>
                        <input
                          type="text"
                          name="link"
                          placeholder={t("theme-accordion-url")}
                          value={link}
                          onChange={(e) => props.changeNavs(e, "header", id)}
                          className="form-control"
                        />
                      </div>
                      <div
                        style={{
                          width: "10%",
                          top: "25px",
                          left: "-8px",
                          position: "relative",
                        }}
                        className="answer-icons"
                      >
                        <a
                          role="button"
                          title=""
                          onClick={(e) =>
                            props.removeLinkFromNav(e, "header", id)
                          }
                        >
                          <svg
                            id="Layer_1"
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 14 18"
                          >
                            <defs></defs>
                            <path
                              d="M18.5,12.09h3v.5h1.09V12a1,1,0,0,0-1-1H18.42a1,1,0,0,0-1,1v.57H18.5Z"
                              transform="translate(-13 -11)"
                            />
                            <path
                              d="M25.1,16.9H14.9a.48.48,0,0,0-.48.51L15.28,28a1.12,1.12,0,0,0,1.12,1h7.2a1.12,1.12,0,0,0,1.12-1l.86-10.55A.48.48,0,0,0,25.1,16.9Zm-7.73,11h0a.56.56,0,0,1-.55-.51l-.53-8.66a.53.53,0,0,1,.51-.57.52.52,0,0,1,.57.5l.54,8.66A.55.55,0,0,1,17.37,27.88Zm3.18-.55a.55.55,0,0,1-.54.55.55.55,0,0,1-.55-.55V18.68a.55.55,0,0,1,.55-.55.55.55,0,0,1,.54.55Zm2.68,0a.55.55,0,0,1-.54.51h0a.55.55,0,0,1-.51-.58l.51-8.65a.54.54,0,1,1,1.08.06Z"
                              transform="translate(-13 -11)"
                            />
                            <path
                              d="M27,15.22l-.36-1.07a.69.69,0,0,0-.65-.47H14a.69.69,0,0,0-.65.47L13,15.22a.44.44,0,0,0,.19.52.4.4,0,0,0,.24.07h13.1a.4.4,0,0,0,.24-.07A.44.44,0,0,0,27,15.22Z"
                              transform="translate(-13 -11)"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                  );
                })}
                <a
                  onClick={(e) => props.addLinkToNav(e, "header")}
                  title=""
                  className="add-link"
                >
                  {t("theme-accordion-add-link")}
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default withTranslation()(Header);
