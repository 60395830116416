import React from "react";
import { fontsArray } from "./fontsArray";
import "./editTextToolbar.css";

class Fonts extends React.Component {
  state = {
    template_fonts: [],
    other_fonts: [],
    allFonts: [],
    fontDropVisible: false,
    formSearch: "",
    fontColor: "#78889f",
    showSelectedFontImg: true,
  };

  handleChange = (e) => {
    this.setState({ formSearch: e.target.value });
    this.setState({ showSelectedFontImg: false });
  };

  handleFonts = () => {
    const { formSearch } = this.state;
    return fontsArray.filter((item, index) => {
      if (formSearch !== "") {
        if (item.name.toLowerCase().startsWith(formSearch.toLowerCase())) {
          return item;
        }
      } else {
        return item;
      }
    });
  };

  toggleFontDrop = (e) => {
    e.preventDefault();
    this.setState({
      fontDropVisible: !this.state.fontDropVisible,
    });
  };

  setActiveFont = (e, font) => {
    if (!font.name) return this.props.saveFont(font.font);
    this.props.saveFont(font.name);
  };
  render() {
    const allFonts = this.handleFonts();
    const { templatesFonts = [] } = this.props;
    const coverStyle = {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    };

    const backgroundImage = this.state.showSelectedFontImg
      ? `url(/assets/fonts/${this.props.selected.replace(/\ /g, "+")}.png)`
      : "none";
    return (
      <div className={"col-font"} style={{ marginRight: "0px" }}>
        {/*<li onClick={this.toggleFontDrop} key={`font-selected`}>*/}
        {/*/!*<img src={require(`./fonts/${this.props.selected.replace(/\ /g, '+')}.png`)} />*!/*/}
        {/*<img src={`/assets/fonts/${this.props.selected.replace(/\ /g, '+')}.png`} />*/}
        {/*</li>*/}

        <button
          className={"select-font"}
          onClick={() => this.setState({ fontDropVisible: true })}
        >
          <input
            // onKeyUp={(e) => {
            //
            //     this.setState({onKeyUp:true,fontSearch:e.target.value})
            //
            //     //FILTER!
            // }}

            // ref={(fontSearchVal) => {
            //     this.fontSearchVal = fontSearchVal;
            // }}
            // className={Styles['color-font-list-search']}
            value={this.state.formSearch}
            onChange={this.handleChange}
            style={{
              backgroundSize: "contain",
              backgroundImage: backgroundImage,
              backgroundColor: "white",
              backgroundRepeatX: "no-repeat",
              border: "none",
              width: "100%",
            }}
          />
        </button>
        {/*<a href="#" className={'select-font'} onClick={e => this.toggleFontDrop(e)}>{this.props.selected}</a>*/}

        <div
          className={
            "set-drop" +
            " " +
            (this.state.fontDropVisible ? "font-drop-visible" : "")
          }
        >
          <div
            style={coverStyle}
            onClick={() => {
              this.setState({
                fontDropVisible: false,
                formSearch: "",
                showSelectedFontImg: true,
              });
            }}
          ></div>
          <ul className="fonts-dropdown">
            <li style={{ color: "#339fec" }}>Template Fonts</li>
            {templatesFonts.map((item, index) => (
              <li
                onClick={() =>
                  this.setState({
                    fontDropVisible: false,
                    formSearch: "",
                    showSelectedFontImg: true,
                  })
                }
                key={`font-${index}`}
              >
                <img
                  onClick={(e) => this.setActiveFont(e, item)}
                  src={`/assets/fonts/${item.font.replace(/\ /g, "+")}.png`}
                />
              </li>
            ))}
            <li style={{ color: "#339fec" }}>Other Fonts</li>
            {allFonts.map((item, index) => (
              <li
                onClick={() =>
                  this.setState({
                    fontDropVisible: false,
                    formSearch: "",
                    showSelectedFontImg: true,
                  })
                }
                key={`font-${index}`}
              >
                <img
                  onClick={(e) => this.setActiveFont(e, item)}
                  src={`/assets/fonts/${item.name.replace(/\ /g, "+")}.png`}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default Fonts;
