import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { mainAxios } from "../../../Axios/mainAxios";



function ThemesItem(props) {
  const { theme, selectedTheme = {}, handleCurrentEdit, t, setImportTheme } = props;
  if (!theme || !Object.keys(theme).length) return <></>;
  const { theme_data, theme_title } = theme;
  // const checkIfGallery = async () => {
  //   const { is_public } = theme;
  //   if (is_public) {
  //     const { data } = await mainAxios.post("themes/duplicate", theme);

  //     handleCurrentEdit(data);
  //   } else {
  //     handleCurrentEdit(theme);
  //   }
  // };
  const answersStyle = {
    color: theme_data.answers.buttonsTextColor,
    background: theme_data.answers.buttonsBackgroundColor,
    border: `${theme_data.answers.buttonsBorderColor} solid ${theme_data.answers.buttonsBorderSize}px`,
    borderRadius: `${theme_data.answers.buttonsBorderRadius}px`,
    padding: `${theme_data.answers.padding.top}px ${theme_data.answers.padding.right}px ${theme_data.answers.padding.bottom}px ${theme_data.answers.padding.left}px`,
    fontSize: `${theme_data.answers.fontSize}px`,
    fontStyle: theme_data.answers.fontStyle,
    fontWeight: theme_data.answers.fontWeight,
    margin: 10,
    fontFamily: theme_data.answers.fontFamily.replace(/\+/g, " "),
  };

  const titleStyle = {
    color: theme_data.title.color,
    padding: `${theme_data.title.padding.top}px ${theme_data.title.padding.right}px ${theme_data.title.padding.bottom}px ${theme_data.title.padding.left}px `,
    fontSize: `min(2.6vw, ${theme_data.title.fontSize > 50 ? 50 : theme_data.title.fontSize
      }px)`,
    fontStyle: theme_data.title.fontStyle,
    fontWeight: theme_data.title.fontWeight,
    fontFamily: theme_data.title.fontFamily.replace(/\+/g, " "),
  };
  let stepStyle = null;
  if (theme_data.backgroundColorType === "gradient") {
    stepStyle = {
      background: `${theme_data.gradientType}(${theme_data.gradientDeg}, ${theme_data.stringOfColors})`,
    };
  }
  if (theme_data.backgroundColorType === "color") {
    stepStyle = {
      backgroundColor: theme_data.backgroundColor,
      backgroundImage: `url(${theme_data.backgroundImage})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: theme_data.backgroundSize,
      backgroundPosition: theme_data.backgroundPosition,
    };
  }
  return (
    <div
      className={`themBox ${selectedTheme.theme_id === theme.theme_id ? "active" : ""
        }`}
      // onClick={() => props.changeTheme(theme)}
      onClick={() => setImportTheme(theme)}
    >
      <a
        type="button"
        onClick={() => {
           // console.log(5)
          // checkIfGallery();
        }}
        title=""
        className="settings"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xlink="http://www.w3.org/1999/xlink"
          width="24"
          height="24"
        >
          <defs>
            <clipPath id="clip-path">
              <rect
                id="Rectangle_92"
                data-name="Rectangle 92"
                width="24"
                height="24"
                transform="translate(1360.25 132.25)"
                fill="#000421"
                stroke="rgba(0,0,0,0)"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
            </clipPath>
          </defs>
          <g
            id="settings-2"
            transform="translate(-1360.25 -132.25)"
            clipPath="url(#clip-path)"
          >
            <g id="Group_136" data-name="Group 136">
              <g id="Group_134" data-name="Group 134">
                <path
                  id="Path_67"
                  data-name="Path 67"
                  d="M1372.25,153.216a2.467,2.467,0,0,1-2.285-1.49l-.438-.991a1.488,1.488,0,0,0-1.532-.885l-1.077.117a2.5,2.5,0,0,1-2.285-3.958l.639-.874a1.491,1.491,0,0,0,0-1.77l-.639-.874a2.5,2.5,0,0,1,2.285-3.958l1.077.117a1.5,1.5,0,0,0,1.533-.885l.436-.991a2.5,2.5,0,0,1,4.571,0l.438.991a1.492,1.492,0,0,0,1.532.885l1.077-.117a2.5,2.5,0,0,1,2.285,3.958l-.639.874a1.491,1.491,0,0,0,0,1.77l.639.874a2.5,2.5,0,0,1-2.285,3.958l-1.077-.117a1.482,1.482,0,0,0-1.533.885l-.436.991A2.469,2.469,0,0,1,1372.25,153.216Zm-4.088-4.375a2.485,2.485,0,0,1,2.28,1.491l.438.991a1.5,1.5,0,0,0,2.741,0l.436-.991a2.491,2.491,0,0,1,2.555-1.476l1.077.117a1.5,1.5,0,0,0,1.372-2.373l-.64-.874a2.488,2.488,0,0,1,0-2.952l.64-.874a1.5,1.5,0,0,0-1.372-2.373l-1.077.117a2.487,2.487,0,0,1-2.554-1.476l-.438-.991a1.5,1.5,0,0,0-2.741,0l-.436.991a2.485,2.485,0,0,1-2.555,1.476l-1.077-.117a1.5,1.5,0,0,0-1.372,2.373l.64.874a2.488,2.488,0,0,1,0,2.952l-.64.874a1.5,1.5,0,0,0,1.372,2.373l1.077-.117A2.516,2.516,0,0,1,1368.162,148.841Z"
                  fill="#000421"
                  stroke="rgba(0,0,0,0)"
                  strokeMiterlimit="10"
                  strokeWidth="1"
                />
              </g>
              <g id="Group_135" data-name="Group 135">
                <path
                  id="Path_68"
                  data-name="Path 68"
                  d="M1372.25,146.75a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,1372.25,146.75Zm0-4a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,1372.25,142.75Z"
                  fill="#000421"
                  stroke="rgba(0,0,0,0)"
                  strokeMiterlimit="10"
                  strokeWidth="1"
                />
              </g>
            </g>
          </g>
        </svg>
      </a>
      <div style={stepStyle}>
        <h4>{theme_title}</h4>
        <div className="answerTitles">
          <h5 style={titleStyle}>{t("rightBar-theme-question")} </h5>
          {/* <p style={answersStyle}>{t("rightBar-theme-answer")}</p> */}
        </div>
        <span style={answersStyle}>{t("rightBar-theme-button")}</span>
      </div>
    </div>
  );

  // return (
  //   <div
  //     onClick={() => props.changeTheme(theme)}
  //     style={{
  //       width: "100%",
  //       border: "1px solid black",
  //       margin: "10px",
  //       ...stepStyle,
  //     }}
  //   >
  //     <h3 style={titleStyle}>Question</h3>
  //     <p {...answersStyle}>Answer</p>
  //     <h5>{theme.theme_title}</h5>
  //     <button>Edit</button>
  //   </div>
  // );
}

export default withTranslation()(ThemesItem);
