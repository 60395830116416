import React from "react";
import { withTranslation } from "react-i18next";
import PaddingInput from "./PaddingInput";

function PaddingEdit(props) {
  const {
    paddingObject,
    selectedTheme,
    context,
    id,
    handleChange,
    changeMainStyle,
    t,
    mode = {},
  } = props;

  //Top
  let topPlatformDirection = "top";
  let topDefaultValueClass = "";
  if (mode.value === "mobile") topPlatformDirection = "mobileTop";
  if (mode.value === "tablet") topPlatformDirection = "tabletTop";
  if (mode.value === "mobile" || mode.value === "tablet") {
    if (
      !paddingObject[topPlatformDirection] ||
      paddingObject[topPlatformDirection] === paddingObject.top
    ) {
      topDefaultValueClass = "input-responsive-default-value";
    }
  }

  //Right
  let rightPlatformDirection = "right";
  let rightDefaultValueClass = "";

  if (mode.value === "mobile") rightPlatformDirection = "mobileRight";
  if (mode.value === "tablet") rightPlatformDirection = "tabletRight";
  if (mode.value === "mobile" || mode.value === "tablet") {
    if (
      !paddingObject[rightPlatformDirection] ||
      paddingObject[rightPlatformDirection] === paddingObject.right
    ) {
      rightDefaultValueClass = "input-responsive-default-value";
    }
  }

  //Left
  let leftPlatformDirection = "left";
  let leftDefaultValueClass = "";

  if (mode.value === "mobile") leftPlatformDirection = "mobileLeft";
  if (mode.value === "tablet") leftPlatformDirection = "tabletLeft";
  if (mode.value === "mobile" || mode.value === "tablet") {
    if (
      !paddingObject[leftPlatformDirection] ||
      paddingObject[leftPlatformDirection] === paddingObject.left
    ) {
      leftDefaultValueClass = "input-responsive-default-value";
    }
  }

  //Top
  let bottomPlatformDirection = "bottom";
  let bottomDefaultValueClass = "";
  if (mode.value === "mobile") bottomPlatformDirection = "mobileBottom";
  if (mode.value === "tablet") bottomPlatformDirection = "tabletBottom";
  if (mode.value === "mobile" || mode.value === "tablet") {
    if (
      !paddingObject[bottomPlatformDirection] ||
      paddingObject[bottomPlatformDirection] === paddingObject.bottom
    ) {
      bottomDefaultValueClass = "input-responsive-default-value";
    }
  }
  return (
    <>
      <PaddingInput
        paddingValue={paddingObject[topPlatformDirection]}
        paddingDirection={topPlatformDirection}
        selectedTheme={selectedTheme}
        context={context}
        id={id}
        handleChange={handleChange}
        changeMainStyle={changeMainStyle}
        translationContent={"theme-accordion-top"}
        t={t}
        borderValue={1}
        defaultValueClass={topDefaultValueClass}
      />
      <PaddingInput
        paddingValue={paddingObject[rightPlatformDirection]}
        paddingDirection={rightPlatformDirection}
        selectedTheme={selectedTheme}
        context={context}
        id={id}
        handleChange={handleChange}
        changeMainStyle={changeMainStyle}
        translationContent={"theme-accordion-right"}
        t={t}
        borderValue={2}
        defaultValueClass={rightDefaultValueClass}
      />
      <PaddingInput
        paddingValue={paddingObject[bottomPlatformDirection]}
        paddingDirection={bottomPlatformDirection}
        selectedTheme={selectedTheme}
        context={context}
        id={id}
        handleChange={handleChange}
        changeMainStyle={changeMainStyle}
        translationContent={"theme-accordion-bottom"}
        t={t}
        borderValue={3}
        defaultValueClass={bottomDefaultValueClass}
      />
      <PaddingInput
        paddingValue={paddingObject[leftPlatformDirection]}
        paddingDirection={leftPlatformDirection}
        selectedTheme={selectedTheme}
        context={context}
        id={id}
        handleChange={handleChange}
        changeMainStyle={changeMainStyle}
        translationContent={"theme-accordion-left"}
        t={t}
        borderValue={4}
        defaultValueClass={leftDefaultValueClass}
      />
    </>
  );
}

export default withTranslation()(PaddingEdit);
