import React, { useEffect, useState } from "react";
import initialFilter from "./FiltersConstants";
import AnalyseSingleFilters from "./AnalyseSingleFilter";
import { FaPlus } from "react-icons/fa";

const AnalyseFilters = (props) => {
  const {
    variables,
    arrayOfData,
    t,
    setFiltersObject,
    filtersObject,
    resetFlag,
    additionalFilters,
    additionalFiltersIndex,
  } = props;

  const AddRemoveFilters = (op, index) => {
    if (op === "add") {
      const tempFilterObject = Object.assign(filtersObject);
      tempFilterObject.push({
        ...initialFilter,
        id: `F_${Math.round(Math.random() * 999999)}`,
      });
      setFiltersObject(tempFilterObject);
    } else if (index) {
      const tempFilterObject = Object.assign(filtersObject);
      tempFilterObject.splice(index, 1);
      setFiltersObject(tempFilterObject);
    }
  };

  return (
    <div>
      {filtersObject.map((filter, index) => (
        <div id={`filter-${filter.id}`} key={`filter-${filter.id}`}>
          <AnalyseSingleFilters
            arrayOfData={arrayOfData}
            setFiltersObject={setFiltersObject}
            filtersObject={filter}
            filterArray={filtersObject}
            resetFlag={resetFlag}
            t={t}
            variables={variables}
            index={index}
            AddRemoveFilters={AddRemoveFilters}
          />
        </div>
      ))}
      <div className="d-flex">
        <div
          className=" float-end m-1"
          onClick={() => AddRemoveFilters("add")}
        ><span style={{color: "#BE1EB9"}}>+</span> Add condition
          {/*<FaPlus />*/}
        </div>
      </div>
    </div>
  );
};

export default AnalyseFilters;
