export function array_move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
}

export function addStr(str, index, stringToAdd) {
  return (
    str.substring(0, index) + stringToAdd + str.substring(index, str.length)
  );
}

export function countDecimals(numAsString) {
  // Check if the number has a decimal point
  if (numAsString.indexOf(".") !== -1) {
    // Return the number of decimal places
    return numAsString.split(".")[1].length;
  } else {
    // Return 0 if the number does not have any decimal places
    return 0;
  }
}

export function generateFunctionName() {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  for (let i = 0; i < 10; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export function quizSubDomainName(str, index, stringToAdd) {
  return str.substring(0, index) + stringToAdd;
}

export const toFindDuplicates = (arry) =>
  arry.filter((item, index) => arry.indexOf(item) !== index);

export const copyToClip = (text) => {
  navigator.clipboard.writeText(text);
};

export function stripTags(html) {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

export function transformCustomCss(cssStr, root) {
  if (!cssStr) return "";
  const testObj = cssStr.replaceAll("\n", " ").split("}");
  const mappedArray = testObj.map((item) => {
    if (
      item.toLowerCase().includes("body ") ||
      item.toLowerCase().includes(" body")
    ) {
      return item.replace("body", root);
    } else {
      return (item = root + " > " + item + "}" + root + " " + item);
    }
  });
  const newCssStr = mappedArray.join("}");
  return newCssStr;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function shuffleArray(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export const handleLiquid = (value, dataWithVariables) => {
  if (value.includes("{{") && value.includes("}}")) {
    const liquidArr = value.split(" ");
    const liquided = liquidArr.map((el) => {
      if (el.includes("}}")) {
        const varName = el.replaceAll("}}", "").replaceAll("{{", "");

        return dataWithVariables[varName.trim()] || varName;
      }
      return el;
    });
    return liquided.join(" ");
  }
  return value;
};

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function truncate(str, n) {
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
}

export const handleFinishQuiz = (quizId, isDuplicateAnswers = true) => {
  const knowyHistory = localStorage.getItem("KnowyHistory") || "{}";
  const ParsedHistory = JSON.parse(knowyHistory);
  const newHistory = {
    ...ParsedHistory,
    [quizId]: { finish: true, isDuplicateAnswers },
  };
  localStorage.setItem("KnowyHistory", JSON.stringify(newHistory));
};

export function getAlignmentClassNameByAlignmentTypeAndDirection(
  alignItems,
  direction,
  verticalAnswers
) {
  const alignmentType = verticalAnswers ? "align-items" : "justify-content";
  if (alignItems == "center") {
    return `${alignmentType}-center`;
  } else if (alignItems == "right") {
    return direction == "rtl"
      ? `${alignmentType}-start`
      : `${alignmentType}-end`;
  } else if (alignItems == "left") {
    return direction == "rtl"
      ? `${alignmentType}-end`
      : `${alignmentType}-start`;
  }
}
