import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setMediaQuery } from "../redux/actions";
import { useMediaQuery } from "react-responsive";

function CheckMediaQuery(props) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  props.actions.setMediaQuery(isMobile, isTablet);
}
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      setMediaQuery: (isMobile, isTablet) =>
        dispatch(setMediaQuery(isMobile, isTablet)),
    },
  };
};
export default connect(null, mapDispatchToProps)(CheckMediaQuery);
