import React, { useEffect, useState } from "react";
// import Frame from "react-frame-component";
import { connect } from "react-redux";
import Navbar from "../Navbar";
import { withTranslation } from "react-i18next";
import Modal from "react-bootstrap/esm/Modal";
// import CheckoutFrame from "./CheckoutFrame";



import {
  getPackagesAction,
  getPackageCheckoutAction,
  setPaymentDetailsAction,
  getPaymentDetailsAction,
  getPaymentCheckoutAction,
  changeSubscriptionPlanAction,
  setGtmStatusAction
} from "../../redux/actions";

import PackageItem from "./PackageItem";
import UpgradeModal from "./UpgradeModal";
import handleHighlightService from "../../utils/recoredSessionService";

// import services from "./services";

function Subscription({
  getPackages,
  packages,
  getPackageCheckout,
  session,
  setPaymentDetails,
  getPaymentDetails,
  paymentDetails,
  getPaymentCheckout,
  changeSubscriptionPlan,
  t,
  userId,
  gtmStatus,
  setGtmStatus,
  userName
}) {
  const [packagesOptions, setPackagesOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isYearly, setIsYearly] = useState(true);
  const [planId, setPlanId] = useState(null);
  useEffect(() => {
    getPackages();
    if (userId && gtmStatus.page && gtmStatus.page !== "subscription") {
      setGtmStatus({ page: "subscription", userId, events: null });
      handleHighlightService(userId, userName)
    }
  }, []);

  useEffect(() => {
    if (session) {
      window.open(session, "_self");
    }
  }, [session]);

  useEffect(() => {
    if (packages) {
      setPackagesOptions(packages);
      setIsModalOpen(false);
    }
  }, [packages]);

  const getCheckoutOrUpgrade = (id, domain, callToAction) => {
    if (callToAction === "Upgrade") {
      // getPackageCheckout(id, domain);
      setPlanId(id);
      setIsModalOpen(true);
    } else {
      getPackageCheckout(id, domain);
    }
  };

  // const { t } = props;

  return (
    <div>
      <Navbar active="Subscription" helpLabel={t("help-center")} />
      <section className="question-content p-1">
        <div className="integrationInner" style={{ width: "90%" }}>
          <h1>Subscription</h1>
          <div className="addSwitch" style={{textAlign:"center",margin:"20px"}}>
            {t("subscription-monthly")}
            <label className="switch" style={{ margin: "0 20px"}}>
              <input
                  type="checkbox"
                  checked={isYearly}
                  name="feedback"
                  onChange={(e) => setIsYearly(!isYearly)}
              />
              <span className="slider round"></span>
            </label>
            {t("subscription-yearly")}
          </div>
          <div className="d-flex flex-wrap" style={  {  alignItems: "center",
            justifyContent: "center"}}>
            {packagesOptions.sort((a, b) => (a.unit_amount > b.unit_amount) ? 1 : -1).map((item,index) => {
              return (
                  !isYearly ? item.recurring.interval === "year" ? <></> :
                <div key={item.id} className="col-md-3">
                  <PackageItem
                    packageItem={item}
                    getPackageCheckout={getCheckoutOrUpgrade}
                  // setIsModalOpen={setIsModalOpen}
                  />
                </div> :
                      item.recurring.interval === "month" ? <></> :
                      <div key={item.id} className="col-md-3">
                    <PackageItem
                        packageItem={item}
                        getPackageCheckout={getCheckoutOrUpgrade}
                        // setIsModalOpen={setIsModalOpen}
                    />
                  </div>
              );
            })}
          </div>
        </div>
      </section>
      <Modal
        className="package-modal"
        size="xl"
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        centered
      >
        <UpgradeModal
          setPaymentDetails={setPaymentDetails}
          getPaymentDetails={getPaymentDetails}
          payDetails={paymentDetails}
          getPaymentCheckout={getPaymentCheckout}
          changeSubscriptionPlan={changeSubscriptionPlan}
          planId={planId}
        />
      </Modal>
    </div>
  );
}

const mapToProps = (state) => {
  return {
    packages: state.packages,
    session: state.packageSession,
    paymentDetails: state.paymentDetails,
    userId: state.loginDetails.user_id,
    gtmStatus: state.gtmStatus,
    userName: state.loginDetails.name,
  };
};
const mapDispatch = (dispatch) => {
  return {
    getPackages: () => {
      dispatch(getPackagesAction());
    },
    getPackageCheckout: (id, domain) => {
      dispatch(getPackageCheckoutAction(id, domain));
    },
    setPaymentDetails: (data) => {
      dispatch(setPaymentDetailsAction(data));
    },
    getPaymentDetails: () => {
      dispatch(getPaymentDetailsAction());
    },
    getPaymentCheckout: (domain) => {
      dispatch(getPaymentCheckoutAction(domain));
    },
    changeSubscriptionPlan: (id) => {
      dispatch(changeSubscriptionPlanAction(id));
    },
    setGtmStatus: (status) => dispatch(setGtmStatusAction(status)),
  };
};

export default withTranslation()(
  connect(mapToProps, mapDispatch)(Subscription)
);
