import React, { useEffect, useState } from "react";

function ProgressBar(props) {
  const {
    list,
    stepId,
    show,
    isEdit,
    data,
    style,
    direction,
    isMobile,
    isTablet,
  } = props;

  const [barColor, setBarColor] = useState("");
  const [barFontFamily, setBarFontFamily] = useState("open-sans");
  const [alignVertical, setAlignVertical] = useState("start");
  const [barShowPercent, setBarShowPercent] = useState(false);

  useEffect(() => {
    if (data) {
      if (data) {
        setBarColor(`${data.color}`);
        setBarFontFamily(data.fontFamily);
        setBarShowPercent(data.showPercent);
      }

      if (data.textAlign == "center") {
        setAlignVertical(data.textAlign);
      } else if (data.textAlign == "right") {
        direction == "rtl"
          ? setAlignVertical(`start`)
          : setAlignVertical(`end`);
      } else if (data.textAlign == "left") {
        direction == "rtl"
          ? setAlignVertical(`end`)
          : setAlignVertical(`start`);
      }
    }
  }, [show, data]);

  const listLength = list.length - 1;
  const currentStepIndex = list.findIndex((step) => step.id === stepId);
  const percentage = parseInt((currentStepIndex * 100) / listLength);

  //Font size
  let fontSize = "14";
  if (isMobile && data.mobileFontSize) fontSize = data.mobileFontSize;
  else if (isTablet && data.tabletFontSize) fontSize = data.tabletFontSize;
  else if (data.fontSize) fontSize = data.fontSize;

  if (!show) return <></>;
  return (
    <div className="progressBar" style={{ alignSelf: alignVertical }}>
      <div>
        <span style={{ width: `${percentage}%`, background: barColor }}></span>
      </div>
      <span
        style={{
          color: barColor,
          fontSize: `${fontSize}px`,
          fontFamily: barFontFamily,
        }}
      >
        {barShowPercent
          ? `${percentage}%`
          : `${currentStepIndex}/${listLength}`}
      </span>
    </div>
  );
}

export default ProgressBar;
