import React, { useState, useEffect, useRef } from "react";
import SignatureCanvas from 'react-signature-canvas'

function SignatureInput(props) {

    const {
        color = "#000000",
        minimalStyle,
        defaultValue,
        isPreview,
        placeholder,
        name,
        height,
        inputId,
        inputStyle,
        errorMessage,
        inputValid,
        isValid,
        data,
        required,
        handlevalidationInputChanges,
        width,
        isMobile,
        isMobilePreview,
    } = props;

    const sigCanvas = useRef({});
    const mainDivRef = useRef({});
    const [defaultVal, setDefaultVal] = useState(defaultValue);
    const [calculatedWidth, setCalculatedWidth] = useState(null)


    useEffect(() => {
        // console.log(inputStyle)

        if (!isMobile && !isMobilePreview) {
            if (width.includes("%") && mainDivRef.current && mainDivRef.current.offsetWidth) {
                setCalculatedWidth(mainDivRef.current.offsetWidth * (width.replace("%", "") / 100))
                // setCalculatedWidth(width.replace("%", "") * 5)
            } else {
                setCalculatedWidth(mainDivRef.current.offsetWidth)
            }
        } else {
            // setCalculatedWidth(window.screen.width * 0.4 > 200 ? 200 : window.screen.width * 0.4)
            setCalculatedWidth(mainDivRef.current.offsetWidth)
        }


    }, [width, isMobile, isMobilePreview, mainDivRef, inputStyle])


    useEffect(() => {
        if (defaultValue) {
            sigCanvas.current.fromDataURL(defaultValue, { width: calculatedWidth, height: calculatedWidth * 0.4 })
        }

    }, [defaultValue, calculatedWidth])



    return (<div style={{ width: `100%`, margin: "auto" }} ref={mainDivRef}>
        {isPreview ? (<><div style={{ width: "fit-content", margin: "auto" }}>
            <SignatureCanvas
                onEnd={(e) => {
                    const b64 = sigCanvas.current.toDataURL("string");

                    handlevalidationInputChanges(b64, inputId)
                }}
                canvasProps={{ width: calculatedWidth, height: calculatedWidth * 0.4, className: 'sigCanvas' }}
                ref={sigCanvas}
            />

        </div>

            <button type="button" className="btn btn-light" onClick={() => { sigCanvas.current.clear() }}>Clear</button>
            {inputValid || isValid ? (
                <></>
            ) : (
                <span style={{ color: "red" }} className="req-error">
                    {errorMessage}
                </span>
            )}
        </>) : (<><div style={{ width: "fit-content", margin: "auto" }}>
            <SignatureCanvas
                canvasProps={{ width: calculatedWidth, height: calculatedWidth * 0.4, className: 'sigCanvas' }}
                ref={sigCanvas}
            />

        </div>

            <button type="button" className="btn btn-light" onClick={() => { sigCanvas.current.clear() }}>Clear</button>
        </>)
        }
    </div>)

}


export default SignatureInput;