import en from "react-phone-number-input/locale/en.json";

export function getDefaultCountriesOptions(
  valueType = "countryName",
  filteredCountries
) {
  if (filteredCountries) {
    return filteredCountries.map((country) => {
      return { label: country.countryName, value: country[valueType] };
    });
  }
  valueType = valueType == "countryName" ? 1 : 0;
  return Object.entries(en)
    .filter(
      (country) =>
        country[1] !== "Phone number country" &&
        country[1] !== "ext." &&
        country[1] !== "Phone"
    )
    .map((country) => {
      return {
        label: country[1],
        value: country[valueType],
        countryName: country[1],
        alpha2code: country[0],
      };
    });
}

export function getCountryCodeByValueType(countryValue) {
  const countryData = Object.entries(en).find(
    (country) => country[0] === countryValue || country[1] === countryValue
  );
  return countryData[0];
}
