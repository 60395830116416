import { Link, Route, Redirect } from "react-router-dom";
import React from "react";

// draw the relevant links to the navbar
export const AppLinks = (props) => {
  const { routes } = props;

  return routes
    .filter((route) => route.isVisible)
    .map((route) => (
      <Link
        key={route.title}
        className="dropdown-item"
        style={{ color: "black", textTransform: "capitalize" }}
        to={route.path}
      >
        {route.title}
      </Link>
    ));
};
// rendering the routes itself
export const AppRoutes = (props) => {
  return props.routes.map((route) => (
    <Route key={route.title} {...route} />
  ));
};
