import React from "react";
import { QRCodeCanvas } from "qrcode.react";

function QRCode({ url, title }) {
  const download = () => {
    const canvas = document.querySelector(".HpQrcode > canvas");
    const link = document.createElement("a");
    link.href = canvas.toDataURL();
    link.download = `${title}-QR.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div className="HpQrcode">
      <QRCodeCanvas value={url} />
      <br />
      <br />
      <button onClick={download} className="btn btn-primary">
        Download PNG
      </button>
    </div>
  );
}

export default QRCode;
