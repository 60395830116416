import { H } from 'highlight.run';
import { configHostUrl, apiUrl, highlightProjectId, } from "../config";

let isRecording = false

const handleHighlightService = (userId, userName) => {
    if (configHostUrl === "localhost:3000") return;
    if (!isRecording) {
        H.init(highlightProjectId, {
            tracingOrigins: [configHostUrl, apiUrl],
            networkRecording: {
                enabled: true,
                recordHeadersAndBody: true,
            },
            urlBlocklist: [
                "http://localhost:3000/"
            ],
        });

        isRecording = true
    } else {
        H.identify(`uid-${userId}-${userName}`, {
            id: userId,
            name: userName
        });
    }

}

export default handleHighlightService


