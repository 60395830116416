import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { toFindDuplicates } from "../../../utils";
// import { saveCustomStyle } from "../../../redux/actions";
import ConfigActionButtons from "./ConfigActionButtons";

import { isJsonString } from "../../../utils";

class Variables extends React.Component {
  state = {
    variables: [],
    varError: "",
  };

  componentDidMount() {
    this.setState({
      variables: JSON.parse(JSON.stringify(this.props.data.variables)),
    });
  }
  //  increment variable name function
  loopNewVariableName = (name, num) => {
    const { variables } = this.state;
    const tempName = `${name}${num}`;
    const isNameExist = variables.find((el) => el.name === tempName);
    return isNameExist ? this.loopNewVariableName(name, num + 1) : tempName;
  };

  addVariable = () => {
    const { variables } = this.state;
    const isNameExist = variables.find((el) => el.name === "Variable");
    const varName = isNameExist
      ? this.loopNewVariableName("Variable", 2)
      : "Variable";
    const newVariable = {
      name: varName,
      type: "text",
      value: "",
      deletable: true,
      valid: true
    };
    variables.push(newVariable);
    this.setState({ variables });
  };

  handleChangeVariable = (e, variableIndex) => {
    const { variables } = this.state;
    let { name, value, type } = e.target;
    const varName = value;
    let varError;

    if (variables[variableIndex].type === "UTM" && name !== "value") return;
    if (!value && type == "text") value = "";
    if (!value && type == "number") value = 0;
    if (!value && type == "json") value = '{}';
    if (variables[variableIndex].type === "json") {
      !isJsonString(value) ? variables[variableIndex].valid = false : variables[variableIndex].valid = true
    }
    variables[variableIndex][name] = value;

    const isDuplications = toFindDuplicates(variables.map((el) => el.name));
    const isVarEmpty = variables.filter((el) => !el.name);
    if (isVarEmpty.length) {
      varError = " * You have an empty variable, this can cause errors";
    } else if (isDuplications.length) {
      varError =
        " * There are two or more varibles with the same name, the latter one will be overan ";
    } else {
      varError = "";
    }

    this.setState({ variables, varError });
  };

  deleteVariable = (variableIndex) => {
    let { variables } = this.state;
    variables.splice(variableIndex, 1);
    this.setState({ variables });
  };

  saveVariables = () => {
    let { variables } = this.state;
    const isEmptyVAr = variables.filter((el) => !el.name);
    let filteredVariables = this.filterByProperty(variables, "name");
    if (variables.length !== filteredVariables.length || isEmptyVAr.length) {
      return this.props.toast.error(
        "You have at least 2 variables with the same name.",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        }
      );
    }
    if (
      JSON.stringify(variables) !== JSON.stringify(this.props.data.variables)
    ) {
      this.props.handleSaveVariables(variables);
    }
  };

  filterByProperty = (array, propertyName) => {
    let occurrences = {};
    return array.filter((x) => {
      let property = x[propertyName];
      if (occurrences[property]) {
        return false;
      }
      occurrences[property] = true;
      return true;
    });
  };

  render() {
    const { variables = [] } = this.state;
    const { t } = this.props;
    return (
      <div>
        <h2>{t("config-variables-title")}</h2>

        {variables.map((itr, index) => {
          let { name, type, value, deletable } = itr;
          if (!value && type == "text") value = "";
          if (!value && type == "number") value = 0;
          if (!value && type == "json") value = '{}';
          return (
            <div key={index} className="fields variablesList">
              <label>{t("config-variables-name")}:</label>
              <input
                type="text"
                name="name"
                disabled={type === "UTM" ? true : false}
                value={name}
                onChange={(e) => this.handleChangeVariable(e, index)}
              />

              <label>{t("config-variables-type")}:</label>
              <select
                name="type"
                value={type}
                disabled={type === "UTM" ? true : false}
                onChange={(e) => this.handleChangeVariable(e, index)}
              >
                <option value="text">
                  {t("config-variables-select-text")}
                </option>
                <option value="number">
                  {t("config-variables-select-number")}
                </option>

                <option value="json">
                  JSON
                </option>
              </select>

              <label>
                {t("config-variables-value")}{" "}
                <small>({t("config-variables-value-optional")})</small>:
              </label>
              <input
                style={{ border: type === "json" && !itr.valid ? '1px solid red' : 'inherit', color: type === "json" && !itr.valid ? 'red' : 'inherit' }}
                type={type}
                name="value"
                value={value}
                onChange={(e) => this.handleChangeVariable(e, index)}
              />
              {deletable && (
                <a
                  className="pointer"
                  onClick={() => this.deleteVariable(index)}
                >
                  {t("config-variables-delete")}
                </a>
              )}
            </div>
          );
        })}

        {/* i comment this global delete variables. i didwnt knew what it does */}

        {/* <button
          disabled={
            JSON.stringify(variables) ===
            JSON.stringify(this.props.data.variables)
          }
          onClick={this.saveVariables}
        >
          {t("config-variables-delete")}
        </button> */}

        <a title="" className="add pointer" onClick={() => this.addVariable()}>
          <span>+ </span>
          {t("config-variables-add")}
        </a>
        <div>
          <span className="red">{this.state.varError}</span>
        </div>

        <ConfigActionButtons
          selectedFunc={this.saveVariables}
          toast={this.props.toast}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      //   saveCustomStyle: (customStyle, quizId) =>
      //     dispatch(saveCustomStyle(customStyle, quizId)),
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Variables)
);
