import React from "react";
import "./logic.css";
import ProductLogicTab from "./ProductLogicTab";
import { withTranslation } from "react-i18next";
class ProductConditions extends React.Component {
  render() {
    const {
      step,
      variables = [],
      customScripts = [],
      feedProducts = [],
    } = this.props;
    let { options } = this.props.step;
    const { arrayOfData, t } = this.props;
    const { logics = [] } = this.props;

    options = options ? options : [];

    return (
      <div className="logic">
        <div className="logic-fields float-left">
          <label>
            Q{this.props.step.counter}.{step.slideName}
          </label>
        </div>

        <div
          className="tab-content-logic"
          id="myTabContent"
          style={{ textAlign: "left" }}
        >
          <div
            className="tab-pane fade show  "
            id="before"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <ProductLogicTab
              stepName={step.slideName}
              logicStepData={logics}
              logicType="before"
              addNewLogic={this.props.addNewLogic}
              changeLogicsDefaultRedirect={
                this.props.changeLogicsDefaultRedirect
              }
              arrayOfData={arrayOfData}
              step={step}
              addRule={this.props.addRule}
              removeLogic={this.props.removeLogic}
              showConditions={this.props.showConditions}
              handleLogicDetails={this.props.handleLogicDetails}
              variables={variables}
              customScripts={customScripts}
              feedProducts={feedProducts}
            />
          </div>
        </div>
        <br />
      </div>
    );
  }
}
export default withTranslation()(ProductConditions);
