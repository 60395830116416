import React, { useEffect, useState } from "react";

export default function QuestionFocusAlert(props) {
  const {
    alertClass,
    msg,
    feedback,
    saveResult,
    quizType,
    customStyle,
    correct,
    questionType,
    isMobile,
    isTablet,
  } = props;

  let styleObj =
    !customStyle || !customStyle.incorrect
      ? {
          incorrect: { text: "incorrect" },
          correct: { text: "Correct!" },
          alertLength: 2000,
        }
      : customStyle;

  //Width
  let width = styleObj.width || "";
  if (isMobile && styleObj.mobileWidth) width = styleObj.mobileWidth;
  else if (isTablet && styleObj.tabletWidth) width = styleObj.tabletWidth;
  else if (styleObj.width) width = styleObj.width;

  //AlertLength
  let alertLength = styleObj.alertLength || "500";
  if (isMobile && styleObj.mobileAlertLength)
    alertLength = styleObj.mobileAlertLength;
  else if (isTablet && styleObj.tabletAlertLength)
    alertLength = styleObj.tabletAlertLength;
  else if (styleObj.alertLength) alertLength = styleObj.alertLength;

  styleObj = {
    ...styleObj,
    ...styleObj.correct,
    width: `${width}%`,
    margin: "auto",
    border: `${styleObj.correct.borderSize}px solid ${styleObj.correct.borderColor}`,
  };
  if (!correct) {
    styleObj = {
      ...styleObj,
      ...styleObj.incorrect,
      width: `${width}%`,
      margin: "auto",
      border: `${styleObj.incorrect.borderSize}px solid ${styleObj.incorrect.borderColor}`,
    };
  }
  if (quizType !== "assessment") {
    return <>{saveResult ? saveResult(500) : <></>}</>;
  }

  return (
    <div className={alertClass} role="alert" style={{ ...styleObj }}>
      {correct ? styleObj.correct.text : styleObj.incorrect.text}
      {saveResult ? saveResult(parseInt(alertLength) || 500) : <></>}
    </div>
  );
}
