import React, { useEffect, useState } from "react";
import moment from "moment";

import "./integrations.css";
export default function KeyCard(props) {
  const { keyObj, showKey, toggleKey, deleteKey } = props;

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (keyObj.is_active) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [keyObj]);

  const copyToClip = (text) => {
    navigator.clipboard.writeText(text);
  };

  const validateDeleteKey = () => {
    const isDelete = window.confirm(
      `Are you sure you want to delete ${keyObj.name} key`
    );
    if (isDelete) deleteKey(keyObj.user_id, keyObj.key_id);
  };

  const copyBtn = keyObj.key.includes("****") ? (
    //   <button className="btn btn-secondary" disabled>
    //     Copy
    //   </button>
    <a title="" className="copy " disabled></a>
  ) : (
    //   <button
    //     className="btn btn-secondary"
    //     onClick={() => copyToClip(keyObj.key)}
    //   >
    //     Copy
    //   </button>
    <a title="" className="copy pointer" onClick={() => copyToClip(keyObj.key)}></a>
  );

  const showBtn = keyObj.key.includes("****") ? (
    <a title="" className="eye-off" onClick={() => showKey(keyObj.key_id)}></a>
  ) : (
    <a title="" className="eye-on"></a>
  );

  return (
    <li className="">
      <label className="checkboxContainer">
        <input
          type="checkbox"
          id={keyObj.key_id}
          onChange={() => toggleKey(!isActive, keyObj.key_id)}
          checked={isActive}
        />
        <span className="checkmark"></span>
        <span>Active</span>
      </label>
      <h3>{keyObj.name}</h3>
      <span className="date">
        {moment(keyObj.create_date).format("DD/MM/YYYY HH:mm:ss")}
      </span>

      {/* <h4>{keyObj.type}</h4> */}
      <span className="key">{keyObj.key}</span>

      {showBtn}
      {copyBtn}
      <a title="" className="delete" onClick={validateDeleteKey}>
        Delete{" "}
      </a>

      {/* <button
        className="btn btn-warning"
        onClick={() => showKey(keyObj.key_id)}
      >
        Show
      </button>
      <div className="custom-control custom-switch">
        <label className="custom-control-label" for={keyObj.key_id}>
          Is Active
        </label>
      </div> */}
      {/* <button className="btn btn-danger" onClick={validateDeleteKey}>
        Delete Key
      </button> */}
    </li>
  );
}
