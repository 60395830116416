import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";

function ProductItem(props) {
  const {
    product,
    productsStyle = {},
    ctaButtonStyle = {},
    defaultProductLink,
    linkButtonText,
    showProductPrice,
    showProductDescription,
    showProductCTA,
    showProductCategory,
  } = props;

  const [selectedVariant, setSelectedVeriation] = useState(product);
  const [variationSelectOptions, setVariationSelectOptions] = useState([]);

  useEffect(() => {
    if (!product) return;
    const { variations = [] } = product;
    setVariationSelectOptions(
      variations.map((itr) => {
        const { product_id, title, image_link } = itr;
        return { label: title, value: product_id, image: image_link };
      })
    );
  }, [product]);

  if (!selectedVariant) return <></>;
  const {
    product_id,
    title,
    image_link,
    link,
    description,
    price_value,
    price_currency,
    category,
  } = selectedVariant;
  const handleChangeVeriation = (productId) => {
    console.log(productId);
    const { variations = [] } = product;
    const activeVeriation = variations.find(
      (variation) => variation.product_id == productId
    );
    if (activeVeriation) setSelectedVeriation(activeVeriation);
  };

  return (
    <>
      <div
        key={product_id}
        style={{
          ...productsStyle,
          height: "auto",
          display: "inline-flex",
          flexDirection: "column",
          minWidth: "100px",
          alignItems: "left",
          textAlign: productsStyle.alignItems,
        }}
        className="answerContainer"
      >
        <span
          style={{
            ...productsStyle,
            fontSize: productsStyle.titleFontSize,
            width: `${productsStyle.textContainerSize}px`,
            border: "none",
            boxShadow: "none",
            minWidth: "100px",
          }}
        >
          {title}
        </span>
        <div>
          <div
            style={{
              width: `${productsStyle.picSize}px`,
              height: `${productsStyle.picSize}px`,
              display: "flex",
              margin: "auto",
              minWidth: "100px",
            }}
          >
            {" "}
            <img
              type="button"
              title=""
              src={image_link || "/img/picFrame.png"}
              alt={title}
              className="answerPic"
            />
          </div>
          {variationSelectOptions.length > 1 && (
            <div>
              <ReactSelect
                options={variationSelectOptions}
                value={{
                  value: selectedVariant.product_id,
                  label: selectedVariant.title,
                  image: selectedVariant.image_link,
                }}
                onChange={({ value }) => handleChangeVeriation(value)}
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundImage: `url(${state.data.image})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    paddingLeft: "40px", // Adjust as needed
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    display: "flex",
                    alignItems: "center",
                    backgroundImage: `url(${state.data.image})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    paddingLeft: "40px", // Adjust as needed
                  }),
                }}
              />
            </div>
          )}
          {showProductPrice && (
            <div>
              <p
                style={{
                  fontSize: productsStyle.priceFontSize,
                  margin: productsStyle.margin,
                  padding: productsStyle.padding,
                }}
              >
                {price_value} {price_currency}
              </p>
            </div>
          )}
          {!category && showProductCategory ? (
            <div>
              <p
                style={{
                  ...productsStyle,
                  fontSize: productsStyle.descriptionFontSize,
                  width: `${productsStyle.textContainerSize}px`,
                  border: "none",
                  boxShadow: "none",
                  minWidth: "100px",
                  margin: productsStyle.margin,
                  padding: productsStyle.padding,
                }}
              >
                Category: {category}
              </p>
            </div>
          ) : (
            <></>
          )}
          {showProductDescription && (
            <p
              style={{
                ...productsStyle,
                fontSize: productsStyle.descriptionFontSize,
                width: `${productsStyle.textContainerSize}px`,
                border: "none",
                boxShadow: "none",
                minWidth: "100px",
                margin: productsStyle.margin,
                padding: productsStyle.padding,
              }}
            >
              {description}
            </p>
          )}
        </div>
        {showProductCTA && (
          <button
            className="btn"
            onClick={() => window.open(link || defaultProductLink, "_blank")}
            style={ctaButtonStyle}
          >
            {linkButtonText || "Go to product"}
          </button>
        )}
      </div>
    </>
  );
}

export default ProductItem;
